import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { BatchStatus } from '@web-panel/api'
import {
  DefaultErrorPage,
  AppPage,
  CardedContent,
  TabSections,
  Button,
  useModalRef,
} from '@web-panel/shared'
import modelIcons from '@local/model-icons'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useBatch } from '@web-panel/oc-api'
import BatchInfoTable from './Tables/BatchInfo'
import BatchItemsTable from './Tables/BatchItems'
import PrintPhysicalLabelButton from './Components/PrintPhysicalLabelButton'
import BatchAttachContainerModal from './Modals/BatchAttachContainerModal'

const PreviewPage: FC = () => {
  const params = useParams()
  const { formatMessage } = useIntl()
  const { data: batch, loading } = useBatch({ id: params.id ?? '' })
  const attachContainerModalRef = useModalRef()
  const { can } = useRoleAuthorization()

  if (loading) return null
  if (!batch)
    return (
      <DefaultErrorPage
        title={formatMessage({ id: 'errors.404.title' })}
        message={formatMessage({ id: 'errors.404.message' })}
      />
    )

  const headerActionButtons = [
    can(
      'productItemBatch',
      'attachContainer',
      <Button
        disabled={batch.status === BatchStatus.Delivered}
        variant="contained"
        color="secondary"
        onClick={() => attachContainerModalRef.current?.open()}
      >
        <FormattedMessage
          id="common.actions.attach-with-name"
          values={{ name: formatMessage({ id: 'models.container' }) }}
        />
      </Button>
    ),
    <PrintPhysicalLabelButton batch={batch} />,
  ]

  return (
    <AppPage
      backButton
      icon={modelIcons.productItemBatch}
      title={formatMessage({ id: 'routes.batch-preview' }, { name: batch.batchNumber })}
      actionButtons={headerActionButtons}
    >
      <CardedContent>
        <TabSections
          sections={[
            {
              key: 'info',
              title: formatMessage({ id: 'pages.product-item-batches-preview.tabs.info' }),
              Content: () => <BatchInfoTable data={batch} />,
            },
            {
              key: 'items',
              badge: batch.itemsNumber,
              title: formatMessage({ id: 'pages.product-item-batches-preview.tabs.items' }),
              Content: () => <BatchItemsTable id={batch.id} />,
            },
          ]}
        />
      </CardedContent>
      <BatchAttachContainerModal ref={attachContainerModalRef} batchId={batch.id} />
    </AppPage>
  )
}

export default pageWithRoleAuthorization(PreviewPage, ({ can }) =>
  can('productItemBatch', 'preview')
)
