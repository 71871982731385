import { FC } from 'react'
import {
  AppPage,
  CardedContent,
  Button,
  DefaultErrorPage,
  useNotifications,
} from '@web-panel/shared'

import modelIcons from '@local/model-icons'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik } from 'formik'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { usePartnerEmployee, useUpdatePartnerEmployee } from '@web-panel/oc-api'
import EditPartnerEmployeeForm, {
  FormFields,
  validationSchema,
} from './Forms/EditPartnerEmployeeForm'

const EditPage: FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()
  const { data: employee, loading } = usePartnerEmployee({
    partnerId: params.id ?? '',
    employeeId: params.employeeId ?? '',
  })
  const { request: updateEmployee, loading: loadingUpdateEmployee } = useUpdatePartnerEmployee()
  const { formatMessage } = useIntl()

  if (loading) return null
  if (!employee)
    return (
      <DefaultErrorPage
        title={formatMessage({ id: 'errors.404.title' })}
        message={formatMessage({ id: 'errors.404.message' })}
      />
    )

  const onSubmit = async (input: FormFields) => {
    try {
      await updateEmployee({
        partnerId: params.id ?? '',
        employeeId: employee.id,
        input,
      })
      navigate(-1)
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (e) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const initialValues: FormFields = {
    firstName: employee.firstName,
    lastName: employee.lastName,
    email: employee.email,
  }

  return (
    <Formik<FormFields>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formProps) => {
        const headerActionButtons = [
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            loading={loadingUpdateEmployee}
            onClick={() => formProps.handleSubmit()}
            disabled={!formProps.dirty || !formProps.isValid}
          >
            <FormattedMessage id="common.actions.update" />
          </Button>,
        ]

        return (
          <AppPage
            icon={modelIcons.partnerEmployee}
            title={formatMessage(
              { id: 'routes.partner-employee-edit' },
              { name: [employee.firstName, employee.lastName].join(' ') }
            )}
            actionButtons={headerActionButtons}
            backButton
          >
            <CardedContent>
              <EditPartnerEmployeeForm {...formProps} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export default pageWithRoleAuthorization(EditPage, ({ can }) => can('partnerEmployee', 'update'))
