import { FC, useCallback } from 'react'
import { AppPage, InfinitScroller, useDebouncedSearch } from '@web-panel/shared'

import modelIcons from '@local/model-icons'
import { useIntl } from 'react-intl'
import { PageHeaderSearch } from '@local/components/PageHeaderSearch'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { usePaymentOuts } from '@web-panel/oc-api'
import IndexTable from './Tables/IndexTable'
import Filters, { useFilters, withFilters } from './Filters'

const PaymentOuts: FC = () => {
  const { formatMessage } = useIntl()
  const { debouncedFilters } = useFilters()
  const { search, onChangeSearch, debouncedSearch } = useDebouncedSearch()
  const {
    data: paymentOuts = [],
    loading,
    loadMore,
  } = usePaymentOuts(
    { ...debouncedFilters, search: debouncedSearch },
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    }
  )

  const FilterComponent: FC = useCallback(
    () => <Filters search={debouncedSearch} />,
    [debouncedSearch]
  )

  return (
    <AppPage
      loading={loading}
      Sidebar={FilterComponent}
      icon={modelIcons.payoutRequest}
      title={formatMessage({ id: 'routes.payout-requests' })}
      searchComponent={<PageHeaderSearch value={search} onChangeValue={onChangeSearch} />}
    >
      <InfinitScroller onLoadMore={loadMore}>
        <IndexTable paymentOuts={paymentOuts} loading={loading} />
      </InfinitScroller>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(withFilters(PaymentOuts), ({ can }) =>
  can('paymentOut', 'list')
)
