import { FC } from 'react'
import { Outlet } from '@web-panel/api'
import { FormSection, Typography } from '@web-panel/shared'
import { useIntl } from 'react-intl'

type DeliveryAddressSectionProps = {
  outlet: Outlet
}

export const DeliveryAddressSection: FC<DeliveryAddressSectionProps> = ({ outlet }) => {
  const { formatMessage } = useIntl()

  return (
    <FormSection title={formatMessage({ id: 'models.order.delivery.address' })}>
      <Typography variant="body1">
        {[
          outlet.location.name,
          `${outlet.location.street} ${outlet.location.houseNumber}`,
          outlet.location.postalCode,
          outlet.location.city.name,
          outlet.location.country.name,
        ].join(', ')}
      </Typography>
    </FormSection>
  )
}
