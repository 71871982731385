import { useRef, useMemo, MouseEventHandler } from 'react'
import { MenuItem, ListItemText, ListItemIcon, Icon } from '@mui/material'
import {
  ConfirmationDialog,
  ConfirmationDialogRef,
  ConfirmationDialogProps,
} from '../ConfirmationDialog'

export type RowActionsMenuItemProps<Data> = {
  data: Data
  title: string
  icon?: string
  confirmation?:
    | Omit<ConfirmationDialogProps, 'onSubmit'>
    | ((data: Data) => Omit<ConfirmationDialogProps, 'onSubmit'>)
  disabled?: boolean | ((data: Data) => boolean)
  onClick: (data: Data) => void
  onCloseDropdown: () => void
}

export function RowActionsMenuItem<Data>({
  data,
  title,
  icon,
  disabled,
  confirmation,
  onClick,
  onCloseDropdown,
}: RowActionsMenuItemProps<Data>) {
  const dialogRef = useRef<ConfirmationDialogRef>(null)

  const onItemClick: MouseEventHandler = (e) => {
    e.stopPropagation()

    if (confirmation) {
      dialogRef.current?.open()
      return
    }
    onCloseDropdown()
    onClick(data)
  }

  const confirmationDialog = useMemo(() => {
    if (!confirmation) return null

    const confirmationProps = typeof confirmation === 'function' ? confirmation(data) : confirmation

    return (
      <ConfirmationDialog
        ref={dialogRef}
        {...confirmationProps}
        onSubmit={() => {
          onCloseDropdown()
          onClick(data)
        }}
      />
    )
  }, [data, confirmation])

  const isDisabled = useMemo(() => {
    if (!disabled) return false
    if (typeof disabled === 'boolean') return disabled

    return disabled(data)
  }, [disabled, data])

  return (
    <>
      {confirmationDialog}
      <MenuItem disabled={isDisabled} onClick={onItemClick}>
        {icon && (
          <ListItemIcon>
            <Icon>{icon}</Icon>
          </ListItemIcon>
        )}
        <ListItemText>{title}</ListItemText>
      </MenuItem>
    </>
  )
}
