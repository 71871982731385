import { FC, useMemo } from 'react'
import { useCountries, Country } from '@web-panel/api'
import { Autocomplete, TextField } from '@web-panel/shared'
import { AutocompleteInputProps } from './types'

type CountryAutocompleteInputPops = AutocompleteInputProps<Country> & {
  enabledFor?: 'location' | 'billing'
}
const CountryAutocompleteInput: FC<CountryAutocompleteInputPops> = ({
  fullWidth,
  className,
  disabled,
  value,
  onChange,
  error,
  label,
  enabledFor,
}) => {
  const { data: countries = [] } = useCountries()

  const filteredCountries = useMemo(() => {
    return countries.filter((country) => {
      if (enabledFor === 'billing') return country.enabledForBilling
      if (enabledFor === 'location') return country.enabledForLocation
      return true
    })
  }, [countries, enabledFor])

  return (
    <Autocomplete
      disablePortal
      fullWidth={fullWidth}
      disabled={disabled}
      options={filteredCountries}
      className={className}
      renderInput={({ inputProps, ...params }) => (
        <TextField
          error={error}
          {...params}
          label={label}
          inputProps={{ ...inputProps, autoComplete: 'do-not-autocomplete' }}
        />
      )}
      getOptionLabel={({ name }) => name}
      onChange={(_data, val) => onChange(val)}
      value={value}
      isOptionEqualToValue={(option, val) => option.code === val.code}
    />
  )
}

export default CountryAutocompleteInput
