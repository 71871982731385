import { useRef, useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { Typography } from '@web-panel/shared'
import blackLogo from '@local/assets/images/BlackLogo.svg'
import QRCode from 'qrcode'
import { useReactToPrint } from 'react-to-print'

const pageStyle = `
  @page {
    size: 29mm 90mm;
    margin: 1.5mm 2mm;
  }

  @media print {
    html, body {
      margin: 1.5mm 2mm;
      size: 29mm 90mm;
      min-height: 100vh;
    }
  }
`

export type PhysicalLabelPrinterRef = {
  print: () => void
}

export type PhysicalLabelPrinterProps = {
  title: string
  subtitle: string
  encodedData: string
}

export const PhysicalLabelPrinter = forwardRef<PhysicalLabelPrinterRef, PhysicalLabelPrinterProps>(
  ({ encodedData, title, subtitle }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const print = useReactToPrint({
      content: () => containerRef.current,
      pageStyle,
      copyStyles: true,
    })
    const [qrImage, setQRImage] = useState<string>()

    const generateQRCode = async () => {
      const image = await QRCode.toDataURL(encodedData, {
        errorCorrectionLevel: 'low',
        margin: 0,
        color: {
          dark: '#000000',
          light: '#ffffff',
        },
      })
      setQRImage(image)
    }

    useEffect(() => {
      generateQRCode()
    }, [])

    useImperativeHandle(ref, () => ({
      print,
    }))

    return (
      <div
        className="flex flex-col items-center top-0 bottom-0 right-0 left-0 margin-auto absolute py-8"
        ref={containerRef}
      >
        <img
          className="transform rotate-90 translate-y-full"
          width="120"
          height="120"
          src={blackLogo}
          alt="logo"
        />
        <div className="flex-1 flex flex-col items-center justify-end">
          <Typography variant="body2">{title}</Typography>
          <Typography className="mb-2" variant="body2">
            {subtitle}
          </Typography>
          <div>
            <img width="120" height="120" src={qrImage} alt="qr link" />
          </div>
        </div>
      </div>
    )
  }
)
