import { gql } from '@apollo/client'
import {
  BoxRentalConfiguration,
  CollectionBoxRentalConfiguration,
  RentalConfigurationStatus,
  boxRentalConfigurationFields,
} from '@web-panel/api'

export type BoxRentalConfigurationsDocumentInput = undefined
export type BoxRentalConfigurationsDocumentResponse = {
  boxRentalConfigurations: {
    configurations: CollectionBoxRentalConfiguration[]
  }
}

export const BoxRentalConfigurationsDocument = gql`
  query boxRentalConfigurations($partnerId: String) {
    boxRentalConfigurations(partnerId: $partnerId) @rest(type: "BoxRentalConfigurationsCollection", path: "/partners-service/inventory/boxes/rental/configurations", method: "GET") {
      configurations @type(name: "BoxRentalConfiguration") {
        ${boxRentalConfigurationFields}
      }
    }
  }
`

export type PartnerBoxRentalConfigurationsDocumentInput = {
  partnerId: string
}
export type PartnerBoxRentalConfigurationsDocumentResponse = {
  partnerBoxRentalConfigurations: {
    configurations: BoxRentalConfiguration[]
  }
}
export const PartnerBoxRentalConfigurationsDocument = gql`
  query PartnerBoxRentalConfigurations($partnerId: String!) {
    partnerBoxRentalConfigurations(partnerId: $partnerId) @rest(type: "PartnerBoxRentalConfigurationsCollection", path: "/partners-service/inventory/boxes/rental/configurations?{args}", method: "GET") {
      configurations @type(name: "PartnerBoxRentalConfiguration") {
        ${boxRentalConfigurationFields}
      }
    }
  }
`

export type BoxRentalConfigurationDocumentInput = {
  id: string
}
export type BoxRentalConfigurationDocumentResponse = {
  boxRentalConfiguration: BoxRentalConfiguration
}
export const BoxRentalConfigurationDocument = gql`
  query boxRentalConfiguration($id: String!) {
    boxRentalConfiguration(id: $id) @rest(type: "BoxRentalConfiguration", path: "/partners-service/inventory/boxes/rental/configurations/{args.id}", method: "GET") {
      ${boxRentalConfigurationFields}
    }
  }
`

export type CreateBoxRentalConfigurationDocumentInput = {
  input: {
    countryCode: string
    status: RentalConfigurationStatus
    price: number
  }
}
export type CreateBoxRentalConfigurationDocumentResponse = {
  createBoxRentalConfiguration: BoxRentalConfiguration
}

export const CreateBoxRentalConfigurationDocument = gql`
  mutation CreateBoxRentalConfiguration($input: any) {
    createBoxRentalConfiguration(input: $input) @rest(type: "BoxRentalConfiguration", path: "/partners-service/inventory/boxes/rental/configurations", method: "POST") {
      ${boxRentalConfigurationFields}
    }
  }
`

export type UpdateBoxRentalConfigurationDocumentInput = {
  id: string
  input: {
    countryCode: string
    status: RentalConfigurationStatus
    price: number
  }
}
export type UpdateBoxRentalConfigurationDocumentResponse = {
  updateBoxRentalConfiguration: BoxRentalConfiguration
}
export const UpdateBoxRentalConfigurationDocument = gql`
  mutation UpdateBoxRentalConfiguration($id: String!, $input: any) {
    updateBoxRentalConfiguration(id: $id, input: $input) @rest(type: "BoxRentalConfiguration", path: "/partners-service/inventory/boxes/rental/configurations/{args.id}", method: "PUT") {
      ${boxRentalConfigurationFields}
    }
  }
`
