import { FC } from 'react'
import { DropdownButton, useFormatDate } from '@web-panel/shared'
import { eachMonthOfInterval } from 'date-fns'
import { useIntl } from 'react-intl'

type FilterOption = {
  title: string
  value: string | undefined
  onClick: () => void
}

type MonthSelectorProps = {
  value: string | undefined
  start: Date
  onChange: (value: string | undefined) => void
}

export const MonthSelector: FC<MonthSelectorProps> = ({ value, start, onChange }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()

  const monthItems: FilterOption[] = eachMonthOfInterval({
    start,
    end: new Date(),
  })
    .reverse()
    .map((d) => ({
      title: formatDate('human-month-year', d),
      value: formatDate('month-year', d),
      onClick: () => onChange(formatDate('month-year', d)),
    }))

  const totalItems: FilterOption[] = [
    {
      title: formatMessage({ id: 'common.labels.all-time' }),
      value: undefined,
      onClick: () => onChange(undefined),
    },
    ...monthItems,
  ]

  const selectedItem = totalItems.find((item) => item.value === value)

  return (
    <DropdownButton
      title={selectedItem?.title ?? totalItems[0].title}
      menu={totalItems}
      color="secondary"
      variant="contained"
    />
  )
}
