import { FC } from 'react'
import { AppPage, CardedContent, Button, useNotifications } from '@web-panel/shared'

import modelIcons from '@local/model-icons'
import { useIntl, FormattedMessage } from 'react-intl'
import { useParams, useNavigate } from 'react-router-dom'
import { Error404 } from '@local/pages/404'
import { Formik } from 'formik'
import { useProductItemPartnerConsumable } from '@web-panel/oc-api'
import { EditConsumableForm, FormFields, validationSchema } from './Forms/EditConsumableForm'
import { useChangePartnerConsumable } from './hooks/use-change-partner-consumable'

export const PartnersConsumablesEditPage: FC = () => {
  const { formatMessage } = useIntl()
  const { pushNotification } = useNotifications()
  const navigate = useNavigate()
  const params = useParams()
  const { data: partnerConsumable, loading: loadingPartnerConsumable } =
    useProductItemPartnerConsumable({
      id: params.consumableId ?? '-1',
      partnerId: params.id ?? '-1',
    })
  const { request: updatePartnerConsumable, loading: loadingUpdatePartnerConsumable } =
    useChangePartnerConsumable(params.id, partnerConsumable)

  if (loadingPartnerConsumable) return null
  if (!partnerConsumable) return <Error404 />

  const initialValues: FormFields = {
    cost: partnerConsumable.cost,
    enabled: partnerConsumable.enabled ?? true,
  }

  const onSubmit = async (fields: FormFields) => {
    const values = validationSchema.cast(fields)
    try {
      await updatePartnerConsumable({ cost: values.cost ?? 0, enabled: values.enabled ?? true })
      navigate(-2)
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <Formik<FormFields>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formProps) => {
        const headerActionButtons = [
          <Button
            fullWidth
            loading={loadingUpdatePartnerConsumable}
            key="save"
            variant="contained"
            color="secondary"
            disabled={!formProps.isValid}
            onClick={() => formProps.handleSubmit()}
          >
            <FormattedMessage id="common.actions.update" />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            icon={modelIcons.settings}
            title={formatMessage(
              { id: 'routes.partners.consumables.preview' },
              {
                name: formatMessage({
                  id: `consumable-product-item-types.${partnerConsumable.consumableType}`,
                }),
              }
            )}
            actionButtons={headerActionButtons}
          >
            <CardedContent>
              <EditConsumableForm {...formProps} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}
