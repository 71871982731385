import { FC } from 'react'
import { ContractPackageCode } from '@web-panel/api'
import { LabeledSelect } from '@web-panel/shared'
import { useFormikContext } from 'formik'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'

import { useContractPackageGroups } from '@web-panel/oc-api'

export type ContractPackageSelectionFormFields = {
  contractPackageCode: ContractPackageCode | null
}

export const contractPackageSelectionValidation = Yup.object({
  contractPackageCode: Yup.string().required(),
})

type ContractPackageSelectionProps = {
  countryCode: string
  businessCategoryId: string
}

export const ContractPackageSelection: FC<ContractPackageSelectionProps> = ({
  countryCode,
  businessCategoryId,
}) => {
  const { data: contractPackageGroups = [] } = useContractPackageGroups({
    countryCode,
    businessCategoryId,
  })
  const { values, errors, setFieldValue } = useFormikContext<ContractPackageSelectionFormFields>()
  const { formatMessage } = useIntl()

  const packageCodes = contractPackageGroups.map(({ contractPackageCode }) => contractPackageCode)
  const options = [...packageCodes, ContractPackageCode.Custom]
  const selectedValue = options.find((o) => o === values.contractPackageCode) ?? ''

  return (
    <LabeledSelect
      fullWidth
      displayEmpty
      label={formatMessage({ id: 'models.contract-package-business.package' })}
      options={options}
      value={selectedValue}
      onChange={(e) => setFieldValue('contractPackageCode', e.target.value)}
      error={!!errors.contractPackageCode}
    />
  )
}
