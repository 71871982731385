import { gql } from '@apollo/client'
import {
  Dispenser,
  dispenserFields,
  DispenserStatus,
  DispenserOperationType,
  DispenserLocationType,
  SortDirection,
  DispenserStandType,
  orderFields,
  Order,
  DispenserTask,
  dispenserTaskFields,
} from '@web-panel/api'

export type DispensersDocumentInput = {
  status?: DispenserStatus
  imei?: string
  core?: string
  search?: string
  outletId?: string
  minBattery?: number
  maxBattery?: number
  endLastSignalDateTime?: string
  startLastSignalDateTime?: string
  cityCode?: string
  operationType?: DispenserOperationType
  locationType?: DispenserLocationType
  sortOrder?: SortDirection
  sortBy?: 'CREATE_DATE' | 'BATTERY' | 'LAST_SIGNAL_DATE' | 'STATUS'
  offset?: number
  limit: number
}
export type DispensersDocumentResponse = {
  dispensersCollection: {
    dispensers: Dispenser[]
    hasNext: boolean
  }
}
export const DispensersDocument = gql`
  query Dispensers(
    $status: String,
    $imei: String,
    $core: String,
    $search: String,
    $outletId: String,
    $minBattery: Int,
    $maxBattery: Int,
    $endLastSignalDateTime: String,
    $startLastSignalDateTime: String,
    $cityCode: String,
    $operationType: String,
    $locationType: String,
    $sortOrder: String,
    $sortBy: String,
    $offset: Int,
    $limit: Int!
  ) {
    dispensersCollection(
      status: $status,
      imei: $imei,
      core: $core,
      search: $search,
      outletId: $outletId,
      minBattery: $minBattery,
      maxBattery: $maxBattery,
      endLastSignalDateTime: $endLastSignalDateTime,
      startLastSignalDateTime: $startLastSignalDateTime,
      cityCode: $cityCode,
      operationType: $operationType,
      locationType: $locationType,
      sortOrder: $sortOrder,
      sortBy: $sortOrder,
      offset: $sortOrder,
      limit: $limit      
    ) @rest(type: "DispenserCollection", path: "/partners-service/dispensers?{args}") {
      dispensers @type(name: "Dispenser") {
        ${dispenserFields}
      }
      hasNext
    }
  }
`

export type CreateDispensersBody = {
  file: File
}
export type CreateDispensersDocumentInput = CreateDispensersBody & {
  bodySerializer?: (
    data: CreateDispensersBody,
    headers: Headers
  ) => { body: FormData; headers: Headers }
}
export type CreateDispensersDocumentResponse = void
export const CreateDispensersDocument = gql`
  mutation CreateDispensers($input: any, $bodySerializer: any) {
    createDispensers(input: $input)
      @rest(
        type: "CreateDispensersResponse"
        path: "/partners-service/dispensers"
        method: "POST"
        bodySerializer: $bodySerializer
      ) {
      none
    }
  }
`

export type DispenserDocumentInput = {
  id: string
}
export type DispenserDocumentResponse = {
  dispenser: Dispenser
}
export const DispenserDocument = gql`
  query Dispenser($id: String!) {
    dispenser(id: $id) @rest(type: "Dispenser", path: "/partners-service/dispensers/{args.id}") {
      ${dispenserFields}
    }
  }
`

export type UpdateDispenserDocumentInput = {
  id: string
  input: {
    coreId: string
    imei: string
    capacity: number
    outletId?: string | null
    locationId?: string | null
    status: DispenserStatus
    operationType: DispenserOperationType
    locationType: DispenserLocationType
    standType: DispenserStandType
  }
}
export type UpdateDispenserDocumentResponse = {
  updateDispenser: Dispenser
}
export const UpdateDispenserDocument = gql`
  mutation UpdateDispenser($id: String!, $input: any) {
    updateDispenser(id: $id, input: $input) @rest(type: "Dispenser", path: "/partners-service/dispensers/{args.id}", method: "PUT") {
      ${dispenserFields}
    }
  }
`

export type AssignDispenserToOutletDocumentInput = {
  id: string
  outletId: string
}
export type AssignDispenserToOutletDocumentResponse = void
export const AssignDispenserToOutletDocument = gql`
  mutation AssignDispenserToOutlet($id: String!, $outletId: String!) {
    assignDispenserToOutlet(id: $id, outletId: $outletId, input: {})
      @rest(
        type: "NoChache"
        path: "/partners-service/dispensers/{args.id}/assign/{args.outletId}"
        method: "PUT"
      ) {
      NoBody
    }
  }
`

export type AssignDispenserToLocationDocumentInput = {
  id: string
  locationId: string
}
export type AssignDispenserToLocationDocumentResponse = void
export const AssignDispenserToLocationDocument = gql`
  mutation AssignDispenserToLocation($id: String!, $locationId: String!) {
    assignDispenserToLocation(id: $id, locationId: $locationId, input: {})
      @rest(
        type: "NoChache"
        path: "/partners-service/dispensers/{args.id}/location/{args.locationId}"
        method: "PUT"
      ) {
      NoBody
    }
  }
`

export type DispenserLastOrderDocumentInput = {
  coreId: string
}
export type DispenserLastOrderDocumentResponse = {
  dispenserLastOrder: Order
}
export const DispenserLastOrderDocument = gql`
  query DispenserLastOrder($coreId: String!) {
    dispenserLastOrder(coreId: $coreId) @rest(type: "Order", path: "/operations-service/orders/dispenser/core/{args.coreId}/open/last") {
      ${orderFields}
    }
  }
`

export type DispenserTasksDocumentInput = {
  dispenserId: string
  pagination: {
    offset?: number
    limit?: number
  }
}

export type DispenserTasksDocumentResponse = {
  dispenserTasks: {
    tasks: DispenserTask[]
    hasNext: boolean
  }
}

export const DispenserTasksDocument = gql`
  query DispenserTasks($dispenserId: String!, $pagination: any) {
    dispenserTasks(dispenserId: $dispenserId, pagination: $pagination)
      @rest(type: "DispenserTaskCollection", path: "/partners-service/dispensers/{args.dispenserId}/tasks?{args.pagination}") {
        tasks @type(name: "DispenserTask") {
          ${dispenserTaskFields}
        }
        hasNext
      }
  }
`
