import { FC } from 'react'
import { DropdownButton } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { AnalyticItemSeriesType } from '@web-panel/api'

type CupStateSelectorProps = {
  selected: AnalyticItemSeriesType
  onSelect: (value: AnalyticItemSeriesType) => void
}

export const CupStateSelector: FC<CupStateSelectorProps> = ({ selected, onSelect }) => {
  const { formatMessage } = useIntl()

  const items = Object.values(AnalyticItemSeriesType).map((cupState) => ({
    key: cupState,
    title: formatMessage({ id: `analytic-item-series-types.${cupState}` }),
    onClick: () => onSelect(cupState),
  }))

  return (
    <DropdownButton
      title={formatMessage({ id: `analytic-item-series-types.${selected}` })}
      menu={items}
      color="secondary"
      variant="contained"
      size="small"
    />
  )
}
