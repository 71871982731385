import { FC } from 'react'
import { AppPage, CardedContent, Button, useNotifications } from '@web-panel/shared'
import { Formik } from 'formik'
import modelIcons from '@local/model-icons'
import { Error404 } from '@local/pages/404'
import { useNavigate, useParams } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { useProductItemConsumable, useUpdateProductItemConsumable } from '@web-panel/oc-api'
import { FormFields, validationSchema, EditConsumableForm } from './Forms/EditConsumableForm'

export const SettingsEditConsumablePageComponent: FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { pushNotification } = useNotifications()
  const { data: consumable, loading } = useProductItemConsumable({ id: params.id! })
  const { request: updateConsumable, loading: loadingUpdateConsumable } =
    useUpdateProductItemConsumable()
  if (loading) return null
  if (!consumable) return <Error404 />

  const initialValues: FormFields = {
    cost: consumable.cost,
  }

  const onSubmit = async (fields: FormFields) => {
    const values = validationSchema.cast(fields)
    try {
      await updateConsumable({
        id: consumable.id,
        input: {
          cost: values.cost ?? 0,
          consumableType: consumable.consumableType,
          currency: consumable.currency,
        },
      })
      navigate(-1)
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <Formik<FormFields>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formProps) => {
        const headerActionButtons = [
          <Button
            fullWidth
            loading={loadingUpdateConsumable}
            key="save"
            variant="contained"
            color="secondary"
            disabled={!formProps.isValid || !formProps.dirty}
            onClick={() => formProps.handleSubmit()}
          >
            <FormattedMessage id="common.actions.update" />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            icon={modelIcons.settings}
            title={formatMessage(
              { id: 'routes.settings.consumables.edit' },
              {
                name: formatMessage({
                  id: `consumable-product-item-types.${consumable.consumableType}`,
                }),
              }
            )}
            actionButtons={headerActionButtons}
          >
            <CardedContent>
              <EditConsumableForm {...formProps} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export const SettingsEditConsumablePage = pageWithRoleAuthorization(
  SettingsEditConsumablePageComponent,
  ({ can }) => can('consumable', 'update')
)
