import { FC } from 'react'
import { ContractPackageSetting } from '@web-panel/api'
import { FormSection } from '@web-panel/shared'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import { ContractSettingsSectionFields } from './helpers'
import { InputSelectSettingType } from './InputSelectSettingType'

type InfrascructurePricingProps = {
  packageCode: string
  packageBoxSettings: ContractPackageSetting[]
  packageDispenserSettings: ContractPackageSetting[]
}
export const InfrascructurePricing: FC<InfrascructurePricingProps> = ({
  packageCode,
  packageBoxSettings,
  packageDispenserSettings,
}) => {
  const { formatMessage } = useIntl()
  const { values, errors, setFieldValue } = useFormikContext<ContractSettingsSectionFields>()

  return (
    <div className="mt-24">
      <FormSection
        firstSection
        title={formatMessage({ id: 'models.contract-package-business.infrastructure-pricing' })}
        containerClasses="-mt-8"
      >
        {values.itemsSetting && (
          <InputSelectSettingType
            allowNullSetting
            nullSettingDisabled={packageCode !== 'CUSTOM'}
            title={formatMessage({ id: 'models.box.plural' })}
            packageSettings={packageBoxSettings}
            currentSetting={values.boxSetting}
            onChange={(setting) => setFieldValue('boxSetting', setting)}
            resourceName={formatMessage({ id: 'models.box' })}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            errors={errors.boxSetting ?? {}}
          />
        )}
        {values.consumablesSetting && (
          <div className="mt-16">
            <InputSelectSettingType
              allowNullSetting
              nullSettingDisabled={packageCode !== 'CUSTOM'}
              title={formatMessage({ id: 'models.dispenser.plural' })}
              packageSettings={packageDispenserSettings}
              currentSetting={values.dispenserSetting}
              onChange={(setting) => setFieldValue('dispenserSetting', setting)}
              resourceName={formatMessage({ id: 'models.dispenser' })}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              errors={errors.dispenserSetting ?? {}}
            />
          </div>
        )}
      </FormSection>
    </div>
  )
}
