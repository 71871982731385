import { FC } from 'react'
import { Link } from '@web-panel/shared'

import { usePartner } from '@web-panel/oc-api'

type PartnerLinkProps = {
  partnerId: string
}

export const PartnerLink: FC<PartnerLinkProps> = ({ partnerId }) => {
  const { data: partner } = usePartner({ id: partnerId })
  if (!partner) return null

  return (
    <Link
      data-testid="PartnerLink"
      to={`/partners/${partner.id}`}
      onClick={(e) => e.stopPropagation()}
    >
      {partner.name}
    </Link>
  )
}
