import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PaymentOutStatus } from '@web-panel/api'
import { DropdownButton } from '@web-panel/shared'

type ChangeStatusButtonProps = {
  loading?: boolean
  value: PaymentOutStatus
  onChange: (value: PaymentOutStatus) => void
}

const ChangeStatusButton: FC<ChangeStatusButtonProps> = ({ value, onChange }) => {
  const { formatMessage } = useIntl()

  const menu = [
    {
      title: formatMessage({ id: `payment-out-statuses.${PaymentOutStatus.Created}` }),
      onClick: () => onChange(PaymentOutStatus.Created),
      disabled: true,
    },
    {
      title: formatMessage({ id: `payment-out-statuses.${PaymentOutStatus.Processing}` }),
      onClick: () => onChange(PaymentOutStatus.Processing),
      disabled: value !== PaymentOutStatus.Created && value !== PaymentOutStatus.ManualRequired,
    },
    {
      title: formatMessage({ id: `payment-out-statuses.${PaymentOutStatus.Processed}` }),
      onClick: () => onChange(PaymentOutStatus.Processed),
      disabled: value === PaymentOutStatus.Processed || value === PaymentOutStatus.Approved,
    },
    {
      title: formatMessage({ id: `payment-out-statuses.${PaymentOutStatus.Rejected}` }),
      onClick: () => onChange(PaymentOutStatus.Rejected),
      disabled:
        value === PaymentOutStatus.Processed ||
        value === PaymentOutStatus.Rejected ||
        value === PaymentOutStatus.Approved,
    },
    {
      title: formatMessage({ id: `payment-out-statuses.${PaymentOutStatus.Approved}` }),
      onClick: () => onChange(PaymentOutStatus.Approved),
      disabled: value !== PaymentOutStatus.ApprovalRequired,
    },
  ]

  Object.values(PaymentOutStatus).map((status) => ({
    title: formatMessage({ id: `payment-out-statuses.${status}` }),
    onClick: () => onChange(status),
    disabled: value === status,
  }))

  return (
    <DropdownButton
      fullWidth
      variant="contained"
      color="secondary"
      menu={menu}
      title={formatMessage({ id: `payment-out-statuses.${value}` })}
    />
  )
}

export default ChangeStatusButton
