import { FC } from 'react'
import {
  AppPage,
  CardedContent,
  HorizontalTable,
  HorizontalTableRow,
  HorizontalTableSection,
  Button,
  FormattedCurrency,
} from '@web-panel/shared'

import { useNavigate, useParams } from 'react-router-dom'
import { Error404 } from '@local/pages/404'
import modelIcons from '@local/model-icons'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import { ProductItemModelConfigurationStatusChip } from '@local/components/ProductItemModelConfigurationStatusChip'

import { useOutletProductItemModelConfiguration, useOutlet } from '@web-panel/oc-api'

const OutletRefundablePreviewPageComponent: FC = () => {
  const { formatMessage } = useIntl()
  const { id, refundableId } = useParams()
  const navigate = useNavigate()
  const { data: outlet, loading: loadingOutlet } = useOutlet({ id: id ?? '' })
  const { can } = useRoleAuthorization()
  const { data: configuration, loading: loadingConfiguration } =
    useOutletProductItemModelConfiguration({ outletId: id ?? '', modelId: refundableId ?? '' })

  if (loadingOutlet || loadingConfiguration) return null
  if (!outlet || !configuration) return <Error404 />

  const { itemModel } = configuration

  const headerActionButtons = [
    can(
      'outlet',
      'updateRefundables',
      <Button
        fullWidth
        key="edit"
        variant="contained"
        color="secondary"
        onClick={() => navigate(`edit`)}
      >
        <FormattedMessage id="common.actions.edit" />
      </Button>
    ),
  ]

  return (
    <AppPage
      backButton
      icon={modelIcons.settings}
      title={formatMessage(
        { id: 'routes.outlets.refundables.preview' },
        { name: configuration.itemModel.id, outletName: outlet.location.name }
      )}
      actionButtons={headerActionButtons}
    >
      <CardedContent>
        <div className="md:p-24 max-w-2xl">
          <HorizontalTable>
            <HorizontalTableSection
              isFirstInTable
              title={formatMessage({ id: 'models.refundable-product-item.item-model' })}
            />
            <HorizontalTableRow title={formatMessage({ id: 'models.refundable-model.id' })}>
              {itemModel.id}
            </HorizontalTableRow>
            <HorizontalTableRow title={formatMessage({ id: 'models.refundable-model.type' })}>
              <FormattedMessage id={`product-item-types.${itemModel.type}`} />
            </HorizontalTableRow>
            <HorizontalTableRow title={formatMessage({ id: 'models.refundable-model.capacity' })}>
              {[itemModel.capacityUnit, itemModel.capacityValue].join(' ')}
            </HorizontalTableRow>
            <HorizontalTableRow title={formatMessage({ id: 'models.refundable-model.form' })}>
              <FormattedMessage id={`product-item-model-forms.${itemModel.form}`} />
            </HorizontalTableRow>
            <HorizontalTableRow title={formatMessage({ id: 'models.refundable-model.model' })}>
              {itemModel.model}
            </HorizontalTableRow>
            <HorizontalTableRow title={formatMessage({ id: 'models.refundable-model.design' })}>
              {itemModel.design}
            </HorizontalTableRow>
            <HorizontalTableRow
              title={formatMessage({ id: 'models.refundable-model.description' })}
            >
              {itemModel.description}
            </HorizontalTableRow>

            <HorizontalTableSection title={formatMessage({ id: 'common.labels.configuration' })} />
            <HorizontalTableRow
              title={formatMessage({ id: 'models.refundable-product-item.cost' })}
            >
              <FormattedCurrency
                value={configuration.cost}
                currency={configuration.currency.name}
              />
            </HorizontalTableRow>
            <HorizontalTableRow
              title={formatMessage({ id: 'models.refundable-product-item.service-fee' })}
            >
              <FormattedCurrency
                value={configuration.serviceFee}
                currency={configuration.currency.name}
              />
            </HorizontalTableRow>
            <HorizontalTableRow
              title={formatMessage({ id: 'models.refundable-product-item.payout-fee-percent' })}
            >
              <FormattedNumber style="percent" value={configuration.payoutFeePercent / 100} />
            </HorizontalTableRow>
            <HorizontalTableRow
              title={formatMessage({ id: 'models.refundable-product-item.refund-fee-percent' })}
            >
              <FormattedNumber style="percent" value={configuration.refundFeePercent / 100} />
            </HorizontalTableRow>
            <HorizontalTableRow
              title={formatMessage({ id: 'models.refundable-product-item.status' })}
            >
              <ProductItemModelConfigurationStatusChip status={configuration.status} />
            </HorizontalTableRow>
          </HorizontalTable>
        </div>
      </CardedContent>
    </AppPage>
  )
}

export const OutletRefundablePreviewPage = pageWithRoleAuthorization(
  OutletRefundablePreviewPageComponent,
  ({ can }) => can('outlet', 'previewRefundable')
)
