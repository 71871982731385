import { useQuery, useMutation } from '@apollo/client'
import { CollectionQueryHook, MutationHook, QueryHook } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  BoxesDocument,
  BoxesDocumentInput,
  BoxesDocumentResponse,
  CreateBoxesDocument,
  CreateBoxesDocumentInput,
  CreateBoxesDocumentResponse,
  GetBoxDocument,
  GetBoxDocumentInput,
  GetBoxDocumentResponse,
  AssignBoxToOutletDocument,
  AssignBoxToOutletDocumentInput,
  AssignBoxToOutletDocumentResponse,
  UpdateBoxDocument,
  UpdateBoxDocumentInput,
  UpdateBoxDocumentResponse,
  BoxStatusAggregationDocument,
  BoxStatusAggregationDocumentInput,
  BoxStatusAggregationDocumentResponse,
  BoxActivityDocument,
  BoxActivityDocumentInput,
  BoxActivityDocumentResponse,
  BoxTasksDocument,
  BoxTasksDocumentInput,
  BoxTasksDocumentResponse,
  BoxFWVersionAggregationDocument,
  BoxFWVersionAggregationDocumentInput,
  BoxFWVersionAggregationDocumentResponse,
  ResetBoxItemsDocument,
  ResetBoxItemsDocumentInput,
  ResetBoxItemsDocumentResponse,
  PublicBoxDocumentInput,
  PublicBoxDocumentResponse,
  PublicBoxDocument,
} from '../docs'

// Request boxes collection
type UseBoxesInput = Omit<BoxesDocumentInput, 'limit' | 'offset'>

export const useBoxes: CollectionQueryHook<
  UseBoxesInput,
  BoxesDocumentResponse['boxesCollection']['boxes']
> = (input, options) => {
  const variables = { ...input, ...defaultCollectionVariables }

  const { data, loading, fetchMore, refetch } = useQuery<BoxesDocumentResponse>(BoxesDocument, {
    ...options,
    variables,
  })

  const loadMore = async () => {
    if (loading || !data || !data.boxesCollection.hasNext) return

    const offset = data.boxesCollection.boxes.length
    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.boxesCollection?.boxes,
    loading,
    loadMore,
    hasMore: data?.boxesCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

// Request single box
export const useBox: QueryHook<GetBoxDocumentInput, GetBoxDocumentResponse['box']> = (
  variables
) => {
  const { data, loading, refetch } = useQuery<GetBoxDocumentResponse, GetBoxDocumentInput>(
    GetBoxDocument,
    { variables }
  )

  return {
    data: data?.box,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

// Create boxes hook
type UseCreateBoxesInput = {
  file: File
}

const bodySerializer: CreateBoxesDocumentInput['bodySerializer'] = (
  data: UseCreateBoxesInput,
  headers: Headers
) => {
  const formData = new FormData()
  formData.append('file', data.file, data.file.name)

  return {
    body: formData,
    headers,
  }
}

export const useCreateBoxes: MutationHook<UseCreateBoxesInput, CreateBoxesDocumentResponse> =
  () => {
    const [execute, { loading }] = useMutation<CreateBoxesDocumentResponse>(CreateBoxesDocument)

    async function request(input: UseCreateBoxesInput): Promise<void> {
      await execute({ variables: { input, bodySerializer } })
    }

    return { loading, request }
  }

export const useAssignBoxToOutlet: MutationHook<
  AssignBoxToOutletDocumentInput,
  AssignBoxToOutletDocumentResponse
> = () => {
  const [execute, { loading }] =
    useMutation<AssignBoxToOutletDocumentResponse>(AssignBoxToOutletDocument)

  async function request(variables: AssignBoxToOutletDocumentInput): Promise<void> {
    await execute({ variables })
  }

  return { loading, request }
}

export const useUpdateBox: MutationHook<
  UpdateBoxDocumentInput,
  UpdateBoxDocumentResponse['updateBox'] | null
> = () => {
  const [execute, { loading }] = useMutation<UpdateBoxDocumentResponse | null>(UpdateBoxDocument)

  async function request(
    variables: UpdateBoxDocumentInput
  ): Promise<UpdateBoxDocumentResponse['updateBox'] | null> {
    const { data } = await execute({ variables })

    return data?.updateBox ?? null
  }

  return { request, loading }
}

// Box status aggregation
export const useBoxStatusAggregation: QueryHook<
  BoxStatusAggregationDocumentInput,
  BoxStatusAggregationDocumentResponse['boxStatusAggregation']['boxCountByStatuses']
> = (variables) => {
  const { data, loading, refetch } = useQuery<BoxStatusAggregationDocumentResponse>(
    BoxStatusAggregationDocument,
    { variables }
  )

  return {
    data: data?.boxStatusAggregation.boxCountByStatuses,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useBoxActivityHistory: CollectionQueryHook<
  BoxActivityDocumentInput,
  BoxActivityDocumentResponse['boxActivity']['activityHistory']
> = (input, options) => {
  const variables = { ...input, ...defaultCollectionVariables }

  const { data, loading, fetchMore, refetch } = useQuery<BoxActivityDocumentResponse>(
    BoxActivityDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.boxActivity.hasNext) return

    const offset = data.boxActivity.activityHistory.length
    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.boxActivity.activityHistory,
    loading,
    loadMore,
    hasMore: data?.boxActivity.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useBoxTasks: CollectionQueryHook<
  BoxTasksDocumentInput,
  BoxTasksDocumentResponse['boxTasks']['tasks']
> = (input, options) => {
  const variables = { ...input, ...defaultCollectionVariables }

  const { data, loading, fetchMore, refetch } = useQuery<BoxTasksDocumentResponse>(
    BoxTasksDocument,
    { ...options, variables }
  )

  const loadMore = async () => {
    if (loading || !data || !data.boxTasks.hasNext) return

    const offset = data.boxTasks.tasks.length
    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.boxTasks.tasks,
    loading,
    loadMore,
    hasMore: data?.boxTasks.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useBoxFWVersionAggregation: QueryHook<
  BoxFWVersionAggregationDocumentInput,
  BoxFWVersionAggregationDocumentResponse['boxFwVersionAggregation']['boxCountByFwVersions']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<BoxFWVersionAggregationDocumentResponse>(
    BoxFWVersionAggregationDocument,
    { ...options, variables }
  )

  return {
    data: data?.boxFwVersionAggregation?.boxCountByFwVersions,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useResetBoxItems: MutationHook<
  ResetBoxItemsDocumentInput,
  ResetBoxItemsDocumentResponse['resetBoxItems']
> = () => {
  const [execute, { loading }] = useMutation<ResetBoxItemsDocumentResponse>(ResetBoxItemsDocument)

  async function request(variables: ResetBoxItemsDocumentInput) {
    const { data } = await execute({ variables })

    if (!data) throw new Error('Could not reset box items')

    return data.resetBoxItems
  }

  return { request, loading }
}

export const usePublicBox: QueryHook<PublicBoxDocumentInput, PublicBoxDocumentResponse['box']> = (
  variables,
  options
) => {
  const { data, loading, refetch } = useQuery<PublicBoxDocumentResponse>(PublicBoxDocument, {
    ...options,
    context: {
      ...options?.context,
      isPublic: true,
    },
    variables,
  })

  return {
    data: data?.box,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}
