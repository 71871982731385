import { FC, useState } from 'react'
import { ProductItemStatus, Batch } from '@web-panel/api'
import { Autocomplete, TextField, LabeledSelect } from '@web-panel/shared'
import { FormikProps } from 'formik'
import { useIntl } from 'react-intl'
import { useDebouncedValue } from 'rooks'
import * as Yup from 'yup'
import _includes from 'lodash/includes'
import _difference from 'lodash/difference'
import { ConsumerAutocomplete } from '@local/components/Autocomplete/ConsumerAutocomplete'
import { useBatches } from '@web-panel/oc-api'
import PartnerSelect from './OutletSelect'
import BoxSelect from './BoxSelect'

const availableProductItems = _difference(Object.values(ProductItemStatus), [
  ProductItemStatus.BoxCheckedIn,
])

export type FormFields = {
  status: ProductItemStatus
  holderId: string | null
  batchId: string | null
  batchNumber: string | null
}

export const validationSchema = Yup.object().shape({
  status: Yup.string().required(),
  holderId: Yup.string().when('status', (status: ProductItemStatus, schema: Yup.StringSchema) => {
    if (
      _includes(
        [
          ProductItemStatus.PartnerStock,
          ProductItemStatus.PartnerOut,
          ProductItemStatus.InUse,
          ProductItemStatus.Returned,
        ],
        status
      )
    )
      return schema.required()

    return schema.nullable()
  }),
  batchNumber: Yup.string().nullable(),
})

function getHolderSelectComponent(status: ProductItemStatus) {
  switch (status) {
    case ProductItemStatus.PartnerStock:
    case ProductItemStatus.PartnerOut:
      return PartnerSelect
    case ProductItemStatus.InUse:
      return ConsumerAutocomplete
    case ProductItemStatus.Returned:
      return BoxSelect
    default:
      return null
  }
}

type OutletFormProps = FormikProps<FormFields>
const OutletForm: FC<OutletFormProps> = ({
  values,
  errors,
  setFieldValue,
  setValues,
  initialValues,
  handleSubmit,
}) => {
  const { formatMessage } = useIntl()
  const [batchSearch, setBatchSearch] = useState(initialValues.batchNumber ?? '')
  const [debouncedBatchSearch] = useDebouncedValue(batchSearch, 250, { initializeWithNull: false })
  const { data: batches = [] } = useBatches({
    batchNumber: (debouncedBatchSearch ?? '').length > 0 ? debouncedBatchSearch ?? '' : undefined,
  })

  const handleStatusChange = (status: ProductItemStatus) => {
    setValues({
      ...values,
      status,
      holderId: null,
    })
  }

  const HolderSelect = getHolderSelectComponent(values.status)

  return (
    <form className="p-24 max-w-2xl" onSubmit={handleSubmit}>
      <div className="flex flex-col">
        <LabeledSelect
          fullWidth
          label={formatMessage({ id: 'models.product-item.status' })}
          value={values.status}
          options={availableProductItems}
          optionTitle={(option) => formatMessage({ id: `product-item-statuses.${option}` })}
          onChange={(e) => handleStatusChange(e.target.value as ProductItemStatus)}
          error={!!errors.status}
        />
        {HolderSelect && (
          <HolderSelect
            value={values.holderId}
            onChange={(value) => setFieldValue('holderId', value)}
            error={!!errors.holderId}
          />
        )}
        <Autocomplete<Batch>
          fullWidth
          className="mt-16"
          filterOptions={(x) => x}
          options={batches}
          renderInput={(params) => (
            <TextField
              label={formatMessage({ id: 'models.product-item.batch' })}
              {...params}
              error={!!errors.batchId}
            />
          )}
          getOptionLabel={(batch) => batch.batchNumber}
          isOptionEqualToValue={(option, val) => option.id === val.id}
          onChange={(e, val) => setFieldValue('batchId', val?.id ?? null)}
          onInputChange={(e, inputValue) => setBatchSearch(inputValue)}
          value={batches.find((o) => o.id === values.batchId) ?? null}
        />
      </div>
    </form>
  )
}

export default OutletForm
