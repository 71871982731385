import { buildSortingContext } from '@web-panel/shared'

type SortingFields =
  | 'CREATE_DATE'
  | 'FILL_LEVEL'
  | 'BATTERY'
  | 'HEART_BEAT_DATE'
  | 'ITEMS'
  | 'LAST_SIGNAL_DATE'
  | 'STATUS'
  | 'OPERATIONS_LAST_VISIT_DATE'

export const { withSorting, useSorting } = buildSortingContext<SortingFields>()
