import { FC } from 'react'
import { FormSection, TextField } from '@web-panel/shared'
import { GeoCountry, OrderInvoiceRecipient } from '@web-panel/api'
import CountryCodeAutocomplete from '@local/components/Autocomplete/CountryCodeAutocomplete'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import * as Yup from 'yup'

export type InvoiceInfoSectionFormFields = {
  name: string
  invoiceCountry: string
  invoiceCity: string
  invoiceStreet: string
  invoiceHouseNumber: string
  invoicePostalCode: string
  invoiceAdditionalAddress: string
  invoiceRecipient: OrderInvoiceRecipient
  registrationId: string | null
  invoicesEmail: string
}

export const invoiceInfoSectionValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
  invoiceCountry: Yup.string().required(),
  invoiceCity: Yup.string().required(),
  invoiceStreet: Yup.string().required(),
  invoiceHouseNumber: Yup.string().required(),
  invoicePostalCode: Yup.string().required(),
  invoiceRecipient: Yup.string().required(),
  registrationId: Yup.string().nullable(),
  invoicesEmail: Yup.string().email().required(),
})

type InvoiceInfoSectionProps = {
  locationCountries: GeoCountry[]
}

export const InvoiceInfoSection: FC<InvoiceInfoSectionProps> = ({ locationCountries }) => {
  const { formatMessage } = useIntl()
  const { values, handleChange, setFieldValue, errors } =
    useFormikContext<InvoiceInfoSectionFormFields>()

  return (
    <FormSection title={formatMessage({ id: 'models.partner.invoice-info' })}>
      <TextField
        fullWidth
        className="mb-16"
        value={values.name}
        onChange={handleChange('name')}
        label={formatMessage({ id: 'models.partner.name' })}
        error={!!errors.name}
      />
      <CountryCodeAutocomplete
        fullWidth
        className="mb-16"
        enabledFor="billing"
        label={formatMessage({ id: 'models.partner.country' })}
        onChange={(countryCode) => setFieldValue('invoiceCountry', countryCode ?? '')}
        error={!!errors.invoiceCountry}
        value={values.invoiceCountry}
      />
      <TextField
        fullWidth
        className="mb-16"
        value={values.invoiceCity}
        onChange={handleChange('invoiceCity')}
        label={formatMessage({ id: 'models.partner.city' })}
        error={!!errors.invoiceCity}
      />
      <TextField
        fullWidth
        className="mb-16"
        value={values.invoiceStreet}
        onChange={handleChange('invoiceStreet')}
        label={formatMessage({ id: 'models.partner.street' })}
        error={!!errors.invoiceStreet}
      />
      <TextField
        fullWidth
        className="mb-16"
        value={values.invoiceHouseNumber}
        onChange={handleChange('invoiceHouseNumber')}
        label={formatMessage({ id: 'models.partner.house-number' })}
        error={!!errors.invoiceHouseNumber}
      />
      <TextField
        fullWidth
        className="mb-16"
        value={values.invoicePostalCode}
        onChange={handleChange('invoicePostalCode')}
        label={formatMessage({ id: 'models.partner.postal-code' })}
        error={!!errors.invoicePostalCode}
      />
      <TextField
        fullWidth
        className="mb-16"
        value={values.invoiceAdditionalAddress}
        onChange={handleChange('invoiceAdditionalAddress')}
        label={formatMessage({ id: 'models.partner.additional-address' })}
        error={!!errors.invoiceAdditionalAddress}
      />
      <TextField
        fullWidth
        className="mb-16"
        value={values.registrationId ?? ''}
        onChange={handleChange('registrationId')}
        label={formatMessage({ id: 'models.partner.registration-id' })}
        error={!!errors.registrationId}
      />
      <TextField
        fullWidth
        value={values.invoicesEmail ?? ''}
        onChange={handleChange('invoicesEmail')}
        label={formatMessage({ id: 'models.partner.invoices-email' })}
        error={!!errors.invoicesEmail}
      />
    </FormSection>
  )
}
