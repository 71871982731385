import { FC } from 'react'
import { DropdownButton } from '@web-panel/shared'
import { useIntl } from 'react-intl'

export const tabToDaysMap: Record<string, number | null> = {
  '0': 7,
  '1': 15,
  '2': 30,
  '3': null,
}

type DaysSelectorProps = {
  selected: string
  onSelect: (value: string) => void
}

export const DaysSelector: FC<DaysSelectorProps> = ({ selected, onSelect }) => {
  const { formatMessage } = useIntl()

  function selectorLabel(count: number | null) {
    if (!count) return formatMessage({ id: 'common.labels.all' })

    return formatMessage({ id: 'common.labels.days' }, { count })
  }

  const items = Object.keys(tabToDaysMap).map((id) => ({
    key: String(id),
    title: selectorLabel(tabToDaysMap[id]),
    onClick: () => onSelect(id),
  }))

  return (
    <DropdownButton
      title={selectorLabel(tabToDaysMap[selected])}
      menu={items}
      color="secondary"
      variant="contained"
      size="small"
    />
  )
}
