import { VFC } from 'react'
import { AppPage, CardedContent, Button, useNotifications } from '@web-panel/shared'
import { ConsumerInfo } from '@web-panel/api'
import { currencies } from '@local/constants/currencies'
import { Formik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { Error404 } from '@local/pages/404'
import { FormattedMessage, useIntl } from 'react-intl'
import { useConsumerInfo, useUpdateConsumerWallet } from '@web-panel/oc-api'
import { UpdateWalletForm, FormFields } from './Forms/UpdateWalletForm'

type UpdateWalletPageProps = {
  consumerId: string
  consumerInfo: ConsumerInfo
}

const UpdateWalletPage: VFC<UpdateWalletPageProps> = ({ consumerId, consumerInfo }) => {
  const { formatMessage } = useIntl()
  const { pushNotification } = useNotifications()
  const navigate = useNavigate()
  const { request: updateConsumerWallet } = useUpdateConsumerWallet()
  const currency = currencies[0]
  const wallet = consumerInfo.balance.find((w) => w.currency.code === currency)

  const initialValues: FormFields = {
    currencyCode: currencies[0],
    minimalAmount: wallet?.minimalAmount ?? 0,
  }

  const onSubmit = async (values: FormFields) => {
    try {
      await updateConsumerWallet({
        id: consumerId,
        input: {
          currencyCode: values.currencyCode,
          minimalAmount: values.minimalAmount,
        },
      })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
      navigate(-1)
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <Formik<FormFields> initialValues={initialValues} onSubmit={onSubmit}>
      {({ isValid, isSubmitting, handleSubmit }) => {
        const actionButtons = [
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleSubmit()}
            loading={isSubmitting}
          >
            <FormattedMessage id="common.actions.update" />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            title={formatMessage({ id: 'actions.update-wallet' })}
            actionButtons={actionButtons}
          >
            <CardedContent padded>
              <UpdateWalletForm wallets={consumerInfo.balance} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export default pageWithRoleAuthorization(
  () => {
    const params = useParams()
    const { data: consumerInfo, loading } = useConsumerInfo({ id: params.id! })
    if (loading) return null
    if (!consumerInfo) return <Error404 />

    return <UpdateWalletPage consumerId={params.id!} consumerInfo={consumerInfo} />
  },
  ({ can }) => can('consumerAccount', 'previewInfo') && can('consumerAccount', 'updateWallet')
)
