import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import QRCode from 'qrcode'
import blackLogo from '@local/assets/images/BlackLogo.svg'
import { useIntl } from 'react-intl'
import { useReactToPrint } from 'react-to-print'

import { generateExternalRoute } from '@local/pages/ExternalRouter/helpers'

import { useBatchItemModels } from '@web-panel/oc-api'

const labelHeight = 100
const labelWidth = 341
const labelPadding = 5
const fontSize = 14
const containerHeight = labelHeight - labelPadding * 2

export type BatchPhysicalLabelRef = {
  print: () => void
}

type BatchPhysicalLabelProps = {
  batchId: string
  batchHumanId: string
  itemsAmount: number
  itemsBrand: string
  createdBy: string
}

const pageStyle = `
  @media print {
    @page {
      size: 90.3mm 29mm;
      margin: 1.5mm 2mm;
    }
  }
`

const BatchPhysicalLabel = forwardRef<BatchPhysicalLabelRef, BatchPhysicalLabelProps>(
  ({ batchId, batchHumanId, itemsAmount, itemsBrand, createdBy }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const print = useReactToPrint({ content: () => containerRef.current, pageStyle })
    const [qrImage, setQrImage] = useState<string | null>(null)
    const { formatMessage } = useIntl()
    const { data: batchModels = [], loading: loadingBatchModels } = useBatchItemModels(
      { id: batchId },
      { fetchPolicy: 'network-only' }
    )

    const generateQrCode = async () => {
      const image = await QRCode.toDataURL(generateExternalRoute('batch', batchId), {
        errorCorrectionLevel: 'low',
        margin: 0,
        color: {
          dark: '#000000',
          light: '#ffffff',
        },
      })
      setQrImage(image)
    }

    useEffect(() => {
      generateQrCode()
    })

    useImperativeHandle(ref, () => ({
      print,
    }))

    if (!qrImage || loadingBatchModels) return null

    const infoLines = [
      `${formatMessage({ id: 'models.batch.id' })}: ${batchHumanId}`,
      `${formatMessage({ id: 'models.batch.items-number' })}: ${itemsAmount}`,
      `${formatMessage({ id: 'models.product-item.brand' })}: ${itemsBrand}`,
      `${formatMessage({ id: 'models.product-item.capacity' })}: ${batchModels
        .map((model) => [model.capacityValue, model.capacityUnit].join(' '))
        .join(', ')}`,
      `${formatMessage({ id: 'models.batch.created-by' })}: ${createdBy}`,
    ]

    const infoLineX = labelPadding + 50
    const infoLineMargin = (containerHeight - fontSize * infoLines.length) / (infoLines.length - 1)

    const getInfoLineY = (id: number) => {
      return id * (fontSize + infoLineMargin) + labelPadding
    }

    return (
      <div ref={containerRef}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${labelWidth} ${labelHeight}`}
          width={labelWidth}
          height={labelHeight}
        >
          <image
            href={blackLogo}
            width={labelHeight - labelPadding * 2}
            transform={`rotate(-90, ${labelPadding}, ${
              labelHeight - labelPadding
            }) translate(${labelPadding}, ${labelHeight - labelPadding})`}
          />
          {infoLines.map((infoLine, id) => (
            <text
              key={id}
              alignmentBaseline="hanging"
              fontSize={`${fontSize}px`}
              fontFamily="Inter, Roboto, Helvetica, Arial, sans-serif"
              x={infoLineX}
              y={getInfoLineY(id)}
            >
              {infoLine}
            </text>
          ))}
          <image
            href={qrImage}
            x={labelWidth - (labelHeight - labelPadding) - labelPadding}
            y={labelPadding}
            width={containerHeight}
            height={containerHeight}
          />
        </svg>
      </div>
    )
  }
)

export default BatchPhysicalLabel
