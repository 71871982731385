import { PropsWithChildren } from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material'

type LabeledSelectProps<Data> = {
  fullWidth?: boolean
  className?: string
  error?: boolean
  disabled?: boolean
  label?: string
  value: Data | ''
  options: Readonly<Data[]>
  displayEmpty?: SelectProps['displayEmpty']
  optionTitle?: (option: Data) => string
  optionDisabled?: (option: Data) => boolean
  onChange?: (event: SelectChangeEvent<Data>) => void
}

// eslint-disable-next-line react/function-component-definition
export function LabeledSelect<Data extends string | number>({
  fullWidth,
  className,
  error,
  disabled,
  label,
  value,
  options,
  displayEmpty,
  optionTitle,
  optionDisabled,
  onChange,
}: PropsWithChildren<LabeledSelectProps<Data>>) {
  return (
    <FormControl fullWidth={fullWidth} className={className} error={error}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        displayEmpty={displayEmpty}
        data-testid="LabeledSelect"
        disabled={disabled}
        label={label}
        value={value}
        onChange={(e) => onChange && onChange(e as SelectChangeEvent<Data>)}
      >
        {options.map((option) => {
          const isDisabled = optionDisabled ? optionDisabled(option) : false

          return (
            <MenuItem key={option} value={option} disabled={isDisabled}>
              {optionTitle ? optionTitle(option) : option}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
