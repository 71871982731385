import { FC, ReactNode } from 'react'
import { Typography, TypographyProps } from '@mui/material'
import classNames from 'classnames'

type FormSectionProps = {
  title?: string
  firstSection?: boolean
  buttons?: ReactNode[]
  containerClasses?: string
  titleSize?: 'medium' | 'large'
  titleWeight?: TypographyProps['fontWeight']
}

export const FormSection: FC<FormSectionProps> = ({
  title,
  firstSection = false,
  buttons,
  children,
  containerClasses,
  titleSize = 'medium',
  titleWeight = '700',
}) => {
  const fontVariant: TypographyProps['variant'] = titleSize === 'medium' ? 'overline' : 'h5'

  return (
    <div className={classNames({ 'mt-24': !firstSection })}>
      {title && (
        <div className="flex flex-row items-start">
          <Typography
            variant={fontVariant}
            color="GrayText"
            fontWeight={titleWeight}
            lineHeight={titleSize === 'large' ? '2rem' : undefined}
          >
            {title}
          </Typography>
          {buttons && buttons.length > 0 && <div className="ml-16">{buttons}</div>}
        </div>
      )}
      <div className={containerClasses ?? 'mt-8'}>{children}</div>
    </div>
  )
}
