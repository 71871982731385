import { useQuery } from '@apollo/client'
import { QueryHook, CollectionQueryHook } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  DonationDocument,
  DonationDocumentInput,
  DonationDocumentResponse,
  DonationsDocument,
  DonationsDocumentInput,
  DonationsDocumentResponse,
  ConsumerDonationsDocument,
  ConsumerDonationsDocumentInput,
  ConsumerDonationsDocumentResponse,
} from '../docs'

type UseDonationsInput = Omit<DonationsDocumentInput, 'limit' | 'offset'>

export const useDonations: CollectionQueryHook<
  UseDonationsInput,
  DonationsDocumentResponse['donationsCollection']['donations']
> = (input, options) => {
  const variables = { ...input, ...defaultCollectionVariables }

  const { data, loading, fetchMore, refetch } = useQuery<DonationsDocumentResponse>(
    DonationsDocument,
    { ...options, variables }
  )

  const loadMore = async () => {
    if (loading || !data || !data.donationsCollection.hasNext) return

    const offset = data.donationsCollection.donations.length
    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.donationsCollection.donations,
    loading,
    loadMore,
    hasMore: data?.donationsCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

type UseConsumerDonationsInput = Omit<ConsumerDonationsDocumentInput, 'limit' | 'offset'>
export const useConsumerDonations: CollectionQueryHook<
  UseConsumerDonationsInput,
  ConsumerDonationsDocumentResponse['consumerDonationsCollection']['donations']
> = (input, options) => {
  const variables = { ...input, ...defaultCollectionVariables }

  const { data, loading, fetchMore, refetch } = useQuery<ConsumerDonationsDocumentResponse>(
    ConsumerDonationsDocument,
    { variables, ...options }
  )

  const loadMore = async () => {
    if (loading || !data || !data.consumerDonationsCollection.hasNext) return

    const offset = data.consumerDonationsCollection.donations.length
    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.consumerDonationsCollection.donations,
    loading,
    loadMore,
    hasMore: data?.consumerDonationsCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useDonation: QueryHook<DonationDocumentInput, DonationDocumentResponse['donation']> = (
  variables,
  options
) => {
  const { data, loading, refetch } = useQuery<DonationDocumentResponse>(DonationDocument, {
    variables,
    ...options,
  })

  return {
    data: data?.donation,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}
