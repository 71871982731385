import { FC } from 'react'
import { TextField, LabeledSelect, FormSection } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import {
  DispenserLocationType,
  DispenserOperationType,
  DispenserStandType,
  DispenserStatus,
} from '@web-panel/api'
import { useFormikContext } from 'formik'
import * as Yup from 'yup'

export type EditDispenserFormFields = {
  coreId: string
  imei: string
  capacity: number
  status: DispenserStatus
  operationType: DispenserOperationType
  locationType: DispenserLocationType
  standType: DispenserStandType
}

export const editDispenserValidationSchema = Yup.object().shape({
  coreId: Yup.string().required(),
  imei: Yup.string().required(),
  capacity: Yup.number().min(1).required(),
  status: Yup.string().oneOf(Object.values(DispenserStatus)).required(),
  operationType: Yup.string().oneOf(Object.values(DispenserOperationType)).required(),
  locationType: Yup.string().oneOf(Object.values(DispenserLocationType)).required(),
  standType: Yup.string().oneOf(Object.values(DispenserStandType)).required(),
})

export const EditDispenserForm: FC = () => {
  const { formatMessage } = useIntl()
  const { values, handleChange, errors, handleSubmit } = useFormikContext<EditDispenserFormFields>()

  return (
    <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit}>
      <div className="flex flex-col p-16 sm:p-24 max-w-2xl">
        <FormSection firstSection>
          <TextField
            fullWidth
            value={values.coreId}
            onChange={handleChange('coreId')}
            label={formatMessage({ id: 'models.dispenser.core-id' })}
            error={!!errors.coreId}
          />
          <TextField
            fullWidth
            className="mt-16"
            value={values.imei}
            onChange={handleChange('imei')}
            label={formatMessage({ id: 'models.dispenser.imei' })}
            error={!!errors.imei}
          />
          <TextField
            fullWidth
            className="mt-16"
            type="number"
            value={values.capacity}
            onChange={handleChange('capacity')}
            label={formatMessage({ id: 'models.dispenser.capacity' })}
            error={!!errors.capacity}
          />
          <LabeledSelect
            fullWidth
            className="mt-16"
            value={values.status}
            options={Object.values(DispenserStatus)}
            optionTitle={(option) => formatMessage({ id: `dispenser-statuses.${option}` })}
            onChange={(e) => handleChange('status')(e.target.value)}
            label={formatMessage({ id: 'models.dispenser.status' })}
            error={!!errors.status}
          />
          <LabeledSelect
            fullWidth
            className="mt-16"
            value={values.operationType}
            options={Object.values(DispenserOperationType)}
            optionTitle={(option) => formatMessage({ id: `dispenser-operation-types.${option}` })}
            onChange={(e) => handleChange('operationType')(e.target.value)}
            label={formatMessage({ id: 'models.dispenser.operation-type' })}
            error={!!errors.operationType}
          />
          <LabeledSelect
            fullWidth
            className="mt-16"
            value={values.locationType}
            options={Object.values(DispenserLocationType)}
            optionTitle={(option) => formatMessage({ id: `dispenser-location-types.${option}` })}
            onChange={(e) => handleChange('locationType')(e.target.value)}
            label={formatMessage({ id: 'models.dispenser.location-type' })}
            error={!!errors.locationType}
          />
          <LabeledSelect
            fullWidth
            className="mt-16"
            value={values.standType}
            options={Object.values(DispenserStandType)}
            optionTitle={(option) => formatMessage({ id: `dispenser-stand-types.${option}` })}
            onChange={(e) => handleChange('standType')(e.target.value)}
            label={formatMessage({ id: 'models.dispenser.stand-type' })}
            error={!!errors.standType}
          />
        </FormSection>
      </div>
    </form>
  )
}
