import { FC } from 'react'
import { LinearProgress, Box } from '@mui/material'

type ProgressBarProps = {
  show: boolean
}

const ProgressBar: FC<ProgressBarProps> = ({ show }) => {
  if (!show) return null

  return (
    <Box className="absolute top-0 left-0 right-0">
      <LinearProgress color="secondary" />
    </Box>
  )
}

export default ProgressBar
