import { FC } from 'react'
import { Card, CardContent } from '@mui/material'
import { motion } from 'framer-motion'
import { getAsset } from '../../registrations'

export const AuthLeftSection: FC = ({ children }) => {
  const logo = getAsset('logo')

  return (
    <Card
      className="Login-leftSection flex flex-col w-full max-w-sm items-center justify-center shadow-0"
      square
    >
      <CardContent className="flex flex-col items-center justify-center w-full py-96 max-w-320">
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
          <div className="flex justify-center mb-48">
            <img className="logo-icon w-full" src={logo} alt="logo" />
          </div>
        </motion.div>
        {children}
      </CardContent>
    </Card>
  )
}
