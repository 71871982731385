import { FC, useRef, useState } from 'react'
import {
  Button,
  ConfirmationDialog,
  ConfirmationDialogRef,
  useNotifications,
} from '@web-panel/shared'

import { FormattedMessage, useIntl } from 'react-intl'

import { useRequestVaultToken } from '@web-panel/oc-api'

type OpenVaultButtonProps = {
  target: string
  vaultUrl: string
}

const OpenVaultButton: FC<OpenVaultButtonProps> = ({ target, vaultUrl }) => {
  const dialogRef = useRef<ConfirmationDialogRef>(null)
  const [link, setLink] = useState('')
  const { request: requestVaultToken, loading: vaultTokenLoading } = useRequestVaultToken()
  const { pushNotification } = useNotifications()
  const { formatMessage } = useIntl()

  const onOpenVault = async () => {
    if (!vaultUrl) return

    try {
      const token = await requestVaultToken()
      const url = new URL(vaultUrl)
      url.searchParams.set('magic_token', token)
      setLink(url.toString())
      const popup = window.open(url.toString(), target)
      if (!popup) {
        dialogRef.current?.open()
      }
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <>
      <Button onClick={onOpenVault} loading={vaultTokenLoading} variant="outlined" color="primary">
        <FormattedMessage id="common.actions.open" />
      </Button>
      <ConfirmationDialog
        ref={dialogRef}
        title={formatMessage({ id: 'confirmation-dialogs.open-vault.title' })}
        acceptTitle={formatMessage({ id: 'common.yes' })}
        cancelTitle={formatMessage({ id: 'common.no' })}
        onSubmit={() => window.open(link.toString(), target)}
      />
    </>
  )
}

export default OpenVaultButton
