import { FC } from 'react'
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { PreviewProps } from './types'
import { daysOfWeek } from './consts'
import { getHoursPresentation } from './helpers'

export const HorizontalPreview: FC<PreviewProps> = ({ data, dayLabels, timeFrameLabels }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">{dayLabels.monday}</TableCell>
            <TableCell align="center">{dayLabels.tuesday}</TableCell>
            <TableCell align="center">{dayLabels.wednesday}</TableCell>
            <TableCell align="center">{dayLabels.thursday}</TableCell>
            <TableCell align="center">{dayLabels.friday}</TableCell>
            <TableCell align="center">{dayLabels.saturday}</TableCell>
            <TableCell align="center">{dayLabels.sunday}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell variant="head">{timeFrameLabels.opening}</TableCell>
            {daysOfWeek.map((id) => {
              const hours = data.find(({ dayOfWeek }) => dayOfWeek === id)
              return (
                <TableCell key={id} align="center">
                  {getHoursPresentation(hours?.openTime)}
                </TableCell>
              )
            })}
          </TableRow>
          <TableRow>
            <TableCell variant="head">{timeFrameLabels.closing}</TableCell>
            {daysOfWeek.map((id) => {
              const hours = data.find(({ dayOfWeek }) => dayOfWeek === id)
              return (
                <TableCell key={id} align="center">
                  {getHoursPresentation(hours?.closeTime)}
                </TableCell>
              )
            })}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
