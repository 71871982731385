import { useQuery } from '@apollo/client'
import { CollectionQueryHook, Tracking } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import { TrackingDocument, TrackingDocumentInput, TrackingDocumentResponse } from '../docs'

export const useTracking: CollectionQueryHook<TrackingDocumentInput, Tracking[]> = (
  input,
  options
) => {
  const variables = {
    ...defaultCollectionVariables,
    ...input,
  }
  const { data, loading, refetch, fetchMore } = useQuery<TrackingDocumentResponse>(
    TrackingDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data?.trackingHistory.hasNext) return

    const offset = data.trackingHistory.trackingDetails.length

    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.trackingHistory.trackingDetails,
    loading,
    loadMore,
    hasMore: data?.trackingHistory.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}
