import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AppPage, Button, InfinitScroller, useModalRef } from '@web-panel/shared'
import modelIcons from '@local/model-icons'
import { Error404 } from '@local/pages/404'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { useGroupLabel, useGroupLabelLabels } from '@web-panel/oc-api'
import { FormattedMessage } from 'react-intl'
import { GroupLabelStatus } from '@web-panel/api'
import PreviewTable from './Tables/PreviewTable'
import ActivateGroupLabelModalConfirmation from './Modals/ActivateGroupLabelModalConfirmation'

const PreviewPage: FC = () => {
  const params = useParams()
  const activationConfirmationModalRef = useModalRef()
  const [showActiveButton, setShowActiveButton] = useState<boolean>(true)

  const { data: groupLabel } = useGroupLabel({
    id: params.id!,
  })

  const {
    data: labels = [],
    loading: loadingGroupLabel,
    loadMore,
    refetch,
  } = useGroupLabelLabels(
    {
      groupLabelId: params.id!,
    },
    { fetchPolicy: 'no-cache' }
  )

  if (loadingGroupLabel) return null
  if (!labels) return <Error404 />

  const actionButtons = [
    <Button
      fullWidth
      variant="contained"
      color="secondary"
      onClick={() => activationConfirmationModalRef.current?.open()}
    >
      <FormattedMessage id="common.actions.activate" />
    </Button>,
  ]

  const labelTitle = `${groupLabel?.externalId} ${groupLabel?.sourceShipmentSequence?.name ?? ''}`

  const handleOnActivated = () => {
    setShowActiveButton(false)
    refetch()
  }

  return (
    <AppPage
      backButton
      loading={loadingGroupLabel}
      icon={modelIcons.groupLabel}
      actionButtons={
        showActiveButton && groupLabel?.status === GroupLabelStatus.Idle ? actionButtons : undefined
      }
      title={labelTitle}
    >
      <InfinitScroller onLoadMore={loadMore}>
        <PreviewTable data={labels} loading={loadingGroupLabel} />
      </InfinitScroller>
      <ActivateGroupLabelModalConfirmation
        ref={activationConfirmationModalRef}
        groupLabel={groupLabel!}
        onActivated={handleOnActivated}
      />
    </AppPage>
  )
}

export default pageWithRoleAuthorization(PreviewPage, ({ can }) => can('groupLabels', 'labelsList'))
