import { MouseEventHandler, ReactNode, useMemo } from 'react'
import { Table, TableRow, TableBody, Typography, TableContainer } from '@mui/material'
import classNames from 'classnames'
import BodyCell from './BodyCell'
import { Field } from './types'
import { TableGridHead } from './TableGridHead'
import { RowActionsButton, RowAction } from './RowActionsButton'

type TableGridProps<Data> = {
  className?: string
  collection: Data[]
  fields: Field<Data>[]
  loading?: boolean
  onClickRow?: (item: Data) => void
  rowClickable?: boolean | ((item: Data) => boolean)
  rowActions?: RowAction<Data>[]
  emptyLabel?: string
  addItemButton?: ReactNode
  addItemButtonAppearance?: 'top' | 'bottom' | 'all'
}

// eslint-disable-next-line react/function-component-definition
export function TableGrid<Data>({
  className,
  collection,
  fields,
  loading = false,
  rowClickable = true,
  onClickRow,
  rowActions,
  emptyLabel,
  addItemButton,
  addItemButtonAppearance = 'all',
}: TableGridProps<Data>) {
  const renderableFields = useMemo(() => {
    return fields.filter(({ isRenderable }) => isRenderable !== false)
  }, [fields])

  if (collection.length === 0)
    return (
      <>
        <TableContainer>
          <Table className={className}>
            <TableGridHead fields={fields} />
          </Table>
        </TableContainer>
        {!loading && (emptyLabel || addItemButton) && (
          <div className="flex w-full justify-center">
            <Typography variant="h6" color="GrayText" className="my-48">
              {addItemButton ?? emptyLabel}
            </Typography>
          </div>
        )}
      </>
    )

  const handleRowClick = (item: Data): MouseEventHandler<HTMLTableRowElement> | undefined => {
    return () => {
      const selectedText = !!getSelection()?.toString()
      if (rowClickable && onClickRow && !selectedText) onClickRow(item)
    }
  }

  return (
    <>
      {collection.length > 0 &&
        addItemButton &&
        (addItemButtonAppearance === 'all' || addItemButtonAppearance === 'top') && (
          <div className="flex px-16 md:px-0 pt-12 md:pt-0  w-full justify-end">
            {addItemButton}
          </div>
        )}
      <TableContainer>
        <Table className={className}>
          <TableGridHead fields={renderableFields} hasRowActions={!!rowActions} />
          <TableBody>
            {collection.map((item, rowId) => {
              const isRowClickable =
                typeof rowClickable === 'boolean' ? rowClickable : rowClickable(item)

              return (
                <TableRow
                  key={rowId}
                  hover={!!onClickRow}
                  className={classNames({ 'cursor-pointer': onClickRow && isRowClickable })}
                  onClick={handleRowClick(item)}
                >
                  {renderableFields.map(({ render, align, hiddenMobile }, cellId) => (
                    <BodyCell key={`${rowId}-${cellId}`} align={align} hiddenMobile={hiddenMobile}>
                      {render(item)}
                    </BodyCell>
                  ))}
                  {rowActions && (
                    <BodyCell key={`${rowId}-${renderableFields.length}`} hiddenMobile>
                      <RowActionsButton data={item} actions={rowActions} />
                    </BodyCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {collection.length > 0 &&
        addItemButton &&
        (addItemButtonAppearance === 'all' || addItemButtonAppearance === 'bottom') && (
          <div className="flex px-16 md:px-0 py-12 md:pb-0 w-full justify-end">{addItemButton}</div>
        )}
    </>
  )
}
