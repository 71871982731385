import { FC } from 'react'
import GoogleMap from 'google-map-react'
import Marker from './Marker'

const defaultZoom = 20

type Coordinates = {
  lat: number
  lng: number
}

type PinDropProps = {
  mapInitialCoordinates: Coordinates
  markerCoordinates: Coordinates
  onChange?: (coordinates: Coordinates) => void
}

export const PinDrop: FC<PinDropProps> = ({
  mapInitialCoordinates,
  markerCoordinates,
  onChange,
}) => {
  return (
    <div className="relative w-full h-full">
      <GoogleMap
        draggable
        bootstrapURLKeys={{ key: 'AIzaSyB_alb91_Qxd45IGwGIa-wwrkYsZ5NASXY' }}
        defaultCenter={mapInitialCoordinates}
        defaultZoom={defaultZoom}
        style={{ width: '100%', height: '300px' }}
        onDrag={({ center }) =>
          onChange &&
          onChange({
            lat: center.lat(),
            lng: center.lng(),
          })
        }
      />
      <Marker />
    </div>
  )
}
