import { FC } from 'react'
import { Outlet } from '@web-panel/api'
import { Button, ConfirmableButton, useModalRef, useNotifications } from '@web-panel/shared'
import { OutletAvatar } from '@local/components/OutletAvatar'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDeleteOutletLogo, usePartner } from '@web-panel/oc-api'
import UpdateOutletLogoModal from '../Modals/UpdateOutletLogo'

type PreviewLogoProps = {
  outlet: Outlet
}

const PreviewLogo: FC<PreviewLogoProps> = ({ outlet }) => {
  const modalRef = useModalRef()
  const { request: deleteOutletLogo, loading } = useDeleteOutletLogo()
  const { data: partner } = usePartner({ id: outlet.partnerId })
  const { pushNotification } = useNotifications()
  const { formatMessage } = useIntl()

  if (!partner) return null

  const handleLogoDelete = async () => {
    try {
      await deleteOutletLogo({ id: outlet.id })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-deleted' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <>
      <OutletAvatar variant="preview" outlet={outlet} />
      <div className="flex flex-row mt-16">
        <Button variant="contained" onClick={() => modalRef.current?.open()}>
          <FormattedMessage id="common.actions.edit" />
        </Button>
        {outlet.logo && outlet.logo !== partner?.logo && (
          <ConfirmableButton
            className="ml-16"
            variant="contained"
            color="error"
            onClick={handleLogoDelete}
            loading={loading}
            confirmation={{
              title: formatMessage({ id: 'confirmation-dialogs.delete-logo.title' }),
              message: formatMessage({ id: 'confirmation-dialogs.delete-logo.message' }),
              cancelTitle: formatMessage({ id: 'common.no' }),
              acceptTitle: formatMessage({ id: 'common.yes' }),
            }}
          >
            <FormattedMessage id="common.actions.delete" />
          </ConfirmableButton>
        )}
      </div>
      <UpdateOutletLogoModal ref={modalRef} outletId={outlet.id} />
    </>
  )
}

export default PreviewLogo
