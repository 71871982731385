import { FC } from 'react'
import { Consumer } from '@web-panel/api'
import { HorizontalTable, HorizontalTableRow, useFormatDate } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { UserStatusChip } from '@local/components/UserStatusChip'

type GeneralTabProps = {
  consumer: Consumer
}

export const GeneralTab: FC<GeneralTabProps> = ({ consumer }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()

  return (
    <HorizontalTable>
      <HorizontalTableRow title={formatMessage({ id: 'models.user.id' })}>
        {consumer.userNumber}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.user.name' })}>
        {[consumer.firstName, consumer.lastName].join(' ')}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.user.email' })}>
        {consumer.email}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.user.phone' })}>
        {consumer.phone}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.user.status' })}>
        <UserStatusChip status={consumer.status} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.user.create-date' })}>
        {formatDate('datetime', consumer.createDate)}
      </HorizontalTableRow>
    </HorizontalTable>
  )
}
