import { VFC } from 'react'
import boxIllustration from '@local/assets/illustrations/box-illustration.svg'
import { Button } from '@web-panel/shared'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import logo from '@local/assets/images/mobile-logo.svg'
import { usePublicBox } from '@web-panel/oc-api'

const aboutUrl = 'https://kooky2go.com/'

const PublicBoxReport: VFC = () => {
  const { boxId } = useParams<{ boxId: string }>()
  const { data: publicBox } = usePublicBox({ id: boxId! })

  return (
    <div className="w-full">
      <div className="flex flex-col items-center">
        <img src={logo} alt="logo" />
        <div className="mt-40">
          <img src={boxIllustration} alt="box illustration" />
        </div>
      </div>
      <div className="mt-36 flex flex-col gap-24">
        <Button
          fullWidth
          size="large"
          variant="contained"
          sx={{
            backgroundColor: '#0B3C4D',
          }}
          onClick={() => {
            window.open(aboutUrl, '_blank')
          }}
        >
          <FormattedMessage id="pages.public-box-report.about-kooky" />
        </Button>
        <Button
          fullWidth
          size="large"
          variant="contained"
          sx={{
            backgroundColor: '#0B3C4D',
          }}
          disabled={!publicBox?.vaultPublicUrl}
          onClick={() => {
            if (!publicBox?.vaultPublicUrl) return
            window.location.href = publicBox.vaultPublicUrl
          }}
        >
          <FormattedMessage id="pages.public-box-report.report-box-problem" />
        </Button>
      </div>
    </div>
  )
}

export default PublicBoxReport
