import { gql } from '@apollo/client'
import { GeoCity, geoCityFields, GeoCityStatus, GeoCountry, geoCountryFields } from '@web-panel/api'

export type UpdateGeoCityDocumentInput = {
  code: string
  input: Pick<GeoCity, 'name' | 'latitude' | 'longitude' | 'status'>
}
export type UpdateGeoCityDocumentResponse = {
  updateCity: GeoCity
}
export const UpdateGeoCityDocument = gql`
  mutation UpdateCity($code: String!, $input: any) {
    updateCity(code: $code, input: $input) @rest(type: "GeoCity", path: "/location-service/cities/{args.code}", method: "PUT") {
      ${geoCityFields}
    }
  }
`

export type CreateGeoCityDocumentInput = {
  input: {
    code: string
    name: string
    countryCode: string
    latitude: number
    longitude: number
    status: GeoCityStatus
  }
}
export type CreateGeoCityDocumentResponse = {
  createCity: GeoCity
}
export const CreateGeoCityDocument = gql`
  mutation CreateCity($code: String!, $input: any) {
    createCity(code: $code, input: $input) @rest(type: "GeoCity", path: "/location-service/cities", method: "POST") {
      ${geoCityFields}
    }
  }
`

export type UpdateGeoCountryDocumentInput = {
  code: string
  input: Pick<GeoCountry, 'code' | 'name' | 'enabledForLocation' | 'enabledForBilling'>
}
export type UpdateGeoCountryDocumentResponse = {
  updateCountry: GeoCountry
}
export const UpdateGeoCountryDocument = gql`
  mutation UpdateCountry($code: String!, $input: any) {
    updateCountry(code: $code, input: $input) @rest(type: "GeoCountry", path: "/location-service/countries/{args.code}", method: "PUT") {
      ${geoCountryFields}
    }
  }
`

export type UpdateGeoCityIconDocumentInput = {
  code: string
  input: {
    image: File
  }
  bodySerializer?: (data: { image: File }, headers: Headers) => { body: FormData; headers: Headers }
}
export type UpdateGeoCityIconDocumentResponse = {
  updateGeoCityIcon: GeoCity
}
export const UpdateGeoCityIconDocument = gql`
  mutation UpdateGeoCityIcon($code: String!, $input: any, $bodySerializer: any) {
    updateGeoCityIcon(code: $code, input: $input) @rest(type: "GeoCity", path: "/location-service/cities/{args.code}/icon", method: "PUT", bodySerializer: $bodySerializer) {
      ${geoCityFields}
    }
  }
`

export type DeleteGeoCityIconDocumentInput = {
  code: string
}
export type DeleteGeoCityIconDocumentResponse = {
  deleteGeoCityIcon: GeoCity
}

export const DeleteGeoCityIconDocument = gql`
  mutation DeleteGeoCityIcon($code: String!) {
    deleteGeoCityIcon(code: $code) @rest(type: "GeoCity", path: "/location-service/cities/{args.code}/icon", method: "DELETE") {
      ${geoCityFields}
    }
  }
`
