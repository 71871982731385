import { forwardRef, useRef, useImperativeHandle } from 'react'
import Cropper from 'react-cropper'

const BLOB_TYPE = 'image/jpeg'

export type ImageCropperRef = {
  getCroppedAsDataUrl: () => string
  getCroppedAsBlob: () => Promise<Blob>
  getCroppedAsFile: (fileName?: string) => Promise<File>
}

type ImageCropperProps = {
  src: string
}

export const ImageCropper = forwardRef<ImageCropperRef, ImageCropperProps>(({ src }, ref) => {
  const cropperRef = useRef<HTMLImageElement>(null)

  const getCroppedCanvas = (): HTMLCanvasElement => {
    if (!cropperRef.current) throw Error('ImageCropper is not ready')

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { cropper } = cropperRef.current as unknown as any
    return cropper.getCroppedCanvas()
  }

  const getCroppedAsDataUrl = () => {
    const croppedCanvas = getCroppedCanvas()
    return croppedCanvas.toDataURL()
  }

  const getCroppedAsBlob = (): Promise<Blob> => {
    const croppedCanvas = getCroppedCanvas()
    return new Promise<Blob>((resolve, reject) => {
      croppedCanvas.toBlob((blob) => (blob ? resolve(blob) : reject()), BLOB_TYPE)
    })
  }

  const getCroppedAsFile = async (fileName = 'cropped-file.jpeg'): Promise<File> => {
    const blob = await getCroppedAsBlob()
    return new File([blob], fileName, { type: BLOB_TYPE })
  }

  useImperativeHandle(ref, () => ({
    getCroppedAsDataUrl,
    getCroppedAsBlob,
    getCroppedAsFile,
  }))

  return (
    <Cropper
      src={src}
      ref={cropperRef}
      guides={false}
      initialAspectRatio={1}
      aspectRatio={1}
      scalable={false}
      zoomable={false}
      checkOrientation
      center={false}
      viewMode={1}
      cropBoxMovable={false}
      cropBoxResizable={false}
      autoCropArea={1}
      dragMode="move"
      background={false}
      modal={false}
    />
  )
})
