import { FC, useRef } from 'react'
import { Batch } from '@web-panel/api'
import { Button } from '@web-panel/shared'
import { FormattedMessage } from 'react-intl'
import { useBatchItems, useUser } from '@web-panel/oc-api'
import BatchPhysicalLabel, { BatchPhysicalLabelRef } from './BatchPhysicalLabel'

type PrintPhysicalLabelButtonProps = {
  batch: Batch
}

const PrintPhysicalLabelButton: FC<PrintPhysicalLabelButtonProps> = ({ batch }) => {
  const labelRef = useRef<BatchPhysicalLabelRef>(null)
  const { data: batchItems = [], loading: loadingBatchItems } = useBatchItems({ id: batch.id })
  const { data: user, loading: loadingUser } = useUser({ id: batch.createdBy ?? '-1' })

  const loading = loadingBatchItems || loadingUser

  const itemsBrand = ((batchItems ?? [])[0] ?? {}).brand ?? ''
  const createdByInitials = user
    ? [user.firstName?.toUpperCase()[0] ?? '', user.lastName?.toUpperCase()[0] ?? ''].join('')
    : ''

  return (
    <>
      <Button
        fullWidth
        loading={loading}
        variant="contained"
        color="secondary"
        onClick={() => labelRef.current?.print()}
      >
        <FormattedMessage id="pages.product-item-batches.header-actions.print" />
      </Button>
      <div className="hidden">
        <BatchPhysicalLabel
          ref={labelRef}
          batchId={batch.id}
          batchHumanId={batch.batchNumber}
          itemsAmount={batch.itemsNumber}
          itemsBrand={itemsBrand}
          createdBy={createdByInitials}
        />
      </div>
    </>
  )
}

export default PrintPhysicalLabelButton
