import { FC } from 'react'
import { Invoice, OrderInvoiceStatus } from '@web-panel/api'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useIntl } from 'react-intl'
import { DropdownButton, useNotifications } from '@web-panel/shared'
import {
  OrderInvoiceStatusChip,
  statusColorMapping,
  useTranslateStatus,
} from '@local/components/OrderInvoiceStatusChip'
import _xor from 'lodash/xor'
import { useUpdateInvoice } from '@web-panel/oc-api'

type ChangeInvoiceStatusButtonProps = {
  invoice: Invoice
}

export const ChangeInvoiceStatusButton: FC<ChangeInvoiceStatusButtonProps> = ({ invoice }) => {
  const { formatMessage } = useIntl()
  const { cannot, loading } = useRoleAuthorization()
  const { request: updateInvoice, loading: loadingUpdate } = useUpdateInvoice()
  const { pushNotification } = useNotifications()
  const translateStatus = useTranslateStatus()

  if (loading) return null

  if (cannot('user', 'update')) return <OrderInvoiceStatusChip status={invoice.status} />

  const handleChangeStatus = async (status: OrderInvoiceStatus) => {
    try {
      await updateInvoice({ id: invoice.id, input: { status } })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const availableStatuses = _xor(Object.values(OrderInvoiceStatus), [invoice.status])

  const menu = availableStatuses.map((status) => ({
    title: translateStatus(status),
    onClick: () => handleChangeStatus(status),
  }))

  const color = statusColorMapping[invoice.status] ?? 'default'

  return (
    <DropdownButton
      variant="contained"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      color={color}
      title={translateStatus(invoice.status)}
      loading={loadingUpdate}
      menu={menu}
    />
  )
}
