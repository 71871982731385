import { FC } from 'react'
import { Button, useModalRef, useNotifications } from '@web-panel/shared'
import { InventoryTaskStatus, Box } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'
import { RoleAuthorized } from '@local/components/RoleAuthorized'
import { useNavigate } from 'react-router-dom'
import { useResetBoxItems, useBoxTasks } from '@web-panel/oc-api'
import { BoxUnfinishedTasksWarningModal } from './BoxUnfinishedTasksWarningModal'

type EmptyBoxButtonProps = {
  box: Box
}
export const EmptyBoxButton: FC<EmptyBoxButtonProps> = ({ box }) => {
  const modalRef = useModalRef()
  const { request: resetBoxItems, loading: loadingResetBoxItems } = useResetBoxItems()
  const { data: tasks = [], loading: loadingTasks } = useBoxTasks(
    { boxId: box.id },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()
  const { formatMessage } = useIntl()

  const isDisabled = (tasks.length === 0 && loadingTasks) || box.cups === 0

  const onClick = async () => {
    const unfinishedTasks = tasks.filter(({ status }) =>
      [InventoryTaskStatus.Created, InventoryTaskStatus.Pending].includes(status)
    )

    if (unfinishedTasks.length > 0) {
      modalRef.current?.open()
      return
    }

    try {
      await resetBoxItems({ boxId: box.id })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const onModalSubmit = async () => {
    try {
      await resetBoxItems({ boxId: box.id })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
      navigate('#tasks')
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <RoleAuthorized resource="box" action="resetBoxItems">
      <Button
        disabled={isDisabled}
        variant="contained"
        color="secondary"
        loading={loadingResetBoxItems}
        onClick={onClick}
      >
        <FormattedMessage
          id="common.actions.empty-with-name"
          values={{
            name: formatMessage({ id: 'models.box' }),
          }}
        />
      </Button>
      <BoxUnfinishedTasksWarningModal ref={modalRef} boxId={box.id} onSubmit={onModalSubmit} />
    </RoleAuthorized>
  )
}
