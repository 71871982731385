import { useMutation, useQuery } from '@apollo/client'
import { CollectionQueryHook, QueryHook, MutationHook } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  ConsumerPaymentOutsDocument,
  ConsumerPaymentOutsDocumentInput,
  ConsumerPaymentOutsDocumentResponse,
  PaymentOutDocument,
  PaymentOutDocumentInput,
  PaymentOutDocumentResponse,
  PaymentOutsDocument,
  PaymentOutsDocumentInput,
  PaymentOutsDocumentResponse,
  PaymentOutsStatusAggregationDocument,
  PaymentOutsStatusAggregationDocumentInput,
  PaymentOutsStatusAggregationDocumentResponse,
  UpdatePaymentOutDocument,
  UpdatePaymentOutDocumentInput,
  UpdatePaymentOutDocumentResponse,
} from '../docs'

type UsePaymentOutInput = Omit<PaymentOutsDocumentInput, 'limit' | 'offset'>
export const usePaymentOuts: CollectionQueryHook<
  UsePaymentOutInput,
  PaymentOutsDocumentResponse['paymentOuts']['payments']
> = (input, options) => {
  const variables = {
    ...defaultCollectionVariables,
    ...input,
  }
  const { data, loading, refetch, fetchMore } = useQuery<PaymentOutsDocumentResponse>(
    PaymentOutsDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.paymentOuts.hasNext) return

    const offset = data.paymentOuts.payments.length

    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.paymentOuts.payments,
    loading,
    loadMore,
    hasMore: data?.paymentOuts.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

type UseConsumerPaymentOutInput = Omit<ConsumerPaymentOutsDocumentInput, 'limit' | 'offset'>
export const useConsumerPaymentOuts: CollectionQueryHook<
  UseConsumerPaymentOutInput,
  ConsumerPaymentOutsDocumentResponse['consumerPaymentOuts']['payments']
> = (input, options) => {
  const variables = {
    ...defaultCollectionVariables,
    ...input,
  }
  const { data, loading, refetch, fetchMore } = useQuery<ConsumerPaymentOutsDocumentResponse>(
    ConsumerPaymentOutsDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.consumerPaymentOuts.hasNext) return

    const offset = data.consumerPaymentOuts.payments.length

    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.consumerPaymentOuts.payments,
    loading,
    loadMore,
    hasMore: data?.consumerPaymentOuts.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const usePaymentOut: QueryHook<
  PaymentOutDocumentInput,
  PaymentOutDocumentResponse['paymentOut']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<PaymentOutDocumentResponse>(PaymentOutDocument, {
    ...options,
    variables,
  })

  return {
    data: data?.paymentOut,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useUpdatePaymentOut: MutationHook<
  UpdatePaymentOutDocumentInput,
  UpdatePaymentOutDocumentResponse['updatePaymentOut'] | null
> = (options) => {
  const [execute, { loading }] = useMutation<UpdatePaymentOutDocumentResponse>(
    UpdatePaymentOutDocument,
    options
  )

  const request = async (variables: UpdatePaymentOutDocumentInput) => {
    const { data } = await execute({ variables })

    return data?.updatePaymentOut ?? null
  }

  return {
    request,
    loading,
  }
}

export const usePaymentOutStatusAggregation: QueryHook<
  PaymentOutsStatusAggregationDocumentInput['params'],
  PaymentOutsStatusAggregationDocumentResponse['paymentOutsStatusAggregation']['withdrawalCountByStatuses']
> = (params, options) => {
  const { data, loading, refetch } = useQuery<PaymentOutsStatusAggregationDocumentResponse>(
    PaymentOutsStatusAggregationDocument,
    { ...options, variables: { params } }
  )

  return {
    data: data?.paymentOutsStatusAggregation.withdrawalCountByStatuses,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}
