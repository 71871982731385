import { FC } from 'react'
import { Batch, BatchStatus } from '@web-panel/api'
import {
  Button,
  HorizontalTable,
  HorizontalTableRow,
  useFormatDate,
  useNotifications,
} from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { OrderLink } from '@local/components/OrderLink'
import { UserLink } from '@local/components/UserLink'
import { OutletLink } from '@local/components/OutletLink'
import { BatchStatusChip } from '@local/components/BatchStatusChip'
import { ContainerLink } from '@local/components/ContainerLink'
import { RoleAuthorized } from '@local/components/RoleAuthorized'

import { useRemoveContainerFromBatch } from '@web-panel/oc-api'

type BatchInfoTableProps = {
  data: Batch
}

const BatchInfoTable: FC<BatchInfoTableProps> = ({ data }) => {
  const { formatMessage } = useIntl()
  const { pushNotification } = useNotifications()
  const formatDate = useFormatDate()
  const { request: removeContainer } = useRemoveContainerFromBatch()

  const handleRemoveContainer = async () => {
    try {
      await removeContainer({ batchId: data.id })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-deleted' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <HorizontalTable>
      <HorizontalTableRow title={formatMessage({ id: 'models.batch.id' })}>
        {data.batchNumber}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.batch.delivered-by' })}>
        {data.deliveredBy && <UserLink userId={data.deliveredBy} />}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.batch.delivered-outlet' })}>
        {data.deliveredOutletId && <OutletLink outletId={data.deliveredOutletId} />}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.batch.delivery-date' })}>
        {data.deliveryDate && formatDate('datetime', data.deliveryDate)}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.batch.order-id' })}>
        {data.orderId && <OrderLink orderId={data.orderId} />}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.batch.status' })}>
        <BatchStatusChip status={data.status} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.batch.created-by' })}>
        {data.createdBy && <UserLink userId={data.createdBy} />}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.batch.create-date' })}>
        {formatDate('datetime', data.createDate)}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.batch.container' })}>
        {data.containerId && (
          <div className="flex flex-row gap-8 items-center">
            <ContainerLink containerId={data.containerId} />
            <RoleAuthorized resource="productItemBatch" action="removeContainer">
              {data.status !== BatchStatus.Delivered && (
                <Button variant="contained" color="error" onClick={handleRemoveContainer}>
                  <FormattedMessage id="common.actions.delete" />
                </Button>
              )}
            </RoleAuthorized>
          </div>
        )}
      </HorizontalTableRow>
    </HorizontalTable>
  )
}

export default BatchInfoTable
