import { FC } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'

import { DefaultErrorPage, AppPage, CardedContent, Button, TabSections } from '@web-panel/shared'
import modelIcons from '@local/model-icons'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import { PhysicalLabelButton } from '@local/components/PhysicalLabelButton'
import { useBox } from '@web-panel/oc-api'
import GeneralTab from './Tabs/General'
import ActivityTab from './Tabs/Activity'
import TasksTab from './Tabs/Tasks'
import { generateExternalRoute } from '../ExternalRouter/helpers'

const PreviewPage: FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const params = useParams()
  const { data: box, loading, refetch } = useBox({ id: params.id ?? '' })
  const { can } = useRoleAuthorization()

  if (loading) return null
  if (!box)
    return (
      <DefaultErrorPage
        title={formatMessage({ id: 'errors.404.title' })}
        message={formatMessage({ id: 'errors.404.message' })}
      />
    )

  const headerActionButtons = [
    <PhysicalLabelButton
      title={formatMessage({ id: 'common.actions.print-physical-label' })}
      printer={{
        title: formatMessage({ id: 'models.box' }),
        subtitle: box.coreId,
        encodedData: generateExternalRoute('box', box.id),
      }}
    />,
    can(
      'box',
      'update',
      <Button
        fullWidth
        key="create"
        variant="contained"
        color="secondary"
        onClick={() => navigate(`/boxes/${params.id}/edit`)}
      >
        <FormattedMessage
          id="common.actions.edit-with-name"
          values={{ name: formatMessage({ id: 'models.box' }) }}
        />
      </Button>
    ),
  ]

  return (
    <AppPage
      backButton
      loading={loading}
      icon={modelIcons.box}
      title={formatMessage({ id: 'routes.boxes-preview' }, { name: box.coreId })}
      actionButtons={headerActionButtons}
    >
      <CardedContent>
        <TabSections
          sections={[
            {
              key: 'general',
              title: formatMessage({ id: 'pages.box-preview.tabs.general' }),
              Content: () => <GeneralTab box={box} onAssignOutlet={refetch} />,
            },
            {
              key: 'tasks',
              title: formatMessage({ id: 'pages.box-preview.tabs.tasks' }),
              Content: () => <TasksTab boxId={box.id} />,
            },
            {
              key: 'activity',
              title: formatMessage({ id: 'pages.box-preview.tabs.history' }),
              Content: () => <ActivityTab boxId={box.id} />,
            },
          ]}
        />
      </CardedContent>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(PreviewPage, ({ can }) => can('box', 'preview'))
