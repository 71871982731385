import { FC, useMemo } from 'react'
import { AnalyticTotalCountType, Outlet } from '@web-panel/api'
import { Widget, Typography } from '@web-panel/shared'
import classNames from 'classnames'

import { useTotalItems } from '@web-panel/oc-api'

type TotalItemsWidgetProps = {
  type: AnalyticTotalCountType
  partnerId: string
  outlets: Outlet[]
  title: string
  color: string
}

export const TotalItemsWidget: FC<TotalItemsWidgetProps> = ({
  partnerId,
  type,
  outlets,
  title,
  color,
}) => {
  const outletIds = outlets.map(({ id }) => id)
  const { data: counters = [] } = useTotalItems({
    type,
    input: { partnerId, outletIds },
  })

  const deliveredCount = useMemo(() => {
    if (outlets.length === 0) return 0

    return counters.reduce((sum, counter) => sum + counter.count, 0)
  }, [outlets, counters])

  return (
    <Widget>
      <div className="text-center py-12">
        <Typography
          className={classNames(
            'text-72',
            'font-semibold',
            'leading-none',
            'treacking-lighter',
            `text-${color}`
          )}
        >
          {deliveredCount}
        </Typography>
        <Typography className={classNames('text-18', 'font-normal', `text-${color}-800`)}>
          {title}
        </Typography>
      </div>
    </Widget>
  )
}
