import { FC } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'

import { DefaultErrorPage, AppPage, CardedContent, TabSections, Button } from '@web-panel/shared'
import modelIcons from '@local/model-icons'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useProductItem } from '@web-panel/oc-api'
import ProductItemInfoTab from './Tabs/ProductItemInfo'
import ProductItemBatchHistoryTab from './Tabs/ProductItemBatchHistory'
import ProductItemHistoryTab from './Tabs/ProductItemHistory'

const PreviewPage: FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { data, loading } = useProductItem({ id: params.id ?? '' })
  const { can } = useRoleAuthorization()

  if (loading) return null
  if (!data)
    return (
      <DefaultErrorPage
        title={formatMessage({ id: 'errors.404.title' })}
        message={formatMessage({ id: 'errors.404.message' })}
      />
    )

  const headerActionButtons = [
    can(
      'productItem',
      'update',
      <Button fullWidth variant="contained" color="secondary" onClick={() => navigate('edit')}>
        <FormattedMessage
          id="common.actions.edit-with-name"
          values={{ name: formatMessage({ id: `product-item-types.${data.type}` }) }}
        />
      </Button>
    ),
  ]

  return (
    <AppPage
      backButton
      loading={loading}
      icon={modelIcons.productItem}
      title={data.qrCode}
      actionButtons={headerActionButtons}
    >
      <CardedContent>
        <TabSections
          sections={[
            {
              key: 'info',
              title: formatMessage({ id: 'pages.product-items-preview.tabs.info' }),
              Content: () => <ProductItemInfoTab data={data} />,
            },
            {
              key: 'batch-history',
              title: formatMessage({ id: 'pages.product-items-preview.tabs.batch-history' }),
              Content: () => <ProductItemBatchHistoryTab id={data.id} />,
            },
            {
              key: 'history',
              title: formatMessage({ id: 'pages.product-items-preview.tabs.history' }),
              Content: () => <ProductItemHistoryTab id={data.id} />,
            },
          ]}
        />
      </CardedContent>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(PreviewPage, ({ can }) => can('productItem', 'preview'))
