import { FC } from 'react'
import { ProductItemStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { Chip, ComponentColor } from '@web-panel/shared'

const statusColorMapping: Record<ProductItemStatus, ComponentColor> = {
  [ProductItemStatus.InUse]: 'info',
  [ProductItemStatus.Lost]: 'error',
  [ProductItemStatus.Operations]: 'secondary',
  [ProductItemStatus.PartnerOut]: 'info',
  [ProductItemStatus.PartnerStock]: 'info',
  [ProductItemStatus.BoxCheckedIn]: 'info',
  [ProductItemStatus.Restock]: 'secondary',
  [ProductItemStatus.Returned]: 'success',
  [ProductItemStatus.Stock]: 'secondary',
  [ProductItemStatus.Reusing]: 'warning',
  [ProductItemStatus.Transferred]: 'warning',
  [ProductItemStatus.Unknown]: 'error',
}

type ProductItemStatusChipProps = {
  status: ProductItemStatus
}

const ProductItemStatusChip: FC<ProductItemStatusChipProps> = ({ status }) => {
  const { formatMessage } = useIntl()

  const color = statusColorMapping[status] ?? 'default'

  return (
    <Chip
      className="min-w-128"
      color={color}
      label={formatMessage({ id: `product-item-statuses.${status}` })}
    />
  )
}

export default ProductItemStatusChip
