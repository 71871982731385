import { FC } from 'react'
import { Hidden } from '@mui/material'
import { PreviewProps } from './types'
import { HorizontalPreview } from './Horizontal'
import { VerticalPreview } from './Vertical'

export const WorkingHoursPreview: FC<PreviewProps> = (props) => {
  return (
    <>
      <Hidden lgDown>
        <HorizontalPreview {...props} />
      </Hidden>
      <Hidden lgUp>
        <VerticalPreview {...props} />
      </Hidden>
    </>
  )
}
