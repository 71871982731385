import { FC } from 'react'
import { RentalConfigurationStatus } from '@web-panel/api'
import { useFormikContext } from 'formik'
import { LabeledSelect, TextField } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'

export type FormFields = {
  status: RentalConfigurationStatus
  price: number
}

export const validationSchema = Yup.object().shape({
  status: Yup.string().oneOf(Object.values(RentalConfigurationStatus)).required(),
  price: Yup.number().min(0).required(),
})

export const UpdateRentalConfigurationForm: FC = () => {
  const { formatMessage } = useIntl()
  const { values, errors, handleChange, setFieldValue, handleSubmit } =
    useFormikContext<FormFields>()

  return (
    <form className="w-full" onSubmit={handleSubmit}>
      <div className="flex flex-col gap-16 max-w-2xl">
        <TextField
          fullWidth
          label={formatMessage({ id: 'models.rental-configuration.price' })}
          value={values.price}
          onChange={handleChange('price')}
          error={!!errors.price}
        />
        <LabeledSelect
          label={formatMessage({ id: 'models.rental-configuration.status' })}
          value={values.status}
          options={Object.values(RentalConfigurationStatus)}
          optionTitle={(status) => formatMessage({ id: `rental-configuration-statuses.${status}` })}
          onChange={(e) => setFieldValue('status', e.target.value)}
          error={!!errors.status}
        />
      </div>
    </form>
  )
}
