import { useBoxFWVersionAggregation } from '@web-panel/oc-api'

export function sortVersion(previous: string, current: string) {
  const previousVersionParts = previous === 'NO_VERSION' ? [] : previous.split('.')
  const currentVersionParts = current === 'NO_VERSION' ? [] : current.split('.')

  const maxParts = Math.max(previousVersionParts.length, currentVersionParts.length)

  let diff = 0

  for (let i = 0; i < maxParts; i += 1) {
    diff = Number(previousVersionParts[i] ?? 0) - Number(currentVersionParts[i] ?? 0)
    if (diff !== 0) break
  }

  return diff
}

type Arguments = Parameters<typeof useBoxFWVersionAggregation>

export const useBoxVersions = (...args: Arguments) => {
  const { data: allFwVersions = [] } = useBoxFWVersionAggregation({})
  const { data: filteredFwVersions = [] } = useBoxFWVersionAggregation(...args)

  const versionNames = allFwVersions.map(({ fwVersion }) => fwVersion).sort(sortVersion)
  const aggregatedVersions = versionNames.reduce((res, versionName) => {
    res[versionName] =
      filteredFwVersions.find((filteredVersion) => filteredVersion.fwVersion === versionName)
        ?.count ?? 0
    return res
  }, {} as Record<string, number>)

  return aggregatedVersions
}
