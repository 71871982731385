import { FC } from 'react'
import { OrderInvoiceStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { Chip, ComponentColor } from '@web-panel/shared'

export const statusColorMapping: Record<OrderInvoiceStatus, ComponentColor> = {
  [OrderInvoiceStatus.Open]: 'info',
  [OrderInvoiceStatus.Paid]: 'success',
  [OrderInvoiceStatus.Unpaid]: 'error',
}

export const useTranslateStatus = () => {
  const { formatMessage } = useIntl()

  return (status: OrderInvoiceStatus) => formatMessage({ id: `invoice-statuses.${status}` })
}

type OrderInvoiceStatusChipProps = {
  status: OrderInvoiceStatus
}

export const OrderInvoiceStatusChip: FC<OrderInvoiceStatusChipProps> = ({ status }) => {
  const translateStatus = useTranslateStatus()

  const color = statusColorMapping[status] ?? 'default'

  return (
    <Chip
      data-testid="OrderInvoiceStatusChip"
      className="min-w-84"
      color={color}
      label={translateStatus(status)}
    />
  )
}
