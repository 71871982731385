import { FC } from 'react'
import { Link } from '@web-panel/shared'

import { useOutlet } from '@web-panel/oc-api'

type OutletLinkProps = {
  outletId: string
}

export const OutletLink: FC<OutletLinkProps> = ({ outletId }) => {
  const { data: outlet } = useOutlet({ id: outletId })
  if (!outlet) return null

  return (
    <Link stopPropogation data-testid="OutletLink" to={`/outlets/${outlet.id}`}>
      {outlet.location.name}
    </Link>
  )
}
