import { PropsWithChildren } from 'react'
import { List, ListItemText, Chip } from '@mui/material'
import { StyledListItem, StyledListSubheader } from './styled'

export type MultipleSelectProps<Data> = PropsWithChildren<{
  label?: string
  value: Data[]
  options: Data[]
  badge?: (option: Data) => string
  optionTitle?: (option: Data) => string
  onSelect?: (value: Data[]) => void
  compareOption?: (option: Data, value: Data) => boolean
}>

const MultipleSelect = <Data extends unknown>({
  label,
  value,
  options,
  badge,
  optionTitle,
  onSelect,
  compareOption = (o, v) => o === v,
}: MultipleSelectProps<Data>) => {
  const handleSelect = (option: Data) => {
    if (!onSelect) return

    const optIndex = value.findIndex((opt) => compareOption(opt, option))
    if (optIndex > -1) {
      onSelect([...value.slice(0, optIndex), ...value.slice(optIndex + 1, value.length)])
    } else {
      onSelect([...value, option])
    }
  }

  return (
    <List className="py-0">
      {label && (
        <StyledListSubheader className="pl-12" disableSticky>
          {label}
        </StyledListSubheader>
      )}
      {options.map((option, id) => (
        <StyledListItem
          selected={value.findIndex((opt) => compareOption(option, opt)) > -1}
          key={id}
          onClick={() => handleSelect(option)}
        >
          <ListItemText>
            <div className="flex flex-row justify-between">
              {optionTitle ? optionTitle(option) : option}
              {badge && <Chip size="small" className="ml-8" label={badge(option)} />}
            </div>
          </ListItemText>
        </StyledListItem>
      ))}
    </List>
  )
}

export default MultipleSelect
