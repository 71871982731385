import { FC } from 'react'
import { FormikProps } from 'formik'
import { Outlet } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { Autocomplete, TextField } from '@web-panel/shared'
import * as Yup from 'yup'
import _includes from 'lodash/includes'

import { useOutlets } from '@web-panel/oc-api'

export type FormFields = {
  outletIds: string[]
  firstName: string
  lastName: string
  email: string
}

export const validationSchema = Yup.object().shape({
  outeletIds: Yup.array(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
})

export const defaultValues: FormFields = {
  outletIds: [],
  firstName: '',
  lastName: '',
  email: '',
}

type CreatePartnerEmployeeFormProps = FormikProps<FormFields> & {
  partnerId: string
}

const CreatePartnerEmployeeForm: FC<CreatePartnerEmployeeFormProps> = ({
  values,
  errors,
  handleChange,
  setFieldValue,
  handleSubmit,
  partnerId,
}) => {
  const { formatMessage } = useIntl()
  const { data: outlets = [] } = useOutlets({ partnerId })

  const selectedOutlets = outlets.filter((outlet) => _includes(values.outletIds, outlet.id))

  const getOutletLabel = (outlet: Outlet) => {
    return outlet.location.name
  }

  return (
    <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit}>
      <div className="flex flex-col p-16 sm:p-24 max-w-2xl">
        <TextField
          fullWidth
          className="mb-16"
          value={values.firstName}
          onChange={handleChange('firstName')}
          label={formatMessage({ id: 'models.partner-employee.first-name' })}
          error={!!errors.firstName}
        />
        <TextField
          fullWidth
          className="mb-16"
          value={values.lastName}
          onChange={handleChange('lastName')}
          label={formatMessage({ id: 'models.partner-employee.last-name' })}
          error={!!errors.lastName}
        />
        <TextField
          type="email"
          fullWidth
          className="mb-16"
          value={values.email}
          onChange={handleChange('email')}
          label={formatMessage({ id: 'models.partner-employee.email' })}
          error={!!errors.email}
        />
        <Autocomplete
          fullWidth
          multiple
          options={outlets}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!errors.outletIds}
              label={formatMessage({ id: 'models.partner-employee.outlets' })}
            />
          )}
          getOptionLabel={getOutletLabel}
          isOptionEqualToValue={(option, val) => option.id === val.id}
          renderOption={(props, outlet) => {
            return (
              <li {...props} key={outlet.id}>
                {getOutletLabel(outlet)}
              </li>
            )
          }}
          onChange={(e, val) =>
            setFieldValue(
              'outletIds',
              val.map(({ id }) => id)
            )
          }
          value={selectedOutlets}
        />
      </div>
    </form>
  )
}

export default CreatePartnerEmployeeForm
