import {
  Outlet,
  OutletType,
  OutletStatus,
  OutletLegalAddress,
  WorkingHours,
  OutletCycleType,
} from '@web-panel/api'

export type UpdateInput = {
  type: OutletType
  firstName: string
  lastName: string
  phone: string
  email: string
  operationsPhone: string | null
  operationsEmail: string | null
  status: OutletStatus
  location: {
    name: string
    cityCode: string
    street: string
    houseNumber: string
    postalCode: string
    latitude: number
    longitude: number
    workingHours: WorkingHours[]
  }
  legalAddress: OutletLegalAddress | null
  featured: boolean
  batchDefaultSize: number
  hiddenOnMap: boolean
  cycleType: OutletCycleType
}

export function outletToUpdateInput(outlet: Outlet): UpdateInput {
  const legalAddress: OutletLegalAddress | null =
    outlet.legalName &&
    outlet.legalCountryCode &&
    outlet.legalCity &&
    outlet.legalStreet &&
    outlet.legalHouseNumber &&
    outlet.legalPostalCode
      ? {
          name: outlet.legalName,
          countryCode: outlet.legalCountryCode,
          city: outlet.legalCity,
          street: outlet.legalStreet,
          houseNumber: outlet.legalHouseNumber,
          postalCode: outlet.legalPostalCode,
          legalAdditionalAddressLines: outlet.legalAdditionalAddressLines ?? [],
        }
      : null

  return {
    type: outlet.type,
    firstName: outlet.firstName,
    lastName: outlet.lastName,
    phone: outlet.phone,
    email: outlet.email,
    operationsPhone: outlet.operationsPhone,
    operationsEmail: outlet.operationsEmail,
    status: outlet.status,
    location: {
      name: outlet.location.name,
      cityCode: outlet.location.cityCode,
      street: outlet.location.street,
      houseNumber: outlet.location.houseNumber,
      postalCode: outlet.location.postalCode,
      latitude: outlet.location.latitude,
      longitude: outlet.location.longitude,
      workingHours: outlet.location.workingHours,
    },
    legalAddress,
    featured: outlet.featured,
    batchDefaultSize: outlet.batchDefaultSize,
    hiddenOnMap: outlet.hiddenOnMap,
    cycleType: outlet.cycleType,
  }
}
