import { FC } from 'react'
import { User } from '@web-panel/api'
import { Paper, TableGrid } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { UserStatusChip } from '@local/components/UserStatusChip'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'

type IndexTableProps = {
  data: User[]
  loading: boolean
}

const IndexTable: FC<IndexTableProps> = ({ data, loading }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { can, cannot } = useRoleAuthorization()

  return (
    <Paper className="overflow-hidden">
      <TableGrid
        collection={data}
        loading={loading}
        fields={[
          {
            title: formatMessage({ id: 'models.user.id' }),
            render: ({ userNumber }) => userNumber,
          },
          { title: formatMessage({ id: 'models.user.email' }), render: ({ email }) => email },
          {
            title: formatMessage({ id: 'models.user.name' }),
            render: ({ firstName, lastName }) => [firstName, lastName].join(' '),
          },
          {
            title: formatMessage({ id: 'models.user.role' }),
            render: ({ role }) => formatMessage({ id: `user-roles.${role}` }),
          },
          {
            title: formatMessage({ id: 'models.user.status' }),
            render: ({ status }) => <UserStatusChip status={status} />,
          },
        ]}
        onClickRow={(user) => navigate(user.id)}
        rowClickable={can('user', 'preview')}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        rowActions={[
          {
            title: formatMessage({ id: 'common.actions.edit' }),
            icon: 'edit',
            onClick: ({ id }) => navigate(`/admin-users/${id}/edit`),
            disabled: cannot('user', 'update'),
            mainAction: true,
          },
        ]}
      />
    </Paper>
  )
}

export default IndexTable
