export const resourceAuthorizationMapping = {
  partner: {
    list: 'partners::partners::list',
    preview: 'partners::partners::get',
    create: 'partners::partners::add',
    delete: 'partners::partners::delete',
    update: 'partners::partners::update',
    listRefundables: 'operations::items::models::partners::list',
    previewRefundable: 'operations::items::models::partners::list',
    updateRefundables: 'operations::items::models::partners::update',
    deleteRefundables: 'operations::items::models::partners::delete',
  },
  partnerEmployee: {
    list: 'partners::partners::employees::list',
    preview: 'partners::partners::employees::get',
    create: 'partners::partners::employees::add',
    update: 'partners::partners::employees::update',
    delete: 'partners::partners::employees::delete',
    assignToOutlet: 'partners::partners::employees::assign::outlet::update',
    removeFromOutlet: 'partners::partners::employees::assign::outlet::delete',
  },
  outlet: {
    list: 'partners::outlets::list',
    preview: 'partners::outlets::get',
    create: 'partners::outlets::add',
    delete: 'partners::outlets::delete',
    update: 'partners::outlets::update',
    listRefundables: 'operations::items::models::outlets::list',
    previewRefundable: 'operations::items::models::outlets::list',
    updateRefundables: 'operations::items::models::outlets::update',
    deleteRefundables: 'operations::items::models::outlets::delete',
  },
  order: {
    list: 'operations::orders::list',
    preview: 'operations::orders::get',
    create: 'operations::orders::add',
    delete: 'operations::orders::delete',
    updateStatus: 'operations::orders::status::update',
    updateReturnType: 'operations::orders::return_type::update',
    addSignature: 'operations::orders::signature::add',
    getAttachContainers: 'operations::orders::containers::get',
    attachContainersToOrder: 'operations::orders::containers::add',
    removeContainersFromOrder: 'operations::orders::containers::delete',
  },
  user: {
    list: 'users::users::list',
    preview: 'users::users::get',
    register: 'users::users::register::add',
    update: 'users::users::update',
    delete: 'users::users::delete',
  },
  paymentOut: {
    list: 'finance::oc::payments::list',
    preview: 'finance::oc::payments::get',
    update: 'finance::oc::payments::update',
  },
  donation: {
    list: 'finance::oc::donations::list',
    preview: 'finance::oc::donations::get',
    consumerPreview: 'finance::oc::donations::consumer::get',
    update: 'finance::oc::donations::update',
  },
  productItem: {
    list: 'operations::items::list',
    preview: 'operations::items::get',
    previewByQQCode: 'operations::items::qr::get',
    previewByRFID: 'operations::items::rfid::get',
    create: 'operations::items::add',
    createBatch: 'operations::items::batch::add',
    update: 'operations::items::update',
    listConsumerHistory: 'operations::items::consumer::list',
    getAggregation: 'operations::items::filter::aggregation::list',
    listBoxHistory: 'operations::items::history::box::list',
    getModelTypes: 'operations::items::models::types::list',
    getModelBrands: 'operations::items::models::brands::list',
  },
  productItemBatch: {
    list: 'operations::batches::list',
    listBatchItems: 'operations::batches::items::list',
    preview: 'operations::batches::get',
    create: 'operations::batches::add',
    delete: 'operations::batches::delete',
    attachContainer: 'operations::batches::containers::add',
    removeContainer: 'operations::batches::containers::delete',
  },
  box: {
    list: 'partners::boxes::list',
    create: 'partners::boxes::add',
    getStatusAggregation: 'partners::boxes::filter::aggregation::get',
    getFWAggregation: 'partners::boxes::filter::aggregation::fw::get',
    preview: 'partners::boxes::get',
    update: 'partners::boxes::update',
    assignToOutlet: 'partners::boxes::outlet::update',
    assignToLocation: 'partners::boxes::location::update',
    listHistory: 'partners::boxes::history::list',
    listTasks: 'partners::boxes::tasks::list',
    createCriticalTask: 'integrator::orders::boxes::add',
    deleteCriticalTask: 'integrator::orders::boxes::delete',
    resetBoxItems: 'partners::boxes::reset::items::delete',
  },
  location: {
    list: 'partners::locations::list',
    preview: 'partners::locations::get',
    update: 'partners::locations::update',
    delete: 'partners::locations::delete',
  },
  consumable: {
    list: 'partners::consumables::list',
    preview: 'partners::consumables::get',
    create: 'partners::consumables::add',
    update: 'partners::consumables::update',
    delete: 'partners::consumables::delete',
  },
  partnerConsumable: {
    list: 'partners::consumables::partners::list',
    preview: 'partners::consumables::partners::get',
    create: 'partners::consumables::partners::add',
    update: 'partners::consumables::partners::update',
    delete: 'partners::consumables::partners::delete',
  },
  geoCity: {
    list: '*',
    preview: '*',
    create: 'locations::cities::add',
    update: 'locations::cities::update',
    listRefundables: 'operations::items::models::cities::list',
    previewRefundable: 'operations::items::models::cities::list',
    updateRefundables: 'operations::items::models::cities::update',
    deleteRefundables: 'operations::items::models::cities::delete',
  },
  geoCountry: {
    list: '*',
    preview: '*',
    create: 'locations::countries::add',
    update: 'locations::countries::update',
    listRefundables: 'operations::items::models::countries::list',
    previewRefundable: 'operations::items::models::countries::list',
    updateRefundables: 'operations::items::models::countries::update',
    deleteRefundables: 'operations::items::models::countries::delete',
  },
  dispenser: {
    list: 'partners::dispensers::list',
    create: 'partners::dispensers::add',
    preview: 'partners::dispensers::get',
    update: 'partners::dispensers::update',
    assignToOutlet: 'partners::dispensers::outlet::update',
    assignToLocation: 'partners::dispensers::location::update',
  },
  loyaltyCampaign: {
    list: 'operations::loyalty::partners::rewards::campaigns::list',
    preview: 'operations::loyalty::partners::rewards::campaigns::get',
    delete: 'operations::loyalty::partners::rewards::campaigns::delete',
  },
  invoice: {
    list: 'documents::invoices::list',
    update: 'documents::invoices::update',
  },
  consumerAccount: {
    list: 'users::users::list',
    preview: 'users::users::get',
    previewInfo: 'finance::oc::accounts::consumer::get',
    delete: 'users::users::delete',
    updateWallet: 'finance::oc::accounts::consumer::wallet::update',
    topUpWallet: 'finance::oc::accounts::consumer::wallet::add',
  },
  boxRentalConfiguration: {
    list: 'partners::inventory::boxes::rental::configurations::list',
    preview: 'partners::inventory::boxes::rental::configurations::get',
    create: 'partners::inventory::boxes::rental::configurations::add',
    update: 'partners::inventory::boxes::rental::configurations::update',
  },
  boxRental: {
    list: 'partners::inventory::boxes::rentals::list',
    preview: 'partners::inventory::boxes::rentals::get',
    create: 'partners::inventory::boxes::rentals::add',
    update: 'partners::inventory::boxes::rentals::update',
  },
  rental: {
    list: 'partners::inventory::rentals::list',
  },
  partnerSupplement: {
    list: 'partners::partners::supplements::get',
    create: 'partners::partners::supplements::create',
    update: 'partners::partners::supplements::update',
    delete: 'partners::partners::supplements::delete',
  },
  outletSupplement: {
    list: 'partners::outlets::supplements::get',
    create: 'partners::outlets::supplements::create',
    update: 'partners::outlets::supplements::update',
    delete: 'partners::outlets::supplements::delete',
  },
  oneTimeContractSetting: {
    list: 'partners::contract::partner::settings::once::list',
    create: 'partners::contract::partner::settings::once::add',
    update: 'partners::contract::partner::settings::id::once::update',
    preview: 'partners::contract::partner::settings::once::get',
    delete: 'partners::contract::partner::settings::id::delete',
    add: 'partners::contract::partner::settings::add',
  },
  container: {
    list: 'operations::containers::list',
    preview: 'operations::containers::get',
    previewByQRCode: 'operations::containers::qr::get',
    create: 'operations::containers::add',
    update: 'operations::containers::update',
    listBatches: 'operations::containers::batches::list',
    listActivity: 'operations::containers::history::list',
  },
  addresses: {
    list: 'shipment::partner::addresses::list',
    preview: 'shipment::partner::addresses::get',
    create: 'shipment::partner::addresses::add',
    update: 'shipment::partner::addresses::update',
    delete: 'shipment::partner::addresses::delete',
  },
  labels: {
    list: 'shipment::labels::partner::list',
    preview: 'shipment::labels::partner::get',
    create: 'shipment::labels::partner::add',
    update: 'shipment::labels::partner::update',
    delete: 'shipment::labels::partner::delete',
  },
  tracking: {
    list: 'shipment::labels::tracking::list',
  },
  groupLabels: {
    list: 'shipment::labels::group::label::list',
    preview: 'shipment::labels::group::label::get',
    create: 'shipment::labels::sequence::add',
    update: 'shipment::labels::group::label::update',
    delete: 'shipment::labels::group::label::delete',
    labelsList: 'shipment::labels::group::label::labels::list',
    activate: 'shipment::container::cycle::containerless::activate',
  },
  sequences: {
    list: 'shipment::sequences::list',
    preview: 'shipment::sequences::get',
  },
  sequenceNodes: {
    list: 'shipment::sequences::nodes::list',
  },
  schedules: {
    list: 'shipment::shipments::pickup::list',
  },
} as const

type AuthorizationMapping = typeof resourceAuthorizationMapping
export type RoleResource = keyof typeof resourceAuthorizationMapping
export type RoleAction<Resource extends RoleResource> = keyof AuthorizationMapping[Resource]
