import { useState, useRef, useMemo, MouseEventHandler } from 'react'
import {
  ButtonGroup,
  Grow,
  Button,
  Icon,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
} from '@mui/material'
import { RowActionsMenuItem, RowActionsMenuItemProps } from './RowActionsMenuItem'

export type RowAction<Data> = {
  icon?: string
  onClick: (data: Data) => void
  mainAction?: boolean
  visible?: (data: Data) => boolean
} & Pick<RowActionsMenuItemProps<Data>, 'title' | 'confirmation' | 'disabled'>

type RowActionsButtonProps<Data> = {
  data: Data
  actions: RowAction<Data>[]
}

export function RowActionsButton<Data>({ data, actions }: RowActionsButtonProps<Data>) {
  const [open, setOpen] = useState(false)
  const anchorEl = useRef(null)

  const mainActionIndex = Math.max(
    actions.findIndex(({ mainAction }) => mainAction),
    0
  )
  const mainAction = actions[mainActionIndex]
  const otherActions = [...actions]
  otherActions.splice(mainActionIndex, 1)

  const handleClick: MouseEventHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(!open)
  }

  const onClose = (e: MouseEvent | TouchEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(false)
  }

  const isMainActionDisabled = useMemo(() => {
    if (!mainAction.disabled) return false
    if (typeof mainAction.disabled === 'boolean') return mainAction.disabled

    return mainAction.disabled(data)
  }, [mainAction.disabled, data])

  return (
    <>
      <ButtonGroup variant="outlined">
        {(mainAction.visible === undefined || mainAction.visible(data)) && (
          <Button
            disabled={isMainActionDisabled}
            startIcon={mainAction.icon && <Icon>{mainAction.icon}</Icon>}
            onClick={(e) => {
              e.stopPropagation()
              mainAction.onClick(data)
            }}
          >
            {mainAction.title}
          </Button>
        )}
        {otherActions.length > 0 && (
          <Button ref={anchorEl} onClick={handleClick}>
            <Icon>expand_more</Icon>
          </Button>
        )}
      </ButtonGroup>
      <Popper sx={{ zIndex: 1 }} anchorEl={anchorEl.current} open={open} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onClose}>
                <MenuList autoFocusItem>
                  {otherActions.map(({ ...itemProps }, id) => (
                    <RowActionsMenuItem
                      data={data}
                      key={id}
                      {...itemProps}
                      onCloseDropdown={() => setOpen(false)}
                    />
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
