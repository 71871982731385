import { FC } from 'react'
import { ContractPackageSetting, ContractSettingConfigFrequency } from '@web-panel/api'
import { FormControlLabel, FormLabel, FormSection, Radio, RadioGroup } from '@web-panel/shared'
import _uniq from 'lodash/uniq'
import { useFormikContext } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import { ContractSettingsSectionFields, packageSettingToInput } from './helpers'
import { InputSelectSettingType } from './InputSelectSettingType'

type InventoryAndServiceSectionProps = {
  packageCode: string
  packageItemSettings: ContractPackageSetting[]
  packageConsumableSettings: ContractPackageSetting[]
  deliverySettings: ContractPackageSetting[]
}
export const InventoryAndServiceSection: FC<InventoryAndServiceSectionProps> = ({
  packageCode,
  packageItemSettings,
  packageConsumableSettings,
  deliverySettings,
}) => {
  const { formatMessage } = useIntl()
  const { values, errors, setValues, setFieldValue } =
    useFormikContext<ContractSettingsSectionFields>()

  const availableFrequencies = _uniq(
    [...packageItemSettings, ...packageConsumableSettings].map(
      ({ contractSettingConfig }) => contractSettingConfig.frequency
    )
  )

  const suitableSettings = (
    settings: ContractPackageSetting[],
    frequency: ContractSettingConfigFrequency
  ) => {
    return settings.filter(
      ({ contractSettingConfig }) => contractSettingConfig.frequency === frequency
    )
  }

  const handleChangeFrequency = (invoiceFrequency: ContractSettingConfigFrequency) => {
    const newItemSetting = suitableSettings(packageItemSettings, invoiceFrequency)[0]
    const newConsumableSetting = suitableSettings(packageConsumableSettings, invoiceFrequency)[0]
    const newDeliverySetting = suitableSettings(deliverySettings, invoiceFrequency)[0]

    setValues({
      ...values,
      invoiceFrequency,
      itemsSetting: newItemSetting ? packageSettingToInput(newItemSetting) : null,
      consumablesSetting: newConsumableSetting ? packageSettingToInput(newConsumableSetting) : null,
      deliverySetting: newDeliverySetting ? packageSettingToInput(newDeliverySetting) : null,
    })
  }

  if (!values.invoiceFrequency) return null

  return (
    <div className="mt-24">
      <div>
        <FormLabel>
          <FormattedMessage id="models.contract-package-business.invoice-frequency" />
        </FormLabel>
        <RadioGroup
          row
          value={values.invoiceFrequency}
          onChange={(e) => handleChangeFrequency(e.target.value as ContractSettingConfigFrequency)}
        >
          <FormControlLabel
            label={formatMessage({ id: 'contract-setting-config-frequencies.MONTHLY' })}
            value={ContractSettingConfigFrequency.Monthly}
            control={<Radio />}
            disabled={!availableFrequencies.includes(ContractSettingConfigFrequency.Monthly)}
          />
          <FormControlLabel
            label={formatMessage({ id: 'contract-setting-config-frequencies.PER_ORDER' })}
            value={ContractSettingConfigFrequency.PerOrder}
            control={<Radio />}
            disabled={!availableFrequencies.includes(ContractSettingConfigFrequency.PerOrder)}
          />
        </RadioGroup>
      </div>
      <FormSection
        firstSection
        title={formatMessage({ id: 'models.contract-package-business.inventory-pricing' })}
        containerClasses="-mt-8"
      >
        {values.itemsSetting && (
          <InputSelectSettingType
            title={formatMessage({ id: 'product-item-types.plural.CUP' })}
            packageSettings={suitableSettings(packageItemSettings, values.invoiceFrequency)}
            currentSetting={values.itemsSetting}
            onChange={(setting) => setFieldValue('itemsSetting', setting)}
            resourceName={formatMessage({ id: 'product-item-types.CUP' })}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            errors={errors.itemsSetting ?? {}}
          />
        )}
        {values.consumablesSetting && (
          <div className="mt-16">
            <InputSelectSettingType
              title={formatMessage({ id: 'consumable-product-item-types.plural.LID' })}
              packageSettings={suitableSettings(packageConsumableSettings, values.invoiceFrequency)}
              currentSetting={values.consumablesSetting}
              onChange={(setting) => setFieldValue('consumablesSetting', setting)}
              resourceName={formatMessage({
                id: 'consumable-product-item-types.LID',
              })}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              errors={errors.consumablesSetting ?? {}}
            />
          </div>
        )}
      </FormSection>
      <FormSection
        title={formatMessage({ id: 'models.contract-package-business.service-pricing' })}
        containerClasses="-mt-8"
      >
        <InputSelectSettingType
          allowNullSetting
          orderLabel
          nullSettingDisabled={packageCode !== 'CUSTOM'}
          title={formatMessage({ id: 'models.contract-package-business.delivery-cost' })}
          packageSettings={suitableSettings(deliverySettings, values.invoiceFrequency)}
          currentSetting={values.deliverySetting}
          onChange={(setting) => setFieldValue('deliverySetting', setting)}
          resourceName={formatMessage({ id: 'models.delivery' })}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          errors={errors.deliverySetting ?? {}}
        />
      </FormSection>
    </div>
  )
}
