import { gql } from '@apollo/client'
import {
  CollectionLoyaltyCampaign,
  collectionLoyaltyCampaignFields,
  LoyaltyCampaign,
  loyaltyCampaignFields,
  LoyaltyCampaignStatus,
} from '@web-panel/api'

export type LoyaltyCampaignsDocumentInput = {
  partnerId: string
  filters?: {
    status?: LoyaltyCampaignStatus
  }
  pagination?: {
    offset?: number
    limit?: number
  }
}
export type LoyaltyCampaignsDocumentResponse = {
  loyaltyCampaigns: {
    rewardCampaigns: CollectionLoyaltyCampaign[]
    hasNext: boolean
  }
}
export const LoyaltyCampaignsDocument = gql`
  query LoyaltyCampaigns($partnerId: String!, $filters: any, $pagination: any) {
    loyaltyCampaigns(partnerId: $partnerId, filters: $filters, pagination: $pagination)
      @rest(
        type: "CollectionLoyaltyCampaigns",
        path: "/operations-service/loyalty/partners/{args.partnerId}/rewards/campaigns?{args.pagination}&{args.filters}"
      ) {
      rewardCampaigns @type(name: "CollectionLoyaltyCampaign") {
        ${collectionLoyaltyCampaignFields}
      }
      hasNext
    }
  }
`

export type LoaltyCampaignDocumentInput = {
  partnerId: string
  campaignId: string
}
export type LoaltyCampaignDocumentResponse = {
  loyaltyCampaign: LoyaltyCampaign
}

export const LoaltyCampaignDocument = gql`
  query LoyaltyCampaign($partnerId: String!, $campaignId: String!) {
    loyaltyCampaign(partnerId: $partnerId, campaignId: $campaignId)
      @rest(
        type: "LoyaltyCampaign",
        path: "/operations-service/loyalty/partners/{args.partnerId}/rewards/campaigns/{args.campaignId}"
      ) {
      ${loyaltyCampaignFields}
    }
  }
`

export type StopLoayltyCampaignDocumentInput = {
  partnerId: string
  campaignId: string
}
export type StopLoayltyCampaignDocumentResponse = void

export const StopLoayltyCampaignDocument = gql`
  mutation StopCampaign($partnerId: String!, $campaignId: String!) {
    stopCampaign(partnerId: $partnerId, campaignId: $campaignId)
      @rest(
        type: "NoBody"
        path: "/operations-service/loyalty/partners/{args.partnerId}/rewards/campaigns/{args.campaignId}"
        method: "DELETE"
      ) {
      NoBody
    }
  }
`
