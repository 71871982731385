import { FC, useMemo } from 'react'
import { SelectableList, LabeledSelect, buildFilterContext } from '@web-panel/shared'
import { ProductItemStatus, ProductItemSize } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { subHours, subDays } from 'date-fns'

import { useProductItemStatusAggregation } from '@web-panel/oc-api'

export const statusLifetimeMap = {
  l24: (d: Date) => ({
    startHolderUpdateDateTime: subHours(d, 24),
    endHolderUpdateDateTime: undefined,
  }),
  '24h-48h': (d: Date) => ({
    startHolderUpdateDateTime: subHours(d, 48),
    endHolderUpdateDateTime: subHours(d, 24),
  }),
  '48h-72h': (d: Date) => ({
    startHolderUpdateDateTime: subHours(d, 72),
    endHolderUpdateDateTime: subHours(d, 48),
  }),
  '72h-7d': (d: Date) => ({
    startHolderUpdateDateTime: subDays(d, 7),
    endHolderUpdateDateTime: subHours(d, 72),
  }),
  '7d-30d': (d: Date) => ({
    startHolderUpdateDateTime: subDays(d, 30),
    endHolderUpdateDateTime: subDays(d, 7),
  }),
  '30d-90d': (d: Date) => ({
    startHolderUpdateDateTime: subDays(d, 90),
    endHolderUpdateDateTime: subDays(d, 30),
  }),
  m90d: (d: Date) => ({
    startHolderUpdateDateTime: undefined,
    endHolderUpdateDateTime: subDays(d, 90),
  }),
} as const

type StatusLifetime = keyof typeof statusLifetimeMap
const statusLifetimeKeys = Object.keys(statusLifetimeMap) as StatusLifetime[]

type FilterFields = {
  status: ProductItemStatus | undefined
  size: ProductItemSize | undefined
  statusLifetime: StatusLifetime | undefined
}

export const { FiltersContext, withFilters, useFilters } = buildFilterContext<FilterFields>({
  status: undefined,
  size: undefined,
  statusLifetime: undefined,
})

export const useStatusLifeTimeFilters = (
  statusLifetime: FilterFields['statusLifetime']
): {
  startHolderUpdateDateTime: Date | undefined
  endHolderUpdateDateTime: Date | undefined
} => {
  const filters = useMemo(() => {
    const now = new Date()
    if (!statusLifetime || !statusLifetimeMap[statusLifetime])
      return {
        startHolderUpdateDateTime: undefined,
        endHolderUpdateDateTime: undefined,
      }

    return statusLifetimeMap[statusLifetime](now)
  }, [statusLifetime])

  return filters
}

type FilterProps = {
  search?: string
}

const Filters: FC<FilterProps> = ({ search }) => {
  const { formatMessage } = useIntl()
  const { filters, setFilter } = useFilters()
  const { startHolderUpdateDateTime, endHolderUpdateDateTime } = useStatusLifeTimeFilters(
    filters.statusLifetime
  )
  const { data: productItemStatusAggregation = [] } = useProductItemStatusAggregation({
    size: filters.size,
    startHolderUpdateDateTime,
    endHolderUpdateDateTime,
    search,
  })

  const aggregationsByStatus = useMemo(() => {
    return productItemStatusAggregation.reduce(
      (obj, { status, count }) => ({
        ...obj,
        [status]: count,
      }),
      {}
    ) as Record<ProductItemStatus, number>
  }, [productItemStatusAggregation])

  return (
    <div className="flex flex-col">
      <SelectableList<ProductItemStatus>
        value={filters.status}
        options={Object.values(ProductItemStatus)}
        optionTitle={(option) => formatMessage({ id: `product-item-statuses.${option}` })}
        label={formatMessage({ id: 'models.product-item.status' })}
        onSelect={(status) => setFilter('status', status)}
        badge={(status) => String(aggregationsByStatus[status] ?? 0)}
      />
      <SelectableList<ProductItemSize>
        className="mt-16"
        value={filters.size}
        options={Object.values(ProductItemSize)}
        optionTitle={(option) => formatMessage({ id: `product-item-sizes.${option}` })}
        label={formatMessage({ id: 'models.product-item.size' })}
        onSelect={(option) => setFilter('size', option)}
      />
      <LabeledSelect<StatusLifetime | ''>
        className="mt-16 mx-12"
        value={filters.statusLifetime ?? ''}
        options={['', ...statusLifetimeKeys]}
        optionTitle={(option) =>
          formatMessage({
            id: option === '' ? 'common.no-filter' : `product-item-status-life-time.${option}`,
          })
        }
        label={formatMessage({ id: 'models.product-item.status-lifetime' })}
        onChange={(e) => {
          setFilter(
            'statusLifetime',
            e.target.value === '' ? undefined : (e.target.value as StatusLifetime)
          )
        }}
      />
    </div>
  )
}

export default Filters
