import { FC } from 'react'
import { Tabs, Tab, Chip, ChipProps } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import _isNil from 'lodash/isNil'

type TabSection = {
  key: string
  title: string
  Content: FC
  enabled?: boolean
  badge?: ChipProps['label']
  badgeColor?: ChipProps['color']
}

type TabSectionsProps = {
  sections: TabSection[]
  onTabSelection?: (selectedTab: string) => void
}

export const TabSections: FC<TabSectionsProps> = ({ sections, onTabSelection }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const hash = `${location.hash}`.replace('#', '')

  const enabledSections = sections.filter(({ enabled }) => enabled ?? true)

  const activeSectionId = enabledSections.findIndex(({ key }) => key === hash)
  const activeTabId = activeSectionId > 0 ? activeSectionId : 0
  const { Content } = enabledSections[activeTabId]

  const navigateTab = (id: number) => {
    if (onTabSelection !== undefined) {
      onTabSelection(enabledSections[id].key)
    }
    const anchor = id === 0 ? '' : `#${enabledSections[id].key}`
    navigate(anchor, { replace: true })
  }

  return (
    <>
      <Tabs
        value={activeTabId}
        onChange={(e, id) => navigateTab(id)}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        classes={{ root: 'w-full' }}
      >
        {enabledSections.map(({ key, title, badge, badgeColor = 'default' }) => (
          <Tab
            key={key}
            label={
              <div className="flex flex-row items-center">
                {title}
                {!_isNil(badge) && (
                  <Chip className="ml-4" color={badgeColor} size="small" label={badge} />
                )}
              </div>
            }
          />
        ))}
      </Tabs>
      <div className="md:p-16 max-w-4xl">
        <Content />
      </div>
    </>
  )
}
