import React, { FC } from 'react'
import {
  buildModal,
  Button,
  Checkbox,
  FormControlLabel,
  ModalBody,
  ModalBodyProps,
  TextField,
  useNotifications,
} from '@web-panel/shared'
import {
  ContractPackageSetting,
  ContractPartnerSetting,
  ContractPartnerSettingConfigInput,
  Outlet,
} from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useAddFeatureContractSetting, useContractPackageSettings } from '@web-panel/oc-api'
import { packageSettingToInput } from '../Forms/CreateContractPackageForm/helpers'

type FormFields = {
  price: number
  amount: number
  featureSetting: ContractPartnerSettingConfigInput[] | null
}

const validationSchema = yup.object().shape({
  amount: yup.number().min(0).required(),
  price: yup.number().min(0).required(),
})

type AddContractSettingModalProps = ModalBodyProps & {
  outlet: Outlet
  features: ContractPartnerSetting[]
  businessCategoryId: string
  packageCode: string
  resourceName?: string
  onAdd: () => Promise<void>
}
const AddContractSettingModal: FC<AddContractSettingModalProps> = ({
  outlet,
  businessCategoryId,
  packageCode,
  features,
  resourceName,
  onAdd,
  onClose,
}) => {
  const { pushNotification } = useNotifications()
  const { formatMessage } = useIntl()
  const { request: addContractSetting, loading } = useAddFeatureContractSetting()

  const countryCode = outlet?.location?.country?.code

  const { data: contractPackageSettings } = useContractPackageSettings({
    countryCode,
    businessCategoryId,
    packageCode,
  })

  const onSubmit = async (values: FormFields) => {
    try {
      values.featureSetting?.map(async (feature) => {
        if (feature != null)
          await addContractSetting({
            partnerId: outlet.partnerId,
            outletId: outlet.id,
            input: {
              amount: feature?.amount || 0,
              price: feature.price,
              currencyCode: feature.currencyCode,
              countryCode: feature.countryCode,
              name: feature.name,
              description: feature.description,
              settingType: feature.settingType,
              contractSettingConfigId: feature.contractSettingConfigId,
            },
          })
        await onAdd()
      })

      onClose()
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  let newSettings: ContractPackageSetting[] = []

  const settings = contractPackageSettings?.filter(
    (packages) => packages.contractSettingConfig?.features.length > 0
  )

  if (features.length === 0) {
    newSettings = settings!
  } else
    features?.forEach((element) => {
      settings?.forEach((_element) => {
        if (element.contractSettingConfig.id !== _element.contractSettingConfig.id) {
          newSettings.push(_element)
        }
      })
    })

  return (
    <Formik<FormFields>
      initialValues={{
        price: 0,
        amount: 0,
        featureSetting: [],
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, setFieldValue, handleSubmit, isValid, dirty }) => (
        <ModalBody
          title={
            formatMessage({
              id: 'models.contract-package-business.contract-features',
            }) ?? ''
          }
          actions={[
            <Button key="close-button" onClick={onClose}>
              <FormattedMessage id="common.actions.cancel" />
            </Button>,
            <Button
              disabled={!isValid || !dirty}
              loading={loading}
              key="submit-button"
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
            >
              <FormattedMessage id="common.actions.add" />
            </Button>,
          ]}
        >
          {settings?.length === features.length ? (
            <div
              style={{
                height: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              No feature available
            </div>
          ) : (
            newSettings?.map((_setting, index) => [
              <div>
                <FormControlLabel
                  label={formatMessage({
                    id: `models.contract-package-business.contract.features.${_setting.contractSettingConfig?.features[0]?.id
                      .replaceAll('_', '-')
                      .toLowerCase()}`,
                  })}
                  control={
                    <Checkbox
                      checked={!!values.featureSetting && !!values.featureSetting[index]}
                      onChange={() => {
                        if (values.featureSetting && values.featureSetting[index]) {
                          setFieldValue(`featureSetting.${index}`, null)
                        } else {
                          setFieldValue(`featureSetting.${index}`, packageSettingToInput(_setting))
                        }
                      }}
                    />
                  }
                />
              </div>,
              values.featureSetting && values.featureSetting[index] && (
                <TextField
                  className="mt-8"
                  fullWidth
                  type="number"
                  label={formatMessage({
                    id: 'models.contract-partner-setting.price',
                  })}
                  onChange={(e) => {
                    if (!values.featureSetting) return
                    const price = e.target.value

                    setFieldValue(`featureSetting.${index}`, {
                      ...values.featureSetting[index],
                      price,
                    })
                  }}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  error={!!errors?.featureSetting?.price}
                />
              ),
            ])
          )}
        </ModalBody>
      )}
    </Formik>
  )
}

export default buildModal(AddContractSettingModal)
