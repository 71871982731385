import { ContainerCapacityType, ContainerType } from '@web-panel/api'
import * as Yup from 'yup'

export type ParsedContainersData = {
  qrCode: string
  type: ContainerType
  capacity: ContainerCapacityType
}

export type ParseResult = {
  validItems: ParsedContainersData[]
  totalCount: number
  invalidLines: number[]
}

const validationSchema = Yup.object().shape({
  qrCode: Yup.string().required(),
  capacity: Yup.string().oneOf(Object.values(ContainerCapacityType)).required(),
})

export function parseContainersCSVFile(str: string): ParseResult {
  const lines = str.split(/\r?\n/).filter((s) => s.length > 0)
  const result = lines.reduce<ParseResult>(
    (res, line, id) => {
      const [qrCode = '', capacity = ''] = line.replace(/"/g, '').split(/[,;]/)
      const item = {
        qrCode,
        type: ContainerType.Container,
        capacity,
      }
      if (validationSchema.isValidSync(item)) {
        return {
          ...res,
          validItems: [...res.validItems, item as ParsedContainersData],
        }
      }
      return {
        ...res,
        invalidLines: [...res.invalidLines, id],
      }
    },
    {
      validItems: [],
      totalCount: lines.length,
      invalidLines: [],
    }
  )

  return result
}
