import { gql } from '@apollo/client'
import {
  BusinessCategory,
  BusinessCategoryGroupType,
  ContractPackageBusinessCategory,
  ContractPackageCode,
  ContractPackageGroup,
  ContractPackageSetting,
  ContractPartnerSetting,
  ContractPartnerSettingConfigInput,
  ContractSettingConfig,
  ContractSettingConfigurationType,
  ContractSettingType,
  OneTimeContractSetting,
  OneTimeContractSettingType,
  Pagination,
  businessCategoryFields,
  contractPackageBusinessCategoryFields,
  contractPackageGroupFields,
  contractPackageSettingFields,
  contractPartnerSettingFields,
  contractSettingConfigFields,
  featureContractPartnerSettingFields,
  oneTimeContractSettingFields,
} from '@web-panel/api'

type ContractSettingFrequencyType = 'once' | 'recurrent'

export type ContractSettingsDocumentInput = {
  frequencyType: ContractSettingFrequencyType
  filters: Partial<{
    configurationType: ContractSettingConfigurationType
  }>
  pagination: Pagination
}
export type ContractSettingsDocumentResponse = {
  contractSettings: {
    contractSettingConfigs: ContractSettingConfig[]
    hasNext: boolean
  }
}
export const ContractSettingsDocument = gql`
  query ContractSettingOnce($frequencyType: String!, $filters: any, $pagination: any) {
    contractSettings(frequencyType: $frequencyType, filters: $filters, pagination: $pagination)
      @rest(
        type: "ContractSettingsCollection",
        path: "/partners-service/contract/configs/{args.frequencyType}?{args.pagination}&{args.filters}",
        method: "GET"
      ) {
        contractSettingConfigs @type(name: "ContractSettingsConfig") {
          ${contractSettingConfigFields}
        }
        hasNext
      }
  }
`

export type ContractPackageGroupsDocumentInput = {
  countryCode: string
  businessCategoryId: string
}
export type ContractPackageGroupsDocumentResponse = {
  contractPackageGroupsCollection: {
    contractPackageGroups: ContractPackageGroup[]
  }
}
export const ContractPackageGroupsDocument = gql`
  query ContractPackageGroups($countryCode: String!, $businessCategoryId: String!) {
    contractPackageGroupsCollection(countryCode: $countryCode, businessCategoryId: $businessCategoryId)
      @rest(
        type: "ContractPackageGroupCollection",
        path: "/partners-service/contract/packages/{args.countryCode}/business/category/{args.businessCategoryId}",
        method: "GET"
      ) {
        contractPackageGroups @type(name: "ContractPackageGroup") {
          ${contractPackageGroupFields}
        }
      }
  }
`

export type ContractPackageSettingsDocumentInput = {
  countryCode: string
  businessCategoryId: string
  packageCode: string
  pagination: Pagination
}
export type ContractPackageSettingsDocumentResponse = {
  contractPackageSettingsCollection: {
    contractPackageSettings: ContractPackageSetting[]
    hasNext: boolean
  }
}
export const ContractPackageSettingsDocument = gql`
  query ContractPackageSettings($countryCode: String!, $businessCategoryId: String!, $packageCode: String!, $pagination: any) {
    contractPackageSettingsCollection(countryCode: $countryCode, businessCategoryId: $businessCategoryId, packageCode: $packageCode, pagination: $pagination)
      @rest(
        type: "ContractPackageSettingsCollection",
        path: "/partners-service/contract/packages/{args.countryCode}/business/category/{args.businessCategoryId}/code/{args.packageCode}?{args.pagination}",
        method: "GET"
      ) {
        contractPackageSettings @type(name: "ContractPackageSetting") {
          ${contractPackageSettingFields}
        }
        hasNext
      }
  }
`
export type ContractPartnerSettingsDocumentInput = {
  partnerId: string
  outletId: string
  pagination: Pagination
}
export type ContractPartnerSettingsDocumentResponse = {
  contractPartnerSettings: {
    category: ContractPackageBusinessCategory | null
    contractPackageCode: ContractPackageCode
    contractSettings: ContractPartnerSetting[]
    hasNext: boolean
  }
}

export const ContractPartnerSettingsDocument = gql`
  query ContractPartnerSettings($partnerId: String!, $outletId: String!) {
    contractPartnerSettings(partnerId: $partnerId, outletId: $outletId)
      @rest(
        type: "ContractPartnerSettingsCollection",
        path: "/partners-service/contract/partner/{args.partnerId}/settings/{args.outletId}",
        method: "GET"
      ) {
        category {
          ${contractPackageBusinessCategoryFields}
        }
        contractPackageCode
        contractSettings @type(name: "ContractPartnerSetting") {
          ${contractPartnerSettingFields}
        }
        hasNext
      }
  }
`

export type CreateContractPartnerPackageDocumentInput = {
  partnerId: string
  outletId: string
  options?: Partial<{
    overrideSettings: boolean
  }>
  input: {
    businessCategoryId: string
    contractPackageCode: ContractPackageCode
    partnerContractSettings: ContractPartnerSettingConfigInput[]
  }
}

export type CreateContractPartnerSettingsDocumentResponse = {
  createContractPartnerPackage: {
    category: BusinessCategory
    contractPackageCode: ContractPackageCode
    contractSettings: ContractPartnerSetting[]
  }
}

export const CreateContractPartnerPackageDocument = gql`
  mutation CreateContractPartnerPackage($partnerId: String!, $outletId: String!, $options: any, $input: any) {
    createContractPartnerPackage(partnerId: $partnerId, outletId: $outletId, options: $options, input: $input)
      @rest(
        type: "ContractPartnerSettings",
        path: "/partners-service/contract/partner/{args.partnerId}/settings/{args.outletId}/package?{args.options}",
        method: "POST"
      ) {
          category {
            ${contractPackageBusinessCategoryFields}
          }
          contractPackageCode
          contractSettings {
            ${contractPartnerSettingFields}
          }
        }
  }
`

export type BusinessCategoriesDocumentInput = {
  businessCategoryType: BusinessCategoryGroupType
  parentCategoryId?: string
}
export type BusinessCategoriesDocumentResponse = {
  businessCategories: {
    categories: BusinessCategory[]
  }
}

export const BusinessCategoriesDocument = gql`
  query BusinessCategories($businessCategoryType: String!, $parentCategoryId: String) {
    businessCategories(businessCategoryType: $businessCategoryType, parentCategoryId: $parentCategoryId)
      @rest(
        type: "BusinessCategoriesCollection"
        path: "/partners-service/business/categories?{args}"
        method: "GET"
      ) {
      categories @type(name: "BusinessCategory") {
        ${businessCategoryFields}
      }
    }
  }
`

export type BusinessCategoryDocumentInput = {
  id: string
}
export type BusinessCategoryDocumentResponse = {
  businessCategory: ContractPackageBusinessCategory
}
export const BusinessCategoryDocument = gql`
  query BusinessCategory($id: String!) {
    businessCategory(id: $id)
      @rest(
        type: "BusinessCategory"
        path: "/partners-service/business/categories/{args.id}"
        method: "GET"
      ) {
      ${businessCategoryFields}
    }
  }
`

export type OneTimeContractSettingsDocumentInput = {
  partnerId: string
  outletId: string
  pagination: Pagination
}
export type OneTimeContractSettingsDocumentResponse = {
  oneTimeContractSettingsCollection: {
    contractSettings: OneTimeContractSetting[]
    hasNext: boolean
  }
}

export const OneTimeContractSettingsDocument = gql`
  query OneTimeContractSettings($partnerId: String!, $outletId: String!, $pagination: any) {
    oneTimeContractSettingsCollection(partnerId: $partnerId, outletId: $outletId, pagination: $pagination)
      @rest(
        type: "OneTimeContractSettingsCollection"
        path: "/partners-service/contract/partner/{args.partnerId}/settings/{args.outletId}/once?{args.pagination}"
        method: "GET"
      ) {
      contractSettings @type(name: "OneTimeContractSetting") {
        ${oneTimeContractSettingFields}
      }
      hasNext
    }
  }
`

export type OneTimeContractSettingDocumentInput = {
  partnerId: string
  outletId: string
  contractSettingId: string
}

export type OneTimeContractSettingDocumentResponse = {
  oneTimeContractSetting: OneTimeContractSetting
}

export const OneTimeContractSettingDocument = gql`
  query OneTimeContractSetting($partnerId: String!, $outletId: String!, $contractSettingId: String!) {
    oneTimeContractSetting(partnerId: $partnerId, outletId: $outletId, contractSettingId: $contractSettingId)
      @rest(
        type: "OneTimeContractSetting",
        path: "/partners-service/contract/partner/{args.partnerId}/settings/{args.outletId}/id/{args.contractSettingId}/once"
        method: "GET"
      ) {
        ${oneTimeContractSettingFields}
      }
  }
`

export type OneTimeContractPackageTemplatesDocumentInput = {
  countryCode: string
  pagination: Pagination
}
export type OneTimeContractPackageTemplatesDocumentResponse = {
  oneTimeContractPackageTemplates: {
    contractPackageSettings: ContractPackageSetting[]
    hasNext: boolean
  }
}
export const OneTimeContractPackageTemplatesDocument = gql`
  query OneTimeContractPackageTemplates($countryCode: String!, $pagination: any) {
    oneTimeContractPackageTemplates(countryCode: $countryCode, pagination: $pagination)
      @rest(
        type: "OneTimeContractPackageTemplatesCollection"
        path: "/partners-service/contract/packages/{args.countryCode}/once?{args.pagination}"
        method: "GET"
      ) {
        contractPackageSettings {
          ${contractPackageSettingFields}
        }
        hasNext
      }
  }
`

type SettingInput = {
  price: number
  currencyCode: string
  amount: number
  name: string
  description: string
  settingType: ContractSettingType
}

type FeatureContractSettingInput = {
  price: number
  currencyCode: string
  countryCode: string
  amount: number
  name: string
  description: string
  settingType: ContractSettingType
  contractSettingConfigId: string
}

type OneTimeSettingInput = {
  validationDate: string
  price: number
  currencyCode: string
  amount: number
  name: string
  description: string
  settingType: OneTimeContractSettingType | ContractSettingType
  contractSettingConfigId: string
}

export type CreateOneTimeContractSettingDocumentInput = {
  partnerId: string
  outletId: string
  input: OneTimeSettingInput
}
export type CreateOneTimeContractSettingDocumentResponse = {
  createOneTimeContractSetting: OneTimeContractSetting
}

export const CreateOneTimeContractSettingDocument = gql`
  mutation CreateOneTimeContractSetting($partnerId: String!, $outletId: String!, $input: any) {
    createOneTimeContractSetting(partnerId: $partnerId, outletId: $outletId, input: $input)
      @rest(
        type: "OneTimeContractSetting",
        path: "/partners-service/contract/partner/{args.partnerId}/settings/{args.outletId}/once",
        method: "POST"
      ) {
        ${oneTimeContractSettingFields}
      }
  }
`

export type UpdateOneTimeContractSettingDocumentInput = {
  partnerId: string
  outletId: string
  contractSettingId: string
  input: Omit<OneTimeSettingInput, 'contractSettingConfigId' | 'validationDate'>
}
export type UpdateOneTimeContractSettingDocumentResponse = {
  updateOneTimeContractSetting: OneTimeContractSetting
}
export const UpdateOneTimeContractSettingDocument = gql`
  mutation UpdateOneTimeContractSetting($partnerId: String!, $outletId: String!, $contractSettingId: String!, $input: any) {
    updateOneTimeContractSetting(partnerId: $partnerId, outletId: $outletId, contractSettingId: $contractSettingId, input: $input)
      @rest(
        type: "OneTimeContractSetting",
        path: "/partners-service/contract/partner/{args.partnerId}/settings/{args.outletId}/id/{args.contractSettingId}/once",
        method: "PUT"
      ) {
        ${oneTimeContractSettingFields}
      }
  }
`

export type DeletePartnerContractSettingDocumentInput = {
  partnerId: string
  outletId: string
  contractSettingId: string
}
export type DeletePartnerContractSettingDocumentResponse = void
export const DeletePartnerContractSettingDocument = gql`
  mutation DeletePartnerContractSetting(
    $partnerId: String!
    $outletId: String!
    $contractSettingId: String!
  ) {
    deletePartnerContractSetting(
      partnerId: $partnerId
      outletId: $outletId
      contractSettingId: $contractSettingId
    )
      @rest(
        type: "NoResponse"
        path: "/partners-service/contract/partner/{args.partnerId}/settings/{args.outletId}/id/{args.contractSettingId}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`

export type UpdateContractSettingDocumentInput = {
  partnerId: string
  outletId: string
  contractSettingId: string
  input: SettingInput
}
export type UpdateContractSettingDocumentResponse = {
  updateContractPartnerSetting: ContractPartnerSetting
}
export const UpdateContractSettingDocument = gql`
  mutation UpdateContractPartnerSetting($partnerId: String!, $outletId: String!, $contractSettingId: String!, $input: any) {
    updateContractPartnerSetting(partnerId: $partnerId, outletId: $outletId, contractSettingId: $contractSettingId, input: $input)
      @rest(
        type: "ContractPartnerSetting",
        path: "/partners-service/contract/partner/{args.partnerId}/settings/{args.outletId}/id/{args.contractSettingId}",
        method: "PUT"
      ) {
        ${contractPartnerSettingFields}
      }
  }
`

export type AddFeatureContractSettingDocumentInput = {
  partnerId: string
  outletId: string
  input: FeatureContractSettingInput
}
export type AddFeatureContractSettingDocumentResponse = {
  addFeatureContractPartnerSetting: ContractPartnerSettingConfigInput
}
export const AddFeatureContractSettingDocument = gql`
  mutation AddFeatureContractSetting($partnerId: String!, $outletId: String!, $input: any) {
    addFeatureContractPartnerSetting(partnerId: $partnerId, outletId: $outletId, input: $input)
      @rest(
        type: "ContractPartnerSetting",
        path: "/partners-service/contract/partner/{args.partnerId}/settings/{args.outletId}",
        method: "POST"
      ) {
        ${featureContractPartnerSettingFields}
      }
  }
`
