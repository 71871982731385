import { FC } from 'react'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import PartnerAutocompleteInput from '@local/components/Autocomplete/PartnerAutocompleteInput'
import { Partner } from '@web-panel/api'
import { DataFormSection, DataFormFields, dataValidationSchema } from './Sections/DataFormSection'

export type FormFields = {
  partner: Partner | null
} & DataFormFields

export const validationSchema = Yup.object()
  .shape({
    partner: Yup.object().required(),
  })
  .concat(dataValidationSchema)

type CreateRentalFormProps = {
  showPartnerSelector: boolean
}
export const CreateRentalForm: FC<CreateRentalFormProps> = ({ showPartnerSelector }) => {
  const { values, errors, setValues, handleSubmit } = useFormikContext<FormFields>()
  const { formatMessage } = useIntl()

  return (
    <form className="w-full" onSubmit={handleSubmit}>
      <div className="flex flex-col items-left gap-16 max-w-2xl">
        {showPartnerSelector && (
          <PartnerAutocompleteInput
            fullWidth
            label={formatMessage({ id: 'models.order.partner' })}
            value={values.partner}
            error={!!errors.partner}
            onChange={(partner) =>
              setValues({
                ...values,
                partner,
                outletId: null,
              })
            }
          />
        )}
        {values.partner && <DataFormSection partnerId={values.partner.id} />}
      </div>
    </form>
  )
}
