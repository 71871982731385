import { FC } from 'react'
import {
  Outlet,
  locationAddressString,
  useCountries,
  getOutletLegalAddressWithDefault,
  isOutletHasLegalAddress,
} from '@web-panel/api'
import { HorizontalTable, HorizontalTableSection, HorizontalTableRow } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { PartnerLink } from '@local/components/PartnerLink'
import OutletStatusChip from '@local/components/OutletStatusChip'

import { usePartner } from '@web-panel/oc-api'

type PreviewTableProps = {
  data: Outlet
}
export const PreviewTable: FC<PreviewTableProps> = ({ data }) => {
  const { formatMessage } = useIntl()
  const { data: countries = [] } = useCountries()
  const { data: partner } = usePartner({ id: data.partnerId })
  if (!partner || countries.length === 0) return null

  const legalAddress = getOutletLegalAddressWithDefault(data, partner)
  const legalCountry = countries.find(({ code }) => code === legalAddress?.countryCode)

  return (
    <HorizontalTable>
      <HorizontalTableRow title={formatMessage({ id: 'models.outlet.location.name' })}>
        {data.location.name}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.outlet.partner' })}>
        <PartnerLink partnerId={data.partnerId} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.outlet.type' })}>
        <FormattedMessage id={`outlet-types.${data.type}`} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.outlet.has-box' })}>
        <FormattedMessage id={data.hasBox ? 'common.yes' : 'common.no'} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.outlet.status' })}>
        <OutletStatusChip status={data.status} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.outlet.featured' })}>
        <FormattedMessage id={data.featured ? 'common.yes' : 'common.no'} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.outlet.hidden-on-map' })}>
        <FormattedMessage id={data.hiddenOnMap ? 'common.yes' : 'common.no'} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.outlet.cycle-type' })}>
        <FormattedMessage id={`outlet-cycle-types.${data.cycleType}`} />
      </HorizontalTableRow>
      <HorizontalTableSection
        icon="person"
        title={formatMessage({ id: 'models.outlet.contact-person' })}
      />
      <HorizontalTableRow title={formatMessage({ id: 'models.outlet.contact-person.name' })}>
        {[data.firstName, data.lastName].join(' ')}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.outlet.contact-person.phone' })}>
        {data.phone}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.outlet.contact-person.email' })}>
        {data.email}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.outlet.operations-phone' })}>
        {data.operationsPhone}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.outlet.operations-email' })}>
        {data.operationsEmail}
      </HorizontalTableRow>
      <HorizontalTableSection
        icon="location_on"
        title={formatMessage({ id: 'models.outlet.location' })}
      />
      <HorizontalTableRow title={formatMessage({ id: 'models.outlet.location.address' })}>
        {locationAddressString(data.location)}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.location.latitude' })}>
        {data.location.latitude}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.location.longitude' })}>
        {data.location.longitude}
      </HorizontalTableRow>

      <HorizontalTableSection title={formatMessage({ id: 'models.order' })} />
      <HorizontalTableRow title={formatMessage({ id: 'models.outlet.batch-default-size' })}>
        {data.batchDefaultSize}
      </HorizontalTableRow>

      <HorizontalTableSection
        title={formatMessage({ id: 'models.outlet.legal-address' })}
        description={
          !isOutletHasLegalAddress(data)
            ? formatMessage({ id: 'common.labels.from-partner' })
            : undefined
        }
      />
      {legalAddress && (
        <>
          <HorizontalTableRow title={formatMessage({ id: 'models.outlet.legal-address.name' })}>
            {legalAddress.name}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.outlet.legal-address.country' })}>
            {legalCountry?.name}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.outlet.legal-address.city' })}>
            {legalAddress.city}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.outlet.legal-address.street' })}>
            {legalAddress.street}
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.outlet.legal-address.house-number' })}
          >
            {legalAddress.houseNumber}
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.outlet.legal-address.postal-code' })}
          >
            {legalAddress.postalCode}
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.outlet.legal-address.additional-address-line-1' })}
          >
            {legalAddress.legalAdditionalAddressLines[0]}
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.outlet.legal-address.additional-address-line-2' })}
          >
            {legalAddress.legalAdditionalAddressLines[1]}
          </HorizontalTableRow>
        </>
      )}
    </HorizontalTable>
  )
}
