import { FC } from 'react'
import {
  OutletType,
  WorkingHours as WorkingHoursData,
  OutletLegalAddress,
  Outlet,
  OutletCycleType,
} from '@web-panel/api'
import {
  TextField,
  LabeledSelect,
  WorkingHoursInput,
  FormSection,
  Button,
  Icon,
  LabeledSwitch,
  useModalRef,
} from '@web-panel/shared'
import { FormikProps } from 'formik'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import CountryCodeAutocomplete from '@local/components/Autocomplete/CountryCodeAutocomplete'
import CityCodeAutocomplete from '@local/components/Autocomplete/CityCodeAutocomplete'
import PinDropModal from '@local/modals/PinDropModal'
import { usePartner } from '@web-panel/oc-api'
import { LegalAddressSection } from './sections/LegalAddressSection'
import {
  OrderSection,
  OrderSectionFormFields,
  orderSectionValidationSchema,
} from './sections/OrderSection'

export type FormFields = {
  type: OutletType
  country: string
  city: string
  location: {
    name: string
    street: string
    houseNumber: string
    latitude: number
    longitude: number
    postalCode: string
    workingHours: WorkingHoursData[]
  }
  legalAddress: OutletLegalAddress | null
  firstName: string
  lastName: string
  phone: string
  email: string
  operationsPhone: string | null
  operationsEmail: string | null
  featured: boolean
  hiddenOnMap: boolean
  cycleType: OutletCycleType
} & OrderSectionFormFields

export const validationSchema = Yup.object()
  .shape({
    country: Yup.string().required(),
    city: Yup.string().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    phone: Yup.string().required(),
    email: Yup.string().email().required(),
    operationsPhone: Yup.string().nullable(),
    operationsEmail: Yup.string().nullable(),
    location: Yup.object().shape({
      name: Yup.string().required(),
      street: Yup.string().required(),
      houseNumber: Yup.string().required(),
      postalCode: Yup.string().required(),
      latitude: Yup.number().required(),
      longitude: Yup.number().required(),
    }),
    legalAddress: Yup.object()
      .shape({
        countryCode: Yup.string().required(),
        city: Yup.string().required(),
        street: Yup.string().required(),
        houseNumber: Yup.string().required(),
        postalCode: Yup.string().required(),
      })
      .nullable(),
    featured: Yup.boolean(),
    hiddenOnMap: Yup.boolean(),
  })
  .concat(orderSectionValidationSchema)

type OutletFormProps = FormikProps<FormFields> & { outlet: Outlet }
const OutletForm: FC<OutletFormProps> = ({
  outlet,
  values,
  errors,
  setFieldValue,
  setValues,
  handleChange,
  handleSubmit,
}) => {
  const { formatMessage } = useIntl()
  const { data: partner } = usePartner({ id: outlet.partnerId })
  const pinDropModalRef = useModalRef()

  if (!partner) return null

  return (
    <form className="p-24 max-w-2xl" onSubmit={handleSubmit}>
      <FormSection firstSection title={formatMessage({ id: 'models.outlet.location' })}>
        <CountryCodeAutocomplete
          fullWidth
          enabledFor="location"
          label={formatMessage({ id: 'models.outlet.location.country' })}
          onChange={(countryCode) => setValues({ ...values, country: countryCode ?? '', city: '' })}
          value={values.country}
          error={!!errors.country}
        />
        <CityCodeAutocomplete
          fullWidth
          className="mt-16"
          countryCode={values.country}
          label={formatMessage({ id: 'models.outlet.location.city' })}
          onChange={(cityCode) => setFieldValue('city', cityCode)}
          value={values.city}
          error={!!errors.city}
        />
      </FormSection>
      <FormSection title={formatMessage({ id: 'models.outlet.general-info' })}>
        <TextField
          fullWidth
          className="mb-16 md:mb-0 md:mr-16"
          label={formatMessage({ id: 'models.outlet.name' })}
          value={values.location.name}
          onChange={handleChange('location.name')}
          error={!!errors.location?.name}
        />
        <LabeledSelect
          fullWidth
          className="mt-16"
          label={formatMessage({ id: 'models.outlet.type' })}
          value={values.type}
          options={Object.values(OutletType)}
          optionTitle={(type) => formatMessage({ id: `outlet-types.${type}` })}
          onChange={(e) => handleChange('type')(e.target.value)}
          error={!!errors.type}
        />
        <div className="flex flex-row mt-16">
          <TextField
            fullWidth
            className="mr-8"
            label={formatMessage({ id: 'models.location.latitude' })}
            value={values.location.latitude}
            onChange={handleChange('location.latitude')}
            error={!!errors.location?.latitude}
          />
          <TextField
            fullWidth
            className="ml-8"
            label={formatMessage({ id: 'models.location.longitude' })}
            value={values.location.longitude}
            onChange={handleChange('location.longitude')}
            error={!!errors.location?.longitude}
          />
          <Button
            className="mt-16 w-full md:w-auto md:ml-16 md:mt-0"
            variant="outlined"
            onClick={() => pinDropModalRef.current?.open()}
          >
            <Icon>my_location</Icon>
          </Button>
        </div>
        <LabeledSwitch
          className="mt-16"
          checked={values.featured}
          label={formatMessage({ id: 'models.outlet.featured' })}
          onChange={handleChange('featured')}
        />
        <LabeledSwitch
          className="mt-16"
          checked={values.hiddenOnMap}
          label={formatMessage({ id: 'models.outlet.hidden-on-map' })}
          onChange={handleChange('hiddenOnMap')}
        />
        <LabeledSelect
          fullWidth
          className="mt-16"
          label={formatMessage({ id: 'models.outlet.cycle-type' })}
          value={values.cycleType}
          options={Object.values(OutletCycleType)}
          optionTitle={(cycleType) => formatMessage({ id: `outlet-cycle-types.${cycleType}` })}
          onChange={(e) => handleChange('cycleType')(e.target.value)}
          error={!!errors.cycleType}
        />
      </FormSection>
      <OrderSection />
      <FormSection title={formatMessage({ id: 'models.outlet.contact-person' })}>
        <TextField
          fullWidth
          label={formatMessage({ id: 'models.outlet.contact-person.first-name' })}
          value={values.firstName}
          onChange={handleChange('firstName')}
          error={!!errors.firstName}
        />
        <TextField
          fullWidth
          className="mt-16"
          label={formatMessage({ id: 'models.outlet.contact-person.last-name' })}
          value={values.lastName}
          onChange={handleChange('lastName')}
          error={!!errors.lastName}
        />
        <TextField
          fullWidth
          className="mt-16"
          label={formatMessage({ id: 'models.outlet.contact-person.email' })}
          value={values.email}
          onChange={handleChange('email')}
          error={!!errors.email}
        />
        <TextField
          fullWidth
          className="mt-16"
          label={formatMessage({ id: 'models.outlet.contact-person.phone' })}
          value={values.phone}
          onChange={handleChange('phone')}
          error={!!errors.phone}
        />
        <TextField
          fullWidth
          className="mt-16"
          label={formatMessage({ id: 'models.outlet.operations-phone' })}
          value={values.operationsPhone}
          onChange={handleChange('operationsPhone')}
          error={!!errors.operationsPhone}
        />
        <TextField
          fullWidth
          className="mt-16"
          label={formatMessage({ id: 'models.outlet.operations-email' })}
          value={values.operationsEmail}
          onChange={handleChange('operationsEmail')}
          error={!!errors.operationsEmail}
        />
      </FormSection>
      <FormSection title={formatMessage({ id: 'models.outlet.address' })}>
        <TextField
          fullWidth
          label={formatMessage({ id: 'models.outlet.location.street' })}
          value={values.location.street}
          onChange={handleChange('location.street')}
          error={!!errors.location?.street}
        />
        <TextField
          fullWidth
          className="mt-16"
          label={formatMessage({ id: 'models.outlet.location.house-number' })}
          value={values.location.houseNumber}
          onChange={handleChange('location.houseNumber')}
          error={!!errors.location?.houseNumber}
        />
        <TextField
          fullWidth
          className="mt-16"
          label={formatMessage({ id: 'models.outlet.location.postal-code' })}
          value={values.location.postalCode}
          onChange={handleChange('location.postalCode')}
          error={!!errors.location?.postalCode}
        />
      </FormSection>
      <LegalAddressSection partner={partner} />
      <FormSection title={formatMessage({ id: 'models.outlet.location.working-hours' })}>
        <WorkingHoursInput
          value={values.location.workingHours}
          onChange={(workingHours) => setFieldValue('location.workingHours', workingHours, true)}
          dayLabels={{
            monday: formatMessage({ id: 'time.day-of-week.short.monday' }),
            tuesday: formatMessage({ id: 'time.day-of-week.short.tuesday' }),
            wednesday: formatMessage({ id: 'time.day-of-week.short.wednesday' }),
            thursday: formatMessage({ id: 'time.day-of-week.short.thursday' }),
            friday: formatMessage({ id: 'time.day-of-week.short.friday' }),
            saturday: formatMessage({ id: 'time.day-of-week.short.saturday' }),
            sunday: formatMessage({ id: 'time.day-of-week.short.sunday' }),
          }}
          actionLabels={{
            add: formatMessage({ id: 'common.actions.add' }),
            delete: formatMessage({ id: 'common.actions.delete' }),
          }}
          timeFrameLabels={{
            opening: formatMessage({ id: 'models.outlet.location.opening-time' }),
            closing: formatMessage({ id: 'models.outlet.location.closing-time' }),
          }}
        />
      </FormSection>
      <PinDropModal
        ref={pinDropModalRef}
        lat={values.location.latitude}
        lng={values.location.longitude}
        onUpdate={({ lat, lng }) => {
          setFieldValue('location', {
            ...values.location,
            latitude: lat,
            longitude: lng,
          })
        }}
      />
    </form>
  )
}

export default OutletForm
