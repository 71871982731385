import { FC, useState, useRef } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Popover from '@mui/material/Popover'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import { useIntl } from 'react-intl'
import { useLocale } from '../../contexts/Locale'

export const LanguageSwitcher: FC = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const buttonRef = useRef<HTMLDivElement | null>(null)
  const { locale, availableLocales, setLocale } = useLocale()
  const { formatMessage } = useIntl()

  const locales = availableLocales.map(({ id, icon, titleKey }) => ({
    id,
    icon,
    title: formatMessage({ id: titleKey }),
  }))
  const currentLocale = locales.find(({ id }) => id === locale) ?? locales[0]

  const handleMenuClick = (newLocale: string) => {
    setLocale(newLocale)
    setMenuOpen(false)
  }

  return (
    <>
      <div ref={buttonRef}>
        <Button className="h-40 w-64" onClick={() => setMenuOpen(!menuOpen)}>
          <img className="mx-4 min-w-20" src={currentLocale.icon} alt={currentLocale.title} />
          <Typography className="mx-4 font-semibold uppercase">{currentLocale.id}</Typography>
        </Button>
      </div>
      <Popover
        open={menuOpen}
        anchorEl={buttonRef.current}
        onClose={() => setMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        {locales.map(({ id, icon, title }) => (
          <MenuItem key={id} onClick={() => handleMenuClick(id)}>
            <ListItemIcon className="mr-4">
              <img className="w-30" src={icon} alt={title} />
            </ListItemIcon>
            <ListItemText primary={title} />
          </MenuItem>
        ))}
      </Popover>
    </>
  )
}
