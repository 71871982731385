import { VFC } from 'react'
import CountryAutocompleteInput from '@local/components/Autocomplete/CountryAutocompleteInput'
import { Country, City, WorkingHours } from '@web-panel/api'
import {
  Button,
  Icon,
  InputAdornment,
  TextField,
  useModalRef,
  WorkingHoursInput,
} from '@web-panel/shared'
import { useFormikContext } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import CityAutocompleteInput from '@local/components/Autocomplete/CityAutocompleteInput'
import GooglePlacesModal from '@local/modals/GooglePlacesModal'
import PinDropModal from '@local/modals/PinDropModal'
import * as Yup from 'yup'

export type DispenserLocationFormFields = {
  country: Country | null
  city: City | null
  name: string
  googlePlaceId: string | null
  latitude: string | null
  longitude: string | null
  street: string
  houseNumber: string
  postalCode: string
  workingHours: WorkingHours[]
}

export const dispenserLocationFormInitialFields: DispenserLocationFormFields = {
  country: null,
  city: null,
  name: '',
  googlePlaceId: '',
  latitude: null,
  longitude: null,
  street: '',
  houseNumber: '',
  postalCode: '',
  workingHours: [],
}

export const dispenserLocationFormValidationSchema = Yup.object().shape({
  country: Yup.object().required(),
  city: Yup.object().required(),
  name: Yup.string().required(),
  googlePlaceId: Yup.string().nullable(),
  latitude: Yup.number().required(),
  longitude: Yup.number().required(),
  street: Yup.string().required(),
  houseNumber: Yup.string().required(),
  postalCode: Yup.string().required(),
  workingHours: Yup.array().required(),
})

export const DispensersLocationForm: VFC = () => {
  const { formatMessage } = useIntl()
  const googlePlacesModalRef = useModalRef()
  const pinDropModalRef = useModalRef()

  const { values, errors, setValues, handleSubmit } =
    useFormikContext<DispenserLocationFormFields>()

  const handleChangeCountry = (country: Country | null) => {
    setValues({ ...dispenserLocationFormInitialFields, country })
  }

  const handleChangeCity = (city: City | null) => {
    setValues({ ...dispenserLocationFormInitialFields, country: values.country, city })
  }

  const handleChange = (changedValues: Partial<DispenserLocationFormFields>) => {
    setValues({ ...values, ...changedValues })
  }

  const showLocationDetails = values.latitude !== null && values.longitude !== null

  return (
    <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit}>
      <div className="flex flex-col p-16 sm:p-24 max-w-2xl">
        <CountryAutocompleteInput
          fullWidth
          enabledFor="location"
          label={formatMessage({ id: 'models.location.country' })}
          value={values.country}
          onChange={(country) => handleChangeCountry(country)}
          error={!!errors.country}
        />

        {values.country && (
          <CityAutocompleteInput
            fullWidth
            className="mt-16"
            country={values.country}
            label={formatMessage({ id: 'models.location.city' })}
            value={values.city}
            onChange={(city) => handleChangeCity(city)}
          />
        )}

        {values.country && values.city && (
          <>
            <GooglePlacesModal
              ref={googlePlacesModalRef}
              country={values.country}
              city={values.city}
              onSubmit={({ lat, lng, ...place }) => {
                handleChange({
                  latitude: String(lat),
                  longitude: String(lng),
                  ...place,
                })
              }}
            />
          </>
        )}

        {values.country && values.city && !showLocationDetails && (
          <div className="mt-16 text-center">
            <Button variant="contained" onClick={() => googlePlacesModalRef.current?.open()}>
              <FormattedMessage id="common.actions.find-on-google-maps" />
            </Button>
          </div>
        )}
        {showLocationDetails && (
          <>
            <TextField
              fullWidth
              className="mt-16"
              label={formatMessage({ id: 'models.location.name' })}
              value={values.name}
              onChange={(e) => handleChange({ name: e.target.value })}
              error={!!errors.name}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      disabled={!values.city || !values.country}
                      onClick={() => googlePlacesModalRef.current?.open()}
                    >
                      <Icon>edit_location</Icon>
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
            <div className="mt-16 grid grid-cols-3 gap-16">
              <TextField
                fullWidth
                label={formatMessage({ id: 'models.location.latitude' })}
                value={values.latitude}
                error={!!errors.latitude}
                onChange={(e) => handleChange({ latitude: e.target.value })}
              />
              <TextField
                fullWidth
                label={formatMessage({ id: 'models.location.longitude' })}
                value={values.longitude}
                error={!!errors.longitude}
                onChange={(e) => handleChange({ longitude: e.target.value })}
              />
              <div className="flex flex-row">
                <TextField
                  fullWidth
                  label={formatMessage({ id: 'models.location.google-place-id' })}
                  value={values.googlePlaceId ?? ''}
                  error={!!errors.googlePlaceId}
                  disabled
                />
                <Button
                  className="mt-16 w-full md:w-auto md:ml-16 md:mt-0"
                  variant="outlined"
                  onClick={() => pinDropModalRef.current?.open()}
                >
                  <Icon>my_location</Icon>
                </Button>
                <PinDropModal
                  ref={pinDropModalRef}
                  lat={Number(values.latitude)}
                  lng={Number(values.longitude)}
                  onUpdate={({ lat, lng }) =>
                    handleChange({ latitude: String(lat), longitude: String(lng) })
                  }
                />
              </div>
            </div>
            <TextField
              fullWidth
              className="mt-16"
              label={formatMessage({ id: 'models.location.street' })}
              value={values.street}
              error={!!errors.street}
              onChange={(e) => handleChange({ street: e.target.value })}
            />
            <TextField
              fullWidth
              className="mt-16"
              label={formatMessage({ id: 'models.location.house-number' })}
              value={values.houseNumber}
              error={!!errors.houseNumber}
              onChange={(e) => handleChange({ houseNumber: e.target.value })}
            />
            <TextField
              fullWidth
              className="mt-16"
              label={formatMessage({ id: 'models.location.postal-code' })}
              value={values.postalCode}
              error={!!errors.postalCode}
              onChange={(e) => handleChange({ postalCode: e.target.value })}
            />
            <WorkingHoursInput
              value={values.workingHours}
              onChange={(workingHours) => handleChange({ workingHours })}
              dayLabels={{
                monday: formatMessage({ id: 'time.day-of-week.short.monday' }),
                tuesday: formatMessage({ id: 'time.day-of-week.short.tuesday' }),
                wednesday: formatMessage({ id: 'time.day-of-week.short.wednesday' }),
                thursday: formatMessage({ id: 'time.day-of-week.short.thursday' }),
                friday: formatMessage({ id: 'time.day-of-week.short.friday' }),
                saturday: formatMessage({ id: 'time.day-of-week.short.saturday' }),
                sunday: formatMessage({ id: 'time.day-of-week.short.sunday' }),
              }}
              actionLabels={{
                add: formatMessage({ id: 'common.actions.add' }),
                delete: formatMessage({ id: 'common.actions.delete' }),
              }}
              timeFrameLabels={{
                opening: formatMessage({ id: 'models.location.opening-time' }),
                closing: formatMessage({ id: 'models.location.closing-time' }),
              }}
            />
          </>
        )}
      </div>
    </form>
  )
}
