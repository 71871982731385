import { FC, ReactElement } from 'react'
import { Paper } from '@mui/material'
import { motion } from 'framer-motion'

type SearchContainerProps = {
  searchComponent?: ReactElement
}

const SearchContainer: FC<SearchContainerProps> = ({ searchComponent }) => {
  if (!searchComponent) return null

  return (
    <motion.div
      className="flex flex-1 items-center"
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.3 } }}
    >
      <Paper className="flex flex-1 items-center px-8 py-4 rounded-16">{searchComponent}</Paper>
    </motion.div>
  )
}

export default SearchContainer
