import { FC, useMemo } from 'react'
import {
  Typography,
  Slider,
  Box,
  SelectableList,
  buildFilterContext,
  isFeatureFlagEnabled,
} from '@web-panel/shared'
import { BoxStatus, useCities } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'
import { subMinutes } from 'date-fns'
import _omit from 'lodash/omit'
import { operationIntervals } from '@local/constants'
import { useBoxStatusAggregation } from '@web-panel/oc-api'
import { useBoxVersions } from './use-box-versions'

type FilterFields = {
  status: BoxStatus | undefined
  minItems: string | undefined
  maxItems: string | undefined
  minLastSignal: string | undefined
  maxLastSignal: string | undefined
  cityCode: string | undefined
  fwVersion: string | undefined
  operationsInterval: string | undefined
}
export const { useFilters, withFilters } = buildFilterContext<FilterFields>({
  status: isFeatureFlagEnabled('neom_demo') ? BoxStatus.Available : undefined,
  minItems: undefined,
  maxItems: undefined,
  minLastSignal: undefined,
  maxLastSignal: undefined,
  cityCode: undefined,
  fwVersion: undefined,
  operationsInterval: undefined,
})

const minFilterItems = 0
const maxFilterItems = 101
const minFilterLastSignal = 0
const maxFilterLastSignal = 361

type FiltersProps = {
  search?: string
}

const Filters: FC<FiltersProps> = ({ search }) => {
  const { filters, setFilter, setFilters } = useFilters()
  const { formatMessage } = useIntl()
  const pageFilters = usePageFilters()
  const { data: cities = [] } = useCities()
  const { data: boxStatusAggregation = [] } = useBoxStatusAggregation({
    ..._omit(pageFilters, 'status'),
    search,
  })
  const boxVersions = useBoxVersions({ ...pageFilters, search })

  const aggregationsByStatus = useMemo(() => {
    return boxStatusAggregation.reduce(
      (obj, { status, count }) => ({
        ...obj,
        [status]: count,
      }),
      {}
    ) as Record<BoxStatus, number>
  }, [boxStatusAggregation])

  const handleItemsChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') return

    const [newMinItems, newMaxItems] = newValue
    setFilters({
      minItems: newMinItems > minFilterItems ? String(newMinItems) : undefined,
      maxItems: newMaxItems < maxFilterItems ? String(newMaxItems) : undefined,
    })
  }

  const handleLastSignalChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') return

    const [newMinLastSignal, newMaxLastSignal] = newValue
    setFilters({
      minLastSignal: newMinLastSignal > minFilterLastSignal ? String(newMinLastSignal) : undefined,
      maxLastSignal: newMaxLastSignal < maxFilterLastSignal ? String(newMaxLastSignal) : undefined,
    })
  }

  return (
    <div className="flex flex-col">
      <div>
        <Typography className="pl-12" color="grayText">
          <FormattedMessage id="models.box.cups" />
        </Typography>
        <Box className="px-16">
          <Slider
            min={minFilterItems}
            max={maxFilterItems}
            value={[Number(filters.minItems ?? 0), Number(filters.maxItems ?? maxFilterItems)]}
            onChange={handleItemsChange}
            valueLabelDisplay="auto"
            valueLabelFormat={(val) => (val < maxFilterItems ? String(val) : '∞')}
          />
        </Box>
      </div>
      <div>
        <Typography className="pl-12" color="grayText">
          <FormattedMessage id="models.box.last-signal-date" />
        </Typography>
        <Box className="px-16">
          <Slider
            min={minFilterLastSignal}
            max={maxFilterLastSignal}
            value={[
              Number(filters.minLastSignal ?? 0),
              Number(filters.maxLastSignal ?? maxFilterLastSignal),
            ]}
            onChange={handleLastSignalChange}
            valueLabelDisplay="auto"
            valueLabelFormat={(val) =>
              val < maxFilterLastSignal
                ? formatMessage({ id: 'common.minutes.with-value' }, { value: val })
                : '∞'
            }
          />
        </Box>
      </div>
      <SelectableList
        className="mt-16"
        value={filters.status}
        options={Object.values(BoxStatus)}
        optionTitle={(option) => formatMessage({ id: `box-statuses.${option}` })}
        badge={(option) => String(aggregationsByStatus[option] ?? 0)}
        label={formatMessage({ id: 'models.box.status' })}
        onSelect={(status) => setFilter('status', status)}
      />
      <SelectableList
        className="mt-16"
        value={filters.cityCode}
        options={cities.map(({ code }) => code)}
        optionTitle={(cityCode) => cities.find(({ code }) => code === cityCode)?.name ?? ''}
        label={formatMessage({ id: 'models.location.city' })}
        onSelect={(cityCode) => setFilter('cityCode', cityCode)}
      />

      <SelectableList
        className="mt-16"
        value={filters.fwVersion}
        options={Object.keys(boxVersions)}
        optionTitle={(fwVersion) =>
          fwVersion === 'NO_VERSION' ? formatMessage({ id: 'common.labels.no-version' }) : fwVersion
        }
        badge={(version) => String(boxVersions[version])}
        label={formatMessage({ id: 'models.box.fw-version' })}
        onSelect={(fwVersion) => setFilter('fwVersion', fwVersion)}
      />

      <SelectableList
        className="mt-16"
        value={filters.operationsInterval}
        options={operationIntervals.map((h) => String(h))}
        optionTitle={(interval) =>
          formatMessage({ id: 'common.labels.hours' }, { value: Number(interval) })
        }
        label={formatMessage({ id: 'models.box.operations-interval' })}
        onSelect={(interval) => setFilter('operationsInterval', interval)}
      />
    </div>
  )
}

export const usePageFilters = () => {
  const { debouncedFilters } = useFilters()
  const { minLastSignal, maxLastSignal, minItems, maxItems, operationsInterval, ...filters } =
    debouncedFilters

  const { startLastSignalDateTime, endLastSignalDateTime } = useMemo(() => {
    const currentDate = new Date()

    return {
      endLastSignalDateTime:
        minLastSignal && Number(minLastSignal) > minFilterLastSignal
          ? subMinutes(currentDate, Number(minLastSignal))
          : undefined,
      startLastSignalDateTime:
        maxLastSignal && Number(maxLastSignal) < maxFilterLastSignal
          ? subMinutes(currentDate, Number(maxLastSignal))
          : undefined,
    }
  }, [minLastSignal, maxLastSignal])

  return {
    ...filters,
    startLastSignalDateTime,
    endLastSignalDateTime,
    minItems: minItems ? Number(minItems) : undefined,
    maxItems: maxItems ? Number(maxItems) : undefined,
    operationsInterval: operationsInterval ? Number(operationsInterval) : undefined,
  }
}

export default Filters
