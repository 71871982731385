import { Divider, Typography } from '@web-panel/shared'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import sadFaceIcon from '@local/assets/icons/sad-face.svg'

type NotReturnedWidgetProps = {
  from30to60: number
  from60to90: number
  from90to120: number
  moreThan120: number
  total: number
}
export const NotReturnedWidget: FC<NotReturnedWidgetProps> = ({
  from30to60,
  from60to90,
  from90to120,
  moreThan120,
  total,
}) => {
  return (
    <div className="p-28 pb-16 border-3 rounded-20 min-w-200" style={{ borderColor: '#E5E5E5' }}>
      <div className="flex flex-row items-center gap-5">
        <Typography fontSize="1.3rem" lineHeight="1.9rem" fontWeight="600">
          <FormattedMessage id="pages.finma-dashboard.cups-not-returned" />
        </Typography>
        <img src={sadFaceIcon} alt="icon" />
      </div>
      <div className="flex flex-row justify-between mt-9">
        <Typography fontSize="1rem" lineHeight="1.8rem" fontWeight="500">
          <FormattedMessage id="pages.finma-dashboard.days" values={{ from: 30, to: 60 }} />
        </Typography>
        <Typography fontSize="1rem" lineHeight="1.8rem" fontWeight="500">
          {from30to60}
        </Typography>
      </div>
      <div className="flex flex-row justify-between">
        <Typography fontSize="1rem" lineHeight="1.8rem" fontWeight="500">
          <FormattedMessage id="pages.finma-dashboard.days" values={{ from: 60, to: 90 }} />
        </Typography>
        <Typography fontSize="1rem" lineHeight="1.8rem" fontWeight="500">
          {from60to90}
        </Typography>
      </div>
      <div className="flex flex-row justify-between">
        <Typography fontSize="1rem" lineHeight="1.8rem" fontWeight="500">
          <FormattedMessage id="pages.finma-dashboard.days" values={{ from: 90, to: 120 }} />
        </Typography>
        <Typography fontSize="1rem" lineHeight="1.8rem" fontWeight="500">
          {from90to120}
        </Typography>
      </div>
      <div className="flex flex-row justify-between">
        <Typography fontSize="1rem" lineHeight="1.8rem" fontWeight="500">
          <FormattedMessage id="pages.finma-dashboard.moreThanDays" values={{ days: 120 }} />
        </Typography>
        <Typography fontSize="1rem" lineHeight="1.8rem" fontWeight="500">
          {moreThan120}
        </Typography>
      </div>
      <Divider className="mt-5 mb-20" />
      <div>
        <Typography fontSize="1rem" lineHeight="1,5rem" fontWeight="600">
          <FormattedMessage id="common.labels.total" />:
        </Typography>
        <div className="text-center">
          <Typography fontSize="6rem" lineHeight="9rem" color="#F44336">
            {total}
          </Typography>
        </div>
      </div>
    </div>
  )
}
