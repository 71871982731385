import { FC } from 'react'
import { useIntl } from 'react-intl'

import { TableGrid, InfinitScroller, useFormatDate } from '@web-panel/shared'
import BoxStatusChip from '@local/components/BoxStatusChip'
import { ActivityTriggerTypeChip } from '@local/components/ActivityTriggerTypeChip'
import ProductItemLink from '@local/components/ProductItemLink'
import { useBoxActivityHistory } from '@web-panel/oc-api'
import { BoxActivityErrorCode } from '../Components/BoxActivityErrorCode'
import { BoxActivityRebootExplanation } from '../Components/BoxActivityRebootExplanation'
import { BoxActivityStatusChangeExplanation } from '../Components/BoxActivityStatusChangeExplanation'

type ActivityTabProps = {
  boxId: string
}

const ActivityTab: FC<ActivityTabProps> = ({ boxId }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const {
    data: activity = [],
    loading,
    loadMore,
  } = useBoxActivityHistory(
    { boxId },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )

  return (
    <InfinitScroller onLoadMore={loadMore}>
      <TableGrid
        loading={loading}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        collection={activity}
        fields={[
          {
            title: formatMessage({ id: 'models.box-activity.trigger-by' }),
            render: ({ triggerBy, triggerType }) =>
              triggerType && <ActivityTriggerTypeChip id={triggerBy} type={triggerType} />,
          },
          {
            title: formatMessage({ id: 'models.box-activity.trigger-for' }),
            render: ({ itemId }) => itemId && <ProductItemLink id={itemId} />,
          },
          {
            title: formatMessage({ id: 'models.box-activity.type' }),
            render: ({ type }) => formatMessage({ id: `box-activity-types.${type}` }),
          },
          {
            title: '',
            render: ({ errorCode, rebootType, status, previousStatus }) => {
              if (errorCode) return <BoxActivityErrorCode code={errorCode} />
              if (rebootType) return <BoxActivityRebootExplanation rebootType={rebootType} />
              if (previousStatus)
                return (
                  <BoxActivityStatusChangeExplanation
                    currentStatus={status}
                    previousStatus={previousStatus}
                  />
                )
              return null
            },
          },
          {
            title: formatMessage({ id: 'models.box-activity.status' }),
            render: ({ status }) => <BoxStatusChip status={status} />,
          },
          {
            title: formatMessage({ id: 'models.box-activity.create-date' }),
            render: ({ createDate }) => formatDate('datetime', createDate),
          },
        ]}
      />
    </InfinitScroller>
  )
}

export default ActivityTab
