import { FC } from 'react'
import { Grow, Card, CardContent, Typography } from '@mui/material'
import { getAsset } from '../registrations'

type InfoProps = {
  title: string
  message: string
  variant?: 'default' | 'error'
}

export const DefaultInfoPage: FC<InfoProps> = ({ variant = 'default', title, message }) => {
  const logo = getAsset('logo')

  function getTitleColor() {
    if (variant === 'error') return 'error'

    return 'default'
  }

  function getMessageColor() {
    if (variant === 'error') return 'error'

    return 'textSecondary'
  }

  return (
    <Grow in>
      <Card className="w-full max-w-384">
        <CardContent className="flex flex-col items-center justify-center text-center p-16 sm:p-48">
          <img className="my-24 md:my-32 px-32 md:px-0" src={logo} alt="logo" />

          <Typography
            variant="subtitle1"
            color={getTitleColor()}
            className="mb-16 text-24 font-semibold"
          >
            {title}
          </Typography>

          <Typography color={getMessageColor()} className="mb-40 font-medium">
            {message}
          </Typography>
        </CardContent>
      </Card>
    </Grow>
  )
}
