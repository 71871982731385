import { useQuery } from '@apollo/client'
import { CollectionQueryHook } from '@web-panel/api'
import { Sequence } from '@web-panel/api/src/data-types/sequence'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import { SequencesDocument, SequencesDocumentInput, SequencesDocumentResponse } from '../docs'

export const useSequences: CollectionQueryHook<
  Omit<SequencesDocumentInput, 'limit' | 'offset'>,
  Sequence[]
> = (input, options) => {
  const variables = {
    ...defaultCollectionVariables,
    ...input,
  }
  const { data, loading, refetch, fetchMore } = useQuery<SequencesDocumentResponse>(
    SequencesDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.sequencesCollection.hasNext) return

    const offset = data.sequencesCollection.shipmentSequences.length

    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.sequencesCollection.shipmentSequences,
    loading,
    loadMore,
    hasMore: data?.sequencesCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}
