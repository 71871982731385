import { FC } from 'react'
import { DropdownButton } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { AnalyticAggregationType } from '@web-panel/api'

type AggregationTypeSelectorProps = {
  selected: AnalyticAggregationType
  onSelect: (value: AnalyticAggregationType) => void
}

export const AggregationTypeSelector: FC<AggregationTypeSelectorProps> = ({
  selected,
  onSelect,
}) => {
  const { formatMessage } = useIntl()

  const items = [
    {
      key: AnalyticAggregationType.Cumulative,
      title: formatMessage({
        id: `analytic-aggregation-type.${AnalyticAggregationType.Cumulative}`,
      }),
      onClick: () => onSelect(AnalyticAggregationType.Cumulative),
    },
    {
      key: AnalyticAggregationType.Individual,
      title: formatMessage({
        id: `analytic-aggregation-type.${AnalyticAggregationType.Individual}`,
      }),
      onClick: () => onSelect(AnalyticAggregationType.Individual),
    },
  ]

  return (
    <DropdownButton
      title={formatMessage({ id: `analytic-aggregation-type.${selected}` })}
      menu={items}
      color="secondary"
      variant="contained"
      size="small"
    />
  )
}
