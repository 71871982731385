import { VFC, useState } from 'react'
import { Button, TableGrid, Typography, useModalRef, useNotifications } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { OutletSupplement } from '@web-panel/api'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useOutletSupplements, useDeleteOutletSupplement } from '@web-panel/oc-api'
import { UpdateOutletSupplementModal } from '../Modals/UpdateOutletSupplementModal'
import { CreateOutletSupplementModal } from '../Modals/CreateOutletSupplementModal'

type OutletSupplementsTableProps = {
  outletId: string
}
export const OutletSupplementsTable: VFC<OutletSupplementsTableProps> = ({ outletId }) => {
  const { pushNotification } = useNotifications()
  const { formatMessage } = useIntl()
  const { cannot } = useRoleAuthorization()
  const { data: outletSupplements = [], refetch: refetchSupplements } = useOutletSupplements({
    outletId,
  })
  const createSupplementModalRef = useModalRef()
  const updateSupplementModalRef = useModalRef()
  const [modalSupplement, setModalSupplement] = useState<OutletSupplement>({
    id: '',
    field: '',
    value: '',
  })
  const { request: deleteOutletSupplement } = useDeleteOutletSupplement()
  const handleDeleteOutletSupplement = async (id: string) => {
    try {
      await deleteOutletSupplement({ outletId, id })
      pushNotification('success', formatMessage({ id: 'common.notifications.deleted' }))
    } catch (error) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <>
      <Typography variant="h6" fontWeight="500" className="mt-32 mx-10">
        <FormattedMessage id="models.partner-supplements" />
      </Typography>
      <TableGrid
        collection={outletSupplements}
        fields={[
          {
            title: formatMessage({ id: 'models.partner-supplement.field' }),
            render: ({ field }) => field,
          },
          {
            title: formatMessage({ id: 'models.partner-supplement.value' }),
            render: ({ value }) => value,
          },
        ]}
        rowActions={[
          {
            title: formatMessage({ id: 'common.actions.edit' }),
            disabled: cannot('outletSupplement', 'update'),
            icon: 'edit',
            onClick: (supplement) => {
              setModalSupplement(supplement)
              updateSupplementModalRef.current?.open()
            },
          },
          {
            title: formatMessage({ id: 'common.actions.delete' }),
            icon: 'delete',
            confirmation: {
              title: formatMessage({
                id: 'confirmation-dialogs.partner-supplement.delete.title',
              }),
              acceptTitle: formatMessage({ id: 'common.actions.delete' }),
              cancelTitle: formatMessage({ id: 'common.actions.cancel' }),
            },
            onClick: ({ id }) => handleDeleteOutletSupplement(id),
            disabled: cannot('outletSupplement', 'delete'),
          },
        ]}
        addItemButton={
          <Button
            disabled={cannot('outletSupplement', 'create')}
            variant="contained"
            onClick={() => createSupplementModalRef.current?.open()}
          >
            <FormattedMessage id="common.actions.add" />
          </Button>
        }
        addItemButtonAppearance="top"
      />
      <CreateOutletSupplementModal
        ref={createSupplementModalRef}
        onCreated={refetchSupplements}
        outletId={outletId}
      />
      <UpdateOutletSupplementModal
        ref={updateSupplementModalRef}
        outletId={outletId}
        supplement={modalSupplement}
      />
    </>
  )
}
