import { ReactElement } from 'react'
import MultipleSelect, { MultipleSelectProps } from './MultipleSelect'
import SingleSelect, { SingleSelectProps } from './SingleSelect'

type SingleProps<Data> = SingleSelectProps<Data> & {
  className?: string
  multiple?: false
}

type MultipleProps<Data> = MultipleSelectProps<Data> & {
  className?: string
  multiple: true
}

/* eslint-disable no-redeclare */
export function SelectableList<Data extends unknown>(props: SingleProps<Data>): ReactElement
export function SelectableList<Data extends unknown>(props: MultipleProps<Data>): ReactElement
export function SelectableList<Data extends unknown>(
  props: SingleProps<Data> | MultipleProps<Data>
): ReactElement {
  if (props.multiple) {
    return (
      <div className={props.className}>
        <MultipleSelect {...props} />
      </div>
    )
  }

  return (
    <div className={props.className}>
      <SingleSelect {...props} />
    </div>
  )
}
