import { FC } from 'react'
import { ProductHolderType } from '@web-panel/api'
import { OutletLink } from '@local/components/OutletLink'
import { UserLink } from '@local/components/UserLink'
import { BoxLink } from '@local/components/BoxLink'
import { FormattedMessage } from 'react-intl'

type ProductItemHolderReferenceProps = {
  holderType: ProductHolderType
  id: string | null
}

export const ProductItemHolderReference: FC<ProductItemHolderReferenceProps> = ({
  holderType,
  id,
}) => {
  if (!id) return <FormattedMessage id={`product-item-holder-types.${holderType}`} />

  switch (holderType) {
    case ProductHolderType.PartnerOutlet:
      return <OutletLink outletId={id} />
    case ProductHolderType.Consumer:
      return <UserLink userId={id} />
    case ProductHolderType.Box:
      return <BoxLink boxId={id} />
    default:
      return <FormattedMessage id={`product-item-holder-types.${holderType}`} />
  }
}
