import { BusinessCategoryAutocomplete } from '@local/components/Autocomplete/BusinessCategoryOutocomplete'
import { Form, useFormikContext } from 'formik'
import { FC } from 'react'
import { ContractSettingConfigFrequency, Outlet } from '@web-panel/api'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'
import { ContractSettingsSectionFields, contractSettingsValidation } from './helpers'
import {
  ContractPackageSelection,
  ContractPackageSelectionFormFields,
  contractPackageSelectionValidation,
} from './ContractPackageSelection'
import { SettingsSection } from './SettingsSection'

export type FormFields = {
  businessCategoryId: string | null
} & ContractPackageSelectionFormFields &
  ContractSettingsSectionFields

export const formInitialValues: FormFields = {
  businessCategoryId: null,
  contractPackageCode: null,
  subscriptionSetting: null,
  invoiceFrequency: ContractSettingConfigFrequency.Monthly,
  itemsSetting: null,
  consumablesSetting: null,
  deliverySetting: null,
  boxSetting: null,
  dispenserSetting: null,
  featureSetting: null,
}

export const validationSchema = Yup.object({
  businessCategoryId: Yup.string().required(),
})
  .concat(contractPackageSelectionValidation)
  .concat(contractSettingsValidation)

type CreateContractPackageFormProps = {
  outlet: Outlet
}
export const CreateContractPackageForm: FC<CreateContractPackageFormProps> = ({ outlet }) => {
  const { formatMessage } = useIntl()
  const { values, initialValues, errors, setValues, handleSubmit, dirty } =
    useFormikContext<FormFields>()

  const changedBusinessCategory = values.businessCategoryId !== initialValues.businessCategoryId
  const changedPackageCode = values.contractPackageCode !== initialValues.contractPackageCode
  const shouldOverrideSettings = (changedBusinessCategory || changedPackageCode) && dirty

  return (
    <Form className="p-24 max-w-2xl" onSubmit={handleSubmit}>
      <BusinessCategoryAutocomplete
        label={formatMessage({ id: 'models.contract-package-business.category' })}
        value={values.businessCategoryId}
        error={!!errors.businessCategoryId}
        onChange={(newCategory) =>
          setValues(
            {
              ...formInitialValues,
              businessCategoryId: newCategory?.id ?? '',
            },
            true
          )
        }
      />
      {values.businessCategoryId && (
        <div className="mt-16">
          <ContractPackageSelection
            countryCode={outlet.location.country.code}
            businessCategoryId={values.businessCategoryId}
          />
        </div>
      )}
      {values.businessCategoryId && values.contractPackageCode && (
        <SettingsSection
          overrideSettings={shouldOverrideSettings}
          countryCode={outlet.location.country.code}
          businessCategoryId={values.businessCategoryId}
          packageCode={values.contractPackageCode}
        />
      )}
    </Form>
  )
}
