import { FC } from 'react'
import { Container } from '@web-panel/api'
import { InfinitScroller, TableGrid, useFormatDate } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { ContainerStatusChip } from '@local/components/ContainerStatusChip'
import { ActivityTypeChip } from '@local/components/ActivityTypeChip'
import { UserLink } from '@local/components/UserLink'
import { ResourceLink } from '@local/components/ResourceLink'

import { useContainerActivityHistory } from '@web-panel/oc-api'

type ContainersActivityHistoryTabProps = {
  container: Container
}
export const ContainersActivityHistoryTab: FC<ContainersActivityHistoryTabProps> = ({
  container,
}) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()

  const {
    data: history = [],
    loading,
    loadMore,
  } = useContainerActivityHistory(
    { containerId: container.id },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )

  return (
    <InfinitScroller onLoadMore={loadMore}>
      <TableGrid
        collection={history}
        loading={loading}
        fields={[
          {
            title: formatMessage({ id: 'models.container-activity.type' }),
            render: ({ type }) => <ActivityTypeChip type={type} />,
          },
          {
            title: formatMessage({ id: 'models.container-activity.reference' }),
            render: ({ referenceLinkId, referenceLinkType }) => (
              <ResourceLink id={referenceLinkId} type={referenceLinkType} />
            ),
          },
          {
            title: formatMessage({ id: 'models.container-activity.container-status' }),
            render: ({ containerStatus }) => <ContainerStatusChip status={containerStatus} />,
          },
          {
            title: formatMessage({ id: 'models.container-activity.done-by' }),
            render: ({ doneBy }) => <UserLink userId={doneBy} />,
          },
          {
            title: formatMessage({ id: 'models.container-activity.create-date' }),
            render: ({ createDate }) => formatDate('human-datetime', createDate),
          },
        ]}
      />
    </InfinitScroller>
  )
}
