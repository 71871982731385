import { FC } from 'react'
import { TableGrid, InfinitScroller, useFormatDate } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import ProductItemActivityHolderLink from '@local/components/ProductItemActivityHolderLink'
import ProductItemStatusChip from '@local/components/ProductItemStatusChip'
import { ActivityTypeChip } from '@local/components/ActivityTypeChip'
import { ActivityTriggerTypeChip } from '@local/components/ActivityTriggerTypeChip'

import { useProductItemHistory } from '@web-panel/oc-api'

type ProductItemHistoryTabProps = {
  id: string
}

export const ProductItemHistoryTab: FC<ProductItemHistoryTabProps> = ({ id }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const {
    data: history = [],
    loading,
    loadMore,
  } = useProductItemHistory(
    { itemId: id },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )

  return (
    <InfinitScroller onLoadMore={loadMore}>
      <TableGrid
        loading={loading}
        collection={history}
        fields={[
          {
            title: formatMessage({ id: 'models.activity.type' }),
            render: ({ type }) => <ActivityTypeChip type={type} />,
          },
          {
            title: formatMessage({ id: 'models.product-item-activity.error' }),
            render: ({ error }) => error,
          },
          {
            title: formatMessage({ id: 'models.product-item-activity.item-status' }),
            render: ({ itemStatus }) => itemStatus && <ProductItemStatusChip status={itemStatus} />,
          },
          {
            title: formatMessage({ id: 'models.activity.triggered-by' }),
            render: ({ triggerType, triggerBy }) =>
              triggerType && <ActivityTriggerTypeChip id={triggerBy} type={triggerType} />,
          },
          {
            title: formatMessage({ id: 'models.product-item-activity.holder' }),
            render: ({ referenceHolderType, referenceHolderId }) =>
              referenceHolderType && (
                <ProductItemActivityHolderLink
                  holderType={referenceHolderType}
                  id={referenceHolderId}
                />
              ),
          },
          {
            title: formatMessage({ id: 'models.activity.create-date' }),
            render: ({ createDate }) => formatDate('datetime', createDate),
          },
        ]}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
      />
    </InfinitScroller>
  )
}

export default ProductItemHistoryTab
