import { FC } from 'react'
import {
  AppPage,
  CardedContent,
  HorizontalTable,
  HorizontalTableRow,
  Button,
  FormattedCurrency,
} from '@web-panel/shared'
import { ConsumablePartnerProductItem } from '@web-panel/api'
import { useParams, useNavigate } from 'react-router-dom'
import { Error404 } from '@local/pages/404'
import { useIntl, FormattedMessage } from 'react-intl'
import modelIcons from '@local/model-icons'

import {
  useProductItemPartnerConsumable,
  useOriginalProductItemConsumable,
} from '@web-panel/oc-api'

type PartnersConsumablesPreviewPageComponentProps = {
  partnerConsumable: ConsumablePartnerProductItem
}
export const PartnersConsumablesPreviewPageComponent: FC<PartnersConsumablesPreviewPageComponentProps> =
  ({ partnerConsumable }) => {
    const { formatMessage } = useIntl()
    const params = useParams()
    const navigate = useNavigate()
    const { data: consumable, loading: loadingConsumable } =
      useOriginalProductItemConsumable(partnerConsumable)

    const headerActionButtons = [
      <Button
        fullWidth
        key="edit"
        variant="contained"
        color="secondary"
        onClick={() => navigate(`/partners/${params.id}/consumables/${params.consumableId}/edit`)}
      >
        <FormattedMessage id="common.actions.edit" />
      </Button>,
    ]

    return (
      <AppPage
        backButton
        loading={loadingConsumable}
        title={formatMessage(
          { id: 'routes.partners.consumables.preview' },
          {
            name: formatMessage({
              id: `consumable-product-item-types.${partnerConsumable.consumableType}`,
            }),
          }
        )}
        actionButtons={headerActionButtons}
        icon={modelIcons.settings}
      >
        <CardedContent padded>
          <HorizontalTable>
            <HorizontalTableRow
              title={formatMessage({ id: 'models.consumable-product-item.type' })}
            >
              <FormattedMessage
                id={`consumable-product-item-types.${partnerConsumable.consumableType}`}
              />
            </HorizontalTableRow>
            <HorizontalTableRow
              title={formatMessage({ id: 'models.consumable-product-item.custom' })}
            >
              <FormattedMessage id={partnerConsumable.partnerId ? 'common.yes' : 'common.no'} />
            </HorizontalTableRow>
            <HorizontalTableRow
              title={formatMessage({ id: 'models.consumable-product-item.cost.partner' })}
            >
              <FormattedCurrency
                value={partnerConsumable.cost}
                currency={partnerConsumable.currency.code}
              />
            </HorizontalTableRow>
            <HorizontalTableRow
              title={formatMessage({ id: 'models.consumable-product-item.cost.original' })}
            >
              <div className="flex flex-row items-center">
                {consumable && (
                  <>
                    <FormattedCurrency
                      value={consumable.cost}
                      currency={consumable.currency.code}
                    />
                    <Button
                      className="ml-12"
                      variant="outlined"
                      onClick={() => navigate(`/settings/consumables/${consumable.id}`)}
                    >
                      <FormattedMessage id="common.actions.open" />
                    </Button>
                  </>
                )}
              </div>
            </HorizontalTableRow>
            <HorizontalTableRow
              title={formatMessage({ id: 'models.consumable-product-item.enabled' })}
            >
              <FormattedMessage
                id={partnerConsumable.enabled === false ? 'common.no' : 'common.yes'}
              />
            </HorizontalTableRow>
          </HorizontalTable>
        </CardedContent>
      </AppPage>
    )
  }

export const PartnersConsumablesPreviewPage: FC = () => {
  const params = useParams()

  const { data: partnerConsumable, loading: loadingPartnerConsumable } =
    useProductItemPartnerConsumable({
      id: params.consumableId ?? '-1',
      partnerId: params.id ?? '-1',
    })

  if (loadingPartnerConsumable) return null
  if (!partnerConsumable) return <Error404 />

  return <PartnersConsumablesPreviewPageComponent partnerConsumable={partnerConsumable} />
}
