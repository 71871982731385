import { useQuery, useMutation } from '@apollo/client'
import { CollectionQueryHook, MutationHook, QueryHook } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  UsersDocument,
  UsersDocumentInput,
  UsersDocumentResponse,
  GetUserDocument,
  GetUserDocumentInput,
  GetUserDocumentResponse,
  CreateAdminUserDocument,
  CreateAdminUserDocumentInput,
  CreateAdminUserDocumentResponse,
  DeleteUserDocument,
  DeleteUserDocumentInput,
  DeleteUserDocumentResponse,
  UpdateUserDocument,
  UpdateUserDocumentInput,
  UpdateUserDocumentResponse,
  UpdateUserRoleDocumentInput,
  UpdateUserRoleDocumentResponse,
  UpdateUserRoleDocument,
} from '../docs'

type UseUsersInput = Omit<UsersDocumentInput, 'limit' | 'offset'>
export const useUsers: CollectionQueryHook<
  UseUsersInput,
  UsersDocumentResponse['usersCollection']['users']
> = (input, options) => {
  const variables = { ...input, ...defaultCollectionVariables }

  const { data, loading, fetchMore, refetch } = useQuery<UsersDocumentResponse>(UsersDocument, {
    ...options,
    variables,
  })

  const loadMore = async () => {
    if (loading || !data || !data.usersCollection.hasNext) return

    const offset = data.usersCollection.users.length
    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.usersCollection.users,
    loading,
    loadMore,
    hasMore: data?.usersCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useCreateAdminUser: MutationHook<
  CreateAdminUserDocumentInput['input'],
  CreateAdminUserDocumentResponse['createAdminUser'] | null
> = () => {
  const [execute, { loading }] = useMutation<
    CreateAdminUserDocumentResponse,
    CreateAdminUserDocumentInput
  >(CreateAdminUserDocument)

  const request = async (input: CreateAdminUserDocumentInput['input']) => {
    const { data } = await execute({ variables: { input } })

    return data?.createAdminUser ?? null
  }

  return {
    loading,
    request,
  }
}

export const useUser: QueryHook<GetUserDocumentInput, GetUserDocumentResponse['user']> = (
  variables
) => {
  const { data, loading, refetch } = useQuery<GetUserDocumentResponse, GetUserDocumentInput>(
    GetUserDocument,
    { variables }
  )

  return {
    data: data?.user,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useDeleteUser: MutationHook<DeleteUserDocumentInput, DeleteUserDocumentResponse> = (
  options
) => {
  const [execute, { loading }] = useMutation<DeleteUserDocumentResponse>(
    DeleteUserDocument,
    options
  )

  const request = async (variables: DeleteUserDocumentInput) => {
    await execute({
      variables,
      refetchQueries: [
        {
          query: GetUserDocument,
          variables: { id: variables.id },
        },
      ],
      awaitRefetchQueries: true,
    })
  }

  return {
    request,
    loading,
  }
}

export const useUpdateUser: MutationHook<
  UpdateUserDocumentInput,
  UpdateUserDocumentResponse['updateUser']
> = (options) => {
  const [execute, { loading }] = useMutation<UpdateUserDocumentResponse>(
    UpdateUserDocument,
    options
  )

  const request = async (variables: UpdateUserDocumentInput) => {
    const { data } = await execute({ variables })

    if (!data) throw new Error('Could not update user')
    return data.updateUser
  }

  return {
    request,
    loading,
  }
}

export const useUpdateUserRole: MutationHook<
  UpdateUserRoleDocumentInput,
  UpdateUserRoleDocumentResponse['updateUserRole']
> = (options) => {
  const [execute, { loading }] = useMutation<UpdateUserRoleDocumentResponse>(
    UpdateUserRoleDocument,
    options
  )

  const request = async (variables: UpdateUserRoleDocumentInput) => {
    const { data } = await execute({ variables })

    if (!data) throw new Error('Could not update user role')
    return data.updateUserRole
  }

  return {
    request,
    loading,
  }
}
