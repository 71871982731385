import { ConsumableProductItemType } from '@web-panel/api'
import _pick from 'lodash/pick'
import { OrderItem } from './Forms/sections/ItemSection'

type OrderRefundable = {
  itemModelId: string
  amount: number
}

type OrderConsumable = {
  type: ConsumableProductItemType
  amount: number
}

export function getOrderRefundablesAndConsumables(items: OrderItem[]): {
  refundables: OrderRefundable[]
  consumables: OrderConsumable[]
} {
  const refundables = items.map((item) => _pick(item, ['itemModelId', 'amount']))
  const consumables = []

  const lidConsumables = {
    type: ConsumableProductItemType.Lid,
    amount: items.reduce((acc, item) => (item.includeLids ? acc + item.amount : acc), 0),
  }
  if (lidConsumables.amount > 0) consumables.push(lidConsumables)

  return {
    refundables,
    consumables,
  }
}
