import { FC } from 'react'
import { Link } from '@web-panel/shared'

import { useContainer } from '@web-panel/oc-api'

type PartnerLinkProps = {
  containerId: string
}

export const ContainerLink: FC<PartnerLinkProps> = ({ containerId }) => {
  const { data: container } = useContainer({ id: containerId })
  if (!container) return null

  return (
    <Link
      data-testid="PartnerLink"
      to={`/containers/${container.id}`}
      onClick={(e) => e.stopPropagation()}
    >
      {container.qrCode}
    </Link>
  )
}
