import { FC } from 'react'
import { Consumer } from '@web-panel/api'
import { Paper, TableGrid, useFormatDate } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { UserStatusChip } from '@local/components/UserStatusChip'

type IndexTableProps = {
  data: Consumer[]
  loading: boolean
}

const IndexTable: FC<IndexTableProps> = ({ data, loading }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()

  return (
    <Paper className="overflow-hidden">
      <TableGrid
        loading={loading}
        collection={data}
        fields={[
          {
            title: formatMessage({ id: 'models.user.id' }),
            render: ({ userNumber }) => userNumber,
          },
          {
            title: formatMessage({ id: 'models.user.name' }),
            render: ({ firstName, lastName }) => [firstName, lastName].join(' '),
          },
          {
            title: formatMessage({ id: 'models.user.email' }),
            render: ({ email }) => email,
          },
          {
            title: formatMessage({ id: 'models.user.phone' }),
            render: ({ phone }) => phone,
          },
          {
            title: formatMessage({ id: 'models.user.status' }),
            render: ({ status }) => <UserStatusChip status={status} />,
          },
          {
            title: formatMessage({ id: 'models.user.create-date' }),
            render: ({ createDate }) => formatDate('date', createDate),
          },
        ]}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        onClickRow={({ id }) => navigate(id)}
      />
    </Paper>
  )
}

export default IndexTable
