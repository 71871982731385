import { useMutation, useQuery } from '@apollo/client'
import { Outlet, QueryHook, CollectionQueryHook, MutationHook } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  OutletsDocument,
  OutletsDocumentResponse,
  OutletsDocumentInput,
  GetOutletDocument,
  GetOutletDocumentInput,
  GetOutletDocumentResponse,
  UpdateOutletDocumentInput,
  UpdateOutletDocumentResponse,
  UpdateOutletDocument,
  UpdateOutletLogoDocument,
  UpdateOutletLogoDocumentInput,
  UpdateOutletLogoDocumentResponse,
  DeleteOutletLogoDocument,
  DeleteOutletLogoDocumentInput,
  DeleteOutletLogoDocumentResponse,
  CreateOutletDocument,
  CreateOutletDocumentInput,
  CreateOutletDocumentResponse,
  OutletSuplementsDocumentInput,
  OutletSuplementsDocumentResponse,
  CreateOutletSuplementDocumentInput,
  CreateOutletSuplementDocumentResponse,
  UpdateOutletSupplementDocumentInput,
  UpdateOutletSupplementDocumentResponse,
  DeleteOutletSupplementDocumentInput,
  DeleteOutletSupplementDocumentResponse,
  DeleteOutletSupplementDocument,
  UpdateOutletSupplementDocument,
  CreateOutletSupplementDocument,
  OutletSupplementsDocument,
} from '../docs'

type UseOutletsInput = Omit<OutletsDocumentInput, 'limit' | 'offset'>
export const useOutlets: CollectionQueryHook<UseOutletsInput, Outlet[]> = (input, options) => {
  const variables = { ...input, ...defaultCollectionVariables }

  const { data, loading, fetchMore, refetch } = useQuery<OutletsDocumentResponse>(OutletsDocument, {
    ...options,
    variables,
  })

  const loadMore = async () => {
    if (loading || !data || !data.outletsCollection.hasNext) return
    const offset = data.outletsCollection.outlets.length

    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.outletsCollection?.outlets,
    loading,
    loadMore,
    hasMore: data?.outletsCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useOutlet: QueryHook<GetOutletDocumentInput, GetOutletDocumentResponse['outlet']> = (
  { id },
  options
) => {
  const { data, loading, refetch } = useQuery<GetOutletDocumentResponse>(GetOutletDocument, {
    variables: { id },
    ...options,
  })

  return {
    loading,
    data: data?.outlet,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useUpdateOutlet: MutationHook<
  UpdateOutletDocumentInput,
  UpdateOutletDocumentResponse['updateOutlet'] | null
> = () => {
  const [execute, { loading }] = useMutation<
    UpdateOutletDocumentResponse,
    UpdateOutletDocumentInput
  >(UpdateOutletDocument)

  const request = async (variables: UpdateOutletDocumentInput) => {
    const { data } = await execute({ variables })

    return data?.updateOutlet ?? null
  }

  return {
    request,
    loading,
  }
}

//  Update Outlet Logo
const updateOutletLogoSerializer: UpdateOutletLogoDocumentInput['bodySerializer'] = (
  { logo },
  headers
) => {
  const formData = new FormData()
  formData.append('logo', logo, logo.name)

  return {
    body: formData,
    headers,
  }
}

type UseUpdateOutletLogoInput = Omit<UpdateOutletLogoDocumentInput, 'bodySerializer'>
export const useUpdateOutletLogo: MutationHook<
  UseUpdateOutletLogoInput,
  UpdateOutletLogoDocumentResponse['updateOutletLogo'] | null
> = () => {
  const [execute, { loading }] = useMutation<
    UpdateOutletLogoDocumentResponse,
    UpdateOutletLogoDocumentInput
  >(UpdateOutletLogoDocument)

  async function request({ id, input }: UseUpdateOutletLogoInput) {
    const { data } = await execute({
      variables: { id, input, bodySerializer: updateOutletLogoSerializer },
    })

    return data?.updateOutletLogo ?? null
  }

  return { loading, request }
}

// Delete Outlet Logo

export const useDeleteOutletLogo: MutationHook<
  DeleteOutletLogoDocumentInput,
  DeleteOutletLogoDocumentResponse['deleteOutletLogo'] | null
> = () => {
  const [execute, { loading }] = useMutation<
    DeleteOutletLogoDocumentResponse,
    DeleteOutletLogoDocumentInput
  >(DeleteOutletLogoDocument)

  async function request(variables: DeleteOutletLogoDocumentInput) {
    const { data } = await execute({ variables })

    return data?.deleteOutletLogo ?? null
  }

  return { request, loading }
}

export const useCreateOutlet: MutationHook<
  CreateOutletDocumentInput,
  CreateOutletDocumentResponse['createOutlet'] | null
> = (options) => {
  const [execute, { loading }] = useMutation<CreateOutletDocumentResponse>(
    CreateOutletDocument,
    options
  )

  async function request(variables: CreateOutletDocumentInput) {
    const { data } = await execute({ variables })

    return data?.createOutlet ?? null
  }

  return {
    request,
    loading,
  }
}

export const useOutletSupplements: QueryHook<
  OutletSuplementsDocumentInput,
  OutletSuplementsDocumentResponse['outletSupplements']['supplements']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<OutletSuplementsDocumentResponse>(
    OutletSupplementsDocument,
    {
      ...options,
      variables,
    }
  )

  return {
    data: data?.outletSupplements?.supplements,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useCreateOutletSupplement: MutationHook<
  CreateOutletSuplementDocumentInput,
  CreateOutletSuplementDocumentResponse['createOutletSupplement']['supplements']
> = (options) => {
  const [execute, { loading }] = useMutation<
    CreateOutletSuplementDocumentResponse,
    CreateOutletSuplementDocumentInput
  >(CreateOutletSupplementDocument, options)

  async function request(variables: CreateOutletSuplementDocumentInput) {
    const { data } = await execute({ variables })

    if (!data) throw new Error('Could not create outlet supplement')

    return data.createOutletSupplement.supplements
  }

  return { request, loading }
}

export const useUpdateOutletSupplement: MutationHook<
  UpdateOutletSupplementDocumentInput,
  UpdateOutletSupplementDocumentResponse['updateOutletSupplement']['supplements']
> = (options) => {
  const [execute, { loading }] = useMutation<
    UpdateOutletSupplementDocumentResponse,
    UpdateOutletSupplementDocumentInput
  >(UpdateOutletSupplementDocument, options)

  async function request(variables: UpdateOutletSupplementDocumentInput) {
    const { data } = await execute({ variables })

    if (!data) throw new Error('Could not update outlet supplement')

    return data.updateOutletSupplement.supplements
  }

  return { request, loading }
}

export const useDeleteOutletSupplement: MutationHook<
  DeleteOutletSupplementDocumentInput,
  DeleteOutletSupplementDocumentResponse
> = (options) => {
  const [execute, { loading }] = useMutation<
    DeleteOutletSupplementDocumentResponse,
    DeleteOutletSupplementDocumentInput
  >(DeleteOutletSupplementDocument, options)

  async function request(variables: DeleteOutletSupplementDocumentInput) {
    await execute({
      variables,
      update: (cache) => {
        cache.evict({
          id: cache.identify({
            __typename: 'OutletSupplement',
            id: variables.id,
          }),
        })
      },
    })
  }

  return { request, loading }
}
