import { FC } from 'react'
import Typography from '@mui/material/Typography'
import { motion } from 'framer-motion'

export type ErrorProps = {
  title: string
  message: string
}

export const DefaultErrorPage: FC<ErrorProps> = ({ title, message }) => {
  return (
    <div className="flex flex-col flex-auto justify-center">
      <div className="text-center">
        <motion.div
          initial={{ opacity: 0, scale: 0.6 }}
          animate={{ opacity: 1, scale: 1, transition: { delay: 0.1 } }}
        >
          <Typography variant="h1" color="inherit" className="font-medium mb-16">
            {title}
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
        >
          <Typography variant="h5" color="textSecondary" className="mb-16 font-normal">
            {message}
          </Typography>
        </motion.div>
      </div>
    </div>
  )
}
