import { gql } from '@apollo/client'
import { User, userFields, UserRole, UserStatus } from '@web-panel/api'

export type UsersDocumentInput = {
  limit: number
  offset?: number
  role?: UserRole
  search?: string
  status?: UserStatus
}
export type UsersDocumentResponse = {
  usersCollection: {
    users: User[]
    hasNext: boolean
  }
}
export const UsersDocument = gql`
  query Users($limit: Int!, $offset: Int, $role: String, $search: String, $status: String) {
    usersCollection(limit: $limit, offset: $offset, role: $role, search: $search, status: $status) @rest(type: "UsersCollection", path: "/users-service/users?{args}") {
      users @type(name: "User") {
        ${userFields}
      }
      hasNext
    }
  }
`

export type CreateAdminUserDocumentInput = {
  input: {
    role: UserRole
    email: string
    firstName: string
    lastName: string
  }
}
export type CreateAdminUserDocumentResponse = {
  createAdminUser: User
}
export const CreateAdminUserDocument = gql`
  mutation CreateAdminUser($input: any) {
    createAdminUser: publish(input: $input) @rest(type: "User", path: "/users-service/users/register", method: "POST") {
      ${userFields}
    }
  }
`

export type GetUserDocumentInput = {
  id: string
}
export type GetUserDocumentResponse = {
  user: User
}

export const GetUserDocument = gql`
  query GetUser($id: String!) {
    user(id: $id) @rest(type: "User", path: "/users-service/users/{args.id}") {
      ${userFields}
    }
  }
`

export type DeleteUserDocumentInput = {
  id: string
}
export type DeleteUserDocumentResponse = void
export const DeleteUserDocument = gql`
  mutation DeleteUSer($id: String!) {
    deleteUser(id: $id)
      @rest(type: "NoResponse", path: "/users-service/users/{args.id}", method: "DELETE") {
      NoResponse
    }
  }
`

export type UpdateUserDocumentInput = {
  id: string
  input: {
    firstName?: string
    lastName?: string
    email?: string
  }
}
export type UpdateUserDocumentResponse = {
  updateUser: User
}
export const UpdateUserDocument = gql`
  mutation UpdateUser($id: String!, $input: any) {
    updateUser(id: $id, input: $input) @rest(type: "User", path: "/users-service/users/{args.id}", method: "PUT") {
      ${userFields}
    }
  }
`

export type UpdateUserRoleDocumentInput = {
  id: string
  input: {
    role: UserRole
  }
}
export type UpdateUserRoleDocumentResponse = {
  updateUserRole: User
}
export const UpdateUserRoleDocument = gql`
  mutation UpdateUserRole($id: String!, $input: any) {
    updateUserRole(id: $id, input: $input) @rest(type: "User", path: "/users-service/users/{args.id}/role", method: "PUT") {
      ${userFields}
    }
  }
`
