import { FC, ChangeEventHandler } from 'react'
import { TextField, InputAdornment, ButtonGroup, Button } from '@mui/material'

type AmountInputProps = {
  value: number
  label: string
  onChange: (newAmount: number) => void
  error?: boolean
  disabled?: boolean
  disabledAdd?: boolean
  disabledSub?: boolean
  inputStep?: number
  enableManualInput?: boolean
}

export const AmountInput: FC<AmountInputProps> = ({
  value,
  label,
  error = false,
  disabled = false,
  disabledAdd = false,
  disabledSub = false,
  inputStep = 1,
  enableManualInput = false,
  onChange,
}) => {
  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!enableManualInput) return

    onChange(Number(e.target.value))
  }

  function increment() {
    const nextValue = value - (value % inputStep) + inputStep
    onChange(nextValue)
  }

  function decrement() {
    const multFactor = value % inputStep === 0 ? 1 : 0
    const prevValue = value - (value % inputStep) - inputStep * multFactor
    onChange(prevValue)
  }

  return (
    <TextField
      label={label}
      error={error}
      disabled={disabled}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <ButtonGroup variant="contained">
              <Button disabled={disabled || disabledSub} onClick={decrement}>
                -
              </Button>
              <Button disabled={disabled || disabledAdd} onClick={increment}>
                +
              </Button>
            </ButtonGroup>
          </InputAdornment>
        ),
      }}
      value={value}
      onChange={handleOnChange}
    />
  )
}
