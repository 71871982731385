import { FC } from 'react'
import { DefaultErrorPage } from '@web-panel/shared'
import { useIntl } from 'react-intl'

export const Error404: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <DefaultErrorPage
      title={formatMessage({ id: 'errors.404.title' })}
      message={formatMessage({ id: 'errors.404.message' })}
    />
  )
}
