import { ContainerStatus } from '@web-panel/api'
import { SelectableList, buildFilterContext } from '@web-panel/shared'
import { FC } from 'react'
import { useIntl } from 'react-intl'

type FilterContext = {
  status: ContainerStatus | undefined
}

export const { FiltersContext, useFilters, withFilters } = buildFilterContext<FilterContext>({
  status: undefined,
})

export const Filters: FC = () => {
  const { filters, setFilter } = useFilters()
  const { formatMessage } = useIntl()

  return (
    <div className="flex flex-col gap-16">
      <SelectableList
        value={filters.status}
        options={Object.values(ContainerStatus)}
        optionTitle={(option) => formatMessage({ id: `container-statuses.${option}` })}
        label={formatMessage({ id: 'models.container.status' })}
        onSelect={(status) => setFilter('status', status)}
      />
    </div>
  )
}
