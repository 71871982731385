import { Outlet, AnalyticAggregationType } from '@web-panel/api'
import { Props } from 'react-apexcharts'
import { compareAsc, subYears } from 'date-fns'

import { useNormalizedCO2SavedSeries, useTotalCO2Saved } from '@web-panel/oc-api'

const requestStartDate = subYears(new Date(), 3).toISOString()

type SeriaData = {
  x: Date
  y: number
}[]

export const useSeries = (
  partnerId: string,
  outlets: Outlet[],
  startDate: Date | null,
  aggregationType: AnalyticAggregationType
): Props['series'] => {
  const outletIds = outlets.map(({ id }) => id)
  const { data = [] } = useNormalizedCO2SavedSeries({
    partnerId,
    outletIds,
    aggregationType,
    startDate: requestStartDate,
  })

  const series = data.map(({ holderId, metrics }) => {
    const outlet = outlets.find(({ id }) => id === holderId)
    const name = outlet?.location.name ?? holderId

    const seriaData: SeriaData = metrics.reduce<SeriaData>((acc, metric) => {
      if (startDate && compareAsc(metric.date, startDate) < 0) return acc

      return [...acc, { x: metric.date, y: metric.value }]
    }, [])

    return {
      name,
      data: seriaData,
    }
  })

  return series
}

export const useTotalCO2SavedSum = (partnerId: string, outlets: Outlet[]) => {
  const outletIds = outlets.map(({ id }) => id)
  const { data = [] } = useTotalCO2Saved({ partnerId, outletIds })

  const sum = data.reduce((res, metric) => {
    return res + metric.value
  }, 0)

  return sum.toFixed(2)
}
