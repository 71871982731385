import { VFC } from 'react'
import { AppPage, CardedContent, Button, DefaultErrorPage, TabSections } from '@web-panel/shared'
import { Dispenser } from '@web-panel/api'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import modelIcons from '@local/model-icons'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispenser } from '@web-panel/oc-api'
import { PrintPhysicalLabelButton } from './Components/PrintPhysicalLabelButton'
import { DispenserGeneralTab } from './Tabs/General'
import { DispenserTasksTab } from './Tabs/Tasks'

type DispenserPreviewPageProps = {
  dispenser: Dispenser
  refetchDispenser: () => Promise<void>
}
const DispenserPreviewPage: VFC<DispenserPreviewPageProps> = ({ dispenser, refetchDispenser }) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { can } = useRoleAuthorization()

  const headerActionButtons = [
    <PrintPhysicalLabelButton dispenser={dispenser} />,
    can(
      'dispenser',
      'update',
      <Button
        fullWidth
        key="create"
        variant="contained"
        color="secondary"
        onClick={() => navigate('edit')}
      >
        <FormattedMessage
          id="common.actions.edit-with-name"
          values={{ name: formatMessage({ id: 'models.dispenser' }) }}
        />
      </Button>
    ),
  ]

  return (
    <AppPage
      backButton
      icon={modelIcons.dispenser}
      title={formatMessage({ id: 'routes.dispenser-preview' }, { name: dispenser.coreId })}
      actionButtons={headerActionButtons}
    >
      <CardedContent>
        <TabSections
          sections={[
            {
              key: 'general',
              title: formatMessage({ id: 'pages.dispenser-preview.tabs.general' }),
              Content: () => (
                <DispenserGeneralTab dispenser={dispenser} refetchDispenser={refetchDispenser} />
              ),
            },
            {
              key: 'tasks',
              title: formatMessage({ id: 'pages.dispenser-preview.tabs.tasks' }),
              Content: () => <DispenserTasksTab dispenserId={dispenser.id} />,
            },
          ]}
        />
      </CardedContent>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(
  () => {
    const params = useParams()
    const { formatMessage } = useIntl()

    const {
      data: dispenser,
      loading,
      refetch: refetchDispenser,
    } = useDispenser({ id: params.id ?? '' })
    if (loading) return null
    if (!dispenser)
      return (
        <DefaultErrorPage
          title={formatMessage({ id: 'errors.404.title' })}
          message={formatMessage({ id: 'errors.404.message' })}
        />
      )

    return <DispenserPreviewPage dispenser={dispenser} refetchDispenser={refetchDispenser} />
  },
  ({ can }) => can('dispenser', 'preview')
)
