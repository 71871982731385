import { FC } from 'react'
import { FormSection, TextField } from '@web-panel/shared'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'

export type OrderSectionFormFields = {
  batchDefaultSize: number
}

export const orderSectionValidationSchema = Yup.object().shape({
  batchDefaultSize: Yup.number().min(1).required(),
})

export const orderSectionDefaultValues: OrderSectionFormFields = {
  batchDefaultSize: 50,
}

export const OrderSection: FC = () => {
  const { values, errors, handleChange } = useFormikContext<OrderSectionFormFields>()
  const { formatMessage } = useIntl()

  return (
    <FormSection title={formatMessage({ id: 'models.order' })}>
      <TextField
        fullWidth
        className="mb-16 md:mb-0 md:mr-16"
        label={formatMessage({ id: 'models.outlet.batch-default-size' })}
        value={values.batchDefaultSize}
        onChange={handleChange('batchDefaultSize')}
        error={!!errors.batchDefaultSize}
      />
    </FormSection>
  )
}
