import { FC } from 'react'
import { Partner } from '@web-panel/api'
import { OriginalDashboard } from './Original'
import { FinmaDashboard } from './Finma'

const finmaDashboardPartners: string[] = [
  '6',
  '37',
  '38',
  '28766',
  '28749',
  '28808',
  '28805',
  '28824',
]

type DashboardTabProps = {
  partner: Partner
}
export const DashboardTab: FC<DashboardTabProps> = ({ partner }) => {
  const isDisplayingFinmaDashboard = finmaDashboardPartners.includes(partner.partnerNumber)

  if (isDisplayingFinmaDashboard) return <FinmaDashboard partner={partner} />

  return <OriginalDashboard partnerId={partner.id} />
}
