import { FC, useState } from 'react'
import {
  buildModal,
  ModalBodyProps,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
  useNotifications,
} from '@web-panel/shared'
import { PartnerEmployee } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'
import _includes from 'lodash/includes'

import { useAssignPartnerEmployee, usePartnerEmployees } from '@web-panel/oc-api'

type AssignPartnerEmployeeModalProps = ModalBodyProps & {
  partnerId: string
  outletId: string
  onSubmit?: () => Promise<void>
}

const AssignPartnerEmployeeModal: FC<AssignPartnerEmployeeModalProps> = ({
  partnerId,
  outletId,
  onClose,
  onSubmit,
}) => {
  const { pushNotification } = useNotifications()
  const { formatMessage } = useIntl()
  const [selectedEmployee, setSelectedEmployee] = useState<PartnerEmployee | null>(null)
  const { data: employees = [] } = usePartnerEmployees({ partnerId })
  const { request: assignEmployee, loading } = useAssignPartnerEmployee()

  const handleSubmit = async () => {
    if (!selectedEmployee) return

    try {
      await assignEmployee({
        partnerId,
        employeeId: selectedEmployee.id,
        outletId,
      })
      if (onSubmit) await onSubmit()
      onClose()
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <>
      <DialogTitle>
        <FormattedMessage id="modals.assign-employee-to-outlet.title" />
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          fullWidth
          options={employees}
          renderInput={(params) => <TextField {...params} />}
          getOptionDisabled={({ outletIds }) => _includes(outletIds, outletId)}
          getOptionLabel={(employee) => [employee.firstName, employee.lastName].join(' ')}
          isOptionEqualToValue={(option, val) => option.id === val.id}
          renderOption={(props, employee) => {
            return (
              <li {...props} key={employee.id}>
                {[employee.firstName, employee.lastName].join(' ')}
              </li>
            )
          }}
          onChange={(e, employee) => setSelectedEmployee(employee)}
          value={selectedEmployee}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="common.actions.cancel" />
        </Button>
        <Button
          loading={loading}
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          disabled={!selectedEmployee}
        >
          <FormattedMessage id="common.actions.assign" />
        </Button>
      </DialogActions>
    </>
  )
}

export default buildModal(AssignPartnerEmployeeModal, { fullWidth: true })
