import { FC } from 'react'
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { InputProps } from './types'
import { Button } from '../../Button'
import TimeInput from './TimeInput'
import { daysOfWeek } from './consts'

const VerticalInput: FC<InputProps> = ({
  value,
  dayLabels,
  timeFrameLabels,
  actionLabels,
  onAdd,
  onDelete,
  onChange,
}) => {
  const dayLabelArray = [
    dayLabels.monday,
    dayLabels.tuesday,
    dayLabels.wednesday,
    dayLabels.thursday,
    dayLabels.friday,
    dayLabels.saturday,
    dayLabels.sunday,
  ]

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{timeFrameLabels.opening}</TableCell>
            <TableCell>{timeFrameLabels.closing}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {daysOfWeek.map((id) => {
            const hours = value.find(({ dayOfWeek }) => dayOfWeek === id)

            return (
              <TableRow key={id}>
                <TableCell>{dayLabelArray[id]}</TableCell>
                {hours ? (
                  <>
                    <TableCell>
                      <TimeInput
                        type="openTime"
                        value={hours.openTime}
                        onChange={(val) => onChange('openTime', id, val)}
                      />
                    </TableCell>
                    <TableCell>
                      <TimeInput
                        type="closeTime"
                        value={hours.closeTime}
                        onChange={(val) => onChange('closeTime', id, val)}
                      />
                    </TableCell>
                    <TableCell>
                      <Button variant="text" color="error" onClick={() => onDelete(id)}>
                        {actionLabels.delete}
                      </Button>
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell align="center" colSpan={2}>
                      <Button variant="outlined" color="primary" onClick={() => onAdd(id)}>
                        {actionLabels.add}
                      </Button>
                    </TableCell>
                    <TableCell />
                  </>
                )}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default VerticalInput
