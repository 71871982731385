import { useMutation, useQuery } from '@apollo/client'
import { CollectionQueryHook, MutationHook, QueryHook } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  ContainerActivityHistoryDocument,
  ContainerActivityHistoryDocumentInput,
  ContainerActivityHistoryDocumentResponse,
  ContainerBatchesDocument,
  ContainerBatchesDocumentInput,
  ContainerBatchesDocumentResponse,
  ContainerByQrCodeDocument,
  ContainerByQrCodeDocumentInput,
  ContainerByQrCodeDocumentResponse,
  ContainerDocument,
  ContainerDocumentInput,
  ContainerDocumentResponse,
  ContainersDocument,
  ContainersDocumentInput,
  ContainersDocumentResponse,
  CreateContainerDocument,
  CreateContainerDocumentInput,
  CreateContainerDocumentResponse,
} from '../docs'

export const useContainers: CollectionQueryHook<
  Omit<ContainersDocumentInput, 'pagination'>,
  ContainersDocumentResponse['containersCollection']['containers']
> = (input, options) => {
  const variables: ContainersDocumentInput = { ...input, pagination: defaultCollectionVariables }

  const { data, loading, fetchMore, refetch } = useQuery<ContainersDocumentResponse>(
    ContainersDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.containersCollection.hasNext) return

    const offset = data.containersCollection.containers.length
    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.containersCollection.containers,
    loading,
    loadMore,
    hasMore: data?.containersCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useContainer: QueryHook<
  ContainerDocumentInput,
  ContainerDocumentResponse['container']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<ContainerDocumentResponse>(ContainerDocument, {
    variables,
    ...options,
  })

  return {
    data: data?.container,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useContainerByQrCode: QueryHook<
  ContainerByQrCodeDocumentInput,
  ContainerByQrCodeDocumentResponse['containerByQrCode']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<ContainerByQrCodeDocumentResponse>(
    ContainerByQrCodeDocument,
    { variables, ...options }
  )

  return {
    data: data?.containerByQrCode,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useCreateContainers: MutationHook<
  CreateContainerDocumentInput['input'],
  CreateContainerDocumentResponse
> = () => {
  const [execute, { loading }] =
    useMutation<CreateContainerDocumentResponse>(CreateContainerDocument)

  async function request(input: CreateContainerDocumentInput['input']) {
    await execute({ variables: { input } })
  }

  return {
    request,
    loading,
  }
}

export const useContainerBatches: CollectionQueryHook<
  Omit<ContainerBatchesDocumentInput, 'pagination'>,
  ContainerBatchesDocumentResponse['containerBatches']['batches']
> = (input, options) => {
  const variables: ContainerBatchesDocumentInput = {
    ...input,
    pagination: defaultCollectionVariables,
  }

  const { data, loading, fetchMore, refetch } = useQuery<ContainerBatchesDocumentResponse>(
    ContainerBatchesDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.containerBatches.hasNext) return

    const offset = data.containerBatches.batches.length
    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.containerBatches.batches,
    loading,
    loadMore,
    hasMore: data?.containerBatches.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useContainerActivityHistory: CollectionQueryHook<
  Omit<ContainerActivityHistoryDocumentInput, 'pagination'>,
  ContainerActivityHistoryDocumentResponse['containerActivityHistory']['containerActivities']
> = (input, options) => {
  const variables: ContainerActivityHistoryDocumentInput = {
    ...input,
    pagination: defaultCollectionVariables,
  }

  const { data, loading, fetchMore, refetch } = useQuery<ContainerActivityHistoryDocumentResponse>(
    ContainerActivityHistoryDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.containerActivityHistory.hasNext) return

    const offset = data.containerActivityHistory.containerActivities.length
    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.containerActivityHistory.containerActivities,
    loading,
    loadMore,
    hasMore: data?.containerActivityHistory.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}
