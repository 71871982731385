import { FC } from 'react'
import { Outlet } from '@web-panel/api'
import {
  InfinitScroller,
  Button,
  TableGrid,
  useNotifications,
  useModalRef,
} from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { usePartnerEmployees, useUnassignPartnerEmployee } from '@web-panel/oc-api'
import AssignEmployeeModal from '../Modals/AssignPartnerEmployee'

type EmployeesTabProps = {
  outlet: Outlet
}

const EmployeesTab: FC<EmployeesTabProps> = ({ outlet }) => {
  const navigate = useNavigate()
  const modalRef = useModalRef()
  const { formatMessage } = useIntl()
  const {
    data: employees = [],
    loading,
    loadMore,
    refetch,
  } = usePartnerEmployees(
    {
      partnerId: outlet.partnerId,
      outletId: outlet.id,
    },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )
  const { request: unassignEmployee } = useUnassignPartnerEmployee()
  const { pushNotification } = useNotifications()

  const detachEmployee = async (employeeId: string) => {
    try {
      await unassignEmployee({ outletId: outlet.id, partnerId: outlet.partnerId, employeeId })
      await refetch()
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (e) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <>
      <InfinitScroller onLoadMore={loadMore}>
        <TableGrid
          loading={loading}
          collection={employees}
          fields={[
            {
              title: formatMessage({ id: 'models.partner-employee.name' }),
              render: ({ firstName, lastName }) => [firstName, lastName],
            },
            {
              title: formatMessage({ id: 'models.partner-employee.email' }),
              render: ({ email }) => email,
            },
            {
              title: '',
              render: (employee) => (
                <Button
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation()
                    detachEmployee(employee.id)
                  }}
                >
                  <FormattedMessage id="common.actions.detach" />
                </Button>
              ),
            },
          ]}
          onClickRow={(employee) =>
            navigate(`/partners/${outlet.partnerId}/employee/${employee.id}`)
          }
          addItemButton={
            <Button variant="contained" color="primary" onClick={() => modalRef.current?.open()}>
              <FormattedMessage id="common.actions.assign" />
            </Button>
          }
        />
      </InfinitScroller>
      <AssignEmployeeModal
        ref={modalRef}
        partnerId={outlet.partnerId}
        outletId={outlet.id}
        onSubmit={refetch}
      />
    </>
  )
}

export default EmployeesTab
