import { gql } from '@apollo/client'
import { Location, WorkingHours } from '@web-panel/api'

export const locationFields = `
  id
  country {
    code
    name
  },
  city {
    code
    name
  },
  timeZoneOffset
  cityCode
  name
  street
  houseNumber
  postalCode
  latitude
  longitude
  googlePlaceId
  photoLink
  workingHours {
    dayOfWeek
    openTime
    closeTime
  }
`

export type CreateLocationDocumentInput = {
  input: {
    cityCode: string
    name: string
    street: string
    houseNumber: string
    postalCode: string
    latitude: number
    longitude: number
    googlePlaceId: string | null
    workingHours: WorkingHours[]
  }
}

export type CreateLocationDocumentResponse = {
  createLocation: Location
}

export const CreateLocationDocument = gql`
  mutation CreateLocation($input: any) {
    createLocation: publish(input: $input)
      @rest(type: "Location", path: "/partners-service/locations", method: "POST") {
      ${locationFields}
    }
  }
`

export type UpdateLocationDocumentInput = {
  id: string
  input: {
    cityCode: string
    name: string
    street: string
    houseNumber: string
    postalCode: string
    latitude: number
    longitude: number
    googlePlaceId: string | null
    workingHours: WorkingHours[]
  }
}

export type UpdateLocationDocumentResponse = {
  updateLocation: Location
}

export const UpdateLocationDocument = gql`
  mutation UpdateLocation($id: String!, $input: any) {
    updateLocation: publish(id: $id, input: $input)
      @rest(type: "Location", path: "/partners-service/locations/{args.id}", method: "PUT") {
      ${locationFields}
    }
  }
`

export type DeleteLocationDocumentInput = {
  id: string
}
export type DeleteLocationDocumentResponse = void
export const DeleteLocationDocument = gql`
  mutation UpdateLocation($id: String!) {
    updateLocation: publish(id: $id)
      @rest(type: "CreateBoxesResponse", path: "/partners-service/locations/{args.id}", method: "DELETE") {
      ${locationFields}
    }
  }
`
