import { gql } from '@apollo/client'
import {
  Partner,
  InputPartnerInvoiceAddress,
  Outlet,
  outletFields,
  PaymentType,
  PartnerStatus,
  partnerFields,
  OrderInvoiceRecipient,
  PartnerSupplement,
  partnerSupplementFields,
} from '@web-panel/api'

export type PartnersDocumentInput = {
  search?: string
}

export type PartnersDocumentResponse = {
  partnersCollection: {
    partners: Partner[]
    hasNext: boolean
  }
}

export const PartnersDocument = gql`
  query Partners($limit: Int, $offset: Int, $search: String) {
    partnersCollection(limit: $limit, offset: $offset, search: $search) @rest(type: "PartnersCollection", path: "/partners-service/partners?{args}") {
      partners @type(name: "Partner") {
        ${partnerFields}
      }
      hasNext
    }
  }
`

export type GetPartnerDocumentInput = {
  id: string
}
export type GetPartnerDocumentResponse = {
  partner: Partner
}

export const GetPartnerDocument = gql`
  query Partner($id: String!) {
    partner(id: $id) @rest(type: "Partner", path: "/partners-service/partners/{args.id}") {
      ${partnerFields}
    }
  }
`

export type DeletePartnerDocumentInput = {
  id: string
}
export type DeletePartnerDocumentResponse = void

export const DeletePartnerDocument = gql`
  mutation DeletePartner($id: String!) {
    deletePartner(id: $id)
      @rest(
        type: "PartnerDeleteResponse"
        path: "/partners-service/partners/{args.id}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`

export type PartnerOutletsDocumentInput = {
  id: string
  limit: number
  offset?: number
}
export type PartnerOutletsDocumentResponse = {
  partnerOutlets: {
    outlets: Outlet[]
    hasNext: boolean
  }
}

export const PartnerOutletsDocument = gql`
  query PartnerOutlets($id: String!, $limit: Int!, $offset: Int) {
    partnerOutlets(id: $id, limit: $limit, offset: $offset)
      @rest(
        type: "PartnerOutletsCollection"
        path: "/partners-service/partners/{args.id}/outlets?limit={args.limit}&offset={args.offset}"
      ) {
      outlets @type(name: "Outlet"){
        ${outletFields}
      }
      hasNext
    }
  }
`

export type UpdatePartnerDocumentInput = {
  id: string
  input: Partial<{
    name: string
    firstName: string
    lastName: string
    phone: string
    email: string
    operationsPhone: string | null
    operationsEmail: string | null
    invoicesEmail: string
    additionalInfo: string
    registrationId: string | null
    comment: string
    invoiceAddress: InputPartnerInvoiceAddress
    finance: Partial<{
      paymentType: PaymentType
      currency: {
        code: string
      }
      cupCost: number
      cupServiceFee: number
      cupRefundPercent: number
      payoutFeePercent: number
    }>
    invoiceRecipient: OrderInvoiceRecipient
    partnerStatus: PartnerStatus
    authorized: boolean
    test: boolean
    kookyOperated: boolean
    loyaltyEnabled: boolean
  }>
}
export type UpdatePartnerDocumentResponse = {
  updatePartner: Partner
}

export const UpdatePartnerDocument = gql`
  mutation UpdatePartner($id: String!, $input: any) {
    updatePartner: publish(id: $id, input: $input) @rest(type: "Partner", path: "/partners-service/partners/{args.id}", method: "PUT") {
      ${partnerFields}
    }
  }
`

export type UpdatePartnerLogoDocumentInput = {
  id: string
  input: {
    logo: File
  }
  bodySerializer?: (data: { logo: File }, headers: Headers) => { body: FormData; headers: Headers }
}
export type UpdatePartnerLogoDocumentResponse = {
  updatePartnerLogo: Partner
}
export const UpdatePartnerLogoDocument = gql`
  mutation UpdatePartnerLogo($id: String!, $input: any, $bodySerializer: any) {
    updatePartnerLogo: publish(id: $id, input: $input)
      @rest(
        type: "Partner"
        path: "/partners-service/partners/{args.id}/logo"
        method: "POST"
        bodySerializer: $bodySerializer
      ) {
      ${partnerFields}
    }
  }
`

export type DeletePartnerLogoDocumentInput = {
  id: string
}
export type DeletePartnerLogoDocumentResponse = {
  deletePartnerLogo: Partner
}

export const DeletePartnerLogoDocument = gql`
  mutation DeletePartnerLogo($id: String!) {
    deletePartnerLogo(id: $id) @rest(type: "Partner", path: "/partners-service/partners/{args.id}/logo", method: "DELETE") {
      ${partnerFields}
    }
  }
`

export type PartnerSuplementsDocumentInput = {
  partnerId: string
}
export type PartnerSuplementsDocumentResponse = {
  partnerSupplements: {
    supplements: PartnerSupplement[]
  }
}
export const PartnerSupplementsDocument = gql`
  query PartnerSupplements($partnerId: String!) {
    partnerSupplements(partnerId: $partnerId)
      @rest(type: "PartnerSupplementsCollection", path: "/partners-service/partners/{args.partnerId}/supplements") {
      supplements @type(name: "PartnerSupplement") {
        ${partnerSupplementFields}
      }
    }
  }
`

export type CreatePartnerSuplementDocumentInput = {
  partnerId: string
  input: {
    field: string
    value: string
  }
}
export type CreatePartnerSuplementDocumentResponse = {
  createPartnerSupplement: {
    supplements: PartnerSupplement[]
  }
}

export const CreatePartnerSupplementDocument = gql`
  mutation CreatePartnerSupplement($partnerId: String!, $input: any) {
    createPartnerSupplement(partnerId: $partnerId, input: $input)
      @rest(
        type: "PartnerSupplement"
        path: "/partners-service/partners/{args.partnerId}/supplements"
        method: "POST"
      ) {
        supplements @type(name: "PartnerSupplement") {
          ${partnerSupplementFields}
        }
    }
  }
`
export type UpdatePartnerSupplementDocumentInput = {
  id: string
  partnerId: string
  input: {
    field: string
    value: string
  }
}
export type UpdatePartnerSupplementDocumentResponse = {
  updatePartnerSupplement: {
    supplements: PartnerSupplement[]
  }
}

export const UpdatePartnerSupplementDocument = gql`
  mutation UpdatePartnerSuplement($partnerId: String!, $id: String!, $input: any) {
    updatePartnerSupplement(partnerId: $partnerId, id: $id, input: $input)
      @rest(
        type: "PartnerSupplement"
        path: "/partners-service/partners/{args.partnerId}/supplements/{args.id}"
        method: "PUT"
      ) {
        supplements @type(name: "PartnerSupplement") {
          ${partnerSupplementFields}
        }
    }
  }
`

export type DeletePartnerSupplementDocumentInput = {
  id: string
  partnerId: string
}
export type DeletePartnerSupplementDocumentResponse = void
export const DeletePartnerSupplementDocument = gql`
  mutation DeletePartnerSuplement($partnerId: String!, $id: String!) {
    deletePartnerSuplement(partnerId: $partnerId, id: $id)
      @rest(
        type: "PartnerSupplement"
        path: "/partners-service/partners/{args.partnerId}/supplements/{args.id}"
        method: "DELETE"
      ) {
      NoBody
    }
  }
`
