import { useQuery, useMutation } from '@apollo/client'
import { CollectionQueryHook, MutationHook, QueryHook } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  DispensersDocument,
  DispensersDocumentInput,
  DispensersDocumentResponse,
  CreateDispensersDocument,
  CreateDispensersDocumentInput,
  CreateDispensersDocumentResponse,
  CreateDispensersBody,
  DispenserDocument,
  DispenserDocumentInput,
  DispenserDocumentResponse,
  UpdateDispenserDocument,
  UpdateDispenserDocumentInput,
  UpdateDispenserDocumentResponse,
  AssignDispenserToOutletDocument,
  AssignDispenserToOutletDocumentInput,
  AssignDispenserToOutletDocumentResponse,
  AssignDispenserToLocationDocument,
  AssignDispenserToLocationDocumentInput,
  AssignDispenserToLocationDocumentResponse,
  DispenserLastOrderDocument,
  DispenserLastOrderDocumentInput,
  DispenserLastOrderDocumentResponse,
  DispenserTasksDocumentInput,
  DispenserTasksDocumentResponse,
  DispenserTasksDocument,
} from '../docs'

// Request boxes collection
type UseDispensersInput = Omit<DispensersDocumentInput, 'limit' | 'offset'>

export const useDispensers: CollectionQueryHook<
  UseDispensersInput,
  DispensersDocumentResponse['dispensersCollection']['dispensers']
> = (input, options) => {
  const variables = { ...input, ...defaultCollectionVariables }

  const { data, loading, fetchMore, refetch } = useQuery<DispensersDocumentResponse>(
    DispensersDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.dispensersCollection.hasNext) return

    const offset = data.dispensersCollection.dispensers.length
    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.dispensersCollection.dispensers,
    loading,
    loadMore,
    hasMore: data?.dispensersCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

const bodySerializer: CreateDispensersDocumentInput['bodySerializer'] = (
  data: CreateDispensersBody,
  headers: Headers
) => {
  const formData = new FormData()
  formData.append('file', data.file, data.file.name)

  return {
    body: formData,
    headers,
  }
}

export const useCreateDispensers: MutationHook<
  CreateDispensersBody,
  CreateDispensersDocumentResponse
> = () => {
  const [execute, { loading }] =
    useMutation<CreateDispensersDocumentResponse>(CreateDispensersDocument)

  async function request(input: CreateDispensersBody): Promise<void> {
    await execute({ variables: { input, bodySerializer } })
  }

  return { loading, request }
}

export const useDispenser: QueryHook<
  DispenserDocumentInput,
  DispenserDocumentResponse['dispenser']
> = (variables) => {
  const { data, loading, refetch } = useQuery<DispenserDocumentResponse>(DispenserDocument, {
    variables,
  })

  return {
    data: data?.dispenser,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useUpdateDispenser: MutationHook<
  UpdateDispenserDocumentInput,
  UpdateDispenserDocumentResponse['updateDispenser']
> = () => {
  const [execute, { loading }] =
    useMutation<UpdateDispenserDocumentResponse>(UpdateDispenserDocument)

  async function request(
    variables: UpdateDispenserDocumentInput
  ): Promise<UpdateDispenserDocumentResponse['updateDispenser']> {
    const { data } = await execute({ variables })

    if (!data?.updateDispenser) throw new Error('Could not update dispenser')

    return data.updateDispenser
  }

  return { loading, request }
}

export const useAssignDispenserToOutlet: MutationHook<
  AssignDispenserToOutletDocumentInput,
  AssignDispenserToOutletDocumentResponse
> = () => {
  const [execute, { loading }] = useMutation<AssignDispenserToOutletDocumentResponse>(
    AssignDispenserToOutletDocument
  )

  async function request(variables: AssignDispenserToOutletDocumentInput): Promise<void> {
    await execute({ variables })
  }

  return { loading, request }
}

export const useAssignDispenserToLocation: MutationHook<
  AssignDispenserToLocationDocumentInput,
  AssignDispenserToLocationDocumentResponse
> = () => {
  const [execute, { loading }] = useMutation<AssignDispenserToLocationDocumentResponse>(
    AssignDispenserToLocationDocument
  )

  async function request(variables: AssignDispenserToLocationDocumentInput): Promise<void> {
    await execute({ variables })
  }

  return { loading, request }
}

export const useDispenserLastOrder: QueryHook<
  DispenserLastOrderDocumentInput,
  DispenserLastOrderDocumentResponse['dispenserLastOrder']
> = (variables) => {
  const { data, loading, refetch } = useQuery<DispenserLastOrderDocumentResponse>(
    DispenserLastOrderDocument,
    { variables }
  )

  return {
    data: data?.dispenserLastOrder,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

type DispenserTasksInput = Omit<DispenserTasksDocumentInput, 'pagination'>

export const useDispenserTasks: CollectionQueryHook<
  DispenserTasksInput,
  DispenserTasksDocumentResponse['dispenserTasks']['tasks']
> = (input, options) => {
  const pagination = { ...defaultCollectionVariables }
  const variables: DispenserTasksDocumentInput = {
    ...input,
    pagination,
  }

  const { data, loading, fetchMore, refetch } = useQuery<DispenserTasksDocumentResponse>(
    DispenserTasksDocument,
    { ...options, variables }
  )

  const loadMore = async () => {
    if (loading || !data || !data.dispenserTasks.hasNext) return

    const offset = data.dispenserTasks.tasks.length
    await fetchMore({ variables: { ...variables, pagination: { ...pagination, offset } } })
  }

  return {
    data: data?.dispenserTasks.tasks,
    loading,
    loadMore,
    hasMore: data?.dispenserTasks.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}
