import { useQuery, useMutation } from '@apollo/client'
import { CollectionQueryHook, MutationHook, QueryHook } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  LoyaltyCampaignsDocument,
  LoyaltyCampaignsDocumentInput,
  LoyaltyCampaignsDocumentResponse,
  LoaltyCampaignDocument,
  LoaltyCampaignDocumentInput,
  LoaltyCampaignDocumentResponse,
  StopLoayltyCampaignDocument,
  StopLoayltyCampaignDocumentInput,
  StopLoayltyCampaignDocumentResponse,
} from '../docs'

type UseLoyaltyCampaignsInput = Omit<LoyaltyCampaignsDocumentInput, 'pagination'>
export const useLoyaltyCampaigns: CollectionQueryHook<
  UseLoyaltyCampaignsInput,
  LoyaltyCampaignsDocumentResponse['loyaltyCampaigns']['rewardCampaigns']
> = (input, options) => {
  const variables: LoyaltyCampaignsDocumentInput = {
    ...input,
    filters: input.filters ?? {},
    pagination: defaultCollectionVariables,
  }

  const { data, loading, fetchMore, refetch } = useQuery<LoyaltyCampaignsDocumentResponse>(
    LoyaltyCampaignsDocument,
    {
      ...options,
      variables,
    }
  )

  const collection = data?.loyaltyCampaigns

  const loadMore = async () => {
    if (loading || !data || !data.loyaltyCampaigns.hasNext) return

    const offset = data.loyaltyCampaigns.rewardCampaigns.length
    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: collection?.rewardCampaigns,
    loading,
    loadMore,
    hasMore: data?.loyaltyCampaigns.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useLoyaltyCampaign: QueryHook<
  LoaltyCampaignDocumentInput,
  LoaltyCampaignDocumentResponse['loyaltyCampaign']
> = (input, options) => {
  const { data, loading, refetch } = useQuery<LoaltyCampaignDocumentResponse>(
    LoaltyCampaignDocument,
    {
      ...options,
      variables: input,
    }
  )

  return {
    data: data?.loyaltyCampaign,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useStopLoyaltyCampaign: MutationHook<
  StopLoayltyCampaignDocumentInput,
  StopLoayltyCampaignDocumentResponse
> = (options) => {
  const [execute, { loading }] = useMutation<StopLoayltyCampaignDocumentResponse>(
    StopLoayltyCampaignDocument,
    options
  )

  async function request(variables: StopLoayltyCampaignDocumentInput) {
    await execute({ variables })
  }

  return {
    request,
    loading,
  }
}
