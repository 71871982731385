import { FC } from 'react'
import { User, UserRole } from '@web-panel/api'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { FormattedMessage, useIntl } from 'react-intl'
import { DropdownButton, useNotifications } from '@web-panel/shared'
import { useUpdateUserRole } from '@web-panel/oc-api'
import { availableRoles } from '../Filters'

type ChangeRoleButtonProps = {
  user: User
}

export const ChangeRoleButton: FC<ChangeRoleButtonProps> = ({ user }) => {
  const { formatMessage } = useIntl()
  const { cannot, loading } = useRoleAuthorization()
  const { request, loading: loadingUpdate } = useUpdateUserRole()
  const { pushNotification } = useNotifications()

  if (loading) return null

  if (cannot('user', 'update')) return <FormattedMessage id={`user-roles.${user.role}`} />

  const handleRoleChange = async (role: UserRole) => {
    try {
      await request({ id: user.id, input: { role } })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const menu = availableRoles.map((role) => ({
    title: formatMessage({ id: `user-roles.${role}` }),
    onClick: () => handleRoleChange(role),
    disabled: user.role === role,
  }))

  return (
    <DropdownButton
      variant="contained"
      color="secondary"
      title={formatMessage({ id: `user-roles.${user.role}` })}
      loading={loadingUpdate}
      menu={menu}
    />
  )
}
