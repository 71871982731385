import { useMutation, useQuery } from '@apollo/client'
import { CollectionQueryHook, MutationHook, QueryHook } from '@web-panel/api'
import { Address } from '@web-panel/api/src/data-types/address'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  AddressesDocument,
  AddressesDocumentInput,
  AddressesDocumentResponse,
  DeleteAddressDocument,
  DeleteAddressDocumentInput,
  DeleteAddressDocumentResponse,
  GetAddressDocument,
  GetAddressDocumentInput,
  GetAddressDocumentResponse,
} from '../docs'

export const useAddresses: CollectionQueryHook<
  Omit<AddressesDocumentInput, 'limit' | 'offset'>,
  Address[]
> = (input, options) => {
  const variables = {
    ...defaultCollectionVariables,
    ...input,
  }
  const { data, loading, refetch, fetchMore } = useQuery<AddressesDocumentResponse>(
    AddressesDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.addressesCollection.hasNext) return

    const offset = data.addressesCollection.partnerAddresses.length

    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.addressesCollection.partnerAddresses,
    loading,
    loadMore,
    hasMore: data?.addressesCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useAddress: QueryHook<GetAddressDocumentInput, GetAddressDocumentResponse['address']> =
  (variables) => {
    const { data, loading, refetch } = useQuery<
      GetAddressDocumentResponse,
      GetAddressDocumentInput
    >(GetAddressDocument, { variables })

    return {
      data: data?.address,
      loading,
      refetch: async () => {
        await refetch()
      },
    }
  }

export const useDeleteAddress: MutationHook<
  DeleteAddressDocumentInput,
  DeleteAddressDocumentResponse
> = () => {
  const [execute, { loading }] = useMutation<
    DeleteAddressDocumentResponse,
    DeleteAddressDocumentInput
  >(DeleteAddressDocument)

  async function request(variables: DeleteAddressDocumentInput): Promise<void> {
    await execute({ variables })
  }

  return { request, loading }
}
