import { FC } from 'react'
import {
  InfinitScroller,
  TableGrid,
  Button,
  useFormatDate,
  FormattedCurrency,
} from '@web-panel/shared'
import { Partner } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { OutletLink } from '@local/components/OutletLink'
import OrderStatusChip from '@local/components/OrderStatusChip'

import { useOrders } from '@web-panel/oc-api'

type OrdersTabProps = {
  partner: Partner
}
const OrdersTab: FC<OrdersTabProps> = ({ partner }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const { data: orders = [], loading, loadMore, hasMore } = useOrders({ partnerId: partner.id })
  const navigate = useNavigate()

  const onLoadMore = () => {
    if (!hasMore) return
    loadMore()
  }

  return (
    <InfinitScroller onLoadMore={onLoadMore}>
      <TableGrid
        loading={loading}
        collection={orders}
        fields={[
          {
            title: formatMessage({ id: 'models.order.id' }),
            render: ({ orderNumber }) => orderNumber,
          },
          {
            title: formatMessage({ id: 'models.order.outlet' }),
            render: ({ outletId }) => <OutletLink outletId={outletId} />,
          },
          {
            title: formatMessage({ id: 'models.order.total-cost' }),
            render: ({ totalCost, currencyCode }) => (
              <FormattedCurrency value={totalCost} currency={currencyCode.toUpperCase()} />
            ),
          },
          {
            title: formatMessage({ id: 'models.order.payment-type' }),
            render: ({ paymentType }) => <FormattedMessage id={`payment-types.${paymentType}`} />,
          },
          {
            title: formatMessage({ id: 'models.order.status' }),
            render: ({ status }) => <OrderStatusChip status={status} />,
          },
          {
            title: formatMessage({ id: 'models.order.invoice-status' }),
            render: ({ invoiceStatus }) => (
              <FormattedMessage id={`invoice-statuses.${invoiceStatus}`} />
            ),
          },
          {
            title: formatMessage({ id: 'models.order.create-date' }),
            render: ({ createDate }) => formatDate('date', createDate),
          },
        ]}
        onClickRow={(order) => navigate(`/orders/${order.id}`)}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        addItemButton={
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/orders/create', { state: { partner } })}
          >
            <FormattedMessage id="common.actions.create" />
          </Button>
        }
      />
    </InfinitScroller>
  )
}

export default OrdersTab
