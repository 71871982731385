import { VFC } from 'react'

import { TableGrid, InfinitScroller, useFormatDate } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { LoyaltyCampaignStatusChip } from '@local/components/LoyaltyCampaignStatusChip'
import { useNavigate } from 'react-router-dom'

import { useLoyaltyCampaigns } from '@web-panel/oc-api'

type PartnerCampaignsTabProps = {
  partnerId: string
}

export const PartnerCampaignsTab: VFC<PartnerCampaignsTabProps> = ({ partnerId }) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const formatDate = useFormatDate()
  const {
    data: campaigns = [],
    loading: loadingCampaigns,
    loadMore,
  } = useLoyaltyCampaigns({ partnerId }, { fetchPolicy: 'cache-and-network' })

  return (
    <InfinitScroller onLoadMore={loadMore}>
      <TableGrid
        loading={loadingCampaigns}
        collection={campaigns}
        fields={[
          {
            title: formatMessage({ id: 'models.loyalty-campaign.id' }),
            render: ({ externalId }) => externalId,
          },
          {
            title: formatMessage({ id: 'models.loyalty-campaign.name' }),
            render: ({ name }) => name,
          },
          {
            title: formatMessage({ id: 'models.loyalty-campaign.status' }),
            render: ({ status }) => <LoyaltyCampaignStatusChip status={status} />,
          },
          {
            title: formatMessage({ id: 'models.loyalty-campaign.start-date' }),
            render: ({ startDate }) => formatDate('human-date', startDate),
          },
          {
            title: formatMessage({ id: 'models.loyalty-campaign.end-date' }),
            render: ({ endDate }) => endDate && formatDate('human-date', endDate),
          },
          {
            title: formatMessage({ id: 'models.loyalty-campaign-reward.type' }),
            render: ({ rewardDetails }) => (
              <FormattedMessage id={`reward-template-types.${rewardDetails[0].type}`} />
            ),
          },
          {
            title: formatMessage({ id: 'models.loyalty-campaign-reward.function-type' }),
            render: ({ rewardDetails }) => (
              <FormattedMessage
                id={`loyalty-campaign-function-types.${rewardDetails[0].functionType}`}
              />
            ),
          },
        ]}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        onClickRow={(campaign) => navigate(`/partners/${partnerId}/campaigns/${campaign.id}`)}
      />
    </InfinitScroller>
  )
}
