import { FC, useEffect } from 'react'
import { TextField, DatePicker, LabeledSelect, Button } from '@web-panel/shared'
import { OutletAutocomplete } from '@local/components/Autocomplete/OutletAutocomplete'
import { useFormikContext } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import * as Yup from 'yup'
import { RentalInventoryType } from '@web-panel/api'
import _addDays from 'date-fns/addDays'
import { usePartnerBoxRentalConfiguration } from '@web-panel/oc-api'

export type DataFormFields = {
  outletId: string | null
  startDate: Date
  endDate: Date | null
  amount: number
}

export const dataValidationSchema = Yup.object().shape({
  outletId: Yup.string().nullable(),
  startDate: Yup.date().required(),
  endDate: Yup.date().nullable(),
  amount: Yup.number().moreThan(0).required(),
})

export type DataFormSectionProps = {
  partnerId: string
}
export const DataFormSection: FC<DataFormSectionProps> = ({ partnerId }) => {
  const { values, errors, setFieldValue, handleChange } = useFormikContext<DataFormFields>()
  const { data: rentalConfiguration } = usePartnerBoxRentalConfiguration({ partnerId })

  useEffect(() => {
    setFieldValue('price', rentalConfiguration?.price ?? 0)
  }, [rentalConfiguration?.price])

  const { formatMessage } = useIntl()

  return (
    <>
      <OutletAutocomplete
        label={formatMessage({ id: 'models.rental.outlet' })}
        outletId={values.outletId}
        partnerId={partnerId}
        onChange={(outlet) => setFieldValue('outletId', outlet?.id ?? null)}
        error={!!errors.outletId}
      />
      <LabeledSelect
        fullWidth
        className="mt-16"
        label={formatMessage({ id: 'models.rental.inventory-type' })}
        value={RentalInventoryType.Box}
        options={[RentalInventoryType.Box]}
        optionTitle={(option) => formatMessage({ id: `rental-inventory-types.${option}` })}
      />
      <TextField
        type="number"
        label={formatMessage({ id: 'models.rental.amount' })}
        value={values.amount}
        onChange={handleChange('amount')}
        error={!!errors.amount}
      />
      <DatePicker
        label={formatMessage({ id: 'models.rental.start-date' })}
        value={values.startDate}
        onChange={(date) => setFieldValue('startDate', date)}
        error={!!errors.startDate}
      />
      <div className="flex flex-row gap-8">
        <DatePicker
          label={formatMessage({ id: 'models.rental.end-date' })}
          value={values.endDate}
          onChange={(date) => setFieldValue('endDate', date)}
          minDate={_addDays(values.startDate, 1)}
          helperText={formatMessage({ id: 'common.actions.optional-field' })}
          error={!!errors.endDate}
        />
        <Button
          color="error"
          className="mb-19"
          disabled={!values.endDate}
          onClick={() => setFieldValue('endDate', null)}
        >
          <FormattedMessage id="common.actions.delete" />
        </Button>
      </div>
    </>
  )
}
