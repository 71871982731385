import { FC } from 'react'
import { FormSection, LabeledSelect, FormControlLabel, Switch } from '@web-panel/shared'
import { PartnerStatus } from '@web-panel/api'
import { useFormikContext } from 'formik'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'

export type AccountSectionFormFields = {
  partnerStatus: PartnerStatus
  authorized: boolean
  test: boolean
  kookyOperated: boolean
  loyaltyEnabled: boolean
  deliverySignatureRequired: boolean
}

export const accountSectionValidationSchema = Yup.object().shape({
  partnerStatus: Yup.string().required(),
  deliverySignatureRequired: Yup.boolean(),
})

export const AccountSection: FC = () => {
  const { formatMessage } = useIntl()
  const { values, errors, handleChange, setFieldValue } =
    useFormikContext<AccountSectionFormFields>()

  return (
    <FormSection firstSection title={formatMessage({ id: 'models.partner.account' })}>
      <LabeledSelect
        fullWidth
        label={formatMessage({ id: 'models.partner.partner-status' })}
        value={values.partnerStatus}
        options={Object.values(PartnerStatus)}
        optionTitle={(option) => formatMessage({ id: `partner-statuses.${option}` })}
        onChange={(e) => handleChange('partnerStatus')(e.target.value)}
        error={!!errors.partnerStatus}
      />
      <div className="mt-16">
        <FormControlLabel
          control={
            <Switch
              checked={values.authorized}
              onChange={(e, val) => setFieldValue('authorized', val)}
            />
          }
          label={formatMessage({ id: 'models.partner.authorized' })}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Switch checked={values.test} onChange={(e, val) => setFieldValue('test', val)} />
          }
          label={formatMessage({ id: 'models.partner.test' })}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={values.kookyOperated}
              onChange={(e, val) => setFieldValue('kookyOperated', val)}
            />
          }
          label={formatMessage({ id: 'models.partner.kooky-operated' })}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={values.loyaltyEnabled}
              onChange={(e, val) => setFieldValue('loyaltyEnabled', val)}
            />
          }
          label={formatMessage({ id: 'models.partner.loyalty-enabled' })}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={values.deliverySignatureRequired}
              onChange={(e, val) => setFieldValue('deliverySignatureRequired', val)}
            />
          }
          label={formatMessage({ id: 'models.partner.delivery-signature-required' })}
        />
      </div>
    </FormSection>
  )
}
