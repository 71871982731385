import { FC } from 'react'
import {
  AppPage,
  CardedContent,
  Button,
  DefaultErrorPage,
  useNotifications,
} from '@web-panel/shared'
import { getOutletLegalAddress } from '@web-panel/api'
import { useIntl, FormattedMessage } from 'react-intl'
import { useParams, useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import _pick from 'lodash/pick'
import modelIcons from '@local/model-icons'

import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { useOutlet, useUpdateOutlet } from '@web-panel/oc-api'
import OutletForm, { FormFields, validationSchema } from './Forms/OutletForm'

const EditPage: FC = () => {
  const { formatMessage } = useIntl()
  const params = useParams()
  const navigate = useNavigate()
  const { data: outlet, loading } = useOutlet({ id: params.id ?? '' })

  const { request: updateOutlet, loading: loadingUpdate } = useUpdateOutlet()
  const { pushNotification } = useNotifications()

  if (loading) return null
  if (!outlet)
    return (
      <DefaultErrorPage
        title={formatMessage({ id: 'errors.404.title' })}
        message={formatMessage({ id: 'errors.404.message' })}
      />
    )

  const onSubmit = async (values: FormFields) => {
    try {
      await updateOutlet({
        id: outlet.id,
        input: {
          type: values.type,
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          email: values.email,
          operationsPhone: values.operationsPhone,
          operationsEmail: values.operationsEmail,
          status: outlet.status,
          location: {
            ...values.location,
            cityCode: values.city,
          },
          legalAddress: values.legalAddress,
          featured: values.featured,
          batchDefaultSize: values.batchDefaultSize,
          hiddenOnMap: values.hiddenOnMap,
          cycleType: values.cycleType,
        },
      })
      navigate(-1)
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const initialValues: FormFields = {
    type: outlet.type,
    country: outlet.location.country.code,
    city: outlet.location.city.code,
    location: _pick(outlet.location, [
      'name',
      'street',
      'houseNumber',
      'postalCode',
      'workingHours',
      'latitude',
      'longitude',
    ]),
    legalAddress: getOutletLegalAddress(outlet),
    firstName: outlet.firstName,
    lastName: outlet.lastName,
    phone: outlet.phone,
    email: outlet.email,
    operationsPhone: outlet.operationsPhone,
    operationsEmail: outlet.operationsEmail,
    featured: outlet.featured ?? false,
    batchDefaultSize: outlet.batchDefaultSize,
    hiddenOnMap: outlet.hiddenOnMap,
    cycleType: outlet.cycleType,
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(formProps) => {
        const headerActionButtons = [
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            loading={loadingUpdate}
            disabled={!formProps.dirty || !formProps.isValid}
            onClick={() => formProps.handleSubmit()}
          >
            <FormattedMessage
              id="common.actions.update-with-name"
              values={{ name: formatMessage({ id: 'models.outlet' }) }}
            />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            icon={modelIcons.outlet}
            title={formatMessage({ id: 'routes.outlets-edit' }, { name: outlet.location.name })}
            actionButtons={headerActionButtons}
          >
            <CardedContent>
              <OutletForm {...formProps} outlet={outlet} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export default pageWithRoleAuthorization(EditPage, ({ can }) => can('outlet', 'update'))
