import { FC } from 'react'
import {
  Button,
  ModalBody,
  buildModal,
  ModalBodyProps,
  FormLabel,
  useNotifications,
} from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useActivateGroupLabel } from '@web-panel/oc-api'
import { GroupLabel } from '@web-panel/api'

type ActivateGroupLabelModalConfirmationProps = ModalBodyProps & {
  onActivated: () => void
  groupLabel: GroupLabel
}

const ActivateGroupLabelModalConfirmation: FC<ActivateGroupLabelModalConfirmationProps> = ({
  onActivated,
  onClose,
  groupLabel,
}) => {
  const { formatMessage } = useIntl()
  const { pushNotification } = useNotifications()
  const { request: activateGroupLabel } = useActivateGroupLabel()

  const onActivate = async () => {
    try {
      await activateGroupLabel({
        input: { groupLabelCode: groupLabel?.code },
      })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-created' })
      )
      onActivated()
      onClose()
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <ModalBody
      title="Activate Group Label"
      actions={[
        <Button key="cancel" onClick={onClose}>
          <FormattedMessage id="common.actions.cancel" />
        </Button>,
        <Button key="confirm" onClick={onActivate}>
          <FormattedMessage id="common.actions.confirm" />
        </Button>,
      ]}
    >
      <FormLabel>
        {`You are activating 
        ${groupLabel?.externalId} ${groupLabel?.sourceShipmentSequence?.name ?? ''}`}
      </FormLabel>
    </ModalBody>
  )
}

export default buildModal(ActivateGroupLabelModalConfirmation, { fullWidth: true })
