import { BoxActivityRebootType } from '@web-panel/api'
import { Typography } from '@web-panel/shared'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

type BoxActivityRebootExplanationProps = {
  rebootType: BoxActivityRebootType
}
export const BoxActivityRebootExplanation: FC<BoxActivityRebootExplanationProps> = ({
  rebootType,
}) => {
  return (
    <>
      <Typography variant="body1">
        <FormattedMessage id="models.box-activity.reboot-reason" />:
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: 600 }}>
        <FormattedMessage id={`box-activity-reboot-types.${rebootType}`} />
      </Typography>
    </>
  )
}
