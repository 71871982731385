import { gql } from '@apollo/client'
import {
  Order,
  OrderStatus,
  Batch,
  batchFields,
  PaymentType,
  orderFields,
  OrderReturnType,
  OrderInvoiceType,
  ConsumableProductItemType,
  containerFields,
  Pagination,
  Container,
} from '@web-panel/api'

export type OrdersDocumentInput = {
  limit: number
  offset: number
  partnerId?: string
  outletId?: string
  status?: OrderStatus
  cityCode?: string
  search?: string
}
export type OrdersDocumentResponse = {
  orderCollection: {
    orders: Order[]
    hasNext: boolean
  }
}
export const OrdersDocument = gql`
  query Orders(
    $limit: Int!,
    $offset: Int,
    $partnerId: String,
    $outletId: String,
    $status: String,
    $search: String
    $cityCode: String
  ) {
    orderCollection(
      limit: $limit,
      offset: $offset,
      partnerId: $partnerId,
      outletId: $outletId,
      status: $status,
      search: $search
      cityCode: $cityCode
    )
      @rest(type: "OrderCollection", path: "/operations-service/orders?{args}") {
      orders @type(name: "Order") {
        ${orderFields}
      }
      hasNext
    }
  }
`

export type GetOrderDocumentInput = {
  id: string
}
export type GetOrderDocumentResponse = {
  order: Order
}
export const GetOrderDocument = gql`
  query Order($id: String!) {
    order(id: $id) @rest(type: "Order", path: "/operations-service/orders/{args.id}") {
      ${orderFields}
    }
  }
`

export type AttachBatchToOrderDocumentInput = {
  id: string
  input: {
    batchId: string
  }
}
export type AttachBatchToOrderDocumentResponse = void
export const AttachBatchToOrderDocument = gql`
  mutation AttachBatchToOrder($id: String!, $input: any) {
    attachBatchToOrder: publish(id: $id, input: $input)
      @rest(path: "/operations-service/orders/{args.id}/batch", method: "POST") {
      NoResponse
    }
  }
`

export type OrderBatchesDocumentInput = {
  id: string
  limit: number
  offset?: number
}
export type OrderBatchesDocumentResponse = {
  orderBatches: {
    batches: Batch[]
    hasNext: boolean
  }
}
export const OrderBatchesDocument = gql`
  query OrderBatches($id: String!, $limit: Int!, $offset: Int) {
    orderBatches(id: $id, limit: $limit, offset: $offset) @rest(type: "OrderBatchesCollection", path: "/operations-service/orders/{args.id}/batches?limit={args.limit}&offset={args.offset}") {
      batches @type(name: "Batch") {
        ${batchFields}
      }
      hasNext
    }
  }
`

export type UpdateOrderStatusDocumentInput = {
  id: string
  input: {
    status: OrderStatus
  }
}
export type UpdateOrderStatusDocumentResponse = void
export const UpdateOrderStatusDocument = gql`
  mutation UpdateOrderStatus($id: String!, $input: any) {
    updateOrderStatus: publish(id: $id, input: $input)
      @rest(
        type: "OrderUpdateStatusResponse"
        path: "/operations-service/orders/{args.id}/status"
        method: "PUT"
      ) {
      NoResponse
    }
  }
`

export type UpdateOrderReturnTypeDocumentInput = {
  id: string
  input: {
    returnType: OrderReturnType
  }
}
export type UpdateOrderReturnTypeDocumentResponse = void
export const UpdateOrderReturnTypeDocument = gql`
  mutation UpdateOrderReturnType($id: String!, $input: any) {
    updateOrderReturnType: publish(id: $id, input: $input)
      @rest(
        type: "OrderUpdateReturnTypeResponse"
        path: "/operations-service/orders/{args.id}/return_type"
        method: "PUT"
      ) {
      NoResponse
    }
  }
`

export type RemoveBatchFromOrderDocumentInput = {
  orderId: string
  batchId: string
}
export type RemoveBatchFromOrderDocumentResponse = void

export const RemoveBatchFromOrderDocument = gql`
  mutation RemoveBachFromOrder($orderId: String!, $batchId: String!) {
    removeBatchFromOrder: publish(orderId: $orderId, batchId: $batchId)
      @rest(
        type: "RemoveBatchFromOrderResponse"
        path: "/operations-service/orders/{args.orderId}/batches/{args.batchId}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`

type RefundableInput = {
  itemModelId: string
  amount: number
}

type ConsumablesInput = {
  type: ConsumableProductItemType
  amount: number
}

export type CreateOrderDocumentInput = {
  input: {
    partnerId: string
    outletId: string
    firstName: string
    lastName: string
    phone: string
    paymentType: PaymentType
    comment: string
    currencyCode: string // TODO: deprecate soon
    refundables: RefundableInput[]
    consumables: ConsumablesInput[]
    expectedDeliveryDate?: string
    expectedDeliveryTimeFrom?: string
    expectedDeliveryTimeTo?: string
    returnType?: OrderReturnType
    invoiceType?: OrderInvoiceType
  }
}

export type CreateOrderDocumentResponse = {
  createOrder: Order
}

export const CreateOrderDocument = gql`
  mutation CreateOrder($input: any) {
    createOrder: publish(input: $input)
      @rest(type: "Order", path: "/operations-service/orders", method: "POST") {
      ${orderFields}
    }
  }
`

export type CreateOrderSignatureDocumentInput = {
  orderId: string
  input: {
    signature: Blob
  }
  bodySerializer?: (
    data: { signature: Blob },
    headers: Headers
  ) => { body: FormData; headers: Headers }
}
export type CreateOrderSignatureDocumentResponse = void
export const CreateOrderSignatureDocument = gql`
  mutation CreateOrderSignature($orderId: String!, $input: any, $bodySerializer: any) {
    createOrderSignature(orderId: $orderId, input: $input)
      @rest(
        type: "OrderSignature"
        path: "/operations-service/orders/{args.orderId}/signature"
        method: "POST"
        bodySerializer: $bodySerializer
      ) {
      NoCache
    }
  }
`

export type DeleteOrderSignatureDocumentInput = {
  orderId: string
}
export type DeleteOrderSignatureDocumentResponse = void
export const DeleteOrderSignatureDocument = gql`
  mutation DeleteOrderSignature($orderId: String!) {
    deleteOrderSignature(orderId: $orderId)
      @rest(
        type: "OrderSignature"
        path: "/operations-service/orders/{args.orderId}/signature"
        method: "DELETE"
      ) {
      NoCache
    }
  }
`

export type OrderAttachedContainersDocumentInput = {
  id: string
  pagination: Pagination
}
export type OrderAttachedContainersDocumentResponse = {
  orderAttachedContainers: {
    containers: Container[]
    hasNext: boolean
  }
}
export const OrderAttachedContainersDocument = gql`
  query OrderAttachedContainers($id: String!, $pagination: any) {
    orderAttachedContainers(id: $id, pagination: $pagination)
      @rest(type: "OrderAttachedContainers", path: "/operations-service/orders/{args.id}/containers?{args.pagination}") {
      containers @type(name: "Container") {
        ${containerFields}
      }
      hasNext
    }
  }
`

export type AttachContainerToOrderDocumentInput = {
  id: string
  input: {
    qrCode: string
  }
}
export type AttachContainerToOrderDocumentResponse = void
export const AttachContainerToOrderDocument = gql`
  mutation AttachContainerToOrderDocument($id: String!, $input: any) {
    attachContainerToOrder: publish(id: $id, input: $input)
      @rest(
        type: "AttachContainerToOrder"
        path: "/operations-service/orders/{args.id}/containers"
        method: "POST"
      ) {
      NoResponse
    }
  }
`
export type RemoveContainerFromOrderDocumentInput = {
  id: string
  containerId: string
}
export type RemoveContainerFromOrderDocumentResponse = void
export const RemoveContainerFromOrderDocument = gql`
  mutation RemoveContainerFromOrder($id: String!, $containerId: String!) {
    removeContainerFromOrder: publish(id: $id, containerId: $containerId)
      @rest(
        type: "RemoveContainerFromOrder"
        path: "/operations-service/orders/{args.id}/containers/{args.containerId}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`
