import { FC } from 'react'
import { AppPage, Button, CardedContent, getISODate, useNotifications } from '@web-panel/shared'
import { BoxRental } from '@web-panel/api'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { Error404 } from '@local/pages/404'
import modelIcons from '@local/model-icons'
import { Formik } from 'formik'
import { useBoxRental, useUpdateBoxRental, useOutlet } from '@web-panel/oc-api'
import { UpdateRentalForm, FormFields, validationSchema } from './Forms/UpdateForm'

type PageComponentProps = {
  rental: BoxRental
}
const PageComponent: FC<PageComponentProps> = ({ rental }) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()
  const { request: updateRental, loading: loadingUpdateRental } = useUpdateBoxRental()
  const { data: outlet, loading: loadingOutlet } = useOutlet(
    { id: rental.outletId! },
    { skip: !rental.outletId }
  )

  if (loadingOutlet) return null

  const onSubmit = async (values: FormFields) => {
    try {
      await updateRental({
        id: rental.id,
        input: {
          status: values.status,
          startDate: getISODate(values.startDate),
          endDate: values.endDate ? getISODate(values.endDate) : null,
          amount: values.amount,
        },
      })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
      navigate(-1)
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const initialValues: FormFields = {
    status: rental.status,
    startDate: new Date(rental.startDate),
    endDate: rental.endDate ? new Date(rental.endDate) : null,
    amount: rental.amount,
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, isValid }) => {
        const headerActionButtons = [
          <Button
            variant="contained"
            color="secondary"
            loading={loadingUpdateRental}
            disabled={!isValid}
            onClick={() => handleSubmit()}
          >
            <FormattedMessage id="common.actions.update" />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            title={formatMessage(
              { id: 'routes.partners.rentals.update' },
              { name: rental.externalId }
            )}
            icon={modelIcons.rental}
            actionButtons={headerActionButtons}
          >
            <CardedContent padded>
              <UpdateRentalForm outlet={outlet} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export default pageWithRoleAuthorization(
  () => {
    const { id } = useParams<{ id: string }>()
    const { data: rental, loading } = useBoxRental({ id: id! })
    if (!rental && loading) return null
    if (!rental) return <Error404 />

    return <PageComponent rental={rental} />
  },
  ({ can }) => can('boxRental', 'preview') && can('boxRental', 'update')
)
