import { useQuery } from '@apollo/client'
import { CollectionQueryHook } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import { RentalsDocument, RentalsDocumentInput, RentalsDocumentResponse } from '../docs'

export const useRentals: CollectionQueryHook<
  RentalsDocumentInput['filters'],
  RentalsDocumentResponse['rentals']['inventories']
> = (filters, options) => {
  const pagination = { ...defaultCollectionVariables }
  const variables: RentalsDocumentInput = {
    filters: filters ?? {},
    pagination,
  }

  const { data, loading, fetchMore, refetch } = useQuery<RentalsDocumentResponse>(RentalsDocument, {
    ...options,
    variables,
  })

  const loadMore = async () => {
    if (loading || !data || !data.rentals.hasNext) return

    const offset = data.rentals.inventories.length
    await fetchMore({
      variables: { variables: { ...variables, pagination: { ...pagination, offset } } },
    })
  }

  return {
    data: data?.rentals.inventories,
    hasMore: data?.rentals.hasNext ?? false,
    loading,
    loadMore,
    refetch: async () => {
      await refetch()
    },
  }
}
