import { FC, useMemo } from 'react'
import { ConsumableProductItemType, Currency } from '@web-panel/api'
import { TextField, LabeledSelect } from '@web-panel/shared'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { useProductItemConsumables } from '@web-panel/oc-api'

export type FormFields = {
  consumableType: ConsumableProductItemType
  cost: number
  currency: Currency
}

export const validationSchema = Yup.object().shape({
  cost: Yup.number().min(0.01).required(),
})

const availableCurrencies: Record<string, Currency> = {
  CHF: {
    code: 'CHF',
    name: 'CHF',
    symbol: 'CHF',
  },
}

export const defaultValues: FormFields = {
  consumableType: ConsumableProductItemType.Lid,
  cost: 0,
  currency: availableCurrencies.CHF,
}

type CreateConsumableFormProps = FormikProps<FormFields>
export const CreateConsumableForm: FC<CreateConsumableFormProps> = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  setFieldValue,
}) => {
  const { formatMessage } = useIntl()
  const { data: consumables = [] } = useProductItemConsumables({ fetchPolicy: 'cache-first' })

  const availableConsumables = useMemo(() => {
    const usedTypes = consumables.map(({ consumableType }) => consumableType)
    return _.difference(Object.values(ConsumableProductItemType), usedTypes)
  }, [consumables])

  return (
    <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit}>
      <div className="flex flex-col p-16 sm:p-24 max-w-2xl">
        <LabeledSelect
          fullWidth
          label={formatMessage({ id: 'models.consumable-product-item.type' })}
          options={Object.values(ConsumableProductItemType)}
          optionTitle={(option) => formatMessage({ id: `consumable-product-item-types.${option}` })}
          optionDisabled={(option) => !_.includes(availableConsumables, option)}
          value={values.consumableType}
          onChange={(e) => handleChange('consumableType')(e.target.value)}
          error={!!errors.consumableType}
        />
        <TextField
          fullWidth
          className="mt-16"
          label={formatMessage({ id: 'models.consumable-product-item.cost' })}
          onChange={handleChange('cost')}
          value={values.cost}
          error={!!errors.cost}
        />
        <LabeledSelect
          fullWidth
          className="mt-16"
          label={formatMessage({ id: 'models.consumable-product-item.currency' })}
          options={Object.keys(availableCurrencies)}
          optionTitle={(option) => availableCurrencies[option].name}
          onChange={(e) => setFieldValue('currency', availableCurrencies[e.target.value])}
          value={values.currency.code}
          error={!!errors.currency}
        />
      </div>
    </form>
  )
}
