import { FC } from 'react'

import { Button, useModalRef } from '@web-panel/shared'
import { useOutlet } from '@web-panel/oc-api'
import { defaultLocation, LocationData } from '../Forms/LocationInput'
import CustomLocationModal from '../Modals/CustomLocationModal'

type BaseButtonProps = {
  title: string
  variant: 'outlined' | 'contained'
  color: 'error' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'inherit'
  onAddLocation: (location: LocationData) => void
}

type ButtonWithExistingLocationProps = BaseButtonProps & {
  outletId: string
}

const ButtonWithExistingLocation: FC<ButtonWithExistingLocationProps> = ({
  title,
  outletId,
  variant,
  color,
  onAddLocation,
}) => {
  const modalRef = useModalRef()
  const { data: outlet, loading } = useOutlet({ id: outletId })

  const location: LocationData = !outlet
    ? defaultLocation
    : {
        ...defaultLocation,
        city: outlet.location.city,
        country: outlet.location.country,
        name: outlet.location.name,
        googlePlaceId: outlet.location.googlePlaceId,
        latitude: String(outlet.location.latitude),
        longitude: String(outlet.location.longitude),
        street: outlet.location.street,
        houseNumber: outlet.location.houseNumber,
        postalCode: outlet.location.postalCode,
        workingHours: outlet.location.workingHours ?? defaultLocation.workingHours,
      }

  const onModalSubmit = (prefill: boolean) => {
    if (prefill) {
      onAddLocation(location)
    } else {
      onAddLocation(defaultLocation)
    }
  }

  return (
    <>
      <Button
        variant={variant}
        color={color}
        loading={loading}
        onClick={() => modalRef.current?.open()}
      >
        {title}
      </Button>
      <CustomLocationModal ref={modalRef} onSubmit={onModalSubmit} />
    </>
  )
}

const ButtonWithoutExistingLocation: FC<BaseButtonProps> = ({
  title,
  variant,
  color,
  onAddLocation,
}) => {
  return (
    <Button variant={variant} color={color} onClick={() => onAddLocation(defaultLocation)}>
      {title}
    </Button>
  )
}

type AddLocationButtonProps = Partial<BaseButtonProps> & {
  title: string
  outletId: string | null
}

const AddLocationButton: FC<AddLocationButtonProps> = ({
  title,
  outletId,
  variant = 'outlined',
  color = 'primary',
  onAddLocation = () => {},
}) => {
  return outletId ? (
    <ButtonWithExistingLocation
      title={title}
      outletId={outletId}
      variant={variant}
      color={color}
      onAddLocation={onAddLocation}
    />
  ) : (
    <ButtonWithoutExistingLocation
      title={title}
      variant={variant}
      color={color}
      onAddLocation={onAddLocation}
    />
  )
}

export default AddLocationButton
