export enum TriggerTypes {
  User = 'USER',
  System = 'SYSTEM',
  Backend = 'BACKEND',
}

export enum ScheduleStatus {
  Cancelled = 'CANCELLED',
}

export type Schedule = {
  id: string
  planedLocalPickupDate: string
  planedPickupDate: string
  closeTime: string
  dispatchConfirmationNumbers: string[]
  partnerShippingAddressId: string
  cancelDate: string
  canceledBy: string
  cancelTriggerType: TriggerTypes
  createdBy: string
  createTriggerType: TriggerTypes
  createDate: string
}

export const scheduleFields = `
  id
  planedLocalPickupDate
  planedPickupDate
  closeTime
  dispatchConfirmationNumbers
  partnerShippingAddressId
  cancelDate
  canceledBy
  cancelTriggerType
  createdBy
  createTriggerType
  createDate
`
