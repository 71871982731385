import { gql } from '@apollo/client'
import { Address, addressFields } from '@web-panel/api'

export type AddressesDocumentInput = {
  limit: number
  offset: number
  search?: string
}

export type AddressesDocumentResponse = {
  addressesCollection: {
    partnerAddresses: Address[]
    hasNext: boolean
  }
}

export const AddressesDocument = gql`
  query Addresses($limit: Int!, $offset: Int!, $search: String) {
    addressesCollection(limit: $limit, offset: $offset, search: $search) @rest(type: "AddressesCollection", path: "/shipment-service/partner/addresses?{args}") {
      partnerAddresses @type(name: "Address") {
        ${addressFields}
      }
      hasNext
    }
  }
`

export type GetAddressDocumentInput = {
  id: string
}
export type GetAddressDocumentResponse = {
  address: Address
}

export const GetAddressDocument = gql`
  query Address($id: String!) {
    address(id: $id) @rest(type: "Address", path: "/shipment-service/partner/addresses/{args.id}") {
      ${addressFields}
    }
  }
`

export type DeleteAddressDocumentInput = {
  id: string
}
export type DeleteAddressDocumentResponse = void

export const DeleteAddressDocument = gql`
  mutation DeleteAddress($id: String!) {
    deleteAddress(id: $id)
      @rest(
        type: "AddressDeleteResponse"
        path: "/shipment-service/partner/addresses/{args.id}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`
