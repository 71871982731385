import { FC, useState } from 'react'
import { useIntervalWhen } from 'rooks'
import { formatDuration } from 'date-fns'

type CounterProps = {
  value: number
}

type TimeComponents = {
  days: number
  hours: number
  minutes: number
  seconds: number
}

function getTimeComponents(value: number): TimeComponents {
  const seconds = Math.floor(value / 1000) % 60
  const minutes = Math.floor(value / 60000) % 60
  const hours = Math.floor(value / 3600000) % 24
  const days = Math.floor(value / 86400000)

  return { days, hours, minutes, seconds }
}

export const Counter: FC<CounterProps> = ({ value }) => {
  const [dynamicValue, setDynamicValue] = useState(value)
  const { seconds, minutes, hours, days } = getTimeComponents(dynamicValue)

  useIntervalWhen(
    () => {
      setDynamicValue(dynamicValue + 1000)
    },
    1000,
    true
  )

  return (
    <>
      {formatDuration({
        seconds,
        minutes,
        hours,
        days,
      })}
    </>
  )
}
