import { FC } from 'react'
import { Outlet } from '@web-panel/api'
import { Avatar } from '@web-panel/shared'
import classNames from 'classnames'

type OutletAvatarProps = {
  outlet: Outlet
  variant?: 'inline' | 'preview'
  className?: string
  onClick?: () => void
}

export const OutletAvatar: FC<OutletAvatarProps> = ({
  className,
  outlet,
  variant = 'inline',
  onClick,
}) => {
  const sx = variant === 'preview' ? { width: '30rem', height: '30rem' } : {}

  return (
    <Avatar
      data-testid="OutletAvatar"
      variant="square"
      className={classNames(className, {
        'text-96': variant === 'preview',
        'cursor-pointer': onClick,
      })}
      src={outlet.logo ?? undefined}
      onClick={onClick}
      sx={sx}
    >
      {outlet.location.name[0].toUpperCase()}
    </Avatar>
  )
}
