import { gql } from '@apollo/client'
import { Donation, DonationStatus, donationFields } from '@web-panel/api'

export type DonationsDocumentInput = {
  limit: number
  offset?: number
  status?: DonationStatus
  organization?: string
}

export type DonationsDocumentResponse = {
  donationsCollection: {
    donations: Donation[]
    hasNext: boolean
  }
}

export const DonationsDocument = gql`
  query Dontations($limit: Int!, $offset: Int, $status: String, $organization: String) {
    donationsCollection(limit: $limit, offset: $offset, status: $status, organization: $organization) @rest(type: "DonationCollection", path: "/finance-service/oc/donations?{args}") {
      donations @type(name: "Donation") {
        ${donationFields}
      }
      hasNext
    }
  }
`

export type ConsumerDonationsDocumentInput = {
  consumerId: string
  limit: number
  offset?: number
  status?: DonationStatus
  organization?: string
}

export type ConsumerDonationsDocumentResponse = {
  consumerDonationsCollection: {
    donations: Donation[]
    hasNext: boolean
  }
}

export const ConsumerDonationsDocument = gql`
  query ConsumerDontations($limit: Int!, $offset: Int, $consumerId: String!, $status: String, $organization: String) {
    consumerDonationsCollection(params: {limit: $limit, offset: $offset, status: $status, organization: $organization}, consumerId: $consumerId) @rest(type: "ConsumerDonationCollection", path: "/finance-service/oc/donations/consumer/{args.consumerId}?{args.params}") {
      donations @type(name: "Donation") {
        ${donationFields}
      }
      hasNext
    }
  }
`

export type DonationDocumentInput = {
  donationId: string
}

export type DonationDocumentResponse = {
  donation: Donation
}

export const DonationDocument = gql`
  query Donation($donationId: String!) {
    donation(donationId: $donationId) @rest(type: "Donation", path: "/finance-service/oc/donations/{args.donationId}") {
      ${donationFields}
    }
  }
`
