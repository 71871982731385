import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { ActivityType } from '@web-panel/api'

type ActivityTypeChipProps = {
  type: ActivityType
}

export const ActivityTypeChip: FC<ActivityTypeChipProps> = ({ type }) => {
  return <FormattedMessage id={`activity-types.${type}`} />
}
