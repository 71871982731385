import { FC } from 'react'

export const PublicLayout: FC = ({ children }) => {
  return (
    <div className="flex flex-col flex-auto items-center justify-center p-24 min-h-screen">
      <div className="flex flex-col items-center justify-center w-full">
        <div className="w-full max-w-sm">{children}</div>
      </div>
    </div>
  )
}
