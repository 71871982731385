import { FC } from 'react'
import { FormattedCurrency, TableGrid } from '@web-panel/shared'

import { FormattedNumber, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { ProductItemModelConfigurationStatusChip } from '@local/components/ProductItemModelConfigurationStatusChip'

import { useOutletProductItemModelConfigurations } from '@web-panel/oc-api'

type OutletRefundablesTabProps = {
  outletId: string
}

export const OutletRefundablesTab: FC<OutletRefundablesTabProps> = ({ outletId }) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { can } = useRoleAuthorization()
  const { data: partnerConfigurations = [], loading: loadingPartnerConfigurations } =
    useOutletProductItemModelConfigurations({ outletId }, { fetchPolicy: 'cache-and-network' })

  return (
    <TableGrid
      collection={partnerConfigurations}
      loading={loadingPartnerConfigurations}
      fields={[
        {
          title: formatMessage({ id: 'models.refundable-product-item.item-model' }),
          render: ({ itemModel }) => itemModel.id,
        },
        {
          title: formatMessage({ id: 'models.refundable-product-item.cost' }),
          render: ({ currency, cost }) => (
            <FormattedCurrency value={cost} currency={currency.name} />
          ),
        },
        {
          title: formatMessage({ id: 'models.refundable-product-item.service-fee' }),
          render: ({ currency, serviceFee }) => (
            <FormattedCurrency value={serviceFee} currency={currency.name} />
          ),
        },
        {
          title: formatMessage({ id: 'models.refundable-product-item.payout-fee-percent' }),
          render: ({ payoutFeePercent }) => (
            <FormattedNumber value={payoutFeePercent / 100} style="percent" />
          ),
        },
        {
          title: formatMessage({ id: 'models.refundable-product-item.refund-fee-percent' }),
          render: ({ refundFeePercent }) => (
            <FormattedNumber value={refundFeePercent / 100} style="percent" />
          ),
        },
        {
          title: formatMessage({ id: 'models.refundable-product-item.status' }),
          render: ({ status }) => <ProductItemModelConfigurationStatusChip status={status} />,
        },
      ]}
      rowClickable={can('geoCity', 'previewRefundable')}
      onClickRow={(configuration) => navigate(`refundables/${configuration.itemModel.id}`)}
      emptyLabel={formatMessage({ id: 'common.no-data' })}
    />
  )
}
