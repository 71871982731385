import { FC } from 'react'
import { OrderStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { Chip, ComponentColor } from '@web-panel/shared'

const statusColorMapping: Record<OrderStatus, ComponentColor> = {
  [OrderStatus.Requested]: 'info',
  [OrderStatus.Processing]: 'secondary',
  [OrderStatus.Delivered]: 'success',
  [OrderStatus.Rejected]: 'error',
}

type OrderStatusChipProps = {
  status: OrderStatus
}

const OrderStatusChip: FC<OrderStatusChipProps> = ({ status }) => {
  const { formatMessage } = useIntl()

  const color = statusColorMapping[status] ?? 'default'

  return (
    <Chip
      className="min-w-84"
      color={color}
      label={formatMessage({ id: `order-statuses.${status}` })}
    />
  )
}

export default OrderStatusChip
