import { FC, useState, useMemo } from 'react'
import { differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns'
import { useIntervalWhen } from 'rooks'
import _compact from 'lodash/compact'

function formatTimeString(value: number) {
  const str = `${value}`
  if (str.length > 1) return str

  return `0${str}`
}

export type HeartbeatCounterProps = {
  date: Date | string
}

export const HeartbeatCounter: FC<HeartbeatCounterProps> = (props) => {
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  const date = new Date(props.date)

  const calculateDiff = () => {
    const now = new Date()
    setSeconds(differenceInSeconds(now, date) % 60)
    setMinutes(differenceInMinutes(now, date) % 60)
    setHours(differenceInHours(now, date))
  }

  useIntervalWhen(calculateDiff, 1000, true, true)

  const counter = useMemo(() => {
    return _compact([
      hours > 0 && hours,
      minutes > 0 && formatTimeString(minutes),
      formatTimeString(seconds),
    ]).join(':')
  }, [hours, minutes, seconds])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{counter}</>
}
