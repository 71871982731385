import { FC } from 'react'
import { BoxStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { Chip, ComponentColor } from '@web-panel/shared'

const statusColorMapping: Record<BoxStatus, ComponentColor> = {
  [BoxStatus.Available]: 'success',
  [BoxStatus.Broken]: 'error',
  [BoxStatus.WarehouseBroken]: 'error',
  [BoxStatus.Deleted]: 'error',
  [BoxStatus.Full]: 'error',
  [BoxStatus.NotAvailable]: 'error',
  [BoxStatus.NoHeartbeat]: 'warning',
  [BoxStatus.Warehouse]: 'info',
}

type BoxStatusChipProps = {
  status: BoxStatus
}

const BoxStatusChip: FC<BoxStatusChipProps> = ({ status }) => {
  const { formatMessage } = useIntl()

  const color = statusColorMapping[status] ?? 'default'

  return (
    <Chip
      className="min-w-72"
      color={color}
      label={formatMessage({ id: `box-statuses.${status}` })}
    />
  )
}

export default BoxStatusChip
