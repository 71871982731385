import { FC, useState, useRef } from 'react'
import {
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  buildModal,
  ModalBodyProps,
  useNotifications,
} from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { ExternalRouterModel } from '@local/pages/ExternalRouter/helpers'
import ResourceQRScanner from '../Components/ResourceQRScanner'

type ResourceScannerModalProps = ModalBodyProps & {
  title: string
  resource: ExternalRouterModel
  onAttachResource: (id: string) => Promise<void>
  attachedResourceIds: string[]
}

const ResourceScannerModal: FC<ResourceScannerModalProps> = ({
  title,
  onClose,
  resource,
  onAttachResource,
  attachedResourceIds,
}) => {
  const previousIdRef = useRef<string | null>(null)
  const { formatMessage } = useIntl()
  const [detectedResourceId, setDetectedResourceId] = useState<string | null>(null)
  const { pushNotification } = useNotifications()

  const onDetectResourceId = async (resourceId: string | null) => {
    if (resourceId === previousIdRef.current) return

    previousIdRef.current = resourceId
    if (!resourceId) return
    const isAlreadyAttached = attachedResourceIds.includes(resourceId)

    if (isAlreadyAttached) {
      pushNotification(
        'error',
        formatMessage(
          { id: 'common.notifications.already-attached-to-order-with-name' },
          { name: formatMessage({ id: `models.${resource}` }) }
        )
      )
    } else {
      setDetectedResourceId(resourceId)
    }
  }

  const handleAttachResource = () => {
    if (!detectedResourceId) return

    onAttachResource(detectedResourceId)
    setDetectedResourceId(null)
  }

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div>
          {!detectedResourceId ? (
            <ResourceQRScanner resource={resource} onDetectResourceId={onDetectResourceId} />
          ) : (
            <div className="text-center">
              <Button
                size="large"
                variant="contained"
                color="secondary"
                onClick={handleAttachResource}
              >
                <FormattedMessage
                  id="common.actions.attach-with-name"
                  values={{ name: formatMessage({ id: `models.${resource}` }) }}
                />
              </Button>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="common.actions.close" />
        </Button>
      </DialogActions>
    </>
  )
}

export default buildModal(ResourceScannerModal, { fullWidth: true, maxWidth: 'md' })
