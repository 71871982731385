import { FC } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { Partner } from '@web-panel/api'
import { AppPage, CardedContent, Button, useNotifications } from '@web-panel/shared'
import { Formik } from 'formik'
import modelIcons from '@local/model-icons'
import { Error404 } from '@local/pages/404'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { usePartner, useUpdatePartner } from '@web-panel/oc-api'
import PartnerForm, { FormFields, validationSchema } from './Forms/PartnerForm'
import { buildPartnerFormInitialValues, partnerFormFieldsToInputValues } from './helpers'

type EditPageProps = {
  partner: Partner
}

const EditPage: FC<EditPageProps> = ({ partner }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { request: updatePartner, loading: updatePartnerLoading } = useUpdatePartner()
  const { pushNotification } = useNotifications()

  const onSubmit = async (values: FormFields) => {
    try {
      await updatePartner({
        id: partner.id,
        input: partnerFormFieldsToInputValues(values),
      })
      navigate(-1)
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <Formik<FormFields>
      initialValues={buildPartnerFormInitialValues(partner)}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, dirty, isValid }) => {
        const headerActionButtons = [
          <Button
            fullWidth
            key="update"
            variant="contained"
            color="secondary"
            loading={updatePartnerLoading}
            disabled={!dirty || !isValid}
            onClick={() => handleSubmit()}
          >
            <FormattedMessage
              id="common.actions.update-with-name"
              values={{ name: formatMessage({ id: 'models.partner' }) }}
            />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            icon={modelIcons.partner}
            title={formatMessage({ id: 'routes.partner-edit' }, { name: partner.name })}
            actionButtons={headerActionButtons}
          >
            <CardedContent>
              <PartnerForm />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export const PartnersEditPage = pageWithRoleAuthorization(
  () => {
    const params = useParams()
    const { data: partner, loading } = usePartner({ id: params.id! })
    if (!partner && loading) return null
    if (!partner) return <Error404 />

    return <EditPage partner={partner} />
  },
  ({ can }) => can('partner', 'update')
)
