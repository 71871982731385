import { FC } from 'react'
import { AppPage, InfinitScroller } from '@web-panel/shared'

import modelIcons from '@local/model-icons'
import { useIntl } from 'react-intl'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { useDonations } from '@web-panel/oc-api'
import IndexTable from './Tables/IndexTable'
import Filters, { useFilters, withFilters } from './Filters'

const DonationsIndexPage: FC = () => {
  const { formatMessage } = useIntl()
  const { debouncedFilters } = useFilters()
  const {
    data: donations = [],
    loading,
    loadMore,
  } = useDonations(
    { status: debouncedFilters.status },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )

  return (
    <AppPage
      loading={false}
      icon={modelIcons.donation}
      title={formatMessage({ id: 'routes.donations' })}
      Sidebar={Filters}
    >
      <InfinitScroller onLoadMore={loadMore}>
        <IndexTable data={donations} loading={loading} />
      </InfinitScroller>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(withFilters(DonationsIndexPage), ({ can }) =>
  can('donation', 'list')
)
