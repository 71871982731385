import { FC } from 'react'
import { TableCell, TableCellProps, Hidden } from '@mui/material'
import { TableSortLabel, TableSortLabelProps } from '../TableSortLabel'

export type HeaderCellProps = Pick<TableCellProps, 'align' | 'style'> & {
  sorting?: TableSortLabelProps
  hiddenMobile?: boolean
}

export const HeaderCell: FC<HeaderCellProps> = ({
  sorting,
  align,
  hiddenMobile,
  children,
  style,
}) => {
  const cell = sorting ? (
    <TableCell align={align} style={style}>
      <TableSortLabel {...sorting}>{children}</TableSortLabel>
    </TableCell>
  ) : (
    <TableCell style={style}>{children}</TableCell>
  )

  if (!hiddenMobile) return cell

  return <Hidden lgDown>{cell}</Hidden>
}

export default HeaderCell
