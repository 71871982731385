import { FC } from 'react'
import { GroupLabel } from '@web-panel/api'
import { Link } from '@web-panel/shared'
import { useGroupLabel } from '@web-panel/oc-api'

type LabelNameLinkProps = {
  groupLabel: GroupLabel
}

const LabelNameLink: FC<LabelNameLinkProps> = ({ groupLabel }) => {
  return (
    <Link
      to={`/sequence-nodes/${groupLabel.sourceShipmentSequence?.id}/${groupLabel.sourceShipmentSequence?.name}/${groupLabel.sourceShipmentSequence?.type}`}
      onClick={(e) => e.stopPropagation()}
    >
      {groupLabel.sourceShipmentSequence?.name}
    </Link>
  )
}

type LinkWithDataLoadProps = {
  groupLabelId: string
}
const LinkWithDataLoad: FC<LinkWithDataLoadProps> = ({ groupLabelId }) => {
  const { data: groupLabel } = useGroupLabel({ id: groupLabelId })
  if (!groupLabel) return null

  return <LabelNameLink groupLabel={groupLabel} />
}

type GroupLabelNameLinkProps = LabelNameLinkProps | LinkWithDataLoadProps

export const GroupLabelNameLink: FC<GroupLabelNameLinkProps> = (props) => {
  if ('groupLabel' in props) return <LabelNameLink groupLabel={props.groupLabel} />

  return <LinkWithDataLoad groupLabelId={props.groupLabelId} />
}

export default GroupLabelNameLink
