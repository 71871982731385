import { FC } from 'react'
import { ContractSettingType } from '@web-panel/api'
import { TextField, Typography } from '@web-panel/shared'
import { FormattedMessage } from 'react-intl'

type Values = {
  amount: number
  price: number
}

type Errors = {
  amount?: boolean
  price?: boolean
}

type ContractSettingInputProps = {
  type: ContractSettingType
  resourceName?: string
  values: Values
  errors: Errors
  currencyCode: string
  amountError?: boolean
  priceError?: boolean
  orderLabel?: boolean
  onUpdate: (updated: { amount: number; price: number }) => void
}
export const ContractSettingInput: FC<ContractSettingInputProps> = ({
  type,
  resourceName,
  values: { amount, price },
  errors: { amount: amountError, price: priceError },
  currencyCode,
  onUpdate,
  orderLabel,
}) => {
  return (
    <div className="flex flex-col md:flex-row gap-16 items-end">
      {type === ContractSettingType.ExceedingAmountFee ? (
        <>
          <TextField
            type="number"
            variant="standard"
            value={amount}
            onChange={(e) =>
              onUpdate({
                amount: e.target.value as unknown as number,
                price,
              })
            }
            error={amountError}
          />
          <Typography>
            <FormattedMessage id="common.labels.included-per-month" />,
          </Typography>
          <TextField
            type="number"
            variant="standard"
            value={price}
            onChange={(e) =>
              onUpdate({
                amount,
                price: e.target.value as unknown as number,
              })
            }
            error={priceError}
          />
          <Typography>
            {currencyCode}&nbsp;
            {resourceName && (
              <>
                &nbsp;
                <FormattedMessage
                  id="common.labels.per-extra"
                  values={{ resource: resourceName }}
                />
              </>
            )}
          </Typography>
        </>
      ) : (
        <>
          <TextField
            type="number"
            variant="standard"
            value={price}
            onChange={(e) =>
              onUpdate({
                amount,
                price: e.target.value as unknown as number,
              })
            }
            error={priceError}
          />
          <Typography>
            {currencyCode}&nbsp;
            <FormattedMessage
              id={orderLabel ? 'common.labels.per-order' : 'common.labels.per-item-per-order'}
            />
          </Typography>
        </>
      )}
    </div>
  )
}
