import { FC, useCallback } from 'react'

import {
  AppPage,
  Button,
  InfinitScroller,
  useModalRef,
  useDebouncedSearch,
} from '@web-panel/shared'
import { useIntl, FormattedMessage } from 'react-intl'
import { PageHeaderSearch } from '@local/components/PageHeaderSearch'
import modelIcons from '@local/model-icons'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useProductItems } from '@web-panel/oc-api'
import IndexTable from './Tables/IndexTable'
import LoadFromFileModal from './Modals/LoadFromFile'
import Filters, { withFilters, useFilters, useStatusLifeTimeFilters } from './Filters'
import { withSorting, useSorting } from './sorting'

const IndexPage: FC = () => {
  const { search, onChangeSearch, debouncedSearch } = useDebouncedSearch()
  const { formatMessage } = useIntl()
  const { filters } = useFilters()
  const statusLifetimeFilters = useStatusLifeTimeFilters(filters.statusLifetime)
  const { can } = useRoleAuthorization()

  const { field: sortBy, direction: sortOrder } = useSorting()
  const {
    data: productItems = [],
    loading,
    hasMore,
    loadMore,
    refetch,
  } = useProductItems(
    {
      search: debouncedSearch,
      status: filters.status,
      size: filters.size,
      ...statusLifetimeFilters,
      sortBy,
      sortOrder,
    },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )
  const loadFromFileModalRef = useModalRef()

  const FilterComponent: FC = useCallback(() => {
    return <Filters search={debouncedSearch} />
  }, [debouncedSearch])

  const onLoadMore = () => {
    if (!hasMore) return
    loadMore()
  }

  const headerActionButtons = [
    can(
      'productItem',
      'createBatch',
      <Button
        fullWidth
        key="create-batch"
        variant="contained"
        color="secondary"
        onClick={() => loadFromFileModalRef.current?.open()}
      >
        <FormattedMessage id="pages.product-items-index.actions.add-batch" />
      </Button>
    ),
  ]

  return (
    <AppPage
      loading={loading}
      icon={modelIcons.productItem}
      title={formatMessage({ id: 'routes.product-items' })}
      actionButtons={headerActionButtons}
      searchComponent={<PageHeaderSearch value={search} onChangeValue={onChangeSearch} />}
      Sidebar={FilterComponent}
    >
      <InfinitScroller onLoadMore={onLoadMore}>
        <IndexTable data={productItems} loading={loading} />
      </InfinitScroller>
      <LoadFromFileModal ref={loadFromFileModalRef} onSubmit={refetch} />
    </AppPage>
  )
}

export default pageWithRoleAuthorization(withSorting(withFilters(IndexPage)), ({ can }) =>
  can('productItem', 'list')
)
