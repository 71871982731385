import { FC } from 'react'
import { Order } from '@web-panel/api'
import { FormattedCurrency, Paper, TableGrid, useFormatDate } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import OrderStatusChip from '@local/components/OrderStatusChip'
import { PartnerLink } from '@local/components/PartnerLink'
import { OutletLink } from '@local/components/OutletLink'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'

type IndexTableProps = {
  data: Order[]
  loading: boolean
}

const IndexTable: FC<IndexTableProps> = ({ data, loading }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const { can } = useRoleAuthorization()

  return (
    <Paper className="overflow-hidden">
      <TableGrid
        loading={loading}
        collection={data}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        onClickRow={({ id }) => navigate(id)}
        rowClickable={can('order', 'preview')}
        fields={[
          {
            title: formatMessage({ id: 'models.order.id' }),
            render: ({ orderNumber }) => orderNumber,
          },
          {
            title: formatMessage({ id: 'models.order.status' }),
            render: ({ status }) => <OrderStatusChip status={status} />,
          },
          {
            title: formatMessage({ id: 'models.order.requested-delivery-date' }),
            render: ({ expectedDeliveryDateFrom }) =>
              expectedDeliveryDateFrom && formatDate('date', expectedDeliveryDateFrom),
          },
          {
            title: formatMessage({ id: 'models.order.partner' }),
            render: ({ partnerId }) => <PartnerLink partnerId={partnerId} />,
            isRenderable: can('partner', 'preview'),
          },
          {
            title: formatMessage({ id: 'models.order.outlet' }),
            render: ({ outletId }) => <OutletLink outletId={outletId} />,
            isRenderable: can('outlet', 'preview'),
          },
          {
            title: formatMessage({ id: 'models.order.cups-amount' }),
            render: ({ orderRefundables }) =>
              orderRefundables.reduce((sum, { itemsAmount }) => sum + itemsAmount, 0),
          },
          {
            title: formatMessage({ id: 'models.order.total-cost' }),
            render: ({ totalCost, currencyCode }) => (
              <FormattedCurrency value={totalCost} currency={currencyCode} />
            ),
          },
          {
            title: formatMessage({ id: 'models.order.invoice-pdf' }),
            render: ({ invoicePdf }) =>
              invoicePdf && (
                <a
                  target="_blank"
                  href={invoicePdf}
                  rel="noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  <FormattedMessage id="common.actions.download" />
                </a>
              ),
          },
          {
            title: formatMessage({ id: 'models.order.delivery-pdf' }),
            render: ({ deliveryPdf }) =>
              deliveryPdf && (
                <a
                  target="_blank"
                  href={deliveryPdf}
                  rel="noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  <FormattedMessage id="common.actions.download" />
                </a>
              ),
          },
        ]}
      />
    </Paper>
  )
}

export default IndexTable
