import { FC } from 'react'

import { FormattedCurrency, InfinitScroller, TableGrid, useFormatDate } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { PaymentOutStatusChip } from '@local/components/PaymentOutStatusChip'

import { useConsumerPaymentOuts } from '@web-panel/oc-api'

type ProductItemsTabProps = {
  userId: string
}

const ProductItemsTab: FC<ProductItemsTabProps> = ({ userId }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const {
    data: paymentOuts = [],
    loading,
    loadMore,
  } = useConsumerPaymentOuts(
    { consumerId: userId },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )

  return (
    <InfinitScroller onLoadMore={loadMore}>
      <TableGrid
        loading={loading}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        collection={paymentOuts}
        fields={[
          {
            title: formatMessage({ id: 'models.payment-out.id' }),
            render: ({ externalPaymentOutId }) => externalPaymentOutId,
          },
          {
            title: formatMessage({ id: 'models.payment-out.amount' }),
            render: ({ amount, currency }) => (
              <FormattedCurrency value={amount} currency={currency.code} />
            ),
          },
          {
            title: formatMessage({ id: 'models.payment-out.payment-out-status' }),
            render: ({ paymentOutStatus }) => <PaymentOutStatusChip status={paymentOutStatus} />,
          },
          {
            title: formatMessage({ id: 'models.payment-out.payment-method-type' }),
            render: ({ paymentMethodType }) => (
              <FormattedMessage id={`payment-types.${paymentMethodType}`} />
            ),
          },
          {
            title: formatMessage({ id: 'models.payment-out.receipt' }),
            render: ({ receipt }) => (
              <a
                target="_blank"
                href={receipt}
                onClick={(e) => e.stopPropagation()}
                rel="noreferrer"
              >
                <FormattedMessage id="common.actions.download" />
              </a>
            ),
          },
          {
            title: formatMessage({ id: 'models.payment-out.create-date' }),
            render: ({ createDate }) => formatDate('date', createDate),
          },
        ]}
        onClickRow={(paymentOut) => navigate(`/payout-requests/${paymentOut.id}`)}
      />
    </InfinitScroller>
  )
}

export default ProductItemsTab
