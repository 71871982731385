import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { compareAsc, addDays, isSameDay } from 'date-fns'
import {
  SerialValueMetricsData,
  SerialCountMetricsData,
  AnalyticAggregationType,
  QueryHook,
} from '@web-panel/api'
import {
  CO2SavedSeriesDocument,
  CO2SavedSeriesDocumentInput,
  CO2SavedSeriesDocumentResponse,
  TotalItemsDocument,
  TotalItemsDocumentInput,
  TotalItemsDocumentResponse,
  TotalConsumersGainedDocument,
  TotalConsumersGainedDocumentInput,
  TotalConsumersGainedDocumentResponse,
  TotalCO2SavedDocument,
  TotalCO2SavedDocumentInput,
  TotalCO2SavedDocumentResponse,
  ItemSeriesDocumentInput,
  ItemSeriesDocumentResponse,
  ItemSeriesDocument,
  AnalyticsTotalItemsSummaryDocumentResponse,
  AnalyticsTotalItemsSummaryDocument,
  AnalyticsTotalItemsNotReturnedSummaryDocumentResponse,
  AnalyticsTotalItemsNotReturnedSummaryDocument,
  AnalyticsTotalOrdersDocumentResponse,
  AnalyticsTotalOrdersDocument,
} from '../docs'

export const useCO2SavedSeries: QueryHook<
  CO2SavedSeriesDocumentInput['input'],
  CO2SavedSeriesDocumentResponse['co2SavedSeries']['metrics']
> = (input, options) => {
  const { data, loading, refetch } = useQuery<CO2SavedSeriesDocumentResponse>(
    CO2SavedSeriesDocument,
    {
      ...options,
      variables: { input },
    }
  )

  return {
    data: data?.co2SavedSeries.metrics,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

function getStartDate(data: (SerialValueMetricsData | SerialCountMetricsData)[]): Date {
  const minDate = data.reduce<Date>((acc, { metrics }) => {
    const d = metrics[0] && new Date(metrics[0].date)

    return d && compareAsc(d, acc) < 0 ? d : acc
  }, new Date())

  return minDate
}

type NormalizedAnalyticsValueMetric = {
  value: number
  date: Date
}

type NormalizedSerialValueMetricsData = {
  holderId: string
  metrics: NormalizedAnalyticsValueMetric[]
}

export const useNormalizedCO2SavedSeries: QueryHook<
  CO2SavedSeriesDocumentInput['input'],
  NormalizedSerialValueMetricsData[]
> = (input, options) => {
  const { data, loading, refetch } = useCO2SavedSeries(input, options)

  const series: NormalizedSerialValueMetricsData[] = useMemo(() => {
    if (!data) return []

    const { aggregationType } = input
    const startDate = getStartDate(data)
    const endDate = input.endDate ? new Date(input.endDate) : new Date()

    return data.map(({ holderId, metrics }) => {
      const normalizedMetrics: NormalizedAnalyticsValueMetric[] = []
      let date = startDate
      let currentMetricId = 0
      let previousValue = 0

      while (compareAsc(date, endDate) < 0) {
        const currentMetric = metrics[currentMetricId]
        if (currentMetric && isSameDay(new Date(currentMetric.date), date)) {
          previousValue =
            aggregationType === AnalyticAggregationType.Cumulative ? currentMetric.value : 0
          currentMetricId += 1
          normalizedMetrics.push({
            date,
            value: currentMetric.value,
          })
        } else {
          normalizedMetrics.push({
            date,
            value: previousValue,
          })
        }
        date = addDays(date, 1)
      }

      return {
        holderId,
        metrics: normalizedMetrics,
      }
    })
  }, [input, data])

  return { data: series, loading, refetch }
}

export const useItemSeries: QueryHook<
  ItemSeriesDocumentInput,
  ItemSeriesDocumentResponse['itemsSeries']['metrics']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<ItemSeriesDocumentResponse>(ItemSeriesDocument, {
    ...options,
    variables,
  })

  return {
    data: data?.itemsSeries.metrics,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

type NormalizedAnalyticsCountMetric = {
  count: number
  date: Date
}

type NormalizedSerialCountMetricsData = {
  holderId: string
  metrics: NormalizedAnalyticsCountMetric[]
}

export const useNormalizedItemSeries: QueryHook<
  ItemSeriesDocumentInput,
  NormalizedSerialCountMetricsData[]
> = (variables, options) => {
  const { data, loading, refetch } = useItemSeries(variables, options)
  const { input } = variables

  const series: NormalizedSerialCountMetricsData[] = useMemo(() => {
    if (!data) return []

    const { aggregationType } = input
    const startDate = getStartDate(data)
    const endDate = input.endDate ? new Date(input.endDate) : new Date()

    return data.map(({ holderId, metrics }) => {
      const normalizedMetrics: NormalizedAnalyticsCountMetric[] = []
      let date = startDate
      let currentMetricId = 0
      let previousValue = 0

      while (compareAsc(date, endDate) < 0) {
        const currentMetric = metrics[currentMetricId]
        if (currentMetric && isSameDay(new Date(currentMetric.date), date)) {
          previousValue =
            aggregationType === AnalyticAggregationType.Cumulative ? currentMetric.count : 0
          currentMetricId += 1
          normalizedMetrics.push({
            date,
            count: currentMetric.count,
          })
        } else {
          normalizedMetrics.push({
            date,
            count: previousValue,
          })
        }
        date = addDays(date, 1)
      }

      return {
        holderId,
        metrics: normalizedMetrics,
      }
    })
  }, [input, data])

  return { data: series, loading, refetch }
}

export const useTotalItems: QueryHook<
  TotalItemsDocumentInput,
  TotalItemsDocumentResponse['totalItems']['counters']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<TotalItemsDocumentResponse>(TotalItemsDocument, {
    ...options,
    variables,
  })

  return {
    data: data?.totalItems.counters,
    loading,
    refetch: async () => {
      await refetch
    },
  }
}

export const useTotalConsumersGained: QueryHook<
  TotalConsumersGainedDocumentInput['input'],
  TotalConsumersGainedDocumentResponse['totalConsumersGained']['counters']
> = (input, options) => {
  const { data, loading, refetch } = useQuery<TotalConsumersGainedDocumentResponse>(
    TotalConsumersGainedDocument,
    { ...options, variables: { input } }
  )

  return {
    data: data?.totalConsumersGained.counters,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useTotalCO2Saved: QueryHook<
  TotalCO2SavedDocumentInput['input'],
  TotalCO2SavedDocumentResponse['totalCO2Saved']['metrics']
> = (input, options) => {
  const { data, loading, refetch } = useQuery<TotalCO2SavedDocumentResponse>(
    TotalCO2SavedDocument,
    { ...options, variables: { input } }
  )

  return {
    data: data?.totalCO2Saved.metrics,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useAnalyticsTotalOrders: QueryHook<
  { partnerId: string; outletIds: string[]; monthYearDate?: string },
  AnalyticsTotalOrdersDocumentResponse['analyticsTotalOrders']
> = ({ outletIds, partnerId, monthYearDate }, options) => {
  const outlets = outletIds.map((id) => `outletId=${id}`).join('&')
  const { data, loading, refetch } = useQuery<AnalyticsTotalOrdersDocumentResponse>(
    AnalyticsTotalOrdersDocument,
    {
      ...options,
      variables: {
        partnerId,
        outlets,
        filters: {
          monthYearDate,
        },
      },
    }
  )

  return {
    data: data?.analyticsTotalOrders,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useAnalyticsTotalItemsSummary: QueryHook<
  { partnerId: string; outletIds: string[]; monthYearDate?: string },
  AnalyticsTotalItemsSummaryDocumentResponse['analyticsTotalItemsSummary']
> = ({ outletIds, partnerId, monthYearDate }, options) => {
  const outlets = outletIds.map((id) => `outletId=${id}`).join('&')
  const { data, loading, refetch } = useQuery<AnalyticsTotalItemsSummaryDocumentResponse>(
    AnalyticsTotalItemsSummaryDocument,
    {
      ...options,
      variables: {
        partnerId,
        outlets,
        filters: {
          monthYearDate,
        },
      },
    }
  )

  return {
    data: data?.analyticsTotalItemsSummary,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useAnalyticsTotalItemsNotReturnedSummary: QueryHook<
  { partnerId: string; outletIds: string[] },
  AnalyticsTotalItemsNotReturnedSummaryDocumentResponse['analyticsTotalItemsNotReturnedSummary']
> = (variables, options) => {
  const outlets = variables.outletIds.map((id) => `outletId=${id}`).join('&')
  const { data, loading, refetch } =
    useQuery<AnalyticsTotalItemsNotReturnedSummaryDocumentResponse>(
      AnalyticsTotalItemsNotReturnedSummaryDocument,
      {
        ...options,
        variables: {
          partnerId: variables.partnerId,
          outlets,
        },
      }
    )

  return {
    data: data?.analyticsTotalItemsNotReturnedSummary,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}
