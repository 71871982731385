import { useMutation } from '@apollo/client'
import { MutationHook } from '@web-panel/api'
import {
  UpdateGeoCityDocumentInput,
  UpdateGeoCityDocumentResponse,
  UpdateGeoCityDocument,
  CreateGeoCityDocumentInput,
  CreateGeoCityDocumentResponse,
  CreateGeoCityDocument,
  UpdateGeoCountryDocumentInput,
  UpdateGeoCountryDocumentResponse,
  UpdateGeoCountryDocument,
  UpdateGeoCityIconDocument,
  UpdateGeoCityIconDocumentInput,
  UpdateGeoCityIconDocumentResponse,
  DeleteGeoCityIconDocument,
  DeleteGeoCityIconDocumentInput,
  DeleteGeoCityIconDocumentResponse,
} from '../docs'

export const useUpdateGeoCity: MutationHook<
  UpdateGeoCityDocumentInput,
  UpdateGeoCityDocumentResponse['updateCity']
> = () => {
  const [execute, { loading }] = useMutation<
    UpdateGeoCityDocumentResponse,
    UpdateGeoCityDocumentInput
  >(UpdateGeoCityDocument)

  const request = async ({ code, input }: UpdateGeoCityDocumentInput) => {
    const { data } = await execute({ variables: { code, input } })
    if (!data) throw new Error('Could not update GeoCity')

    return data?.updateCity
  }

  return {
    request,
    loading,
  }
}

export const useCreateGeoCity: MutationHook<
  CreateGeoCityDocumentInput,
  CreateGeoCityDocumentResponse['createCity']
> = () => {
  const [execute, { loading }] = useMutation<
    CreateGeoCityDocumentResponse,
    CreateGeoCityDocumentInput
  >(CreateGeoCityDocument)

  const request = async ({ input }: CreateGeoCityDocumentInput) => {
    const { data } = await execute({ variables: { input } })
    if (!data) throw new Error('Could not create GeoCity')

    return data?.createCity
  }

  return {
    request,
    loading,
  }
}

export const useUpdateGeoCountry: MutationHook<
  UpdateGeoCountryDocumentInput,
  UpdateGeoCountryDocumentResponse['updateCountry']
> = () => {
  const [execute, { loading }] = useMutation<
    UpdateGeoCountryDocumentResponse,
    UpdateGeoCountryDocumentInput
  >(UpdateGeoCountryDocument)

  const request = async ({ code, input }: UpdateGeoCountryDocumentInput) => {
    const { data } = await execute({ variables: { code, input } })
    if (!data) throw new Error('Could not update GeoCity')

    return data?.updateCountry
  }

  return {
    request,
    loading,
  }
}

const updateCityIconBodySerializer: UpdateGeoCityIconDocumentInput['bodySerializer'] = (
  { image },
  headers
) => {
  const formData = new FormData()
  formData.append('image', image, image.name)

  return {
    body: formData,
    headers,
  }
}
type UseUpdateGeoCityIconInput = Omit<UpdateGeoCityIconDocumentInput, 'bodySerializer'>
export const useUpdateGeoCityIcon: MutationHook<
  UseUpdateGeoCityIconInput,
  UpdateGeoCityIconDocumentResponse['updateGeoCityIcon']
> = () => {
  const [execute, { loading }] = useMutation<
    UpdateGeoCityIconDocumentResponse,
    UpdateGeoCityIconDocumentInput
  >(UpdateGeoCityIconDocument)

  const request = async ({ code, input }: UseUpdateGeoCityIconInput) => {
    const { data } = await execute({
      variables: { code, input, bodySerializer: updateCityIconBodySerializer },
    })
    if (!data) throw new Error('Could not update GeoCity')

    return data.updateGeoCityIcon
  }

  return {
    request,
    loading,
  }
}

export const useDeleteGeoCityIcon: MutationHook<
  DeleteGeoCityIconDocumentInput,
  DeleteGeoCityIconDocumentResponse['deleteGeoCityIcon']
> = () => {
  const [execute, { loading }] = useMutation<
    DeleteGeoCityIconDocumentResponse,
    DeleteGeoCityIconDocumentInput
  >(DeleteGeoCityIconDocument)

  const request = async ({ code }: DeleteGeoCityIconDocumentInput) => {
    const { data } = await execute({ variables: { code } })
    if (!data) throw new Error('Could not delete GeoCity')

    return data.deleteGeoCityIcon
  }

  return {
    request,
    loading,
  }
}
