import { EditableLocation } from '@local/../../api/src'
import { Button, ButtonProps, useModalRef } from '@web-panel/shared'
import { RoleAuthorized } from '@local/components/RoleAuthorized'
import { VFC } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import AssignCustomModalConfirmation from '../Modals/AssignCustomModalConfirmation'

type AssignCustomLocationButtonProps = {
  className?: string
  variant?: ButtonProps['variant']
  dispenserId: string
  outletLocation: EditableLocation | null
}

export const AssignCustomLocationButton: VFC<AssignCustomLocationButtonProps> = ({
  className,
  variant = 'outlined',
  dispenserId,
  outletLocation,
}) => {
  const navigate = useNavigate()
  const modalRef = useModalRef()

  const path = `/dispensers/${dispenserId}/custom-location/create`

  const onClick = () => {
    if (!outletLocation) {
      navigate(path)
      return
    }
    modalRef.current?.open()
  }

  return (
    <RoleAuthorized resource="box" action="assignToLocation">
      <Button className={className} variant={variant} onClick={onClick}>
        <FormattedMessage id="common.actions.assign-custom" />
      </Button>
      <AssignCustomModalConfirmation
        ref={modalRef}
        onSubmit={(prefill) => {
          navigate(path, { state: { location: prefill ? outletLocation : null } })
        }}
      />
    </RoleAuthorized>
  )
}
