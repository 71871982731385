import { FC } from 'react'
import { Outlet } from '@web-panel/api'
import {
  Button,
  FormattedCurrency,
  InfinitScroller,
  TableGrid,
  useFormatDate,
} from '@web-panel/shared'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { RoleAuthorized, useRoleAuthorization } from '@local/components/RoleAuthorized'

import { useOneTimeContractSettings, useOneTimeContractPackageTemplates } from '@web-panel/oc-api'

type OneTimeContractTabProps = {
  outlet: Outlet
}

export const OneTimeContractTab: FC<OneTimeContractTabProps> = ({ outlet }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const navigate = useNavigate()
  const { can } = useRoleAuthorization()
  const { data: contractSettings = [], loadMore } = useOneTimeContractSettings(
    {
      outletId: outlet.id,
      partnerId: outlet.partnerId,
    },
    { fetchPolicy: 'cache-and-network' }
  )
  const { data: templates = [] } = useOneTimeContractPackageTemplates({
    countryCode: outlet.location.country.code,
  })

  return (
    <InfinitScroller onLoadMore={loadMore}>
      <TableGrid
        collection={contractSettings}
        fields={[
          {
            title: formatMessage({ id: 'models.one-time-contract-setting.name' }),
            render: ({ name }) => name,
          },
          {
            title: formatMessage({ id: 'models.one-time-contract-setting.amount' }),
            render: ({ amount }) => <FormattedNumber value={amount} />,
          },
          {
            title: formatMessage({ id: 'models.one-time-contract-setting.price' }),
            render: ({ price, currency }) => (
              <FormattedCurrency value={price} currency={currency.code} />
            ),
          },
          {
            title: formatMessage({ id: 'models.one-time-contract-setting.total' }),
            render: ({ price, amount, currency }) => (
              <FormattedCurrency value={price * amount} currency={currency.code} />
            ),
          },
          {
            title: formatMessage({ id: 'models.one-time-contract-setting.validation-date' }),
            render: ({ validationDate }) => formatDate('date', validationDate),
          },
        ]}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        addItemButton={
          <RoleAuthorized resource="oneTimeContractSetting" action="create">
            <Button
              disabled={templates.length === 0}
              variant="contained"
              color="primary"
              onClick={() => navigate('create-one-time-contract')}
            >
              <FormattedMessage id="common.actions.create" />
            </Button>
          </RoleAuthorized>
        }
        onClickRow={({ id }) => navigate(`one-time-contract/${id}`)}
        rowClickable={can('oneTimeContractSetting', 'preview')}
      />
    </InfinitScroller>
  )
}
