import { FC } from 'react'
import { SelectableList, buildFilterContext } from '@web-panel/shared'
import { UserStatus, UserRole } from '@web-panel/api'
import { useIntl } from 'react-intl'

export const availableRoles: UserRole[] = [
  UserRole.Administrator,
  UserRole.OperationsManager,
  UserRole.Driver,
]

type FilterFields = {
  status: UserStatus | undefined
  role: UserRole | undefined
}
export const { useFilters, withFilters } = buildFilterContext<FilterFields>({
  status: UserStatus.Active,
  role: UserRole.Administrator,
})

const Filters: FC = () => {
  const { filters, setFilter } = useFilters()
  const { formatMessage } = useIntl()

  return (
    <div className="flex flex-col">
      <SelectableList
        label={formatMessage({ id: 'models.user.status' })}
        value={filters.status}
        options={Object.values(UserStatus)}
        optionTitle={(option) => formatMessage({ id: `user-statuses.${option}` })}
        onSelect={(status) => setFilter('status', status)}
      />
      <SelectableList
        className="mt-16"
        label={formatMessage({ id: 'models.user.role' })}
        value={filters.role}
        options={availableRoles}
        optionTitle={(option) => formatMessage({ id: `user-roles.${option}` })}
        onSelect={(role) => setFilter('role', role)}
      />
    </div>
  )
}

export default Filters
