import { useMutation } from '@apollo/client'
import { MutationHook } from '@web-panel/api'
import {
  CreateLocationDocument,
  CreateLocationDocumentInput,
  CreateLocationDocumentResponse,
  UpdateLocationDocument,
  UpdateLocationDocumentInput,
  UpdateLocationDocumentResponse,
  DeleteLocationDocument,
  DeleteLocationDocumentInput,
  DeleteLocationDocumentResponse,
} from '../docs'

export const useCreateLocation: MutationHook<
  CreateLocationDocumentInput,
  CreateLocationDocumentResponse['createLocation']
> = (options) => {
  const [execute, { loading }] = useMutation<CreateLocationDocumentResponse>(
    CreateLocationDocument,
    options
  )

  const request = async (variables: CreateLocationDocumentInput) => {
    const { data } = await execute({ variables })
    if (!data) throw new Error('Location could not be created')

    return data.createLocation
  }

  return { request, loading }
}

export const useUpdateLocation: MutationHook<
  UpdateLocationDocumentInput,
  UpdateLocationDocumentResponse['updateLocation']
> = (options) => {
  const [execute, { loading }] = useMutation<UpdateLocationDocumentResponse>(
    UpdateLocationDocument,
    options
  )

  const request = async (variables: UpdateLocationDocumentInput) => {
    const { data } = await execute({ variables })
    if (!data) throw new Error('Location could not be updated')

    return data.updateLocation
  }

  return { request, loading }
}

export const useDeleteLocation: MutationHook<
  DeleteLocationDocumentInput,
  DeleteLocationDocumentResponse
> = (options) => {
  const [execute, { loading }] = useMutation<DeleteLocationDocumentResponse>(
    DeleteLocationDocument,
    options
  )

  const request = async (variables: DeleteLocationDocumentInput) => {
    await execute({ variables })
  }

  return { request, loading }
}
