import { FC } from 'react'
import { Batch } from '@web-panel/api'
import { Link } from '@web-panel/shared'
import { FormattedMessage } from 'react-intl'

import { useBatch } from '@web-panel/oc-api'

type BatchLinkProps = {
  batch: Batch
}
const BatchLink: FC<BatchLinkProps> = ({ batch }) => {
  return (
    <Link to={`/product-item-batches/${batch.id}`} onClick={(e) => e.stopPropagation()}>
      <FormattedMessage id="models.batch" />
      &nbsp; #{batch.batchNumber}
    </Link>
  )
}

type LinkWithDataLoadProps = {
  batchId: string
}
const LinkWithDataLoad: FC<LinkWithDataLoadProps> = ({ batchId }) => {
  const { data: batch } = useBatch({ id: batchId })
  if (!batch) return null

  return <BatchLink batch={batch} />
}

type ProductItemBatchLinkProps = BatchLinkProps | LinkWithDataLoadProps

export const ProductItemBatchLink: FC<ProductItemBatchLinkProps> = (props) => {
  if ('batch' in props) return <BatchLink batch={props.batch} />

  return <LinkWithDataLoad batchId={props.batchId} />
}

export default ProductItemBatchLink
