import { FC } from 'react'

import { AppPage, CardedContent, Button, useNotifications } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { Formik } from 'formik'
import modelIcons from '@local/model-icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { useCreateOutlet } from '@web-panel/oc-api'
import CreateOutletForm, {
  FormFields,
  defaultValues,
  validationSchema,
} from './Forms/CreateOutletForm'

const CreatePage: FC = () => {
  const { formatMessage } = useIntl()
  const { pushNotification } = useNotifications()
  const navigate = useNavigate()
  const { request: createOutlet } = useCreateOutlet()
  const { state } = useLocation()

  const onSubmit = async (input: FormFields) => {
    if (!input.partner || !input.city || !input.country || !input.lat || !input.lng) return
    try {
      await createOutlet({
        input: {
          partnerId: input.partner.id,
          type: input.type,
          firstName: input.firstName,
          lastName: input.lastName,
          phone: input.phone,
          email: input.email,
          operationsPhone: null,
          operationsEmail: null,
          location: {
            cityCode: input.city.code,
            name: input.name,
            street: input.street,
            houseNumber: input.houseNumber,
            postalCode: input.postalCode,
            latitude: input.lat,
            longitude: input.lng,
            workingHours: input.workingHours,
          },
          legalAddress: input.legalAddress,
          batchDefaultSize: input.batchDefaultSize,
          cycleType: input.cycleType,
        },
      })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-created' })
      )
      navigate(-1)
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const initialValues =
    state && state.partner ? { ...defaultValues, partner: state.partner } : defaultValues

  return (
    <Formik<FormFields>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formProps) => {
        const headerActionButtons = [
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            loading={formProps.isSubmitting}
            disabled={!formProps.dirty || !formProps.isValid}
            onClick={() => formProps.handleSubmit()}
          >
            <FormattedMessage id="common.actions.create" />
          </Button>,
        ]
        return (
          <AppPage
            backButton
            icon={modelIcons.outlet}
            title={formatMessage({ id: 'routes.outlets-create' })}
            actionButtons={headerActionButtons}
          >
            <CardedContent>
              <CreateOutletForm {...formProps} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export default pageWithRoleAuthorization(CreatePage, ({ can }) => can('outlet', 'create'))
