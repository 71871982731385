import { FC, useState } from 'react'
import { Outlet, AnalyticTotalCountType } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { OutletsDropdown } from '@local/pages/Partners/Components/OutletsDropdown'
import { usePartnerOutlets } from '@web-panel/oc-api'
import { TotalItemsWidget } from './Components/TotalItemsWidget'
import { TotalConsumersWidget } from './Components/TotalConsumersWidget'
import { CO2Widget } from './Components/CO2Widget'
import CupsWidget from './Components/CupsWidget'

type DashboardTabProps = {
  partnerId: string
}

export const OriginalDashboard: FC<DashboardTabProps> = ({ partnerId }) => {
  const { formatMessage } = useIntl()
  const [selectedOutlets, setSelectedOutlets] = useState<Outlet[]>([])
  const { data: outlets = [], loading } = usePartnerOutlets({ id: partnerId })

  if (loading) return null

  const widgetOutlets = selectedOutlets.length > 0 ? selectedOutlets : outlets

  return (
    <div className="w-full px-16 md:px-0">
      <div className="mb-16 flex justify-end">
        <OutletsDropdown
          outlets={outlets}
          checked={selectedOutlets}
          onChange={setSelectedOutlets}
        />
      </div>
      <div className="flex flex-col">
        <div className="grid md:grid-cols-4 gap-16">
          <TotalConsumersWidget partnerId={partnerId} outlets={widgetOutlets} />
          <TotalItemsWidget
            color="red"
            type={AnalyticTotalCountType.Lost}
            partnerId={partnerId}
            outlets={widgetOutlets}
            title={formatMessage({
              id: 'pages.partner-preview.tabs.dashboard.cups-lost-widget.title',
            })}
          />
          <TotalItemsWidget
            color="orange"
            type={AnalyticTotalCountType.Returned}
            partnerId={partnerId}
            outlets={widgetOutlets}
            title={formatMessage({
              id: 'pages.partner-preview.tabs.dashboard.cups-returned-widget.title',
            })}
          />
          <TotalItemsWidget
            color="green"
            type={AnalyticTotalCountType.Scanned}
            partnerId={partnerId}
            outlets={widgetOutlets}
            title={formatMessage({
              id: 'pages.partner-preview.tabs.dashboard.cups-scanned-widget.title',
            })}
          />
        </div>
        <div className="grid md:grid-cols-2 gap-16 mt-16">
          <CO2Widget partnerId={partnerId} outlets={widgetOutlets} />
          <CupsWidget partnerId={partnerId} outlets={widgetOutlets} />
        </div>
      </div>
    </div>
  )
}
