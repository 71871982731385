import { VFC } from 'react'
import {
  AppPage,
  CardedContent,
  Button,
  DefaultErrorPage,
  useNotifications,
} from '@web-panel/shared'
import { DispenserLocationType, DispenserOperationType, DispenserStandType } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'
import { Formik } from 'formik'
import modelIcons from '@local/model-icons'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispenser, useUpdateDispenser } from '@web-panel/oc-api'
import {
  EditDispenserForm,
  EditDispenserFormFields,
  editDispenserValidationSchema,
} from './Forms/EditForm'

const DispenserEditPage: VFC = () => {
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()
  const { formatMessage } = useIntl()
  const params = useParams()
  const { data: dispenser, loading } = useDispenser({ id: params.id ?? '' })
  const { request: updateDispenser, loading: loadingUpdateDispenser } = useUpdateDispenser()

  if (loading) return null
  if (!dispenser)
    return (
      <DefaultErrorPage
        title={formatMessage({ id: 'errors.404.title' })}
        message={formatMessage({ id: 'errors.404.message' })}
      />
    )

  const onSubmit = async (values: EditDispenserFormFields) => {
    try {
      await updateDispenser({
        id: dispenser.id,
        input: {
          coreId: values.coreId,
          imei: values.imei,
          capacity: values.capacity,
          status: values.status,
          operationType: values.operationType,
          locationType: values.locationType,
          standType: values.standType,
          outletId: dispenser.outletId,
          locationId: dispenser.customLocation?.id ?? null,
        },
      })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
      navigate(-1)
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const initialValues: EditDispenserFormFields = {
    coreId: dispenser.coreId,
    imei: dispenser.imei ?? '',
    capacity: dispenser.capacity,
    status: dispenser.status,
    operationType: dispenser.operationType ?? DispenserOperationType.Manual,
    locationType: dispenser.locationType ?? DispenserLocationType.Indoor,
    standType: dispenser.standType ?? DispenserStandType.NoWheels,
  }

  return (
    <Formik<EditDispenserFormFields>
      initialValues={initialValues}
      validationSchema={editDispenserValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, dirty, handleSubmit }) => {
        const headerActionButtons = [
          <Button
            fullWidth
            loading={loadingUpdateDispenser}
            key="save"
            variant="contained"
            color="secondary"
            disabled={!isValid}
            onClick={() => handleSubmit()}
          >
            <FormattedMessage id="common.actions.update" />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            icon={modelIcons.dispenser}
            title={formatMessage({ id: 'routes.dispensers-edit' }, { name: dispenser.coreId })}
            actionButtons={headerActionButtons}
          >
            <CardedContent>
              <EditDispenserForm />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export default pageWithRoleAuthorization(DispenserEditPage, ({ can }) => can('dispenser', 'update'))
