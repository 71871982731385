import { FC } from 'react'
import {
  Button,
  ModalBody,
  buildModal,
  ModalBodyProps,
  useNotifications,
  TextField,
  FormLabel,
} from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useCreateGroupLabel } from '@web-panel/oc-api'
import { Formik } from 'formik'
import * as Yup from 'yup'

type CreateGroupLabelModalProps = ModalBodyProps & {
  onCreated: () => void
  shipmentSequenceId: string
}

export type CreateGroupLabelFormFields = {
  numberOfCycles: number | undefined
}

export const createGroupLabelValidationSchema = Yup.object().shape({
  numberOfCycles: Yup.number()
    .typeError('Quantity must be a number')
    .positive('Quantity should be greater than 1')
    .required('Please enter a number between 1 and 20')
    .max(20, 'Please enter a number between 1 and 20'),
})

const CreateGroupLabelModal: FC<CreateGroupLabelModalProps> = ({
  onCreated,
  onClose,
  shipmentSequenceId,
}) => {
  const { formatMessage } = useIntl()
  const { pushNotification } = useNotifications()
  const { request: createGroupLabel } = useCreateGroupLabel()

  const onCreate = async (fields: CreateGroupLabelFormFields) => {
    try {
      await createGroupLabel({
        input: {
          shipmentSequenceId,
          numberOfCycles: fields.numberOfCycles,
        },
      })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-created' })
      )
      onCreated()
      onClose()
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <Formik<CreateGroupLabelFormFields>
      enableReinitialize
      key="creategrouplabel"
      initialValues={{ numberOfCycles: undefined }}
      validationSchema={createGroupLabelValidationSchema}
      onSubmit={onClose}
    >
      {({ handleChange, errors, values, dirty }) => {
        return (
          <ModalBody
            title="Create Group Labels"
            actions={[
              <Button key="cancel" onClick={onClose}>
                <FormattedMessage id="common.actions.cancel" />
              </Button>,
              <Button
                key="confirm"
                disabled={
                  !!errors.numberOfCycles ||
                  values.numberOfCycles === undefined ||
                  values.numberOfCycles === null
                }
                onClick={() => dirty && onCreate(values)}
              >
                <FormattedMessage id="common.actions.confirm" />
              </Button>,
            ]}
          >
            <form className="flex flex-col justify-center w-full">
              <div className="flex flex-col p-16 sm:p-24 max-w-2xl">
                <TextField
                  fullWidth
                  className="mb-16 mt-16"
                  value={values.numberOfCycles}
                  onChange={handleChange('numberOfCycles')}
                  label={formatMessage({
                    id: 'models.shipment.sequences.group-label.number-of-cycles',
                  })}
                  error={!!errors.numberOfCycles}
                />

                <FormLabel error color="error">
                  {errors.numberOfCycles}
                </FormLabel>
              </div>
            </form>
          </ModalBody>
        )
      }}
    </Formik>
  )
}

export default buildModal(CreateGroupLabelModal, { fullWidth: true })
