import { FC } from 'react'
import { ProductItemActivityHolderType } from '@web-panel/api'
import { OutletLink } from '@local/components/OutletLink'
import { UserLink } from '@local/components/UserLink'
import { BoxLink } from '@local/components/BoxLink'
import HolderType from './HolderType'

type ProductItemActivityHolderLinkProps = {
  holderType: ProductItemActivityHolderType
  id: string | null
}

const ProductItemActivityHolderLink: FC<ProductItemActivityHolderLinkProps> = ({
  holderType,
  id,
}) => {
  if (holderType === ProductItemActivityHolderType.PartnerOutlet && id)
    return <OutletLink outletId={id} />
  if (holderType === ProductItemActivityHolderType.Consumer && id) return <UserLink userId={id} />
  if (holderType === ProductItemActivityHolderType.Box && id) return <BoxLink boxId={id} />

  return <HolderType holderType={holderType} />
}

export default ProductItemActivityHolderLink
