import { FC, useState } from 'react'
import { Autocomplete, TextField } from '@web-panel/shared'
import { Outlet } from '@web-panel/api'
import { useDebouncedValue } from 'rooks'
import { useIntl } from 'react-intl'

import { useOutlets } from '@web-panel/oc-api'

type OutletSelectProps = {
  value: string | null
  onChange: (value: string | null) => void
  error?: boolean
}

const OutletSelect: FC<OutletSelectProps> = ({ value, onChange, error }) => {
  const [search, setSearch] = useState(value ?? '')
  const [debouncedSearch] = useDebouncedValue(search, 250, { initializeWithNull: false })
  const { data: outlets = [] } = useOutlets({ search: debouncedSearch ?? '' })
  const { formatMessage } = useIntl()

  return (
    <Autocomplete<Outlet>
      fullWidth
      className="mt-16"
      filterOptions={(x) => x}
      options={outlets}
      renderInput={(params) => (
        <TextField
          label={formatMessage({ id: 'product-item-holder-types.PARTNER_OUTLET' })}
          error={error}
          {...params}
        />
      )}
      getOptionLabel={(outlet) => outlet.location.name}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      onChange={(e, val) => onChange(val?.id ?? null)}
      onInputChange={(e, inputValue) => setSearch(inputValue)}
      value={outlets.find((o) => o.id === value) ?? null}
    />
  )
}

export default OutletSelect
