import { gql } from '@apollo/client'
import {
  Batch,
  Container,
  ContainerActivity,
  ContainerCapacityType,
  ContainerStatus,
  ContainerType,
  Pagination,
  batchFields,
  containerActivityFields,
  containerFields,
} from '@web-panel/api'

export type ContainersDocumentInput = {
  filters: Partial<{
    status: ContainerStatus
    search: string
  }>
  pagination: Pagination
}
export type ContainersDocumentResponse = {
  containersCollection: {
    containers: Container[]
    hasNext: boolean
  }
}

export const ContainersDocument = gql`
  query Containers($filters: any, $pagination: any) {
    containersCollection(filters: $filters, pagination: $pagination)
      @rest (
        type: "ContainersCollection",
        path: "/operations-service/containers?{args.pagination}&{args.filters}"
        method: "GET"
      )
    {
      containers @type(name: "Container") {
        ${containerFields}
      }
      hasNext
    }
  }
`

export type ContainerByQrCodeDocumentInput = {
  qrCode: string
}
export type ContainerByQrCodeDocumentResponse = {
  containerByQrCode: Container
}
export const ContainerByQrCodeDocument = gql`
  query ContainerByQrCode($qrCode: String!) {
    containerByQrCode(qrCode: $qrCode)
      @rest(type: "Container", path: "/operations-service/containers/qr/{args.qrCode}", method: "GET") {
        ${containerFields}
      }
  }
`

export type ContainerDocumentInput = {
  id: string
}
export type ContainerDocumentResponse = {
  container: Container
}
export const ContainerDocument = gql`
  query Container($id: String!) {
    container(id: $id) @rest(type: "Container", path: "/operations-service/containers/{args.id}", method: "GET") {
      ${containerFields}
    }
  }
`
export type CreateContainerDocumentInput = {
  input: {
    containers: {
      qrCode: string
      type: ContainerType
      capacity: ContainerCapacityType
    }[]
  }
}
export type CreateContainerDocumentResponse = void
export const CreateContainerDocument = gql`
  mutation CreateContainer($input: any) {
    createContainer: publish(input: $input)
      @rest(type: "NoCache", path: "/operations-service/containers", method: "POST") {
      NoCache
    }
  }
`

export type ContainerBatchesDocumentInput = {
  containerId: string
  filters: Partial<{
    status: ContainerStatus.Ready | ContainerStatus.Delivered
  }>
  pagination: Pagination
}
export type ContainerBatchesDocumentResponse = {
  containerBatches: {
    batches: Batch[]
    hasNext: boolean
  }
}
export const ContainerBatchesDocument = gql`
  query ContainerBatches($containerId: String!, $filters: any, $pagination: any) {
    containerBatches(containerId: $containerId, filters: $filters, pagination: $pagination)
      @rest(
        type: "ContainerBatches"
        path: "/operations-service/containers/{args.containerId}/batches?{args.filters}&{args.pagination}"
        method: "GET"
      ) {
      batches @type(name: "Batch") {
        ${batchFields}
      }
      hasNext
    }
  }
`

export type ContainerActivityHistoryDocumentInput = {
  containerId: string
  pagination: Pagination
}
export type ContainerActivityHistoryDocumentResponse = {
  containerActivityHistory: {
    containerActivities: ContainerActivity[]
    hasNext: boolean
  }
}
export const ContainerActivityHistoryDocument = gql`
  query ContainerActivityHistory($containerId: String!, $pagination: any) {
    containerActivityHistory(containerId: $containerId, pagination: $pagination)
      @rest(
        type: "ContainerActivityHistory"
        path: "/operations-service/containers/{args.containerId}/history?{args.pagination}"
        method: "GET"
      ) {
      containerActivities @type(name: "ContainerActivity") {
        ${containerActivityFields}
      }
      hasNext
    }
  }
`
