import { useMemo } from 'react'
import { NavigationProvider } from '@web-panel/shared'
import { useRoleAuthorization } from '../RoleAuthorized'
import { navigationConfig } from './config'

export const NavigationProviderComponent: NavigationProvider = ({ children }) => {
  const { can, loading } = useRoleAuthorization()

  const config = useMemo(() => {
    if (loading) return []

    const sections = navigationConfig.map((section) => {
      const routes = section.routes.filter(({ authorization }) => {
        if (!authorization) return true
        return can(authorization.resource, authorization.action)
      })

      return { ...section, routes }
    })

    const nonEmptySections = sections.filter(({ routes }) => routes.length > 0)
    return nonEmptySections
  }, [loading])

  return children(config)
}
