import { EditableLocation, editableLocationFields } from './location'
import { ActivityTriggerType } from './activity'
import { InventoryTask, inventoryTaskFields } from './inventory-task'

export enum BoxStatus {
  Available = 'AVAILABLE',
  NotAvailable = 'NOT_AVAILABLE',
  Full = 'FULL',
  Broken = 'BROKEN',
  Warehouse = 'WAREHOUSE',
  WarehouseBroken = 'WAREHOUSE_BROKEN',
  Deleted = 'DELETED',
  NoHeartbeat = 'NO_HEARTBEAT',
}

export enum BoxConnectionType {
  WIFI = 'WIFI',
  FourG = 'FOUR_G',
}

export enum BoxType {
  Outdoor = 'OUTDOOR',
  Indoor = 'INDOOR',
}

export enum BoxPowerSupplyType {
  Socker = 'SOCKET',
  Battery = 'BATTERY',
  Solar = 'SOLAR',
}

export type Box = {
  id: string
  name: null
  headId: string | null
  bodyId: string | null
  location: EditableLocation | null
  temperature: number | null
  fillLevel: number | null
  fillLevelThreshold: number | null
  fillLevelFull: number | null
  heartbeatEnabled: boolean
  searchableInOutlet: boolean
  model: string | null
  producerId: string | null
  macAddress: string
  coreId: string
  imei: string | null
  outletId: string | null
  status: BoxStatus | null
  type: BoxType | null
  capacity: number
  cups: number
  totalCups: number
  cupsCountThreshold: number | null
  cupsCountFull: number | null
  battery: number | null
  broken: boolean
  reported: boolean
  heartbeatDate: string | null
  lastSignalDate: string | null
  powerSupplyType: BoxPowerSupplyType | null
  operationsInterval: number
  operationsLastVisitDate: string | null
  previousUptime: number | null
  currentUptime: number | null
  heartbeatSequence: number | null
  hwVersion: string | null
  fwVersion: string | null
  boxConnectionType: BoxConnectionType | null
  vaultUrl: string | null
  operationUrl: string | null
  acceptanceToleranceTof: number
  priority: number
  lightControlEnabled: boolean
}

export const boxFields = `
  id
  name
  macAddress
  coreId
  imei
  outletId
  status
  type
  capacity
  cups
  totalCups
  cupsCountThreshold
  cupsCountFull
  battery
  broken
  reported
  heartbeatDate
  lastSignalDate
  headId
  bodyId
  temperature
  fillLevel
  fillLevelThreshold
  fillLevelFull
  heartbeatEnabled
  searchableInOutlet
  model
  producerId
  location {
    ${editableLocationFields}
  }
  powerSupplyType
  operationsInterval
  operationsLastVisitDate
  previousUptime
  currentUptime
  heartbeatSequence
  hwVersion
  fwVersion
  boxConnectionType
  vaultUrl
  operationUrl
  acceptanceToleranceTof
  priority
  lightControlEnabled
`

export enum BoxActivityRebootType {
  Unknown = 'UNKNOWN',
  PowerOff = 'POWER_OFF',
  Watchdog = 'WATCHDOG',
}

export enum BoxActivityType {
  ItemReturned = 'ITEM_RETURNED',
  ItemReturnedNonReturnable = 'ITEM_RETURNED_NON_RETURNABLE',
  ItemChecked = 'ITEM_CHECKED',
  ItemCheckedNonReturnable = 'ITEM_CHECKED_NON_RETURNABLE',
  BrokenStatusUpdate = 'BROKEN_STATUS_UPDATE',
  HeartbeatStatusUpdate = 'HEARTBEAT_STATUS_UPDATE',
  StatusChange = 'STATUS_CHANGE',
  FullCapacity = 'FULL_CAPACITY',
  InitialHeartbeat = 'INITIAL_HEARTBEAT',
  BatteryChanged = 'BATTERY_CHANGED',
  ErrorHeartbeat = 'ERROR_HEARTBEAT',
  EmptyLoad = 'EMPTY_LOAD',
  Reboot = 'REBOOT_TYPE',
}

export type BoxActivityHistoryElement = {
  boxId: string
  status: BoxStatus
  type: BoxActivityType
  triggerBy: string
  triggerType: ActivityTriggerType
  createDate: string
  lastHeartbeatDate: string
  lastSignalDate: string | null // except for error type
  broken: boolean | null // only broken type
  itemId: string | null // only item type
  errorCode: string | null // only error type
  previousStatus: BoxStatus | null // only status change type
  rebootType: BoxActivityRebootType | null // only reboot type
}

export const boxActivityHistoryElementFields = `
  boxId
  status
  type
  triggerBy
  triggerType
  createDate
  lastHeartbeatDate
  lastSignalDate
  broken
  itemId
  previousStatus
  errorCode
  rebootType
`

export type BoxTask = {
  boxId: string
} & InventoryTask

export const boxTaskFields = `
  boxId
  ${inventoryTaskFields}
`

export type PublicBoxData = {
  id: string
  vaultPublicUrl: string | null
}

export const publicBoxDataFields = `
  id
  vaultPublicUrl
`
