import { OrderStatus } from '@web-panel/api'
import { buildFilterContext } from '@web-panel/shared'

type Filters = {
  status: OrderStatus | undefined
  cityCode: string | undefined
}

export const { FiltersContext, useFilters, withFilters } = buildFilterContext<Filters>({
  status: undefined,
  cityCode: undefined,
})
