import { ThemeOptions } from '@web-panel/shared'

export const themeOverrides: ThemeOptions = {
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingTop: '1rem',
          paddingBottom: '1rem',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          lineHeight: '1.8rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          fontSize: '1.8rem',
          lineHeight: '2.1rem',
          height: '5rem',
          fontWeight: 700,
          borderRadius: '3rem',
        },
      },
    },
  },
  palette: {
    accent2: {
      main: '#9031AA',
    },
  },
}
