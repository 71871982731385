import { FC, useEffect, useRef, useState } from 'react'
import {
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  buildModal,
  ModalBodyProps,
  useNotifications,
} from '@web-panel/shared'
import { Order } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'

import { useCreateOrderSignature, useDeleteOrderSignature, useOrder } from '@web-panel/oc-api'

type Point = { x: number; y: number }

type SignatureModalProps = ModalBodyProps & {
  order: Order
}

const SignatureModal: FC<SignatureModalProps> = ({ order, onClose }) => {
  const [givenSignature, setGivenSignature] = useState(false)
  const { formatMessage } = useIntl()
  const { pushNotification } = useNotifications()
  const { request: createSignature, loading: loadingCreate } = useCreateOrderSignature()
  const { request: deleteSignature, loading: loadingDelete } = useDeleteOrderSignature()
  const { refetch: refetchOrder } = useOrder({ id: order.id })
  const imageRef = useRef<HTMLImageElement>(null)

  const loading = loadingCreate || loadingDelete

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const context = useRef<CanvasRenderingContext2D>(null)
  const offset = useRef({ x: 0, y: 0 })
  const scaleFactor = useRef({ x: 0, y: 0 })
  const touchStart = useRef<Point | null>(null)

  const handleTouchStart = (event: TouchEvent) => {
    event.preventDefault()
    if (!context.current) return
    context.current.lineCap = 'round'
    context.current.beginPath()

    touchStart.current = getTouchCoordinates(event.changedTouches[0])
  }

  const handleTouchEnd = (event: TouchEvent) => {
    if (!context.current) return
    context.current.closePath()
    setGivenSignature(true)
  }

  const handleTouchMove = (event: TouchEvent) => {
    event.preventDefault()
    if (!context.current) return
    const ctx = context.current

    const touches = event.changedTouches
    if (touchStart.current) {
      ctx.moveTo(touchStart.current.x, touchStart.current.y)

      for (let i = 0; i < touches.length; i += 1) {
        const { x, y } = getTouchCoordinates(touches[i])

        ctx.lineWidth = 5
        ctx.strokeStyle = '#000'
        ctx.lineTo(x, y)
        ctx.stroke()
        touchStart.current = { x, y }
      }
    }
  }

  const handleMouseDown = (event: MouseEvent) => {
    event.preventDefault()
    if (!context.current) return
    context.current.lineCap = 'round'
    context.current.beginPath()

    touchStart.current = getMouseEventCoordinates(event)
  }

  const handleMouseMove = (event: MouseEvent) => {
    event.preventDefault()
    if (!touchStart.current) return
    if (!context.current) return
    const ctx = context.current

    ctx.moveTo(touchStart.current.x, touchStart.current.y)
    const { x, y } = getMouseEventCoordinates(event)
    ctx.lineWidth = 5
    ctx.strokeStyle = '#000'
    ctx.lineTo(x, y)
    ctx.stroke()
    touchStart.current = { x, y }
  }

  const handleMouseUp = () => {
    if (!context.current) return
    context.current.closePath()
    touchStart.current = null
    setGivenSignature(true)
  }

  const getMouseEventCoordinates = (event: MouseEvent) => {
    return {
      x: (event.clientX - offset.current.x) * scaleFactor.current.x,
      y: (event.clientY - offset.current.y) * scaleFactor.current.y,
    }
  }

  const getTouchCoordinates = (touch: Touch) => {
    return {
      x: (touch.clientX - offset.current.x) * scaleFactor.current.x,
      y: (touch.clientY - offset.current.y) * scaleFactor.current.y,
    }
  }

  useEffect(() => {
    if (!canvasRef.current) return
    const { left, right, top, bottom } = canvasRef.current.getBoundingClientRect()

    offset.current = { x: left, y: top }
    scaleFactor.current = {
      x: canvasRef.current.width / (right - left),
      y: canvasRef.current.height / (bottom - top),
    }

    const ctx = canvasRef.current.getContext('2d')
    if (!ctx) return

    ctx.imageSmoothingEnabled = true
    ctx.imageSmoothingQuality = 'high'

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    context.current = ctx

    canvasRef.current?.addEventListener('touchstart', handleTouchStart)
    canvasRef.current?.addEventListener('touchend', handleTouchEnd)
    canvasRef.current?.addEventListener('touchmove', handleTouchMove)
    canvasRef.current?.addEventListener('mousedown', handleMouseDown)
    canvasRef.current?.addEventListener('mousemove', handleMouseMove)
    canvasRef.current?.addEventListener('mouseup', handleMouseUp)

    // ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
    ctx.fillStyle = '#fff'
    ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height)
  }, [])

  const handleCreateSignature = async (signature: Blob | null) => {
    if (!signature || !imageRef.current) return
    const file = new File([signature], 'signature.jpg', { type: 'image/jpeg' })

    try {
      if (order.deliverySignature) await deleteSignature({ orderId: order.id })
      await createSignature({ orderId: order.id, input: { signature: file } })
      await refetchOrder()
      formatMessage({ id: 'common.notifications.successfully-created' })
      onClose()
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const handleConfirm = () => {
    if (!context.current || !canvasRef.current) return
    canvasRef.current.toBlob(handleCreateSignature, 'image/jpeg')
  }

  return (
    <>
      <DialogTitle>
        <FormattedMessage id="modals.sign-order-delivery.title" />
      </DialogTitle>
      <DialogContent className="p-4">
        <img ref={imageRef} src="" alt="" />
        <canvas width="600" height="300" className="border-1 w-full" ref={canvasRef} />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!givenSignature}
          variant="contained"
          loading={loading}
          onClick={handleConfirm}
        >
          <FormattedMessage id="common.actions.save" />
        </Button>
      </DialogActions>
    </>
  )
}

export default buildModal(SignatureModal, { fullWidth: true })
