import { FC } from 'react'
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { PreviewProps } from './types'
import { daysOfWeek } from './consts'
import { getHoursPresentation } from './helpers'

export const VerticalPreview: FC<PreviewProps> = ({ data, dayLabels, timeFrameLabels }) => {
  const dayLabelArray = [
    dayLabels.monday,
    dayLabels.tuesday,
    dayLabels.wednesday,
    dayLabels.thursday,
    dayLabels.friday,
    dayLabels.saturday,
    dayLabels.sunday,
  ]

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">{timeFrameLabels.opening}</TableCell>
            <TableCell align="center">{timeFrameLabels.closing}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {daysOfWeek.map((id) => {
            const hours = data.find(({ dayOfWeek }) => dayOfWeek === id)

            return (
              <TableRow key={id}>
                <TableCell variant="head">{dayLabelArray[id]}</TableCell>
                {hours ? (
                  <>
                    <TableCell align="center">{getHoursPresentation(hours.openTime)}</TableCell>
                    <TableCell align="center">{getHoursPresentation(hours.closeTime)}</TableCell>
                  </>
                ) : (
                  <TableCell colSpan={2} />
                )}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
