import { FC } from 'react'

import { AppPage, InfinitScroller, useDebouncedSearch } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import modelIcons from '@local/model-icons'
import { PageHeaderSearch } from '@local/components/PageHeaderSearch'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { useConsumers } from '@web-panel/oc-api'
import IndexTable from './Tables/IndexTable'
import Filters, { withFilters, useFilters } from './Filters'

const IndexPage: FC = () => {
  const { formatMessage } = useIntl()
  const { filters } = useFilters()
  const { search, onChangeSearch, debouncedSearch } = useDebouncedSearch()
  const {
    data: consumers = [],
    loading,
    hasMore,
    loadMore,
  } = useConsumers(
    { ...filters, search: debouncedSearch },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )

  const onLoadMore = () => {
    if (!hasMore) return
    loadMore()
  }

  return (
    <AppPage
      loading={loading}
      icon={modelIcons.appUser}
      title={formatMessage({ id: 'routes.app-users' })}
      Sidebar={Filters}
      searchComponent={<PageHeaderSearch value={search} onChangeValue={onChangeSearch} />}
    >
      <InfinitScroller onLoadMore={onLoadMore}>
        <IndexTable data={consumers} loading={loading} />
      </InfinitScroller>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(withFilters(IndexPage), ({ can }) =>
  can('consumerAccount', 'list')
)
