import { useQuery, useMutation } from '@apollo/client'
import { Batch, getClient, CollectionQueryHook, MutationHook, QueryHook } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  BatchesDocument,
  BatchesDocumentInput,
  BatchesDocumentResponse,
  CreateBatchDocument,
  CreateBatchDocumentInput,
  CreateBatchDocumentResponse,
  GetBatchDocument,
  GetBatchDocumentInput,
  GetBatchDocumentResponse,
  BatchItemsDocument,
  BatchItemsDocumentResponse,
  BatchItemModelsDocument,
  BatchItemModelsDocumentInput,
  BatchItemModelsDocumentResponse,
  AttachContainerToBatchDocumentResponse,
  AttachContainerToBatchDocumentInput,
  AttachContainerToBatchDocument,
  RemoveContainerFromBatchDocumentInput,
  RemoveContainerFromBatchDocumentResponse,
  RemoveContainerFromBatchDocument,
} from '../docs'

type UseBatchesInput = Omit<BatchesDocumentInput, 'limit' | 'offset'>
export const useBatches: CollectionQueryHook<UseBatchesInput, Batch[]> = (input, options) => {
  const variables = {
    ...defaultCollectionVariables,
    ...input,
  }
  const { data, loading, fetchMore, refetch } = useQuery<BatchesDocumentResponse>(BatchesDocument, {
    ...options,
    variables,
  })

  const loadMore = async () => {
    if (loading || !data || !data.batchesCollection.hasNext) return

    const offset = data.batchesCollection.batches.length

    await fetchMore({
      variables: {
        ...variables,
        offset,
      },
    })
  }

  return {
    data: data?.batchesCollection.batches,
    loading,
    loadMore,
    hasMore: data?.batchesCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useCreateBatch: MutationHook<CreateBatchDocumentInput, Batch> = () => {
  const [execute, { loading }] = useMutation<CreateBatchDocumentResponse>(CreateBatchDocument)

  async function request(input: CreateBatchDocumentInput): Promise<Batch> {
    const { data } = await execute({ variables: { input } })
    if (!data?.createBatch) throw Error('Batch was not created. Unknown error')

    return data?.createBatch ?? null
  }

  return { loading, request }
}

export const useBatch: QueryHook<GetBatchDocumentInput, Batch> = (input) => {
  const { data, loading, refetch } = useQuery<GetBatchDocumentResponse>(GetBatchDocument, {
    variables: input,
  })

  return {
    data: data?.batch,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export async function fetchBatch(id: string) {
  const client = getClient()
  const { data } = await client.query<GetBatchDocumentResponse | undefined>({
    query: GetBatchDocument,
    variables: { id },
  })

  return data?.batch ?? null
}

type UseBatchItemsInput = {
  id: string
}

export const useBatchItems: CollectionQueryHook<
  UseBatchItemsInput,
  BatchItemsDocumentResponse['batchItems']['items']
> = (input, options) => {
  const variables = { ...defaultCollectionVariables, ...input }

  const { data, loading, fetchMore, refetch } = useQuery<BatchItemsDocumentResponse>(
    BatchItemsDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.batchItems.hasNext) return

    const offset = data.batchItems.items.length

    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.batchItems.items,
    loading,
    loadMore,
    hasMore: data?.batchItems.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useBatchItemModels: QueryHook<
  BatchItemModelsDocumentInput,
  BatchItemModelsDocumentResponse['batchItemModels']['itemModels']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<BatchItemModelsDocumentResponse>(
    BatchItemModelsDocument,
    { variables, ...options }
  )

  return {
    data: data?.batchItemModels.itemModels,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useAttachContainerToBatch: MutationHook<
  AttachContainerToBatchDocumentInput,
  AttachContainerToBatchDocumentResponse['attachContainerToBatch']
> = () => {
  const [execute, { loading }] = useMutation<AttachContainerToBatchDocumentResponse>(
    AttachContainerToBatchDocument
  )

  const request = async (variables: AttachContainerToBatchDocumentInput) => {
    const { data } = await execute({ variables })

    if (!data?.attachContainerToBatch) throw new Error('Could not attach container to batch')

    return data.attachContainerToBatch
  }

  return {
    request,
    loading,
  }
}

export const useRemoveContainerFromBatch: MutationHook<
  RemoveContainerFromBatchDocumentInput,
  RemoveContainerFromBatchDocumentResponse['removeContainerFromBatch']
> = () => {
  const [execute, { loading }] = useMutation<RemoveContainerFromBatchDocumentResponse>(
    RemoveContainerFromBatchDocument
  )

  const request = async (variables: RemoveContainerFromBatchDocumentInput) => {
    const { data } = await execute({ variables })

    if (!data?.removeContainerFromBatch) throw new Error('Could not remove container from batch')

    return data.removeContainerFromBatch
  }

  return {
    request,
    loading,
  }
}
