import { FC } from 'react'
import {
  AppPage,
  Button,
  ConfirmableButton,
  CardedContent,
  HorizontalTable,
  HorizontalTableRow,
  useNotifications,
  FormattedCurrency,
} from '@web-panel/shared'
import modelIcons from '@local/model-icons'
import { useIntl, FormattedMessage } from 'react-intl'
import { useParams, useNavigate } from 'react-router-dom'
import { Error404 } from '@local/pages/404'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useProductItemConsumable, useDeleteProductItemConsumable } from '@web-panel/oc-api'

const SettingsConsumablesPreviewPageComponent: FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()
  const { formatMessage } = useIntl()
  const { can } = useRoleAuthorization()
  const { data: consumable, loading } = useProductItemConsumable({ id: params.id! })
  const { request: deleteConsumable, loading: loadingDelete } = useDeleteProductItemConsumable()

  if (loading) return null
  if (!consumable) return <Error404 />

  const handleDelete = async () => {
    try {
      await deleteConsumable({ id: consumable.id })
      navigate(-1)
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-deleted' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const headerActionButtons = [
    can(
      'consumable',
      'delete',
      <ConfirmableButton
        fullWidth
        loading={loadingDelete}
        key="delete"
        variant="contained"
        color="error"
        confirmation={{
          title: formatMessage({ id: 'confirmation-dialogs.delete-consumable.title' }),
          acceptTitle: formatMessage({ id: 'common.yes' }),
          cancelTitle: formatMessage({ id: 'common.no' }),
        }}
        onClick={handleDelete}
      >
        <FormattedMessage
          id="common.actions.delete-with-name"
          values={{ name: formatMessage({ id: 'models.consumable-product-item' }) }}
        />
      </ConfirmableButton>
    ),
    can(
      'consumable',
      'update',
      <Button
        fullWidth
        key="edit"
        variant="contained"
        color="secondary"
        onClick={() => navigate(`/settings/consumables/${params.id}/edit`)}
      >
        <FormattedMessage
          id="common.actions.edit-with-name"
          values={{ name: formatMessage({ id: 'models.consumable-product-item' }) }}
        />
      </Button>
    ),
  ]

  return (
    <AppPage
      backButton
      icon={modelIcons.settings}
      title={formatMessage(
        { id: 'routes.settings.consumables.preview' },
        {
          name: formatMessage({ id: `consumable-product-item-types.${consumable.consumableType}` }),
        }
      )}
      actionButtons={headerActionButtons}
    >
      <CardedContent>
        <div className="md:p-24 max-w-2xl">
          <HorizontalTable>
            <HorizontalTableRow
              title={formatMessage({ id: 'models.consumable-product-item.type' })}
            >
              <FormattedMessage id={`consumable-product-item-types.${consumable.consumableType}`} />
            </HorizontalTableRow>
            <HorizontalTableRow
              title={formatMessage({ id: 'models.consumable-product-item.cost' })}
            >
              <FormattedCurrency value={consumable.cost} currency={consumable.currency.code} />
            </HorizontalTableRow>
          </HorizontalTable>
        </div>
      </CardedContent>
    </AppPage>
  )
}

export const SettingsConsumablesPreviewPage = pageWithRoleAuthorization(
  SettingsConsumablesPreviewPageComponent,
  ({ can }) => can('consumable', 'preview')
)
