import { FC, useCallback } from 'react'
import {
  AppPage,
  InfinitScroller,
  Button,
  useModalRef,
  useDebouncedSearch,
} from '@web-panel/shared'
import { useIntl, FormattedMessage } from 'react-intl'

import modelIcons from '@local/model-icons'
import { PageHeaderSearch } from '@local/components/PageHeaderSearch'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useBoxes } from '@web-panel/oc-api'
import { IndexTable } from './Tables/IndexTable'
import CreateBoxesModal from './Modals/CreateBoxes'
import Filters, { withFilters, usePageFilters } from './Filters'
import { withSorting, useSorting } from './sorting'

const IndexPage: FC = () => {
  const { search, onChangeSearch, debouncedSearch } = useDebouncedSearch()
  const { formatMessage } = useIntl()
  const createBoxesModalRef = useModalRef()
  const filters = usePageFilters()
  const { field: sortBy, direction: sortOrder } = useSorting()
  const { can } = useRoleAuthorization()
  const {
    data: boxes = [],
    loading,
    hasMore,
    loadMore,
  } = useBoxes(
    {
      search: debouncedSearch,
      sortOrder,
      sortBy,
      ...filters,
    },
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'cache-first',
    }
  )

  const FilterComponent: FC = useCallback(() => {
    return <Filters search={debouncedSearch} />
  }, [debouncedSearch])

  const onLoadMore = () => {
    if (!hasMore) return
    loadMore()
  }

  const headerActionButtons = [
    can(
      'box',
      'create',
      <Button
        fullWidth
        key="create"
        variant="contained"
        color="secondary"
        onClick={() => createBoxesModalRef.current?.open()}
      >
        <FormattedMessage
          id="common.actions.create-with-name"
          values={{ name: formatMessage({ id: 'models.box' }) }}
        />
      </Button>
    ),
  ]

  return (
    <AppPage
      loading={loading}
      icon={modelIcons.box}
      title={formatMessage({ id: 'routes.boxes' })}
      searchComponent={<PageHeaderSearch value={search} onChangeValue={onChangeSearch} />}
      actionButtons={headerActionButtons}
      Sidebar={FilterComponent}
    >
      <InfinitScroller onLoadMore={onLoadMore}>
        <IndexTable data={boxes} loading={loading} />
      </InfinitScroller>
      <CreateBoxesModal ref={createBoxesModalRef} />
    </AppPage>
  )
}

export default pageWithRoleAuthorization(withSorting(withFilters(IndexPage)), ({ can }) =>
  can('box', 'list')
)
