import { FC } from 'react'
import { LabeledSelect, TextField, InputAdornment } from '@web-panel/shared'
import { Currency, ProductItemModelConfigurationStatus } from '@web-panel/api'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import { useIntl } from 'react-intl'

export type FormFields = {
  status: ProductItemModelConfigurationStatus
  cost: number
  serviceFee: number
  refundFeePercent: number
  payoutFeePercent: number
}

export const validationSchema = Yup.object().shape({
  status: Yup.string().oneOf(Object.values(ProductItemModelConfigurationStatus)).required(),
  cost: Yup.number().min(0).required(),
  serviceFee: Yup.number().min(0).required(),
  refundFeePercent: Yup.number().min(0).max(100).required(),
  payoutFeePercent: Yup.number().min(0).max(100).required(),
})

type EditRefundableFormProps = FormikProps<FormFields> & {
  currency: Currency
}
export const EditRefundableForm: FC<EditRefundableFormProps> = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  currency,
}) => {
  const { formatMessage } = useIntl()

  return (
    <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit}>
      <div className="flex flex-col p-16 sm:p-24 max-w-2xl">
        <LabeledSelect<ProductItemModelConfigurationStatus>
          label={formatMessage({ id: 'models.refundable-product-item.status' })}
          options={Object.values(ProductItemModelConfigurationStatus)}
          value={values.status}
          optionTitle={(status) =>
            formatMessage({ id: `product-item-model-configuration-statuses.${status}` })
          }
          onChange={(e) => handleChange('status')(e.target.value)}
        />
        <TextField
          className="mt-16"
          type="number"
          label={formatMessage({ id: 'models.refundable-product-item.cost' })}
          value={values.cost}
          onChange={handleChange('cost')}
          error={!!errors.cost}
          InputProps={{
            endAdornment: <InputAdornment position="end">{currency.name}</InputAdornment>,
          }}
        />
        <TextField
          className="mt-16"
          type="number"
          label={formatMessage({ id: 'models.refundable-product-item.service-fee' })}
          value={values.serviceFee}
          onChange={handleChange('serviceFee')}
          error={!!errors.serviceFee}
          InputProps={{
            endAdornment: <InputAdornment position="end">{currency.name}</InputAdornment>,
          }}
        />
        <TextField
          className="mt-16"
          type="number"
          label={formatMessage({ id: 'models.refundable-product-item.refund-fee-percent' })}
          value={values.refundFeePercent}
          onChange={handleChange('refundFeePercent')}
          error={!!errors.refundFeePercent}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        <TextField
          className="mt-16"
          type="number"
          label={formatMessage({ id: 'models.refundable-product-item.payout-fee-percent' })}
          value={values.payoutFeePercent}
          onChange={handleChange('payoutFeePercent')}
          error={!!errors.payoutFeePercent}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </div>
    </form>
  )
}
