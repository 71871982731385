import { gql } from '@apollo/client'
import {
  PaymentOut,
  PaymentOutStatus,
  paymentOutFields,
  PaymentOutStatusAggregation,
  paymentOutStatusAggregationFields,
} from '@web-panel/api'

export type PaymentOutsDocumentInput = {
  limit?: number
  offset?: number
  status?: PaymentOutStatus
  search?: string
}
export type PaymentOutsDocumentResponse = {
  paymentOuts: {
    payments: PaymentOut[]
    hasNext: boolean
  }
}

export const PaymentOutsDocument = gql`
  query PaymentOuts($limit: Int, $offset: Int, $status: String, $search: String) {
    paymentOuts(limit: $limit, offset: $offset, status: $status, search: $search)
      @rest(type: "PaymentOutCollection", path: "/finance-service/oc/payments?{args}") {
        payments @type(name: "PaymentOut") {
          ${paymentOutFields}
        }
        hasNext
      }
  }
`

export type ConsumerPaymentOutsDocumentInput = {
  consumerId: string
  limit?: number
  offset?: number
  status?: string
}
export type ConsumerPaymentOutsDocumentResponse = {
  consumerPaymentOuts: {
    payments: PaymentOut[]
    hasNext: boolean
  }
}

export const ConsumerPaymentOutsDocument = gql`
  query ConsumerPaymentOuts($consumerId: String!, $limit: Int, $offset: Int, $status: String) {
    consumerPaymentOuts(consumerId: $consumerId, limit: $limit, offset: $offset, status: $status)
      @rest(
        type: "ConsumerPaymentOutsCollection"
        path: "/finance-service/oc/payments/consumer/{args.consumerId}?{args}"
      ) {
        payments @type(name: "PaymentOut") {
          ${paymentOutFields}
        }
        hasNext
      }
  }
`

export type PaymentOutDocumentInput = {
  id: string
}
export type PaymentOutDocumentResponse = {
  paymentOut: PaymentOut
}

export const PaymentOutDocument = gql`
  query PaymentOut($id: String!) {
    paymentOut(id: $id) @rest(type: "PaymentOut", path: "/finance-service/oc/payments/{args.id}") {
      ${paymentOutFields}
    }
  }
`

export type UpdatePaymentOutDocumentInput = {
  id: string
  input: Partial<{
    paymentOutStatus: PaymentOutStatus
  }>
}

export type UpdatePaymentOutDocumentResponse = {
  updatePaymentOut: PaymentOut
}

export const UpdatePaymentOutDocument = gql`
  mutation UpdatePaymentOut($id: String!, $input: any) {
    updatePaymentOut(id: $id, input: $input) @rest(type: "PaymentOut", path: "/finance-service/oc/payments/{args.id}", method: "PUT") {
      ${paymentOutFields}
    }
  }
`

export type PaymentOutsStatusAggregationDocumentInput = {
  params: Partial<{
    search: string
  }>
}

export type PaymentOutsStatusAggregationDocumentResponse = {
  paymentOutsStatusAggregation: {
    withdrawalCountByStatuses: PaymentOutStatusAggregation[]
  }
}

export const PaymentOutsStatusAggregationDocument = gql`
  query PaymentOutsStatusAggregation($params: any) {
    paymentOutsStatusAggregation(params: $params) @rest(type: "PaymentOutsStatusAggregation", path: "/finance-service/oc/payments/aggregation/status?{args.params}") {
      withdrawalCountByStatuses {
        ${paymentOutStatusAggregationFields}
      }
    }
  }
`
