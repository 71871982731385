import { FC } from 'react'
import { UserRole } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { Link } from '@web-panel/shared'

import { useUser } from '@web-panel/oc-api'

type UserLinkProps = {
  userId: string
}

export const UserLink: FC<UserLinkProps> = ({ userId }) => {
  const { formatMessage } = useIntl()
  const { data: user } = useUser({ id: userId })

  if (!user) return null

  const getUserName = () => {
    if (!user) return ''
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`
    }

    if (user.email) return user.email

    return [formatMessage({ id: `user-roles.${user.role}` }), user.userNumber].join(' ')
  }

  if (user.role === UserRole.Consumer)
    return (
      <Link stopPropogation data-testid="UserLink" to={`/app-users/${user.id}`}>
        {getUserName()}
      </Link>
    )

  if (user.role === UserRole.Administrator)
    return (
      <Link stopPropogation data-testid="UserLink" to={`/admin-users/${user.id}`}>
        {getUserName()}
      </Link>
    )

  return <>{getUserName()}</>
}
