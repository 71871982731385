import { FC } from 'react'
import { Container } from '@web-panel/api'
import { InfinitScroller, TableGrid, useFormatDate } from '@web-panel/shared'
import { FormattedNumber, useIntl } from 'react-intl'
import { OutletLink } from '@local/components/OutletLink'
import { OrderLink } from '@local/components/OrderLink'
import { BatchStatusChip } from '@local/components/BatchStatusChip'
import { UserLink } from '@local/components/UserLink'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useNavigate } from 'react-router-dom'

import { useContainerBatches } from '@web-panel/oc-api'

export type ContainersBatchesTabProps = {
  container: Container
}
export const ContainersBatchesTab: FC<ContainersBatchesTabProps> = ({ container }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const { can } = useRoleAuthorization()

  const {
    data: batches = [],
    loading: loadingBatches,
    loadMore: loadMoreBatches,
  } = useContainerBatches(
    {
      containerId: container.id,
      filters: {},
    },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )

  return (
    <InfinitScroller onLoadMore={loadMoreBatches}>
      <TableGrid
        collection={batches}
        loading={loadingBatches}
        fields={[
          {
            title: formatMessage({ id: 'models.batch.id' }),
            render: ({ batchNumber }) => batchNumber,
          },
          {
            title: formatMessage({ id: 'models.batch.delivered-outlet' }),
            render: ({ deliveredOutletId }) =>
              deliveredOutletId && <OutletLink outletId={deliveredOutletId} />,
          },
          {
            title: formatMessage({ id: 'models.batch.delivery-date' }),
            render: ({ deliveryDate }) => deliveryDate && formatDate('date', deliveryDate),
          },
          {
            title: formatMessage({ id: 'models.batch.order-id' }),
            render: ({ orderId }) => orderId && <OrderLink orderId={orderId} />,
          },
          {
            title: formatMessage({ id: 'models.batch.status' }),
            render: ({ status }) => <BatchStatusChip status={status} />,
          },
          {
            title: formatMessage({ id: 'models.batch.items-number' }),
            render: ({ itemsNumber }) => <FormattedNumber value={itemsNumber} />,
          },
          {
            title: formatMessage({ id: 'models.batch.created-by' }),
            render: ({ createdBy }) => createdBy && <UserLink userId={createdBy} />,
          },
          {
            title: formatMessage({ id: 'models.batch.create-date' }),
            render: ({ createDate }) => formatDate('date', createDate),
          },
        ]}
        rowClickable={can('productItemBatch', 'preview')}
        onClickRow={({ id }) => navigate(id)}
      />
    </InfinitScroller>
  )
}
