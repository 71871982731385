import { FC, ReactElement } from 'react'
import { DialogTitle, DialogContent, DialogActions } from '@mui/material'

type ModalBodyProps = {
  title: string
  actions?: ReactElement[]
}

export const ModalBody: FC<ModalBodyProps> = ({ title, actions = [], children }) => {
  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      {actions.length > 0 && <DialogActions>{actions}</DialogActions>}
    </>
  )
}
