import { FC, useState } from 'react'
import {
  Partner,
  Country,
  City,
  WorkingHours as WorkingHoursData,
  OutletType,
  OutletCycleType,
} from '@web-panel/api'
import {
  FormSection,
  TextField,
  Button,
  LabeledSelect,
  WorkingHoursInput,
  useModalRef,
  Icon,
} from '@web-panel/shared'
import { FormikProps } from 'formik'
import PartnerAutocompleteInput from '@local/components/Autocomplete/PartnerAutocompleteInput'
import CountryAutocompleteInput from '@local/components/Autocomplete/CountryAutocompleteInput'
import CityAutocompleteInput from '@local/components/Autocomplete/CityAutocompleteInput'
import GooglePlacesModal from '@local/modals/GooglePlacesModal'
import { useIntl, FormattedMessage } from 'react-intl'
import * as Yup from 'yup'
import PinDropModal from '@local/modals/PinDropModal'
import {
  LegalAddressSection,
  InvoiceSectionFormFields,
  invoiceSectionInitialValues,
  invoiceValidationSchema,
} from './sections/LegalAddressSection'
import {
  OrderSection,
  OrderSectionFormFields,
  orderSectionValidationSchema,
  orderSectionDefaultValues,
} from './sections/OrderSection'

export type FormFields = {
  partner: Partner | null
  country: Country | null
  city: City | null
  lat: number | null
  lng: number | null
  name: string
  street: string
  houseNumber: string
  postalCode: string
  workingHours: WorkingHoursData[]
  type: OutletType
  firstName: string
  lastName: string
  email: string
  phone: string
  cycleType: OutletCycleType
} & InvoiceSectionFormFields &
  OrderSectionFormFields

export const defaultValues: FormFields = {
  partner: null,
  country: null,
  city: null,
  lat: null,
  lng: null,
  name: '',
  street: '',
  houseNumber: '',
  postalCode: '',
  workingHours: [],
  type: OutletType.Bar,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  cycleType: OutletCycleType.Standard,
  ...invoiceSectionInitialValues,
  ...orderSectionDefaultValues,
}

export const validationSchema = Yup.object()
  .shape({
    partner: Yup.object().required(),
    country: Yup.object().required(),
    city: Yup.object().required(),
    lat: Yup.number().required(),
    lng: Yup.number().required(),
    name: Yup.string().required(),
    street: Yup.string().required(),
    houseNumber: Yup.string().required(),
    postalCode: Yup.string().required(),
    workingHours: Yup.array(),
    type: Yup.string().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().email().required(),
    phone: Yup.string().required(),
    legalAddress: invoiceValidationSchema,
  })
  .concat(orderSectionValidationSchema)

type CreateOutletFormProps = FormikProps<FormFields>
const CreateOutletForm: FC<CreateOutletFormProps> = ({
  values,
  handleChange,
  setFieldValue,
  setValues,
  handleSubmit,
  errors,
}) => {
  const { formatMessage } = useIntl()
  const [showDetailed, setShowDetailed] = useState(false)
  const modalRef = useModalRef()
  const pinDropModalRef = useModalRef()

  return (
    <form className="p-24 max-w-2xl" onSubmit={handleSubmit}>
      <PartnerAutocompleteInput
        fullWidth
        label={formatMessage({ id: 'models.outlet.partner' })}
        value={values.partner}
        onChange={(partner) => setFieldValue('partner', partner)}
        error={!!errors.partner}
      />
      <FormSection title={formatMessage({ id: 'models.outlet.location' })}>
        <CountryAutocompleteInput
          fullWidth
          enabledFor="location"
          className="mt-16"
          label={formatMessage({ id: 'models.outlet.location.country' })}
          value={values.country}
          onChange={(country) => setValues({ ...values, country, city: defaultValues.city })}
          error={!!errors.country}
        />
        {values.country && (
          <CityAutocompleteInput
            fullWidth
            className="mt-16"
            label={formatMessage({ id: 'models.outlet.location.city' })}
            value={values.city}
            country={values.country}
            onChange={(city) => setFieldValue('city', city)}
            error={!!errors.city}
          />
        )}
        {values.country && values.city && (
          <div className="flex justify-center">
            <Button
              className="mt-16"
              variant="contained"
              size="large"
              onClick={() => modalRef.current?.open()}
            >
              <FormattedMessage id="common.actions.find-on-google-maps" />
            </Button>
            <GooglePlacesModal
              ref={modalRef}
              country={values.country}
              city={values.city}
              onSubmit={(place) => {
                setShowDetailed(true)
                setValues({
                  ...values,
                  ...place,
                })
              }}
            />
          </div>
        )}
      </FormSection>
      {showDetailed && (
        <>
          <FormSection title={formatMessage({ id: 'models.outlet.general-info' })}>
            <TextField
              fullWidth
              label={formatMessage({ id: 'models.outlet.name' })}
              value={values.name}
              onChange={handleChange('name')}
            />
            <LabeledSelect
              fullWidth
              className="mt-16"
              label={formatMessage({ id: 'models.outlet.type' })}
              value={values.type}
              options={Object.values(OutletType)}
              optionTitle={(option) => formatMessage({ id: `outlet-types.${option}` })}
              onChange={(e) => handleChange('type')(e.target.value)}
              error={!!errors.type}
            />
            <div className="flex flex-row mt-16">
              <TextField
                fullWidth
                className="mr-8"
                label={formatMessage({ id: 'models.location.latitude' })}
                value={values.lat}
                onChange={handleChange('lat')}
                error={!!errors.lat}
              />
              <TextField
                fullWidth
                className="ml-8"
                label={formatMessage({ id: 'models.location.longitude' })}
                value={values.lng}
                onChange={handleChange('lng')}
                error={!!errors.lng}
              />
              <Button
                className="mt-16 w-full md:w-auto md:ml-16 md:mt-0"
                variant="outlined"
                onClick={() => pinDropModalRef.current?.open()}
              >
                <Icon>my_location</Icon>
              </Button>
            </div>
            <div>
              <LabeledSelect
                fullWidth
                className="mt-16"
                label={formatMessage({ id: 'models.outlet.cycle-type' })}
                value={values.cycleType}
                options={Object.values(OutletCycleType)}
                optionTitle={(cycleType) =>
                  formatMessage({ id: `outlet-cycle-types.${cycleType}` })
                }
                onChange={(e) => handleChange('cycleType')(e.target.value)}
                error={!!errors.cycleType}
              />
            </div>
          </FormSection>
          <OrderSection />
          <FormSection title={formatMessage({ id: 'models.outlet.contact-person' })}>
            <TextField
              fullWidth
              label={formatMessage({ id: 'models.outlet.contact-person.first-name' })}
              value={values.firstName}
              error={!!errors.firstName}
              onChange={handleChange('firstName')}
            />
            <TextField
              fullWidth
              className="mt-16"
              label={formatMessage({ id: 'models.outlet.contact-person.last-name' })}
              value={values.lastName}
              error={!!errors.lastName}
              onChange={handleChange('lastName')}
            />
            <TextField
              fullWidth
              className="mt-16"
              label={formatMessage({ id: 'models.outlet.contact-person.email' })}
              value={values.email}
              error={!!errors.email}
              onChange={handleChange('email')}
            />
            <TextField
              fullWidth
              className="mt-16"
              label={formatMessage({ id: 'models.outlet.contact-person.phone' })}
              value={values.phone}
              error={!!errors.phone}
              onChange={handleChange('phone')}
            />
          </FormSection>
          <FormSection title={formatMessage({ id: 'models.outlet.address' })}>
            <TextField
              fullWidth
              label={formatMessage({ id: 'models.outlet.location.street' })}
              value={values.street}
              error={!!errors.street}
              onChange={handleChange('street')}
            />
            <TextField
              fullWidth
              className="mt-16"
              label={formatMessage({ id: 'models.outlet.location.house-number' })}
              value={values.houseNumber}
              error={!!errors.houseNumber}
              onChange={handleChange('houseNumber')}
            />
            <TextField
              fullWidth
              className="mt-16"
              label={formatMessage({ id: 'models.outlet.location.postal-code' })}
              value={values.postalCode}
              error={!!errors.postalCode}
              onChange={handleChange('postalCode')}
            />
          </FormSection>
          {values.partner && <LegalAddressSection partner={values.partner} />}
          <FormSection title={formatMessage({ id: 'models.outlet.location.working-hours' })}>
            <WorkingHoursInput
              value={values.workingHours}
              onChange={(val) => setFieldValue('workingHours', val)}
              dayLabels={{
                monday: formatMessage({ id: 'time.day-of-week.short.monday' }),
                tuesday: formatMessage({ id: 'time.day-of-week.short.tuesday' }),
                wednesday: formatMessage({ id: 'time.day-of-week.short.wednesday' }),
                thursday: formatMessage({ id: 'time.day-of-week.short.thursday' }),
                friday: formatMessage({ id: 'time.day-of-week.short.friday' }),
                saturday: formatMessage({ id: 'time.day-of-week.short.saturday' }),
                sunday: formatMessage({ id: 'time.day-of-week.short.sunday' }),
              }}
              actionLabels={{
                add: formatMessage({ id: 'common.actions.add' }),
                delete: formatMessage({ id: 'common.actions.delete' }),
              }}
              timeFrameLabels={{
                opening: formatMessage({ id: 'models.outlet.location.opening-time' }),
                closing: formatMessage({ id: 'models.outlet.location.closing-time' }),
              }}
            />
          </FormSection>
          <PinDropModal
            ref={pinDropModalRef}
            lat={values.lat ?? 0}
            lng={values.lng ?? 0}
            onUpdate={({ lat, lng }) => {
              setValues({
                ...values,
                lat,
                lng,
              })
            }}
          />
        </>
      )}
    </form>
  )
}

export default CreateOutletForm
