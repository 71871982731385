import { ContainerCapacityTypeChip } from '@local/components/ContainerCapacityTypeChip'
import { ContainerHolderReference } from '@local/components/ContainerHolderReference'
import { ContainerStatusChip } from '@local/components/ContainerStatusChip'
import { OrderLink } from '@local/components/OrderLink'
import { Container } from '@web-panel/api'
import { HorizontalTable, HorizontalTableRow, useFormatDate } from '@web-panel/shared'
import { FC } from 'react'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'

type ContainersGeneralTabProps = {
  container: Container
}
export const ContainersGeneralTab: FC<ContainersGeneralTabProps> = ({ container }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()

  return (
    <HorizontalTable>
      <HorizontalTableRow title={formatMessage({ id: 'models.container.qr-code' })}>
        {container.qrCode}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.container.type' })}>
        <FormattedMessage id={`container-types.${container.type}`} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.container.status' })}>
        <ContainerStatusChip status={container.status} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.container.status-update-date' })}>
        {formatDate('human-date', container.statusUpdateDate)}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.container.capacity-type' })}>
        <ContainerCapacityTypeChip capacityType={container.capacityType} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.container.capacity' })}>
        <FormattedNumber value={container.capacity} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.container.holder' })}>
        <ContainerHolderReference holderId={container.holderId} holderType={container.holderType} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.container.holder-update-date' })}>
        {formatDate('human-date', container.holderUpdateDate)}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.container.order' })}>
        {container.orderId && <OrderLink orderId={container.orderId} />}
        {container.orderExternalId && <>&nbsp;({container.orderExternalId})</>}
      </HorizontalTableRow>
    </HorizontalTable>
  )
}
