import { FC } from 'react'
import {
  Button,
  ModalBody,
  buildModal,
  ModalBodyProps,
  useNotifications,
  TextField,
  FormLabel,
  DatePicker,
} from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useCreateSchedule } from '@web-panel/oc-api'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { parseInt } from 'lodash'
import { addDays, format as formatDate } from 'date-fns'

interface TimeOption {
  label: string
  value: number // Time in hours for easier comparison (e.g., 11 = 11 AM, 13 = 1 PM)
}

type CreateScheduleModalProps = ModalBodyProps & {
  partnerShippingAddressId: string
}

export type CreateScheduleFormFields = {
  pickUpPackagesNumber: number | undefined
  pickupDate: Date
  pickupTime: number | null
  pickupClosingTime: number | null
}

export const createScheduleValidationSchema = Yup.object().shape({
  pickUpPackagesNumber: Yup.number()
    .typeError('Packages must be a number')
    .positive('Packages should be greater than 1')
    .required('Please enter a number'),
  pickupDate: Yup.date().required('Please select pick up date'),
  pickupTime: Yup.number()
    .required('Pick up time is required') // Ensures the field is not left empty
    .typeError('Please select pick up time'), // Ensures the value is a number
  pickupClosingTime: Yup.number()
    .required('Close time is required') // Ensures the field is not left empty
    .typeError('Please select close time'), // Ensures the value is a number
})

const timeOptions: TimeOption[] = [
  { label: '11:00 AM', value: 11 },
  { label: '12:00 PM', value: 12 },
  { label: '1:00 PM', value: 13 },
  { label: '2:00 PM', value: 14 },
  { label: '3:00 PM', value: 15 },
  { label: '4:00 PM', value: 16 },
  { label: '5:00 PM', value: 17 },
  { label: '6:00 PM', value: 18 },
]

const CreateScheduleModal: FC<CreateScheduleModalProps> = ({
  partnerShippingAddressId,
  onClose,
}) => {
  const { formatMessage } = useIntl()
  const { pushNotification } = useNotifications()
  const { request: createSchedule } = useCreateSchedule()

  const onCreate = async (fields: CreateScheduleFormFields) => {
    try {
      await createSchedule({
        input: {
          partnerShippingAddressId,
          pickupClosingTime: fields.pickupClosingTime?.toString().concat(':00'),
          pickupDate: formatDate(fields.pickupDate, 'yyyy-MM-dd'),
          pickUpPackagesNumber: fields.pickUpPackagesNumber,
          pickupTime: fields.pickupTime?.toString().concat(':00'),
        },
      })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-created' })
      )
      onClose()
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <Formik<CreateScheduleFormFields>
      enableReinitialize
      key="createschedule"
      initialValues={{
        pickUpPackagesNumber: undefined,
        pickupClosingTime: null,
        pickupTime: null,
        pickupDate: new Date(),
      }}
      validationSchema={createScheduleValidationSchema}
      onSubmit={onClose}
    >
      {({ handleChange, setFieldValue, errors, values, dirty }) => {
        const availableCloseTimes = timeOptions.filter((option) => {
          return values.pickupTime !== null && option.value > values.pickupTime!
        })

        return (
          <ModalBody
            title="Create Schedule"
            actions={[
              <Button key="cancel" onClick={onClose}>
                <FormattedMessage id="common.actions.cancel" />
              </Button>,
              <Button
                key="confirm"
                disabled={
                  ((!!errors.pickUpPackagesNumber ||
                    values.pickUpPackagesNumber === undefined ||
                    values.pickUpPackagesNumber === null) &&
                    !!errors.pickupTime) ||
                  values.pickupTime === undefined ||
                  (values.pickupTime === null && !!errors.pickupClosingTime) ||
                  values.pickupClosingTime === undefined ||
                  values.pickupClosingTime === null
                }
                onClick={() => dirty && onCreate(values)}
              >
                <FormattedMessage id="common.actions.confirm" />
              </Button>,
            ]}
          >
            <form className="flex flex-col justify-center w-full">
              <div className="flex flex-col p-16 sm:p-24 max-w-2xl">
                <div>
                  <DatePicker
                    className="mt-16"
                    label={formatMessage({
                      id: 'models.schedule.pick-up-date',
                    })}
                    onChange={(val) => setFieldValue('pickupDate', val)}
                    value={values.pickupDate}
                    minDate={addDays(new Date(), 0)}
                    maxDate={addDays(new Date(), 5 - new Date().getDay())}
                    shouldDisableWeekend
                  />
                </div>
                <div className="mt-28 mb-28 flex flex-row">
                  <FormLabel className="mr-10" style={{ fontWeight: 'bold', fontSize: 14 }}>
                    Pick up time from
                  </FormLabel>
                  <div>
                    <select
                      style={{
                        border: 'none',
                        borderBottom: '1px solid black',
                        outline: 'none',
                      }}
                      id="pickup-time"
                      value={values.pickupTime ?? ''}
                      onChange={(event) => {
                        setFieldValue('pickupTime', parseInt(event.target.value))
                      }}
                    >
                      <option value="" disabled>
                        Pick Up Time
                      </option>
                      {timeOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <FormLabel className="ml-10 mr-10" style={{ fontWeight: 'bold', fontSize: 14 }}>
                    till
                  </FormLabel>
                  <div>
                    <select
                      style={{
                        border: 'none',
                        borderBottom: '1px solid black',
                        outline: 'none',
                      }}
                      id="close-time"
                      value={values.pickupClosingTime ?? ''}
                      onChange={(event) => {
                        setFieldValue('pickupClosingTime', parseInt(event.target.value))
                      }}
                      disabled={values.pickupTime === null} // Disable until pick up time is selected
                    >
                      <option value="" disabled>
                        Close Time
                      </option>
                      {availableCloseTimes.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <TextField
                  fullWidth
                  className="mb-16"
                  value={values.pickUpPackagesNumber}
                  onChange={handleChange('pickUpPackagesNumber')}
                  label={formatMessage({
                    id: 'models.schedule.number-of-packages',
                  })}
                  error={!!errors.pickUpPackagesNumber}
                />

                <FormLabel error color="error">
                  {errors.pickUpPackagesNumber && <div>{errors.pickUpPackagesNumber}</div>}
                  {errors.pickupTime && <div>{errors.pickupTime}</div>}
                  {errors.pickupClosingTime && <div>{errors.pickupClosingTime}</div>}
                </FormLabel>
              </div>
            </form>
          </ModalBody>
        )
      }}
    </Formik>
  )
}

export default buildModal(CreateScheduleModal, { fullWidth: true })
