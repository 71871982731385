import { VFC } from 'react'
import {
  AppPage,
  CardedContent,
  Button,
  useNotifications,
  DefaultErrorPage,
} from '@web-panel/shared'
import { EditableLocation } from '@web-panel/api'
import { Formik } from 'formik'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import modelIcons from '@local/model-icons'
import { useCreateLocation, useAssignDispenserToLocation, useDispenser } from '@web-panel/oc-api'
import {
  DispensersLocationForm,
  dispenserLocationFormInitialFields,
  dispenserLocationFormValidationSchema,
  DispenserLocationFormFields,
} from './Forms/LocationForm'

const CreateCustomLocation: VFC = () => {
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()
  const params = useParams()
  const { formatMessage } = useIntl()
  const {
    data: dispenser,
    loading: dispenserLoading,
    refetch: refetchDispenser,
  } = useDispenser({ id: params.id! })
  const { request: createLocation, loading: loadingCreateLocation } = useCreateLocation()
  const { request: assignToLocation, loading: loadingAssignToLocation } =
    useAssignDispenserToLocation()
  const { state } = useLocation()
  const prefillLocation = state?.location as EditableLocation | null

  if (!dispenser && dispenserLoading) return null
  if (!dispenser)
    return (
      <DefaultErrorPage
        title={formatMessage({ id: 'errors.404.title' })}
        message={formatMessage({ id: 'errors.404.message' })}
      />
    )

  const onSubmit = async ({
    city,
    country,
    latitude,
    longitude,
    ...locationFields
  }: DispenserLocationFormFields) => {
    if (!city) return

    try {
      const location = await createLocation({
        input: {
          ...locationFields,
          cityCode: city.code,
          latitude: Number(latitude),
          longitude: Number(longitude),
        },
      })
      await assignToLocation({
        id: params.id!,
        locationId: location.id,
      })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
      await refetchDispenser()
      navigate(-1)
    } catch (e) {
      console.error(e)
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const initialValues: DispenserLocationFormFields = {
    country: prefillLocation?.country ?? dispenserLocationFormInitialFields.country,
    city: prefillLocation?.city ?? dispenserLocationFormInitialFields.city,
    name: prefillLocation?.name ?? dispenserLocationFormInitialFields.name,
    googlePlaceId:
      prefillLocation?.googlePlaceId ?? dispenserLocationFormInitialFields.googlePlaceId,
    latitude: prefillLocation?.latitude
      ? String(prefillLocation.latitude)
      : dispenserLocationFormInitialFields.latitude,
    longitude: prefillLocation?.longitude
      ? String(prefillLocation.longitude)
      : dispenserLocationFormInitialFields.latitude,
    street: prefillLocation?.street ?? dispenserLocationFormInitialFields.street,
    houseNumber: prefillLocation?.houseNumber ?? dispenserLocationFormInitialFields.houseNumber,
    postalCode: prefillLocation?.postalCode ?? dispenserLocationFormInitialFields.postalCode,
    workingHours: prefillLocation?.workingHours ?? dispenserLocationFormInitialFields.workingHours,
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={dispenserLocationFormValidationSchema}
    >
      {({ isValid, handleSubmit }) => {
        const headerActionButtons = [
          <Button
            fullWidth
            loading={loadingCreateLocation || loadingAssignToLocation}
            key="save"
            variant="contained"
            color="secondary"
            disabled={!isValid}
            onClick={() => handleSubmit()}
          >
            <FormattedMessage id="common.actions.update" />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            icon={modelIcons.dispenser}
            title={formatMessage(
              { id: 'routes.dispensers-assign-custom-location' },
              { name: dispenser.coreId }
            )}
            actionButtons={headerActionButtons}
          >
            <CardedContent>
              <DispensersLocationForm />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export default pageWithRoleAuthorization(CreateCustomLocation, ({ can }) =>
  can('dispenser', 'assignToLocation')
)
