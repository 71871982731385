import { FC } from 'react'

import { FormattedCurrency, InfinitScroller, TableGrid, useFormatDate } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import ProductItemStatusChip from '@local/components/ProductItemStatusChip'
import { OutletLink } from '@local/components/OutletLink'

import { useConsumerProductItems } from '@web-panel/oc-api'

type ProductItemsTabProps = {
  userId: string
}

const ProductItemsTab: FC<ProductItemsTabProps> = ({ userId }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const {
    data = [],
    loading,
    loadMore,
  } = useConsumerProductItems(
    { consumerId: userId },
    { fetchPolicy: 'no-cache', nextFetchPolicy: 'cache-first' }
  )

  return (
    <InfinitScroller onLoadMore={loadMore}>
      <TableGrid
        loading={loading}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        collection={data}
        fields={[
          {
            title: formatMessage({ id: 'models.product-item.qr-code' }),
            render: ({ qr }) => qr,
          },
          {
            title: formatMessage({ id: 'models.product-item.rfid' }),
            render: ({ rfid }) => rfid,
          },
          {
            title: formatMessage({ id: 'models.product-item.size' }),
            render: ({ size }) => <FormattedMessage id={`product-item-sizes.${size}`} />,
          },
          {
            title: formatMessage({ id: 'models.product-item.taken-from-outlet' }),
            render: ({ takenFromOutletId }) => <OutletLink outletId={takenFromOutletId} />,
          },
          {
            title: formatMessage({ id: 'models.product-item.taken-from-outlet-date' }),
            render: ({ takenFromOutletDate }) => formatDate('date', takenFromOutletDate),
          },
          {
            title: formatMessage({ id: 'models.product-item.return-date' }),
            render: ({ returnDate }) => returnDate && formatDate('date', returnDate),
          },
          {
            title: formatMessage({ id: 'models.product-item.refund' }),
            render: ({ refund }) =>
              refund && <FormattedCurrency value={refund.amount} currency={refund.currency.code} />,
          },
          {
            title: formatMessage({ id: 'models.product-item.status' }),
            render: ({ status }) => <ProductItemStatusChip status={status} />,
          },
        ]}
        onClickRow={(productItem) => navigate(`/product-items/${productItem.id}`)}
      />
    </InfinitScroller>
  )
}

export default ProductItemsTab
