import { FC, useMemo } from 'react'
import {
  AmountInput,
  LabeledSelect,
  FormSection,
  Typography,
  LabeledSwitch,
  Button,
  FormattedCurrency,
} from '@web-panel/shared'
import {
  Partner,
  OrderReturnType,
  ConsumableProductItemType,
  ProductItemModelConfiguration,
  Outlet,
} from '@web-panel/api'
import { useIntl, FormattedMessage } from 'react-intl'
import { useFormikContext } from 'formik'
import * as Yup from 'yup'
import { RoleAuthorized } from '@local/components/RoleAuthorized'
import { useNavigate } from 'react-router-dom'

import {
  useProductItemPartnerConsumableByType,
  useProductItemModelTypeConfigurations,
} from '@web-panel/oc-api'

export type OrderItem = {
  amount: number
  itemModelId: string
  includeLids: boolean
}

export type ItemSectionFormFields = {
  orderItems: OrderItem[]
  returnType: OrderReturnType
}

export const defaultItemsInfoValues: ItemSectionFormFields = {
  orderItems: [],
  returnType: OrderReturnType.Consumer,
}

export const itemsInfoValidationSchema = Yup.object().shape({
  orderItems: Yup.array().required(),
})

type ItemsSectionProps = {
  partner: Partner
  outlet: Outlet
}

export const ItemsSection: FC<ItemsSectionProps> = ({ partner, outlet }) => {
  const { formatMessage, formatNumber } = useIntl()
  const { values, setFieldValue } = useFormikContext<ItemSectionFormFields>()
  const navigate = useNavigate()

  const { data: modelConfigurations = [], loading: loadingModelConfigurations } =
    useProductItemModelTypeConfigurations({ partnerId: partner.id, outletId: outlet.id })

  const { data: consumableLid, loading: loadingConsumableLid } =
    useProductItemPartnerConsumableByType({
      partnerId: partner.id,
      type: ConsumableProductItemType.Lid,
    })

  const totalAmount = useMemo(() => {
    return values.orderItems.reduce((sum, { amount }) => sum + amount, 0)
  }, [values.orderItems])

  if (loadingModelConfigurations || loadingConsumableLid) return null
  if (modelConfigurations.length === 0)
    return (
      <FormSection title={formatMessage({ id: 'models.order.quantity' })}>
        <Typography className="mb-8" variant="body1" color="error">
          <FormattedMessage id="errors.no-refundables-configured-for-outlet" />
        </Typography>
        <RoleAuthorized resource="outlet" action="listRefundables">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate(`/outlets/${outlet.id}#refundables`)}
          >
            <FormattedMessage id="models.outlet-preview.tabs.refundables" />
          </Button>
        </RoleAuthorized>
      </FormSection>
    )

  const { currency } = modelConfigurations[0]

  const getOrderItem = (modelId: string): [OrderItem, number] => {
    const orderItemId = values.orderItems.findIndex((item) => item.itemModelId === modelId)

    return [
      orderItemId >= 0
        ? values.orderItems[orderItemId]
        : { itemModelId: modelId, amount: 0, includeLids: false },
      orderItemId,
    ]
  }

  const handleChangeOrderItem = (orderItem: OrderItem) => {
    const orderItems = [...values.orderItems]
    let id = orderItems.findIndex((item) => item.itemModelId === orderItem.itemModelId)
    if (id === -1) id = orderItems.length

    if (orderItem.amount > 0) {
      orderItems[id] = orderItem
    } else if (id >= 0) {
      orderItems.splice(id, 1)
    }

    setFieldValue('orderItems', orderItems)
  }

  const calculateOrderItemFullCost = (modelConfiguration: ProductItemModelConfiguration) => {
    const [orderItem] = getOrderItem(modelConfiguration.itemModel.id)
    const productCost = orderItem.amount * (modelConfiguration.cost + modelConfiguration.serviceFee)
    const lidCost = orderItem.includeLids ? (consumableLid?.cost ?? 0) * orderItem.amount : 0
    return productCost + lidCost
  }

  const calculateTotalOrderCost = () => {
    return modelConfigurations.reduce(
      (sum, modelConfiguration) => sum + calculateOrderItemFullCost(modelConfiguration),
      0
    )
  }

  const isCycleTypeAnonymous =
    outlet.orderReturnTypes.length === 1 && outlet.orderReturnTypes[0] === OrderReturnType.Anonymous

  if (values.returnType !== OrderReturnType.Anonymous && isCycleTypeAnonymous) {
    setFieldValue('returnType', OrderReturnType.Anonymous)
  }

  return (
    <>
      <FormSection title={formatMessage({ id: 'models.order.quantity' })}>
        <LabeledSelect
          fullWidth
          label={formatMessage({ id: 'models.order.return-type' })}
          options={Object.values(outlet.orderReturnTypes)}
          optionTitle={(option) => formatMessage({ id: `order-return-types.${option}` })}
          value={values.returnType}
          disabled={isCycleTypeAnonymous}
          onChange={(e) => {
            setFieldValue('returnType', e.target.value)
          }}
        />

        {modelConfigurations.map((modelConfiguration) => {
          const [orderItem] = getOrderItem(modelConfiguration.itemModel.id)
          const itemFullCost = calculateOrderItemFullCost(modelConfiguration)

          return (
            <div key={modelConfiguration.itemModel.id} className="mt-22">
              <div className="flex flex-row mt-16 items-center">
                <AmountInput
                  enableManualInput
                  error={totalAmount === 0}
                  inputStep={outlet.batchDefaultSize}
                  label={modelConfiguration.itemModel.description}
                  disabledSub={orderItem.amount === 0}
                  value={orderItem.amount}
                  onChange={(amount) => handleChangeOrderItem({ ...orderItem, amount })}
                />
                <div className="hidden md:flex flex-col flex-1 md:mt-0 md:ml-16">
                  <Typography variant="caption">
                    <FormattedMessage id="models.order.item-cost" />
                  </Typography>
                  <Typography variant="body1" fontWeight={600}>
                    <FormattedCurrency
                      value={itemFullCost}
                      currency={modelConfiguration.currency.name}
                    />
                  </Typography>
                </div>
              </div>
              {consumableLid && (
                <LabeledSwitch
                  className="ml-0 md:ml-6 mt-8 md:mt-0"
                  disabled={orderItem.amount === 0}
                  labelPlacement="start"
                  label={formatMessage(
                    {
                      id: 'common.labels.add-lids',
                    },
                    {
                      count: orderItem.amount,
                      lidPrice: formatNumber(consumableLid.cost, {
                        style: 'currency',
                        currency: currency.code,
                      }),
                    }
                  )}
                  checked={orderItem.includeLids}
                  onChange={(e, includeLids) =>
                    handleChangeOrderItem({ ...orderItem, includeLids })
                  }
                />
              )}
            </div>
          )
        })}
      </FormSection>
      <FormSection title={formatMessage({ id: 'models.order.total-cost' })}>
        <Typography variant="body1" fontWeight={600}>
          <FormattedCurrency value={calculateTotalOrderCost()} currency={currency.code} />
        </Typography>
      </FormSection>
    </>
  )
}
