import { FC } from 'react'
import { UserMenu as SharedUserMenu } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { useAuthContext, useCurrentUser } from '@web-panel/api'

const UserMenu: FC = () => {
  const { formatMessage } = useIntl()
  const { signout } = useAuthContext()
  const { data: currentUser } = useCurrentUser()
  if (!currentUser) return null

  const userName = [currentUser.firstName, currentUser.lastName].join(' ')
  const userRole = formatMessage({ id: `user-roles.${currentUser.role}` })

  const menu = [
    {
      icon: 'logout',
      title: formatMessage({ id: 'components.user-menu.sign-out' }),
      action: signout,
    },
  ]

  return <SharedUserMenu userName={userName} userRole={userRole} menu={menu} />
}

export default UserMenu
