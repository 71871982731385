import { FC, MouseEvent, useLayoutEffect } from 'react'
import {
  InfinitScroller,
  TableGrid,
  Typography,
  Button,
  Hidden,
  useNotifications,
  useModalRef,
  useFormatDate,
} from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { Order } from '@web-panel/api'
import { OutletLink } from '@local/components/OutletLink'
import { UserLink } from '@local/components/UserLink'
import { useLocation, useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { ContainerStatusChip } from '@local/components/ContainerStatusChip'
import { BatchStatusChip } from '@local/components/BatchStatusChip'
import {
  useAttachBatchesToOrder,
  useOrderBatches,
  useRemoveBatchFromOrder,
  useOrderAttachedContainers,
  useAttachContainerToOrder,
  useRemoveContainerFromOrder,
} from '@web-panel/oc-api'
import ContainerAttachModal from '../Modals/ContainerAttachModal'
import ResourceScannerModal from '../Modals/ResourceScannerModal'
import BatchAttachModal from '../Modals/BatchAttachModal'

type BatchesTabProps = {
  order: Order
}

const BatchesTab: FC<BatchesTabProps> = ({ order }) => {
  const { pushNotification } = useNotifications()
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const { state } = useLocation()
  const navigate = useNavigate()
  const containerScannerRef = useModalRef()
  const batchScanneRef = useModalRef()
  const containerAttachModalRef = useModalRef()
  const batchAttachModalRef = useModalRef()
  const { request: attachBatches } = useAttachBatchesToOrder()
  const { request: attachContainerToOrder } = useAttachContainerToOrder()
  const { cannot } = useRoleAuthorization()
  const {
    data: batches = [],
    loading: loadingBatches,
    loadMore: loadMoreBatches,
    refetch: refetchBatches,
  } = useOrderBatches(
    { id: order.id },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )
  const {
    data: containers = [],
    loading: loadingContainers,
    loadMore: loadMoreContainers,
    refetch: refetchContainers,
  } = useOrderAttachedContainers(
    { id: order.id },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )
  const { request: removeBatchFromOrder } = useRemoveBatchFromOrder()
  const { request: removeContainerFromOrder } = useRemoveContainerFromOrder()

  useLayoutEffect(() => {
    if (!state || !state.openScanner || !isMobile) return

    batchScanneRef.current?.open()
  }, [])

  const handleAttachBatchesToOrder = async (batchIds: string[]) => {
    try {
      await attachBatches({ id: order.id, batchIds })
      await refetchBatches()
      await refetchContainers()
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const handleDetach = (batchId: string) => {
    return async (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      await removeBatchFromOrder({ orderId: order.id, batchId })
      await refetchBatches()
      await refetchContainers()
    }
  }

  const handleAttachContainerToOrder = async (qrCode: string) => {
    try {
      await attachContainerToOrder({ id: order.id, input: { qrCode } })
      await refetchContainers()
      await refetchBatches()
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const handleRemoveContainerFromOrder = (containerId: string) => {
    return async (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()

      try {
        await removeContainerFromOrder({ id: order.id, containerId })
        await refetchContainers()
        await refetchBatches()
        pushNotification(
          'success',
          formatMessage({ id: 'common.notifications.successfully-deleted' })
        )
      } catch (err) {
        pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
      }
    }
  }

  return (
    <>
      <div>
        <Typography className="ml-16 md:ml-0" variant="h3">
          <FormattedMessage id="models.batch.plural" />
        </Typography>
        <InfinitScroller onLoadMore={loadMoreBatches}>
          <TableGrid
            loading={loadingBatches}
            emptyLabel={formatMessage({ id: 'common.no-data' })}
            collection={batches}
            onClickRow={(batch) => navigate(`/product-item-batches/${batch.id}`)}
            fields={[
              {
                title: formatMessage({ id: 'models.batch.id' }),
                render: ({ batchNumber }) => batchNumber,
              },
              {
                title: formatMessage({ id: 'models.batch.delivery-box' }),
                render: ({ deliveryBox }) => deliveryBox,
                hiddenMobile: true,
              },
              {
                title: formatMessage({ id: 'models.batch.delivered-by' }),
                render: ({ deliveredBy }) => deliveredBy && <UserLink userId={deliveredBy} />,
                hiddenMobile: true,
              },
              {
                title: formatMessage({ id: 'models.batch.status' }),
                render: BatchStatusChip,
              },
              {
                title: formatMessage({ id: 'models.batch.delivered-outlet' }),
                render: ({ deliveredOutletId }) =>
                  deliveredOutletId && <OutletLink outletId={deliveredOutletId} />,
                hiddenMobile: true,
              },
              {
                title: formatMessage({ id: 'models.batch.delivery-date' }),
                render: ({ deliveryDate }) => deliveryDate && formatDate('datetime', deliveryDate),
                hiddenMobile: true,
              },
              {
                title: '',
                render: (batch) => (
                  <Button variant="text" color="error" onClick={handleDetach(batch.id)}>
                    <FormattedMessage id="common.actions.detach" />
                  </Button>
                ),
              },
            ]}
            addItemButtonAppearance="top"
            addItemButton={
              <div className="flex flex-row gap-8 items-center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => batchAttachModalRef.current?.open()}
                >
                  <FormattedMessage id="common.actions.attach" />
                </Button>
                <Hidden lgUp>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => batchScanneRef.current?.open()}
                  >
                    <FormattedMessage id="common.actions.scan-qr-code" />
                  </Button>
                </Hidden>
              </div>
            }
          />
        </InfinitScroller>
      </div>
      <div className="mt-48">
        <Typography className="ml-16 md:ml-0" variant="h3">
          <FormattedMessage id="models.container.plural" />
        </Typography>
        <InfinitScroller onLoadMore={loadMoreContainers}>
          <TableGrid
            loading={loadingContainers}
            emptyLabel={formatMessage({ id: 'common.no-data' })}
            collection={containers}
            onClickRow={(container) => navigate(`/containers/${container.id}`)}
            fields={[
              {
                title: formatMessage({ id: 'models.container.qr-code' }),
                render: ({ qrCode }) => qrCode,
              },
              {
                title: formatMessage({ id: 'models.container.status' }),
                render: ContainerStatusChip,
              },
              {
                title: '',
                render: (container) => (
                  <Button
                    variant="text"
                    color="error"
                    onClick={handleRemoveContainerFromOrder(container.id)}
                    disabled={cannot('order', 'removeContainersFromOrder')}
                  >
                    <FormattedMessage id="common.actions.detach" />
                  </Button>
                ),
              },
            ]}
            addItemButtonAppearance="top"
            addItemButton={
              <div className="flex flex-row gap-8 items-center">
                <Button
                  disabled={cannot('order', 'attachContainersToOrder')}
                  variant="contained"
                  color="primary"
                  onClick={() => containerAttachModalRef.current?.open()}
                >
                  <FormattedMessage id="common.actions.attach" />
                </Button>
                <Hidden lgUp>
                  <Button
                    disabled={cannot('order', 'attachContainersToOrder')}
                    variant="contained"
                    color="primary"
                    onClick={() => containerScannerRef.current?.open()}
                  >
                    <FormattedMessage id="common.actions.scan-qr-code" />
                  </Button>
                </Hidden>
              </div>
            }
          />
        </InfinitScroller>
      </div>
      <ResourceScannerModal
        ref={batchScanneRef}
        title={formatMessage({ id: 'modals.scan-batch-qr-code.title' })}
        resource="batch"
        attachedResourceIds={batches.map((batch) => batch.id)}
        onAttachResource={(batchId: string) => handleAttachBatchesToOrder([batchId])}
      />
      <ResourceScannerModal
        ref={containerScannerRef}
        title={formatMessage({ id: 'modals.scan-container-qr-code.title' })}
        resource="container"
        attachedResourceIds={containers.map((container) => container.qrCode)}
        onAttachResource={handleAttachContainerToOrder}
      />
      <ContainerAttachModal
        ref={containerAttachModalRef}
        onSubmit={handleAttachContainerToOrder}
        alreadyAttachedContainerIds={containers.map((container) => container.id)}
      />
      <BatchAttachModal
        ref={batchAttachModalRef}
        onSubmit={handleAttachBatchesToOrder}
        alreadyAttachedBatchIds={batches.map((batch) => batch.id)}
      />
    </>
  )
}

export default BatchesTab
