import { FC } from 'react'
import { Button, TableGrid, InfinitScroller } from '@web-panel/shared'
import { Partner } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { OutletAvatar } from '@local/components/OutletAvatar'
import OutletStatusChip from '@local/components/OutletStatusChip'

import { usePartnerOutlets } from '@web-panel/oc-api'

type OutletsTabProps = {
  partner: Partner
}
const OutletsTab: FC<OutletsTabProps> = ({ partner }) => {
  const { formatMessage } = useIntl()
  const {
    data: outlets = [],
    loading,
    loadMore,
  } = usePartnerOutlets(
    { id: partner.id },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )
  const navigate = useNavigate()

  return (
    <InfinitScroller onLoadMore={loadMore}>
      <TableGrid
        loading={loading}
        collection={outlets}
        fields={[
          {
            title: formatMessage({ id: 'models.outlet.id' }),
            render: ({ outletNumber }) => outletNumber,
          },
          {
            title: formatMessage({ id: 'models.outlet.location.name' }),
            render: (outlet) => (
              <div className="flex flex-row items-center">
                <OutletAvatar className="mr-16" outlet={outlet} />
                {outlet.location.name}
              </div>
            ),
          },
          {
            title: formatMessage({ id: 'models.outlet.contact-person.name' }),
            render: ({ firstName, lastName }) => [firstName, lastName].join(' '),
          },
          {
            title: formatMessage({ id: 'models.outlet.email' }),
            render: ({ email }) => email,
          },
          {
            title: formatMessage({ id: 'models.outlet.contact-person.phone' }),
            render: ({ phone }) => phone,
          },
          {
            title: formatMessage({ id: 'models.outlet.operations-phone' }),
            render: ({ operationsPhone }) => operationsPhone,
          },
          {
            title: formatMessage({ id: 'models.outlet.operations-email' }),
            render: ({ operationsEmail }) => operationsEmail,
          },
          {
            title: formatMessage({ id: 'models.outlet.status' }),
            render: ({ status }) => <OutletStatusChip status={status} />,
          },
          {
            title: formatMessage({ id: 'models.outlet.type' }),
            render: ({ type }) => <FormattedMessage id={`outlet-types.${type}`} />,
          },
        ]}
        onClickRow={(outlet) => navigate(`/outlets/${outlet.id}`)}
        addItemButton={
          <Button
            variant="contained"
            onClick={() => navigate('/outlets/create', { state: { partner } })}
          >
            <FormattedMessage id="common.actions.add" />
          </Button>
        }
      />
    </InfinitScroller>
  )
}

export default OutletsTab
