import { useMemo, VFC } from 'react'
import { SelectableList, Typography, Box, Slider, buildFilterContext } from '@web-panel/shared'
import {
  DispenserLocationType,
  DispenserOperationType,
  DispenserStatus,
  useCities,
} from '@web-panel/api'
import { useIntl, FormattedMessage } from 'react-intl'
import { subMinutes } from 'date-fns'

type FilterFields = {
  status: DispenserStatus | undefined
  operationType: DispenserOperationType | undefined
  locationType: DispenserLocationType | undefined
  minLastSignal: string | undefined
  maxLastSignal: string | undefined
  cityCode: string | undefined
}
export const { useFilters, withFilters } = buildFilterContext<FilterFields>({
  status: undefined,
  operationType: undefined,
  locationType: undefined,
  minLastSignal: undefined,
  maxLastSignal: undefined,
  cityCode: undefined,
})

const minFilterLastSignal = 0
const maxFilterLastSignal = 361

const Filters: VFC = () => {
  const { formatMessage } = useIntl()
  const { filters, setFilter, setFilters } = useFilters()
  const { data: cities = [] } = useCities()

  const handleLastSignalChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') return

    const [newMinLastSignal, newMaxLastSignal] = newValue
    setFilters({
      minLastSignal: newMinLastSignal > minFilterLastSignal ? String(newMinLastSignal) : undefined,
      maxLastSignal: newMaxLastSignal < maxFilterLastSignal ? String(newMaxLastSignal) : undefined,
    })
  }

  return (
    <div className="flex flex-col">
      <div>
        <Typography className="pl-12" color="grayText">
          <FormattedMessage id="models.dispenser.last-signal-date" />
        </Typography>
        <Box className="px-16">
          <Slider
            min={minFilterLastSignal}
            max={maxFilterLastSignal}
            value={[
              Number(filters.minLastSignal ?? 0),
              Number(filters.maxLastSignal ?? maxFilterLastSignal),
            ]}
            onChange={handleLastSignalChange}
            valueLabelDisplay="auto"
            valueLabelFormat={(val) =>
              val < maxFilterLastSignal
                ? formatMessage({ id: 'common.minutes.with-value' }, { value: val })
                : '∞'
            }
          />
        </Box>
      </div>
      <SelectableList
        className="mt-16"
        label={formatMessage({ id: 'models.dispenser.status' })}
        value={filters.status}
        options={Object.values(DispenserStatus)}
        optionTitle={(option) => formatMessage({ id: `dispenser-statuses.${option}` })}
        onSelect={(status) => setFilter('status', status)}
      />
      <SelectableList
        className="mt-16"
        label={formatMessage({ id: 'models.dispenser.operation-type' })}
        value={filters.operationType}
        options={Object.values(DispenserOperationType)}
        optionTitle={(option) => formatMessage({ id: `dispenser-operation-types.${option}` })}
        onSelect={(operationType) => setFilter('operationType', operationType)}
      />
      <SelectableList
        className="mt-16"
        label={formatMessage({ id: 'models.dispenser.location-type' })}
        value={filters.locationType}
        options={Object.values(DispenserLocationType)}
        optionTitle={(option) => formatMessage({ id: `dispenser-location-types.${option}` })}
        onSelect={(locationType) => setFilter('locationType', locationType)}
      />
      <SelectableList
        className="mt-16"
        value={filters.cityCode}
        options={cities.map(({ code }) => code)}
        optionTitle={(cityCode) => cities.find(({ code }) => code === cityCode)?.name ?? ''}
        label={formatMessage({ id: 'models.location.city' })}
        onSelect={(cityCode) => setFilter('cityCode', cityCode)}
      />
    </div>
  )
}

export const usePageFilters = () => {
  const { debouncedFilters } = useFilters()
  const { minLastSignal, maxLastSignal, ...filters } = debouncedFilters

  const { startLastSignalDateTime, endLastSignalDateTime } = useMemo(() => {
    const currentDate = new Date()

    return {
      endLastSignalDateTime:
        minLastSignal && Number(minLastSignal) > minFilterLastSignal
          ? subMinutes(currentDate, Number(minLastSignal)).toISOString()
          : undefined,
      startLastSignalDateTime:
        maxLastSignal && Number(maxLastSignal) < maxFilterLastSignal
          ? subMinutes(currentDate, Number(maxLastSignal)).toISOString()
          : undefined,
    }
  }, [minLastSignal, maxLastSignal])

  return {
    ...filters,
    startLastSignalDateTime,
    endLastSignalDateTime,
  }
}

export default Filters
