import { useEffect, forwardRef, useImperativeHandle, useRef } from 'react'
import { Html5Qrcode } from 'html5-qrcode'
import { ExternalRouterModel } from '@local/pages/ExternalRouter/helpers'

export type ResourceQRScannerRef = {
  stop: () => void
  pause: () => void
  resume: () => void
}

type ResourceQRScannerProps = {
  resource: ExternalRouterModel
  onDetectResourceId: (id: string | null) => void
}

const ResourceQRScanner = forwardRef<ResourceQRScannerRef, ResourceQRScannerProps>(
  ({ resource, onDetectResourceId }, ref) => {
    const scannerRef = useRef<Html5Qrcode>(null)

    useImperativeHandle(ref, () => ({
      stop: () => {
        scannerRef.current?.stop()
      },
      pause: () => scannerRef.current?.pause(),
      resume: () => {
        if (!scannerRef.current) throw new Error('not found scanner')
        scannerRef.current?.resume()
      },
    }))

    const onDetectQRCode = async (data: string) => {
      try {
        const url = new URL(data)
        const path = url.pathname.replaceAll('/', '')
        const search = new URLSearchParams(url.search)
        const model = search.get('model') ?? search.get('m')
        const id = search.get('id') ?? search.get('i')
        if (path === 'external' && model === resource && id) {
          onDetectResourceId(id)
        } else {
          onDetectResourceId(null)
        }
      } catch (err) {
        console.error(err)
        onDetectResourceId(null)
      }
    }

    const onError = () => {
      onDetectResourceId(null)
    }

    const setScanner = async (elementId: string) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      scannerRef.current = new Html5Qrcode(elementId)
      scannerRef.current.start({ facingMode: 'environment' }, { fps: 10 }, onDetectQRCode, onError)
    }

    useEffect(() => {
      setScanner('container')
      return () => {
        scannerRef.current?.stop()
      }
    }, [])

    return <div id="container" />
  }
)

export default ResourceQRScanner
