import { FC } from 'react'
import { Button, TableGrid, InfinitScroller } from '@web-panel/shared'

import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'

import { usePartnerEmployees } from '@web-panel/oc-api'

type EmployeesTabProps = {
  partnerId: string
}

const EmployeesTab: FC<EmployeesTabProps> = ({ partnerId }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { can } = useRoleAuthorization()
  const {
    data: employees = [],
    loading,
    loadMore,
  } = usePartnerEmployees(
    { partnerId },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )

  return (
    <InfinitScroller onLoadMore={loadMore}>
      <TableGrid
        loading={loading}
        collection={employees}
        fields={[
          {
            title: formatMessage({ id: 'models.partner-employee.name' }),
            render: ({ firstName, lastName }) => [firstName, lastName].join(' '),
          },
          {
            title: formatMessage({ id: 'models.partner-employee.email' }),
            render: ({ email }) => email,
          },
          {
            title: formatMessage({ id: 'models.partner-employee.outlets' }),
            render: ({ outletIds }) => outletIds.length,
          },
        ]}
        onClickRow={(employee) => navigate(`employee/${employee.id}`)}
        rowClickable={can('partnerEmployee', 'preview')}
        addItemButton={
          can('partnerEmployee', 'create') ? (
            <Button variant="contained" color="primary" onClick={() => navigate('employee/create')}>
              <FormattedMessage id="common.actions.create" />
            </Button>
          ) : undefined
        }
      />
    </InfinitScroller>
  )
}

export default EmployeesTab
