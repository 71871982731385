import { FC } from 'react'
import {
  buildModal,
  ModalBodyProps,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  useNotifications,
} from '@web-panel/shared'

import { FormattedMessage, useIntl } from 'react-intl'
import { Formik } from 'formik'
import { useCreatePartnerSupplement } from '@web-panel/oc-api'
import {
  PartnerSupplementForm,
  FormFields,
  initialValues,
} from '../Forms/PartnerSupplementForm.tsx'

type CreatePartnerSupplementModalComponentProps = ModalBodyProps & {
  partnerId: string
  onCreated: () => void
}
const Component: FC<CreatePartnerSupplementModalComponentProps> = ({
  partnerId,
  onClose,
  onCreated,
}) => {
  const { request: createPartnerSupplement, loading: loadingCreatePartnerSupplement } =
    useCreatePartnerSupplement()
  const { formatMessage } = useIntl()
  const { pushNotification } = useNotifications()

  const onSubmit = async (input: FormFields) => {
    try {
      await createPartnerSupplement({ partnerId, input })
      onCreated()
      onClose()
      pushNotification('success', formatMessage({ id: 'common.notifications.created' }))
    } catch (error) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <>
          <DialogTitle>
            <FormattedMessage id="modals.create-partner-supplement.title" />
          </DialogTitle>
          <DialogContent>
            <PartnerSupplementForm />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>
              <FormattedMessage id="common.actions.cancel" />
            </Button>
            <Button
              loading={loadingCreatePartnerSupplement}
              variant="contained"
              color="secondary"
              onClick={() => handleSubmit()}
            >
              <FormattedMessage id="common.actions.create" />
            </Button>
          </DialogActions>
        </>
      )}
    </Formik>
  )
}

export const CreatePartnerSupplementModal = buildModal(Component, { fullWidth: true })
