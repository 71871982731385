import { FC } from 'react'
import { ScheduleStatus } from '@web-panel/api'
import { Chip, ComponentColor } from '@web-panel/shared'

const statusColorMapping: Record<ScheduleStatus, ComponentColor> = {
  [ScheduleStatus.Cancelled]: 'error',
}

type ScheduleStatusChipProps = {
  status: ScheduleStatus
}

const ScheduleStatusChip: FC<ScheduleStatusChipProps> = ({ status }) => {
  const color = statusColorMapping[status] ?? 'default'
  return <Chip className="min-w-72" color={color} label={status} />
}

export default ScheduleStatusChip
