import { useMutation, useQuery } from '@apollo/client'
import { MutationHook, QueryHook } from '../types'
import { RequestAuthEmailDocument, GetTokensDocument } from '../docs'

type RequestAuthEmailInput = {
  email: string
}

export const useRequestAuthEmail: MutationHook<RequestAuthEmailInput, void> = () => {
  const [execute, { loading }] = useMutation<void>(RequestAuthEmailDocument)

  async function request(input: RequestAuthEmailInput): Promise<void> {
    await execute({
      variables: { input },
      context: {
        isPublic: true,
      },
      fetchPolicy: 'network-only',
    })
  }

  return {
    request,
    loading,
  }
}

type GetTokensInput = {
  authToken: string
}

type GetTokensResponse = {
  accessToken: string
  refreshToken: string
}

export const useGetTokens: QueryHook<GetTokensInput, GetTokensResponse> = (variables) => {
  const { data, loading, refetch } = useQuery<{ tokens: GetTokensResponse }>(GetTokensDocument, {
    context: {
      isPublic: true,
    },
    fetchPolicy: 'network-only',
    variables,
  })

  return {
    data: data?.tokens,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}
