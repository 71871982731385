import { FC } from 'react'
import { AppPage, Button, CardedContent, useNotifications } from '@web-panel/shared'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { FormattedMessage, useIntl } from 'react-intl'
import { BoxRentalConfiguration } from '@web-panel/api'
import { useNavigate, useParams } from 'react-router-dom'
import modelIcons from '@local/model-icons'
import { Formik } from 'formik'
import { Error404 } from '@local/pages/404'
import { useRentalConfiguration, useUpdateRentalConfiguration } from '@web-panel/oc-api'
import {
  UpdateRentalConfigurationForm,
  FormFields,
  validationSchema,
} from './Forms/UpdateRentalConfigurationForm'

type PageComponentProps = {
  configuration: BoxRentalConfiguration
}
const PageComponent: FC<PageComponentProps> = ({ configuration }) => {
  const { formatMessage } = useIntl()
  const { pushNotification } = useNotifications()
  const navigate = useNavigate()
  const { request: updateConfiguration, loading: loadingUpdate } = useUpdateRentalConfiguration()

  const onSubmit = async (values: FormFields) => {
    try {
      await updateConfiguration({
        id: configuration.id,
        input: { ...values, countryCode: configuration.countryCode },
      })
      formatMessage({ id: 'common.notifications.successfully-updated' })
      navigate(-1)
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const initialValues: FormFields = {
    status: configuration.status,
    price: configuration.price,
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, isValid }) => {
        const headerActionButtons = [
          <Button
            loading={loadingUpdate}
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => handleSubmit()}
            disabled={!isValid}
          >
            <FormattedMessage id="common.actions.update" />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            title={formatMessage({ id: 'routes.settings.box-rental-configuration.update' })}
            icon={modelIcons.rentalConfiguration}
            actionButtons={headerActionButtons}
          >
            <CardedContent padded>
              <UpdateRentalConfigurationForm />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export default pageWithRoleAuthorization(
  () => {
    const { id } = useParams<{ id: string }>()
    const { data: configuration, loading } = useRentalConfiguration({ id: id! })

    if (!configuration && loading) return null
    if (!configuration) return <Error404 />

    return <PageComponent configuration={configuration} />
  },
  ({ can }) => can('boxRentalConfiguration', 'preview') && can('boxRentalConfiguration', 'update')
)
