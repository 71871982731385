import { BoxStatus } from '@web-panel/api'
import { Typography } from '@web-panel/shared'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

type BoxActivityStatusChangeExplanationProps = {
  currentStatus: BoxStatus
  previousStatus: BoxStatus
}
export const BoxActivityStatusChangeExplanation: FC<BoxActivityStatusChangeExplanationProps> = ({
  currentStatus,
  previousStatus,
}) => {
  return (
    <Typography variant="body1">
      <FormattedMessage id={`box-statuses.${previousStatus}`} />
      &nbsp;
      {'->'}
      <FormattedMessage id={`box-statuses.${currentStatus}`} />
      &nbsp;
    </Typography>
  )
}
