import { FC } from 'react'
import { Partner, getFullPartnerAddress } from '@web-panel/api'
import { Paper, TableGrid } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import PartnerAvatar from '@local/components/PartnerAvatar'
import { PartnerStatusChip } from '@local/components/PartnerStatusChip'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'

import { useDeletePartner } from '@web-panel/oc-api'

type IndexTableProps = {
  data: Partner[]
  loading: boolean
}

const IndexTable: FC<IndexTableProps> = ({ data, loading }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { can, cannot } = useRoleAuthorization()
  const { request: deletePartner } = useDeletePartner()

  return (
    <Paper className="overflow-hidden">
      <TableGrid
        loading={loading}
        collection={data}
        fields={[
          {
            title: formatMessage({ id: 'models.partner.id' }),
            render: ({ partnerNumber }) => partnerNumber,
          },
          {
            title: formatMessage({ id: 'models.partner.name' }),
            render: (partner) => (
              <div className="flex flex-row items-center">
                <PartnerAvatar className="mr-16" partner={partner} />
                {partner.name}
              </div>
            ),
          },
          {
            title: formatMessage({ id: 'models.partner.address' }),
            render: (partner) => getFullPartnerAddress(partner),
          },
          {
            title: formatMessage({ id: 'models.partner.partner-status' }),
            render: ({ partnerStatus }) => <PartnerStatusChip status={partnerStatus} />,
          },
          {
            title: formatMessage({ id: 'models.partner.test' }),
            render: ({ test }) => <FormattedMessage id={test ? 'common.yes' : 'common.no'} />,
          },
        ]}
        onClickRow={(partner) => navigate(`${partner.id}`)}
        rowClickable={can('partner', 'preview')}
        rowActions={[
          {
            title: formatMessage({ id: 'common.actions.details' }),
            icon: 'visibility',
            onClick: ({ id }) => navigate(`/partners/${id}`),
            disabled: cannot('partner', 'preview'),
          },
          {
            title: formatMessage({ id: 'common.actions.edit' }),
            icon: 'edit',
            onClick: ({ id }) => navigate(`/partners/${id}/edit`),
            disabled: cannot('partner', 'update'),
            mainAction: true,
          },
          {
            title: formatMessage({ id: 'common.actions.delete' }),
            icon: 'delete',
            onClick: ({ id }) => deletePartner({ id }),
            disabled: cannot('partner', 'update'),
            confirmation: (partner) => ({
              title: formatMessage(
                { id: 'confirmation-dialogs.delete-partner' },
                { name: partner.name }
              ),
              acceptTitle: formatMessage({ id: 'common.yes' }),
              cancelTitle: formatMessage({ id: 'common.no' }),
            }),
          },
        ]}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
      />
    </Paper>
  )
}

export default IndexTable
