import { FC } from 'react'
import { AppPage, CardedContent, Button, useNotifications } from '@web-panel/shared'
import { useIntl, FormattedMessage } from 'react-intl'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import modelIcons from '@local/model-icons'
import { useCreateGeoCity } from '@web-panel/oc-api'
import { CreateCityForm, FormFields, validationSchema, defaultValues } from './Forms/CreateCityForm'

export const SettingsCreateCityPage: FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()
  const { request: createCity, loading: loadingCreate } = useCreateGeoCity()

  const onSubmit = async (input: FormFields) => {
    try {
      await createCity({ input })
      navigate(-1)
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-created' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <Formik<FormFields>
      initialValues={{ ...defaultValues }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formProps) => {
        const headerActionButtons = [
          <Button
            fullWidth
            loading={loadingCreate}
            key="save"
            variant="contained"
            color="secondary"
            disabled={!formProps.isValid}
            onClick={() => formProps.handleSubmit()}
          >
            <FormattedMessage id="common.actions.create" />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            icon={modelIcons.settings}
            title={formatMessage({ id: 'routes.settings.cities.create' })}
            actionButtons={headerActionButtons}
          >
            <CardedContent>
              <CreateCityForm {...formProps} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}
