import { FC } from 'react'

import { AppPage, CardedContent, DefaultErrorPage } from '@web-panel/shared'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import modelIcons from '@local/model-icons'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { useDonation } from '@web-panel/oc-api'
import PreviewTable from './Tables/PreviewTable'

const DonationPreviewPage: FC = () => {
  const params = useParams()
  const { formatMessage } = useIntl()
  const { data: donation, loading } = useDonation({ donationId: params.id! })

  if (loading) return null
  if (!donation)
    return (
      <DefaultErrorPage
        title={formatMessage({ id: 'errors.404.title' })}
        message={formatMessage({ id: 'errors.404.message' })}
      />
    )

  return (
    <AppPage
      backButton
      icon={modelIcons.donation}
      title={formatMessage({ id: 'routes.donations-preview' }, { name: donation.externalId })}
    >
      <CardedContent>
        <PreviewTable data={donation} />
      </CardedContent>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(DonationPreviewPage, ({ can }) =>
  can('donation', 'preview')
)
