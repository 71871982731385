import { FC } from 'react'
import { Hidden, TextField, TextFieldProps } from '@mui/material'
import { DesktopDatePicker, MobileDatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

type DatePickerProps = {
  fullWidth?: boolean
  className?: string
  label: string
  value: Date | null
  onChange: (value: Date | null) => void
  minDate?: Date
  maxDate?: Date
  readOnly?: boolean
  shouldDisableWeekend?: boolean
  helperText?: string
  error?: boolean
}

const inputFormat = 'dd.MM.yyyy'
const inputMask = '__.__.____'

export const DatePicker: FC<DatePickerProps> = ({
  className,
  onChange,
  fullWidth,
  readOnly = true,
  helperText,
  error,
  shouldDisableWeekend,
  ...props
}) => {
  function disableWeekends(date: Date) {
    return shouldDisableWeekend ? date.getDay() === 0 || date.getDay() === 6 : false
  }

  return (
    <div className={className}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Hidden lgDown>
          <DesktopDatePicker
            {...props}
            shouldDisableDate={disableWeekends}
            inputFormat={inputFormat}
            mask={inputMask}
            onChange={onChange}
            renderInput={(params: TextFieldProps) => (
              <TextField
                fullWidth={fullWidth}
                {...params}
                inputProps={{ ...params.inputProps, readOnly }}
                helperText={helperText}
                error={error || params.error}
              />
            )}
          />
        </Hidden>
        <Hidden lgUp>
          <MobileDatePicker
            {...props}
            shouldDisableDate={disableWeekends}
            inputFormat={inputFormat}
            mask={inputMask}
            onChange={onChange}
            renderInput={(params: TextFieldProps) => (
              <TextField
                fullWidth={fullWidth}
                {...params}
                inputProps={{ ...params.inputProps, readOnly }}
                helperText={helperText}
                error={error || params.error}
              />
            )}
          />
        </Hidden>
      </LocalizationProvider>
    </div>
  )
}
