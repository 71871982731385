import { FC, useState } from 'react'
import { Button, ModalBody, ModalBodyProps, buildModal } from '@web-panel/shared'
import { Container, ContainerStatus } from '@web-panel/api'
import { ContainerAutocompleteInput } from '@local/components/Autocomplete/ContainerAutocompleteInput'
import { FormattedMessage, useIntl } from 'react-intl'

type ContainerAttachModalProps = ModalBodyProps & {
  alreadyAttachedContainerIds: string[]
  onSubmit: (qrCode: string) => void
}

const ContainerAttachModal: FC<ContainerAttachModalProps> = ({
  onClose,
  alreadyAttachedContainerIds,
  onSubmit,
}) => {
  const { formatMessage } = useIntl()
  const [selectedContainer, setSelectedContainer] = useState<Container | null>(null)

  const handleSubmit = () => {
    if (!selectedContainer) return

    onSubmit(selectedContainer.qrCode)
    onClose()
  }

  const filterAutocompleteOptions = (options: Container[]) => {
    return options.filter(
      ({ id, status }) =>
        (status === ContainerStatus.Ready || status === ContainerStatus.Operations) &&
        !alreadyAttachedContainerIds.includes(id)
    )
  }

  return (
    <ModalBody
      title="Container attach modal"
      actions={[
        <Button key="close-button" onClick={onClose}>
          <FormattedMessage id="common.actions.cancel" />
        </Button>,
        <Button
          key="submit-button"
          disabled={!selectedContainer}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          <FormattedMessage id="common.actions.attach" />
        </Button>,
      ]}
    >
      <div className="pt-5">
        <ContainerAutocompleteInput
          filterOptions={filterAutocompleteOptions}
          label={formatMessage({ id: 'models.container' })}
          onChange={setSelectedContainer}
          value={selectedContainer}
        />
      </div>
    </ModalBody>
  )
}

export default buildModal(ContainerAttachModal, {
  PaperProps: { className: 'overflow-y-visible' },
  fullWidth: true,
  maxWidth: 'md',
})
