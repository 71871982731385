import { FC, useState } from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  GooglePlacesAutocomplete,
  buildModal,
  ModalBodyProps,
  GoogleAutocompletePlace,
} from '@web-panel/shared'
import { Country, City } from '@web-panel/api'
import { FormattedMessage } from 'react-intl'

type GooglePlacesModalProps = ModalBodyProps & {
  country: Country
  city: City
  onSubmit: (place: GoogleAutocompletePlace) => void
}

const GooglePlacesModal: FC<GooglePlacesModalProps> = ({ country, city, onClose, onSubmit }) => {
  const [searchInput, setSearchInput] = useState<string>('')
  const [place, setPlace] = useState<GoogleAutocompletePlace>()

  const handleClose = () => {
    onClose()
  }
  const handleSubmit = () => {
    if (!place) return
    onSubmit(place)
    handleClose()
  }

  return (
    <>
      <DialogTitle>
        <FormattedMessage id="modals.pick-place-from-google-maps" />
      </DialogTitle>
      <DialogContent className="flex flex-col items-center">
        <GooglePlacesAutocomplete
          fullWidth
          inputValue={searchInput}
          country={country.name}
          city={city.name}
          onInputValueChange={setSearchInput}
          onSelect={setPlace}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage id="common.actions.cancel" />
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!place}>
          <FormattedMessage id="common.actions.update" />
        </Button>
      </DialogActions>
    </>
  )
}

export default buildModal(GooglePlacesModal, { fullWidth: true, maxWidth: 'md' })
