import { FC, useState } from 'react'
import {
  FormSection,
  DatePicker,
  Typography,
  Button,
  ButtonGroup,
  InputLabel,
  isFeatureFlagEnabled,
} from '@web-panel/shared'
import { useIntl, FormattedMessage } from 'react-intl'
import { addDays, format as formatDate } from 'date-fns'
import { useFormikContext } from 'formik'

const deliveryDateRange = 7

const deliveryTimeSlots = {
  morning: isFeatureFlagEnabled('neom')
    ? {
        from: '06:30:00',
        to: '09:00:00',
      }
    : {
        from: '08:00:00',
        to: '12:00:00',
      },
  afternoon: {
    from: '14:00:00',
    to: '18:00:00',
  },
}

function formatTimeString(time: string): string {
  return time.split(':').slice(0, 2).join(':')
}

export type DeliveryTimeFormFields = {
  expectedDeliveryDate: Date
  expectedDeliveryTimeFrom: string
  expectedDeliveryTimeTo: string
}

export const defaultDeliveryTimeValues: DeliveryTimeFormFields = {
  expectedDeliveryDate: new Date(),
  expectedDeliveryTimeFrom: deliveryTimeSlots.morning.from,
  expectedDeliveryTimeTo: deliveryTimeSlots.morning.to,
}

export const DeliveryTimeSection: FC = () => {
  const [isEditing, setIsEditing] = useState(false)

  const { formatMessage } = useIntl()
  const { values, setValues, setFieldValue } = useFormikContext<DeliveryTimeFormFields>()

  return (
    <FormSection title={formatMessage({ id: 'models.order.delivery-time' })}>
      <Typography variant="body1">
        <FormattedMessage
          id="labels.order-delivery-from-to"
          values={{
            date: formatDate(values.expectedDeliveryDate, 'dd.MM.yyyy'),
            fromTime: formatTimeString(values.expectedDeliveryTimeFrom),
            toTime: formatTimeString(values.expectedDeliveryTimeTo),
          }}
        />
      </Typography>
      {!isEditing ? (
        <Button className="mt-8" variant="contained" onClick={() => setIsEditing(true)}>
          <FormattedMessage id="labels.set-delivery-time" />
        </Button>
      ) : (
        <>
          <DatePicker
            className="mt-16"
            label={formatMessage({ id: 'models.order.delivery-date' })}
            value={values.expectedDeliveryDate}
            onChange={(val) => setFieldValue('expectedDeliveryDate', val)}
            minDate={addDays(new Date(), 1)}
            maxDate={addDays(new Date(), 1 + deliveryDateRange)}
          />
          <InputLabel className="mt-16" shrink>
            <FormattedMessage id="models.order.delivery-time" />
          </InputLabel>
          <ButtonGroup>
            <Button
              variant="contained"
              color={
                values.expectedDeliveryTimeFrom === deliveryTimeSlots.morning.from
                  ? 'secondary'
                  : 'primary'
              }
              onClick={() =>
                setValues({
                  ...values,
                  expectedDeliveryTimeFrom: deliveryTimeSlots.morning.from,
                  expectedDeliveryTimeTo: deliveryTimeSlots.morning.to,
                })
              }
            >
              {[
                formatTimeString(deliveryTimeSlots.morning.from),
                formatTimeString(deliveryTimeSlots.morning.to),
              ].join(' - ')}
            </Button>
            <Button
              variant="contained"
              color={
                values.expectedDeliveryTimeFrom === deliveryTimeSlots.afternoon.from
                  ? 'secondary'
                  : 'primary'
              }
              onClick={() =>
                setValues({
                  ...values,
                  expectedDeliveryTimeFrom: deliveryTimeSlots.afternoon.from,
                  expectedDeliveryTimeTo: deliveryTimeSlots.afternoon.to,
                })
              }
            >
              {[
                formatTimeString(deliveryTimeSlots.afternoon.from),
                formatTimeString(deliveryTimeSlots.afternoon.to),
              ].join(' - ')}
            </Button>
          </ButtonGroup>
        </>
      )}
    </FormSection>
  )
}
