import { FC } from 'react'
import { HorizontalTableRow } from '@web-panel/shared'
import { locationAddressString } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { OutletLink } from '@local/components/OutletLink'

import { useOutlet } from '@web-panel/oc-api'

type PreviewTableOutletFieldsProps = {
  outletId: string
}
export const PreviewTableOutletFields: FC<PreviewTableOutletFieldsProps> = ({ outletId }) => {
  const { formatMessage } = useIntl()
  const { data: outlet } = useOutlet({ id: outletId })
  const outletAddress = outlet && outlet.location ? locationAddressString(outlet.location) : ''

  return (
    <>
      <HorizontalTableRow title={formatMessage({ id: 'models.order.outlet' })}>
        <OutletLink outletId={outletId} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.order.outlet.city' })}>
        {outlet?.location.city.name}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.order.outlet.address' })}>
        {outletAddress}
      </HorizontalTableRow>
    </>
  )
}
