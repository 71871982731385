import { gql } from '@apollo/client'
import { BoxRental, boxRentalFields, RentalStatus } from '@web-panel/api'

export type BoxRentalsDocumentInput = {
  filters: {
    partnerId?: string
  }
  pagination: {
    offset?: number
    limit?: number
  }
}
export type BoxRentalsDocumentResponse = {
  boxRentals: {
    inventories: BoxRental[]
    hasNext: boolean
  }
}
export const BoxRentalsDocument = gql`
  query BoxRentals($filters: any, $pagination: any) {
    boxRentals(filters: $filters, pagination: $pagination)
      @rest(
        type: "BoxRentalsCollection",
        path: "/partners-service/inventory/boxes/rentals?{args.filters}&{args.pagination}",
        method: "GET"
      ) {
      inventories @type(name: "BoxRental") {
        ${boxRentalFields}
      }
      hasNext
    }
  }
`

export type BoxRentalDocumentInput = {
  id: string
}
export type BoxRentalDocumentResponse = {
  boxRental: BoxRental
}
export const BoxRentalDocument = gql`
  query BoxRental($id: String!) {
    boxRental(id: $id) @rest(type: "BoxRental", path: "/partners-service/inventory/boxes/rentals/{args.id}", method: "GET") {
      ${boxRentalFields}
    }
  }
`

export type CreateBoxRentalDocumentInput = {
  input: {
    partnerId: string
    outletId: string | null
    startDate: string
    endDate: string | null
    amount: number
  }
}
export type CreateBoxRentalDocumentResponse = {
  createBoxRental: BoxRental
}
export const CreateBoxRentalDocument = gql`
  mutation CreateBoxRental($input: any) {
    createBoxRental(input: $input) @rest(type: "BoxRental", path: "/partners-service/inventory/boxes/rentals", method: "POST") {
      ${boxRentalFields}
    }
  }
`

export type UpdateBoxRentalDocumentInput = {
  id: string
  input: {
    status: RentalStatus
    startDate: string
    endDate: string | null
    amount: number
  }
}
export type UpdateBoxRentalDocumentResponse = {
  updateBoxRental: BoxRental
}
export const UpdateBoxRentalDocument = gql`
  mutation UpdateBoxRental($id: String!, $input: any) {
    updateBoxRental(id: $id, input: $input) @rest(type: "BoxRental", path: "/partners-service/inventory/boxes/rentals/{args.id}", method: "PUT") {
      ${boxRentalFields}
    }
  }
`
