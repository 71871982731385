import { VFC } from 'react'
import { TextField, LabeledSelect } from '@web-panel/shared'
import { GeoCountry } from '@web-panel/api'
import { currencies } from '@local/constants/currencies'
import { useFormikContext } from 'formik'
import * as yup from 'yup'
import { useIntl } from 'react-intl'

export type FormFields = {
  amount: number
  currencyCode: string
  countryCode: string
}

export const validationSchema = yup.object().shape({
  amount: yup.number().required(),
  currencyCode: yup.string().required(),
  countryCode: yup.string().required(),
})

type TopUpWalletFormProps = {
  countries: GeoCountry[]
}
export const TopUpWalletForm: VFC<TopUpWalletFormProps> = ({ countries }) => {
  const { formatMessage } = useIntl()
  const { values, errors, setFieldValue, handleChange, handleSubmit } =
    useFormikContext<FormFields>()

  return (
    <form onSubmit={handleSubmit}>
      {currencies.length > 1 && (
        <LabeledSelect
          fullWidth
          label={formatMessage({ id: 'models.wallet.currency' })}
          value={values.currencyCode}
          options={currencies}
          error={!!errors.currencyCode}
          onChange={(e) => setFieldValue('currencyCode', e.target.value)}
        />
      )}
      {countries.length > 1 && (
        <LabeledSelect
          fullWidth
          className="mt-16"
          label={formatMessage({ id: 'models.wallet.country' })}
          value={values.countryCode}
          options={countries.map(({ code }) => code)}
          optionTitle={(code) => countries.find(({ code: c }) => c === code)?.name ?? ''}
          error={!!errors.countryCode}
          onChange={(e) => setFieldValue('countryCode', e.target.value)}
        />
      )}
      <TextField
        fullWidth
        className="mt-16"
        label={formatMessage({ id: 'models.wallet.amount' })}
        value={values.amount}
        error={!!errors.amount}
        onChange={handleChange('amount')}
      />
    </form>
  )
}
