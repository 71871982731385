import { useQuery, useMutation } from '@apollo/client'
import { UserRole, MutationHook, QueryHook, CollectionQueryHook } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  ConsumersDocument,
  ConsumersDocumentInput,
  ConsumersDocumentResponse,
  ConsumerDocument,
  ConsumerDocumentInput,
  ConsumerDocumentResponse,
  ConsumerInfoDocument,
  ConsumerInfoDocumentInput,
  ConsumerInfoDocumentResponse,
  UpdateConsumerWalletDocument,
  UpdateConsumerWalletDocumentInput,
  UpdateConsumerWalletDocumentResponse,
  TopUpConsumerWalletDocument,
  TopUpConsumerWalletDocumentInput,
  TopUpConsumerWalletDocumentResponse,
} from '../docs'

type UseUsersInput = Omit<ConsumersDocumentInput, 'limit' | 'offset'>
export const useConsumers: CollectionQueryHook<
  UseUsersInput,
  ConsumersDocumentResponse['consumersCollection']['users']
> = (input, options) => {
  const variables = { ...input, ...defaultCollectionVariables }

  const { data, loading, fetchMore, refetch } = useQuery<ConsumersDocumentResponse>(
    ConsumersDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.consumersCollection.hasNext) return

    const offset = data.consumersCollection.users.length
    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.consumersCollection.users,
    loading,
    loadMore,
    hasMore: data?.consumersCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useConsumer: QueryHook<ConsumerDocumentInput, ConsumerDocumentResponse['consumer']> = (
  variables
) => {
  const { data, loading, refetch } = useQuery<ConsumerDocumentResponse>(ConsumerDocument, {
    variables,
  })

  const ensuredConsumer = data?.consumer.role === UserRole.Consumer ? data.consumer : undefined

  return {
    data: ensuredConsumer,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useConsumerInfo: QueryHook<
  ConsumerInfoDocumentInput,
  ConsumerInfoDocumentResponse['consumerInfo']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<ConsumerInfoDocumentResponse>(ConsumerInfoDocument, {
    variables,
    ...options,
  })

  return {
    data: data?.consumerInfo,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useUpdateConsumerWallet: MutationHook<
  UpdateConsumerWalletDocumentInput,
  UpdateConsumerWalletDocumentResponse['updateConsumerWallet']
> = () => {
  const [execute, { loading }] = useMutation<UpdateConsumerWalletDocumentResponse>(
    UpdateConsumerWalletDocument
  )

  const request = async (variables: UpdateConsumerWalletDocumentInput) => {
    const { data } = await execute({ variables })
    if (!data) throw new Error('Could not update consumer wallet')
    return data?.updateConsumerWallet
  }

  return {
    request,
    loading,
  }
}

export const useTopUpConsumerWallet: MutationHook<
  TopUpConsumerWalletDocumentInput,
  TopUpConsumerWalletDocumentResponse['topUpConsumerWallet']
> = () => {
  const [execute, { loading }] = useMutation<TopUpConsumerWalletDocumentResponse>(
    TopUpConsumerWalletDocument
  )

  const request = async (variables: TopUpConsumerWalletDocumentInput) => {
    const { data } = await execute({ variables })
    if (!data) throw new Error('Could not top up consumer wallet')
    return data?.topUpConsumerWallet
  }

  return {
    request,
    loading,
  }
}
