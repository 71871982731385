import { FC } from 'react'
import { FormSection, RadioGroup, Radio, FormControlLabel, TextField } from '@web-panel/shared'
import { OutletLegalAddress, Partner, getOutletLegalAddressFromPartner } from '@web-panel/api'
import { useIntl } from 'react-intl'
import CountryCodeAutocomplete from '@local/components/Autocomplete/CountryCodeAutocomplete'
import { useFormikContext } from 'formik'
import * as Yup from 'yup'

type InvoiceType = 'outlet' | 'partner'

export type InvoiceSectionFormFields = {
  legalAddress: OutletLegalAddress | null
}

export const invoiceSectionInitialValues: InvoiceSectionFormFields = {
  legalAddress: null,
}

export const invoiceValidationSchema = Yup.object()
  .shape({
    name: Yup.string().required(),
    countryCode: Yup.string().required(),
    city: Yup.string().required(),
    street: Yup.string().required(),
    houseNumber: Yup.string().required(),
    postalCode: Yup.string().required(),
  })
  .nullable()

type LegalAddressSectionProps = {
  partner: Partner
}

export const LegalAddressSection: FC<LegalAddressSectionProps> = ({ partner }) => {
  const { formatMessage } = useIntl()
  const { values, errors, handleChange, setFieldValue } =
    useFormikContext<InvoiceSectionFormFields>()

  const partnerLegalAddress = getOutletLegalAddressFromPartner(partner)
  const legalAddress = values.legalAddress ?? partnerLegalAddress

  const invoiceType: InvoiceType = values.legalAddress ? 'outlet' : 'partner'
  const disabled = !values.legalAddress

  const handleChangeInvoiceType = (type: InvoiceType) => {
    setFieldValue('legalAddress', type === 'outlet' ? partnerLegalAddress : null)
  }

  return (
    <FormSection title={formatMessage({ id: 'models.outlet.legal-address' })}>
      <RadioGroup
        value={invoiceType}
        onChange={(e) => handleChangeInvoiceType(e.target.value as InvoiceType)}
      >
        <FormControlLabel
          value="partner"
          control={<Radio />}
          label={formatMessage({ id: 'labels.outlet.use-partner-invoice' })}
        />
        <FormControlLabel
          value="outlet"
          control={<Radio />}
          label={formatMessage({ id: 'labels.outlet.use-outlet-invoice' })}
        />
      </RadioGroup>
      <TextField
        fullWidth
        className="mt-16"
        label={formatMessage({ id: 'models.outlet.legal-address.name' })}
        value={legalAddress?.name ?? ''}
        onChange={handleChange('legalAddress.name')}
        disabled={disabled}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={!!errors?.legalAddress?.name}
      />
      <CountryCodeAutocomplete
        fullWidth
        className="mt-16"
        label={formatMessage({ id: 'models.outlet.legal-address.country' })}
        value={legalAddress?.countryCode ?? ''}
        onChange={(country) => setFieldValue('legalAddress.countryCode', country)}
        disabled={disabled}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={!!errors?.legalAddress?.countryCode}
      />
      <TextField
        fullWidth
        className="mt-16"
        label={formatMessage({ id: 'models.outlet.legal-address.city' })}
        value={legalAddress?.city ?? ''}
        onChange={handleChange('legalAddress.city')}
        disabled={disabled}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={!!errors?.legalAddress?.city}
      />
      <TextField
        fullWidth
        className="mt-16"
        label={formatMessage({ id: 'models.outlet.legal-address.street' })}
        value={legalAddress?.street ?? ''}
        onChange={handleChange('legalAddress.street')}
        disabled={disabled}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={!!errors?.legalAddress?.street}
      />
      <TextField
        fullWidth
        className="mt-16"
        label={formatMessage({ id: 'models.outlet.legal-address.house-number' })}
        value={legalAddress?.houseNumber ?? ''}
        onChange={handleChange('legalAddress.houseNumber')}
        disabled={disabled}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={!!errors?.legalAddress?.houseNumber}
      />
      <TextField
        fullWidth
        className="mt-16"
        label={formatMessage({ id: 'models.outlet.legal-address.postal-code' })}
        value={legalAddress?.postalCode ?? ''}
        onChange={handleChange('legalAddress.postalCode')}
        disabled={disabled}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={!!errors?.legalAddress?.postalCode}
      />
      <TextField
        fullWidth
        className="mt-16"
        label={formatMessage({ id: 'models.outlet.legal-address.additional-address-line-1' })}
        value={legalAddress?.legalAdditionalAddressLines[0] ?? ''}
        onChange={handleChange('legalAddress.legalAdditionalAddressLines[0]')}
        disabled={disabled}
      />
      <TextField
        fullWidth
        className="mt-16"
        label={formatMessage({ id: 'models.outlet.legal-address.additional-address-line-2' })}
        value={legalAddress?.legalAdditionalAddressLines[1] ?? ''}
        onChange={handleChange('legalAddress.legalAdditionalAddressLines[1]')}
        disabled={disabled}
      />
    </FormSection>
  )
}
