import { gql } from '@apollo/client'
import { Tracking, trackingFields } from '@web-panel/api'

export type TrackingDocumentInput = {
  labelId?: string
}

export type TrackingDocumentResponse = {
  trackingHistory: {
    trackingDetails: Tracking[]
    hasNext: boolean
  }
}

export const TrackingDocument = gql`
  query TrackingHistory($limit: Int, $offset: Int, $labelId: String!) {
    trackingHistory(limit: $limit, offset: $offset, labelId :$labelId) @rest(type: "TrackingHistoryCollection", path: "/shipment-service/labels/{args.labelId}/tracking") {
      trackingDetails @type(name: "TrackingHistory") {
        ${trackingFields}
      }
      hasNext
    }
  }
`
