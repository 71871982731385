import { FC } from 'react'

import { TableGrid, InfinitScroller, useFormatDate, FormattedCurrency } from '@web-panel/shared'
import { useIntl, FormattedMessage } from 'react-intl'
import { OutletLink } from '@local/components/OutletLink'
import { useInvoices } from '@web-panel/oc-api'
import { ChangeInvoiceStatusButton } from '../Components/ChangeInvoiceStatusButton'

type InvoicesTabProps = {
  partnerId: string
}

const InvoicesTab: FC<InvoicesTabProps> = ({ partnerId }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const {
    data: invoices = [],
    loading,
    loadMore,
  } = useInvoices(
    { partnerId },
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    }
  )

  return (
    <InfinitScroller onLoadMore={loadMore}>
      <TableGrid
        loading={loading}
        collection={invoices}
        fields={[
          {
            title: formatMessage({ id: 'models.invoice.id' }),
            render: ({ externalId }) => externalId,
          },
          {
            title: formatMessage({ id: 'models.invoice.frequency' }),
            render: ({ frequency }) =>
              formatMessage({ id: `order-invoice-frequency.${frequency}` }),
          },
          {
            title: formatMessage({ id: 'models.invoice.outlet' }),
            render: ({ outletId }) => (outletId ? <OutletLink outletId={outletId} /> : '-'),
          },
          {
            title: formatMessage({ id: 'models.invoice.total-cost' }),
            render: ({ totalCost, currencyCode }) => (
              <FormattedCurrency value={totalCost} currency={currencyCode} />
            ),
          },
          {
            title: formatMessage({ id: 'models.invoice.invoice-pdf' }),
            render: ({ id, invoicePdf }) => (
              <a target={`invoice-${id}`} href={invoicePdf} rel="noreferrer">
                <FormattedMessage id="common.actions.download" />
              </a>
            ),
          },
          {
            title: formatMessage({ id: 'models.invoice.invoice-date' }),
            render: ({ invoiceDate }) => formatDate('date', invoiceDate),
          },
          {
            title: formatMessage({ id: 'models.invoice.status' }),
            render: (invoice) => <ChangeInvoiceStatusButton invoice={invoice} />,
          },
        ]}
      />
    </InfinitScroller>
  )
}

export default InvoicesTab
