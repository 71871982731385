import { FC, useState } from 'react'
import { Outlet, Partner } from '@web-panel/api'
import { useIntl } from 'react-intl'
import happyFaceIcon from '@local/assets/icons/happy-face.svg'
import repeatIcon from '@local/assets/icons/repeat.svg'
import { OutletsDropdown } from '@local/pages/Partners/Components/OutletsDropdown'
import { useFormatDate } from '@web-panel/shared'
import {
  useOutlets,
  useAnalyticsTotalOrders,
  useAnalyticsTotalItemsSummary,
  useAnalyticsTotalItemsNotReturnedSummary,
} from '@web-panel/oc-api'
import { TotalWidget } from './Components/TotalWidget'
import { NotReturnedWidget } from './Components/NotReturnedWidget'
import { MonthSelector } from './Components/MonthSelector'

type DashboardTabProps = {
  partner: Partner
}
export const FinmaDashboard: FC<DashboardTabProps> = ({ partner }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const [selectedOutlets, setSelectedOutlets] = useState<Outlet[]>([])
  const [selectedMonth, setSelectedMonth] = useState<string | undefined>(
    formatDate('month-year', new Date())
  )

  const partnerId = partner.id
  const { data: outlets = [] } = useOutlets({ partnerId })
  const chosenOutlets = selectedOutlets.length > 0 ? selectedOutlets : outlets
  const outletIds = chosenOutlets.map((outlet) => outlet.id)

  const { data: totalOrdersAnalytic } = useAnalyticsTotalOrders({
    partnerId,
    outletIds,
    monthYearDate: selectedMonth,
  })
  const { data: totalItems } = useAnalyticsTotalItemsSummary({
    partnerId,
    outletIds,
    monthYearDate: selectedMonth,
  })
  const { data: notReturnedSummary } = useAnalyticsTotalItemsNotReturnedSummary({
    partnerId,
    outletIds,
  })

  return (
    <div className="w-full px-16 md:px-0 py-16 md:py-0">
      <div className="mb-16 flex justify-end">
        <OutletsDropdown
          outlets={outlets}
          checked={selectedOutlets}
          onChange={setSelectedOutlets}
        />
      </div>
      <div className="flex flex-col md:flex-row gap-46">
        <div className="flex-1">
          <div className="flex flex-row justify-end mb-14">
            <MonthSelector
              start={new Date(partner.createDate)}
              value={selectedMonth}
              onChange={setSelectedMonth}
            />
          </div>
          <div className="grid md:grid-cols-3 gap-46 h-auto">
            <div>
              <TotalWidget
                color="#65D0EA"
                value={totalOrdersAnalytic?.totalOrders ?? 0}
                title={formatMessage({ id: 'pages.finma-dashboard.total-orders' })}
              />
            </div>
            <div>
              <TotalWidget
                color="#FF9800"
                value={totalItems?.totalDeliveredCount ?? 0}
                title={formatMessage({ id: 'pages.finma-dashboard.cups-delivered' })}
                icon={happyFaceIcon}
              />
            </div>
            <div>
              <TotalWidget
                color="#4CAF50"
                value={totalItems?.totalReturnedCount ?? 0}
                title={formatMessage({ id: 'pages.finma-dashboard.cups-returned' })}
                icon={repeatIcon}
              />
            </div>
          </div>
        </div>
        <div className="md:w-224">
          <NotReturnedWidget
            from30to60={notReturnedSummary?.totalNotReturnedFrom30To60Days ?? 0}
            from60to90={notReturnedSummary?.totalNotReturnedFrom60To90Days ?? 0}
            from90to120={notReturnedSummary?.totalNotReturnedFrom90To120Days ?? 0}
            moreThan120={notReturnedSummary?.totalNotReturnedIn120Days ?? 0}
            total={notReturnedSummary?.totalNotReturned ?? 0}
          />
        </div>
      </div>
    </div>
  )
}
