import { FC, useState } from 'react'
import {
  Button,
  ModalBody,
  Typography,
  PinDrop,
  buildModal,
  ModalBodyProps,
} from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'

type PinDropModalProps = ModalBodyProps & {
  lat: number
  lng: number
  onUpdate?: (coordinates: { lat: number; lng: number }) => void
}

const PinDropModal: FC<PinDropModalProps> = ({ lat, lng, onClose, onUpdate }) => {
  const [coordinates, setCoordinates] = useState({ lat, lng })
  const { formatMessage } = useIntl()

  const handleUpdate = () => {
    if (onUpdate) onUpdate(coordinates)
    onClose()
  }

  return (
    <ModalBody
      title={formatMessage({ id: 'modals.pin-drop-modal.title' })}
      actions={[
        <Button key="close-button" onClick={onClose}>
          <FormattedMessage id="common.actions.cancel" />
        </Button>,
        <Button key="submit-button" variant="contained" color="primary" onClick={handleUpdate}>
          <FormattedMessage id="common.actions.update" />
        </Button>,
      ]}
    >
      <div className="flex flex-col items-center">
        <PinDrop
          mapInitialCoordinates={{ lat, lng }}
          markerCoordinates={coordinates}
          onChange={(updatedCoordinates) => setCoordinates(updatedCoordinates)}
        />
        <div className="flex flex-row justify-start w-full mt-16">
          <Typography variant="body1">
            <FormattedMessage id="models.location.latitude" />: {coordinates.lat}
          </Typography>
          <Typography variant="body1" className="ml-8">
            <FormattedMessage id="models.location.longitude" />: {coordinates.lng}
          </Typography>
        </div>
      </div>
    </ModalBody>
  )
}

export default buildModal(PinDropModal, { fullWidth: true, maxWidth: 'md' })
