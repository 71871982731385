import { FC } from 'react'
import { ProductItem, Batch } from '@web-panel/api'
import { Paper, TableGrid } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import ProductItemStatusChip from '@local/components/ProductItemStatusChip'
import ProductItemBatchLink from '@local/components/ProductItemBatchLink'
import { ProductItemHolderReference } from '@local/components/ProductItemHolderReference'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useSorting } from '../sorting'

type ProductItemWithBatch = ProductItem & { batch: Batch | null }
type IndexTableProps = {
  data: ProductItemWithBatch[]
  loading: boolean
}

const IndexTable: FC<IndexTableProps> = ({ data, loading }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { can, cannot } = useRoleAuthorization()
  const { field, direction, handleToggleSort } = useSorting()

  return (
    <Paper className="overflow-hidden">
      <TableGrid
        loading={loading}
        collection={data}
        fields={[
          {
            title: formatMessage({ id: 'models.product-item.qr-code' }),
            render: ({ qrCode }) => qrCode,
            sorting: {
              active: field === 'QR_CODE',
              direction,
              onToggle: handleToggleSort('QR_CODE'),
            },
          },
          {
            title: formatMessage({ id: 'models.product-item.rfid' }),
            render: ({ rfid }) => rfid,
          },
          {
            title: formatMessage({ id: 'models.product-item.batch' }),
            render: ({ batch }) => batch && <ProductItemBatchLink batch={batch} />,
          },
          {
            title: formatMessage({ id: 'models.product-item.brand' }),
            render: ({ brand }) => brand,
          },
          {
            title: formatMessage({ id: 'models.product-item.status' }),
            render: ({ status }) => <ProductItemStatusChip status={status} />,
            align: 'center',
            sorting: {
              active: field === 'STATUS',
              direction,
              onToggle: handleToggleSort('STATUS'),
            },
          },
          {
            title: formatMessage({ id: 'models.product-item.current-holder-type' }),
            render: ({ currentHolderId, currentHolderType }) => (
              <ProductItemHolderReference id={currentHolderId} holderType={currentHolderType} />
            ),
          },
          {
            title: formatMessage({ id: 'models.product-item.capacity' }),
            render: ({ model }) => `${model.capacityValue} ${model.capacityUnit}`,
          },
        ]}
        onClickRow={(productItem) => navigate(`/product-items/${productItem.id}`)}
        rowClickable={can('productItem', 'preview')}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        rowActions={[
          {
            title: formatMessage({ id: 'common.actions.edit' }),
            icon: 'edit',
            onClick: ({ id }) => navigate(`/product-items/${id}/edit`),
            disabled: cannot('productItem', 'update'),
            mainAction: true,
          },
        ]}
      />
    </Paper>
  )
}

export default IndexTable
