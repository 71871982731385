import { FC } from 'react'
import { TextField, LabeledSwitch } from '@web-panel/shared'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import { useIntl } from 'react-intl'

export type FormFields = {
  cost: number
  enabled: boolean
}

export const validationSchema = Yup.object().shape({
  cost: Yup.number().min(0).required(),
  enabled: Yup.boolean().required(),
})

type EditConsumableFormProps = FormikProps<FormFields>
export const EditConsumableForm: FC<EditConsumableFormProps> = ({
  values,
  errors,
  handleChange,
  handleSubmit,
}) => {
  const { formatMessage } = useIntl()

  return (
    <form className="w-full" onSubmit={handleSubmit}>
      <div className="flex flex-col items-left p-16 sm:p-24 max-w-2xl">
        <TextField
          name="cost"
          label={formatMessage({ id: 'models.consumable-product-item.cost' })}
          onChange={handleChange('cost')}
          value={values.cost}
          error={!!errors.cost}
        />
        <LabeledSwitch
          className="mt-16"
          label={formatMessage({ id: 'models.consumable-product-item.enabled' })}
          checked={values.enabled}
          onChange={handleChange('enabled')}
        />
      </div>
    </form>
  )
}
