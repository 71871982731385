import { FC, useRef } from 'react'
import { Button } from '@web-panel/shared'
import { PhysicalLabelPrinter, PhysicalLabelPrinterProps, PhysicalLabelPrinterRef } from './Printer'

type PhysicalLabelButtonProps = {
  printer: PhysicalLabelPrinterProps
  title: string
}

export const PhysicalLabelButton: FC<PhysicalLabelButtonProps> = ({ title, printer }) => {
  const labelRef = useRef<PhysicalLabelPrinterRef>(null)

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        onClick={() => labelRef.current?.print()}
      >
        {title}
      </Button>
      <div className="hidden">
        <PhysicalLabelPrinter ref={labelRef} {...printer} />
      </div>
    </>
  )
}
