import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import modelIcons from '@local/model-icons'
import { Outlet } from '@web-panel/api'
import { AppPage, Button, CardedContent, useNotifications } from '@web-panel/shared'
import { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  useOutlet,
  useOneTimeContractSetting,
  useUpdateOneTimeContractSetting,
} from '@web-panel/oc-api'
import { Error404 } from '../404'
import {
  FormFields,
  UpdateOneTimeContractSettingForm,
  validationSchema,
} from './Forms/UpdateOneTimeContractSettingForm'

type UpdateOneTimeContractSettingPageProps = {
  outlet: Outlet
}

const UpdateOneTimeContractSettingPage: FC<UpdateOneTimeContractSettingPageProps> = ({
  outlet,
}) => {
  const params = useParams()
  const { pushNotification } = useNotifications()
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { data: oneTimeSetting, loading: loadingOneTimeSetting } = useOneTimeContractSetting({
    partnerId: outlet.partnerId,
    outletId: outlet.id,
    contractSettingId: params.contractSettingId!,
  })
  const { request: updateOneTimeSetting, loading: loadingUpdate } =
    useUpdateOneTimeContractSetting()

  if (!oneTimeSetting || loadingOneTimeSetting) return null
  if (!oneTimeSetting) return <Error404 />

  const initialValues: FormFields = {
    name: oneTimeSetting.name,
    description: oneTimeSetting.description,
    amount: oneTimeSetting.amount,
    price: oneTimeSetting.price,
  }

  const onSubmit = async (values: FormFields) => {
    try {
      await updateOneTimeSetting({
        partnerId: outlet.partnerId,
        outletId: outlet.id,
        contractSettingId: oneTimeSetting.id,
        input: {
          price: values.price,
          amount: values.amount,
          name: values.name,
          currencyCode: oneTimeSetting.currency.code,
          description: values.description,
          settingType: oneTimeSetting.settingType,
        },
      })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
      navigate(-1)
    } catch (e) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ isValid, handleSubmit }) => (
        <AppPage
          backButton
          icon={modelIcons.contractPackage}
          title={oneTimeSetting.name}
          actionButtons={[
            <Button
              disabled={!isValid}
              loading={loadingUpdate}
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => handleSubmit()}
            >
              <FormattedMessage id="common.actions.update" />
            </Button>,
          ]}
        >
          <CardedContent>
            <UpdateOneTimeContractSettingForm />
          </CardedContent>
        </AppPage>
      )}
    </Formik>
  )
}

export default pageWithRoleAuthorization(
  () => {
    const params = useParams()
    const { data: outlet, loading: loadingOutlet } = useOutlet({ id: params.id! })

    if (loadingOutlet && !outlet) return null
    if (!outlet) return <Error404 />

    return <UpdateOneTimeContractSettingPage outlet={outlet} />
  },
  ({ can }) => can('oneTimeContractSetting', 'update')
)
