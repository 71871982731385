import { FC, useState, useRef } from 'react'
import {
  buildModal,
  ModalBodyProps,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FileLoadInput,
  Typography,
  ImageCropper,
  useNotifications,
  ImageCropperRef,
} from '@web-panel/shared'

import { FormattedMessage, useIntl } from 'react-intl'

import { useUpdateOutletLogo } from '@web-panel/oc-api'

type UpdateOutletLogoModalProps = ModalBodyProps & { outletId: string }
const UpdateOutletLogoModal: FC<UpdateOutletLogoModalProps> = ({ outletId, onClose }) => {
  const { formatMessage } = useIntl()
  const { pushNotification } = useNotifications()
  const cropperRef = useRef<ImageCropperRef>(null)
  const [logo, setLogo] = useState<string>()
  const [fileName, setFileName] = useState<string>()
  const { request: updateLogo, loading: updateLogoLoading } = useUpdateOutletLogo()

  const handleSubmit = async () => {
    if (!cropperRef.current) return

    try {
      const logoFile = await cropperRef.current.getCroppedAsFile(fileName)
      await updateLogo({ id: outletId, input: { logo: logoFile } })
      onClose()
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const onFileLoad = (files: FileList) => {
    const file = files[0]
    setFileName(file.name)

    const reader = new FileReader()
    reader.addEventListener('loadend', async (event) => {
      const data = (event.target?.result ?? '') as string
      setLogo(data)
    })
    reader.readAsDataURL(file)
  }

  return (
    <>
      <DialogTitle>
        <FormattedMessage id="modals.load-new-logo.title" />
      </DialogTitle>
      <DialogContent>
        <div className="flex justify-center">
          {!logo ? (
            <div className="flex flex-col items-center">
              <FileLoadInput onLoad={onFileLoad} accept="image/jpg" />
              <Typography className="mt-16">
                <FormattedMessage id="common.labels.file-picker-hint" />
              </Typography>
            </div>
          ) : (
            <ImageCropper ref={cropperRef} src={logo} />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="common.actions.cancel" />
        </Button>
        <Button
          loading={updateLogoLoading}
          variant="contained"
          color="secondary"
          disabled={!logo}
          onClick={() => handleSubmit()}
        >
          <FormattedMessage id="common.actions.update" />
        </Button>
      </DialogActions>
    </>
  )
}

export default buildModal(UpdateOutletLogoModal, { fullWidth: true })
