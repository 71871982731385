import { FC } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  DefaultErrorPage,
  AppPage,
  CardedContent,
  ConfirmableButton,
  Button,
  TabSections,
  useNotifications,
  isFeatureFlagDisabled,
  isFeatureFlagEnabled,
} from '@web-panel/shared'
import modelIcons from '@local/model-icons'
import _compact from 'lodash/compact'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useConsumer, useDeleteUser } from '@web-panel/oc-api'
import { GeneralTab } from './Tabs/General'
import ProductItemsTab from './Tabs/ProductItems'
import PaymentOutsTab from './Tabs/PaymentOuts'
import FinancialTab from './Tabs/Financial'
import DonationsTab from './Tabs/Donations'

const PreviewPage: FC = () => {
  const { formatMessage } = useIntl()
  const { id } = useParams()
  const navigate = useNavigate()
  const { data: consumer, loading } = useConsumer(
    { id: id ?? '' },
    { fetchPolicy: 'cache-and-network' }
  )
  const { request: deleteUser, loading: loadingDeleteUser } = useDeleteUser()
  const { pushNotification } = useNotifications()
  const { can } = useRoleAuthorization()

  if (loading) return null
  if (!consumer)
    return (
      <DefaultErrorPage
        title={formatMessage({ id: 'errors.404.title' })}
        message={formatMessage({ id: 'errors.404.message' })}
      />
    )

  const handleDelete = async () => {
    try {
      await deleteUser({ id: consumer.id })
      navigate(-1)
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-deleted' })
      )
    } catch (e) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const headerActionButtons = _compact([
    isFeatureFlagEnabled('neom') &&
      can(
        'consumerAccount',
        'updateWallet',
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          onClick={() => navigate(`/app-users/${id}/update-wallet`)}
        >
          <FormattedMessage id="actions.update-wallet" />
        </Button>
      ),
    can(
      'consumerAccount',
      'topUpWallet',
      <Button
        fullWidth
        color="secondary"
        variant="contained"
        onClick={() => navigate(`/app-users/${id}/top-up`)}
      >
        <FormattedMessage id="actions.top-up-wallet" />
      </Button>
    ),
    can(
      'consumerAccount',
      'delete',
      <ConfirmableButton
        fullWidth
        variant="contained"
        color="error"
        loading={loadingDeleteUser}
        confirmation={{
          title: formatMessage({ id: 'confirmation-dialogs.delete-app-user.title' }),
          acceptTitle: formatMessage({ id: 'common.yes' }),
          cancelTitle: formatMessage({ id: 'common.no' }),
        }}
        onClick={handleDelete}
      >
        <FormattedMessage
          id="common.actions.delete-with-name"
          values={{ name: formatMessage({ id: 'activity-trigger-types.CONSUMER' }) }}
        />
      </ConfirmableButton>
    ),
  ])

  return (
    <AppPage
      backButton
      icon={modelIcons.appUser}
      title={formatMessage({ id: 'routes.app-user-preview' }, { name: consumer.userNumber })}
      actionButtons={headerActionButtons}
    >
      <CardedContent>
        <TabSections
          sections={_compact([
            {
              key: 'general',
              title: formatMessage({ id: 'pages.app-user-preview.tabs.general' }),
              Content: () => <GeneralTab consumer={consumer} />,
            },
            {
              key: 'financial',
              title: formatMessage({ id: 'pages.app-user-preview.tabs.financial' }),
              Content: () => <FinancialTab consumerInfo={consumer.info} />,
            },
            {
              key: 'product-items',
              title: formatMessage({ id: 'pages.app-user-preview.tabs.product-items' }),
              Content: () => <ProductItemsTab userId={consumer.id} />,
            },
            isFeatureFlagDisabled('neom') && {
              key: 'payout-requests',
              title: formatMessage({ id: 'pages.app-user-preview.tabs.payment-outs' }),
              Content: () => <PaymentOutsTab userId={consumer.id} />,
            },
            isFeatureFlagDisabled('meom') && {
              key: 'donations',
              title: formatMessage({ id: 'pages.app-user-preview.tabs.donations' }),
              Content: () => <DonationsTab userId={consumer.id} />,
            },
          ])}
        />
      </CardedContent>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(
  PreviewPage,
  ({ can }) => can('consumerAccount', 'preview') && can('consumerAccount', 'previewInfo')
)
