import { FC, useMemo } from 'react'
import { useCountryCities } from '@web-panel/api'
import { Autocomplete, TextField } from '@web-panel/shared'
import { AutocompleteInputProps } from './types'

type CityCodeAutocompletePops = AutocompleteInputProps<string> & {
  countryCode: string
}
const CityCodeAutocomplete: FC<CityCodeAutocompletePops> = ({
  fullWidth,
  className,
  disabled,
  value,
  onChange,
  error,
  label,
  countryCode,
}) => {
  const { data: cities = [] } = useCountryCities({ countryCode })
  const city = useMemo(() => {
    return cities.find(({ code }) => code === value)
  }, [value, countryCode, cities])

  return (
    <Autocomplete
      key={city?.code}
      disablePortal
      fullWidth={fullWidth}
      disabled={disabled}
      options={cities}
      className={className}
      renderInput={({ inputProps, ...params }) => (
        <TextField
          error={error}
          {...params}
          label={label}
          inputProps={{ ...inputProps, autoComplete: 'do-not-autocomplete' }}
        />
      )}
      getOptionLabel={({ name }) => name}
      onChange={(_data, val) => onChange(val?.code ?? '')}
      value={city}
      isOptionEqualToValue={(option, val) => option.code === val?.code}
    />
  )
}

export default CityCodeAutocomplete
