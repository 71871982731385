import { FC, ReactElement } from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'
import _compact from 'lodash/compact'

export type ActionButtonsContainerProps = {
  className?: string
  actionButtons?: (ReactElement | null | undefined)[]
}

const ActionButtonsContainer: FC<ActionButtonsContainerProps> = ({
  className,
  actionButtons = [],
}) => {
  const buttons = _compact(actionButtons)
  if (buttons.length === 0) return null

  return (
    <motion.div
      className={classNames('grid', `grid-cols-${buttons.length}`, 'gap-12', className)}
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
    >
      {buttons.map((button, key) => (
        <div key={key}>{button}</div>
      ))}
    </motion.div>
  )
}

export default ActionButtonsContainer
