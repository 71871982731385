import { FC } from 'react'
import { Checkbox, FormControlLabel, FormSection, TextField } from '@web-panel/shared'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import { ContractPackageSetting } from '@web-panel/api'
import { ContractSettingsSectionFields, packageSettingToInput } from './helpers'

type LoyaltyPricingSectionProps = {
  packageSetting: ContractPackageSetting[]
}

export const ContractFeaturesSection: FC<LoyaltyPricingSectionProps> = ({ packageSetting }) => {
  const { formatMessage } = useIntl()
  const { values, errors, setFieldValue } = useFormikContext<ContractSettingsSectionFields>()

  const toggleEnabled = (index: number) => {
    if (values.featureSetting && values.featureSetting[index]) {
      setFieldValue(`featureSetting.${index}`, null)
    } else {
      setFieldValue(`featureSetting.${index}`, packageSettingToInput(packageSetting[index]))
    }
  }
  const handleChangePrice = (price: string, index: number) => {
    if (!values.featureSetting) return

    setFieldValue(`featureSetting.${index}`, {
      ...values.featureSetting[index],
      price,
    })
  }

  return (
    <FormSection
      containerClasses="-mt-8"
      title={formatMessage({
        id: 'models.contract-package-business.contract-features',
      })}
    >
      {packageSetting.map((setting, index) => [
        <div>
          <FormControlLabel
            disabled={setting.contractPackageCode !== 'CUSTOM'}
            label={formatMessage({
              id: `models.contract-package-business.contract.features.${setting.contractSettingConfig.features[0].id
                .replaceAll('_', '-')
                .toLowerCase()}`,
            })}
            control={
              <Checkbox
                checked={!!values.featureSetting && !!values.featureSetting[index]}
                onChange={() => toggleEnabled(index)}
              />
            }
          />
        </div>,
        values.featureSetting && values.featureSetting[index] && (
          <TextField
            className="mt-8"
            fullWidth
            type="number"
            label={formatMessage({
              id: 'models.contract-partner-setting.price',
            })}
            value={values.featureSetting[index]?.price ?? ''}
            onChange={(e) => handleChangePrice(e.target.value, index)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error={!!errors?.featureSetting?.price}
          />
        ),
      ])}
    </FormSection>
  )
}
