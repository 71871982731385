import { FC } from 'react'
import { FormSection, LabeledSelect } from '@web-panel/shared'
import { PaymentType, OrderInvoiceRecipient } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import * as Yup from 'yup'

export type CommercialsSectionFormFields = {
  financePaymentType: PaymentType
  invoiceRecipient: OrderInvoiceRecipient
}

export const commericalsSectionValidationSchema = Yup.object().shape({
  financePaymentType: Yup.string().required(),
  invoiceRecipient: Yup.string().required(),
})

export const CommercialsSection: FC = () => {
  const { formatMessage } = useIntl()
  const { values, handleChange, errors } = useFormikContext<CommercialsSectionFormFields>()

  return (
    <FormSection title={formatMessage({ id: 'models.partner.commercials' })}>
      <LabeledSelect
        fullWidth
        label={formatMessage({ id: 'models.partner.payment-type' })}
        value={values.financePaymentType ?? ''}
        options={Object.values(PaymentType)}
        optionTitle={(option) => formatMessage({ id: `payment-types.${option}` })}
        onChange={(e) => handleChange('financePaymentType')(e.target.value)}
        error={!!errors.financePaymentType}
      />

      <LabeledSelect
        fullWidth
        className="mt-16"
        label={formatMessage({ id: 'models.partner.invoice-recipient' })}
        value={values.invoiceRecipient}
        options={Object.values(OrderInvoiceRecipient)}
        optionTitle={(option) => formatMessage({ id: `order-invoice-recipient.${option}` })}
        onChange={(e) => handleChange('invoiceRecipient')(e.target.value)}
        error={!!errors.invoiceRecipient}
      />
    </FormSection>
  )
}
