import { FC } from 'react'
import { AppPage, InfinitScroller, Button, useDebouncedSearch } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useOrders } from '@web-panel/oc-api'
import { PageHeaderSearch } from '@local/components/PageHeaderSearch'
import { useNavigate } from 'react-router-dom'
import modelIcons from '@local/model-icons'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import IndexTable from './Tables/IndexTable'
import { withFilters, useFilters } from './FiltersContext'
import Filters from './Filters'

const IndexPage: FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { search, onChangeSearch, debouncedSearch } = useDebouncedSearch()
  const { filters } = useFilters()
  const { can } = useRoleAuthorization()
  const {
    data: orders = [],
    loading,
    loadMore,
  } = useOrders(
    {
      ...filters,
      search: debouncedSearch,
    },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )

  const headerActionButtons = [
    can(
      'order',
      'create',
      <Button
        fullWidth
        color="secondary"
        variant="contained"
        onClick={() => navigate('/orders/create')}
      >
        <FormattedMessage
          id="common.actions.create-with-name"
          values={{ name: formatMessage({ id: 'models.order' }) }}
        />
      </Button>
    ),
  ]

  return (
    <AppPage
      loading={loading}
      icon={modelIcons.order}
      title={formatMessage({ id: 'routes.orders' })}
      Sidebar={Filters}
      searchComponent={<PageHeaderSearch value={search} onChangeValue={onChangeSearch} />}
      actionButtons={headerActionButtons}
    >
      <InfinitScroller onLoadMore={loadMore}>
        <IndexTable data={orders} loading={loading} />
      </InfinitScroller>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(withFilters(IndexPage), ({ can }) => can('order', 'list'))
