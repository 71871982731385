import { FC } from 'react'
import {
  Button,
  HeartbeatCounter,
  HorizontalTable,
  HorizontalTableRow,
  HorizontalTableSection,
  TableCell,
  TableRow,
  WorkingHoursPreview,
  useFormatDate,
  useModalRef,
} from '@web-panel/shared'
import { DispenserStatusChip } from '@local/components/DispenserStatusChip'
import { Dispenser } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'
import { OutletLink } from '@local/components/OutletLink'
import { RoleAuthorized } from '@local/components/RoleAuthorized'
import { useNavigate } from 'react-router-dom'
import { AssignCustomLocationButton } from '../Components/AssignCustomLocationButton'
import AssignDispenserToOutletModal from '../Modals/AssignToOutlet'

export type DispenserGeneralTabProps = {
  dispenser: Dispenser
  refetchDispenser: () => void
}

export const DispenserGeneralTab: FC<DispenserGeneralTabProps> = ({
  dispenser,
  refetchDispenser,
}) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const navigate = useNavigate()
  const assignDispenserToOutletModalRef = useModalRef()

  const dispenserLocation = dispenser.customLocation ?? dispenser.outletLocation

  return (
    <>
      <HorizontalTable>
        <HorizontalTableRow title={formatMessage({ id: 'models.dispenser.status' })}>
          <DispenserStatusChip status={dispenser.status} />
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.dispenser.core-id' })}>
          {dispenser.coreId}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.dispenser.qr-code' })}>
          {dispenser.qrCode}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.dispenser.imei' })}>
          {dispenser.imei ?? '-'}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.dispenser.dispenser-app-id' })}>
          {dispenser.dispenserAppId ?? '-'}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.dispenser.activation-code' })}>
          {dispenser.activationCode}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.dispenser.capacity' })}>
          {dispenser.capacity}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.dispenser.battery' })}>
          {dispenser.battery ?? '-'}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.dispenser.items-processed' })}>
          {dispenser.itemsProcessed}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.dispenser.operation-type' })}>
          {dispenser.operationType ? (
            <FormattedMessage id={`dispenser-operation-types.${dispenser.operationType}`} />
          ) : (
            '-'
          )}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.dispenser.location-type' })}>
          {dispenser.locationType ? (
            <FormattedMessage id={`dispenser-location-types.${dispenser.locationType}`} />
          ) : (
            '-'
          )}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.dispenser.stand-type' })}>
          {dispenser.standType ? (
            <FormattedMessage id={`dispenser-stand-types.${dispenser.standType}`} />
          ) : (
            '-'
          )}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.dispenser.last-signal-date' })}>
          {dispenser.lastSignalDate && <HeartbeatCounter date={dispenser.lastSignalDate} />}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.dispenser.create-date' })}>
          {formatDate('human-date', dispenser.createDate)}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.dispenser.outlet' })}>
          {dispenser.outletId ? (
            <div className="flex flex-row items-center">
              <OutletLink outletId={dispenser.outletId} />
              <Button
                variant="outlined"
                className="ml-16"
                onClick={() => assignDispenserToOutletModalRef.current?.open()}
              >
                <FormattedMessage id="common.actions.reassign" />
              </Button>
            </div>
          ) : (
            <RoleAuthorized resource="dispenser" action="assignToOutlet">
              <Button
                variant="outlined"
                onClick={() => assignDispenserToOutletModalRef.current?.open()}
              >
                <FormattedMessage id="common.actions.assign" />
              </Button>
            </RoleAuthorized>
          )}
        </HorizontalTableRow>
        <HorizontalTableSection title={formatMessage({ id: 'models.dispenser.location' })} />
        {dispenserLocation ? (
          <>
            <HorizontalTableRow title={formatMessage({ id: 'models.location.origin' })}>
              {dispenser.customLocation ? (
                <div className="flex flex-row items-center">
                  {formatMessage({ id: 'common.labels.custom' })}
                  <Button
                    variant="outlined"
                    className="ml-16"
                    onClick={() => navigate('custom-location/update')}
                  >
                    <FormattedMessage id="common.actions.update" />
                  </Button>
                </div>
              ) : (
                <div className="flex flex-row items-center">
                  {formatMessage({ id: 'models.outlet' })}
                  <AssignCustomLocationButton
                    className="ml-16"
                    variant="outlined"
                    dispenserId={dispenser.id}
                    outletLocation={dispenser.outletLocation}
                  />
                </div>
              )}
            </HorizontalTableRow>
            <HorizontalTableRow title={formatMessage({ id: 'models.location.country' })}>
              {dispenserLocation.country.name}
            </HorizontalTableRow>
            <HorizontalTableRow title={formatMessage({ id: 'models.location.city' })}>
              {dispenserLocation.city.name}
            </HorizontalTableRow>
            <HorizontalTableRow title={formatMessage({ id: 'models.location.name' })}>
              {dispenserLocation.name}
            </HorizontalTableRow>
            <HorizontalTableRow title={formatMessage({ id: 'models.location.street' })}>
              {dispenserLocation.street}
            </HorizontalTableRow>
            <HorizontalTableRow title={formatMessage({ id: 'models.location.house-number' })}>
              {dispenserLocation.houseNumber}
            </HorizontalTableRow>
            <HorizontalTableRow title={formatMessage({ id: 'models.location.postal-code' })}>
              {dispenserLocation.postalCode}
            </HorizontalTableRow>
          </>
        ) : (
          <TableRow>
            <TableCell className="py-16" align="center" colSpan={2}>
              <AssignCustomLocationButton
                variant="outlined"
                dispenserId={dispenser.id}
                outletLocation={dispenser.outletLocation}
              />
            </TableCell>
          </TableRow>
        )}
      </HorizontalTable>
      {dispenserLocation && (
        <WorkingHoursPreview
          data={dispenserLocation.workingHours}
          dayLabels={{
            monday: formatMessage({ id: 'time.day-of-week.short.monday' }),
            tuesday: formatMessage({ id: 'time.day-of-week.short.tuesday' }),
            wednesday: formatMessage({ id: 'time.day-of-week.short.wednesday' }),
            thursday: formatMessage({ id: 'time.day-of-week.short.thursday' }),
            friday: formatMessage({ id: 'time.day-of-week.short.friday' }),
            saturday: formatMessage({ id: 'time.day-of-week.short.saturday' }),
            sunday: formatMessage({ id: 'time.day-of-week.short.sunday' }),
          }}
          timeFrameLabels={{
            opening: formatMessage({ id: 'models.location.opening-time' }),
            closing: formatMessage({ id: 'models.location.closing-time' }),
          }}
        />
      )}
      <AssignDispenserToOutletModal
        ref={assignDispenserToOutletModalRef}
        dispenser={dispenser}
        onSubmit={() => refetchDispenser()}
      />
    </>
  )
}
