import { Typography } from '@web-panel/shared'
import { FC } from 'react'

type TotalWidgetProps = {
  color: string
  value: number
  title: string
  icon?: string
}

export const TotalWidget: FC<TotalWidgetProps> = ({ value, title, color, icon }) => {
  return (
    <div className="text-center border-3 rounded-24 p-12 pb-5" style={{ borderColor: color }}>
      <Typography fontSize="5.5rem" lineHeight="8.2rem" fontWeight="700" color={color}>
        {value}
      </Typography>
      <div className="flex flex-row items-center justify-center gap-5">
        <Typography fontSize="1.8rem" lineHeight="2.7rem" fontWeight="500" color={color}>
          {title}
        </Typography>
        {icon && <img src={icon} alt="icon" />}
      </div>
    </div>
  )
}
