import { FC, useState } from 'react'
import {
  Autocomplete,
  Button,
  ModalBody,
  ModalBodyProps,
  TextField,
  buildModal,
} from '@web-panel/shared'
import { Batch, BatchStatus } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'

import { useBatches } from '@web-panel/oc-api'

type BatchAttachModalProps = ModalBodyProps & {
  alreadyAttachedBatchIds: string[]
  onSubmit: (id: string[]) => void
}

const BatchAttachModal: FC<BatchAttachModalProps> = ({
  onClose,
  alreadyAttachedBatchIds,
  onSubmit,
}) => {
  const { formatMessage } = useIntl()
  const [searchString, setSearchString] = useState<string>('')
  const { data: batches = [] } = useBatches(
    { status: BatchStatus.Ready, batchNumber: searchString },
    { fetchPolicy: 'cache-and-network' }
  )
  const [selectedBatches, setSelectedBatches] = useState<Batch[]>([])

  const handleSubmit = () => {
    if (selectedBatches.length === 0) return
    onSubmit(selectedBatches.map(({ id }) => id))
    onClose()
  }

  const filterAutocompleteOptions = (options: Batch[]) => {
    return options.filter((option) => !alreadyAttachedBatchIds.includes(option.id))
  }

  return (
    <ModalBody
      title="Container attach modal"
      actions={[
        <Button key="close-button" onClick={onClose}>
          <FormattedMessage id="common.actions.cancel" />
        </Button>,
        <Button
          key="submit-button"
          disabled={selectedBatches.length === 0}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          <FormattedMessage id="common.actions.attach" />
        </Button>,
      ]}
    >
      <div className="pt-5">
        <Autocomplete
          fullWidth
          multiple
          options={batches}
          getOptionLabel={({ batchNumber }) => batchNumber}
          filterOptions={filterAutocompleteOptions}
          renderInput={(params) => (
            <TextField {...params} label={formatMessage({ id: `models.batch.id` })} />
          )}
          inputValue={searchString}
          onInputChange={(e, inputValue) => setSearchString(inputValue)}
          onChange={(e, value) => setSelectedBatches(value)}
          value={selectedBatches}
        />
      </div>
    </ModalBody>
  )
}

export default buildModal(BatchAttachModal, {
  PaperProps: { className: 'overflow-y-visible' },
  fullWidth: true,
  maxWidth: 'md',
})
