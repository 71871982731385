import { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react'
import { Dispenser } from '@web-panel/api'
import { useReactToPrint } from 'react-to-print'
import QRCode from 'qrcode'
import blackLogo from '@local/assets/images/BlackLogo.svg'
import { generateExternalRoute } from '@local/pages/ExternalRouter/helpers'

const labelHeight = 100
const labelWidth = 341
const labelPadding = 5
const infoLineX = labelPadding + 50
const fontSize = 16
const containerHeight = labelHeight - labelPadding * 2

const pageStyle = `
@page {
  size: 90.3mm 29mm;
  margin: 1.5mm 2mm;
}

@media print {
  html, body {
    size: 90.3mm 29mm;
    margin: 1.5mm 2mm;
  }
}
`

export type DispenserPhysicalLabelRef = {
  print: () => void
}

type DispenserPhysicalLabelProps = {
  dispenser: Dispenser
}

export const DispenserPhysicalLabel = forwardRef<
  DispenserPhysicalLabelRef,
  DispenserPhysicalLabelProps
>(({ dispenser }, ref) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [qrImage, setQrImage] = useState<string | null>(null)

  const print = useReactToPrint({ content: () => containerRef.current, pageStyle })

  useImperativeHandle(ref, () => ({
    print,
  }))

  const generateQrCode = async () => {
    const image = await QRCode.toDataURL(generateExternalRoute('dispenser', dispenser.id), {
      errorCorrectionLevel: 'low',
      margin: 0,
      color: {
        dark: '#000000',
        light: '#ffffff',
      },
    })
    setQrImage(image)
  }

  useEffect(() => {
    generateQrCode()
  })

  if (!qrImage) return null

  const labels = ['POS - Auto Dispenser', dispenser.coreId]
  const labelMargin = (containerHeight - labels.length * fontSize) / (labels.length + 1)

  const getLabelY = (id: number) => {
    return labelPadding + labelMargin + id * (labelMargin + fontSize)
  }

  return (
    <div ref={containerRef}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${labelWidth} ${labelHeight}`}
        width={labelWidth}
        height={labelHeight}
      >
        <image
          href={blackLogo}
          width={containerHeight}
          transform={`rotate(-90, ${labelPadding}, ${
            labelHeight - labelPadding
          }) translate(${labelPadding}, ${labelHeight - labelPadding})`}
        />
        {labels.map((label, id) => (
          <text
            key={id}
            alignmentBaseline="hanging"
            fontSize={`${fontSize}px`}
            fontFamily="Inter, Roboto, Helvetica, Arial, sans-serif"
            x={infoLineX}
            y={getLabelY(id)}
          >
            {label}
          </text>
        ))}
        <image
          href={qrImage}
          x={labelWidth - (labelHeight - labelPadding) - labelPadding}
          y={labelPadding}
          width={containerHeight}
          height={containerHeight}
        />
      </svg>
    </div>
  )
})
