import { FC } from 'react'
import { ContainerStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { Chip, ComponentColor } from '@web-panel/shared'

const statusColorMapping: Record<ContainerStatus, ComponentColor> = {
  [ContainerStatus.Delivered]: 'info',
  [ContainerStatus.Deleted]: 'error',
  [ContainerStatus.Operations]: 'info',
  [ContainerStatus.Ready]: 'success',
  [ContainerStatus.ReadyReturn]: 'success',
  [ContainerStatus.Unknown]: 'warning',
}

type ContainerStatusChipProps = {
  status: ContainerStatus
}

export const ContainerStatusChip: FC<ContainerStatusChipProps> = ({ status }) => {
  const { formatMessage } = useIntl()

  const color = statusColorMapping[status] ?? 'default'

  return (
    <Chip
      className="min-w-84"
      color={color}
      label={formatMessage({ id: `container-statuses.${status}` })}
    />
  )
}
