import { FC } from 'react'

import { FormattedCurrency, InfinitScroller, TableGrid, useFormatDate } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import DonationStatusChip from '@local/components/DonationStatusChip'
import { useNavigate } from 'react-router-dom'

import { useConsumerDonations } from '@web-panel/oc-api'

type DonationsTabProps = {
  userId: string
}

const DonationsTab: FC<DonationsTabProps> = ({ userId }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const navigate = useNavigate()
  const {
    data = [],
    loading,
    loadMore,
  } = useConsumerDonations(
    { consumerId: userId },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )

  return (
    <InfinitScroller onLoadMore={loadMore}>
      <TableGrid
        loading={loading}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        collection={data}
        fields={[
          {
            title: formatMessage({ id: 'models.donation.id' }),
            render: ({ externalId }) => externalId,
          },
          {
            title: formatMessage({ id: 'models.donation.amount' }),
            render: ({ amount, currency }) => (
              <FormattedCurrency value={amount} currency={currency.code} />
            ),
          },
          {
            title: formatMessage({ id: 'models.donation.create-date' }),
            render: ({ createDate }) => formatDate('date', createDate),
          },
          {
            title: formatMessage({ id: 'models.donation.status' }),
            render: ({ status }) => <DonationStatusChip status={status} />,
          },
          {
            title: formatMessage({ id: 'models.donation.donation-organization' }),
            render: ({ donationOrganization }) => donationOrganization,
          },
        ]}
        onClickRow={(donation) => navigate(`/donations/${donation.id}`)}
      />
    </InfinitScroller>
  )
}

export default DonationsTab
