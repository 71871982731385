import { FC } from 'react'
import { TableCell, TableCellProps, Hidden } from '@mui/material'

type BodyCellProps = {
  hiddenMobile?: boolean
  align?: TableCellProps['align']
}

const BodyCell: FC<BodyCellProps> = ({ hiddenMobile, align, children }) => {
  const cell = <TableCell align={align}>{children}</TableCell>
  if (!hiddenMobile) return cell

  return <Hidden lgDown>{cell}</Hidden>
}

export default BodyCell
