import { VFC, PropsWithChildren, ReactElement } from 'react'
import { useCurrentUserAuthrorizations } from '@web-panel/api'
import _includes from 'lodash/includes'
import { Error404 } from '@local/pages/404'
import { RoleResource, RoleAction, resourceAuthorizationMapping } from './types'

type CheckFnc = <Resource extends RoleResource>(
  resource: Resource,
  action: RoleAction<Resource>
) => boolean

export const useRoleAuthorization = () => {
  const { data, loading } = useCurrentUserAuthrorizations()
  const resources = data?.resources ?? []

  function can<Resource extends RoleResource>(
    resource: Resource,
    action: RoleAction<Resource>
  ): boolean
  // eslint-disable-next-line no-redeclare, @typescript-eslint/no-explicit-any
  function can<Resource extends RoleResource, Element extends any>(
    resource: Resource,
    action: RoleAction<Resource>,
    element: Element
  ): Element | null
  // eslint-disable-next-line no-redeclare, @typescript-eslint/no-explicit-any
  function can<Resource extends RoleResource, Element extends any>(
    resource: Resource,
    action: RoleAction<Resource>,
    element?: Element
  ): boolean | Element | null {
    const resourceName = resourceAuthorizationMapping[resource][action] as unknown as string
    const isAllowed = resourceName === '*' || _includes(resources, resourceName)

    if (!element) return isAllowed
    return isAllowed ? element : null
  }

  const cannot: CheckFnc = (resource, action) => {
    return !can(resource, action)
  }

  return { can, cannot, loading }
}

type RoleAuthorizedProps<Resource extends RoleResource> = PropsWithChildren<{
  resource: Resource
  action: RoleAction<Resource>
}>

export function RoleAuthorized<Resource extends RoleResource>({
  resource,
  action,
  children,
}: RoleAuthorizedProps<Resource>): ReactElement | null {
  const { cannot } = useRoleAuthorization()
  if (cannot(resource, action)) return null

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export function pageWithRoleAuthorization(
  Component: VFC,
  check: (funcs: { can: CheckFnc; cannot: CheckFnc }) => boolean
): VFC {
  return () => {
    const { can, cannot, loading } = useRoleAuthorization()

    if (loading) return null

    if (!check({ can, cannot })) return <Error404 />

    return <Component />
  }
}
