import { FC } from 'react'
import { Donation } from '@web-panel/api'
import {
  FormattedCurrency,
  HorizontalTable,
  HorizontalTableRow,
  useFormatDate,
} from '@web-panel/shared'
import { useIntl, FormattedMessage } from 'react-intl'
import { UserLink } from '@local/components/UserLink'
import DonationStatusChip from '@local/components/DonationStatusChip'

type PreviewTableProps = {
  data: Donation
}

const PreviewTable: FC<PreviewTableProps> = ({ data }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()

  return (
    <div className="md:p-24 max-w-2xl">
      <HorizontalTable>
        <HorizontalTableRow title={formatMessage({ id: 'models.donation.id' })}>
          {data.externalId}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.donation.consumer' })}>
          <UserLink userId={data.consumerId} />
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.donation.create-date' })}>
          {formatDate('datetime', data.createDate)}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.donation.amount' })}>
          <FormattedCurrency value={data.amount} currency={data.currency.code} />
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.donation.amount-to-donate' })}>
          <FormattedCurrency value={data.amountToWithdraw} currency={data.currency.code} />
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.donation.status' })}>
          <DonationStatusChip status={data.status} />
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.donation.donation-organization' })}>
          {data.donationOrganization}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.donation.receipt' })}>
          {data.receipt && (
            <a
              target="_blank"
              href={data.receipt}
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <FormattedMessage id="common.actions.download" />
            </a>
          )}
        </HorizontalTableRow>
      </HorizontalTable>
    </div>
  )
}

export default PreviewTable
