import { FC } from 'react'
import { Checkbox, FormControlLabel, FormSection, TextField } from '@web-panel/shared'
import { useFormikContext } from 'formik'
import { ContractPackageSetting } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { ContractSettingsSectionFields, packageSettingToInput } from './helpers'

type SubscriptionPricingSectionProps = {
  packageSetting: ContractPackageSetting
}

export const SubscriptionPricingSection: FC<SubscriptionPricingSectionProps> = ({
  packageSetting,
}) => {
  const { formatMessage } = useIntl()
  const { values, errors, setFieldValue } = useFormikContext<ContractSettingsSectionFields>()

  const toggleEnabled = () => {
    if (values.subscriptionSetting) {
      setFieldValue('subscriptionSetting', null)
    } else {
      setFieldValue('subscriptionSetting', packageSettingToInput(packageSetting))
    }
  }
  const handleChangePrice = (price: string) => {
    if (!values.subscriptionSetting) return

    setFieldValue('subscriptionSetting', {
      ...values.subscriptionSetting,
      price,
    })
  }

  return (
    <FormSection
      containerClasses="-mt-8"
      title={formatMessage({ id: 'models.contract-package-business.subscription-pricing' })}
    >
      <div>
        <FormControlLabel
          disabled={packageSetting.contractPackageCode !== 'CUSTOM'}
          label={formatMessage({ id: 'models.contract-package-business.monthly-bundle-fee' })}
          control={<Checkbox checked={!!values.subscriptionSetting} onChange={toggleEnabled} />}
        />
      </div>
      {values.subscriptionSetting && (
        <TextField
          className="mt-8"
          fullWidth
          type="number"
          label={formatMessage({ id: 'models.contract-partner-setting.price' })}
          value={values.subscriptionSetting.price ?? ''}
          onChange={(e) => handleChangePrice(e.target.value)}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error={!!errors.subscriptionSetting?.price}
        />
      )}
    </FormSection>
  )
}
