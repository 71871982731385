import { FC, useMemo } from 'react'
import { UserStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { Chip } from '@web-panel/shared'

type UserStatusChipProps = {
  status: UserStatus
}

export const UserStatusChip: FC<UserStatusChipProps> = ({ status }) => {
  const { formatMessage } = useIntl()

  const color = useMemo(() => {
    switch (status) {
      case UserStatus.Active:
        return 'success'
      case UserStatus.Deleted:
        return 'error'
      case UserStatus.Inactive:
        return 'warning'
      case UserStatus.Pending:
        return 'info'
      default:
        return 'default'
    }
  }, [status])

  return (
    <Chip
      className="min-w-72"
      color={color}
      label={formatMessage({ id: `user-statuses.${status}` })}
    />
  )
}
