import { FC } from 'react'
import { useCities } from '@web-panel/api'
import { TableGrid, Button, Link } from '@web-panel/shared'
import { useIntl, FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { GeoCityStatusChip } from '@local/components/GeoCityStatusChip'

export const SettingsCitiesTab: FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { data: cities = [], loading } = useCities({ fetchPolicy: 'cache-and-network' })
  const { can, cannot } = useRoleAuthorization()

  return (
    <TableGrid
      collection={cities}
      loading={loading}
      fields={[
        {
          title: formatMessage({ id: 'models.geo-city.code' }),
          render: ({ code }) => code,
        },
        {
          title: formatMessage({ id: 'models.geo-city.name' }),
          render: ({ name }) => name,
        },
        {
          title: formatMessage({ id: 'models.geo-city.latitude' }),
          render: ({ latitude }) => latitude,
        },
        {
          title: formatMessage({ id: 'models.geo-city.longitude' }),
          render: ({ longitude }) => longitude,
        },
        {
          title: formatMessage({ id: 'models.geo-city.country' }),
          render: ({ country }) => (
            <Link stopPropogation to={`/settings/countries/${country.code}`}>
              {country.name} ({country.code})
            </Link>
          ),
        },
        {
          title: formatMessage({ id: 'models.geo-city.status' }),
          render: ({ status }) => <GeoCityStatusChip status={status} />,
        },
      ]}
      onClickRow={({ code }) => navigate(`/settings/cities/${code}`)}
      rowClickable={can('geoCity', 'preview')}
      rowActions={[
        {
          title: formatMessage({ id: 'common.actions.details' }),
          icon: 'visibility',
          onClick: ({ code }) => navigate(`/settings/cities/${code}`),
          disabled: cannot('geoCity', 'preview'),
        },
        {
          title: formatMessage({ id: 'common.actions.edit' }),
          icon: 'edit',
          onClick: ({ code }) => navigate(`/settings/cities/${code}/edit`),
          disabled: cannot('geoCity', 'update'),
          mainAction: true,
        },
      ]}
      emptyLabel={formatMessage({ id: 'common.no-data' })}
      addItemButton={
        can('geoCity', 'create') ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/settings/cities/create')}
          >
            <FormattedMessage id="common.actions.create" />
          </Button>
        ) : null
      }
    />
  )
}
