import { useRef, VFC } from 'react'
import { Button } from '@web-panel/shared'
import { Dispenser } from '@web-panel/api'
import { FormattedMessage } from 'react-intl'
import { DispenserPhysicalLabel, DispenserPhysicalLabelRef } from './DispenserPhysicalLabel'

type PrintPhysicalLabelButtonProps = {
  dispenser: Dispenser
}

export const PrintPhysicalLabelButton: VFC<PrintPhysicalLabelButtonProps> = ({ dispenser }) => {
  const labelRef = useRef<DispenserPhysicalLabelRef>(null)

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        onClick={() => labelRef.current?.print()}
      >
        <FormattedMessage id="pages.boxes.header-actions.print" />
      </Button>
      <div className="hidden">
        <DispenserPhysicalLabel ref={labelRef} dispenser={dispenser} />
      </div>
    </>
  )
}
