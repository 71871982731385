import { FC } from 'react'
import { useCountries } from '@web-panel/api'
import { useFormikContext } from 'formik'
import * as Yup from 'yup'
import {
  AccountSection,
  AccountSectionFormFields,
  accountSectionValidationSchema,
} from './AccountSection'
import {
  ContactInfoSection,
  ContactInfoSectionFormFields,
  contactInfoSectionValidationSchema,
} from './ContactInfoSection'
import {
  InvoiceInfoSection,
  InvoiceInfoSectionFormFields,
  invoiceInfoSectionValidationSchema,
} from './InvoiceInfoSection'
import {
  CommercialsSection,
  CommercialsSectionFormFields,
  commericalsSectionValidationSchema,
} from './CommercialsSection'

export type FormFields = AccountSectionFormFields &
  ContactInfoSectionFormFields &
  InvoiceInfoSectionFormFields &
  CommercialsSectionFormFields &
  CommercialsSectionFormFields

export const validationSchema = Yup.object()
  .concat(accountSectionValidationSchema)
  .concat(contactInfoSectionValidationSchema)
  .concat(invoiceInfoSectionValidationSchema)
  .concat(commericalsSectionValidationSchema)

const PartnerForm: FC = () => {
  const { data: countries = [], loading: countriesLoading } = useCountries()
  const { handleSubmit } = useFormikContext()
  if (countriesLoading) return null

  return (
    <form className="p-16 max-w-2xl" onSubmit={handleSubmit}>
      <AccountSection />
      <ContactInfoSection />
      <InvoiceInfoSection locationCountries={countries} />
      <CommercialsSection />
    </form>
  )
}

export default PartnerForm
