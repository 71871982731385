import { useMutation, useQuery } from '@apollo/client'
import { CollectionQueryHook, GroupLabel, Label, MutationHook, QueryHook } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  GroupLabelsDocument,
  GroupLabelsDocumentInput,
  GroupLabelsDocumentResponse,
  DeleteGroupLabelDocument,
  DeleteGroupLabelDocumentInput,
  DeleteGroupLabelDocumentResponse,
  GetGroupLabelDocument,
  GetGroupLabelDocumentInput,
  GetGroupLabelDocumentResponse,
  GroupLabelLabelsDocumentInput,
  GroupLabelLabelsDocumentResponse,
  GroupLabelLabelsDocument,
  ActivateGroupLabelDocumentInput,
  ActivateGroupLabelDocumentResponse,
  ActivateGroupLabelDocument,
  CreateGroupLabelDocumentInput,
  CreateGroupLabelDocumentResponse,
  CreateGroupLabelDocument,
} from '../docs'

export const useGroupLabels: CollectionQueryHook<
  Omit<GroupLabelsDocumentInput, 'limit' | 'offset'>,
  GroupLabel[]
> = (input, options) => {
  const variables = {
    ...defaultCollectionVariables,
    ...input,
  }
  const { data, loading, fetchMore, refetch } = useQuery<GroupLabelsDocumentResponse>(
    GroupLabelsDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.groupLabelsCollection.hasNext) return

    const offset = data.groupLabelsCollection.labels.length

    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.groupLabelsCollection.labels,
    loading,
    loadMore,
    hasMore: data?.groupLabelsCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useGroupLabelLabels: CollectionQueryHook<GroupLabelLabelsDocumentInput, Label[]> = (
  input,
  options
) => {
  const variables = {
    ...defaultCollectionVariables,
    ...input,
  }
  const { data, loading, refetch, fetchMore } = useQuery<GroupLabelLabelsDocumentResponse>(
    GroupLabelLabelsDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data?.labelsCollection?.hasNext) return

    const offset = data?.labelsCollection?.labels?.length

    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.labelsCollection?.labels,
    loading,
    loadMore,
    hasMore: data?.labelsCollection?.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useGroupLabel: QueryHook<
  GetGroupLabelDocumentInput,
  GetGroupLabelDocumentResponse['groupLabel']
> = (variables) => {
  const { data, loading, refetch } = useQuery<
    GetGroupLabelDocumentResponse,
    GetGroupLabelDocumentInput
  >(GetGroupLabelDocument, { variables })

  return {
    data: data?.groupLabel,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useDeleteGroupLabel: MutationHook<
  DeleteGroupLabelDocumentInput,
  DeleteGroupLabelDocumentResponse
> = () => {
  const [execute, { loading }] = useMutation<
    DeleteGroupLabelDocumentResponse,
    DeleteGroupLabelDocumentInput
  >(DeleteGroupLabelDocument)

  async function request(variables: DeleteGroupLabelDocumentInput): Promise<void> {
    await execute({ variables })
  }

  return { request, loading }
}

export const useActivateGroupLabel: MutationHook<
  ActivateGroupLabelDocumentInput,
  ActivateGroupLabelDocumentResponse
> = () => {
  const [execute, { loading }] = useMutation<
    ActivateGroupLabelDocumentResponse,
    ActivateGroupLabelDocumentInput
  >(ActivateGroupLabelDocument)

  const request = async ({ input }: ActivateGroupLabelDocumentInput) => {
    const { data } = await execute({ variables: { input } })
    if (!data) throw new Error('Could not activate group label')
  }

  return { request, loading }
}

export const useCreateGroupLabel: MutationHook<
  CreateGroupLabelDocumentInput,
  CreateGroupLabelDocumentResponse
> = () => {
  const [execute, { loading }] = useMutation<
    CreateGroupLabelDocumentResponse,
    CreateGroupLabelDocumentInput
  >(CreateGroupLabelDocument)

  const request = async ({ input }: CreateGroupLabelDocumentInput) => {
    const { data } = await execute({ variables: { input } })
    if (!data) throw new Error('Could not create group label')
  }

  return { request, loading }
}
