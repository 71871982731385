import { FC } from 'react'
import { Label } from '@web-panel/api'
import { Link } from '@web-panel/shared'

import { useLabel } from '@web-panel/oc-api'

type LabelLinkProps = {
  label: Label
}
const LabelLink: FC<LabelLinkProps> = ({ label }) => {
  return (
    <Link to={`/group-labels/${label.groupLabelId.id}`} onClick={(e) => e.stopPropagation()}>
      {label.groupLabelExternalId}
    </Link>
  )
}

type LinkWithDataLoadProps = {
  labelId: string
}
const LinkWithDataLoad: FC<LinkWithDataLoadProps> = ({ labelId }) => {
  const { data: label } = useLabel({ id: labelId })
  if (!label) return null

  return <LabelLink label={label} />
}

type AddressGroupLabelLinkProps = LabelLinkProps | LinkWithDataLoadProps

export const AddressGroupLabelLink: FC<AddressGroupLabelLinkProps> = (props) => {
  if ('label' in props) return <LabelLink label={props.label} />

  return <LinkWithDataLoad labelId={props.labelId} />
}

export default AddressGroupLabelLink
