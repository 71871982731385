import { FC } from 'react'
import { SelectableList, buildFilterContext } from '@web-panel/shared'
import { DonationStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'

type FilterFields = {
  status: DonationStatus | undefined
}

export const { useFilters, withFilters } = buildFilterContext<FilterFields>({
  status: undefined,
})

const Filters: FC = () => {
  const { filters, setFilter } = useFilters()
  const { formatMessage } = useIntl()

  return (
    <div className="flex flex-col">
      <SelectableList
        value={filters.status}
        options={Object.values(DonationStatus)}
        optionTitle={(option) => formatMessage({ id: `donation-statuses.${option}` })}
        label={formatMessage({ id: 'models.donation.status' })}
        onSelect={(status) => setFilter('status', status)}
      />
    </div>
  )
}

export default Filters
