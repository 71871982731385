import { Props } from 'react-apexcharts'

export const chartDefaults: Props['options'] = {
  chart: {
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  stroke: {
    curve: 'smooth',
    width: 3,
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    strokeWidth: 3,
    strokeOpacity: 1,
    fillOpacity: 1,
    hover: {
      size: 6,
    },
  },
  legend: {
    show: false,
  },
  fill: {
    type: 'solid',
    opacity: 0.7,
  },
  tooltip: {
    followCursor: true,
    theme: 'dark',
    fixed: {
      enabled: false,
      position: 'topRight',
      offsetX: 0,
      offsetY: 0,
    },
  },
  yaxis: {
    labels: {
      formatter: (val) => val.toFixed(2),
    },
  },
  xaxis: {
    type: 'datetime',
  },
}
