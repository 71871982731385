import { FC, ReactElement } from 'react'
import { Card, Typography } from '@mui/material'
import classNames from 'classnames'

type WidgetProps = {
  title?: string
  contentFullWidth?: boolean
  buttons?: ReactElement[]
}

export const Widget: FC<WidgetProps> = ({
  title,
  buttons = [],
  contentFullWidth = false,
  children,
}) => {
  return (
    <Card className="w-full rounded-20 shadow">
      {title && (
        <div className="relative px-20 pt-20 pb-8">
          <Typography className="h3 sm:h2 font-medium">{title}</Typography>
        </div>
      )}
      {buttons.length > 0 && (
        <div className="flex flex-row justify-end px-20">
          {buttons.map((button, key) => (
            <div key={key} className="ml-8">
              {button}
            </div>
          ))}
        </div>
      )}

      <div
        className={classNames('relative', 'py-16', {
          'px-20': !contentFullWidth,
        })}
      >
        {children}
      </div>
    </Card>
  )
}
