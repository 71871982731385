import { FC } from 'react'
import { ProductItemModelConfigurationStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { Chip, ComponentColor } from '@web-panel/shared'

const statusColorMapping: Record<ProductItemModelConfigurationStatus, ComponentColor> = {
  [ProductItemModelConfigurationStatus.Active]: 'success',
  [ProductItemModelConfigurationStatus.Inactive]: 'error',
}

type ProductItemModelConfigurationStatusChipProps = {
  status: ProductItemModelConfigurationStatus
}

export const ProductItemModelConfigurationStatusChip: FC<ProductItemModelConfigurationStatusChipProps> =
  ({ status }) => {
    const { formatMessage } = useIntl()

    const color = statusColorMapping[status] ?? 'default'

    return (
      <Chip
        className="min-w-128"
        color={color}
        label={formatMessage({ id: `product-item-model-configuration-statuses.${status}` })}
      />
    )
  }
