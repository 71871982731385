import { RoleAuthorized, pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import modelIcons from '@local/model-icons'
import {
  AppPage,
  Button,
  CardedContent,
  FormattedCurrency,
  HorizontalTable,
  HorizontalTableRow,
  useFormatDate,
  useNotifications,
} from '@web-panel/shared'
import { FC } from 'react'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { Outlet } from '@web-panel/api'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useOneTimeContractSetting,
  useOutlet,
  useDeleteOneTimeContractSetting,
} from '@web-panel/oc-api'
import { Error404 } from '../404'

type PreviewOneTimeContractSettingPageParams = {
  outlet: Outlet
}
const PreviewOneTimeContractSettingPage: FC<PreviewOneTimeContractSettingPageParams> = ({
  outlet,
}) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const params = useParams()
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()
  const { data: oneTimeSetting, loading: loadingOneTimeSetting } = useOneTimeContractSetting({
    partnerId: outlet.partnerId,
    outletId: outlet.id,
    contractSettingId: params.contractSettingId!,
  })
  const { request: deleteOneTimeSetting, loading: loadingDelete } =
    useDeleteOneTimeContractSetting()
  if (!oneTimeSetting || loadingOneTimeSetting) return null
  if (!oneTimeSetting) return <Error404 />

  const handleDeleteOneTimeSetting = async () => {
    try {
      await deleteOneTimeSetting({
        partnerId: outlet.partnerId,
        outletId: outlet.id,
        contractSettingId: oneTimeSetting.id,
      })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-deleted' })
      )
      navigate(-1)
    } catch (e) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <AppPage
      backButton
      icon={modelIcons.contractPackage}
      title={oneTimeSetting.name}
      actionButtons={[
        <RoleAuthorized resource="oneTimeContractSetting" action="update">
          <Button
            disabled={loadingDelete}
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => navigate('update')}
          >
            <FormattedMessage id="common.actions.edit" />
          </Button>
        </RoleAuthorized>,
        <RoleAuthorized resource="oneTimeContractSetting" action="delete">
          <Button
            loading={loadingDelete}
            fullWidth
            variant="contained"
            color="error"
            onClick={handleDeleteOneTimeSetting}
          >
            <FormattedMessage id="common.actions.delete" />
          </Button>
        </RoleAuthorized>,
      ]}
    >
      <CardedContent padded>
        <HorizontalTable>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.one-time-contract-setting.name' })}
          >
            {oneTimeSetting.name}
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.one-time-contract-setting.description' })}
          >
            {oneTimeSetting.description}
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.one-time-contract-setting.price' })}
          >
            <FormattedCurrency
              value={oneTimeSetting.price}
              currency={oneTimeSetting.currency.code}
            />
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.one-time-contract-setting.amount' })}
          >
            <FormattedNumber value={oneTimeSetting.amount} />
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.one-time-contract-setting.total' })}
          >
            <FormattedCurrency
              value={oneTimeSetting.price * oneTimeSetting.amount}
              currency={oneTimeSetting.currency.code}
            />
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.one-time-contract-setting.validation-date' })}
          >
            {formatDate('date', oneTimeSetting.validationDate)}
          </HorizontalTableRow>
        </HorizontalTable>
      </CardedContent>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(
  () => {
    const params = useParams()
    const { data: outlet, loading: loadingOutlet } = useOutlet({ id: params.id! })

    if (loadingOutlet && !outlet) return null
    if (!outlet) return <Error404 />

    return <PreviewOneTimeContractSettingPage outlet={outlet} />
  },
  ({ can }) => can('oneTimeContractSetting', 'preview')
)
