import { FC } from 'react'
import { Outlet } from '@web-panel/api'
import {
  TableGrid,
  InfinitScroller,
  Button,
  useFormatDate,
  FormattedCurrency,
} from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import OrderStatusChip from '@local/components/OrderStatusChip'
import { useNavigate } from 'react-router-dom'

import { useOrders, usePartner } from '@web-panel/oc-api'

type OrdersTabProps = {
  outlet: Outlet
}

const OrdersTab: FC<OrdersTabProps> = ({ outlet }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const navigate = useNavigate()
  const {
    data: orders = [],
    loading,
    loadMore,
  } = useOrders(
    { outletId: outlet.id },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )
  const { data: partner } = usePartner({ id: outlet.partnerId })

  const onCreateNew = () => {
    if (!partner) return

    navigate('/orders/create', { state: { partner, outlet } })
  }

  return (
    <InfinitScroller onLoadMore={loadMore}>
      <TableGrid
        loading={loading}
        collection={orders}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        onClickRow={(order) => navigate(`/orders/${order.id}`)}
        addItemButton={
          <Button variant="contained" color="primary" onClick={onCreateNew}>
            <FormattedMessage id="common.actions.create" />
          </Button>
        }
        fields={[
          {
            title: formatMessage({ id: 'models.order.id' }),
            render: ({ orderNumber }) => orderNumber,
          },
          {
            title: formatMessage({ id: 'models.order.total-cost' }),
            render: ({ totalCost, currencyCode }) => (
              <FormattedCurrency value={totalCost} currency={currencyCode} />
            ),
          },
          {
            title: formatMessage({ id: 'models.order.payment-type' }),
            render: ({ paymentType }) => <FormattedMessage id={`payment-types.${paymentType}`} />,
          },
          {
            title: formatMessage({ id: 'models.order.status' }),
            render: ({ status }) => <OrderStatusChip status={status} />,
          },
          {
            title: formatMessage({ id: 'models.order.invoice-status' }),
            render: ({ invoiceStatus }) => (
              <FormattedMessage id={`invoice-statuses.${invoiceStatus}`} />
            ),
          },
          {
            title: formatMessage({ id: 'models.order.create-date' }),
            render: ({ createDate }) => formatDate('date', createDate),
          },
        ]}
      />
    </InfinitScroller>
  )
}

export default OrdersTab
