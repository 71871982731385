import { FC } from 'react'

import {
  AppPage,
  CardedContent,
  TabSections,
  DefaultErrorPage,
  useModalRef,
  Button,
} from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import modelIcons from '@local/model-icons'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useOrder } from '@web-panel/oc-api'
import GeneralTab from './Tabs/General'
import BatchesTab from './Tabs/Batches'
import { ChangeOrderStatusButton } from './Components/ChangeOrderStatusButton'
import { OrderItemsTab } from './Tabs/OrderItemsTab'
import SignatureModal from './Modals/SignatureModal'

const PreviewPage: FC = () => {
  const { formatMessage } = useIntl()
  const signatureModalRef = useModalRef()
  const params = useParams()
  const { data: order, loading } = useOrder({ id: params.id ?? '' })
  const { can } = useRoleAuthorization()

  if (!order && loading) return null
  if (!order)
    return (
      <DefaultErrorPage
        title={formatMessage({ id: 'errors.404.title' })}
        message={formatMessage({ id: 'errors.404.message' })}
      />
    )

  const actionButtons = [
    <Button
      fullWidth
      variant="contained"
      color="secondary"
      onClick={() => signatureModalRef.current?.open()}
    >
      <FormattedMessage id="common.actions.sign" />
    </Button>,
    can(
      'order',
      'updateStatus',
      <ChangeOrderStatusButton
        order={order}
        openSignatureDialog={() => signatureModalRef.current?.open()}
      />
    ),
  ]

  return (
    <AppPage
      backButton
      icon={modelIcons.order}
      actionButtons={actionButtons}
      title={formatMessage({ id: 'routes.order-preview' }, { name: order.orderNumber })}
    >
      <CardedContent>
        <TabSections
          sections={[
            {
              key: 'general',
              title: formatMessage({ id: 'pages.orders-preview.tabs.general' }),
              Content: () => <GeneralTab data={order} />,
            },
            {
              key: 'items',
              title: formatMessage({ id: 'pages.orders-preview.tabs.items' }),
              Content: () => <OrderItemsTab data={order} />,
            },
            {
              key: 'batches',
              title: formatMessage({ id: 'pages.orders-preview.tabs.batches' }),
              Content: () => <BatchesTab order={order} />,
            },
          ]}
        />
        <SignatureModal order={order} ref={signatureModalRef} />
      </CardedContent>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(PreviewPage, ({ can }) => can('order', 'preview'))
