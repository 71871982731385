export default {
  dashboard: 'dashboard',
  partner: 'storefront',
  partnerEmployee: 'assignment_ind',
  outlet: 'store',
  order: 'shopping_cart',
  appUser: 'people',
  payoutRequest: 'paid',
  productItem: 'local_drink',
  productItemBatch: 'all_inbox',
  container: 'inventory_2',
  box: 'assignment_returned',
  dispenser: 'circle',
  adminUser: 'admin_panel_settings',
  settings: 'settings',
  donation: 'volunteer_activism',
  loaltyCampaign: 'campaign',
  rental: 'inventory',
  rentalConfiguration: 'inventory2',
  contractPackage: 'receipt_long',
  address: 'pin',
  tracking: 'table',
  groupLabel: 'label',
  sequences: 'animation',
}
