import { getAppLogo } from '@web-panel/shared'
import defaultLogo from '@local/assets/images/logo.svg'
import neomLogo from '@local/assets/images/logo-neom.svg'

const logoSetting = getAppLogo()

const mapping = {
  default: defaultLogo,
  neom: neomLogo,
} as const

type LogoName = keyof typeof mapping

const appLogoName: LogoName = Object.keys(mapping).includes(logoSetting)
  ? (logoSetting as LogoName)
  : 'default'
export const appLogo = mapping[appLogoName]
