import { FC } from 'react'
import { AppPage, InfinitScroller, useDebouncedSearch } from '@web-panel/shared'
import { useIntl } from 'react-intl'

import modelIcons from '@local/model-icons'
import { PageHeaderSearch } from '@local/components/PageHeaderSearch'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { useSequences } from '@web-panel/oc-api'
import IndexTable from './Tables/IndexTable'

const IndexPage: FC = () => {
  const { search, onChangeSearch, debouncedSearch } = useDebouncedSearch()
  const { formatMessage } = useIntl()

  const {
    data: sequences = [],
    loading,
    hasMore,
    loadMore,
  } = useSequences(
    { search: debouncedSearch },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )

  const onLoadMore = () => {
    if (!hasMore) return
    loadMore()
  }

  return (
    <AppPage
      loading={loading}
      icon={modelIcons.sequences}
      title={formatMessage({ id: 'routes.sequences' })}
      searchComponent={<PageHeaderSearch value={search} onChangeValue={onChangeSearch} />}
    >
      <InfinitScroller onLoadMore={onLoadMore}>
        <IndexTable data={sequences} loading={loading} />
      </InfinitScroller>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(IndexPage, ({ can }) => can('sequences', 'list'))
