import { FC } from 'react'
import { Partner, RentalInventoryType } from '@web-panel/api'
import { Button, InfinitScroller, TableGrid, useFormatDate } from '@web-panel/shared'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { OutletLink } from '@local/components/OutletLink'
import { RentalStatusChip } from '@local/components/RentalStatusChip'

import { useRentals } from '@web-panel/oc-api'

type RentalsTabProps = {
  partner: Partner
}
export const RentalsTab: FC<RentalsTabProps> = ({ partner }) => {
  const { data: rentals = [], loading, loadMore } = useRentals({ partnerId: partner.id })
  const formatDate = useFormatDate()
  const { can } = useRoleAuthorization()
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  return (
    <InfinitScroller onLoadMore={loadMore}>
      <TableGrid
        collection={rentals}
        loading={loading}
        fields={[
          {
            title: formatMessage({ id: 'models.rental.id' }),
            render: ({ externalId }) => externalId,
          },
          {
            title: formatMessage({ id: 'models.rental.outlet' }),
            render: ({ outletId }) => outletId && <OutletLink outletId={outletId} />,
          },
          {
            title: formatMessage({ id: 'models.rental.inventory-type' }),
            render: ({ inventoryType }) => (
              <FormattedMessage id={`rental-inventory-types.${inventoryType}`} />
            ),
          },
          {
            title: formatMessage({ id: 'models.rental.amount' }),
            render: ({ amount }) => amount,
          },
          {
            title: formatMessage({ id: 'models.rental.start-date' }),
            render: ({ startDate }) => formatDate('date', startDate),
          },
          {
            title: formatMessage({ id: 'models.rental.end-date' }),
            render: ({ endDate }) => endDate && formatDate('date', endDate),
          },
          {
            title: formatMessage({ id: 'models.rental.status' }),
            render: ({ status }) => <RentalStatusChip status={status} />,
          },
        ]}
        addItemButton={can(
          'boxRental',
          'create',
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/rentals/new', { state: { partner } })}
          >
            <FormattedMessage id="common.actions.create" />
          </Button>
        )}
        rowClickable={({ inventoryType }) =>
          inventoryType === RentalInventoryType.Box && can('box', 'preview')
        }
        onClickRow={({ id }) => navigate(`/rentals/${id}`)}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
      />
    </InfinitScroller>
  )
}
