import { FC } from 'react'
import { GeoCityStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { Chip, ComponentColor } from '@web-panel/shared'

const statusColorMapping: Record<GeoCityStatus, ComponentColor> = {
  [GeoCityStatus.Enabled]: 'success',
  [GeoCityStatus.Disabled]: 'error',
  [GeoCityStatus.Coming]: 'info',
}

type GeoCityStatusChipProps = {
  status: GeoCityStatus
}

export const GeoCityStatusChip: FC<GeoCityStatusChipProps> = ({ status }) => {
  const { formatMessage } = useIntl()

  const color = statusColorMapping[status] ?? 'default'

  return (
    <Chip
      data-testid="GeoCityStatusChip"
      className="min-w-84"
      color={color}
      label={formatMessage({ id: `geo-city-statuses.${status}` })}
    />
  )
}
