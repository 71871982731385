import { FC, useRef } from 'react'
import { IconButtonProps, IconButton } from '@mui/material'
import {
  ConfirmationDialog,
  ConfirmationDialogRef,
  ConfirmationDialogProps,
} from '../ConfirmationDialog'

type ConfirmableButtonProps = Omit<IconButtonProps, 'onClick'> & {
  confirmation: Omit<ConfirmationDialogProps, 'onSubmit'>
  onClick: () => void | Promise<void>
}

export const ConfirmableIconButton: FC<ConfirmableButtonProps> = ({
  confirmation,
  onClick,
  ...props
}) => {
  const dialogRef = useRef<ConfirmationDialogRef>(null)

  const handleSubmit = () => {
    if (onClick) onClick()
  }

  return (
    <>
      <ConfirmationDialog ref={dialogRef} {...confirmation} onSubmit={handleSubmit} />
      <IconButton onClick={() => dialogRef.current?.open()} {...props} />
    </>
  )
}
