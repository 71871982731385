import { FC } from 'react'
import { TextField } from '@web-panel/shared'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import { useIntl } from 'react-intl'

export type FormFields = {
  cost: number
}

export const validationSchema = Yup.object().shape({
  cost: Yup.number().min(0.01).required(),
})

type EditConsumableFormProps = FormikProps<FormFields>
export const EditConsumableForm: FC<EditConsumableFormProps> = ({
  values,
  errors,
  handleChange,
  handleSubmit,
}) => {
  const { formatMessage } = useIntl()

  return (
    <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit}>
      <div className="flex flex-col p-16 sm:p-24 max-w-2xl">
        <TextField
          name="cost"
          label={formatMessage({ id: 'models.consumable-product-item.cost' })}
          onChange={handleChange('cost')}
          value={values.cost}
          error={!!errors.cost}
        />
      </div>
    </form>
  )
}
