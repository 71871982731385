import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'
import { formatIBAN, PaymentOutStatus } from '@web-panel/api'
import {
  DefaultErrorPage,
  AppPage,
  CardedContent,
  HorizontalTable,
  HorizontalTableSection,
  HorizontalTableRow,
  useNotifications,
  useFormatDate,
  FormattedCurrency,
} from '@web-panel/shared'
import modelIcons from '@local/model-icons'
import { PaymentOutStatusChip } from '@local/components/PaymentOutStatusChip'
import { UserLink } from '@local/components/UserLink'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import { usePaymentOut, useUpdatePaymentOut } from '@web-panel/oc-api'
import ChangeStatusButton from './components/ChangeStatusButton'

const PreviewPage: FC = () => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const params = useParams()
  const { pushNotification } = useNotifications()
  const { data: paymentOut, loading } = usePaymentOut({ id: params.id ?? '' })
  const { request: updatePaymentOut, loading: loadingPaymentOut } = useUpdatePaymentOut()
  const { can } = useRoleAuthorization()

  if (loading) return null
  if (!paymentOut)
    return (
      <DefaultErrorPage
        title={formatMessage({ id: 'errors.404.title' })}
        message={formatMessage({ id: 'errors.404.message' })}
      />
    )

  const handleOnStatusChange = async (paymentOutStatus: PaymentOutStatus) => {
    try {
      await updatePaymentOut({ id: paymentOut.id, input: { paymentOutStatus } })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const headerActionButtons = [
    can(
      'paymentOut',
      'update',
      <ChangeStatusButton
        loading={loadingPaymentOut}
        value={paymentOut.paymentOutStatus}
        onChange={handleOnStatusChange}
      />
    ),
  ]

  return (
    <AppPage
      backButton
      loading={loading}
      icon={modelIcons.payoutRequest}
      actionButtons={headerActionButtons}
      title={formatMessage(
        { id: 'routes.payment-out-preview' },
        { name: paymentOut.externalPaymentOutId }
      )}
    >
      <CardedContent padded>
        <HorizontalTable>
          <HorizontalTableRow title={formatMessage({ id: 'models.payment-out.id' })}>
            {paymentOut.externalPaymentOutId}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.payment-out.consumer' })}>
            <UserLink userId={paymentOut.consumerId} />
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.payment-out.amount' })}>
            <FormattedCurrency value={paymentOut.amount} currency={paymentOut.currency.code} />
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.payment-out.amount-to-payout' })}>
            <FormattedCurrency
              value={paymentOut.amountToPayout}
              currency={paymentOut.currency.code}
            />
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.payment-out.payment-method-type' })}
          >
            <FormattedMessage id={`payment-types.${paymentOut.paymentMethodType}`} />
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.payment-out.receipt' })}>
            <a target="_blank" href={paymentOut.receipt} rel="noreferrer">
              <FormattedMessage id="common.actions.download" />
            </a>
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.payment-out.create-date' })}>
            {formatDate('datetime', paymentOut.createDate)}
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.payment-out.payment-out-status' })}
          >
            <PaymentOutStatusChip status={paymentOut.paymentOutStatus} />
          </HorizontalTableRow>
          {paymentOut.bankAccount && (
            <>
              <HorizontalTableSection
                title={formatMessage({ id: 'models.payment-out.bank-account' })}
              />
              <HorizontalTableRow title={formatMessage({ id: 'models.bank-account.name' })}>
                {[paymentOut.bankAccount.firstName, paymentOut.bankAccount.lastName].join(' ')}
              </HorizontalTableRow>
              <HorizontalTableRow title={formatMessage({ id: 'models.bank-account.iban' })}>
                {formatIBAN(paymentOut.bankAccount.iban)}
              </HorizontalTableRow>
              <HorizontalTableRow title={formatMessage({ id: 'models.bank-account.country' })}>
                {paymentOut.bankAccount.country.name}
              </HorizontalTableRow>
              <HorizontalTableRow title={formatMessage({ id: 'models.bank-account.city' })}>
                {paymentOut.bankAccount.city}
              </HorizontalTableRow>
              <HorizontalTableRow title={formatMessage({ id: 'models.bank-account.address' })}>
                {paymentOut.bankAccount.address}
              </HorizontalTableRow>
              <HorizontalTableRow title={formatMessage({ id: 'models.bank-account.postal-code' })}>
                {paymentOut.bankAccount.postalCode}
              </HorizontalTableRow>
            </>
          )}
          {paymentOut.creditCard && (
            <>
              <HorizontalTableSection
                title={formatMessage({ id: 'models.payment-out.credit-card' })}
              />
              <HorizontalTableRow title={formatMessage({ id: 'models.credit-card.card-number' })}>
                {paymentOut.creditCard.cardNumber}
              </HorizontalTableRow>
            </>
          )}
        </HorizontalTable>
      </CardedContent>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(PreviewPage, ({ can }) => can('paymentOut', 'preview'))
