import { useQuery } from '@apollo/client'
import { QueryHook } from '../types'
import {
  CurrentUserDocument,
  CurrentUserDocumentInput,
  CurrentUserDocumentResponse,
  CurrentUserAuthorizationsDocumentInput,
  CurrentUserAuthorizationsDocumentResponse,
  CurrentUserAuthorizationsDocument,
} from '../docs'

export const useCurrentUser: QueryHook<
  CurrentUserDocumentInput,
  CurrentUserDocumentResponse['currentUser']
> = (options) => {
  const { data, loading, refetch } = useQuery<CurrentUserDocumentResponse>(
    CurrentUserDocument,
    options
  )

  return {
    data: data?.currentUser,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useCurrentUserAuthrorizations: QueryHook<
  CurrentUserAuthorizationsDocumentInput,
  CurrentUserAuthorizationsDocumentResponse['currentUserAuthorizations']
> = (options) => {
  const { data, loading, refetch } = useQuery<CurrentUserAuthorizationsDocumentResponse>(
    CurrentUserAuthorizationsDocument
  )

  return {
    data: data?.currentUserAuthorizations,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}
