import { FC } from 'react'
import { ConsumablePartnerProductItem } from '@web-panel/api'
import { FormattedCurrency, TableGrid } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'

import {
  useProductItemPartnerConsumables,
  useOriginalProductItemConsumable,
} from '@web-panel/oc-api'

type OriginalConstProps = { partnerConsumable: ConsumablePartnerProductItem }
const OriginalCost: FC<OriginalConstProps> = ({ partnerConsumable }) => {
  const { data: consumable } = useOriginalProductItemConsumable(partnerConsumable)
  if (!consumable) return null

  return <FormattedCurrency value={consumable.cost} currency={consumable.currency.code} />
}

type PartnersConsumableTabProps = {
  partnerId: string
}

export const PartnersConsumableTab: FC<PartnersConsumableTabProps> = ({ partnerId }) => {
  const { formatMessage, formatNumber } = useIntl()
  const navigate = useNavigate()
  const { data: partnerConsumables = [], loading: loadingPartnerConsumables } =
    useProductItemPartnerConsumables({ partnerId }, { fetchPolicy: 'cache-and-network' })
  const { can } = useRoleAuthorization()

  return (
    <TableGrid
      collection={partnerConsumables}
      loading={loadingPartnerConsumables}
      fields={[
        {
          title: formatMessage({ id: 'models.consumable-product-item.type' }),
          render: ({ consumableType }) =>
            formatMessage({ id: `consumable-product-item-types.${consumableType}` }),
        },
        {
          title: formatMessage({ id: 'models.consumable-product-item.custom' }),
          render: ({ partnerId: consumablePartnerId }) =>
            formatMessage({ id: consumablePartnerId ? 'common.yes' : 'common.no' }),
        },
        {
          title: formatMessage({ id: 'models.consumable-product-item.cost.partner' }),
          render: ({ cost, currency }) =>
            formatNumber(cost, { style: 'currency', currency: currency.code }),
        },
        {
          title: formatMessage({ id: 'models.consumable-product-item.cost.original' }),
          render: (partnerConsumable) => <OriginalCost partnerConsumable={partnerConsumable} />,
        },
        {
          title: formatMessage({ id: 'models.consumable-product-item.enabled' }),
          render: (partnerConsumable) => (
            <FormattedMessage
              id={partnerConsumable.enabled === false ? 'common.no' : 'common.yes'}
            />
          ),
        },
      ]}
      emptyLabel={formatMessage({ id: 'common.no-data' })}
      onClickRow={({ id }) => navigate(`consumables/${id}`)}
      rowClickable={can('partnerConsumable', 'preview')}
    />
  )
}
