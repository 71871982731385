import { useDebouncedValue } from 'rooks'
import { useSearchParams } from './location-helpers'

export const useDebouncedSearch = () => {
  const [searchParams, updateSearchParams] = useSearchParams()
  const [debouncedSearch] = useDebouncedValue(searchParams.search ?? '', 250, {
    initializeWithNull: false,
  })

  const onChangeSearch = (search: string) => {
    updateSearchParams({ search })
  }

  return {
    search: searchParams.search ?? '',
    debouncedSearch:
      debouncedSearch && debouncedSearch.length > 0 ? (debouncedSearch as string) : undefined,
    onChangeSearch,
  }
}
