import { FC } from 'react'
import { DispenserStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { Chip, ComponentColor } from '@web-panel/shared'

const statusColorMapping: Record<DispenserStatus, ComponentColor> = {
  [DispenserStatus.Available]: 'success',
  [DispenserStatus.NotAvailable]: 'error',
  [DispenserStatus.NoLastSignal]: 'warning',
  [DispenserStatus.Broken]: 'error',
  [DispenserStatus.WarehouseBroken]: 'error',
  [DispenserStatus.NotAvailable]: 'error',
  [DispenserStatus.UnderRepair]: 'secondary',
  [DispenserStatus.Warehouse]: 'info',
  [DispenserStatus.Deleted]: 'error',
}

type DispenserStatusChipProps = {
  status: DispenserStatus
}

export const DispenserStatusChip: FC<DispenserStatusChipProps> = ({ status }) => {
  const { formatMessage } = useIntl()

  const color = statusColorMapping[status] ?? 'default'

  return (
    <Chip
      className="min-w-72"
      color={color}
      label={formatMessage({ id: `dispenser-statuses.${status}` })}
    />
  )
}
