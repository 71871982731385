import { FC } from 'react'
import { TableContainer, Table, TableBody } from '@mui/material'

type HorizontalTableProps = {
  className?: string
}

export const HorizontalTable: FC<HorizontalTableProps> = ({ className, children }) => {
  return (
    <TableContainer>
      <Table className={className}>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  )
}
