import { FC, useState } from 'react'
import {
  FileLoadInput,
  Typography,
  Button,
  ModalBody,
  buildModal,
  ModalBodyProps,
  useNotifications,
} from '@web-panel/shared'

import { FormattedMessage, useIntl } from 'react-intl'

import { useCreateBoxes } from '@web-panel/oc-api'

type CreateBoxesModalProps = ModalBodyProps

const CreateBoxesModal: FC<CreateBoxesModalProps> = ({ onClose }) => {
  const [file, setFile] = useState<File | null>(null)
  const { request: createBoxes, loading: createBoxesLoading } = useCreateBoxes()
  const { pushNotification } = useNotifications()
  const { formatMessage } = useIntl()

  const onSubmit = async () => {
    if (!file) return
    try {
      await createBoxes({ file })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-created' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const handleClose = () => {
    setFile(null)
    onClose()
  }

  return (
    <ModalBody
      title={formatMessage({ id: 'modals.create-cookie-boxes.title' })}
      actions={[
        <Button onClick={handleClose}>
          <FormattedMessage id="common.actions.cancel" />
        </Button>,
        <Button
          loading={createBoxesLoading}
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={!file}
        >
          <FormattedMessage id="common.actions.create" />
        </Button>,
      ]}
    >
      <div className="flex flex-col items-center">
        <FileLoadInput
          state={file ? 'valid' : 'empty'}
          onLoad={(files) => setFile(files[0])}
          accept=".csv"
        />
        <Typography className="mt-16">
          <FormattedMessage id="common.labels.file-picker-hint" />
        </Typography>
      </div>
    </ModalBody>
  )
}

export default buildModal(CreateBoxesModal, { fullWidth: true, maxWidth: 'md' })
