import { Box } from '@web-panel/api'
import { useUpdateBox, useCreateLocation, useUpdateLocation } from '@web-panel/oc-api'
import { FormFields } from './Forms/UpdateForm'

export function useUpdateBoxWithLocation(box?: Box) {
  const { request: updateBox, loading: loadingUpdateBox } = useUpdateBox()
  const { request: createLocation, loading: loadingCreateLocation } = useCreateLocation()
  const { request: updateLocation, loading: loadingUpdateLocation } = useUpdateLocation()

  const updateBoxWithLocation = async ({ location, ...values }: FormFields) => {
    if (!box) return
    let locationId = box?.location?.id && box.location.editable ? box.location.id : null

    if (location) {
      const { city, ...locationFields } = location

      if (locationId) {
        await updateLocation({
          id: locationId,
          input: {
            ...locationFields,
            cityCode: city?.code ?? '',
            latitude: Number(locationFields.latitude),
            longitude: Number(locationFields.longitude),
          },
        })
      } else {
        const createdLocation = await createLocation({
          input: {
            cityCode: city?.code ?? '',
            ...locationFields,
            latitude: Number(locationFields.latitude),
            longitude: Number(locationFields.longitude),
          },
        })
        locationId = createdLocation.id
      }

      await updateBox
    } else if (!location && box.location) {
      locationId = null
    }

    await updateBox({
      id: box.id,
      input: { ...values, locationId },
    })
  }

  return {
    updateBoxWithLocation,
    loading: loadingUpdateBox || loadingCreateLocation || loadingUpdateLocation,
  }
}
