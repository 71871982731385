import { gql } from '@apollo/client'
import { PartnerEmployee } from '@web-panel/api'

export const partnerEmployeeFields = `
  id
  firstName
  lastName
  email
  outletIds
`

export type PartnerEmployeesDocumentInput = {
  partnerId: string
  outletId?: string
  limit?: number
  offset?: number
}
export type PartnerEmployeesDocumentResponse = {
  partnerEmployeesCollection: {
    employees: PartnerEmployee[]
    hasNext: boolean
  }
}

export const PartnerEmployeesDocument = gql`
  query PartnerEmployees($partnerId: String!, $outletId: String, $limit: Int, $offset: Int) {
    partnerEmployeesCollection(partnerId: $partnerId, outletId: $outletId, limit: $limit, offset: $offset) @rest(type: "PartnerEmployeesCollection", path: "/partners-service/partners/{args.partnerId}/employees?{args}") {
      employees @type(name: "PartnerEmployee") {
        ${partnerEmployeeFields}
      }
      hasNext
    }
  }
`

export type PartnerEmployeeDocumentInput = {
  partnerId: string
  employeeId: string
}
export type PartnerEmployeeDocumentResponse = {
  employee: PartnerEmployee
}
export const PartnerEmployeeDocument = gql`
  query PartnerEmployee($partnerId: String!, $employeeId: String!) {
    employee(partnerId: $partnerId, employeeId: $employeeId) @rest(type: "PartnerEmployee", path: "/partners-service/partners/{args.partnerId}/employees/{args.employeeId}") {
      ${partnerEmployeeFields}
    }
  }
`

export type PartnerEmployeeDeleteDocumentInput = {
  partnerId: string
  employeeId: string
}
export type PartnerEmployeeDeleteDocumentResponse = void
export const PartnerEmployeeDeleteDocument = gql`
  mutation PartnerEmployee($partnerId: String!, $employeeId: String!) {
    deleteEmployee(partnerId: $partnerId, employeeId: $employeeId)
      @rest(
        type: "NoResponse"
        path: "/partners-service/partners/{args.partnerId}/employees/{args.employeeId}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`

export type PartnerEmployeeUpdateDocumentInput = {
  partnerId: string
  employeeId: string
  input: {
    firstName: string
    lastName: string
    email: string
  }
}
export type PartnerEmployeeUpdateDocumentResponse = {
  updatePartnerEmployee: PartnerEmployee
}

export const PartnerEmployeeUpdateDocument = gql`
  mutation UpdatePartnerEmployee($partnerId: String!, $employeeId: string!, $input: any) {
    updatePartnerEmployee: publish(partnerId: $partnerId, employeeId: $employeeId, input: $input) @rest(type: "PartnerEmployee", path: "/partners-service/partners/{args.partnerId}/employees/{args.employeeId}", method: "PUT") {
      ${partnerEmployeeFields}
    }
  }
`

export type PartnerEmployeeCreateDocumentInput = {
  partnerId: string
  input: {
    firstName: string
    lastName: string
    email: string
    outletIds: string[]
  }
}
export type PartnerEmployeeCreateDocumentResponse = void

export const PartnerEmployeeCreateDocument = gql`
  mutation UpdatePartnerEmployee($partnerId: String!, $input: any) {
    updatePartnerEmployee: publish(partnerId: $partnerId, input: $input)
      @rest(
        type: "NoResponse"
        path: "/partners-service/partners/{args.partnerId}/employees"
        method: "POST"
      ) {
      NoResponse
    }
  }
`

export type PartnerEmployeeAssignDocumentInput = {
  partnerId: string
  employeeId: string
  outletId: string
}
export type PartnerEmployeeAssignDocumentResponse = void

export const PartnerEmployeeAssignDocument = gql`
  mutation AssignPartnerEmployee($partnerId: String!, $employeeId: String!, $outletId: String!) {
    assignPartnerEmployee: publish(
      partnerId: $partnerId
      employeeId: $employeeId
      outletId: $outletId
      input: {}
    )
      @rest(
        type: "NoResponse"
        path: "/partners-service/partners/{args.partnerId}/employees/{args.employeeId}/assign/{args.outletId}"
        method: "PUT"
      ) {
      NoResponse
    }
  }
`

export type PartnerEmployeeUnassignDocumentInput = {
  partnerId: string
  employeeId: string
  outletId: string
}
export type PartnerEmployeeUnassignDocumentResponse = void

export const PartnerEmployeeUnassignDocument = gql`
  mutation UnassignPartnerEmployee($partnerId: String!, $employeeId: String!, $outletId: String!) {
    unassignPartnerEmployee: publish(
      partnerId: $partnerId
      employeeId: $employeeId
      outletId: $outletId
    )
      @rest(
        type: "NoResponse"
        path: "/partners-service/partners/{args.partnerId}/employees/{args.employeeId}/assign/{args.outletId}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`
