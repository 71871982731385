import { FC } from 'react'
import { SelectableList } from '@web-panel/shared'
import { BatchStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { useFilters } from './FiltersContex'

const Filters: FC = () => {
  const { filters, setFilter } = useFilters()
  const { formatMessage } = useIntl()

  return (
    <div className="flex flex-col">
      <SelectableList
        label={formatMessage({ id: 'models.batch.status' })}
        value={filters.status}
        options={Object.values(BatchStatus)}
        optionTitle={(option) => formatMessage({ id: `batch-statuses.${option}` })}
        onSelect={(status) => setFilter('status', status)}
      />
    </div>
  )
}

export default Filters
