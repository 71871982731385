import { Location } from '../data-types'

export function locationAddressString(location: Location): string {
  return [
    `${location.street} ${location.houseNumber}`,
    `${location.postalCode} ${location.city.name}`,
    location.country.name,
  ].join(', ')
}

export function shortLocationAddressString(location: Location): string {
  return [`${location.street} ${location.houseNumber}`, `${location.postalCode}`].join(', ')
}
