import { FC, useMemo } from 'react'
import { TableGrid, Button, FormattedCurrency } from '@web-panel/shared'
import { ConsumableProductItemType } from '@web-panel/api'
import { useIntl, FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import _difference from 'lodash/difference'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useProductItemConsumables, useDeleteProductItemConsumable } from '@web-panel/oc-api'

export const ConsumablesTab: FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const {
    data: consumables = [],
    refetch: refetchConsumables,
    loading,
  } = useProductItemConsumables({
    fetchPolicy: 'cache-and-network',
  })
  const { request: deleteConsumable } = useDeleteProductItemConsumable()
  const { can, cannot } = useRoleAuthorization()

  const disabledCreate = useMemo(() => {
    if (loading) return false

    const usedTypes = consumables.map(({ consumableType }) => consumableType)
    const diff = _difference(Object.values(ConsumableProductItemType), usedTypes)

    return diff.length === 0
  }, [consumables, loading])

  const onDeleteConsuamble = async (id: string) => {
    await deleteConsumable({ id })
    await refetchConsumables()
  }

  return (
    <TableGrid
      collection={consumables}
      loading={loading}
      fields={[
        {
          title: formatMessage({ id: 'models.consumable-product-item.type' }),
          render: ({ consumableType }) => (
            <FormattedMessage id={`consumable-product-item-types.${consumableType}`} />
          ),
        },
        {
          title: formatMessage({ id: 'models.consumable-product-item.cost' }),
          render: ({ cost, currency }) => (
            <FormattedCurrency value={cost} currency={currency.code} />
          ),
        },
      ]}
      onClickRow={({ id }) => navigate(`/settings/consumables/${id}`)}
      rowClickable={can('consumable', 'preview')}
      rowActions={[
        {
          title: formatMessage({ id: 'common.actions.details' }),
          icon: 'visibility',
          onClick: ({ id }) => navigate(`/settings/consumables/${id}`),
          disabled: cannot('consumable', 'preview'),
        },
        {
          title: formatMessage({ id: 'common.actions.edit' }),
          icon: 'edit',
          onClick: ({ id }) => navigate(`/settings/consumables/${id}/edit`),
          disabled: cannot('consumable', 'update'),
          mainAction: true,
        },
        {
          title: formatMessage({ id: 'common.actions.delete' }),
          icon: 'delete',
          onClick: ({ id }) => onDeleteConsuamble(id),
          disabled: cannot('consumable', 'delete'),
          confirmation: {
            title: formatMessage({ id: 'confirmation-dialogs.delete-consumable.title' }),
            acceptTitle: formatMessage({ id: 'common.yes' }),
            cancelTitle: formatMessage({ id: 'common.no' }),
          },
        },
      ]}
      emptyLabel={formatMessage({ id: 'common.no-data' })}
      addItemButton={
        can('consumable', 'create') ? (
          <Button
            key="create"
            variant="contained"
            color="primary"
            disabled={disabledCreate}
            onClick={() => navigate('/settings/consumables/create')}
          >
            <FormattedMessage id="common.actions.create" />
          </Button>
        ) : null
      }
    />
  )
}
