import { FC } from 'react'
import { Typography } from '@web-panel/shared'
import { FormattedMessage } from 'react-intl'

type CityIconProps = {
  url: string | null
}

export const CityIcon: FC<CityIconProps> = ({ url }) => {
  return (
    <div className="flex w-320 h-320 justify-center items-center">
      {url ? (
        <img
          className="w-320 h-320"
          src={url}
          alt="city icon"
          style={{ backgroundColor: '#BBBBBB' }}
        />
      ) : (
        <Typography variant="h4" color="gray">
          <FormattedMessage id="common.labels.no-icon" />
        </Typography>
      )}
    </div>
  )
}
