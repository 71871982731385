import { FC } from 'react'
import { ConsumerInfo, formatIBAN } from '@web-panel/api'
import {
  HorizontalTable,
  HorizontalTableRow,
  HorizontalTableSpan,
  HorizontalTableSection,
  TableGrid,
  useFormatDate,
  isFeatureFlagEnabled,
} from '@web-panel/shared'
import { FormattedNumber, useIntl } from 'react-intl'

type FinancialTabProps = {
  consumerInfo: ConsumerInfo
}

const FinancialTab: FC<FinancialTabProps> = ({ consumerInfo }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const { creditCard, bankAccount, wwfAccount } = consumerInfo.paymentMethods

  return (
    <HorizontalTable>
      <HorizontalTableSection
        isFirstInTable
        title={formatMessage({ id: 'models.consumer-info.balance' })}
      />
      <HorizontalTableSpan>
        <TableGrid
          collection={consumerInfo.balance}
          fields={[
            {
              title: formatMessage({ id: 'models.wallet.currency' }),
              render: ({ currency }) => currency.name,
            },
            {
              title: formatMessage({ id: 'models.wallet.amount' }),
              render: ({ amount }) => <FormattedNumber value={amount} />,
            },
            {
              isRenderable: isFeatureFlagEnabled('neom'),
              title: formatMessage({ id: 'models.wallet.available-amount' }),
              render: ({ availableAmount }) => <FormattedNumber value={availableAmount} />,
            },
            {
              isRenderable: isFeatureFlagEnabled('neom'),
              title: formatMessage({ id: 'models.wallet.minimal-amount' }),
              render: ({ minimalAmount }) => <FormattedNumber value={minimalAmount} />,
            },
            {
              title: formatMessage({ id: 'models.wallet.update-date' }),
              render: ({ updateDate }) => formatDate('datetime', updateDate),
            },
          ]}
        />
      </HorizontalTableSpan>
      {creditCard && (
        <>
          <HorizontalTableSection
            title={formatMessage({ id: 'models.consumer-info.credit-card' })}
          />
          <HorizontalTableRow title={formatMessage({ id: 'models.credit-card.card-number' })}>
            {creditCard.cardNumber}
          </HorizontalTableRow>
        </>
      )}
      {bankAccount && (
        <>
          <HorizontalTableSection
            title={formatMessage({ id: 'models.consumer-info.payment-methods.bank-account' })}
          />
          <HorizontalTableRow title={formatMessage({ id: 'models.bank-account.name' })}>
            {[bankAccount.firstName, bankAccount.lastName].join(' ')}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.bank-account.iban' })}>
            {formatIBAN(bankAccount.iban)}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.bank-account.country' })}>
            {bankAccount.country.name}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.bank-account.city' })}>
            {bankAccount.city}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.bank-account.address' })}>
            {bankAccount.address}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.bank-account.postal-code' })}>
            {bankAccount.postalCode}
          </HorizontalTableRow>
        </>
      )}
      {wwfAccount && (
        <>
          <HorizontalTableSection
            title={formatMessage({ id: 'models.consumer-info.wwf-account' })}
          />
          <HorizontalTableRow title={formatMessage({ id: 'models.wwf-account.country' })}>
            {wwfAccount.country.name}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.wwf-account.city' })}>
            {wwfAccount.city}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.wwf-account.postal-code' })}>
            {wwfAccount.postalCode}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.wwf-account.address' })}>
            {wwfAccount.address}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.wwf-account.name' })}>
            {[wwfAccount.firstName, wwfAccount.lastName].join(' ')}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.wwf-account.birthdate' })}>
            {wwfAccount.birthdate}
          </HorizontalTableRow>
        </>
      )}
    </HorizontalTable>
  )
}

export default FinancialTab
