import { gql } from '@apollo/client'
import {
  AnalyticAggregationType,
  SerialValueMetricsData,
  SerialCountMetricsData,
  TotalCountMetricsData,
  TotalValueMetricsData,
  serialValueMetricsDataFields,
  serialCountMetricsDataFields,
  totalValueMetricsDataFields,
  totalCountMetricsDataFields,
  AnalyticItemSeriesType,
  AnalyticTotalCountType,
  AnalyticItemsNotReutnedSummary,
  analyticItemsNotReutnedSummaryFields,
  totalItemsMetricsCountDataFields,
  TotalItemsMetricsCountData,
} from '@web-panel/api'

export type CO2SavedSeriesDocumentInput = {
  input: {
    partnerId: string
    outletIds: string[]
    aggregationType?: AnalyticAggregationType
    startDate?: string
    endDate?: string
  }
}
export type CO2SavedSeriesDocumentResponse = {
  co2SavedSeries: {
    metrics: SerialValueMetricsData[]
  }
}

export const CO2SavedSeriesDocument = gql`
  query CO2SavedSeries($input: any) {
    co2SavedSeries(input: $input) @rest(type: "CO2SavedSeries", path: "/operations-service/analytics/date/items/co2/saved/series", method: "POST") {
      metrics {
        ${serialValueMetricsDataFields}
      }
    }
  }
`

export type ItemSeriesDocumentInput = {
  type: AnalyticItemSeriesType
  input: {
    partnerId: string
    outletIds: string[]
    aggregationType?: AnalyticAggregationType
    startDate?: string
    endDate?: string
  }
}

export type ItemSeriesDocumentResponse = {
  itemsSeries: {
    metrics: SerialCountMetricsData[]
  }
}

export const ItemSeriesDocument = gql`
  query ItemSeries($type: String!, $input: any) {
    itemsSeries(type: $type, input: $input) @rest(type: "ItemSeries", path: "/operations-service/analytics/date/items/{args.type}/series", method: "POST") {
      metrics {
        ${serialCountMetricsDataFields}
      }
    }
  }
`

export type TotalItemsDocumentInput = {
  type: AnalyticTotalCountType
  input: {
    partnerId: string
    outletIds: string[]
  }
}
export type TotalItemsDocumentResponse = {
  totalItems: {
    counters: TotalCountMetricsData[]
  }
}

export const TotalItemsDocument = gql`
  query TotalItems($type: String!, $input: any) {
    totalItems(type: $type, input: $input) @rest(type: "TotalItemsDelivered", path: "/operations-service/analytics/total/items/{args.type}", method: "POST") {
      counters {
        ${totalCountMetricsDataFields}
      }
    }
  }
`

export type TotalConsumersGainedDocumentInput = {
  input: {
    partnerId: string
    outletIds: string[]
  }
}

export type TotalConsumersGainedDocumentResponse = {
  totalConsumersGained: {
    counters: TotalCountMetricsData[]
  }
}

export const TotalConsumersGainedDocument = gql`
  query TotalConsumersGained($input: any) {
    totalConsumersGained(input: $input) @rest(type: "TotalConsumersGained", path: "/operations-service/analytics/total/consumers", method: "POST") {
      counters {
        ${totalCountMetricsDataFields}
      }
    }
  }
`

export type TotalCO2SavedDocumentInput = {
  input: {
    partnerId: string
    outletIds: string[]
  }
}

export type TotalCO2SavedDocumentResponse = {
  totalCO2Saved: {
    metrics: TotalValueMetricsData[]
  }
}

export const TotalCO2SavedDocument = gql`
  query TotalCO2Saved($input: any) {
    totalCO2Saved(input: $input)
      @rest(type: "TotalCO2Saved", path: "/operations-service/analytics/total/items/co2/saved", method: "POST") {
        metrics {
          ${totalValueMetricsDataFields}
        }
      }
  }
`

export type AnalyticsTotalOrdersDocumentInput = {
  partnerId: string
  outlets: string
  filters: {
    monthYearDate?: string
  }
}
export type AnalyticsTotalOrdersDocumentResponse = {
  analyticsTotalOrders: {
    ordersCount: TotalCountMetricsData[]
    totalOrders: number
  }
}
export const AnalyticsTotalOrdersDocument = gql`
  query AnalyticsTotalOrders($partnerid: String!, $outlets: String!, $filters: any) {
    analyticsTotalOrders(partnerId: $partnerId, outlets: $outlets, filters: $filters)
      @rest(type: "AnalyticsTotalOrders", path: "/operations-service/analytics/total/orders/delivered?{args.outlets}&partnerId={args.partnerId}&{args.filters}", method: "GET") {
        ordersCount {
          ${totalCountMetricsDataFields}
        }
        totalOrders
      }
  }
`

export type AnalyticsTotalItemsSummaryDocumentInput = {
  partnerId: string
  outlets: string
  filters: {
    monthYearDate?: string
  }
}
export type AnalyticsTotalItemsSummaryDocumentResponse = {
  analyticsTotalItemsSummary: {
    itemMetricCounters: TotalItemsMetricsCountData[]
    totalReturnedCount: number
    totalDeliveredCount: number
  }
}

export const AnalyticsTotalItemsSummaryDocument = gql`
  query AnalyticsTotalItemsSummary($partnerId: String, $outlets: String!, $filters: any) {
    analyticsTotalItemsSummary(partnerId: $partnerId, outlets: $outlets, filters: $filters)
      @rest(
        type: "AnalyticsTotalItemsSummary"
        path: "/operations-service/analytics/total/items/summary?{args.outlets}&partnerId={args.partnerId}&{args.filters}"
        method: "GET"
      ) {
      itemMetricCounters {
        ${totalItemsMetricsCountDataFields}
      }
      totalReturnedCount
      totalDeliveredCount
    }
  }
`

export type AnalyticsTotalItemsNotReturnedSummaryDocumentInput = {
  partnerId: string
  outlets: string
}
export type AnalyticsTotalItemsNotReturnedSummaryDocumentResponse = {
  analyticsTotalItemsNotReturnedSummary: {
    nonReturnedStatisticsSummaryByOutlets: AnalyticItemsNotReutnedSummary[]
    totalNotReturned: number
    totalNotReturnedFrom30To60Days: number
    totalNotReturnedFrom60To90Days: number
    totalNotReturnedFrom90To120Days: number
    totalNotReturnedIn120Days: number
  }
}

export const AnalyticsTotalItemsNotReturnedSummaryDocument = gql`
  query AnalyticsTotalItemsNotReuturnedSummary($partnerId: String!, $outlets: String!) {
    analyticsTotalItemsNotReturnedSummary(partnerId: $partnerId, outlets: $outlets)
      @rest(
        type: "AnalyticsTotalItemsNotReturnedSummary"
        path: "/operations-service/analytics/total/items/not/returned/summary?{args.outlets}&partnerId={args.partnerId}"
        method: "GET"
      ) {
        nonReturnedStatisticsSummaryByOutlets {
          ${analyticItemsNotReutnedSummaryFields}
        }
        totalNotReturned
        totalNotReturnedFrom30To60Days
        totalNotReturnedFrom60To90Days
        totalNotReturnedFrom90To120Days
        totalNotReturnedIn120Days
    }
  }
`
