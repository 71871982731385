import { FC } from 'react'
import { InfinitScroller, TableGrid, useFormatDate } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { OrderLink } from '@local/components/OrderLink'
import { UserLink } from '@local/components/UserLink'
import { OutletLink } from '@local/components/OutletLink'
import { BatchStatusChip } from '@local/components/BatchStatusChip'
import { useProductItemBatchHistory } from '@web-panel/oc-api'

type ProductItemBatchHistoryTabProps = {
  id: string
}

const ProductItemBatchHistoryTab: FC<ProductItemBatchHistoryTabProps> = ({ id }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const { data: batches = [], loading, hasMore, loadMore } = useProductItemBatchHistory({ id })

  const onLoadMore = () => {
    if (!hasMore) return
    loadMore()
  }

  return (
    <InfinitScroller onLoadMore={onLoadMore}>
      <TableGrid
        loading={loading}
        collection={batches}
        fields={[
          {
            title: formatMessage({ id: 'models.batch.id' }),
            render: ({ batchNumber }) => batchNumber,
          },
          {
            title: formatMessage({ id: 'models.batch.delivered-by' }),
            render: ({ deliveredBy }) => (deliveredBy ? <UserLink userId={deliveredBy} /> : '-'),
          },
          {
            title: formatMessage({ id: 'models.batch.status' }),
            render: ({ status }) => <BatchStatusChip status={status} />,
          },
          {
            title: formatMessage({ id: 'models.batch.delivered-outlet' }),
            render: ({ deliveredOutletId }) =>
              deliveredOutletId ? <OutletLink outletId={deliveredOutletId} /> : '-',
          },
          {
            title: formatMessage({ id: 'models.batch.delivery-date' }),
            render: ({ deliveryDate }) => (deliveryDate ? formatDate('date', deliveryDate) : '-'),
          },
          {
            title: formatMessage({ id: 'models.batch.order' }),
            render: ({ orderId }) => (orderId ? <OrderLink orderId={orderId} /> : '-'),
          },
        ]}
        onClickRow={(batch) => navigate(`/product-item-batches/${batch.id}`)}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
      />
    </InfinitScroller>
  )
}

export default ProductItemBatchHistoryTab
