import {
  buildModal,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  ModalBodyProps,
} from '@web-panel/shared'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

type BoxUnfinishedTasksWarningModalProps = ModalBodyProps & {
  boxId: string
  onSubmit: () => void
}

const BoxUnfinishedTasksWarning: FC<BoxUnfinishedTasksWarningModalProps> = ({
  onClose,
  onSubmit,
}) => {
  return (
    <>
      <DialogTitle>
        <FormattedMessage id="modals.empty-box.title" />
      </DialogTitle>
      <DialogContent>
        <FormattedMessage id="modals.empty-box.message" />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="common.actions.cancel" />
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          <FormattedMessage id="common.actions.confirm" />
        </Button>
      </DialogActions>
    </>
  )
}

export const BoxUnfinishedTasksWarningModal = buildModal(BoxUnfinishedTasksWarning, {
  fullWidth: true,
  maxWidth: 'md',
})
