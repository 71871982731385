import { FC } from 'react'
import { FormLabel, LabeledSelect, TextField } from '@web-panel/shared'
import { FormikProps } from 'formik'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import _uniq from 'lodash/uniq'
import { ProductItemModelType } from '@web-panel/api'

const brandsArray = ['BERN', 'KOOKY-WHITE-1', 'RASTAL', 'ROCHE', 'TAVERO', 'VICAFE', 'COCA-COLA']

export type FormFields = {
  itemCodes: string[]
  comment: string
  brand: string
  itemModelId: string
}

export const defaultValues: FormFields = {
  itemCodes: [],
  comment: '',
  brand: '',
  itemModelId: '',
}

export const validationSchema = Yup.object().shape({
  itemCodes: Yup.array()
    .of(Yup.string().required())
    .compact((val: string | undefined) => !val || val.length === 0)
    .ensure()
    .min(1)
    .required(),
  comment: Yup.string(),
  brand: Yup.string(),
  itemModelId: Yup.string().required(),
})

export function castValues(values: FormFields) {
  const { itemCodes, comment, brand, itemModelId } = validationSchema.cast(values) as FormFields

  return {
    itemCodes: _uniq(itemCodes),
    comment,
    brand,
    itemModelId,
  }
}

type ProductItemBatchFormProps = FormikProps<FormFields> & {
  modelTypes: ProductItemModelType[]
}

const ProductItemBatchForm: FC<ProductItemBatchFormProps> = ({
  values,
  errors,
  setFieldValue,
  handleSubmit,
  handleChange,
  modelTypes,
}) => {
  const { formatMessage } = useIntl()

  const { itemCodes } = castValues(values)
  const itemsAmount = itemCodes.length
  const cupModels = modelTypes[0]?.models

  return (
    <div className="p-16">
      <form onSubmit={handleSubmit}>
        <TextField
          type="text"
          fullWidth
          multiline
          rows={15}
          className="flex-1 mr-16"
          label={formatMessage({
            id: 'pages.product-item-batch.new.form.items',
          })}
          value={values.itemCodes.join('\r\n')}
          onChange={(e) => {
            setFieldValue('itemCodes', e.target.value.split('\n'))
          }}
          error={!!errors.itemCodes}
          helperText={formatMessage(
            { id: 'models.product-item.with-amount' },
            { amount: itemsAmount }
          )}
        />

        <div className="row mt-14">
          <FormLabel className="ml-10">
            {formatMessage({
              id: 'pages.product-item-batch.new.form.adapt-model-brand-title',
            })}
          </FormLabel>
          <div className="column mt-10 flex">
            <LabeledSelect
              className="flex flex-1 mr-10"
              label={formatMessage({
                id: 'pages.product-item-batch.new.form.capacity',
              })}
              value={values.itemModelId || ''}
              onChange={(e) => setFieldValue('itemModelId', e.target.value)}
              options={cupModels?.filter((n) => n)?.map(({ id }) => id) || []}
              optionTitle={(modelId) => modelId ?? ''}
              error={!!errors.itemModelId}
            />
            <LabeledSelect
              className="flex flex-1 ml-10"
              label={formatMessage({
                id: 'pages.product-item-batch.new.form.brand',
              })}
              value={values?.brand}
              onChange={(e) => setFieldValue('brand', e.target.value)}
              options={brandsArray?.filter((n) => n) || []}
              optionTitle={(brand) => brand ?? ''}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default ProductItemBatchForm
