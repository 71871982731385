import { FC } from 'react'
import { useSearchParams, Navigate } from 'react-router-dom'
import { useAuthContext } from '@web-panel/api'
import { DispenserRouter } from './DispenserRouter'
import { ExternalRouterModel } from './helpers'
import { ContainerExternalNavigator } from './ExternalContainerNavigator'

type ExternalRouterProps = {
  isAuthenticated: boolean
  everSignedIn: boolean
}

export const ExternalRouterComponent: FC<ExternalRouterProps> = ({
  isAuthenticated,
  everSignedIn,
}) => {
  const [params] = useSearchParams()
  const model = (params.get('model') ?? params.get('m')) as ExternalRouterModel | null
  const id = params.get('id') ?? params.get('i')

  if (!id) return <Navigate to="/" replace />

  switch (model) {
    case 'dispenser':
      return <DispenserRouter dispenserId={id} />
    case 'order':
      return <Navigate to={`/orders/${id}#batches`} replace state={{ openScanner: true }} />
    case 'batch':
      return <Navigate to={`/product-item-batches/${id}`} replace />
    case 'box':
      if (isAuthenticated || everSignedIn) {
        return <Navigate to={`/boxes/${id}`} replace />
      }
      return <Navigate to={`/public/box/${id}/report`} replace />
    case 'container':
      return <ContainerExternalNavigator qrCode={id} />
    default:
      return <Navigate to="/" replace />
  }
}

export default () => {
  const { authenticated, lastSignIn } = useAuthContext()

  return <ExternalRouterComponent isAuthenticated={authenticated} everSignedIn={!!lastSignIn} />
}
