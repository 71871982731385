import { FC } from 'react'
import { TextField, LabeledSelect } from '@web-panel/shared'
import { GeoCityStatus } from '@web-panel/api'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import { useIntl } from 'react-intl'

export type FormFields = {
  name: string
  latitude: number
  longitude: number
  status: GeoCityStatus
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  latitude: Yup.number().required(),
  longitude: Yup.number().required(),
})

type EditCityFormProps = FormikProps<FormFields>
export const EditCityForm: FC<EditCityFormProps> = ({
  values,
  errors,
  handleChange,
  handleSubmit,
}) => {
  const { formatMessage } = useIntl()

  return (
    <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit}>
      <div className="flex flex-col p-16 sm:p-24 max-w-2xl">
        <TextField
          label={formatMessage({ id: 'models.geo-city.name' })}
          onChange={handleChange('name')}
          value={values.name}
          error={!!errors.name}
        />
        <TextField
          className="mt-16"
          type="number"
          label={formatMessage({ id: 'models.geo-city.latitude' })}
          onChange={handleChange('latitude')}
          value={values.latitude}
          error={!!errors.latitude}
        />
        <TextField
          className="mt-16"
          type="number"
          label={formatMessage({ id: 'models.geo-city.longitude' })}
          onChange={handleChange('longitude')}
          value={values.longitude}
          error={!!errors.longitude}
        />
        <LabeledSelect
          className="mt-16"
          label={formatMessage({ id: 'models.geo-city.status' })}
          onChange={(e) => handleChange('status')(e.target.value)}
          value={values.status}
          options={Object.values(GeoCityStatus)}
          optionTitle={(status) => formatMessage({ id: `geo-city-statuses.${status}` })}
        />
      </div>
    </form>
  )
}
