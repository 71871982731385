import { FC } from 'react'
import { Typography } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'

function decToHex(value: number): string {
  const hex = value.toString(16).toUpperCase()
  if (hex.length % 2 === 0) return hex

  return `0${hex}`
}

type BoxActivityErrorCodeProps = {
  code: string
}

export const BoxActivityErrorCode: FC<BoxActivityErrorCodeProps> = ({ code }) => {
  const { formatMessage } = useIntl()

  const hexCode = decToHex(parseInt(code, 10))
  const periferyCode = hexCode.slice(0, 2)

  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <Typography variant="body1">
          <FormattedMessage id="models.box-activity.error-code" />
          :&nbsp;
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          {hexCode}
        </Typography>
      </div>
      <div className="flex flex-row">
        <Typography variant="body1">
          <FormattedMessage id="models.box-activity.error-code-periphery" />
          :&nbsp;
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          <FormattedMessage
            id={`models.box-activity.error-code-periphery.${periferyCode}`}
            defaultMessage={formatMessage({
              id: 'models.box-activity.error-code-periphery.unknown',
            })}
          />
        </Typography>
      </div>
      <div>
        <Typography variant="body2">
          <FormattedMessage
            id={`models.box-activity.error-code-explanation.${hexCode}`}
            defaultMessage={formatMessage({
              id: 'models.box-activity.error-code-explanation.unknown',
            })}
          />
        </Typography>
      </div>
    </div>
  )
}
