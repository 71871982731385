import { FC } from 'react'
import { AppPage, Button, CardedContent, getISODate, useNotifications } from '@web-panel/shared'
import { Partner } from '@web-panel/api'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { FormattedMessage, useIntl } from 'react-intl'
import { Formik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import modelIcons from '@local/model-icons'
import startOfMonth from 'date-fns/startOfMonth'
import addMonths from 'date-fns/addMonths'
import { useCreateBoxRental } from '@web-panel/oc-api'
import { CreateRentalForm, FormFields, validationSchema } from './Forms/CreateForm'

type PageComponentProps = {
  partner: Partner | null
}

export const PageComponent: FC<PageComponentProps> = ({ partner }) => {
  const { formatMessage } = useIntl()
  const { request: createRental, loading: loadingCreate } = useCreateBoxRental()
  const { pushNotification } = useNotifications()
  const navigate = useNavigate()

  const onSubmit = async (fields: FormFields) => {
    try {
      await createRental({
        partnerId: fields.partner!.id,
        outletId: fields.outletId,
        startDate: getISODate(fields.startDate),
        endDate: fields.endDate ? getISODate(fields.endDate) : null,
        amount: fields.amount,
      })
      navigate(-1)
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-created' })
      )
    } catch (err) {
      console.error(err)
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }
  const nextMonthStart = addMonths(startOfMonth(new Date()), 1)

  const initialValues: FormFields = {
    partner,
    outletId: null,
    startDate: nextMonthStart,
    endDate: null,
    amount: 1,
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, isValid }) => {
        const headerActionButtons = [
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            loading={loadingCreate}
            disabled={!isValid}
            onClick={() => handleSubmit()}
          >
            <FormattedMessage id="common.actions.create" />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            title={formatMessage({ id: 'routes.partners.rentals.create' })}
            icon={modelIcons.rental}
            actionButtons={headerActionButtons}
          >
            <CardedContent padded>
              <CreateRentalForm showPartnerSelector={!partner} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export default pageWithRoleAuthorization(
  () => {
    const { state } = useLocation()

    return <PageComponent partner={state?.partner ?? null} />
  },
  ({ can }) => can('boxRental', 'create')
)
