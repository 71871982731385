import { FC } from 'react'
import { HorizontalTable, HorizontalTableRow } from '@web-panel/shared'
import { GeoCountry } from '@web-panel/api'
import { useIntl, FormattedMessage } from 'react-intl'

type GeneralTabProps = {
  country: GeoCountry
}
export const GeneralTab: FC<GeneralTabProps> = ({ country }) => {
  const { formatMessage } = useIntl()

  return (
    <HorizontalTable>
      <HorizontalTableRow title={formatMessage({ id: 'models.geo-country.code' })}>
        {country.code}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.geo-country.name' })}>
        {country.name}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.geo-country.enabled-for-billing' })}>
        <FormattedMessage id={country.enabledForBilling ? 'common.yes' : 'common.no'} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.geo-country.enabled-for-location' })}>
        <FormattedMessage id={country.enabledForLocation ? 'common.yes' : 'common.no'} />
      </HorizontalTableRow>
    </HorizontalTable>
  )
}
