import { FC } from 'react'
import { Order } from '@web-panel/api'
import { HorizontalTableSection, HorizontalTableRow } from '@web-panel/shared'
import { useIntl } from 'react-intl'

type ContactSectionProps = {
  order: Order
}

export const ContactSection: FC<ContactSectionProps> = ({ order }) => {
  const { formatMessage } = useIntl()

  return (
    <>
      <HorizontalTableSection title={formatMessage({ id: 'models.order.contact-person' })} />
      <HorizontalTableRow title={formatMessage({ id: 'models.order.contact-person.name' })}>
        {[order.firstName, order.lastName].join(' ')}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.order.contact-person.phone' })}>
        {order.phone}
      </HorizontalTableRow>
    </>
  )
}
