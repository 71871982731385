import { FC } from 'react'
import { Icon } from '@mui/material'
import { motion } from 'framer-motion'
import classNames from 'classnames'

type FilterToggleButtonProprs = {
  className?: string
  onClick: () => void
}

const FilterToggleButton: FC<FilterToggleButtonProprs> = ({ className, onClick }) => {
  return (
    <motion.div
      className={classNames('flex flex-row justify-end', className)}
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0, transition: { delay: 0.4 } }}
    >
      <Icon onClick={onClick}>filter_alt</Icon>
    </motion.div>
  )
}

export default FilterToggleButton
