import { FC } from 'react'

import { Navigate } from 'react-router-dom'

import { useContainerByQrCode } from '@web-panel/oc-api'

type ContainerExternalNavigatorProps = {
  qrCode: string
}

export const ContainerExternalNavigator: FC<ContainerExternalNavigatorProps> = ({ qrCode }) => {
  const { data: container, loading } = useContainerByQrCode({ qrCode })
  if (!container && loading) return null

  if (container) {
    return <Navigate to={`/containers/${container.id}`} replace />
  }
  return <Navigate to="/" replace />
}
