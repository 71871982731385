import { gql } from '@apollo/client'
import {
  Consumer,
  consumerFields,
  ConsumerInfo,
  consumerInfoFields,
  UserStatus,
  UserRole,
} from '@web-panel/api'

export type ConsumersDocumentInput = {
  limit: number
  offset?: number
  search?: string
  status?: UserStatus
  userNumber?: string
}
export type ConsumersDocumentResponse = {
  consumersCollection: {
    users: Consumer[]
    hasNext: boolean
  }
}
export const ConsumersDocument = gql`
  query Consumers($limit: Int!, $offset: Int, $search: String, $status: String, $userNumber: String) {
    consumersCollection(limit: $limit, offset: $offset, role: "${UserRole.Consumer}", search: $search, status: $status, userNumber: $userNumber) @rest(type: "ConsumersCollection", path: "/users-service/users?{args}") {
      users @type(name: "Consumer") {
        ${consumerFields}
      }
      hasNext
    }
  }
`

export type ConsumerDocumentInput = {
  id: string
}
export type ConsumerDocumentResponse = {
  consumer: Consumer & {
    info: ConsumerInfo
  }
}
export const ConsumerDocument = gql`
  query Consumer($id: String!) {
    consumer(id: $id) @rest(type: "Consumer", path: "/users-service/users/{args.id}") {
      ${consumerFields}
      info @rest(type: "ConsumerInfo", path: "/finance-service/oc/accounts/consumer/{exportVariables.id}") {
        ${consumerInfoFields}
      }
    }
  }
`

export type ConsumerInfoDocumentInput = {
  id: string
}
export type ConsumerInfoDocumentResponse = {
  consumerInfo: ConsumerInfo
}
export const ConsumerInfoDocument = gql`
  query ConsumerInfo($id: String!) {
    consumerInfo(id: $id) @rest(type: "ConsumerInfo", path: "/finance-service/oc/accounts/consumer/{args.id}") {
      ${consumerInfoFields}
    }
  }
`

export type UpdateConsumerWalletDocumentInput = {
  id: string
  input: {
    minimalAmount: number
    currencyCode: string
  }
}
export type UpdateConsumerWalletDocumentResponse = {
  updateConsumerWallet: ConsumerInfo
}
export const UpdateConsumerWalletDocument = gql`
  mutation UpdateConsumerWallet($id: String!, $input: any) {
    updateConsumerWallet(id: $id, input: $input) @rest(type: "ConsumerInfo", path: "/finance-service/oc/accounts/consumer/{args.id}/wallet", method: "PUT") {
      ${consumerInfoFields}
    }
  }
`

export type TopUpConsumerWalletDocumentInput = {
  id: string
  input: {
    amount: number
    currencyCode: string
    countryCode: string
  }
}
export type TopUpConsumerWalletDocumentResponse = {
  topUpConsumerWallet: ConsumerInfo
}
export const TopUpConsumerWalletDocument = gql`
  mutation TopUpConsumerWallet($id: String!, $input: any) {
    topUpConsumerWallet(id: $id, input: $input) @rest(type: "ConsumerInfo", path: "/finance-service/oc/accounts/consumer/{args.id}/wallet", method: "POST") {
      ${consumerInfoFields}
    }
  }
`
