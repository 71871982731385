import { FC, useMemo } from 'react'
import { useCountries, Country } from '@web-panel/api'
import { Autocomplete, TextField } from '@web-panel/shared'
import { AutocompleteInputProps } from './types'

type CountryCodeAutocompletePops = AutocompleteInputProps<string> & {
  enabledFor?: 'location' | 'billing'
}
const CountryCodeAutocomplete: FC<CountryCodeAutocompletePops> = ({
  fullWidth,
  className,
  disabled,
  value,
  onChange,
  error,
  label,
  enabledFor,
}) => {
  const { data: countries = [] } = useCountries()

  const [filteredCountries, country] = useMemo(() => {
    const ctrs = countries.filter(({ enabledForBilling, enabledForLocation }) => {
      if (enabledFor === 'billing') return enabledForBilling
      if (enabledFor === 'location') return enabledForLocation
      return true
    })
    const ctr = ctrs.find(({ code }) => value === code) ?? null
    return [ctrs, ctr]
  }, [value, countries, enabledFor])

  return (
    <Autocomplete<Country>
      key={country?.code}
      disablePortal
      fullWidth={fullWidth}
      disabled={disabled}
      options={filteredCountries}
      className={className}
      renderInput={({ inputProps, ...params }) => (
        <TextField
          error={error}
          {...params}
          label={label}
          inputProps={{ ...inputProps, autoComplete: 'do-not-autocomplete' }}
        />
      )}
      getOptionLabel={({ name }) => name}
      onChange={(_data, val) => onChange(val?.code ?? '')}
      value={country}
      isOptionEqualToValue={(option, val) => option.code === val?.code}
    />
  )
}

export default CountryCodeAutocomplete
