import { FC } from 'react'
import { Partner } from '@web-panel/api'
import { Avatar } from '@web-panel/shared'
import classNames from 'classnames'

type PartnerAvatarProps = {
  partner: Partner
  variant?: 'inline' | 'preview'
  className?: string
  onClick?: () => void
}

const PartnerAvatar: FC<PartnerAvatarProps> = ({
  variant = 'inline',
  className,
  onClick,
  partner,
}) => {
  const sx = variant === 'preview' ? { width: '30rem', height: '30rem' } : {}

  return (
    <Avatar
      data-testid="PartnerAvatar"
      variant="circular"
      className={classNames(className, {
        'text-96': variant === 'preview',
        'cursor-pointer': onClick,
      })}
      src={partner.logo ?? undefined}
      onClick={onClick}
      sx={sx}
    >
      {partner.name[0].toUpperCase()}
    </Avatar>
  )
}

export default PartnerAvatar
