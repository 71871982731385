import { FC } from 'react'
import { Order } from '@web-panel/api'
import {
  Button,
  FormattedCurrency,
  HorizontalTable,
  HorizontalTableRow,
  HorizontalTableSection,
  Typography,
  useFormatDate,
  useNotifications,
} from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import OrderStatusChip from '@local/components/OrderStatusChip'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { PartnerLink } from '@local/components/PartnerLink'
import { useDeleteOrderSignature, useOrder } from '@web-panel/oc-api'
import ChangeReturnTypeButton from '../../Components/ChangeReturnTypeButton'
import { PreviewTableOutletFields } from './OutletFields'
import { ContactSection } from './ContactSection'

type PreviewTableProps = {
  order: Order
}

export const PreviewTable: FC<PreviewTableProps> = ({ order }) => {
  const { pushNotification } = useNotifications()
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const { can } = useRoleAuthorization()
  const { refetch } = useOrder({ id: order.id })
  const { request: deleteSignature, loading: loadingDeleteSignature } = useDeleteOrderSignature()

  const handleDeleteSignature = async () => {
    try {
      await deleteSignature({ orderId: order.id })
      await refetch()
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-deleted' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <HorizontalTable>
      <HorizontalTableRow title={formatMessage({ id: 'models.order.id' })}>
        {order.orderNumber}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.order.create-date' })}>
        {formatDate('datetime', order.createDate)}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.order.requested-delivery-date' })}>
        {order.expectedDeliveryDateFrom && formatDate('date', order.expectedDeliveryDateFrom)}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.order.requested-delivery-time' })}>
        {order.expectedDeliveryDateFrom &&
          order.expectedDeliveryDateTo &&
          [
            formatDate('time', order.expectedDeliveryDateFrom),
            formatDate('time', order.expectedDeliveryDateTo),
          ].join(' - ')}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.order.return-type' })}>
        {can('order', 'updateReturnType') ? (
          <ChangeReturnTypeButton order={order} />
        ) : (
          <FormattedMessage id={`order-return-types.${order.returnType}`} />
        )}
      </HorizontalTableRow>
      {(can('partner', 'preview') || can('outlet', 'preview')) && (
        <HorizontalTableSection title={formatMessage({ id: 'models.order.partner' })} />
      )}
      {can('partner', 'preview') && (
        <HorizontalTableRow title={formatMessage({ id: 'models.order.partner.name' })}>
          <PartnerLink partnerId={order.partnerId} />
        </HorizontalTableRow>
      )}
      {can('outlet', 'preview') && <PreviewTableOutletFields outletId={order.outletId} />}
      <ContactSection order={order} />
      <HorizontalTableSection title={formatMessage({ id: 'models.order.commercials' })} />
      <HorizontalTableRow title={formatMessage({ id: 'models.order.payment-type' })}>
        <FormattedMessage id={`payment-types.${order.paymentType}`} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.order.status' })}>
        <OrderStatusChip status={order.status} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.order.total-cost' })}>
        <FormattedCurrency value={order.totalCost} currency={order.currencyCode.toUpperCase()} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.order.invoice-pdf' })}>
        {order.invoicePdf && (
          <a target="_blank" href={order.invoicePdf} rel="noreferrer">
            <FormattedMessage id="common.actions.download" />
          </a>
        )}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.order.delivery-pdf' })}>
        {order.deliveryPdf && (
          <a target="_blank" href={order.deliveryPdf} rel="noreferrer">
            <FormattedMessage id="common.actions.download" />
          </a>
        )}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.order.delivery-signature' })}>
        {order.deliverySignature ? (
          <div className="flex flex-row gap-8 items-center">
            <Button
              loading={loadingDeleteSignature}
              variant="text"
              color="error"
              onClick={handleDeleteSignature}
            >
              <FormattedMessage id="common.actions.delete" />
            </Button>
          </div>
        ) : (
          <Typography>-</Typography>
        )}
      </HorizontalTableRow>
      <HorizontalTableSection title="OptimoRoute" />
      <HorizontalTableRow title={formatMessage({ id: 'models.order.optimo-route-url' })}>
        {order.optimoRouteUrl && order.optimoRouteOrderNo && (
          <a target="_blank" href={order.optimoRouteUrl} rel="noreferrer">
            OptimoRoute {order.optimoRouteOrderNo}
          </a>
        )}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.order.optimo-route-status' })}>
        {order.optimoRouteStatus && (
          <FormattedMessage id={`optimo-route-statuses.${order.optimoRouteStatus}`} />
        )}
      </HorizontalTableRow>
    </HorizontalTable>
  )
}
