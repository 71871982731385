import { FC, useMemo } from 'react'
import { SelectableList, buildFilterContext } from '@web-panel/shared'
import { PaymentOutStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'

import { usePaymentOutStatusAggregation } from '@web-panel/oc-api'

type FilterFields = {
  status: PaymentOutStatus | undefined
}
export const { useFilters, withFilters } = buildFilterContext<FilterFields>({
  status: undefined,
})

type FiltersProps = {
  search?: string
}

const Filters: FC<FiltersProps> = ({ search }) => {
  const { formatMessage } = useIntl()
  const { filters, setFilter } = useFilters()
  const { data: statusAggregations = [] } = usePaymentOutStatusAggregation(
    { search },
    { fetchPolicy: 'cache-and-network' }
  )

  const aggregationsByStatus = useMemo(() => {
    return statusAggregations.reduce(
      (obj, { status, count }) => ({
        ...obj,
        [status]: count,
      }),
      {}
    ) as Record<PaymentOutStatus, number>
  }, [statusAggregations])

  return (
    <div className="flex flex-col">
      <SelectableList
        label={formatMessage({ id: 'models.payment-out.payment-out-status' })}
        value={filters.status}
        options={Object.values(PaymentOutStatus)}
        optionTitle={(option) => formatMessage({ id: `payment-out-statuses.${option}` })}
        onSelect={(status) => setFilter('status', status)}
        badge={(status) => String(aggregationsByStatus[status] ?? 0)}
      />
    </div>
  )
}

export default Filters
