import { useMutation, useQuery } from '@apollo/client'
import { CollectionQueryHook, MutationHook, QueryHook } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  PartnerEmployeesDocument,
  PartnerEmployeesDocumentInput,
  PartnerEmployeesDocumentResponse,
  PartnerEmployeeDocument,
  PartnerEmployeeDocumentInput,
  PartnerEmployeeDocumentResponse,
  PartnerEmployeeDeleteDocument,
  PartnerEmployeeDeleteDocumentInput,
  PartnerEmployeeDeleteDocumentResponse,
  PartnerEmployeeUpdateDocument,
  PartnerEmployeeUpdateDocumentInput,
  PartnerEmployeeUpdateDocumentResponse,
  PartnerEmployeeCreateDocument,
  PartnerEmployeeCreateDocumentInput,
  PartnerEmployeeCreateDocumentResponse,
  PartnerEmployeeAssignDocument,
  PartnerEmployeeAssignDocumentInput,
  PartnerEmployeeAssignDocumentResponse,
  PartnerEmployeeUnassignDocument,
  PartnerEmployeeUnassignDocumentInput,
  PartnerEmployeeUnassignDocumentResponse,
  UserOutletsDocument,
  UserOutletsDocumentResponse,
} from '../docs'

type UsePartnerEmployeesInput = Omit<PartnerEmployeesDocumentInput, 'limit' | 'offset'>
export const usePartnerEmployees: CollectionQueryHook<
  UsePartnerEmployeesInput,
  PartnerEmployeesDocumentResponse['partnerEmployeesCollection']['employees']
> = (input, options) => {
  const variables = { ...input, ...defaultCollectionVariables }

  const { data, loading, fetchMore, refetch } = useQuery<PartnerEmployeesDocumentResponse>(
    PartnerEmployeesDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.partnerEmployeesCollection.hasNext) return
    const offset = data.partnerEmployeesCollection.employees.length

    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.partnerEmployeesCollection.employees,
    loading,
    loadMore,
    hasMore: data?.partnerEmployeesCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const usePartnerEmployee: QueryHook<
  PartnerEmployeeDocumentInput,
  PartnerEmployeeDocumentResponse['employee']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<PartnerEmployeeDocumentResponse>(
    PartnerEmployeeDocument,
    { variables, ...options }
  )

  return {
    data: data?.employee,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useDeletePartnerEmployee: MutationHook<
  PartnerEmployeeDeleteDocumentInput,
  PartnerEmployeeDeleteDocumentResponse
> = (options) => {
  const [execute, { loading }] = useMutation<
    PartnerEmployeeDeleteDocumentResponse,
    PartnerEmployeeDeleteDocumentInput
  >(PartnerEmployeeDeleteDocument, options)

  const request = async (variables: PartnerEmployeeDeleteDocumentInput) => {
    await execute({
      variables,
      update(cache) {
        cache.modify({
          id: cache.identify({ id: variables.employeeId, __typename: 'PartnerEmployee' }),
          optimistic: true,
          fields(value, details) {
            return details.DELETE
          },
        })
      },
    })
  }

  return {
    request,
    loading,
  }
}

export const useUpdatePartnerEmployee: MutationHook<
  PartnerEmployeeUpdateDocumentInput,
  PartnerEmployeeUpdateDocumentResponse['updatePartnerEmployee'] | null
> = (options) => {
  const [execute, { loading }] = useMutation<PartnerEmployeeUpdateDocumentResponse>(
    PartnerEmployeeUpdateDocument,
    options
  )

  const request = async (variables: PartnerEmployeeUpdateDocumentInput) => {
    const { data } = await execute({ variables })

    return data?.updatePartnerEmployee ?? null
  }

  return {
    request,
    loading,
  }
}

export const useCreatePartnerEmployee: MutationHook<
  PartnerEmployeeCreateDocumentInput,
  PartnerEmployeeCreateDocumentResponse
> = (options) => {
  const [execute, { loading }] = useMutation<PartnerEmployeeCreateDocumentResponse>(
    PartnerEmployeeCreateDocument,
    options
  )

  const request = async (variables: PartnerEmployeeCreateDocumentInput) => {
    await execute({ variables })
  }

  return { request, loading }
}

export const useAssignPartnerEmployee: MutationHook<
  PartnerEmployeeAssignDocumentInput,
  PartnerEmployeeAssignDocumentResponse
> = (options) => {
  const [execute, { loading }] = useMutation<PartnerEmployeeAssignDocumentResponse>(
    PartnerEmployeeAssignDocument,
    options
  )

  const request = async (variables: PartnerEmployeeAssignDocumentInput) => {
    await execute({
      variables,
      refetchQueries: [
        {
          query: PartnerEmployeeDocument,
          variables,
        },
      ],
    })
  }

  return { request, loading }
}

export const useUnassignPartnerEmployee: MutationHook<
  PartnerEmployeeUnassignDocumentInput,
  PartnerEmployeeUnassignDocumentResponse
> = (options) => {
  const [execute, { loading }] = useMutation<PartnerEmployeeUnassignDocumentResponse>(
    PartnerEmployeeUnassignDocument,
    options
  )

  const request = async (variables: PartnerEmployeeUnassignDocumentInput) => {
    await execute({
      variables,
      refetchQueries: [
        {
          query: PartnerEmployeeDocument,
          variables,
        },
      ],
    })
  }

  return { request, loading }
}

type UsePartnerEmployeeOutletsInput = {
  partnerEmployeeId: string
}

export const usePartnerEmployeeOutlets: CollectionQueryHook<
  UsePartnerEmployeeOutletsInput,
  UserOutletsDocumentResponse['userOutletsCollection']['outlets']
> = (input, options) => {
  const variables = { userId: input.partnerEmployeeId, ...defaultCollectionVariables }

  const { data, loading, fetchMore, refetch } = useQuery<UserOutletsDocumentResponse>(
    UserOutletsDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.userOutletsCollection.hasNext) return
    const offset = data.userOutletsCollection.outlets.length

    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.userOutletsCollection.outlets,
    loading,
    loadMore,
    hasMore: data?.userOutletsCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}
