import { FC, useState } from 'react'
import {
  buildModal,
  ModalBodyProps,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FileLoadInput,
  Typography,
  useNotifications,
} from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useUpdateGeoCityIcon } from '@web-panel/oc-api'

type UpdateCityIconModalComponentProps = ModalBodyProps & {
  cityCode: string
}

const UpdateCityIconModalComponent: FC<UpdateCityIconModalComponentProps> = ({
  cityCode,
  onClose,
}) => {
  const { formatMessage } = useIntl()
  const { pushNotification } = useNotifications()
  const [image, setImage] = useState<string>()
  const [loadedFile, setLoadedFile] = useState<File | null>(null)
  const { request: updateIcon, loading: updateIconLoading } = useUpdateGeoCityIcon()

  const handleSubmit = async () => {
    if (!loadedFile) return
    try {
      await updateIcon({ code: cityCode, input: { image: loadedFile } })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const onFileLoad = (files: FileList) => {
    const file = files[0]
    setLoadedFile(file)

    const reader = new FileReader()
    reader.addEventListener('loadend', async (event) => {
      const data = (event.target?.result ?? '') as string
      setImage(data)
    })
    reader.readAsDataURL(file)
  }

  return (
    <>
      <DialogTitle>
        <FormattedMessage id="modals.load-new-city-icon.title" />
      </DialogTitle>
      <DialogContent>
        <div className="flex justify-center">
          {!image ? (
            <div className="flex flex-col items-center">
              <FileLoadInput onLoad={onFileLoad} accept="image/svg+xml" />
              <Typography className="mt-16">
                <FormattedMessage id="common.labels.file-picker-hint" />
              </Typography>
            </div>
          ) : (
            <img
              style={{ width: '100%', height: '100%', backgroundColor: '#BBBBBB' }}
              src={image}
              alt="upload"
            />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="common.actions.cancel" />
        </Button>
        <Button
          loading={updateIconLoading}
          variant="contained"
          color="secondary"
          disabled={!image}
          onClick={() => handleSubmit()}
        >
          <FormattedMessage id="common.actions.update" />
        </Button>
      </DialogActions>
    </>
  )
}

export const UpdateCityIconModal = buildModal(UpdateCityIconModalComponent, { fullWidth: true })
