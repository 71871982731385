import { VFC } from 'react'
import { AppPage, CardedContent, Button, useNotifications } from '@web-panel/shared'
import { useCountries, GeoCountry } from '@web-panel/api'
import { currencies } from '@local/constants/currencies'
import { Formik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { Error404 } from '@local/pages/404'
import { FormattedMessage, useIntl } from 'react-intl'
import { useConsumerInfo, useTopUpConsumerWallet } from '@web-panel/oc-api'
import { TopUpWalletForm, validationSchema, FormFields } from './Forms/TopUpWalletForm'

type TopUpWalletPageProps = {
  consumerId: string
  countries: GeoCountry[]
}

const TopUpWalletPage: VFC<TopUpWalletPageProps> = ({ consumerId, countries }) => {
  const { formatMessage } = useIntl()
  const { pushNotification } = useNotifications()
  const navigate = useNavigate()
  const { request: updateConsumerWallet } = useTopUpConsumerWallet()

  const initialValues: FormFields = {
    currencyCode: currencies[0],
    amount: 0,
    countryCode: countries[0].code,
  }

  const onSubmit = async (input: FormFields) => {
    try {
      await updateConsumerWallet({
        id: consumerId,
        input,
      })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
      navigate(-1)
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <Formik<FormFields>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting, handleSubmit }) => {
        const actionButtons = [
          <Button
            disabled={!isValid}
            variant="contained"
            color="secondary"
            onClick={() => handleSubmit()}
            loading={isSubmitting}
          >
            <FormattedMessage id="common.actions.update" />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            title={formatMessage({ id: 'actions.top-up-wallet' })}
            actionButtons={actionButtons}
          >
            <CardedContent padded>
              <TopUpWalletForm countries={countries} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export default pageWithRoleAuthorization(
  () => {
    const params = useParams()
    const { data: consumerInfo, loading: loadingConsumerInfo } = useConsumerInfo({ id: params.id! })
    const { data: countries, loading: loadingCountries } = useCountries()
    if (loadingConsumerInfo || loadingCountries) return null
    if (!consumerInfo || !countries) return <Error404 />

    const enabledCountries = countries.filter(({ enabledForLocation }) => enabledForLocation)

    return <TopUpWalletPage countries={enabledCountries} consumerId={params.id!} />
  },
  ({ can }) => can('consumerAccount', 'previewInfo') && can('consumerAccount', 'updateWallet')
)
