import { VFC } from 'react'
import { Dispenser, shortLocationAddressString } from '@web-panel/api'
import { TableGrid, Paper, HeartbeatCounter } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { DispenserStatusChip } from '@local/components/DispenserStatusChip'
import { useNavigate } from 'react-router-dom'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useSorting } from '../sorting'

type DispencerIndexTableProps = {
  data: Dispenser[]
  loading: boolean
}

export const DispencerIndexTable: VFC<DispencerIndexTableProps> = ({ data, loading }) => {
  const { formatMessage } = useIntl()
  const { can, cannot } = useRoleAuthorization()
  const navigate = useNavigate()
  const { field, direction, handleToggleSort } = useSorting()

  return (
    <Paper className="overflow-hidden">
      <TableGrid
        collection={data}
        loading={loading}
        fields={[
          {
            title: formatMessage({ id: 'models.dispenser.core-id' }),
            render: ({ coreId }) => coreId,
          },
          {
            title: formatMessage({ id: 'models.dispenser.qr-code' }),
            render: ({ qrCode }) => qrCode,
          },
          {
            title: formatMessage({ id: 'models.location.name' }),
            render: ({ customLocation, outletLocation }) =>
              (customLocation ?? outletLocation)?.name,
          },
          {
            title: formatMessage({ id: 'models.location.city' }),
            render: ({ outletLocation, customLocation }) =>
              (customLocation ?? outletLocation)?.city.name,
          },
          {
            title: formatMessage({ id: 'models.location.address' }),
            render: ({ outletLocation, customLocation }) => {
              if (customLocation) return shortLocationAddressString(customLocation)
              if (outletLocation) return shortLocationAddressString(outletLocation)
              return null
            },
          },
          {
            title: formatMessage({ id: 'models.dispenser.capacity' }),
            render: ({ capacity }) => capacity,
          },
          {
            title: formatMessage({ id: 'models.dispenser.status' }),
            render: ({ status }) => <DispenserStatusChip status={status} />,
            sorting: {
              active: field === 'STATUS',
              direction,
              onToggle: handleToggleSort('STATUS'),
            },
          },
          {
            title: formatMessage({ id: 'models.dispenser.last-signal-date' }),
            render: ({ lastSignalDate }) =>
              lastSignalDate && <HeartbeatCounter date={lastSignalDate} />,
            sorting: {
              active: field === 'LAST_SIGNAL_DATE',
              direction,
              onToggle: handleToggleSort('LAST_SIGNAL_DATE'),
            },
          },
        ]}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        onClickRow={({ id }) => navigate(`/dispensers/${id}`)}
        rowClickable={can('dispenser', 'preview')}
        rowActions={[
          {
            title: formatMessage({ id: 'common.actions.edit' }),
            icon: 'edit',
            onClick: ({ id }) => navigate(`/dispensers/${id}/edit`),
            disabled: cannot('dispenser', 'update'),
            mainAction: true,
          },
        ]}
      />
    </Paper>
  )
}
