import { FC } from 'react'
import { SelectableList, buildFilterContext } from '@web-panel/shared'
import { GroupLabelStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'

type FilterFields = {
  status: GroupLabelStatus | undefined
  search: string
}

export const { FiltersContext, withFilters, useFilters } = buildFilterContext<FilterFields>({
  status: undefined,
  search: '',
})

type FilterProps = {
  search?: string
}

const Filters: FC<FilterProps> = () => {
  const { formatMessage } = useIntl()
  const { filters, setFilter } = useFilters()

  return (
    <div className="flex flex-col">
      <SelectableList<GroupLabelStatus>
        value={filters.status}
        options={Object.values(GroupLabelStatus)}
        optionTitle={(option) => option}
        label={formatMessage({ id: 'models.product-item.status' })}
        onSelect={(status) => setFilter('status', status)}
      />
    </div>
  )
}

export default Filters
