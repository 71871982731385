import { FC } from 'react'
import { TableRow, TableCell } from '@mui/material'

export const HorizontalTableSpan: FC = ({ children }) => {
  return (
    <TableRow>
      <TableCell className="border-none p-0" colSpan={2}>
        {children}
      </TableCell>
    </TableRow>
  )
}
