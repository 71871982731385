import { useMutation } from '@apollo/client'
import { MutationHook } from '@web-panel/api'
import {
  DeleteIntegratorBoxOrderDocument,
  DeleteIntegratorBoxOrderDocumentInput,
  DeleteIntegratorBoxOrderDocumentResponse,
  VaultTokenDocument,
  VaultTokenDocumentInput,
  VaultTokenDocumentResponse,
  CreateIntegratorBoxOrderDocument,
  CreateIntegratorBoxOrderDocumentInput,
  CreateIntegratorBoxOrderDocumentResponse,
} from '../docs'

export const useRequestVaultToken: MutationHook<
  VaultTokenDocumentInput,
  VaultTokenDocumentResponse['vaultToken']['magicToken']
> = () => {
  const [execute, { loading }] = useMutation<VaultTokenDocumentResponse>(VaultTokenDocument)

  const request = async () => {
    const { data } = await execute()

    if (!data) throw Error('Failed to request vault token')

    return data?.vaultToken.magicToken
  }

  return {
    request,
    loading,
  }
}

export const useCreateIntegratorBoxOrder: MutationHook<
  CreateIntegratorBoxOrderDocumentInput['input'],
  CreateIntegratorBoxOrderDocumentResponse['createBoxOrder']
> = () => {
  const [execute, { loading }] = useMutation<CreateIntegratorBoxOrderDocumentResponse>(
    CreateIntegratorBoxOrderDocument
  )

  const request = async (input: CreateIntegratorBoxOrderDocumentInput['input']) => {
    const { data } = await execute({ variables: { input } })

    if (!data) throw Error('Could not create box integrator box order')

    return data.createBoxOrder
  }

  return {
    request,
    loading,
  }
}

export const useDeleteIntegratorBoxOrder: MutationHook<
  DeleteIntegratorBoxOrderDocumentInput,
  DeleteIntegratorBoxOrderDocumentResponse
> = () => {
  const [execute, { loading }] = useMutation(DeleteIntegratorBoxOrderDocument)

  const request = async (variables: DeleteIntegratorBoxOrderDocumentInput) => {
    const { data } = await execute({ variables })

    if (!data) throw Error('Failed to delete box order')
  }

  return {
    request,
    loading,
  }
}
