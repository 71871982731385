import { FC, ReactElement } from 'react'
import { Icon } from '@mui/material'
import { motion } from 'framer-motion'

type PageIconProps = {
  icon?: string
  image?: ReactElement
}

const PageIcon: FC<PageIconProps> = ({ icon, image }) => {
  if (icon)
    return (
      <motion.div
        className="flex items-center mr-12"
        initial={{ scale: 0 }}
        animate={{ scale: 1, transition: { delay: 0.3 } }}
      >
        <Icon className="notranslate MuiIcon-root MuiIcon-fontSizeMedium text-24 md:text-32">
          {icon}
        </Icon>
      </motion.div>
    )

  return (
    <motion.div
      className="flex items-center mr-12"
      initial={{ scale: 0 }}
      animate={{ scale: 1, transition: { delay: 0.3 } }}
    >
      {image}
    </motion.div>
  )
}

export default PageIcon
