import { FC } from 'react'
import { Typography } from '@mui/material'
import { motion } from 'framer-motion'
import classNames from 'classnames'

type TitleProps = {
  show: boolean
  title: string
}

const Title: FC<TitleProps> = ({ title, show }) => {
  return (
    <motion.div initial={{ x: -20 }} animate={{ x: 0, transition: { delay: 0.3 } }}>
      <Typography
        className={classNames('sm:flex text-16 md:text-24 mr-48 font-semibold', {
          hidden: !show,
        })}
        variant="body1"
      >
        {title}
      </Typography>
    </motion.div>
  )
}

export default Title
