import { FC } from 'react'
import { DropdownButton, useNotifications } from '@web-panel/shared'
import { Outlet, OutletStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { useUpdateOutlet } from '@web-panel/oc-api'
import { outletToUpdateInput } from './helpers'

type OutletStatusChangeButtonProps = {
  outlet: Outlet
}

const OutletStatusChangeButton: FC<OutletStatusChangeButtonProps> = ({ outlet }) => {
  const { formatMessage } = useIntl()
  const { pushNotification } = useNotifications()
  const { request: updateOutlet, loading } = useUpdateOutlet()

  const handleUpdateOutletStatus = async (status: OutletStatus) => {
    try {
      await updateOutlet({
        id: outlet.id,
        input: {
          ...outletToUpdateInput(outlet),
          status,
        },
      })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const menu = Object.values(OutletStatus).map((status) => ({
    title: formatMessage({ id: `outlet-statuses.${status}` }),
    onClick: () => handleUpdateOutletStatus(status),
    disabled: outlet.status === status,
  }))

  return (
    <DropdownButton
      fullWidth
      loading={loading}
      variant="contained"
      color="secondary"
      title={formatMessage({ id: `outlet-statuses.${outlet.status}` })}
      menu={menu}
    />
  )
}

export default OutletStatusChangeButton
