import { FC } from 'react'
import { BoxRental } from '@web-panel/api'
import {
  AppPage,
  Button,
  CardedContent,
  HorizontalTable,
  HorizontalTableRow,
  useFormatDate,
} from '@web-panel/shared'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useNavigate, useParams } from 'react-router-dom'
import { Error404 } from '@local/pages/404'
import { FormattedMessage, useIntl } from 'react-intl'
import modelIcons from '@local/model-icons'
import { OutletLink } from '@local/components/OutletLink'
import { RentalStatusChip } from '@local/components/RentalStatusChip'
import { useBoxRental } from '@web-panel/oc-api'

type PageComponentProps = {
  rental: BoxRental
}
const PageComponent: FC<PageComponentProps> = ({ rental }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const navigate = useNavigate()
  const { can } = useRoleAuthorization()

  const headerActionButtons = [
    can(
      'boxRental',
      'update',
      <Button variant="contained" color="secondary" onClick={() => navigate('edit')}>
        <FormattedMessage id="common.actions.edit" />
      </Button>
    ),
  ]

  return (
    <AppPage
      backButton
      title={formatMessage({ id: 'routes.partners.rentals.preview' }, { name: rental.externalId })}
      icon={modelIcons.rental}
      actionButtons={headerActionButtons}
    >
      <CardedContent padded>
        <HorizontalTable>
          <HorizontalTableRow title={formatMessage({ id: 'models.rental.id' })}>
            {rental.externalId}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.rental.outlet' })}>
            {rental.outletId && <OutletLink outletId={rental.outletId} />}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.rental.status' })}>
            <RentalStatusChip status={rental.status} />
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.rental.start-date' })}>
            {formatDate('date', rental.startDate)}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.rental.end-date' })}>
            {rental.endDate && formatDate('date', rental.endDate)}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.rental.amount' })}>
            {rental.amount}
          </HorizontalTableRow>
        </HorizontalTable>
      </CardedContent>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(
  () => {
    const { id } = useParams<{ id: string }>()
    const { data: rental, loading } = useBoxRental({ id: id! })
    if (!rental && loading) return null
    if (!rental) return <Error404 />

    return <PageComponent rental={rental} />
  },
  ({ can }) => can('boxRental', 'preview')
)
