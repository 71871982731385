import { useQuery, useMutation } from '@apollo/client'
import { CollectionQueryHook, MutationHook } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  InvoicesDocument,
  InvoicesDocumentInput,
  InvoicesDocumentResponse,
  UpdateInvoiceDocument,
  UpdateInvoiceDocumentInput,
  UpdateInvoiceDocumentResponse,
} from '../docs'

export const useInvoices: CollectionQueryHook<
  InvoicesDocumentInput['params'],
  InvoicesDocumentResponse['invoicesCollection']['invoices']
> = (params, options) => {
  const variables = { params, ...defaultCollectionVariables }

  const { data, loading, fetchMore, refetch } = useQuery<InvoicesDocumentResponse>(
    InvoicesDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.invoicesCollection.hasNext) return

    const offset = data.invoicesCollection.invoices.length
    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.invoicesCollection.invoices,
    loading,
    loadMore,
    hasMore: data?.invoicesCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useUpdateInvoice: MutationHook<
  UpdateInvoiceDocumentInput,
  UpdateInvoiceDocumentResponse
> = (options) => {
  const [execute, { loading }] = useMutation<UpdateInvoiceDocumentResponse>(
    UpdateInvoiceDocument,
    options
  )

  async function request(values: UpdateInvoiceDocumentInput) {
    const { data } = await execute({
      variables: values,
      update: (cache, _doc, { variables }) => {
        cache.modify({
          id: cache.identify({ __typename: 'Invoice', id: values.id }),
          optimistic: true,
          fields: {
            status() {
              return values.input.status
            },
          },
        })
      },
    })
    if (!data) throw new Error('Could not update invoice')
  }

  return {
    request,
    loading,
  }
}
