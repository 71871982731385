import { TableHead, TableRow } from '@mui/material'
import HeaderCell from './HeaderCell'
import { Field } from './types'

type TableGridHeadProps<Data> = {
  fields: Field<Data>[]
  hasRowActions?: boolean
}

export function TableGridHead<Data>({ fields, hasRowActions }: TableGridHeadProps<Data>) {
  return (
    <TableHead>
      <TableRow>
        {fields.map(({ title, sorting, align, hiddenMobile }, id) => (
          <HeaderCell key={id} sorting={sorting} align={align} hiddenMobile={hiddenMobile}>
            {title}
          </HeaderCell>
        ))}
        {hasRowActions && <HeaderCell style={{ width: '1px' }} hiddenMobile />}
      </TableRow>
    </TableHead>
  )
}
