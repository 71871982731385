import { gql } from '@apollo/client'
import {
  Batch,
  BatchStatus,
  ProductItem,
  batchFields,
  productItemFields,
  ProductItemModel,
  productItemModelFields,
} from '@web-panel/api'

export type BatchesDocumentInput = {
  limit: number
  offset?: number
  status?: BatchStatus
  batchNumber?: string
  search?: string
}
export type BatchesDocumentResponse = {
  batchesCollection: {
    batches: Batch[]
    hasNext: boolean
  }
}

export const BatchesDocument = gql`
  query Batches($limit: Int!, $offset: Int, $status: String, $batchNumber: String, $search: string) {
    batchesCollection(limit: $limit, offset: $offset, status: $status, batchNumber: $batchNumber, search: $search) @rest(type: "BatchesCollection", path: "/operations-service/batches?{args}") {
      batches @type(name: "Batch") {
        ${batchFields}
      }
      hasNext
    }
  }
`

export type CreateBatchDocumentInput = {
  comment: string
  itemCodes: string[]
  itemModelId: string
  brand: string
}
export type CreateBatchDocumentResponse = {
  createBatch: Batch
}

export const CreateBatchDocument = gql`
  mutation CreateBatch($input: any) {
    createBatch: publish(input: $input) @rest(type: "Batch", path: "/operations-service/batches", method: "POST") {
      ${batchFields}
    }
  }
`

export type GetBatchDocumentInput = {
  id: string
}
export type GetBatchDocumentResponse = {
  batch: Batch
}
export const GetBatchDocument = gql`
  query Batch($id: String!) {
    batch(id: $id) @rest(type: "Batch", path: "/operations-service/batches/{args.id}") {
      ${batchFields}
    }
  }
`

export type BatchItemsDocumentInput = {
  id: string
  limit: number
  offset?: number
}
export type BatchItemsDocumentResponse = {
  batchItems: {
    items: (ProductItem & { batch: Batch | null })[]
    hasNext: boolean
  }
}
export const BatchItemsDocument = gql`
  query BatchItemsDocument($id: String!, $limit: String!, $offset: String) {
    batchItems(id: $id, limit: $limit, offset: $offset) @rest(type: "ProductItemCollection", path: "/operations-service/batches/{args.id}/items?limit={args.limit}&offset={args.offset}") {
      items @type(name: "ProductItem") {
        ${productItemFields}
      }
      hasNext
    }
  }
`

export type BatchItemModelsDocumentInput = {
  id: string
}

export type BatchItemModelsDocumentResponse = {
  batchItemModels: {
    itemModels: ProductItemModel[]
  }
}

export const BatchItemModelsDocument = gql`
  query BatchItemModels($id: String!) {
    batchItemModels(id: $id) @rest(type: "BatchProductItemModels", path: "/operations-service/batches/{args.id}/items/models") {
      itemModels @type(name: "BatchProductItemModel") {
        ${productItemModelFields}
      }
    }
  }
`

export type AttachContainerToBatchDocumentInput = {
  batchId: string
  input: {
    qrCode: string
  }
}
export type AttachContainerToBatchDocumentResponse = {
  attachContainerToBatch: Batch
}
export const AttachContainerToBatchDocument = gql`
  mutation AttachContainerToBatch($batchId: String!, $input: any) {
    attachContainerToBatch(batchId: $batchId, input: $input)
      @rest(
        type: "Batch",
        path: "/operations-service/batches/{args.batchId}/containers", 
        method: "POST"
      ) {
      ${batchFields}
    }
  }
`

export type RemoveContainerFromBatchDocumentInput = {
  batchId: string
}
export type RemoveContainerFromBatchDocumentResponse = {
  removeContainerFromBatch: Batch
}

export const RemoveContainerFromBatchDocument = gql`
  mutation RemoveContainerFromBatch($batchId: String!) {
    removeContainerFromBatch: publish(batchId: $batchId)
      @rest(
        type: "Batch",
        path: "/operations-service/batches/{args.batchId}/containers",
        method: "DELETE"
      ) {
      ${batchFields}
    }
  }
`
