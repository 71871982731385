import { FC } from 'react'
import { Paper, TableGrid, useFormatDate } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { Label } from '@web-panel/api'
import AddressGroupLabelLink from '@local/components/GroupLabelIdLink'
import LabelStatusChip from '@local/components/LabelStatusChip'

type IndexTableProps = {
  data: Label[]
  loading: boolean
}

const IndexTable: FC<IndexTableProps> = ({ data, loading }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { cannot } = useRoleAuthorization()
  const formatDate = useFormatDate()

  return (
    <Paper className="overflow-hidden">
      <TableGrid
        loading={loading}
        collection={data}
        fields={[
          {
            title: formatMessage({
              id: 'models.shipment-partner-label.tracking-number',
            }),
            render: (label) => label.packages[0].trackingNumber,
          },
          {
            title: formatMessage({
              id: 'models.shipment-partner-label.status',
            }),
            render: (label) => label.status && <LabelStatusChip status={label.status} />,
          },
          {
            title: formatMessage({
              id: 'models.shipment-partner-label.group-id',
            }),
            render: (label) => label && <AddressGroupLabelLink label={label} />,
          },
          {
            title: formatMessage({
              id: 'models.shipment-partner-label.last-update',
            }),
            render: (label) => formatDate('datetime', label.updateStatusDate),
          },
        ]}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        rowActions={[
          {
            title: formatMessage({ id: 'common.actions.track-history' }),
            icon: 'history',
            visible: ({ hasTrackingInfo }) => hasTrackingInfo,
            onClick: ({ id, hasTrackingInfo, packages }) =>
              hasTrackingInfo && navigate(`/tracking/${id}/${packages[0].trackingNumber}`),
            disabled: cannot('tracking', 'list'),
            mainAction: true,
          },
        ]}
      />
    </Paper>
  )
}

export default IndexTable
