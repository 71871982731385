import { FC, useState, useMemo } from 'react'
import { useCountries } from '@web-panel/api'
import { TableGrid, TextField } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import _includes from 'lodash/includes'
import _sortBy from 'lodash/sortBy'

const priorityCountryCodes = ['CH', 'DE', 'SA']

export const SettingsCountriesTab: FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const { data: countries = [], loading } = useCountries({ fetchPolicy: 'cache-and-network' })

  const filteredCountries = useMemo(() => {
    let filtered = countries
    if (search.length > 0) {
      const regexp = new RegExp(search)
      filtered = countries.filter(
        (country) => country.name.match(regexp) || country.code.match(regexp)
      )
    }
    return _sortBy(filtered, (country) => (_includes(priorityCountryCodes, country.code) ? 0 : 1))
  }, [countries, search])

  const { can, cannot } = useRoleAuthorization()

  return (
    <>
      <div className="px-8 md:px-0 py-8 md:py-0">
        <TextField
          fullWidth
          label={formatMessage({ id: 'common.actions.search' })}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <TableGrid
        collection={filteredCountries}
        loading={loading}
        fields={[
          {
            title: formatMessage({ id: 'models.geo-country.code' }),
            render: ({ code }) => code,
          },
          {
            title: formatMessage({ id: 'models.geo-country.name' }),
            render: ({ name }) => name,
          },
          {
            title: formatMessage({ id: 'models.geo-country.enabled-for-billing' }),
            render: ({ enabledForBilling }) => (
              <FormattedMessage id={enabledForBilling ? 'common.yes' : 'common.no'} />
            ),
          },
          {
            title: formatMessage({ id: 'models.geo-country.enabled-for-location' }),
            render: ({ enabledForLocation }) => (
              <FormattedMessage id={enabledForLocation ? 'common.yes' : 'common.no'} />
            ),
          },
        ]}
        onClickRow={({ code }) => navigate(`/settings/countries/${code}`)}
        rowClickable={can('geoCountry', 'preview')}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        rowActions={[
          {
            title: formatMessage({ id: 'common.actions.details' }),
            icon: 'visibility',
            onClick: ({ code }) => navigate(`/settings/countries/${code}`),
            disabled: cannot('geoCountry', 'preview'),
          },
          {
            title: formatMessage({ id: 'common.actions.edit' }),
            icon: 'edit',
            onClick: ({ code }) => navigate(`/settings/countries/${code}/edit`),
            disabled: cannot('geoCountry', 'update'),
            mainAction: true,
          },
        ]}
      />
    </>
  )
}
