import { FC } from 'react'
import { GroupLabelStatus, LabelStatus } from '@web-panel/api'
import { Chip, ComponentColor } from '@web-panel/shared'

const statusColorMapping: Record<LabelStatus, ComponentColor> = {
  [LabelStatus.Delivered]: 'success',
  [LabelStatus.Deactivated]: 'error',
  [LabelStatus.Interrupted]: 'error',
  [LabelStatus.Active]: 'secondary',
  [LabelStatus.Idle]: 'info',
}

type LabelStatusChipProps = {
  status: LabelStatus | GroupLabelStatus
  mapActiveStatus?: boolean
}

function MapActiveStatus(status: LabelStatus | GroupLabelStatus): string {
  if (status === LabelStatus.Active) {
    return 'IN TRANSIT'
  }
  return status
}

const LabelStatusChip: FC<LabelStatusChipProps> = ({ status, mapActiveStatus }) => {
  const color = statusColorMapping[status] ?? 'default'
  return (
    <Chip
      className="min-w-72"
      color={color}
      label={mapActiveStatus ? MapActiveStatus(status) : status}
    />
  )
}

export default LabelStatusChip
