import { FC } from 'react'
import { Address, useCountry } from '@web-panel/api'
import {
  HorizontalTable,
  HorizontalTableRow,
  HorizontalTableSection,
  useFormatDate,
} from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'

type GeneralTabProps = {
  data: Address
}

export const GeneralTab: FC<GeneralTabProps> = ({ data }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const { data: country } = useCountry({
    code: data.countryCode ?? '-1',
  })

  return (
    <div className="flex flex-col md:flex-row">
      <div className="flex-1">
        <HorizontalTable>
          <HorizontalTableSection
            isFirstInTable
            title={formatMessage({
              id: 'pages.partner-preview.tabs.general.account-info',
            })}
          />
          <HorizontalTableRow title={formatMessage({ id: 'models.shipment-address.id' })}>
            {data?.id}
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({
              id: 'models.shipment-address.partner-type',
            })}
          >
            <FormattedMessage id={data?.partnerType} />
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({
              id: 'models.shipment-address.operation-start-date',
            })}
          >
            {formatDate('human-date', data?.operationsStartDate)}
          </HorizontalTableRow>

          <HorizontalTableSection
            title={formatMessage({
              id: 'pages.partner-preview.tabs.general.address',
            })}
          />

          <HorizontalTableRow title={formatMessage({ id: 'models.partner.country' })}>
            {country?.name}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.city' })}>
            {data?.cityName}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.address' })}>
            {data?.address}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.postal-code' })}>
            {data?.zipCode}
          </HorizontalTableRow>

          <HorizontalTableSection
            title={formatMessage({
              id: 'pages.partner-preview.tabs.general.contact-details',
            })}
          />
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.company' })}>
            {data?.contactDetail?.company}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.person-name' })}>
            {data?.contactDetail?.fullName}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.phone' })}>
            {data?.contactDetail?.phone}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.email' })}>
            {data?.contactDetail?.email}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.operations-phone' })}>
            {data?.contactDetail?.phone}
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({
              id: 'models.partner.operations-mobile-phone',
            })}
          >
            {data?.contactDetail?.mobilePhone}
          </HorizontalTableRow>
        </HorizontalTable>
      </div>
    </div>
  )
}
