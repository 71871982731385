import { FC } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'

import { DefaultErrorPage, AppPage, CardedContent, Button } from '@web-panel/shared'
import { Formik } from 'formik'
import modelIcons from '@local/model-icons'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { useProductItem, useUpdateProductItem } from '@web-panel/oc-api'
import UpdateProductItemForm, { FormFields, validationSchema } from './Forms/UpdateProductItemForm'

const PreviewPage: FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { data: productItem, loading } = useProductItem({ id: params.id ?? '' })
  const { request: update, loading: loadingUpdate } = useUpdateProductItem()

  if (loading) return null
  if (!productItem)
    return (
      <DefaultErrorPage
        title={formatMessage({ id: 'errors.404.title' })}
        message={formatMessage({ id: 'errors.404.message' })}
      />
    )

  const initialValues: FormFields = {
    status: productItem.status,
    holderId: productItem.currentHolderId,
    batchId: productItem.batch?.id ?? null,
    batchNumber: productItem.batch?.batchNumber ?? null,
  }

  const onSubmit = async (values: FormFields) => {
    try {
      await update({
        id: productItem.id,
        input: {
          status: values.status,
          holderId: values.holderId,
          batchId: values.batchId,
        },
      })
      navigate(-1)
    } catch (e) {
      //
    }
  }

  return (
    <Formik<FormFields>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formProps) => {
        const headerActionButtons = [
          <Button
            fullWidth
            variant="contained"
            loading={loadingUpdate}
            color="secondary"
            disabled={!formProps.dirty || !formProps.isValid}
            onClick={() => formProps.handleSubmit()}
          >
            <FormattedMessage id="common.actions.update" />
          </Button>,
        ]
        return (
          <AppPage
            backButton
            loading={loading}
            icon={modelIcons.productItem}
            title={formatMessage({ id: 'routes.product-item-edit' }, { name: productItem.qrCode })}
            actionButtons={headerActionButtons}
          >
            <CardedContent>
              <UpdateProductItemForm {...formProps} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export default pageWithRoleAuthorization(PreviewPage, ({ can }) => can('productItem', 'update'))
