import { FC } from 'react'
import {
  AppPage,
  CardedContent,
  Button,
  DefaultErrorPage,
  useNotifications,
  useModalRef,
} from '@web-panel/shared'

import { useIntl, FormattedMessage } from 'react-intl'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Formik } from 'formik'
import modelIcons from '@local/model-icons'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { useBox } from '@web-panel/oc-api'
import { UpdateBoxForm, FormFields, validationSchema } from './Forms/UpdateForm'
import { useUpdateBoxWithLocation } from './use-update-box'
import { UpdateBoxWarningModal } from './Components/UpdateBoxWarningModal'

type WarningField = 'acceptanceToleranceTof'
const warningFields: WarningField[] = ['acceptanceToleranceTof']
const translationsMapping: Record<WarningField, string> = {
  acceptanceToleranceTof: 'acceptance-tolerance-tof',
}

const EditPage: FC = () => {
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()
  const updateModalRef = useModalRef()
  const { formatMessage } = useIntl()
  const params = useParams()
  const { data: box, loading } = useBox({ id: params.id ?? '' })
  const { updateBoxWithLocation, loading: loadingUpdateBox } = useUpdateBoxWithLocation(box)
  const { state } = useLocation()

  if (loading) return null
  if (!box)
    return (
      <DefaultErrorPage
        title={formatMessage({ id: 'errors.404.title' })}
        message={formatMessage({ id: 'errors.404.message' })}
      />
    )

  const initialValues =
    state && state.initialValues
      ? {
          ...box,
          ...state.initialValues,
        }
      : box

  const onSubmit = async (values: FormFields) => {
    try {
      await updateBoxWithLocation(values)
      navigate(-1)
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <>
      <Formik<FormFields>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formikProps) => {
          const changedWarningFields = warningFields.filter(
            (fieldName) => initialValues[fieldName] !== formikProps.values[fieldName]
          )
          const fieldsToWarnTranslationNames = changedWarningFields.map(
            (fieldName) => translationsMapping[fieldName]
          )

          const headerActionButtons = [
            <Button
              fullWidth
              loading={loadingUpdateBox}
              key="save"
              variant="contained"
              color="secondary"
              disabled={!formikProps.isValid}
              onClick={() =>
                fieldsToWarnTranslationNames.length > 0
                  ? updateModalRef.current?.open()
                  : formikProps.handleSubmit()
              }
            >
              <FormattedMessage id="common.actions.update" />
            </Button>,
          ]

          return (
            <AppPage
              backButton
              icon={modelIcons.box}
              title={formatMessage({ id: 'routes.boxes-edit' }, { name: box.coreId })}
              actionButtons={headerActionButtons}
            >
              <CardedContent>
                <UpdateBoxForm {...formikProps} />
              </CardedContent>
              <UpdateBoxWarningModal
                ref={updateModalRef}
                fieldsToWarn={fieldsToWarnTranslationNames}
                onSubmit={() => onSubmit(formikProps.values)}
              />
            </AppPage>
          )
        }}
      </Formik>
    </>
  )
}

export default pageWithRoleAuthorization(EditPage, ({ can }) => can('box', 'update'))
