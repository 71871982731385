import { FC } from 'react'
import { DonationStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { Chip, ComponentColor } from '@web-panel/shared'

const statusColorMapping: Record<DonationStatus, ComponentColor> = {
  [DonationStatus.Processed]: 'success',
  [DonationStatus.Rejected]: 'error',
  [DonationStatus.Failed]: 'error',
  [DonationStatus.Created]: 'info',
}

type DonationStatusChipProps = {
  status: DonationStatus
}

const DonationStatusChip: FC<DonationStatusChipProps> = ({ status }) => {
  const { formatMessage } = useIntl()

  const color = statusColorMapping[status] ?? 'default'

  return (
    <Chip
      className="min-w-72"
      color={color}
      label={formatMessage({ id: `donation-statuses.${status}` })}
    />
  )
}

export default DonationStatusChip
