import { VFC } from 'react'
import {
  AppPage,
  InfinitScroller,
  Button,
  useModalRef,
  useDebouncedSearch,
} from '@web-panel/shared'

import modelIcons from '@local/model-icons'
import { useIntl, FormattedMessage } from 'react-intl'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import { PageHeaderSearch } from '@local/components/PageHeaderSearch'
import { useDispensers } from '@web-panel/oc-api'
import { DispencerIndexTable } from './Tables/IndexTable'
import CreateDispensersModal from './Modals/CreateDispensers'
import Filters, { withFilters, usePageFilters } from './Filters'
import { useSorting, withSorting } from './sorting'

const DispensersIndexPage: VFC = () => {
  const { formatMessage } = useIntl()
  const createDispensersModalRef = useModalRef()
  const filters = usePageFilters()
  const { search, onChangeSearch, debouncedSearch } = useDebouncedSearch()
  const { field: sortBy, direction: sortOrder } = useSorting()
  const { can } = useRoleAuthorization()

  const {
    data: dispencers = [],
    loading,
    loadMore,
  } = useDispensers(
    {
      search: debouncedSearch,
      sortBy,
      sortOrder,
      ...filters,
    },
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    }
  )

  const headerActionButtons = [
    can(
      'dispenser',
      'create',
      <Button
        fullWidth
        key="create"
        variant="contained"
        color="secondary"
        onClick={() => createDispensersModalRef.current?.open()}
      >
        <FormattedMessage
          id="common.actions.create-with-name"
          values={{ name: formatMessage({ id: 'models.dispenser' }) }}
        />
      </Button>
    ),
  ]

  return (
    <AppPage
      loading={loading}
      icon={modelIcons.dispenser}
      title={formatMessage({ id: 'routes.dispensers' })}
      actionButtons={headerActionButtons}
      Sidebar={Filters}
      searchComponent={<PageHeaderSearch value={search} onChangeValue={onChangeSearch} />}
    >
      <InfinitScroller onLoadMore={loadMore}>
        <DispencerIndexTable data={dispencers} loading={loading} />
      </InfinitScroller>
      <CreateDispensersModal ref={createDispensersModalRef} />
    </AppPage>
  )
}

export default pageWithRoleAuthorization(withSorting(withFilters(DispensersIndexPage)), ({ can }) =>
  can('dispenser', 'list')
)
