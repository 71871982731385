import { FC } from 'react'
import { TextField, LabeledSwitch } from '@web-panel/shared'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import { useIntl } from 'react-intl'

export type FormFields = {
  name: string
  enabledForBilling: boolean
  enabledForLocation: boolean
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  enabledForBilling: Yup.boolean().required(),
  enabledForLocation: Yup.boolean().required(),
})

type EditCountryFormProps = FormikProps<FormFields>
export const EditCountryForm: FC<EditCountryFormProps> = ({
  values,
  errors,
  handleChange,
  handleSubmit,
}) => {
  const { formatMessage } = useIntl()

  return (
    <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit}>
      <div className="flex flex-col p-16 sm:p-24 max-w-2xl">
        <TextField
          label={formatMessage({ id: 'models.geo-country.name' })}
          onChange={handleChange('name')}
          value={values.name}
          error={!!errors.name}
        />
        <LabeledSwitch
          className="mt-16"
          label={formatMessage({ id: 'models.geo-country.enabled-for-billing' })}
          checked={values.enabledForBilling}
          onChange={handleChange('enabledForBilling')}
        />
        <LabeledSwitch
          className="mt-16"
          label={formatMessage({ id: 'models.geo-country.enabled-for-location' })}
          checked={values.enabledForLocation}
          onChange={handleChange('enabledForLocation')}
        />
      </div>
    </form>
  )
}
