export enum SequenceTypes {
  Cycle = 'CYCLE',
  Series = 'SERIES',
}

export function MapSequenceType(type: SequenceTypes): string {
  if (type === SequenceTypes.Cycle) {
    return 'CIRCULAR'
  }
  return 'LINEAR'
}

export type Sequence = {
  id: string
  name: string
  enabled: true
  automatized: true
  type: SequenceTypes
  createDate: string
}

export const sequenceFields = `
  id
  name
  enabled
  automatized
  type
  createDate
`
