import { FC } from 'react'
import { useCountryCities, City, Country } from '@web-panel/api'
import { Autocomplete, TextField } from '@web-panel/shared'
import { AutocompleteInputProps } from './types'

type CityAutocompleteInputPops = AutocompleteInputProps<City> & {
  country: Country
}
const CityAutocompleteInput: FC<CityAutocompleteInputPops> = ({
  fullWidth,
  className,
  disabled,
  value,
  onChange,
  error,
  label,
  country,
}) => {
  const { data: cities = [] } = useCountryCities({ countryCode: country.code })

  return (
    <Autocomplete
      disablePortal
      fullWidth={fullWidth}
      disabled={disabled}
      options={cities}
      className={className}
      renderInput={({ inputProps, ...params }) => (
        <TextField
          error={error}
          {...params}
          label={label}
          inputProps={{ ...inputProps, autoComplete: 'do-not-autocomplete' }}
        />
      )}
      getOptionLabel={({ name }) => name}
      onChange={(_data, val) => onChange(val)}
      value={value}
      isOptionEqualToValue={(option, val) => option.code === val.code}
    />
  )
}

export default CityAutocompleteInput
