import { FC } from 'react'
import { HorizontalTable, HorizontalTableRow, Link, Button, useModalRef } from '@web-panel/shared'
import { GeoCity } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'
import { GeoCityStatusChip } from '@local/components/GeoCityStatusChip'
import classNames from 'classnames'
import { useDeleteGeoCityIcon } from '@web-panel/oc-api'
import { CityIcon } from '../Components/CityIcon'
import { UpdateCityIconModal } from '../Modals/UpdateCityIconModal'

type GeneralTabProps = {
  city: GeoCity
}
export const GeneralTab: FC<GeneralTabProps> = ({ city }) => {
  const { formatMessage } = useIntl()
  const { request: deleteIcon, loading: loadingDeleteIcon } = useDeleteGeoCityIcon()
  const modalRef = useModalRef()

  return (
    <div className="flex flex-row">
      <div className="flex px-16 flex-col items-center">
        <CityIcon url={city.icon} />
        <div className={classNames('pt-16', 'grid', 'gap-8', `grid-cols-${city.icon ? 2 : 1}`)}>
          <Button variant="contained" color="primary" onClick={() => modalRef.current?.open()}>
            <FormattedMessage id={city.icon ? 'common.actions.change' : 'common.actions.upload'} />
          </Button>
          {city.icon && (
            <Button
              loading={loadingDeleteIcon}
              variant="contained"
              color="error"
              onClick={() => deleteIcon({ code: city.code })}
            >
              <FormattedMessage id="common.actions.delete" />
            </Button>
          )}
        </div>
        <UpdateCityIconModal cityCode={city.code} ref={modalRef} />
      </div>
      <div className="flex-1">
        <HorizontalTable>
          <HorizontalTableRow title={formatMessage({ id: 'models.geo-city.code' })}>
            {city.code}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.geo-city.name' })}>
            {city.name}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.geo-city.latitude' })}>
            {city.latitude}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.geo-city.longitude' })}>
            {city.longitude}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.geo-city.country' })}>
            <Link stopPropogation to={`/settings/countries/${city.country.code}`}>
              {city.country.name} ({city.country.code})
            </Link>
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.geo-city.status' })}>
            <GeoCityStatusChip status={city.status} />
          </HorizontalTableRow>
        </HorizontalTable>
      </div>
    </div>
  )
}
