import { FC, useState } from 'react'
import {
  buildModal,
  ModalBodyProps,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
  useNotifications,
} from '@web-panel/shared'
import { Outlet } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'
import _includes from 'lodash/includes'

import { useAssignPartnerEmployee, useOutlets, usePartnerEmployee } from '@web-panel/oc-api'

type AssignPArtnerEmployeeModalProps = ModalBodyProps & {
  partnerId: string
  partnerEmployeeId: string
}

const AssignPartnerEmployeeModal: FC<AssignPArtnerEmployeeModalProps> = ({
  partnerId,
  partnerEmployeeId,
  onClose,
}) => {
  const { pushNotification } = useNotifications()
  const { formatMessage } = useIntl()
  const [selectedOutlet, setSelectedOutlet] = useState<Outlet | null>(null)
  const { data: outlets = [] } = useOutlets({ partnerId })
  const { data: partnerEmployee } = usePartnerEmployee({ partnerId, employeeId: partnerEmployeeId })
  const { request: assignEmployee, loading } = useAssignPartnerEmployee()

  if (!partnerEmployee) return null

  const handleSubmit = async () => {
    if (!selectedOutlet) return

    try {
      await assignEmployee({
        partnerId,
        outletId: selectedOutlet.id,
        employeeId: partnerEmployeeId,
      })
      onClose()
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <>
      <DialogTitle>
        <FormattedMessage id="modals.assign-employee-to-outlet.title" />
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          fullWidth
          options={outlets}
          renderInput={(params) => <TextField {...params} />}
          getOptionDisabled={({ id }) => _includes(partnerEmployee.outletIds, id)}
          getOptionLabel={(outlet) => outlet.location.name}
          isOptionEqualToValue={(option, val) => option.id === val.id}
          renderOption={(props, outlet) => {
            return (
              <li {...props} key={outlet.id}>
                {outlet.location.name}
              </li>
            )
          }}
          onChange={(e, outlet) => setSelectedOutlet(outlet)}
          value={selectedOutlet}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="common.actions.cancel" />
        </Button>
        <Button
          loading={loading}
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          disabled={!selectedOutlet}
        >
          <FormattedMessage id="common.actions.assign" />
        </Button>
      </DialogActions>
    </>
  )
}

export default buildModal(AssignPartnerEmployeeModal, { fullWidth: true })
