import { FC, useState } from 'react'
import {
  AppPage,
  CardedContent,
  Button,
  Alert,
  AlertTitle,
  useNotifications,
} from '@web-panel/shared'

import { useIntl, FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Formik, FormikHelpers } from 'formik'
import modelIcons from '@local/model-icons'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { useCreateBatch, useProductItemModelTypes } from '@web-panel/oc-api'
import ProductItemBatchForm, {
  FormFields,
  defaultValues,
  validationSchema,
  castValues,
} from './Forms/ProductItemBatchForm'

const CreatePage: FC = () => {
  const navigate = useNavigate()
  const [missingCupCodes, setMissingCupCodes] = useState<string[]>([])
  const { pushNotification } = useNotifications()
  const { formatMessage } = useIntl()
  const { request: createBatch, loading: loadingCreateBatch } = useCreateBatch()
  const { data: modelTypes = [] } = useProductItemModelTypes()
  // const { data: modelBrands } = useProductBrands()

  const onSubmit = async (values: FormFields, { setFieldError }: FormikHelpers<FormFields>) => {
    try {
      const castedValues = castValues(values)
      const batch = await createBatch(castedValues)
      navigate(`/product-item-batches/${batch.id}`)
      pushNotification(
        'success',
        formatMessage({ id: 'notifications.batch-created' }, { name: batch.batchNumber }),
        { onClick: () => navigate(`/product-item-batches/${batch.id}`) }
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const { errorCode, elements = [] } = err.networkError.result
      if (errorCode === 5022) {
        setMissingCupCodes(elements)
        setFieldError('itemCodes', 'missing')
        pushNotification('error', formatMessage({ id: 'errors.create-batch.missing-cups' }))
        return
      }
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <Formik<FormFields>
      initialValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const headerActionButtons = [
          <Button
            fullWidth
            key="save"
            variant="contained"
            color="secondary"
            loading={loadingCreateBatch}
            disabled={!formikProps.dirty || !formikProps.isValid}
            onClick={() => formikProps.handleSubmit()}
          >
            <FormattedMessage id="common.actions.create" />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            icon={modelIcons.productItemBatch}
            title={formatMessage({ id: 'routes.batches-new' })}
            actionButtons={headerActionButtons}
          >
            <CardedContent>
              {missingCupCodes.length > 0 && (
                <div className="px-16 pt-16">
                  <Alert severity="error">
                    <AlertTitle>
                      <FormattedMessage id="forms.create-batch.missing-cups" />
                    </AlertTitle>
                    {missingCupCodes.join(', ')}
                  </Alert>
                </div>
              )}
              <ProductItemBatchForm {...formikProps} modelTypes={modelTypes} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export default pageWithRoleAuthorization(CreatePage, ({ can }) => can('productItemBatch', 'create'))
