import { FC } from 'react'
import { Icon, Input } from '@web-panel/shared'
import { useIntl } from 'react-intl'

type PageHeaderSearchProps = {
  value: string
  onChangeValue: (value: string) => void
}
export const PageHeaderSearch: FC<PageHeaderSearchProps> = ({ value, onChangeValue }) => {
  const { formatMessage } = useIntl()

  return (
    <>
      <Icon data-testid="PageHeaderSearch-icon" color="action">
        search
      </Icon>
      <Input
        data-testid="PageHeaderSearch-input"
        placeholder={formatMessage({ id: 'common.actions.search' })}
        disableUnderline
        className="mx-8"
        fullWidth
        value={value}
        onChange={(e) => onChangeValue(e.target.value)}
      />
    </>
  )
}
