import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { PartnerLink } from '../PartnerLink'
import { OutletLink } from '../OutletLink'
import { ProductItemLink } from '../ProductItemLink'
import { ProductItemBatchLink } from '../ProductItemBatchLink'
import { BoxLink } from '../BoxLink'
import { UserLink } from '../UserLink'
import { ContainerLink } from '../ContainerLink'
import { OrderLink } from '../OrderLink'

type Resource =
  | 'ITEM'
  | 'CONTAINER'
  | 'BATCH'
  | 'ORDER'
  | 'BOX'
  | 'OPERATIONS_CENTER'
  | 'PARTNER'
  | 'PARTNER_OUTLET'
  | 'CONSUMER'

type ResourceLinkProps = {
  type: Resource
  id: string
}

export const ResourceLink: FC<ResourceLinkProps> = ({ type, id }) => {
  switch (type) {
    case 'PARTNER':
      return <PartnerLink partnerId={id} />
    case 'PARTNER_OUTLET':
      return <OutletLink outletId={id} />
    case 'ITEM':
      return <ProductItemLink id={id} />
    case 'BATCH':
      return <ProductItemBatchLink batchId={id} />
    case 'BOX':
      return <BoxLink boxId={id} />
    case 'CONSUMER':
      return <UserLink userId={id} />
    case 'CONTAINER':
      return <ContainerLink containerId={id} />
    case 'ORDER':
      return <OrderLink orderId={id} />
    case 'OPERATIONS_CENTER':
      return <FormattedMessage id="common.labels.operations-center" />
    default:
      return null
  }
  // if (holderType === ProductItemActivityHolderType.PartnerOutlet && id)
  //   return <OutletLink outletId={id} />
  // if (holderType === ProductItemActivityHolderType.Consumer && id) return <UserLink userId={id} />
  // if (holderType === ProductItemActivityHolderType.Box && id) return <BoxLink boxId={id} />

  // return <HolderType holderType={holderType} />
}
