import _compact from 'lodash/compact'
import { Partner } from '../data-types'

export const getFullPartnerAddress = ({ invoiceAddress }: Partner): string => {
  if (!invoiceAddress) return ''

  const address = _compact([invoiceAddress.street, invoiceAddress.houseNumber]).join(' ')
  return _compact([
    address,
    invoiceAddress.postalCode,
    invoiceAddress.city,
    invoiceAddress.countryCode,
  ]).join(', ')
}
