import { FC } from 'react'
import { Form, useFormikContext } from 'formik'
import { TextField } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'

export type FormFields = {
  name: string
  description: string
  amount: number
  price: number
}

export const validationSchema = Yup.object({
  name: Yup.string().required(),
  description: Yup.string().required(),
  amount: Yup.number().min(0).required(),
  price: Yup.number().required(),
})

export const UpdateOneTimeContractSettingForm: FC = () => {
  const { values, errors, handleChange, handleSubmit } = useFormikContext<FormFields>()
  const { formatMessage } = useIntl()

  return (
    <Form className="p-24 max-w-2xl" onSubmit={handleSubmit}>
      <TextField
        fullWidth
        value={values.name}
        InputLabelProps={{ shrink: true }}
        label={formatMessage({ id: 'models.one-time-contract-setting.name' })}
        placeholder={formatMessage({ id: 'common.labels.fee-name' })}
        onChange={handleChange('name')}
        error={!!errors.name}
      />
      <TextField
        fullWidth
        className="mt-16"
        value={values.description}
        InputLabelProps={{ shrink: true }}
        label={formatMessage({ id: 'models.one-time-contract-setting.description' })}
        placeholder={formatMessage({ id: 'common.labels.fee-description' })}
        onChange={handleChange('description')}
        error={!!errors.description}
      />

      <TextField
        fullWidth
        type="number"
        className="mt-16"
        value={values.amount}
        label={formatMessage({ id: 'models.one-time-contract-setting.amount' })}
        onChange={handleChange('amount')}
        error={!!errors.amount}
      />

      <TextField
        fullWidth
        type="number"
        className="mt-16"
        value={values.price}
        label={formatMessage({ id: 'models.one-time-contract-setting.price' })}
        onChange={handleChange('price')}
        error={!!errors.price}
      />
    </Form>
  )
}
