import { ProductItemSize, ProductItemType } from '@web-panel/api'
import * as Yup from 'yup'

export type ProductItemData = {
  qrCode: string
  rfid: string
  brand: string
  size: ProductItemSize
  type: ProductItemType
}

const validationSchema = Yup.object().shape({
  qrCode: Yup.string().required(),
  rfid: Yup.string().required(),
  brand: Yup.string().required(),
  size: Yup.string().oneOf(Object.values(ProductItemSize)).required(),
  type: Yup.string().oneOf(Object.values(ProductItemType)).required(),
})

function getProductItemSize(value: string) {
  const keys = Object.values(ProductItemSize).filter((size) => size === value)
  return keys.length > 0 ? keys[0] : null
}

export type ParseResult = {
  validItems: ProductItemData[]
  totalCount: number
  invalidLines: number[]
}

export function parseCsvFile(str: string): ParseResult {
  const lines = str.split(/\r?\n/).filter((s) => s.length > 0)

  const result = lines.reduce<ParseResult>(
    (res, line, id) => {
      const [qrCode = '', rfid = '', brand = '', sizeString = ''] = line
        .replace(/"/g, '')
        .split(/[,;]/)
      const size = getProductItemSize(sizeString.toUpperCase())
      const item = {
        qrCode,
        rfid,
        brand,
        size,
        type: ProductItemType.Cup,
      }
      if (validationSchema.isValidSync(item)) {
        return {
          ...res,
          validItems: [...res.validItems, item as ProductItemData],
        }
      }
      return {
        ...res,
        invalidLines: [...res.invalidLines, id],
      }
    },
    {
      validItems: [],
      totalCount: lines.length,
      invalidLines: [],
    }
  )

  return result
}
