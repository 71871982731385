import { FC } from 'react'
import { SelectableList } from '@web-panel/shared'
import { OrderStatus, useCities } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { useFilters } from './FiltersContext'

const Filters: FC = () => {
  const { filters, setFilter } = useFilters()
  const { formatMessage } = useIntl()
  const { data: cities = [] } = useCities()

  const cityCodes = cities.map(({ code }) => code)

  return (
    <div className="flex flex-col gap-16">
      <SelectableList
        value={filters.status}
        options={Object.values(OrderStatus)}
        optionTitle={(option) => formatMessage({ id: `order-statuses.${option}` })}
        label={formatMessage({ id: 'models.order.status' })}
        onSelect={(status) => setFilter('status', status)}
      />

      <SelectableList
        value={filters.cityCode}
        options={cityCodes}
        optionTitle={(option) => cities.find(({ code }) => code === option)?.name ?? ''}
        label={formatMessage({ id: 'models.order.city' })}
        onSelect={(cityCode) => setFilter('cityCode', cityCode)}
      />
    </div>
  )
}

export default Filters
