import { LabelStatus } from './label'

export type Tracking = {
  id: string
  status: LabelStatus
  updateStatusDate: string
  originStatus: string
  originDescription: string
  originUpdateStatusDate: string
}

export const trackingFields = `
  id
  status
  updateStatusDate
  originStatus
  originDescription
  originUpdateStatusDate
`
