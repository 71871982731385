import { useMutation, useQuery } from '@apollo/client'
import { CollectionQueryHook, MutationHook, QueryHook } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  BusinessCategoriesDocument,
  BusinessCategoriesDocumentInput,
  BusinessCategoriesDocumentResponse,
  BusinessCategoryDocument,
  BusinessCategoryDocumentInput,
  BusinessCategoryDocumentResponse,
  ContractPackageGroupsDocument,
  ContractPackageGroupsDocumentInput,
  ContractPackageGroupsDocumentResponse,
  ContractPackageSettingsDocument,
  ContractPackageSettingsDocumentInput,
  ContractPackageSettingsDocumentResponse,
  ContractPartnerSettingsDocument,
  ContractPartnerSettingsDocumentInput,
  ContractPartnerSettingsDocumentResponse,
  ContractSettingsDocument,
  ContractSettingsDocumentInput,
  ContractSettingsDocumentResponse,
  CreateContractPartnerPackageDocument,
  CreateContractPartnerPackageDocumentInput,
  CreateContractPartnerSettingsDocumentResponse,
  CreateOneTimeContractSettingDocument,
  CreateOneTimeContractSettingDocumentInput,
  CreateOneTimeContractSettingDocumentResponse,
  DeletePartnerContractSettingDocumentInput,
  DeletePartnerContractSettingDocumentResponse,
  DeletePartnerContractSettingDocument,
  OneTimeContractPackageTemplatesDocument,
  OneTimeContractPackageTemplatesDocumentInput,
  OneTimeContractPackageTemplatesDocumentResponse,
  OneTimeContractSettingDocument,
  OneTimeContractSettingDocumentInput,
  OneTimeContractSettingDocumentResponse,
  OneTimeContractSettingsDocument,
  OneTimeContractSettingsDocumentInput,
  OneTimeContractSettingsDocumentResponse,
  UpdateContractSettingDocument,
  UpdateContractSettingDocumentInput,
  UpdateContractSettingDocumentResponse,
  UpdateOneTimeContractSettingDocument,
  UpdateOneTimeContractSettingDocumentInput,
  UpdateOneTimeContractSettingDocumentResponse,
  AddFeatureContractSettingDocumentInput,
  AddFeatureContractSettingDocumentResponse,
  AddFeatureContractSettingDocument,
} from '../docs'

export const useContractSettings: CollectionQueryHook<
  Omit<ContractSettingsDocumentInput, 'pagination'>,
  ContractSettingsDocumentResponse['contractSettings']['contractSettingConfigs']
> = (input, options) => {
  const variables: ContractSettingsDocumentInput = {
    ...input,
    pagination: defaultCollectionVariables,
  }

  const { data, loading, fetchMore, refetch } = useQuery<ContractSettingsDocumentResponse>(
    ContractSettingsDocument,
    { ...options, variables }
  )

  const loadMore = async () => {
    if (loading || !data || !data.contractSettings.hasNext) return

    const offset = data.contractSettings.contractSettingConfigs.length
    await fetchMore({
      variables: { ...variables, pagination: { ...variables.pagination, offset } },
    })
  }

  return {
    data: data?.contractSettings.contractSettingConfigs,
    loading,
    loadMore,
    hasMore: data?.contractSettings.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useContractPackageGroups: QueryHook<
  ContractPackageGroupsDocumentInput,
  ContractPackageGroupsDocumentResponse['contractPackageGroupsCollection']['contractPackageGroups']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<ContractPackageGroupsDocumentResponse>(
    ContractPackageGroupsDocument,
    { ...options, variables }
  )

  return {
    data: data?.contractPackageGroupsCollection.contractPackageGroups,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useContractPackageSettings: CollectionQueryHook<
  Omit<ContractPackageSettingsDocumentInput, 'pagination'>,
  ContractPackageSettingsDocumentResponse['contractPackageSettingsCollection']['contractPackageSettings']
> = (filters, options) => {
  const variables: ContractPackageSettingsDocumentInput = {
    ...filters,
    pagination: defaultCollectionVariables,
  }

  const { data, loading, fetchMore, refetch } = useQuery<ContractPackageSettingsDocumentResponse>(
    ContractPackageSettingsDocument,
    { ...options, variables }
  )

  const loadMore = async () => {
    if (loading || !data || !data.contractPackageSettingsCollection.hasNext) return

    const offset = data.contractPackageSettingsCollection?.contractPackageSettings?.length
    await fetchMore({
      variables: { ...variables, pagination: { ...variables.pagination, offset } },
    })
  }

  return {
    data: data?.contractPackageSettingsCollection?.contractPackageSettings,
    loading,
    loadMore,
    hasMore: data?.contractPackageSettingsCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

type UseContractPartnerSettingsResponse = Pick<
  ContractPartnerSettingsDocumentResponse['contractPartnerSettings'],
  'category' | 'contractPackageCode' | 'contractSettings'
>

export const useContractPartnerSettings: CollectionQueryHook<
  Omit<ContractPartnerSettingsDocumentInput, 'pagination'>,
  UseContractPartnerSettingsResponse
> = (input, options) => {
  const variables: ContractPartnerSettingsDocumentInput = {
    ...input,
    pagination: defaultCollectionVariables,
  }

  const { data, loading, fetchMore, refetch } = useQuery<ContractPartnerSettingsDocumentResponse>(
    ContractPartnerSettingsDocument,
    { ...options, variables }
  )

  const loadMore = async () => {
    if (loading || !data || !data.contractPartnerSettings.hasNext) return

    const offset = data.contractPartnerSettings.contractSettings.length
    await fetchMore({
      variables: { ...variables, pagination: { ...variables.pagination, offset } },
    })
  }

  const responseData = data && {
    category: data.contractPartnerSettings.category,
    contractPackageCode: data.contractPartnerSettings.contractPackageCode,
    contractSettings: data.contractPartnerSettings.contractSettings,
  }

  return {
    data: responseData,
    loading,
    loadMore,
    hasMore: data?.contractPartnerSettings.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useBusinessCategories: QueryHook<
  BusinessCategoriesDocumentInput,
  BusinessCategoriesDocumentResponse['businessCategories']['categories']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<BusinessCategoriesDocumentResponse>(
    BusinessCategoriesDocument,
    { variables, ...options }
  )

  return {
    data: data?.businessCategories.categories,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useBusinessCategory: QueryHook<
  BusinessCategoryDocumentInput,
  BusinessCategoryDocumentResponse['businessCategory']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<BusinessCategoryDocumentResponse>(
    BusinessCategoryDocument,
    { ...options, variables }
  )

  return {
    data: data?.businessCategory,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useCreatePartnerContractPackage: MutationHook<
  CreateContractPartnerPackageDocumentInput,
  CreateContractPartnerSettingsDocumentResponse
> = (options) => {
  const [execute, { loading }] = useMutation<CreateContractPartnerSettingsDocumentResponse>(
    CreateContractPartnerPackageDocument,
    options
  )

  async function request(variables: CreateContractPartnerPackageDocumentInput) {
    const { data } = await execute({
      variables: { ...variables, options: variables.options ?? {} },
    })
    if (!data) throw new Error('Could not create partner package')

    return data
  }

  return {
    request,
    loading,
  }
}

export const useOneTimeContractSettings: CollectionQueryHook<
  Omit<OneTimeContractSettingsDocumentInput, 'pagination'>,
  OneTimeContractSettingsDocumentResponse['oneTimeContractSettingsCollection']['contractSettings']
> = (input, options) => {
  const variables: OneTimeContractSettingsDocumentInput = {
    ...input,
    pagination: defaultCollectionVariables,
  }

  const { data, loading, fetchMore, refetch } = useQuery<OneTimeContractSettingsDocumentResponse>(
    OneTimeContractSettingsDocument,
    { ...options, variables }
  )

  const loadMore = async () => {
    if (loading || !data || !data.oneTimeContractSettingsCollection.hasNext) return

    const offset = data.oneTimeContractSettingsCollection.contractSettings.length
    await fetchMore({
      variables: { ...variables, pagination: { ...variables.pagination, offset } },
    })
  }

  return {
    data: data?.oneTimeContractSettingsCollection.contractSettings,
    loading,
    loadMore,
    hasMore: data?.oneTimeContractSettingsCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useOneTimeContractSetting: QueryHook<
  OneTimeContractSettingDocumentInput,
  OneTimeContractSettingDocumentResponse['oneTimeContractSetting']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<OneTimeContractSettingDocumentResponse>(
    OneTimeContractSettingDocument,
    { variables, ...options }
  )

  return {
    data: data?.oneTimeContractSetting,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useOneTimeContractPackageTemplates: QueryHook<
  Omit<OneTimeContractPackageTemplatesDocumentInput, 'pagination'>,
  OneTimeContractPackageTemplatesDocumentResponse['oneTimeContractPackageTemplates']['contractPackageSettings']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<OneTimeContractPackageTemplatesDocumentResponse>(
    OneTimeContractPackageTemplatesDocument,
    {
      variables: {
        ...variables,
        pagination: defaultCollectionVariables,
      },
      ...options,
    }
  )

  return {
    data: data?.oneTimeContractPackageTemplates?.contractPackageSettings,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useCreateOneTimeContractSetting: MutationHook<
  CreateOneTimeContractSettingDocumentInput,
  CreateOneTimeContractSettingDocumentResponse['createOneTimeContractSetting']
> = (options) => {
  const [execute, { loading }] = useMutation<CreateOneTimeContractSettingDocumentResponse>(
    CreateOneTimeContractSettingDocument,
    options
  )

  async function request(variables: CreateOneTimeContractSettingDocumentInput) {
    const { data } = await execute({
      variables: { ...variables },
    })
    if (!data) throw new Error('Could not create one time contract setting')

    return data.createOneTimeContractSetting
  }

  return {
    request,
    loading,
  }
}

export const useUpdateOneTimeContractSetting: MutationHook<
  UpdateOneTimeContractSettingDocumentInput,
  UpdateOneTimeContractSettingDocumentResponse['updateOneTimeContractSetting']
> = (options) => {
  const [execute, { loading }] = useMutation<UpdateOneTimeContractSettingDocumentResponse>(
    UpdateOneTimeContractSettingDocument,
    options
  )

  async function request(variables: UpdateOneTimeContractSettingDocumentInput) {
    const { data } = await execute({
      variables: { ...variables },
    })
    if (!data) throw new Error('Could not create one time contract setting')

    return data.updateOneTimeContractSetting
  }

  return {
    request,
    loading,
  }
}

export const useDeleteOneTimeContractSetting: MutationHook<
  DeletePartnerContractSettingDocumentInput,
  void
> = (options) => {
  const [execute, { loading }] = useMutation<DeletePartnerContractSettingDocumentResponse>(
    DeletePartnerContractSettingDocument,
    options
  )

  async function request(variables: DeletePartnerContractSettingDocumentInput) {
    await execute({
      variables,
      update: (cache) => {
        const normalizedId = cache.identify({
          id: variables.contractSettingId,
          __typename: 'OneTimeContractSetting',
        })
        cache.evict({ id: normalizedId })
        cache.gc()
      },
    })
  }

  return {
    request,
    loading,
  }
}

export const useAddFeatureContractSetting: MutationHook<
  AddFeatureContractSettingDocumentInput,
  AddFeatureContractSettingDocumentResponse['addFeatureContractPartnerSetting']
> = (options) => {
  const [execute, { loading }] = useMutation<AddFeatureContractSettingDocumentResponse>(
    AddFeatureContractSettingDocument,
    options
  )

  async function request(variables: AddFeatureContractSettingDocumentInput) {
    const { data } = await execute({ variables })

    if (!data) throw new Error('Could not update contract partner setting')

    return data.addFeatureContractPartnerSetting
  }

  return {
    request,
    loading,
  }
}

export const useUpdateContractSetting: MutationHook<
  UpdateContractSettingDocumentInput,
  UpdateContractSettingDocumentResponse['updateContractPartnerSetting']
> = (options) => {
  const [execute, { loading }] = useMutation<UpdateContractSettingDocumentResponse>(
    UpdateContractSettingDocument,
    options
  )

  async function request(variables: UpdateContractSettingDocumentInput) {
    const { data } = await execute({ variables })

    if (!data) throw new Error('Could not update contract partner setting')

    return data.updateContractPartnerSetting
  }

  return {
    request,
    loading,
  }
}

export const useDeletePartnerContractSetting: MutationHook<
  DeletePartnerContractSettingDocumentInput,
  void
> = (options) => {
  const [execute, { loading }] = useMutation<DeletePartnerContractSettingDocumentResponse>(
    DeletePartnerContractSettingDocument,
    options
  )

  async function request(variables: DeletePartnerContractSettingDocumentInput) {
    await execute({
      variables,
      update: (cache) => {
        const normalizedId = cache.identify({
          id: variables.contractSettingId,
          __typename: 'ContractPartnerSetting',
        })
        cache.evict({ id: normalizedId })
        cache.gc()
      },
    })
  }

  return {
    request,
    loading,
  }
}
