import { gql } from '@apollo/client'
import { Rental, rentalFields } from '@web-panel/api'

export type RentalsDocumentInput = {
  filters: {
    partnerId?: string
  }
  pagination: {
    offset?: number
    limit?: number
  }
}
export type RentalsDocumentResponse = {
  rentals: {
    inventories: Rental[]
    hasNext: boolean
  }
}
export const RentalsDocument = gql`
  query Rentals($filters: any, $pagination: any) {
    rentals(filters: $filters, pagination: $pagination)
      @rest(
        type: "RentalsCollection",
        path: "/partners-service/inventory/rentals?{args.filters}&{args.pagination}",
        method: "GET"
      ) {
      inventories @type(name: "Rental") {
        ${rentalFields}
      }
      hasNext
    }
  }
`
