import { FC } from 'react'
import { OutletStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { Chip, ComponentColor } from '@web-panel/shared'

type Color = Exclude<ComponentColor, 'default'>
export const statusColorMapping: Record<OutletStatus, Color> = {
  [OutletStatus.Active]: 'success',
  [OutletStatus.Deleted]: 'error',
  [OutletStatus.Inactive]: 'warning',
  [OutletStatus.Pending]: 'info',
}

type OutletStatusChipProps = {
  status: OutletStatus
}

const OutletStatusChip: FC<OutletStatusChipProps> = ({ status }) => {
  const { formatMessage } = useIntl()

  const color = statusColorMapping[status] ?? 'default'

  return (
    <Chip
      className="min-w-72"
      color={color}
      label={formatMessage({ id: `outlet-statuses.${status}` })}
    />
  )
}

export default OutletStatusChip
