import { FC } from 'react'

import { AppPage, Button, InfinitScroller, useDebouncedSearch } from '@web-panel/shared'
import { useIntl, FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { PageHeaderSearch } from '@local/components/PageHeaderSearch'
import modelIcons from '@local/model-icons'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useBatches } from '@web-panel/oc-api'
import IndexTable from './Tables/IndexTable'
import { useFilters, withFilters } from './FiltersContex'
import Filters from './Filters'

const IndexPage: FC = () => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { debouncedFilters } = useFilters()
  const { search, onChangeSearch, debouncedSearch } = useDebouncedSearch()
  const { can } = useRoleAuthorization()
  const {
    data: batches = [],
    loading,
    hasMore,
    loadMore,
  } = useBatches(
    { ...debouncedFilters, search: debouncedSearch },
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    }
  )

  const onLoadMore = () => {
    if (!hasMore) return
    loadMore()
  }

  const headerActionButtons = [
    can(
      'productItemBatch',
      'create',
      <Button
        fullWidth
        key="create"
        variant="contained"
        color="secondary"
        onClick={() => navigate('/product-item-batches/create')}
      >
        <FormattedMessage id="pages.product-item-batch.index.actions.add-batch" />
      </Button>
    ),
  ]

  return (
    <AppPage
      loading={loading}
      icon={modelIcons.productItemBatch}
      title={formatMessage({ id: 'routes.batches' })}
      actionButtons={headerActionButtons}
      Sidebar={Filters}
      searchComponent={<PageHeaderSearch value={search} onChangeValue={onChangeSearch} />}
    >
      <InfinitScroller onLoadMore={onLoadMore}>
        <IndexTable data={batches} />
      </InfinitScroller>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(withFilters(IndexPage), ({ can }) =>
  can('productItemBatch', 'list')
)
