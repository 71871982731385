import { FC } from 'react'
import { Partner, Outlet, isOutletHasLegalAddress, OrderInvoiceType } from '@web-panel/api'
import { AppPage, CardedContent, Button, useNotifications } from '@web-panel/shared'
import modelIcons from '@local/model-icons'
import { FormattedMessage, useIntl } from 'react-intl'
import { Formik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { format as formatDate, addDays } from 'date-fns'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { useCreateOrder } from '@web-panel/oc-api'
import {
  CreateOrderForm,
  FormFields,
  defaultValues,
  validationSchema,
} from './Forms/CreateOrderForm'
import { getOrderRefundablesAndConsumables } from './helpers'

const CreatePage: FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()
  const { state } = useLocation()
  const { request: createOrder, loading: loadingCreate } = useCreateOrder()
  const partner = (state?.partner ?? null) as Partner | null
  const outlet = (state?.outlet ?? null) as Outlet | null

  const onSubmit = async (values: FormFields) => {
    if (!values.partner || !values.outlet) return
    const { refundables, consumables } = getOrderRefundablesAndConsumables(values.orderItems)

    try {
      await createOrder({
        input: {
          partnerId: values.partner.id,
          outletId: values.outlet.id,
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          paymentType: values.partner.finance.paymentType,
          comment: '',
          currencyCode: 'CHF', // TODO: deprecate soon
          refundables,
          consumables,
          returnType: values.returnType,
          invoiceType: values.invoiceType,
          expectedDeliveryDate: formatDate(values.expectedDeliveryDate, 'yyyy-MM-dd'),
          expectedDeliveryTimeFrom: values.expectedDeliveryTimeFrom,
          expectedDeliveryTimeTo: values.expectedDeliveryTimeTo,
        },
      })
      navigate(-1)
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-created' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const initialValues: FormFields = {
    ...defaultValues,
    partner,
    outlet,
    firstName: outlet?.firstName ?? '',
    lastName: outlet?.lastName ?? '',
    phone: outlet?.phone ?? '',
    expectedDeliveryDate: addDays(new Date(), 1),
    invoiceType:
      outlet && isOutletHasLegalAddress(outlet)
        ? OrderInvoiceType.OutletInvoice
        : OrderInvoiceType.PartnerInvoice,
  }

  return (
    <Formik<FormFields>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formProps) => {
        const { orderItems } = formProps.values
        const totalAmount = orderItems.reduce((sum, { amount }) => sum + amount, 0)
        const isDisabled = !formProps.dirty || !formProps.isValid || !totalAmount

        const headerActionButtons = [
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            loading={loadingCreate}
            disabled={isDisabled}
            onClick={() => formProps.handleSubmit()}
          >
            <FormattedMessage
              id="common.actions.create-with-name"
              values={{ name: formatMessage({ id: 'models.order' }) }}
            />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            icon={modelIcons.order}
            title={formatMessage({ id: 'routes.orders-create' })}
            actionButtons={headerActionButtons}
          >
            <CardedContent>
              <CreateOrderForm {...formProps} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export default pageWithRoleAuthorization(CreatePage, ({ can }) => can('order', 'create'))
