import { FC } from 'react'
import { useCountry } from '@web-panel/api'
import { AppPage, CardedContent, Button, useNotifications } from '@web-panel/shared'
import modelIcons from '@local/model-icons'
import { FormattedMessage, useIntl } from 'react-intl'
import { Formik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { Error404 } from '@local/pages/404'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { useUpdateGeoCountry } from '@web-panel/oc-api'
import { EditCountryForm, FormFields, validationSchema } from './Forms/EditCountryForm'

const SettingsCountryEditPageComponent: FC = () => {
  const { formatMessage } = useIntl()
  const { code } = useParams()
  const { pushNotification } = useNotifications()
  const navigate = useNavigate()
  const { data: country, loading } = useCountry({ code: code ?? '' })
  const { request: updateGeoCountry, loading: loadingUpdate } = useUpdateGeoCountry()

  if (loading) return null
  if (!country) return <Error404 />

  const initialValues: FormFields = {
    name: country.name,
    enabledForBilling: country.enabledForBilling,
    enabledForLocation: country.enabledForLocation,
  }

  const onSubmit = async (input: FormFields) => {
    try {
      await updateGeoCountry({
        code: country.code,
        input: {
          ...input,
          code: country.code,
        },
      })
      navigate(-1)
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <Formik<FormFields>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formProps) => {
        const headerActionButtons = [
          <Button
            fullWidth
            loading={loadingUpdate}
            key="save"
            variant="contained"
            color="secondary"
            disabled={!formProps.isValid || !formProps.dirty}
            onClick={() => formProps.handleSubmit()}
          >
            <FormattedMessage id="common.actions.update" />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            icon={modelIcons.settings}
            title={formatMessage({ id: 'routes.settings.country.edit' }, { name: country.name })}
            actionButtons={headerActionButtons}
          >
            <CardedContent>
              <EditCountryForm {...formProps} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export const SettingsCountryEditPage = pageWithRoleAuthorization(
  SettingsCountryEditPageComponent,
  ({ can }) => can('geoCountry', 'update')
)
