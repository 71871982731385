import { FC, useMemo } from 'react'
import { BusinessCategory, BusinessCategoryGroupType } from '@web-panel/api'
import { Autocomplete, Box, TextField } from '@web-panel/shared'
import { useBusinessCategories } from '@web-panel/oc-api'

export type BusinessCategoryAutocompleteProps = {
  label: string
  className?: string
  fullWidth?: boolean
  disabled?: boolean
  error?: boolean
  value: string | null
  onChange: (data: BusinessCategory | null) => void
}

export const BusinessCategoryAutocomplete: FC<BusinessCategoryAutocompleteProps> = ({
  label,
  className,
  fullWidth,
  disabled,
  error,
  value,
  onChange,
}) => {
  const { data: businessCategories = [] } = useBusinessCategories({
    businessCategoryType: BusinessCategoryGroupType.Sub,
  })
  const selectedBusinessCategory = useMemo(
    () => businessCategories.find(({ id }) => id === value) ?? null,
    [JSON.stringify(businessCategories), value]
  )

  return (
    <Autocomplete
      disablePortal
      className={className}
      fullWidth={fullWidth}
      disabled={disabled}
      options={businessCategories}
      renderInput={(params) => <TextField error={error} {...params} label={label} />}
      getOptionLabel={({ code }) => code}
      renderOption={(props, option) => (
        <Box {...props} key={option.id} component="li">
          {option.code}
        </Box>
      )}
      onChange={(_data, val) => onChange(val)}
      value={selectedBusinessCategory}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      filterOptions={(x) => x}
    />
  )
}
