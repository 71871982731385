import { FC } from 'react'
import { TableSortLabel as MUITableSortLabel } from '@mui/material'
import { SortDirection } from '../../builders/sort-context-builder'

export type TableSortLabelProps = {
  active: boolean
  direction?: SortDirection
  onToggle?: () => void
}

export const TableSortLabel: FC<TableSortLabelProps> = ({
  active,
  direction,
  onToggle,
  children,
}) => {
  return (
    <MUITableSortLabel
      active={active}
      direction={direction === SortDirection.Ascending ? 'asc' : 'desc'}
      onClick={onToggle}
    >
      {children}
    </MUITableSortLabel>
  )
}
