import { FC } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  TextField,
  Button,
  InputAdornment,
  Icon,
  WorkingHoursInput,
  useModalRef,
} from '@web-panel/shared'
import { Country, City, WorkingHours } from '@web-panel/api'
import * as Yup from 'yup'
import { FormikErrors } from 'formik'
import GooglePlacesModal from '@local/modals/GooglePlacesModal'
import CountryAutocompleteInput from '@local/components/Autocomplete/CountryAutocompleteInput'
import CityAutocompleteInput from '@local/components/Autocomplete/CityAutocompleteInput'
import PinDropModal from '@local/modals/PinDropModal'
import AddLocationButton from '../Components/AddLocationButton'

export type LocationData = {
  id: string | null
  country: Country | null
  city: City | null
  name: string
  googlePlaceId: string | null
  latitude: string | null
  longitude: string | null
  street: string
  houseNumber: string
  postalCode: string
  workingHours: WorkingHours[]
  editable: boolean
}

export const defaultLocation: LocationData = {
  id: null,
  country: null,
  city: null,
  name: '',
  googlePlaceId: '',
  latitude: null,
  longitude: null,
  street: '',
  houseNumber: '',
  postalCode: '',
  workingHours: [],
  editable: true,
}

export const validationSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  country: Yup.object().required(),
  city: Yup.object().required(),
  name: Yup.string().required(),
  googlePlaceId: Yup.string().nullable(),
  latitude: Yup.number().required(),
  longitude: Yup.number().required(),
  street: Yup.string().required(),
  houseNumber: Yup.string().required(),
  postalCode: Yup.string().required(),
  workingHours: Yup.array().required(),
})

type LocationInputProps = {
  value: LocationData | null
  outletId: string | null
  disabled?: boolean
  onChange: (location: LocationData | null) => void
  errors?: FormikErrors<LocationData>
}

const LocationInput: FC<LocationInputProps> = ({
  value,
  outletId,
  disabled = false,
  errors = {},
  onChange,
}) => {
  const { formatMessage } = useIntl()
  const modalRef = useModalRef()
  const pinDropModalRef = useModalRef()

  const handleChange = (changedValues: Partial<LocationData>) => {
    if (!value) return

    onChange({
      ...value,
      ...changedValues,
    })
  }

  const handleChangeCountry = (country: Country | null) => {
    handleChange({
      ...defaultLocation,
      country,
    })
  }

  const handleChangeCity = (city: City | null) => {
    handleChange({
      ...defaultLocation,
      country: value?.country,
      city,
    })
  }

  if (!value)
    return (
      <AddLocationButton
        title={formatMessage({ id: 'common.labels.add-location' })}
        outletId={outletId}
        variant="contained"
        onAddLocation={(location) => onChange(location)}
      />
    )

  const showLocationDetails = value.latitude !== null && value.longitude !== null

  return (
    <div>
      <CountryAutocompleteInput
        fullWidth
        enabledFor="location"
        label={formatMessage({ id: 'models.location.country' })}
        disabled={disabled}
        value={value.country}
        onChange={(country) => handleChangeCountry(country)}
        error={!!errors.country}
      />
      {value.country && (
        <CityAutocompleteInput
          fullWidth
          className="mt-16"
          country={value.country}
          label={formatMessage({ id: 'models.location.city' })}
          disabled={!value.country}
          value={value.city}
          onChange={(city) => handleChangeCity(city)}
        />
      )}

      {!showLocationDetails && value.city && (
        <div className="mt-16 text-center">
          <Button variant="contained" onClick={() => modalRef.current?.open()}>
            <FormattedMessage id="common.actions.find-on-google-maps" />
          </Button>
        </div>
      )}

      {showLocationDetails && (
        <>
          <TextField
            fullWidth
            className="mt-16"
            label={formatMessage({ id: 'models.location.name' })}
            value={value.name}
            onChange={(e) => handleChange({ name: e.target.value })}
            error={!!errors.name}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    disabled={!value.city || !value.country}
                    onClick={() => modalRef.current?.open()}
                  >
                    <Icon>edit_location</Icon>
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          <div className="mt-16 md:flex md:flex-row">
            <TextField
              fullWidth
              label={formatMessage({ id: 'models.location.google-place-id' })}
              value={value.googlePlaceId ?? ''}
              error={!!errors.googlePlaceId}
              disabled
            />
            <TextField
              fullWidth
              className="mt-16 md:ml-16 md:mt-0"
              label={formatMessage({ id: 'models.location.latitude' })}
              value={value.latitude}
              error={!!errors.latitude}
              onChange={(e) => handleChange({ latitude: e.target.value })}
            />
            <TextField
              fullWidth
              className="mt-16 md:ml-16 md:mt-0"
              label={formatMessage({ id: 'models.location.longitude' })}
              value={value.longitude}
              error={!!errors.longitude}
              onChange={(e) => handleChange({ longitude: e.target.value })}
            />
            <Button
              className="mt-16 w-full md:w-auto md:ml-16 md:mt-0"
              variant="outlined"
              onClick={() => pinDropModalRef.current?.open()}
            >
              <Icon>my_location</Icon>
            </Button>
          </div>
          <TextField
            fullWidth
            className="mt-16"
            label={formatMessage({ id: 'models.location.street' })}
            value={value.street}
            error={!!errors.street}
            onChange={(e) => handleChange({ street: e.target.value })}
          />
          <TextField
            fullWidth
            className="mt-16"
            label={formatMessage({ id: 'models.location.house-number' })}
            value={value.houseNumber}
            error={!!errors.houseNumber}
            onChange={(e) => handleChange({ houseNumber: e.target.value })}
          />
          <TextField
            fullWidth
            className="mt-16"
            label={formatMessage({ id: 'models.location.postal-code' })}
            value={value.postalCode}
            error={!!errors.postalCode}
            onChange={(e) => handleChange({ postalCode: e.target.value })}
          />
          <WorkingHoursInput
            value={value.workingHours}
            onChange={(workingHours) => handleChange({ workingHours })}
            dayLabels={{
              monday: formatMessage({ id: 'time.day-of-week.short.monday' }),
              tuesday: formatMessage({ id: 'time.day-of-week.short.tuesday' }),
              wednesday: formatMessage({ id: 'time.day-of-week.short.wednesday' }),
              thursday: formatMessage({ id: 'time.day-of-week.short.thursday' }),
              friday: formatMessage({ id: 'time.day-of-week.short.friday' }),
              saturday: formatMessage({ id: 'time.day-of-week.short.saturday' }),
              sunday: formatMessage({ id: 'time.day-of-week.short.sunday' }),
            }}
            actionLabels={{
              add: formatMessage({ id: 'common.actions.add' }),
              delete: formatMessage({ id: 'common.actions.delete' }),
            }}
            timeFrameLabels={{
              opening: formatMessage({ id: 'models.location.opening-time' }),
              closing: formatMessage({ id: 'models.location.closing-time' }),
            }}
          />
        </>
      )}
      <div className="text-right mt-16">
        <Button variant="contained" color="error" onClick={() => onChange(null)}>
          <FormattedMessage id="common.labels.delete-location" />
        </Button>
      </div>
      {value.country && value.city && (
        <GooglePlacesModal
          ref={modalRef}
          country={value.country}
          city={value.city}
          onSubmit={({ lat, lng, ...place }) => {
            handleChange({
              latitude: String(lat),
              longitude: String(lng),
              ...place,
            })
          }}
        />
      )}
      <PinDropModal
        ref={pinDropModalRef}
        lat={Number(value.latitude)}
        lng={Number(value.longitude)}
        onUpdate={({ lat, lng }) =>
          onChange({ ...value, latitude: String(lat), longitude: String(lng) })
        }
      />
    </div>
  )
}

export default LocationInput
