import { gql } from '@apollo/client'
import {
  invoiceFields,
  Invoice,
  OrderInvoiceType,
  OrderInvoiceStatus,
  OrderInvoiceFrequency,
} from '@web-panel/api'

export type InvoicesDocumentInput = {
  limit: number
  offset: number
  params: {
    type?: OrderInvoiceType
    status?: OrderInvoiceStatus
    frequency?: OrderInvoiceFrequency
    partnerId?: string
    outletId?: string
    externalId?: string
  }
}
export type InvoicesDocumentResponse = {
  invoicesCollection: {
    invoices: Invoice[]
    hasNext: boolean
  }
}
export const InvoicesDocument = gql`
  query Invoices($limit: Int!, $offset: Int!,  $params: any) {
    invoicesCollection(limit: $limit, offset: $offset, params: $params)
      @rest(
        type: "InvoicesCollection",
        path: "/documents-service/invoices?limit={args.limit}&offset={args.offset}&{args.params}"
      ) {
        invoices @type(name: "Invoice") {
        ${invoiceFields}
      }
      hasNext
    }
  }
`

export type UpdateInvoiceDocumentInput = {
  id: string
  input: {
    status: OrderInvoiceStatus
  }
}
export type UpdateInvoiceDocumentResponse = void
export const UpdateInvoiceDocument = gql`
  mutation UpdateInvoice($id: ID!, $input: any) {
    updateInvoice(id: $id, input: $input)
      @rest(type: "NoCache", path: "/documents-service/invoices/{args.id}", method: "PUT") {
      NoCache
    }
  }
`
