import { FC, useEffect } from 'react'
import { DefaultInfoPage } from '@web-panel/shared'
import { useGetTokens, useAuthContext } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'

const ConfirmEmail: FC = () => {
  const { formatMessage } = useIntl()
  const { signin } = useAuthContext()
  const [searchParams] = useSearchParams()
  const authToken = searchParams.get('authToken') ?? ''
  const { data: tokens, loading } = useGetTokens({ authToken })

  const isFailed = !tokens && !loading

  useEffect(() => {
    if (!tokens) return

    signin(tokens)
  }, [tokens])

  const titleKey = !isFailed
    ? 'pages.email-confirmation.title'
    : 'pages.email-confirmation.error-title'

  const messageKey = !isFailed
    ? 'pages.email-confirmation.message'
    : 'pages.email-confirmation.error-message'

  return (
    <DefaultInfoPage
      variant={isFailed ? 'error' : 'default'}
      title={formatMessage({ id: titleKey })}
      message={formatMessage({ id: messageKey })}
    />
  )
}

export default ConfirmEmail
