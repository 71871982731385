import { FC } from 'react'
import { Box, shortLocationAddressString } from '@web-panel/api'
import { Paper, TableGrid, HeartbeatCounter, useFormatDate } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import BoxStatusChip from '@local/components/BoxStatusChip'
import { useNavigate } from 'react-router-dom'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useSorting } from '../sorting'

type IndexTableProps = {
  data: Box[]
  loading: boolean
}

export const IndexTable: FC<IndexTableProps> = ({ data, loading }) => {
  const { field, direction, handleToggleSort } = useSorting()
  const { formatMessage } = useIntl()
  const { can, cannot } = useRoleAuthorization()
  const formatDate = useFormatDate()
  const navigate = useNavigate()

  return (
    <Paper className="overflow-hidden">
      <TableGrid
        collection={data}
        loading={loading}
        fields={[
          {
            title: formatMessage({ id: 'models.box.core-id' }),
            render: ({ coreId }) => coreId,
          },
          {
            title: formatMessage({ id: 'models.location.name' }),
            render: ({ location }) => location?.name,
          },
          {
            title: formatMessage({ id: 'models.box.fw-version' }),
            render: ({ fwVersion }) => fwVersion,
          },
          {
            title: formatMessage({ id: 'models.box.location.city' }),
            render: ({ location }) => location?.city.name,
          },
          {
            title: formatMessage({ id: 'models.box.location.address' }),
            render: ({ location }) => location && shortLocationAddressString(location),
          },
          {
            title: formatMessage({ id: 'models.box.cups' }),
            render: ({ cups }) => cups,
            sorting: {
              active: field === 'ITEMS',
              direction,
              onToggle: handleToggleSort('ITEMS'),
            },
          },
          {
            title: formatMessage({ id: 'models.box.status' }),
            render: ({ status }) => status && <BoxStatusChip status={status} />,
            align: 'center',
            sorting: {
              active: field === 'STATUS',
              direction,
              onToggle: handleToggleSort('STATUS'),
            },
          },
          {
            title: formatMessage({ id: 'models.box.last-signal-date' }),
            render: ({ lastSignalDate }) =>
              lastSignalDate && <HeartbeatCounter date={lastSignalDate} />,
            sorting: {
              active: field === 'LAST_SIGNAL_DATE',
              direction,
              onToggle: handleToggleSort('LAST_SIGNAL_DATE'),
            },
          },
          {
            title: formatMessage({ id: 'models.box.operations-last-visit-date' }),
            render: ({ operationsLastVisitDate }) =>
              operationsLastVisitDate && formatDate('date', operationsLastVisitDate),
            sorting: {
              active: field === 'OPERATIONS_LAST_VISIT_DATE',
              direction,
              onToggle: handleToggleSort('OPERATIONS_LAST_VISIT_DATE'),
            },
          },
        ]}
        onClickRow={(box) => navigate(box.id)}
        rowClickable={can('box', 'preview')}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        rowActions={[
          {
            title: formatMessage({ id: 'common.actions.edit' }),
            icon: 'edit',
            onClick: ({ id }) => navigate(`/boxes/${id}/edit`),
            disabled: cannot('box', 'update'),
            mainAction: true,
          },
        ]}
      />
    </Paper>
  )
}
