import {
  ContractPackageSetting,
  ContractPartnerSetting,
  ContractSettingConfigFrequency,
  ContractSettingConfigOwnerType,
  ContractSettingConfigTriggerUnit,
  ContractSettingType,
} from '@web-panel/api'
import { formatCurrency } from '@web-panel/shared'
import { useIntl } from 'react-intl'

export const useSettingDescription = () => {
  const { formatMessage } = useIntl()

  return (setting: ContractPartnerSetting, resource?: string): string => {
    if (setting.contractSettingConfig.triggerUnit === ContractSettingConfigTriggerUnit.Outlet) {
      return formatCurrency(setting.price, setting.currency.code)
    }

    if (setting.settingType === ContractSettingType.ExceedingAmountFee) {
      return [
        [setting.amount, formatMessage({ id: 'common.labels.included-per-month' })].join(' '),
        [
          formatCurrency(setting.price, setting.currency.code),
          formatMessage({ id: 'common.labels.per-extra' }, { resource }),
        ].join(' '),
      ].join(', ')
    }
    const labelKey =
      setting.contractSettingConfig.triggerUnit === ContractSettingConfigTriggerUnit.Order
        ? 'common.labels.per-order'
        : 'common.labels.per-item-per-order'

    return [
      formatCurrency(setting.price, setting.currency.code),
      formatMessage({ id: labelKey }),
    ].join(' ')
  }
}

export function groupPackageSettings<
  Setting extends ContractPackageSetting | ContractPartnerSetting
>(settings: Setting[]) {
  const subscriptionSetting = settings.find(
    ({ contractSettingConfig }) =>
      contractSettingConfig.ownerType === ContractSettingConfigOwnerType.Outlet &&
      contractSettingConfig.triggerUnit === ContractSettingConfigTriggerUnit.Outlet &&
      contractSettingConfig.frequency === ContractSettingConfigFrequency.Monthly &&
      contractSettingConfig.features?.length === 0
  )

  const itemSettings = settings.filter(
    ({ contractSettingConfig }) =>
      contractSettingConfig.ownerType === ContractSettingConfigOwnerType.Outlet &&
      contractSettingConfig.triggerUnit === ContractSettingConfigTriggerUnit.Item
  )

  const consumableSettings = settings.filter(
    ({ contractSettingConfig }) =>
      contractSettingConfig.ownerType === ContractSettingConfigOwnerType.Outlet &&
      contractSettingConfig.triggerUnit === ContractSettingConfigTriggerUnit.Consumable
  )

  const deliverySettings = settings.filter(
    ({ contractSettingConfig }) =>
      contractSettingConfig.ownerType === ContractSettingConfigOwnerType.Outlet &&
      contractSettingConfig.triggerUnit === ContractSettingConfigTriggerUnit.Order
  )

  const boxSettings = settings.filter(
    ({ contractSettingConfig }) =>
      contractSettingConfig.ownerType === ContractSettingConfigOwnerType.Outlet &&
      contractSettingConfig.triggerUnit === ContractSettingConfigTriggerUnit.Box
  )

  const dispenserSettings = settings.filter(
    ({ contractSettingConfig }) =>
      contractSettingConfig.ownerType === ContractSettingConfigOwnerType.Outlet &&
      contractSettingConfig.triggerUnit === ContractSettingConfigTriggerUnit.Dispenser
  )

  const features = settings.filter(
    ({ contractSettingConfig }) => contractSettingConfig.features?.length > 0
  )

  return {
    subscriptionSetting,
    itemSettings,
    consumableSettings,
    deliverySettings,
    boxSettings,
    dispenserSettings,
    features,
  }
}

export const useSettingTitle = ({
  contractSettingConfig: { triggerUnit },
}: ContractPartnerSetting) => {
  const { formatMessage } = useIntl()

  let translationKey: string | null = null

  switch (triggerUnit) {
    case ContractSettingConfigTriggerUnit.Outlet:
      translationKey = 'models.contract-package-business.monthly-bundle-fee'
      break
    case ContractSettingConfigTriggerUnit.Item:
      translationKey = 'product-item-types.plural.CUP'
      break
    case ContractSettingConfigTriggerUnit.Consumable:
      translationKey = 'consumable-product-item-types.plural.LID'
      break
    case ContractSettingConfigTriggerUnit.Order:
      translationKey = 'models.contract-package-business.delivery-cost'
      break
    case ContractSettingConfigTriggerUnit.Box:
      translationKey = 'models.box.plural'
      break
    case ContractSettingConfigTriggerUnit.Dispenser:
      translationKey = 'models.dispenser.plural'
      break
    default:
      translationKey = null
  }

  if (!translationKey) return null

  return formatMessage({ id: translationKey })
}
