import { VFC } from 'react'
import {
  AppPage,
  Button,
  CardedContent,
  InfinitScroller,
  TableGrid,
  useFormatDate,
} from '@web-panel/shared'
import { RentalInventoryType } from '@web-panel/api'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import { FormattedMessage, useIntl } from 'react-intl'
import modelIcons from '@local/model-icons'
import { PartnerLink } from '@local/components/PartnerLink'
import { OutletLink } from '@local/components/OutletLink'
import { RentalStatusChip } from '@local/components/RentalStatusChip'
import { useNavigate } from 'react-router-dom'
import { useRentals } from '@web-panel/oc-api'

const PageComponent: VFC = () => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const navigate = useNavigate()
  const { can } = useRoleAuthorization()
  const {
    data: rentals = [],
    loading,
    loadMore,
  } = useRentals({}, { fetchPolicy: 'cache-and-network' })

  const actionButtons = [
    can(
      'boxRental',
      'create',
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        onClick={() => navigate('/rentals/new')}
      >
        <FormattedMessage id="common.actions.create" />
      </Button>
    ),
  ]

  return (
    <AppPage
      icon={modelIcons.rental}
      title={formatMessage({ id: 'routes.rentals' })}
      actionButtons={actionButtons}
    >
      <InfinitScroller onLoadMore={loadMore}>
        <CardedContent>
          <TableGrid
            loading={loading}
            collection={rentals}
            fields={[
              {
                title: formatMessage({ id: 'models.rental.id' }),
                render: ({ externalId }) => externalId,
              },
              {
                title: formatMessage({ id: 'models.rental.partner' }),
                render: ({ partnerId }) => <PartnerLink partnerId={partnerId} />,
              },
              {
                title: formatMessage({ id: 'models.rental.outlet' }),
                render: ({ outletId }) => outletId && <OutletLink outletId={outletId} />,
              },
              {
                title: formatMessage({ id: 'models.rental.inventory-type' }),
                render: ({ inventoryType }) => (
                  <FormattedMessage id={`rental-inventory-types.${inventoryType}`} />
                ),
              },
              {
                title: formatMessage({ id: 'models.rental.amount' }),
                render: ({ amount }) => amount,
              },
              {
                title: formatMessage({ id: 'models.rental.start-date' }),
                render: ({ startDate }) => formatDate('date', startDate),
              },
              {
                title: formatMessage({ id: 'models.rental.end-date' }),
                render: ({ endDate }) => endDate && formatDate('date', endDate),
              },
              {
                title: formatMessage({ id: 'models.rental.status' }),
                render: ({ status }) => <RentalStatusChip status={status} />,
              },
            ]}
            rowClickable={({ inventoryType }) =>
              inventoryType === RentalInventoryType.Box && can('boxRental', 'preview')
            }
            onClickRow={({ id }) => navigate(`/rentals/${id}`)}
          />
        </CardedContent>
      </InfinitScroller>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(PageComponent, ({ can }) => can('rental', 'list'))
