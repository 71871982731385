import { FC } from 'react'
import { Outlet } from '@web-panel/api'
import {
  Button,
  HorizontalTable,
  HorizontalTableRow,
  HorizontalTableSection,
  Typography,
  useModalRef,
} from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useContractPartnerSettings } from '@web-panel/oc-api'
import { TableSettingRow } from '../Components/TableSettingRow'
import { groupPackageSettings } from '../helpers/setting-helpers'
import AddContractSettingModal from '../Modals/AddContractSetting'

type ContractTabProps = {
  outlet: Outlet
}

export const ContractTab: FC<ContractTabProps> = ({ outlet }) => {
  const modalRef = useModalRef()

  const { formatMessage } = useIntl()
  const {
    data: contractPartnerSettings,
    refetch: refetchPartnerSettings,
    loading,
  } = useContractPartnerSettings(
    {
      outletId: outlet.id,
      partnerId: outlet.partnerId,
    },
    { fetchPolicy: 'cache-and-network' }
  )
  const navigate = useNavigate()

  const contractSettings = contractPartnerSettings?.contractSettings

  if (!contractSettings && loading) return null
  if (!contractSettings || contractSettings.length === 0)
    return (
      <div className="w-full flex flex-col items-center">
        <Typography variant="h4">
          <FormattedMessage id="common.no-data" />
        </Typography>
        <Button
          className="mt-12"
          variant="contained"
          color="primary"
          onClick={() => navigate('create-contract-package')}
        >
          <FormattedMessage id="common.actions.create" />
        </Button>
      </div>
    )

  const {
    subscriptionSetting,
    itemSettings,
    consumableSettings,
    deliverySettings,
    boxSettings,
    dispenserSettings,
    features,
  } = groupPackageSettings(contractPartnerSettings.contractSettings)
  const itemSetting = itemSettings[0]
  const consumableSetting = consumableSettings[0]
  const deliverySetting = deliverySettings[0]
  const boxSetting = boxSettings[0]
  const dispenserSetting = dispenserSettings[0]

  const addContractFeaturesButton = (
    <Button key="change" variant="outlined" size="small" onClick={() => modalRef.current?.open()}>
      Add
    </Button>
  )

  return (
    <>
      <div className="flex flex-row justify-end">
        <Button variant="contained" onClick={() => navigate('rebuild-contract-package')}>
          <FormattedMessage id="common.actions.rebuild" />
        </Button>
      </div>
      <HorizontalTable>
        <HorizontalTableRow
          title={formatMessage({
            id: 'models.contract-package-business.category',
          })}
        >
          {contractPartnerSettings.category?.code}
        </HorizontalTableRow>
        <HorizontalTableRow
          title={formatMessage({
            id: 'models.contract-package-business.package',
          })}
        >
          {contractPartnerSettings.contractPackageCode}
        </HorizontalTableRow>
        <HorizontalTableSection
          title={formatMessage({
            id: 'models.contract-package-business.subscription-pricing',
          })}
        />
        <HorizontalTableRow
          title={formatMessage({
            id: 'models.contract-package-business.monthly-bundle-fee',
          })}
        >
          <TableSettingRow setting={subscriptionSetting} onUpdate={refetchPartnerSettings} />
        </HorizontalTableRow>

        <HorizontalTableSection
          title={formatMessage({
            id: 'models.contract-package-business.inventory-pricing',
          })}
        />
        <HorizontalTableRow title={formatMessage({ id: 'product-item-types.plural.CUP' })}>
          <TableSettingRow
            setting={itemSetting}
            resourceName={formatMessage({ id: 'product-item-types.CUP' })}
            onUpdate={refetchPartnerSettings}
          />
        </HorizontalTableRow>
        <HorizontalTableRow
          title={formatMessage({
            id: 'consumable-product-item-types.plural.LID',
          })}
        >
          <TableSettingRow
            setting={consumableSetting}
            resourceName={formatMessage({
              id: 'consumable-product-item-types.LID',
            })}
            onUpdate={refetchPartnerSettings}
          />
        </HorizontalTableRow>

        <HorizontalTableSection
          title={formatMessage({
            id: 'models.contract-package-business.service-pricing',
          })}
        />
        <HorizontalTableRow
          title={formatMessage({
            id: 'models.contract-package-business.delivery-cost',
          })}
        >
          <TableSettingRow
            setting={deliverySetting}
            resourceName={formatMessage({ id: 'models.delivery' })}
            onUpdate={refetchPartnerSettings}
          />
        </HorizontalTableRow>

        <HorizontalTableSection
          title={formatMessage({
            id: 'models.contract-package-business.infrastructure-pricing',
          })}
        />
        <HorizontalTableRow title={formatMessage({ id: 'models.box.plural' })}>
          <TableSettingRow
            setting={boxSetting}
            resourceName={formatMessage({ id: 'models.box' })}
            onUpdate={refetchPartnerSettings}
          />
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.dispenser.plural' })}>
          <TableSettingRow
            setting={dispenserSetting}
            resourceName={formatMessage({ id: 'models.dispenser' })}
            onUpdate={refetchPartnerSettings}
          />
        </HorizontalTableRow>

        <HorizontalTableSection
          title={formatMessage({
            id: 'models.contract-package-business.contract-features',
          })}
          buttons={[addContractFeaturesButton]}
        />

        {features.length > 0 &&
          features.map((feature) => (
            <HorizontalTableRow
              title={formatMessage({
                id: `models.contract-package-business.contract.features.${feature.contractSettingConfig.features[0].id
                  .replaceAll('_', '-')
                  .toLowerCase()}`,
              })}
            >
              <TableSettingRow
                setting={feature}
                title={formatMessage({
                  id: `models.contract-package-business.contract.features.${feature.contractSettingConfig.features[0].id
                    .replaceAll('_', '-')
                    .toLowerCase()}`,
                })}
                onUpdate={refetchPartnerSettings}
              />
            </HorizontalTableRow>
          ))}

        <AddContractSettingModal
          ref={modalRef}
          outlet={outlet}
          features={features}
          packageCode={contractPartnerSettings?.contractPackageCode as string}
          businessCategoryId={contractPartnerSettings?.category?.id || ''}
          onAdd={refetchPartnerSettings}
        />
      </HorizontalTable>
    </>
  )
}
