import { FC, useRef } from 'react'
import { Order, OrderStatus } from '@web-panel/api'
import {
  DropdownButton,
  ConfirmationDialog,
  ConfirmationDialogRef,
  useNotifications,
} from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { useUpdateOrderStatus, useOrderBatches, usePartner } from '@web-panel/oc-api'

type ChangeOrderStatusButtonProps = {
  openSignatureDialog: () => void
  order: Order
}

export const ChangeOrderStatusButton: FC<ChangeOrderStatusButtonProps> = ({
  order,
  openSignatureDialog,
}) => {
  const deliverDialogRef = useRef<ConfirmationDialogRef>(null)
  const rejectDialogRef = useRef<ConfirmationDialogRef>(null)
  const { pushNotification } = useNotifications()
  const { request: updateOrderStatus } = useUpdateOrderStatus()
  const { data: batches = [], loading: loadingBatches } = useOrderBatches({ id: order.id })
  const { data: partner, loading: loadingPartner } = usePartner({ id: order.partnerId })
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  if (!partner || loadingPartner || loadingBatches) return null

  const handleMenuClick = (status: OrderStatus) => {
    return async () => {
      try {
        await updateOrderStatus({ id: order.id, input: { status } })
        pushNotification(
          'success',
          formatMessage({ id: 'common.notifications.successfully-updated' })
        )
      } catch (err) {
        pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
      }
    }
  }

  const menu = [
    {
      key: OrderStatus.Requested,
      title: formatMessage({ id: `order-statuses.${OrderStatus.Requested}` }),
      onClick: handleMenuClick(OrderStatus.Requested),
      disabled: true,
    },
    {
      key: OrderStatus.Processing,
      title: formatMessage({ id: `order-statuses.${OrderStatus.Processing}` }),
      onClick: handleMenuClick(OrderStatus.Processing),
      disabled: order.status !== OrderStatus.Requested,
    },
    {
      key: OrderStatus.Delivered,
      title: formatMessage({ id: `order-statuses.${OrderStatus.Delivered}` }),
      onClick: () => deliverDialogRef.current?.open(),
      disabled: order.status !== OrderStatus.Processing,
    },
    {
      key: OrderStatus.Rejected,
      title: formatMessage({ id: `order-statuses.${OrderStatus.Rejected}` }),
      onClick: () => rejectDialogRef.current?.open(),
      disabled: order.status === OrderStatus.Delivered,
    },
  ]

  const actualMenu = menu.filter(({ key }) => key !== order.status)

  const getConfirmationDialogProps = () => {
    if (batches.length === 0) {
      return {
        title: formatMessage({ id: 'confirmation-dialogs.set-delivered-is-not-possible.title' }),
        message: formatMessage({
          id: 'confirmation-dialogs.set-delivered-is-not-possible.message',
        }),
        acceptTitle: formatMessage({ id: 'common.actions.attach' }),
        cancelTitle: formatMessage({ id: 'common.actions.cancel' }),
        onSubmit: () => navigate(`/orders/${order.id}/#batches`),
      }
    }

    if (partner.deliverySignatureRequired && !order.deliverySignature) {
      return {
        title: formatMessage({ id: 'confirmation-dialogs.set-delivered-requires-signature.title' }),
        message: formatMessage({
          id: 'confirmation-dialogs.set-delivered-requires-signature.message',
        }),
        acceptTitle: formatMessage({ id: 'common.actions.sign' }),
        cancelTitle: formatMessage({ id: 'common.actions.cancel' }),
        onSubmit: openSignatureDialog,
      }
    }

    return {
      title: formatMessage({ id: 'confirmation-dialogs.set-delivered-order-status.title' }),
      message: formatMessage({ id: 'confirmation-dialogs.set-delivered-order-status.message' }),
      acceptTitle: formatMessage({ id: 'common.yes' }),
      cancelTitle: formatMessage({ id: 'common.no' }),
      onSubmit: handleMenuClick(OrderStatus.Delivered),
    }
  }

  return (
    <>
      <ConfirmationDialog ref={deliverDialogRef} {...getConfirmationDialogProps()} />
      <ConfirmationDialog
        ref={rejectDialogRef}
        title={formatMessage({ id: 'confirmation-dialogs.set-rejected-order-status.title' })}
        acceptTitle={formatMessage({ id: 'common.yes' })}
        cancelTitle={formatMessage({ id: 'common.no' })}
        onSubmit={handleMenuClick(OrderStatus.Rejected)}
      />
      <DropdownButton
        title={formatMessage({ id: `order-statuses.${order.status}` })}
        menu={actualMenu}
        color="secondary"
        variant="contained"
      />
    </>
  )
}
