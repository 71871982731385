import { FC } from 'react'
import { TextField } from '@mui/material'

function getHoursMinutes(time: string): string {
  const components = time.split(':')
  return [components[0], components[1]].join(':')
}

type TimeInputProps = {
  type: 'openTime' | 'closeTime'
  value: string
  onChange: (value: string) => void
}

const TimeInput: FC<TimeInputProps> = ({ type, value, onChange }) => {
  return (
    <TextField
      type="time"
      value={getHoursMinutes(value)}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

export default TimeInput
