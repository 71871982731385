import { FC, useCallback, useState } from 'react'
import {
  AppPage,
  Button,
  CardedContent,
  InfinitScroller,
  TabSections,
  useModalRef,
} from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'

import seriesIcon from '@local/assets/icons/series.svg'
import cycleIcon from '@local/assets/icons/cycle.svg'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { useGroupLabels, useSequenceNodes } from '@web-panel/oc-api'
import { useParams } from 'react-router-dom'
import { GroupLabel, MapSequenceType, SequenceTypes } from '@web-panel/api'
import IndexTable from './Tables/IndexTable'
import GroupLabelsIndexTable from '../GroupLabels/Tables/IndexTable'
import ActivateGroupLabelModalConfirmation from '../GroupLabels/Modals/ActivateGroupLabelModalConfirmation'
import { useSorting, withSorting } from '../GroupLabels/sorting'
import CreateGroupLabelModal from '../GroupLabels/Modals/CreateGroupLabelModal'
import Filters, { useFilters, withFilters } from '../GroupLabels/Filters'

const IndexPage: FC = () => {
  const { formatMessage } = useIntl()
  const params = useParams()
  const { filters } = useFilters()
  const activationConfirmationModalRef = useModalRef()
  const showCreateGroupLabelsModalRef = useModalRef()
  const [groupLabel, setGroupLabel] = useState<GroupLabel>()
  const { field: sortBy, direction: sortOrder } = useSorting()

  const {
    data: groupLabels = [],
    loading: groupLabelsLoading,
    hasMore: groupLabelsHasMore,
    loadMore: groupLabelsLoadMore,
    refetch,
  } = useGroupLabels(
    { ...filters, shipmentSequenceId: params.id, sortBy, sortOrder },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )

  const {
    data: sequenceNodes = [],
    loading,
    hasMore,
    loadMore,
  } = useSequenceNodes(
    { shipmentSequenceId: params.id! },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )

  const onLoadMore = () => {
    if (!hasMore) return
    loadMore()
  }

  const onGroupLabelsLoadMore = () => {
    if (!groupLabelsHasMore) return
    groupLabelsLoadMore()
  }

  const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || ''

  const handleOnActivate = (_groupLabel: GroupLabel) => {
    setGroupLabel(_groupLabel)
    activationConfirmationModalRef.current?.open()
  }

  const actionButtons = [
    <Button
      fullWidth
      variant="contained"
      color="secondary"
      onClick={() => showCreateGroupLabelsModalRef.current?.open()}
    >
      <FormattedMessage
        id="common.actions.create-with-name"
        values={{ name: formatMessage({ id: 'routes.group-labels' }) }}
      />
    </Button>,
  ]

  const FilterComponent: FC = useCallback(() => {
    return <Filters />
  }, [])

  return (
    <AppPage
      loading={loading}
      image={
        <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40}>
          <image href={_getTitleIcon()} height={40} width={40} />
        </svg>
      }
      actionButtons={actionButtons}
      backButton
      title={formatMessage(
        { id: 'routes.sequence-nodes' },
        {
          type: capitalize(MapSequenceType(params.type as SequenceTypes)),
          name: params.name,
        }
      )}
      Sidebar={FilterComponent}
    >
      <CardedContent>
        <TabSections
          sections={[
            {
              key: 'nodes',
              title: formatMessage({
                id: 'pages.sequences.tabs.nodes',
              }),
              Content: () => (
                <InfinitScroller onLoadMore={onLoadMore}>
                  <IndexTable data={sequenceNodes} loading={loading} />
                </InfinitScroller>
              ),
            },
            {
              key: 'group-lables',
              title: formatMessage({
                id: 'pages.sequences.tabs.group-labels',
              }),
              Content: () => (
                <InfinitScroller onLoadMore={onGroupLabelsLoadMore}>
                  <GroupLabelsIndexTable
                    data={groupLabels}
                    loading={groupLabelsLoading}
                    onActivate={(label) => handleOnActivate(label)}
                  />
                </InfinitScroller>
              ),
            },
          ]}
        />
      </CardedContent>
      <ActivateGroupLabelModalConfirmation
        ref={activationConfirmationModalRef}
        groupLabel={groupLabel!}
        onActivated={refetch}
      />
      <CreateGroupLabelModal
        ref={showCreateGroupLabelsModalRef}
        shipmentSequenceId={params.id!}
        onCreated={refetch}
      />
    </AppPage>
  )

  function _getTitleIcon(): string | undefined {
    return params.type === 'CYCLE' ? cycleIcon : seriesIcon
  }
}

export default pageWithRoleAuthorization(withSorting(withFilters(IndexPage)), ({ can }) =>
  can('sequenceNodes', 'list')
)
