import { FC } from 'react'
import { Box } from '@web-panel/api'
import {
  Button,
  HeartbeatCounter,
  HorizontalTable,
  HorizontalTableRow,
  HorizontalTableSection,
  WorkingHoursPreview,
  Counter,
  useFormatDate,
} from '@web-panel/shared'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { OutletLink } from '@local/components/OutletLink'
import { useNavigate } from 'react-router-dom'
import { RoleAuthorized } from '@local/components/RoleAuthorized'
import AddLocationButton from '../Components/AddLocationButton'
import OpenVaultButton from '../Components/OpenVaultButton'
import { OptimoRouteCriticalButton } from '../Components/OptimoRouteCriticalButton'
import { EmptyBoxButton } from '../Components/EmptyBoxButton'

type PreviewTableProps = {
  data: Box
  onClickAssignOutlet: () => void
}
const PreviewTable: FC<PreviewTableProps> = ({ data, onClickAssignOutlet }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const navigate = useNavigate()

  const { vaultUrl } = data

  return (
    <>
      <HorizontalTable className="table-fixed">
        <HorizontalTableRow title={formatMessage({ id: 'models.box.vault' })}>
          {vaultUrl && <OpenVaultButton target={`vault-${data.id}`} vaultUrl={vaultUrl} />}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.optimo-route' })}>
          <OptimoRouteCriticalButton boxId={data.id} />
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.imei' })}>
          {data.imei}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.core-id' })}>
          {data.coreId}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.mac-address' })}>
          {data.macAddress}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.producer-id' })}>
          {data.producerId}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.model' })}>
          {data.model}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.head-id' })}>
          {data.headId}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.body-id' })}>
          {data.bodyId}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.type' })}>
          {data.type && <FormattedMessage id={`box-types.${data.type}`} />}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.status' })}>
          {data.status && <FormattedMessage id={`box-statuses.${data.status}`} />}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.reported' })}>
          <FormattedMessage id={data.reported ? 'common.yes' : 'common.no'} />
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.battery' })}>
          {data.battery && <FormattedNumber style="percent" value={data.battery / 100} />}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.capacity' })}>
          {data.capacity}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.cups' })}>
          <div className="flex flex-row items-center gap-16">
            <div>{data.cups}</div>
            <EmptyBoxButton box={data} />
          </div>
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.total-cups' })}>
          {data.totalCups}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.cups-count-threshold' })}>
          {data.cupsCountThreshold}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.cups-count-full' })}>
          {data.cupsCountFull}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.fill-level' })}>
          {data.fillLevel}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.fill-level-threshold' })}>
          {data.fillLevelThreshold}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.fill-level-full' })}>
          {data.fillLevelFull}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.temperature' })}>
          {data.temperature && (
            <FormattedNumber style="unit" unit="celsius" value={data.temperature} />
          )}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.last-signal-date' })}>
          {data.lastSignalDate && <HeartbeatCounter date={data.lastSignalDate} />}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.operations-interval' })}>
          <FormattedMessage id="common.labels.hours" values={{ value: data.operationsInterval }} />
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.operations-last-visit-date' })}>
          {data.operationsLastVisitDate && formatDate('datetime', data.operationsLastVisitDate)}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.power-supply-type' })}>
          {data.powerSupplyType && (
            <FormattedMessage id={`box-power-supply-types.${data.powerSupplyType}`} />
          )}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.fw-version' })}>
          {data.fwVersion}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.hw-version' })}>
          {data.hwVersion}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.box-connection-type' })}>
          {data.boxConnectionType && (
            <FormattedMessage id={`box-connection-types.${data.boxConnectionType}`} />
          )}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.priority' })}>
          {data.priority}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.acceptance-tolerance-tof' })}>
          {data.acceptanceToleranceTof}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.light-control-enabled' })}>
          <FormattedMessage id={data.lightControlEnabled ? 'common.yes' : 'common.no'} />
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.operation-url' })}>
          {data.operationUrl}
        </HorizontalTableRow>

        <HorizontalTableSection title={formatMessage({ id: 'models.box.heartbeat' })} />
        <HorizontalTableRow title={formatMessage({ id: 'models.box.heartbeat' })}>
          {data.heartbeatDate && <HeartbeatCounter date={data.heartbeatDate} />}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.heartbeat-date' })}>
          {data.heartbeatDate && formatDate('datetime', data.heartbeatDate)}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.uptime' })}>
          {data.currentUptime && <Counter value={data.currentUptime} />}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.heartbeat-sequence' })}>
          {data.heartbeatSequence}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.box.heartbeat-enabled' })}>
          <FormattedMessage id={data.heartbeatEnabled ? 'common.yes' : 'common.no'} />
        </HorizontalTableRow>

        <RoleAuthorized resource="outlet" action="preview">
          <HorizontalTableSection title={formatMessage({ id: 'models.box.outlet' })} />
          <HorizontalTableRow title={formatMessage({ id: 'models.box.outlet' })}>
            <div className="flex flex-row items-center">
              {data.outletId && (
                <div className="mr-16">
                  <OutletLink outletId={data.outletId} />
                </div>
              )}
              <RoleAuthorized resource="box" action="assignToOutlet">
                <Button color="primary" variant="outlined" onClick={onClickAssignOutlet}>
                  <FormattedMessage
                    id={data.outletId ? 'common.actions.reassign' : 'common.actions.assign'}
                  />
                </Button>
              </RoleAuthorized>
            </div>
          </HorizontalTableRow>
          {data.outletId && (
            <HorizontalTableRow title={formatMessage({ id: 'models.box.searchable-in-outlet' })}>
              <FormattedMessage id={data.searchableInOutlet ? 'common.yes' : 'common.no'} />
            </HorizontalTableRow>
          )}
        </RoleAuthorized>

        <RoleAuthorized resource="location" action="preview">
          <HorizontalTableSection title={formatMessage({ id: 'models.box.location' })} />
          {data.location && data.location.editable ? (
            <>
              <HorizontalTableRow title={formatMessage({ id: 'models.location.country' })}>
                {data.location.country.name}
              </HorizontalTableRow>
              <HorizontalTableRow title={formatMessage({ id: 'models.location.city' })}>
                {data.location.city.name}
              </HorizontalTableRow>
              <HorizontalTableRow title={formatMessage({ id: 'models.location.name' })}>
                {data.location.name}
              </HorizontalTableRow>
              <HorizontalTableRow title={formatMessage({ id: 'models.location.street' })}>
                {data.location.street}
              </HorizontalTableRow>
              <HorizontalTableRow title={formatMessage({ id: 'models.location.house-number' })}>
                {data.location.houseNumber}
              </HorizontalTableRow>
              <HorizontalTableRow title={formatMessage({ id: 'models.location.postal-code' })}>
                {data.location.postalCode}
              </HorizontalTableRow>
            </>
          ) : (
            <RoleAuthorized resource="box" action="assignToLocation">
              <HorizontalTableRow title={formatMessage({ id: 'models.box.location' })}>
                <AddLocationButton
                  title={formatMessage({ id: 'common.actions.add' })}
                  outletId={data.outletId}
                  onAddLocation={(location) =>
                    navigate('edit', { state: { initialValues: { location } } })
                  }
                />
              </HorizontalTableRow>
            </RoleAuthorized>
          )}
        </RoleAuthorized>
      </HorizontalTable>
      {data.location && data.location.editable && (
        <div className="mt-24">
          <WorkingHoursPreview
            data={data.location.workingHours}
            dayLabels={{
              monday: formatMessage({ id: 'time.day-of-week.short.monday' }),
              tuesday: formatMessage({ id: 'time.day-of-week.short.tuesday' }),
              wednesday: formatMessage({ id: 'time.day-of-week.short.wednesday' }),
              thursday: formatMessage({ id: 'time.day-of-week.short.thursday' }),
              friday: formatMessage({ id: 'time.day-of-week.short.friday' }),
              saturday: formatMessage({ id: 'time.day-of-week.short.saturday' }),
              sunday: formatMessage({ id: 'time.day-of-week.short.sunday' }),
            }}
            timeFrameLabels={{
              opening: formatMessage({ id: 'models.location.opening-time' }),
              closing: formatMessage({ id: 'models.location.closing-time' }),
            }}
          />
        </div>
      )}
    </>
  )
}

export default PreviewTable
