import { FC, useState } from 'react'
import { FormSection, TextField, Typography, Button } from '@web-panel/shared'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'

export type ContactInformationSectionFields = {
  firstName: string
  lastName: string
  phone: string
}

export const contactInformationValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  phone: Yup.string().required(),
})

export const defaultContactInformationValues: ContactInformationSectionFields = {
  firstName: '',
  lastName: '',
  phone: '',
}

export const ContactInformationSection: FC = () => {
  const { formatMessage } = useIntl()
  const { values, errors, handleChange } = useFormikContext<ContactInformationSectionFields>()

  const someFieldsEmpty = !values.firstName || !values.lastName || !values.phone
  const fieldsHaveErrors = !!errors.firstName || !!errors.lastName || !!errors.phone
  const [isEditing, setIsEditing] = useState(someFieldsEmpty)

  const changeButton = (
    <Button
      key="change"
      variant="outlined"
      size="small"
      disabled={someFieldsEmpty || fieldsHaveErrors}
      onClick={() => setIsEditing(!isEditing)}
    >
      {formatMessage({ id: !isEditing ? 'common.actions.change' : 'common.actions.update' })}
    </Button>
  )

  return (
    <FormSection
      title={formatMessage({ id: 'models.order.contact-person' })}
      buttons={[changeButton]}
    >
      {!isEditing ? (
        <>
          <Typography>
            {values.firstName} {values.lastName}
          </Typography>
          <Typography>{values.phone}</Typography>
        </>
      ) : (
        <>
          <TextField
            fullWidth
            error={!!errors.firstName}
            label={formatMessage({ id: 'models.order.contact-person.first-name' })}
            value={values.firstName}
            onChange={handleChange('firstName')}
          />
          <TextField
            fullWidth
            className="mt-16"
            error={!!errors.lastName}
            label={formatMessage({ id: 'models.order.contact-person.last-name' })}
            value={values.lastName}
            onChange={handleChange('lastName')}
          />
          <TextField
            fullWidth
            className="mt-16"
            error={!!errors.phone}
            label={formatMessage({ id: 'models.order.contact-person.phone' })}
            value={values.phone}
            onChange={handleChange('phone')}
          />
        </>
      )}
    </FormSection>
  )
}
