import { FC } from 'react'
import { FormControlLabel, FormControlLabelProps, Switch, SwitchProps } from '@mui/material'

type LabeledSwitchProps = Pick<SwitchProps, 'disabled' | 'checked' | 'onChange'> &
  Pick<FormControlLabelProps, 'label' | 'labelPlacement' | 'className'>

export const LabeledSwitch: FC<LabeledSwitchProps> = ({
  label,
  labelPlacement,
  className,
  ...props
}) => {
  return (
    <FormControlLabel
      className={className}
      control={<Switch {...props} />}
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}
