import { FC } from 'react'
import {
  Button,
  TableGrid,
  InfinitScroller,
  useNotifications,
  useModalRef,
} from '@web-panel/shared'
import { PartnerEmployee } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import OutletStatusChip from '@local/components/OutletStatusChip'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { usePartnerEmployeeOutlets, useUnassignPartnerEmployee } from '@web-panel/oc-api'
import AssignPartnerEmployee from '../Modals/AssignPartnerEmployee'

type OutletsTabProps = {
  employee: PartnerEmployee
  partnerId: string
}

const OutletsTab: FC<OutletsTabProps> = ({ employee, partnerId }) => {
  const modalRef = useModalRef()
  const { formatMessage } = useIntl()
  const { pushNotification } = useNotifications()
  const navigate = useNavigate()
  const { request: detachEmployee, loading: loadingDetach } = useUnassignPartnerEmployee()
  const { can } = useRoleAuthorization()
  const {
    data: outlets = [],
    loading,
    loadMore,
  } = usePartnerEmployeeOutlets(
    { partnerEmployeeId: employee.id },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )

  const onDetach = async (outletId: string) => {
    try {
      await detachEmployee({ partnerId, outletId, employeeId: employee.id })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (e) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <>
      <InfinitScroller onLoadMore={loadMore}>
        <TableGrid
          loading={loading}
          collection={outlets}
          fields={[
            {
              title: formatMessage({ id: 'models.outlet.id' }),
              render: ({ outletNumber }) => outletNumber,
            },
            {
              title: formatMessage({ id: 'models.outlet.name' }),
              render: ({ location }) => location.name,
            },
            {
              title: formatMessage({ id: 'models.outlet.status' }),
              render: ({ status }) => <OutletStatusChip status={status} />,
            },
            {
              title: formatMessage({ id: 'models.outlet.type' }),
              render: ({ type }) => <FormattedMessage id={`outlet-types.${type}`} />,
            },
            {
              title: '',
              isRenderable: can('partnerEmployee', 'removeFromOutlet'),
              render: (outlet) => (
                <Button
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation()
                    onDetach(outlet.id)
                  }}
                  loading={loadingDetach}
                >
                  <FormattedMessage id="common.actions.detach" />
                </Button>
              ),
            },
          ]}
          onClickRow={(outlet) => navigate(`/outlets/${outlet.id}`)}
          emptyLabel={formatMessage({ id: 'common.no-data' })}
          addItemButton={
            can('partnerEmployee', 'assignToOutlet') && (
              <Button variant="contained" color="primary" onClick={() => modalRef.current?.open()}>
                <FormattedMessage id="common.actions.assign" />
              </Button>
            )
          }
        />
      </InfinitScroller>

      <AssignPartnerEmployee ref={modalRef} partnerId={partnerId} partnerEmployeeId={employee.id} />
    </>
  )
}

export default OutletsTab
