import { FC, useState, useMemo } from 'react'
import { Widget } from '@web-panel/shared'
import { AnalyticAggregationType, Outlet, AnalyticItemSeriesType } from '@web-panel/api'
import ReactApexChart from 'react-apexcharts'
import { useIntl } from 'react-intl'
import { subDays } from 'date-fns'
import { chartDefaults } from '../chart-defaults'
import { useSeries } from './use-series'
import { DaysSelector, tabToDaysMap } from '../DaysSelector'
import { CupStateSelector } from '../CupStateSelector'
import { AggregationTypeSelector } from '../AggregationTypeSelector'

type CO2WidgetProps = {
  partnerId: string
  outlets: Outlet[]
}

const CO2Widget: FC<CO2WidgetProps> = ({ partnerId, outlets }) => {
  const [daysToPresent, setDaysToPreset] = useState<string>('3')
  const [cupsState, setCupsState] = useState<AnalyticItemSeriesType>(
    AnalyticItemSeriesType.Returned
  )
  const [aggregationType, setAggreationType] = useState(AnalyticAggregationType.Cumulative)
  const startDate = useMemo(() => {
    const days = tabToDaysMap[daysToPresent]
    if (!days) return null
    return subDays(new Date(), days - 1)
  }, [daysToPresent])

  const { formatMessage } = useIntl()
  const series = useSeries(partnerId, outlets, startDate, cupsState, aggregationType)

  const widgetButtons = [
    <AggregationTypeSelector selected={aggregationType} onSelect={setAggreationType} />,
    <CupStateSelector selected={cupsState} onSelect={setCupsState} />,
    <DaysSelector selected={daysToPresent} onSelect={setDaysToPreset} />,
  ]

  return (
    <Widget
      contentFullWidth
      title={formatMessage({ id: 'pages.partner-preview.tabs.dashboard.cup-series-widget.title' })}
      buttons={widgetButtons}
    >
      <ReactApexChart
        stacked="true"
        type="area"
        options={{
          ...chartDefaults,
          xaxis: {
            ...chartDefaults?.xaxis,
          },
        }}
        series={series}
        height={200}
      />
    </Widget>
  )
}

export default CO2Widget
