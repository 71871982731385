import { FC } from 'react'
import { Hidden } from '@mui/material'
import { WorkingHoursData, InputProps } from './Inputs/types'
import HorizontalInput from './Inputs/Horizontal'
import VerticalInput from './Inputs/Vertical'

function getDefaultWorkingHours(dayOfWeek: number): WorkingHoursData {
  return {
    dayOfWeek,
    openTime: '09:00:00',
    closeTime: '21:00:00',
  }
}

type WorkingHoursInputProps = {
  onChange: (value: WorkingHoursData[]) => void
} & Omit<InputProps, 'onAdd' | 'onDelete' | 'onChange'>

export const WorkingHoursInput: FC<WorkingHoursInputProps> = ({
  value,
  onChange,
  dayLabels,
  timeFrameLabels,
  actionLabels,
}) => {
  const handleTimeChange = (type: 'openTime' | 'closeTime', dayOfWeek: number, timeVal: string) => {
    const dayOfWeekId = value.findIndex((day) => day.dayOfWeek === dayOfWeek)

    const updatedValues = [...value]
    updatedValues[dayOfWeekId] = {
      ...updatedValues[dayOfWeekId],
      [type]: `${timeVal}:00`,
    }
    onChange(updatedValues)
  }

  const handleDeleteWorkingHours = (dayOfWeek: number) => {
    const updatedValues = value.filter((item) => item.dayOfWeek !== dayOfWeek)
    onChange(updatedValues)
  }

  const handleAddWorkingHours = (dayOfWeek: number) => {
    onChange([
      ...value.slice(0, dayOfWeek),
      getDefaultWorkingHours(dayOfWeek),
      ...value.slice(dayOfWeek, value.length),
    ])
  }

  return (
    <>
      <Hidden lgDown>
        <HorizontalInput
          value={value}
          dayLabels={dayLabels}
          timeFrameLabels={timeFrameLabels}
          actionLabels={actionLabels}
          onChange={handleTimeChange}
          onAdd={handleAddWorkingHours}
          onDelete={handleDeleteWorkingHours}
        />
      </Hidden>
      <Hidden lgUp>
        <VerticalInput
          value={value}
          dayLabels={dayLabels}
          timeFrameLabels={timeFrameLabels}
          actionLabels={actionLabels}
          onChange={handleTimeChange}
          onAdd={handleAddWorkingHours}
          onDelete={handleDeleteWorkingHours}
        />
      </Hidden>
    </>
  )
}
