import {
  ContractPartnerSettingConfigInput,
  ContractPackageSetting,
  ContractSettingConfigFrequency,
  ContractPartnerSetting,
} from '@web-panel/api'
import * as Yup from 'yup'
import _compact from 'lodash/compact'

export type ContractSettingsSectionFields = {
  subscriptionSetting: ContractPartnerSettingConfigInput | null
  invoiceFrequency: ContractSettingConfigFrequency | null
  itemsSetting: ContractPartnerSettingConfigInput | null
  consumablesSetting: ContractPartnerSettingConfigInput | null
  deliverySetting: ContractPartnerSettingConfigInput | null
  boxSetting: ContractPartnerSettingConfigInput | null
  dispenserSetting: ContractPartnerSettingConfigInput | null
  featureSetting: ContractPartnerSettingConfigInput[] | null
}

export const contractSettingsValidation = Yup.object({
  subscriptionSetting: Yup.object({
    price: Yup.number().moreThan(0).required(),
  }).nullable(),
  itemsSetting: Yup.object({
    amount: Yup.number().min(0).required(),
    price: Yup.number().min(0).required(),
  }).required(),
  consumablesSetting: Yup.object({
    amount: Yup.number().min(0).required(),
    price: Yup.number().min(0).required(),
  }).required(),
  deliverySetting: Yup.object({
    amount: Yup.number().min(0).required(),
    price: Yup.number().min(0).required(),
  }).nullable(),
  boxSetting: Yup.object({
    amount: Yup.number().min(0).required(),
    price: Yup.number().min(0).required(),
  }).nullable(),
  dispenserSetting: Yup.object({
    amount: Yup.number().min(0).required(),
    price: Yup.number().min(0).required(),
  }).nullable(),
  featureSetting: Yup.object({
    price: Yup.number().moreThan(0).required(),
  }).nullable(),
})

export function packageSettingToInput(
  setting: ContractPackageSetting | ContractPartnerSetting
): ContractPartnerSettingConfigInput {
  return {
    price: setting.price,
    currencyCode: setting.currency.code,
    countryCode: setting.countryCode.code,
    amount: setting.amount,
    name: setting.name,
    description: setting.description,
    settingType: setting.settingType,
    contractSettingConfigId: setting.contractSettingConfig.id,
  }
}

export function formValuesToPartnerSettingsInput(
  values: ContractSettingsSectionFields
): ContractPartnerSettingConfigInput[] {
  return _compact(
    [
      values.subscriptionSetting,
      values.itemsSetting,
      values.consumablesSetting,
      values.deliverySetting,
      values.boxSetting,
      values.dispenserSetting,
    ].concat(values.featureSetting)
  )
}
