import { FC, useMemo } from 'react'
import { AppPage, CardedContent, Button, useNotifications } from '@web-panel/shared'
import { ConsumableProductItemType } from '@web-panel/api'
import { useIntl, FormattedMessage } from 'react-intl'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import _difference from 'lodash/difference'
import { useCreateProductItemConsumable, useProductItemConsumables } from '@web-panel/oc-api'
import {
  CreateConsumableForm,
  FormFields,
  validationSchema,
  defaultValues,
} from './Forms/CreateConsumableForm'

const SettingsCreateConsumablePageComponent: FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()
  const { request: createConsumable, loading: loadingConsumable } = useCreateProductItemConsumable()
  const { data: consumables = [], loading: loadingConsumables } = useProductItemConsumables({
    fetchPolicy: 'cache-first',
  })

  const availableConsumables = useMemo(() => {
    const usedTypes = consumables.map(({ consumableType }) => consumableType)
    return _difference(Object.values(ConsumableProductItemType), usedTypes)
  }, [consumables])

  if (loadingConsumables || loadingConsumable) return null

  const onSubmit = async (values: FormFields) => {
    try {
      await createConsumable({ input: values })
      navigate(-1)
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-created' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <Formik<FormFields>
      initialValues={{ ...defaultValues, consumableType: availableConsumables[0] }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formProps) => {
        const headerActionButtons = [
          <Button
            fullWidth
            loading={loadingConsumable}
            key="save"
            variant="contained"
            color="secondary"
            disabled={!formProps.isValid}
            onClick={() => formProps.handleSubmit()}
          >
            <FormattedMessage id="common.actions.create" />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            title={formatMessage({ id: 'routes.settings.consumables.create' })}
            actionButtons={headerActionButtons}
          >
            <CardedContent>
              <CreateConsumableForm {...formProps} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export const SettingsCreateConsumablePage = pageWithRoleAuthorization(
  SettingsCreateConsumablePageComponent,
  ({ can }) => can('consumable', 'create')
)
