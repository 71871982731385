import { FC } from 'react'
import { AppPage, Button, CardedContent, useNotifications } from '@web-panel/shared'
import { useCountry } from '@web-panel/api'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { Formik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import modelIcons from '@local/model-icons'
import { Error404 } from '@local/pages/404'
import {
  useCountryProductItemModelConfiguration,
  useUpdateCountryProductItemModelConfiguration,
} from '@web-panel/oc-api'
import { EditRefundableForm, FormFields, validationSchema } from '../Forms/EditRefundableForm'

export const SettingsCountryRefundableEditPageComponent: FC = () => {
  const { formatMessage } = useIntl()
  const { code, id } = useParams()
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()
  const { data: country, loading: loadingCountry } = useCountry({ code: code ?? '' })
  const { data: configuration, loading: loadingConfiguration } =
    useCountryProductItemModelConfiguration({ countryCode: code ?? '', modelId: id ?? '' })
  const { request: updateConfiguration, loading: loadingUpdateConfiguration } =
    useUpdateCountryProductItemModelConfiguration()

  if (loadingConfiguration || loadingCountry) return null
  if (!configuration || !country) return <Error404 />

  const initialValues: FormFields = {
    status: configuration.status,
    cost: configuration.cost ?? 0,
    serviceFee: configuration.serviceFee ?? 0,
    refundFeePercent: configuration.refundFeePercent ?? 0,
    payoutFeePercent: configuration.payoutFeePercent ?? 0,
  }

  const onSubmit = async (values: FormFields) => {
    try {
      await updateConfiguration({
        countryCode: country.code,
        input: {
          itemModelId: configuration.itemModel.id,
          ...values,
        },
      })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
      navigate(-1)
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <Formik<FormFields>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formProps) => {
        const actionButtons = [
          <Button
            variant="contained"
            color="secondary"
            disabled={!formProps.dirty || !formProps.isValid}
            loading={loadingUpdateConfiguration}
            onClick={() => formProps.handleSubmit()}
          >
            <FormattedMessage id="common.actions.update" />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            icon={modelIcons.settings}
            title={formatMessage(
              { id: 'routes.settings.country.refundable.edit' },
              { name: configuration.itemModel.id, country: country.name }
            )}
            actionButtons={actionButtons}
          >
            <CardedContent>
              <EditRefundableForm {...formProps} currency={configuration.currency ?? {}} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export const SettingsCountryRefundableEditPage = pageWithRoleAuthorization(
  SettingsCountryRefundableEditPageComponent,
  ({ can }) => can('geoCountry', 'updateRefundables')
)
