import { FC } from 'react'
import { Batch } from '@web-panel/api'
import { FormattedNumber, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Paper, TableGrid, useFormatDate } from '@web-panel/shared'
import { OrderLink } from '@local/components/OrderLink'
import { OutletLink } from '@local/components/OutletLink'
import { BatchStatusChip } from '@local/components/BatchStatusChip'
import { UserLink } from '@local/components/UserLink'

type IndexTableProps = {
  data: Batch[]
}

const IndexTable: FC<IndexTableProps> = ({ data }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()

  return (
    <Paper className="overflow-hidden">
      <TableGrid
        collection={data}
        fields={[
          {
            title: formatMessage({ id: 'models.batch.id' }),
            render: ({ batchNumber }) => batchNumber,
          },
          {
            title: formatMessage({ id: 'models.batch.delivered-outlet' }),
            render: ({ deliveredOutletId }) =>
              deliveredOutletId && <OutletLink outletId={deliveredOutletId} />,
          },
          {
            title: formatMessage({ id: 'models.batch.delivery-date' }),
            render: ({ deliveryDate }) => deliveryDate && formatDate('date', deliveryDate),
          },
          {
            title: formatMessage({ id: 'models.batch.order-id' }),
            render: ({ orderId }) => orderId && <OrderLink orderId={orderId} />,
          },
          {
            title: formatMessage({ id: 'models.batch.status' }),
            render: ({ status }) => <BatchStatusChip status={status} />,
          },
          {
            title: formatMessage({ id: 'models.batch.items-number' }),
            render: ({ itemsNumber }) => <FormattedNumber value={itemsNumber} />,
          },
          {
            title: formatMessage({ id: 'models.batch.created-by' }),
            render: ({ createdBy }) => createdBy && <UserLink userId={createdBy} />,
          },
          {
            title: formatMessage({ id: 'models.batch.create-date' }),
            render: ({ createDate }) => formatDate('date', createDate),
          },
        ]}
        onClickRow={({ id }) => navigate(id)}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
      />
    </Paper>
  )
}

export default IndexTable
