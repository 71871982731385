import { FC, useState } from 'react'
import PageHeader, { PageHeaderProps } from './Header'
import { PageContent, PageContentProps } from './PageContent'
import SidebarComponent from './Sidebar'

type AppPageProps = Omit<PageHeaderProps, 'smSidebarToggle' | 'onOpenSidebar'> &
  Omit<PageContentProps, 'sidebar'> & { Sidebar?: FC }

export const AppPage: FC<AppPageProps> = ({ Sidebar, children, actionButtons, ...props }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const onOpenSidebar = () => {
    setIsDrawerOpen(true)
  }

  const sidebar = (Sidebar || actionButtons) && (
    <SidebarComponent
      Content={Sidebar}
      isDrawerOpen={isDrawerOpen}
      onCloseDrawer={() => setIsDrawerOpen(false)}
    />
  )

  return (
    <>
      <PageHeader
        {...props}
        smSidebarToggle={!!Sidebar}
        actionButtons={actionButtons}
        onOpenSidebar={onOpenSidebar}
      />
      <PageContent sidebar={sidebar}>{children}</PageContent>
    </>
  )
}
