import { FC } from 'react'
import { LoyaltyCampaignStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { Chip, ComponentColor } from '@web-panel/shared'

const statusColorMapping: Record<LoyaltyCampaignStatus, ComponentColor> = {
  [LoyaltyCampaignStatus.Active]: 'success',
  [LoyaltyCampaignStatus.Canceled]: 'error',
  [LoyaltyCampaignStatus.Disabled]: 'error',
  [LoyaltyCampaignStatus.Ended]: 'info',
  [LoyaltyCampaignStatus.Completed]: 'info',
}

type LoyaltyCampaignStatusChipProps = {
  status: LoyaltyCampaignStatus
}

export const LoyaltyCampaignStatusChip: FC<LoyaltyCampaignStatusChipProps> = ({ status }) => {
  const { formatMessage } = useIntl()

  const color = statusColorMapping[status] ?? 'default'

  return (
    <Chip
      data-testid="LoyaltyCampaignStatusChip"
      className="min-w-84"
      color={color}
      label={formatMessage({ id: `loyalty-campaign-statuses.${status}` })}
    />
  )
}
