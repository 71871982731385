import { FC, useState } from 'react'
import { Autocomplete, TextField } from '@web-panel/shared'
import { User, UserRole } from '@web-panel/api'
import { useDebouncedValue } from 'rooks'
import { useIntl } from 'react-intl'
import { useUsers, useUser } from '@web-panel/oc-api'

function getUserLabel(user: User | null) {
  if (!user) return ''

  if (user.firstName || user.lastName)
    return `${user.userNumber} ${[user.firstName, user.lastName].join(' ')}`

  if (user.email) return `${user.userNumber} ${user.email}`

  return `${user.userNumber} ${user.phone}`
}

type ConsumerAutocompleteProps = {
  value: string | null
  onChange: (value: string | null) => void
  error?: boolean
}

export const ConsumerAutocomplete: FC<ConsumerAutocompleteProps> = ({ value, onChange, error }) => {
  const { data: user } = useUser({ id: value ?? '-1' })
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebouncedValue(search, 250, { initializeWithNull: false })
  const { data: users = [] } = useUsers({
    search: debouncedSearch ?? '',
    role: UserRole.Consumer,
  })
  const { formatMessage } = useIntl()

  return (
    <Autocomplete<User | null>
      key={user?.id ?? 'empty'}
      fullWidth
      className="mt-16"
      filterOptions={(x) => x}
      options={users}
      renderInput={(params) => (
        <TextField
          error={error}
          label={formatMessage({ id: 'product-item-holder-types.CONSUMER' })}
          {...params}
        />
      )}
      getOptionLabel={getUserLabel}
      isOptionEqualToValue={(option, val) => (option && val && option.id === val.id) ?? false}
      onChange={(e, val) => onChange(val?.id ?? null)}
      onInputChange={(e, val, reason) => {
        if (reason === 'reset') return
        setSearch(val)
      }}
      value={user}
    />
  )
}
