import { FC, useMemo } from 'react'
import { Outlet } from '@web-panel/api'
import { Widget, Typography } from '@web-panel/shared'
import { FormattedMessage } from 'react-intl'

import { useTotalConsumersGained } from '@web-panel/oc-api'

type TotalConsumersWidgetProps = {
  partnerId: string
  outlets: Outlet[]
}

export const TotalConsumersWidget: FC<TotalConsumersWidgetProps> = ({ partnerId, outlets }) => {
  const outletIds = outlets.map(({ id }) => id)
  const { data: counters = [] } = useTotalConsumersGained({ partnerId, outletIds })

  const consumersCount = useMemo(() => {
    if (outlets.length === 0) return 0

    return counters.reduce((sum, counter) => sum + counter.count, 0)
  }, [outlets, counters])

  return (
    <Widget>
      <div className="text-center py-12">
        <Typography className="text-72 font-semibold leading-none text-purple tracking-tighter">
          {consumersCount}
        </Typography>
        <Typography className="text-18 text-purple-800 font-normal">
          <FormattedMessage id="pages.partner-preview.tabs.dashboard.consumers-widget.title" />
        </Typography>
      </div>
    </Widget>
  )
}
