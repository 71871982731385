import { PropsWithChildren } from 'react'
import { List, ListItemText, Chip } from '@mui/material'
import { StyledListItem, StyledListSubheader } from './styled'

export type SingleSelectProps<Data> = PropsWithChildren<{
  label?: string
  value: Data | undefined
  options: Data[]
  badge?: (option: Data) => string
  optionTitle?: (option: Data) => string
  onSelect?: (value: Data | undefined) => void
  compareOption?: (option: Data, value: Data) => boolean
}>

const SingleSelect = <Data extends unknown>({
  label,
  value,
  options,
  badge,
  optionTitle,
  onSelect,
  compareOption = (o, v) => o === v,
}: SingleSelectProps<Data>) => {
  const isSelected = (option: Data) => {
    if (!value) return false

    return compareOption(option, value)
  }

  const handleSelect = (option: Data) => {
    if (!onSelect) return

    if (isSelected(option)) {
      onSelect(undefined)
    } else {
      onSelect(option)
    }
  }

  return (
    <List className="py-0">
      {label && (
        <StyledListSubheader className="pl-12" disableSticky>
          {label}
        </StyledListSubheader>
      )}
      {options.map((option, id) => (
        <StyledListItem selected={isSelected(option)} key={id} onClick={() => handleSelect(option)}>
          <ListItemText>
            <div className="flex flex-row justify-between">
              {optionTitle ? optionTitle(option) : option}
              {badge && <Chip size="small" className="ml-8" label={badge(option)} />}
            </div>
          </ListItemText>
        </StyledListItem>
      ))}
    </List>
  )
}

export default SingleSelect
