import { FC } from 'react'
import { PartnerStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { Chip, ComponentColor } from '@web-panel/shared'

const statusColorMapping: Record<PartnerStatus, ComponentColor> = {
  [PartnerStatus.Active]: 'success',
  [PartnerStatus.Deleted]: 'error',
  [PartnerStatus.Inactive]: 'error',
  [PartnerStatus.Pending]: 'warning',
}

type PartnerStatusChipProps = {
  status: PartnerStatus
}

export const PartnerStatusChip: FC<PartnerStatusChipProps> = ({ status }) => {
  const { formatMessage } = useIntl()

  const color = statusColorMapping[status] ?? 'default'

  return (
    <Chip
      data-testid="PartnerStatusChip"
      className="min-w-84"
      color={color}
      label={formatMessage({ id: `partner-statuses.${status}` })}
    />
  )
}
