import { FC } from 'react'
import {
  Partner,
  Outlet,
  PaymentType,
  OrderInvoiceType,
  isOutletHasLegalAddress,
} from '@web-panel/api'
import { FormSection } from '@web-panel/shared'
import { FormikProps } from 'formik'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import PartnerAutocompleteInput from '@local/components/Autocomplete/PartnerAutocompleteInput'
import { OutletAutocomplete } from '@local/components/Autocomplete/OutletAutocomplete'
import { LegalAddressSection } from './sections/LegalAddressSection'
import {
  ItemsSection,
  ItemSectionFormFields,
  defaultItemsInfoValues,
  itemsInfoValidationSchema,
} from './sections/ItemSection'
import { DeliveryAddressSection } from './sections/DeliveryAddressSection'
import {
  DeliveryTimeSection,
  DeliveryTimeFormFields,
  defaultDeliveryTimeValues,
} from './sections/DeliveryTimeSection'
import {
  ContactInformationSection,
  ContactInformationSectionFields,
  contactInformationValidationSchema,
  defaultContactInformationValues,
} from './sections/ContactInformationSection'

export type FormFields = {
  partner: Partner | null
  outlet: Outlet | null
  paymentType: PaymentType
  invoiceType: OrderInvoiceType
} & ItemSectionFormFields &
  DeliveryTimeFormFields &
  ContactInformationSectionFields

export const defaultValues: FormFields = {
  partner: null,
  outlet: null,
  paymentType: PaymentType.BankAccount,
  invoiceType: OrderInvoiceType.PartnerInvoice,
  ...defaultItemsInfoValues,
  ...defaultDeliveryTimeValues,
  ...defaultContactInformationValues,
}

export const validationSchema = Yup.object()
  .shape({
    partner: Yup.object().required(),
    outlet: Yup.object().required(),
  })
  .concat(itemsInfoValidationSchema)
  .concat(contactInformationValidationSchema)

type CreateOrderFormProps = FormikProps<FormFields>

export const CreateOrderForm: FC<CreateOrderFormProps> = ({ values, setValues, handleSubmit }) => {
  const { formatMessage } = useIntl()

  return (
    <form className="p-24 max-w-2xl" onSubmit={handleSubmit}>
      <FormSection
        firstSection
        title={formatMessage({ id: 'forms.create-order.sections.partner' })}
      >
        <PartnerAutocompleteInput
          fullWidth
          label={formatMessage({ id: 'models.order.partner' })}
          value={values.partner}
          onChange={(partner) =>
            setValues({
              ...values,
              partner,
              outlet: null,
            })
          }
        />

        <OutletAutocomplete
          fullWidth
          className="mt-16"
          disabled={!values.partner}
          label={formatMessage({ id: 'models.order.outlet' })}
          partnerId={values.partner?.id ?? undefined}
          outletId={values.outlet?.id ?? null}
          onChange={(outlet) =>
            setValues({
              ...values,
              outlet,
              invoiceType:
                outlet && isOutletHasLegalAddress(outlet)
                  ? OrderInvoiceType.OutletInvoice
                  : OrderInvoiceType.PartnerInvoice,
              firstName: outlet?.firstName ?? '',
              lastName: outlet?.lastName ?? '',
              phone: outlet?.phone ?? '',
            })
          }
        />
      </FormSection>
      {values.partner && values.outlet && (
        <>
          <ContactInformationSection />
          <ItemsSection partner={values.partner} outlet={values.outlet} />
          <DeliveryAddressSection outlet={values.outlet} />
          <LegalAddressSection partner={values.partner} outlet={values.outlet} />
          <DeliveryTimeSection />
        </>
      )}
    </form>
  )
}
