import { FC } from 'react'
import { ContainerCapacityType } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { Chip, ComponentColor } from '@web-panel/shared'

const statusColorMapping: Record<ContainerCapacityType, ComponentColor> = {
  [ContainerCapacityType.Large]: 'error',
  [ContainerCapacityType.Small]: 'success',
  [ContainerCapacityType.DispenserTube]: 'info',
  [ContainerCapacityType.Default]: 'default',
}

type ContainerCapacityTypeChipProps = {
  capacityType: ContainerCapacityType
}

export const ContainerCapacityTypeChip: FC<ContainerCapacityTypeChipProps> = ({ capacityType }) => {
  const { formatMessage } = useIntl()

  const color = statusColorMapping[capacityType] ?? 'default'

  return (
    <Chip
      className="min-w-84"
      color={color}
      label={formatMessage({ id: `container-capacity-types.${capacityType}` })}
    />
  )
}
