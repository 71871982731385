import { FC } from 'react'
import { AppPage, Button, CardedContent, useNotifications } from '@web-panel/shared'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { FormattedMessage, useIntl } from 'react-intl'
import { CollectionBoxRentalConfiguration, RentalConfigurationStatus } from '@web-panel/api'
import { useLocation, useNavigate } from 'react-router-dom'
import modelIcons from '@local/model-icons'
import { Formik } from 'formik'
import { Error404 } from '@local/pages/404'
import { useCreateRentalConfiguration } from '@web-panel/oc-api'
import {
  CreateRentalConfigurationForm,
  FormFields,
  validationSchema,
} from './Forms/CreateRentalConfigurationForm'

type PageComponentProps = {
  configuration: CollectionBoxRentalConfiguration
}
const PageComponent: FC<PageComponentProps> = ({ configuration }) => {
  const { formatMessage } = useIntl()
  const { pushNotification } = useNotifications()
  const navigate = useNavigate()
  const { request: createConfiguration, loading: loadingCreateConfiguration } =
    useCreateRentalConfiguration()

  const onSubmit = async (values: FormFields) => {
    try {
      await createConfiguration({ ...values, countryCode: configuration.countryCode })
      formatMessage({ id: 'common.notifications.successfully-created' })
      navigate(-1)
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const initialValues: FormFields = {
    status: RentalConfigurationStatus.Active,
    price: 10,
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, isValid }) => {
        const headerActionButtons = [
          <Button
            loading={loadingCreateConfiguration}
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => handleSubmit()}
            disabled={!isValid}
          >
            <FormattedMessage id="common.actions.create" />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            title={formatMessage({ id: 'routes.settings.box-rental-configuration.create' })}
            icon={modelIcons.rentalConfiguration}
            actionButtons={headerActionButtons}
          >
            <CardedContent padded>
              <CreateRentalConfigurationForm />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export default pageWithRoleAuthorization(
  () => {
    const { state } = useLocation()
    const configuration = state?.configuration as CollectionBoxRentalConfiguration
    if (!configuration) return <Error404 />

    return <PageComponent configuration={configuration} />
  },
  ({ can }) => can('boxRentalConfiguration', 'create')
)
