import {
  buildModal,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  ModalBodyProps,
} from '@web-panel/shared'
import { FC } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

type UpdateBoxWarningModalProps = ModalBodyProps & {
  fieldsToWarn: string[]
  onSubmit: () => void
}

const UpdateBoxWarning: FC<UpdateBoxWarningModalProps> = ({ fieldsToWarn, onClose, onSubmit }) => {
  const { formatMessage } = useIntl()

  return (
    <>
      <DialogTitle>
        <FormattedMessage id="modals.update-box-warning.title" />
      </DialogTitle>
      <DialogContent>
        <FormattedMessage
          id="modals.update-box-warning.message"
          values={{
            fieldNames: fieldsToWarn
              .map((fieldToWarn) => `"${formatMessage({ id: `models.box.${fieldToWarn}` })}"`)
              .join(','),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="common.actions.cancel" />
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          <FormattedMessage id="common.actions.update" />
        </Button>
      </DialogActions>
    </>
  )
}

export const UpdateBoxWarningModal = buildModal(UpdateBoxWarning, {
  fullWidth: true,
  maxWidth: 'md',
})
