import { FC, useState } from 'react'
import {
  Button,
  ModalBody,
  Checkbox,
  FormControlLabel,
  buildModal,
  ModalBodyProps,
} from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'

type CustomLocationModalProps = ModalBodyProps & {
  onSubmit: (prefill: boolean) => void
}

const CustomLocationModal: FC<CustomLocationModalProps> = ({ onClose, onSubmit }) => {
  const [prefill, setPrefill] = useState(true)
  const { formatMessage } = useIntl()

  return (
    <ModalBody
      title={formatMessage({ id: 'confirmation-dialogs.box-with-outlet.add-location' })}
      actions={[
        <Button key="cancel" onClick={onClose}>
          <FormattedMessage id="common.actions.cancel" />
        </Button>,
        <Button key="submit" onClick={() => onSubmit(prefill)}>
          <FormattedMessage id="common.actions.add" />
        </Button>,
      ]}
    >
      <FormControlLabel
        label={formatMessage({ id: 'confirmation-dialogs.box-with-outlet.prefill' })}
        control={<Checkbox checked={prefill} onChange={() => setPrefill(!prefill)} />}
      />
    </ModalBody>
  )
}

export default buildModal(CustomLocationModal, { fullWidth: true })
