import React from 'react'
import ReactDOM from 'react-dom'
import {
  App,
  LanguageSwitcher,
  SystemMonitoring,
  registerAsset,
  registerRoutes,
  registerNavigation,
  registerTheme,
  registerApplicationName,
} from '@web-panel/shared'
import { configureClient, AuthContextProvider, AuthContextConsumer } from '@web-panel/api'
import { NavigationProviderComponent } from '@local/components/Navigation'
import { appLogo } from '@local/app-logo'
import routes from './routes'
import { locales, availableLocales } from './locales'
import UserMenu from './components/UserMenu'
import { themeOverrides } from './theme-overrides'

import '@web-panel/shared/src/assets/styles/app.css'

configureClient(process.env.REACT_APP_API_HOST ?? '')

registerAsset('logo', appLogo)
registerRoutes(routes)
registerNavigation('NavigationProvider', NavigationProviderComponent)
registerNavigation('defaultBackLabelKey', 'common.actions.navigate-back-label')
registerTheme(themeOverrides)
registerApplicationName('Operation Center')

ReactDOM.render(
  <React.StrictMode>
    <SystemMonitoring serviceName="oc-panel">
      <AuthContextProvider>
        <AuthContextConsumer>
          {({ authenticated }) => (
            <App
              auth={{
                authenticated,
                redirect: {
                  onRestrictedAuth: '/sign-in',
                  onRestrictedNonAuth: '/orders',
                },
              }}
              locale={{
                locales,
                availableLocales,
                defaultLocale: 'en',
              }}
              toolbar={{
                rightTools: [LanguageSwitcher, UserMenu],
              }}
            />
          )}
        </AuthContextConsumer>
      </AuthContextProvider>
    </SystemMonitoring>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
