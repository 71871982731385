import { ConsumablePartnerProductItem } from '@web-panel/api'
import _ from 'lodash'

import {
  useCreateProductItemPartnerConsumable,
  useUpdateProductItemPartnerConsumable,
} from '@web-panel/oc-api'

type ChangeInput = {
  cost: number
  enabled: boolean
}

export const useChangePartnerConsumable = (
  partnerId?: string,
  consumable?: ConsumablePartnerProductItem
) => {
  const { request: createConsumable, loading: loadingCreate } =
    useCreateProductItemPartnerConsumable()
  const { request: updateConsumable, loading: loadingUpdate } =
    useUpdateProductItemPartnerConsumable()

  const request = async (variables: ChangeInput) => {
    if (!partnerId || !consumable) return undefined

    if (consumable.partnerId) {
      const response = await updateConsumable({
        id: consumable.id,
        partnerId,
        input: {
          ..._.pick(consumable, ['consumableType', 'currency']),
          ...variables,
        },
      })
      return response
    }

    const response = await createConsumable({
      partnerId,
      input: {
        ..._.pick(consumable, ['consumableType', 'currency']),
        ...variables,
      },
    })
    return response
  }

  return {
    request,
    loading: loadingCreate || loadingUpdate,
  }
}
