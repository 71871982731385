import { gql } from '@apollo/client'
import { SequenceNode, sequenceNodeFields } from '@web-panel/api'

export type SequenceNodesDocumentInput = {
  limit: number
  offset: number
  search?: string
  shipmentSequenceId: string
}

export type SequenceNodesDocumentResponse = {
  sequenceNodesCollection: {
    sequenceNodes: SequenceNode[]
    hasNext: boolean
  }
}

export const SequenceNodesDocument = gql`
  query SequenceNodes($limit: Int!, $offset: Int!, $search: String, $shipmentSequenceId: String!) {
    sequenceNodesCollection(limit: $limit, offset: $offset, search: $search, shipmentSequenceId: $shipmentSequenceId) @rest(type: "SequenceNodesCollection", path: "/shipment-service/sequences/{args.shipmentSequenceId}/nodes?limit={args.limit}&offset={args.offset}&search={args.search}") {
      sequenceNodes @type(name: "SequenceNodes") {
        ${sequenceNodeFields}
      }
      hasNext
    }
  }
`
