import { FC, useState } from 'react'
import {
  Button,
  ModalBody,
  buildModal,
  ModalBodyProps,
  LabeledSwitch,
  Typography,
} from '@web-panel/shared'
import { Outlet, Box } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'
import { OutletAutocomplete } from '@local/components/Autocomplete/OutletAutocomplete'

import { useAssignBoxToOutlet } from '@web-panel/oc-api'

type OutletAssignmentModalProps = ModalBodyProps & {
  box: Box
  onSubmit?: () => void
}

const OutletAssignmentModal: FC<OutletAssignmentModalProps> = ({ box, onSubmit, onClose }) => {
  const [searchableInOutlet, setSearchableInOutlet] = useState(false)
  const [keepLocation, setKeepLocation] = useState(false)
  const { formatMessage } = useIntl()
  const { request: assignBoxToOutlet } = useAssignBoxToOutlet()
  const [selectedOutlet, setSelectedOutlet] = useState<Outlet | null>(null)

  const onAssign = async () => {
    if (!selectedOutlet) return

    await assignBoxToOutlet({
      boxId: box.id,
      outletId: selectedOutlet.id,
      input: { searchableInOutlet, keepLocation },
    })
    if (onSubmit) onSubmit()
    setSelectedOutlet(null)
    onClose()
  }

  return (
    <ModalBody
      title={formatMessage({ id: 'pages.boxes.new.models.outlet-assignment.title' })}
      actions={[
        <Button onClick={onClose} key="cancel-button">
          <FormattedMessage id="common.actions.cancel" />
        </Button>,
        <Button
          variant="contained"
          color="primary"
          onClick={onAssign}
          disabled={!selectedOutlet}
          key="submit-button"
        >
          <FormattedMessage id="pages.boxes.new.models.outlet-assignment.submit-button" />
        </Button>,
      ]}
    >
      <OutletAutocomplete
        fullWidth
        className="mt-8"
        label={formatMessage({ id: 'models.box.outlet' })}
        onChange={setSelectedOutlet}
        outletId={selectedOutlet?.id ?? null}
      />
      <LabeledSwitch
        className="mt-16"
        disabled={!selectedOutlet}
        label={formatMessage({ id: 'models.box.searchable-in-outlet' })}
        checked={searchableInOutlet}
        onChange={(e) => setSearchableInOutlet(e.target.checked)}
      />
      {box.location?.editable && (
        <div className="mt-16">
          {!keepLocation && (
            <Typography variant="body1" color="warning.main">
              <FormattedMessage id="warnings.remove-box-from-location" />
            </Typography>
          )}
          <LabeledSwitch
            disabled={!selectedOutlet}
            label={formatMessage({ id: 'common.labels.keep-location' })}
            checked={keepLocation}
            onChange={(e) => setKeepLocation(e.target.checked)}
          />
        </div>
      )}
    </ModalBody>
  )
}

export default buildModal(OutletAssignmentModal, { fullWidth: true, maxWidth: 'md' })
