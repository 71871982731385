import { FC } from 'react'
import { Autocomplete, TextField, Typography } from '@web-panel/shared'
import { OutletStatus, locationAddressString, Outlet } from '@web-panel/api'
import { useDebouncedValue } from 'rooks'

import { useOutlets, useOutlet } from '@web-panel/oc-api'

type OutletAutocompleteProps = {
  fullWidth?: boolean
  className?: string
  outletId: string | null
  label?: string
  onChange: (outlet: Outlet | null) => void
  error?: boolean
  disabled?: boolean
  partnerId?: string
}

export const OutletAutocomplete: FC<OutletAutocompleteProps> = ({
  fullWidth,
  className,
  disabled,
  outletId,
  onChange,
  label,
  error,
  partnerId,
}) => {
  const [debouncedSearch, onChangeSearch] = useDebouncedValue('', 250, {
    initializeWithNull: false,
  })
  const { data: currentOutlet } = useOutlet({ id: outletId ?? '-1' })
  const { data: outlets = [] } = useOutlets({ search: debouncedSearch ?? '', partnerId })

  return (
    <Autocomplete
      key={outletId ?? '-1'}
      fullWidth={fullWidth}
      className={className}
      options={outlets}
      disabled={disabled}
      renderInput={({ inputProps, ...params }) => (
        <TextField
          error={error}
          {...params}
          label={label}
          inputProps={{ ...inputProps, autoComplete: 'do-not-autocomplete' }}
        />
      )}
      getOptionDisabled={({ status }) => status !== OutletStatus.Active}
      getOptionLabel={(outlet) =>
        [outlet.location.name, locationAddressString(outlet.location)].join(', ')
      }
      isOptionEqualToValue={(option, val) => option.id === val.id}
      renderOption={(props, outlet) => {
        return (
          <li {...props} key={outlet.id}>
            {outlet.location.name}
            &nbsp;
            <Typography color="gray" variant="caption">
              ({locationAddressString(outlet.location)})
            </Typography>
          </li>
        )
      }}
      onChange={(e, outlet) => onChange(outlet)}
      onInputChange={(event, val, reason) => {
        onChangeSearch(val)
      }}
      value={currentOutlet ?? null}
    />
  )
}
