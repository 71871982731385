import { FC, useMemo, createContext, useContext } from 'react'
import { useDebouncedValue } from 'rooks'
import _pick from 'lodash/pick'
import { useSearchParams } from '../hooks'

export function buildFilterContext<Filters extends Record<string, string | undefined>>(
  defaultValues: Filters
) {
  type FilterSetter<T extends keyof Filters = keyof Filters> = (name: T, value: Filters[T]) => void
  type FilterContext = {
    filters: Filters
    debouncedFilters: Filters
    setFilter: FilterSetter
    setFilters: (value: Partial<Filters>) => void
  }

  const Context = createContext<FilterContext>({
    filters: defaultValues,
    debouncedFilters: defaultValues,
    setFilter: () => {
      //
    },
    setFilters: () => {
      //
    },
  })

  const FiltersContextProvider: FC = ({ children }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const filters = useMemo(() => {
      const filterKeys = Object.keys(defaultValues) as (keyof Filters)[]
      return { ...defaultValues, ..._pick(searchParams, filterKeys) }
    }, [searchParams, defaultValues])

    const [debouncedFilters] = useDebouncedValue(filters, 250, { initializeWithNull: false })

    const setFilter: FilterSetter = (name, value) => {
      setSearchParams({
        [name]: value,
      })
    }

    const setFilters = (updatedFilters: Partial<Filters>) => {
      setSearchParams(updatedFilters)
    }

    return (
      <Context.Provider
        value={{ filters, setFilter, setFilters, debouncedFilters: debouncedFilters ?? filters }}
      >
        {children}
      </Context.Provider>
    )
  }

  function withFilters(Component: FC): FC {
    return () => (
      <FiltersContextProvider>
        <Component />
      </FiltersContextProvider>
    )
  }

  const useFilters = () => {
    return useContext(Context)
  }

  return { FiltersContext: FiltersContextProvider, withFilters, useFilters }
}
