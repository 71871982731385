import { FC, useCallback, useState } from 'react'
import { AppPage, InfinitScroller, useDebouncedSearch, useModalRef } from '@web-panel/shared'
import { useIntl } from 'react-intl'

import modelIcons from '@local/model-icons'
import { PageHeaderSearch } from '@local/components/PageHeaderSearch'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { useGroupLabels } from '@web-panel/oc-api'
import { GroupLabel } from '@web-panel/api'
import IndexTable from './Tables/IndexTable'
import Filters, { useFilters, withFilters } from './Filters'
import ActivateGroupLabelModalConfirmation from './Modals/ActivateGroupLabelModalConfirmation'
import { useSorting, withSorting } from './sorting'

const IndexPage: FC = () => {
  const { search, onChangeSearch, debouncedSearch } = useDebouncedSearch()
  const { formatMessage } = useIntl()
  const activationConfirmationModalRef = useModalRef()
  const { filters } = useFilters()
  const [groupLabel, setGroupLabel] = useState<GroupLabel>()
  const { field: sortBy, direction: sortOrder } = useSorting()

  const {
    data: groupLabels = [],
    loading,
    hasMore,
    refetch,
    loadMore,
  } = useGroupLabels(
    { ...filters, search: debouncedSearch, sortBy, sortOrder },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )

  const handleOnActivate = (_groupLabel: GroupLabel) => {
    setGroupLabel(_groupLabel)
    activationConfirmationModalRef.current?.open()
  }

  const onLoadMore = () => {
    if (!hasMore) return
    loadMore()
  }

  const FilterComponent: FC = useCallback(() => {
    return <Filters search={debouncedSearch} />
  }, [debouncedSearch])

  return (
    <AppPage
      loading={loading}
      icon={modelIcons.groupLabel}
      title={formatMessage({ id: 'routes.group-labels' })}
      searchComponent={<PageHeaderSearch value={search} onChangeValue={onChangeSearch} />}
      Sidebar={FilterComponent}
    >
      <InfinitScroller onLoadMore={onLoadMore}>
        <IndexTable
          data={groupLabels}
          loading={loading}
          showGroupNameAsLink
          onActivate={(label) => handleOnActivate(label)}
        />
      </InfinitScroller>
      <ActivateGroupLabelModalConfirmation
        ref={activationConfirmationModalRef}
        groupLabel={groupLabel!}
        onActivated={refetch}
      />
    </AppPage>
  )
}

export default pageWithRoleAuthorization(withSorting(withFilters(IndexPage)), ({ can }) =>
  can('groupLabels', 'list')
)
