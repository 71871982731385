import { Partner } from '@web-panel/api'
import _pick from 'lodash/pick'
import { FormFields } from './Forms/PartnerForm'

export function buildPartnerFormInitialValues(partner: Partner): FormFields {
  return {
    ..._pick(partner, [
      'name',
      'firstName',
      'lastName',
      'email',
      'partnerStatus',
      'authorized',
      'registrationId',
      'invoiceRecipient',
      'test',
      'kookyOperated',
      'loyaltyEnabled',
      'deliverySignatureRequired',
    ]),
    phone: partner.phone ?? '',
    operationsPhone: partner.operationsPhone ?? '',
    operationsEmail: partner.operationsEmail ?? '',
    invoiceCountry: partner.invoiceAddress.countryCode,
    invoiceCity: partner.invoiceAddress.city ?? '',
    invoiceStreet: partner.invoiceAddress.street ?? '',
    invoiceHouseNumber: partner.invoiceAddress.houseNumber ?? '',
    invoicePostalCode: partner.invoiceAddress.postalCode ?? '',
    invoiceAdditionalAddress: partner.invoiceAddress.addressAddition ?? '',
    financePaymentType: partner.finance.paymentType,
    invoicesEmail: partner.invoicesEmail ?? partner.email,
  }
}

export function partnerFormFieldsToInputValues(formFields: FormFields) {
  return {
    ..._pick(formFields, [
      'name',
      'firstName',
      'lastName',
      'phone',
      'email',
      'operationsPhone',
      'operationsEmail',
      'partnerStatus',
      'authorized',
      'registrationId',
      'invoiceRecipient',
      'test',
      'kookyOperated',
      'invoicesEmail',
      'loyaltyEnabled',
      'deliverySignatureRequired',
    ]),
    invoiceAddress: {
      countryCode: formFields.invoiceCountry,
      city: formFields.invoiceCity,
      street: formFields.invoiceStreet,
      houseNumber: formFields.invoiceHouseNumber,
      postalCode: formFields.invoicePostalCode,
      addressAddition: formFields.invoiceAdditionalAddress,
    },
    finance: {
      paymentType: formFields.financePaymentType,
    },
  }
}
