import { FC, ReactNode } from 'react'
import { TableRow, TableCell, Icon, Typography } from '@mui/material'
import classNames from 'classnames'

type HorizontalTableSectionProps = {
  isFirstInTable?: boolean
  icon?: string
  title: string
  description?: string
  buttons?: ReactNode[]
}

export const HorizontalTableSection: FC<HorizontalTableSectionProps> = ({
  icon,
  isFirstInTable = false,
  title,
  description,
  buttons,
}) => {
  return (
    <TableRow>
      <TableCell variant="head" colSpan={2} className={classNames({ 'pt-36': !isFirstInTable })}>
        <div className="flex flex-row">
          <div className="flex flex-row w-full items-end">
            <Typography variant="h6" className="flex flex-row items-center">
              {icon && <Icon className="mr-5">{icon}</Icon>}
              {title}
            </Typography>
            {description && (
              <Typography variant="caption" color="GrayText" className="ml-10 mb-3">
                {description}
              </Typography>
            )}
          </div>
          {buttons && buttons.length > 0 && <div>{buttons}</div>}
        </div>
      </TableCell>
    </TableRow>
  )
}
