import { FC } from 'react'
import { buildModal, Button, ModalBody, ModalBodyProps, useNotifications } from '@web-panel/shared'
import { ContractPartnerSetting, ContractSettingConfigTriggerUnit } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useUpdateContractSetting } from '@web-panel/oc-api'
import { ContractSettingInput } from '../Components/ContractSettingInput'
import { useSettingTitle } from '../helpers/setting-helpers'

type FormFields = {
  price: number
  amount: number
}

const validationSchema = yup.object().shape({
  amount: yup.number().min(0).required(),
  price: yup.number().min(0).required(),
})

type UpdateContractSettingModalProps = ModalBodyProps & {
  setting: ContractPartnerSetting
  resourceName?: string
  heading?: string
  onUpdate: () => Promise<void>
}
const UpdateContractSettingModal: FC<UpdateContractSettingModalProps> = ({
  setting,
  heading,
  resourceName,
  onUpdate,
  onClose,
}) => {
  const { pushNotification } = useNotifications()
  const { formatMessage } = useIntl()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const title = heading ?? useSettingTitle(setting)
  const { request: updateContractSetting, loading } = useUpdateContractSetting()

  const onSubmit = async (values: FormFields) => {
    try {
      await updateContractSetting({
        partnerId: setting.partnerId,
        outletId: setting.outletId,
        contractSettingId: setting.id,
        input: {
          amount: values.amount,
          price: values.price,
          currencyCode: setting.countryCode.code,
          name: setting.name,
          description: setting.description,
          settingType: setting.settingType,
        },
      })
      await onUpdate()
      onClose()
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <Formik<FormFields>
      initialValues={{ price: setting.price, amount: setting.amount }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, setValues, handleSubmit, isValid, dirty }) => (
        <ModalBody
          title={title ?? ''}
          actions={[
            <Button key="close-button" onClick={onClose}>
              <FormattedMessage id="common.actions.cancel" />
            </Button>,
            <Button
              disabled={!isValid || !dirty}
              loading={loading}
              key="submit-button"
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
            >
              <FormattedMessage id="common.actions.update" />
            </Button>,
          ]}
        >
          <div className="flex flex-row justify-center pt-24">
            <ContractSettingInput
              type={setting.settingType}
              resourceName={resourceName}
              values={values}
              errors={{ amount: !!errors.amount, price: !!errors.price }}
              currencyCode={setting.countryCode.code}
              onUpdate={setValues}
              orderLabel={
                setting.contractSettingConfig.triggerUnit === ContractSettingConfigTriggerUnit.Order
              }
            />
          </div>
        </ModalBody>
      )}
    </Formik>
  )
}

export default buildModal(UpdateContractSettingModal)
