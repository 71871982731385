import { FC } from 'react'
import { AppPage, CardedContent, TabSections } from '@web-panel/shared'
import modelIcons from '@local/model-icons'
import { useIntl } from 'react-intl'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { ConsumablesTab } from './Tabs/ConsumablesTab'
import { SettingsCitiesTab } from './Tabs/CitiesTab'
import { SettingsCountriesTab } from './Tabs/CountriesTab'
import { BoxRentalConfigurationsTab } from './Tabs/BoxRentalConfigurationsTab'

export const SettingsIndexPage: FC = () => {
  const { formatMessage } = useIntl()
  const { can, loading: loadingRoleAuthorization } = useRoleAuthorization()
  if (loadingRoleAuthorization) return null

  return (
    <AppPage icon={modelIcons.settings} title={formatMessage({ id: 'routes.settings' })}>
      <CardedContent>
        <TabSections
          sections={[
            {
              key: 'consumables',
              title: formatMessage({ id: 'routes.settings.consumables' }),
              Content: ConsumablesTab,
              enabled: can('consumable', 'list'),
            },
            {
              key: 'cities',
              title: formatMessage({ id: 'routes.settings.cities' }),
              Content: SettingsCitiesTab,
              enabled: can('geoCity', 'list'),
            },
            {
              key: 'countries',
              title: formatMessage({ id: 'routes.settings.countries' }),
              Content: SettingsCountriesTab,
              enabled: can('geoCountry', 'list'),
            },
            {
              key: 'rentals',
              title: formatMessage({ id: 'routes.settings.box-rentals' }),
              Content: BoxRentalConfigurationsTab,
              enabled: can('boxRentalConfiguration', 'list'),
            },
          ]}
        />
      </CardedContent>
    </AppPage>
  )
}
