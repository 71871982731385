import { FC } from 'react'
import { Container, ContainerHolderType } from '@web-panel/api'
import { FormattedMessage } from 'react-intl'
import { OutletLink } from '../OutletLink'

type ContainerHolderReferenceProps = Pick<Container, 'holderType' | 'holderId'>

export const ContainerHolderReference: FC<ContainerHolderReferenceProps> = ({
  holderId,
  holderType,
}) => {
  if (holderType === ContainerHolderType.OperationsCenter)
    return <FormattedMessage id="common.labels.operations-center" />
  if (!holderId) return null

  switch (holderType) {
    case ContainerHolderType.PartnerOutlet:
      return <OutletLink outletId={holderId} />
    default:
      return <FormattedMessage id="common.labels.operations-center" />
  }
}
