import { FC } from 'react'
import { FormikProps } from 'formik'
import { useIntl } from 'react-intl'
import { TextField } from '@web-panel/shared'
import * as Yup from 'yup'

export type FormFields = {
  firstName: string
  lastName: string
  email: string
}

export const validationSchema = Yup.object().shape({
  outeletIds: Yup.array(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
})

export const defaultValues: FormFields = {
  firstName: '',
  lastName: '',
  email: '',
}

type EditPartnerEmployeeFormTypes = FormikProps<FormFields>

const EditPartnerEmployeeForm: FC<EditPartnerEmployeeFormTypes> = ({
  values,
  errors,
  handleChange,
  handleSubmit,
}) => {
  const { formatMessage } = useIntl()

  return (
    <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit}>
      <div className="flex flex-col p-16 sm:p-24 max-w-2xl">
        <TextField
          fullWidth
          value={values.firstName}
          onChange={handleChange('firstName')}
          label={formatMessage({ id: 'models.partner-employee.first-name' })}
          error={!!errors.firstName}
        />
        <TextField
          fullWidth
          className="mt-16"
          value={values.lastName}
          onChange={handleChange('lastName')}
          label={formatMessage({ id: 'models.partner-employee.last-name' })}
          error={!!errors.lastName}
        />
        <TextField
          type="email"
          fullWidth
          className="mt-16"
          value={values.email}
          onChange={handleChange('email')}
          label={formatMessage({ id: 'models.partner-employee.email' })}
          error={!!errors.email}
        />
      </div>
    </form>
  )
}

export default EditPartnerEmployeeForm
