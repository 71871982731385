import { ContainerAutocompleteInput } from '@local/components/Autocomplete/ContainerAutocompleteInput'
import { Container, ContainerStatus } from '@web-panel/api'
import { Button, ModalBody, ModalBodyProps, buildModal, useNotifications } from '@web-panel/shared'
import { FC, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { useAttachContainerToBatch } from '@web-panel/oc-api'

type BatchAttachContainerModalProps = ModalBodyProps & {
  batchId: string
}

const BatchAttachContainerModal: FC<BatchAttachContainerModalProps> = ({ batchId, onClose }) => {
  const { formatMessage } = useIntl()
  const { pushNotification } = useNotifications()
  const [container, setContainer] = useState<Container | null>(null)
  const { request: attachContainer, loading } = useAttachContainerToBatch()

  const handleAttach = async () => {
    if (!container) return

    try {
      await attachContainer({ batchId, input: { qrCode: container.qrCode } })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
      onClose()
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <ModalBody
      title={formatMessage({ id: 'modals.batch-attach-container.title' })}
      actions={[
        <Button disabled={loading} key="close-button" onClick={onClose}>
          <FormattedMessage id="common.actions.cancel" />
        </Button>,
        <Button
          loading={loading}
          key="submit-button"
          variant="contained"
          color="primary"
          onClick={handleAttach}
        >
          <FormattedMessage id="common.actions.attach" />
        </Button>,
      ]}
    >
      <div className="pt-6">
        <ContainerAutocompleteInput
          fullWidth
          className="z-10"
          label={formatMessage({ id: 'models.batch.container' })}
          value={container}
          onChange={setContainer}
          getOptionDisabled={({ status }) =>
            ![ContainerStatus.Ready, ContainerStatus.Operations].includes(status)
          }
        />
      </div>
    </ModalBody>
  )
}

export default buildModal(BatchAttachContainerModal, {
  PaperProps: {
    className: 'overflow-y-visible',
  },
  fullWidth: true,
  maxWidth: 'md',
})
