import { FC } from 'react'
import { Typography, WorkingHoursPreview } from '@web-panel/shared'
import { Outlet } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'
import { RoleAuthorized } from '@local/components/RoleAuthorized'
import PreviewLogo from '../Tables/PreviewLogo'
import { PreviewTable } from '../Tables/PreviewTable'
import { OutletSupplementsTable } from '../Tables/OutletSupplements'

type GeneralTabProps = {
  outlet: Outlet
}
export const GeneralTab: FC<GeneralTabProps> = ({ outlet }) => {
  const { formatMessage } = useIntl()

  return (
    <div className="flex flex-col md:flex-row">
      <div className="mt-16 md:mt-0 md:w-1/3 mb-24 flex flex-col items-center">
        <PreviewLogo outlet={outlet} />
      </div>
      <div className="flex-1">
        <PreviewTable data={outlet} />
        <RoleAuthorized resource="outletSupplement" action="list">
          <OutletSupplementsTable outletId={outlet.id} />
        </RoleAuthorized>
        <div className="mt-48 px-16 md:px-0 flex items-center">
          <Typography className="h2 font-medium">
            <FormattedMessage id="models.outlet.location.working-hours" />
          </Typography>
        </div>
        <WorkingHoursPreview
          data={outlet.location.workingHours}
          dayLabels={{
            monday: formatMessage({ id: 'time.day-of-week.short.monday' }),
            tuesday: formatMessage({ id: 'time.day-of-week.short.tuesday' }),
            wednesday: formatMessage({
              id: 'time.day-of-week.short.wednesday',
            }),
            thursday: formatMessage({ id: 'time.day-of-week.short.thursday' }),
            friday: formatMessage({ id: 'time.day-of-week.short.friday' }),
            saturday: formatMessage({ id: 'time.day-of-week.short.saturday' }),
            sunday: formatMessage({ id: 'time.day-of-week.short.sunday' }),
          }}
          timeFrameLabels={{
            opening: formatMessage({
              id: 'models.outlet.location.opening-time',
            }),
            closing: formatMessage({
              id: 'models.outlet.location.closing-time',
            }),
          }}
        />
      </div>
    </div>
  )
}
