import { FC } from 'react'
import {
  ContractPackageSetting,
  ContractPartnerSettingConfigInput,
  ContractSettingType,
} from '@web-panel/api'
import { FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { packageSettingToInput } from './helpers'

type InputSelectSettingTypeProps = {
  title: string
  packageSettings: ContractPackageSetting[]
  currentSetting: ContractPartnerSettingConfigInput | null
  orderLabel?: boolean
  allowNullSetting?: boolean
  nullSettingDisabled?: boolean
  resourceName: string
  errors: {
    amount?: string
    price?: string
  }
  onChange: (setting: ContractPartnerSettingConfigInput | null) => void
}
export const InputSelectSettingType: FC<InputSelectSettingTypeProps> = ({
  title,
  orderLabel,
  packageSettings,
  currentSetting,
  allowNullSetting,
  nullSettingDisabled,
  resourceName,
  errors,
  onChange,
}) => {
  const { formatMessage } = useIntl()

  if (!currentSetting && !allowNullSetting) return null

  const availableTypes = packageSettings.map(({ settingType }) => settingType)

  const handleOnChangeType = (type: string) => {
    if (type === 'none') {
      onChange(null)
      return
    }

    const newSetting = packageSettings.find(({ settingType }) => settingType === type)
    if (!newSetting) return

    onChange(packageSettingToInput(newSetting))
  }

  const onEditCurrentSetting = (updateFields: Partial<ContractPartnerSettingConfigInput>) => {
    if (!currentSetting) return
    onChange({ ...currentSetting, ...updateFields })
  }

  return (
    <div>
      <div className="flex flex-row gap-16 items-center">
        <div className="min-w-100">
          <Typography fontWeight="500">{title}</Typography>
        </div>
        <div>
          <RadioGroup
            row
            value={currentSetting?.settingType ?? 'none'}
            onChange={(e) => handleOnChangeType(e.target.value as ContractSettingType)}
          >
            <FormControlLabel
              label={formatMessage({ id: 'contract-setting-types.EXCEEDING_AMOUNT_FEE' })}
              value={ContractSettingType.ExceedingAmountFee}
              control={<Radio />}
              disabled={!availableTypes.includes(ContractSettingType.ExceedingAmountFee)}
            />
            <FormControlLabel
              label={formatMessage({ id: 'contract-setting-types.REGULAR_FEE' })}
              value={ContractSettingType.RegularFee}
              control={<Radio />}
              disabled={!availableTypes.includes(ContractSettingType.RegularFee)}
            />
            {allowNullSetting && (
              <FormControlLabel
                label={formatMessage({ id: 'common.labels.not-applicable' })}
                disabled={nullSettingDisabled}
                value="none"
                control={<Radio />}
              />
            )}
          </RadioGroup>
        </div>
      </div>
      {currentSetting && (
        <div className="flex flex-row gap-16 items-end">
          {currentSetting.settingType === ContractSettingType.ExceedingAmountFee ? (
            <>
              <TextField
                type="number"
                variant="standard"
                value={currentSetting.amount}
                onChange={(e) =>
                  onEditCurrentSetting({
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    amount: e.target.value,
                  })
                }
                error={!!errors.amount}
              />
              <Typography>
                <FormattedMessage id="common.labels.included-per-month" />,
              </Typography>
              <TextField
                type="number"
                variant="standard"
                value={currentSetting.price}
                onChange={(e) =>
                  onEditCurrentSetting({
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    price: e.target.value,
                  })
                }
                error={!!errors.price}
              />
              <Typography>
                {currentSetting.currencyCode}&nbsp;
                <FormattedMessage
                  id="common.labels.per-extra"
                  values={{ resource: resourceName }}
                />
              </Typography>
            </>
          ) : (
            <>
              <TextField
                type="number"
                variant="standard"
                value={currentSetting.price}
                onChange={(e) =>
                  onEditCurrentSetting({
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    price: e.target.value,
                  })
                }
                error={!!errors.price}
              />
              <Typography>
                {currentSetting.currencyCode}&nbsp;
                <FormattedMessage
                  id={orderLabel ? 'common.labels.per-order' : 'common.labels.per-item-per-order'}
                />
              </Typography>
            </>
          )}
        </div>
      )}
    </div>
  )
}
