import { FC } from 'react'
import { ProductItem, Batch } from '@web-panel/api'
import { HorizontalTable, HorizontalTableRow, HorizontalTableSection } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { ProductItemHolderReference } from '@local/components/ProductItemHolderReference'
import ProductItemBatchLink from '@local/components/ProductItemBatchLink'

type ProductItemWithBatch = ProductItem & {
  batch: Batch | null
}

type ProductItemInfoTabProps = {
  data: ProductItemWithBatch
}

const ProductItemInfoTab: FC<ProductItemInfoTabProps> = ({ data }) => {
  const { formatMessage } = useIntl()

  return (
    <HorizontalTable>
      <HorizontalTableRow title={formatMessage({ id: 'models.product-item.qr-code' })}>
        {data.qrCode}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.product-item.rfid' })}>
        {data.rfid}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.product-item.brand' })}>
        {data.brand}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.product-item.chip' })}>
        {data.chip}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.product-item.current-holder' })}>
        <ProductItemHolderReference holderType={data.currentHolderType} id={data.currentHolderId} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.product-item.status' })}>
        <FormattedMessage id={`product-item-statuses.${data.status}`} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.product-item.batch' })}>
        {data.batch && <ProductItemBatchLink batch={data.batch} />}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.product-item.batches' })}>
        {data.batches}
      </HorizontalTableRow>
      <HorizontalTableSection title={formatMessage({ id: 'models.product-item.model' })} />
      <HorizontalTableRow title={formatMessage({ id: 'models.product-item-model.id' })}>
        {data.model.id}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.product-item-model.capacity' })}>
        {data.model.capacityValue} {data.model.capacityUnit}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.product-item-model.form' })}>
        <FormattedMessage id={`product-item-model-forms.${data.model.form}`} />
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.product-item-model.model' })}>
        {data.model.model}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.product-item-model.design' })}>
        {data.model.design}
      </HorizontalTableRow>
    </HorizontalTable>
  )
}

export default ProductItemInfoTab
