import { FC } from 'react'
import { Outlet } from '@web-panel/api'
import { Paper, TableGrid } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { OutletAvatar } from '@local/components/OutletAvatar'
import OutletStatusChip from '@local/components/OutletStatusChip'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'

type IndexTableProps = {
  data: Outlet[]
  loading: boolean
}

const IndexTable: FC<IndexTableProps> = ({ data, loading }) => {
  const navigate = useNavigate()
  const { can, cannot } = useRoleAuthorization()
  const { formatMessage } = useIntl()

  return (
    <Paper className="overflow-hidden">
      <TableGrid
        loading={loading}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        collection={data}
        fields={[
          {
            title: formatMessage({ id: 'models.outlet.id' }),
            render: ({ outletNumber }) => outletNumber,
          },
          {
            title: formatMessage({ id: 'models.outlet.location.name' }),
            render: (outlet) => (
              <div className="flex flex-row items-center">
                <OutletAvatar className="mr-16" outlet={outlet} />
                {outlet.location.name}
              </div>
            ),
          },
          {
            title: formatMessage({ id: 'models.outlet.name' }),
            render: ({ firstName, lastName }) => [firstName, lastName].join(' '),
          },
          {
            title: formatMessage({ id: 'models.outlet.name' }),
            render: ({ firstName, lastName }) => [firstName, lastName].join(' '),
          },
          {
            title: formatMessage({ id: 'models.outlet.email' }),
            render: ({ email }) => email,
          },
          {
            title: formatMessage({ id: 'models.outlet.status' }),
            render: ({ status }) => <OutletStatusChip status={status} />,
          },
          {
            title: formatMessage({ id: 'models.outlet.type' }),
            render: ({ type }) => <FormattedMessage id={`outlet-types.${type}`} />,
          },
        ]}
        onClickRow={(outlet) => navigate(outlet.id)}
        rowClickable={can('outlet', 'preview')}
        rowActions={[
          {
            title: formatMessage({ id: 'common.actions.edit' }),
            icon: 'edit',
            onClick: ({ id }) => navigate(`/outlets/${id}/edit`),
            disabled: cannot('outlet', 'update'),
            mainAction: true,
          },
        ]}
      />
    </Paper>
  )
}

export default IndexTable
