import { gql } from '@apollo/client'

const vaultTokenFields = `
  magicToken
`

export type VaultTokenDocumentInput = undefined
export type VaultTokenDocumentResponse = {
  vaultToken: {
    magicToken: string
  }
}

export const VaultTokenDocument = gql`
  mutation VaultToken {
    vaultToken @rest(type: "VaultToken", path: "/integrator/vault/token", method: "GET") {
      ${vaultTokenFields}
    }
  }
`

export type CreateIntegratorBoxOrderDocumentInput = {
  input: {
    boxId: string
    operation?: string
    operationDateFrom?: string
    operationDateTo?: string
    priority?: number
  }
}
export type CreateIntegratorBoxOrderDocumentResponse = {
  createBoxOrder: {
    orderNo: string
  }
}
export const CreateIntegratorBoxOrderDocument = gql`
  mutation CreateBoxOrder($input: any) {
    createBoxOrder(input: $input)
      @rest(type: "BoxOrder", path: "/integrator/orders/boxes", method: "POST") {
      orderNo
    }
  }
`

export type DeleteIntegratorBoxOrderDocumentInput = {
  orderId: string
}
export type DeleteIntegratorBoxOrderDocumentResponse = void
export const DeleteIntegratorBoxOrderDocument = gql`
  mutation DeleteBoxOrder($orderId: String!) {
    deleteBoxOrder(orderId: $orderId)
      @rest(type: "NoBody", path: "/integrator/orders/boxes/{args.orderId}", method: "DELETE") {
      NoBody
    }
  }
`
