import { FC } from 'react'
import { TextField, DatePicker, LabeledSelect, Button } from '@web-panel/shared'
import { Outlet, RentalStatus } from '@web-panel/api'
import { useFormikContext } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import * as Yup from 'yup'
import _addDays from 'date-fns/addDays'

export type FormFields = {
  status: RentalStatus
  startDate: Date
  endDate: Date | null
  amount: number
}

export const validationSchema = Yup.object().shape({
  status: Yup.string().oneOf(Object.values(RentalStatus)).required(),
  startDate: Yup.date().required(),
  endDate: Yup.date().nullable(),
  amount: Yup.number().moreThan(0).required(),
})

type UpdateRentalFormProps = {
  outlet: Outlet | undefined
}

export const UpdateRentalForm: FC<UpdateRentalFormProps> = ({ outlet }) => {
  const { values, errors, setFieldValue, handleChange, handleSubmit } =
    useFormikContext<FormFields>()
  const { formatMessage } = useIntl()

  return (
    <form className="w-full" onSubmit={handleSubmit}>
      <div className="flex flex-col items-left gap-16 max-w-2xl">
        <TextField
          disabled
          InputLabelProps={{ shrink: true }}
          label={formatMessage({ id: 'models.rental.outlet' })}
          value={outlet?.location.name ?? ''}
        />

        <LabeledSelect
          label={formatMessage({ id: 'models.rental.status' })}
          value={values.status}
          options={Object.values(RentalStatus)}
          optionTitle={(status) => formatMessage({ id: `rental-statuses.${status}` })}
          onChange={(e) => setFieldValue('status', e.target.value)}
        />
        <TextField
          type="number"
          label={formatMessage({ id: 'models.rental.amount' })}
          value={values.amount}
          onChange={handleChange('amount')}
          error={!!errors.amount}
        />
        <DatePicker
          label={formatMessage({ id: 'models.rental.start-date' })}
          value={values.startDate}
          onChange={(date) => setFieldValue('startDate', date)}
          error={!!errors.startDate}
        />
        <div className="flex flex-row gap-8">
          <DatePicker
            label={formatMessage({ id: 'models.rental.end-date' })}
            value={values.endDate}
            onChange={(date) => setFieldValue('endDate', date)}
            minDate={_addDays(values.startDate, 1)}
            helperText={formatMessage({ id: 'common.actions.optional-field' })}
            error={!!errors.endDate}
          />
          <Button
            color="error"
            className="mb-19"
            disabled={!values.endDate}
            onClick={() => setFieldValue('endDate', null)}
          >
            <FormattedMessage id="common.actions.delete" />
          </Button>
        </div>
      </div>
    </form>
  )
}
