import { FC, useState, useRef } from 'react'
import { Menu, MenuItem, Icon } from '@mui/material'
import { Button, ButtonProps } from '../Button'

type DropdownMenuItem = {
  key?: string
  title: string
  onClick: () => void
  disabled?: boolean
}

// TODO: refactor to Pick from ButtonProps
export type DropdownButtonProps = Pick<
  ButtonProps,
  'fullWidth' | 'variant' | 'color' | 'size' | 'loading' | 'disabled'
> & {
  title: string
  menu: DropdownMenuItem[]
}

export const DropdownButton: FC<DropdownButtonProps> = ({ title, menu, ...buttonProps }) => {
  const [open, setOpen] = useState(false)
  const anchorEl = useRef(null)

  return (
    <>
      <Button {...buttonProps} ref={anchorEl} onClick={() => setOpen(!open)}>
        {title}
        <Icon>arrow_drop_down</Icon>
      </Button>
      <Menu
        anchorEl={anchorEl.current}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setOpen(false)}
      >
        {menu.map((menuItem, id) => (
          <MenuItem
            key={menuItem.key ?? id}
            disabled={menuItem.disabled}
            onClick={() => {
              setOpen(false)
              menuItem.onClick()
            }}
          >
            {menuItem.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
