import { FC, useState } from 'react'
import { Partner } from '@web-panel/api'
import { Autocomplete, Box, TextField } from '@web-panel/shared'
import { useDebouncedValue } from 'rooks'
import { usePartners } from '@web-panel/oc-api'
import { AutocompleteInputProps } from './types'

type PartnerAutocompleteInputProps = AutocompleteInputProps<Partner>
const PartnerAutocompleteInput: FC<PartnerAutocompleteInputProps> = ({
  label,
  className,
  fullWidth,
  disabled,
  error,
  value,
  onChange,
}) => {
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebouncedValue(search, 250, {
    initializeWithNull: false,
  })
  const { data: partners = [] } = usePartners({ search: debouncedSearch ?? '' })

  return (
    <Autocomplete
      disablePortal
      className={className}
      fullWidth={fullWidth}
      disabled={disabled}
      options={partners}
      renderInput={(params) => <TextField error={error} {...params} label={label} />}
      getOptionLabel={({ name }) => name}
      renderOption={(props, option) => (
        <Box {...props} key={option.id} component="li">
          {option.name}
        </Box>
      )}
      onChange={(_data, val) => onChange(val)}
      onInputChange={(event, val, reason) => {
        if (reason === 'reset' && !val) return
        setSearch(val)
      }}
      value={value}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      filterOptions={(x) => x}
    />
  )
}

export default PartnerAutocompleteInput
