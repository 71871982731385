import { gql } from '@apollo/client'
import { Sequence, sequenceFields } from '@web-panel/api'

export type SequencesDocumentInput = {
  limit: number
  offset: number
  search?: string
}

export type SequencesDocumentResponse = {
  sequencesCollection: {
    shipmentSequences: Sequence[]
    hasNext: boolean
  }
}

export const SequencesDocument = gql`
  query Sequences($limit: Int!, $offset: Int!, $search: String) {
    sequencesCollection(limit: $limit, offset: $offset, search: $search) @rest(type: "SequencesCollection", path: "/shipment-service/sequences?{args}") {
      shipmentSequences @type(name: "Sequence") {
        ${sequenceFields}
      }
      hasNext
    }
  }
`
