import { useFormikContext } from 'formik'
import { TextField } from '@web-panel/shared'
import { useIntl } from 'react-intl'

export type FormFields = {
  field: string
  value: string
}

export const initialValues: FormFields = {
  field: '',
  value: '',
}

export const PartnerSupplementForm = () => {
  const { values, handleChange, handleSubmit } = useFormikContext<FormFields>()
  const { formatMessage } = useIntl()

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 gap-16 pt-6">
        <TextField
          fullWidth
          label={formatMessage({ id: 'models.partner-supplement.field' })}
          value={values.field}
          onChange={handleChange('field')}
        />
        <TextField
          fullWidth
          label={formatMessage({ id: 'models.partner-supplement.value' })}
          value={values.value}
          onChange={handleChange('value')}
        />
      </div>
    </form>
  )
}
