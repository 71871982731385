import { FC } from 'react'
import { Donation } from '@web-panel/api'
import { FormattedCurrency, Paper, TableGrid, useFormatDate } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { UserLink } from '@local/components/UserLink'
import { useNavigate } from 'react-router-dom'
import DonationStatusChip from '@local/components/DonationStatusChip'

type IndexTableProps = {
  data: Donation[]
  loading: boolean
}

const IndexTable: FC<IndexTableProps> = ({ data, loading }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const navigate = useNavigate()

  return (
    <Paper className="overflow-hidden">
      <TableGrid
        loading={loading}
        collection={data}
        fields={[
          {
            title: formatMessage({ id: 'models.donation.id' }),
            render: ({ externalId }) => externalId,
          },
          {
            title: formatMessage({ id: 'models.donation.consumer' }),
            render: ({ consumerId }) => <UserLink userId={consumerId} />,
          },
          {
            title: formatMessage({ id: 'models.donation.amount' }),
            render: ({ amount, currency }) => (
              <FormattedCurrency value={amount} currency={currency.code} />
            ),
          },
          {
            title: formatMessage({ id: 'models.donation.create-date' }),
            render: ({ createDate }) => formatDate('date', createDate),
          },
          {
            title: formatMessage({ id: 'models.donation.status' }),
            render: ({ status }) => <DonationStatusChip status={status} />,
          },
          {
            title: formatMessage({ id: 'models.donation.donation-organization' }),
            render: ({ donationOrganization }) => donationOrganization,
          },
        ]}
        onClickRow={(donation) => navigate(donation.id)}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
      />
    </Paper>
  )
}

export default IndexTable
