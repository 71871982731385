import { Outlet, Partner, OutletLegalAddress } from '../data-types'

export function getOutletLegalAddress({
  legalName,
  legalCountryCode,
  legalCity,
  legalHouseNumber,
  legalStreet,
  legalPostalCode,
  legalAdditionalAddressLines,
}: Outlet): OutletLegalAddress | null {
  if (
    legalName &&
    legalCountryCode &&
    legalCity &&
    legalHouseNumber &&
    legalStreet &&
    legalPostalCode
  ) {
    return {
      name: legalName,
      countryCode: legalCountryCode,
      city: legalCity,
      houseNumber: legalHouseNumber,
      street: legalStreet,
      postalCode: legalPostalCode,
      legalAdditionalAddressLines: legalAdditionalAddressLines ?? [],
    }
  }

  return null
}

export function getOutletLegalAddressFromPartner(partner: Partner): OutletLegalAddress | null {
  const { city, countryCode, street, houseNumber, postalCode } = partner.invoiceAddress ?? {}
  if (city && countryCode && street && houseNumber && postalCode)
    return {
      name: partner.name,
      city,
      countryCode,
      street,
      houseNumber,
      postalCode,
      legalAdditionalAddressLines: ['', ''],
    }

  return null
}

export function getOutletLegalAddressWithDefault(
  outlet: Outlet,
  partner: Partner
): OutletLegalAddress | null {
  return getOutletLegalAddress(outlet) ?? getOutletLegalAddressFromPartner(partner)
}

export function isOutletHasLegalAddress(outlet: Outlet): boolean {
  return !!getOutletLegalAddress(outlet)
}
