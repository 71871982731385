import { useQuery } from '@apollo/client'
import { CollectionQueryHook, SequenceNode } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  SequenceNodesDocument,
  SequenceNodesDocumentInput,
  SequenceNodesDocumentResponse,
} from '../docs'

export const useSequenceNodes: CollectionQueryHook<
  Omit<SequenceNodesDocumentInput, 'limit' | 'offset'>,
  SequenceNode[]
> = (input, options) => {
  const variables = {
    ...defaultCollectionVariables,
    ...input,
  }
  const { data, loading, refetch, fetchMore } = useQuery<SequenceNodesDocumentResponse>(
    SequenceNodesDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.sequenceNodesCollection.hasNext) return

    const offset = data.sequenceNodesCollection.sequenceNodes.length

    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.sequenceNodesCollection.sequenceNodes,
    loading,
    loadMore,
    hasMore: data?.sequenceNodesCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}
