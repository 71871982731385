import { FC } from 'react'

import { AppPage, CardedContent, Button, useNotifications } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import modelIcons from '@local/model-icons'
import { useCreateAdminUser } from '@web-panel/oc-api'
import AdminUserForm, { FormFields, defaultValues, validationSchema } from './Forms/AdminUserForm'

const CreatePage: FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()
  const { request: createAdmin, loading } = useCreateAdminUser()

  const onSubmit = async (values: FormFields) => {
    try {
      await createAdmin(values)
      navigate(-1)
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-created' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <Formik<FormFields>
      initialValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const headerActionButtons = [
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => formikProps.handleSubmit()}
            disabled={!formikProps.dirty || !formikProps.isValid || loading}
          >
            <FormattedMessage id="common.actions.create" />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            loading={loading}
            icon={modelIcons.adminUser}
            title={formatMessage({ id: 'routes.admin-users-new' })}
            actionButtons={headerActionButtons}
          >
            <CardedContent>
              <AdminUserForm {...formikProps} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export default CreatePage
