import { FC } from 'react'
import { AppPage, Button, CardedContent, TabSections } from '@web-panel/shared'
import { useCity } from '@web-panel/api'
import modelIcons from '@local/model-icons'
import { useIntl, FormattedMessage } from 'react-intl'
import { useParams, useNavigate } from 'react-router-dom'
import { Error404 } from '@local/pages/404'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { GeneralTab } from './Tabs/GeneralTab'
import { CityRefundablesTab } from './Tabs/RefundablesTab'

export const SettingsCityPreviewPage: FC = () => {
  const { formatMessage } = useIntl()
  const { code } = useParams()
  const { data: city, loading } = useCity({ code: code ?? '' })
  const { can } = useRoleAuthorization()
  const navigate = useNavigate()

  if (loading) return null
  if (!city) return <Error404 />

  const headerActionButtons = [
    can(
      'geoCity',
      'update',
      <Button
        fullWidth
        key="edit"
        variant="contained"
        color="secondary"
        onClick={() => navigate(`/settings/cities/${city.code}/edit`)}
      >
        <FormattedMessage
          id="common.actions.edit-with-name"
          values={{ name: formatMessage({ id: 'models.geo-city' }) }}
        />
      </Button>
    ),
  ]

  return (
    <AppPage
      backButton
      icon={modelIcons.settings}
      title={formatMessage({ id: 'routes.settings.cities.preview' }, { name: city.name })}
      actionButtons={headerActionButtons}
    >
      <CardedContent>
        <TabSections
          sections={[
            {
              key: 'general',
              title: formatMessage({ id: 'common.labels.general' }),
              Content: () => <GeneralTab city={city} />,
            },
            {
              key: 'refundables',
              enabled: can('geoCity', 'listRefundables'),
              title: formatMessage({ id: 'models.refundable-product-items' }),
              Content: () => <CityRefundablesTab city={city} />,
            },
          ]}
        />
      </CardedContent>
    </AppPage>
  )
}
