import { FC } from 'react'
import {
  AppPage,
  Button,
  InfinitScroller,
  Paper,
  TableGrid,
  useDebouncedSearch,
  useModalRef,
} from '@web-panel/shared'
import modelIcons from '@local/model-icons'

import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { ContainerStatusChip } from '@local/components/ContainerStatusChip'
import { ContainerCapacityTypeChip } from '@local/components/ContainerCapacityTypeChip'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useNavigate } from 'react-router-dom'
import { PageHeaderSearch } from '@local/components/PageHeaderSearch'
import { ContainerHolderReference } from '@local/components/ContainerHolderReference'
import { useContainers } from '@web-panel/oc-api'
import CreateContainersModal from './Modals/CreateContainersModal'
import { Filters, useFilters, withFilters } from './Filters'

const IndexPage: FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { can } = useRoleAuthorization()
  const createContainersModalRef = useModalRef()
  const { search, onChangeSearch, debouncedSearch } = useDebouncedSearch()
  const { filters } = useFilters()
  const {
    data: containers = [],
    refetch,
    loading,
    loadMore,
  } = useContainers(
    {
      filters: {
        search: debouncedSearch,
        ...filters,
      },
    },
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    }
  )

  const headerActionButtons = [
    can(
      'container',
      'create',
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        onClick={() => createContainersModalRef.current?.open()}
      >
        <FormattedMessage
          id="common.actions.create-with-name"
          values={{ name: formatMessage({ id: 'models.container.plural' }) }}
        />
      </Button>
    ),
  ]

  return (
    <AppPage
      loading={loading}
      icon={modelIcons.container}
      title="Containers"
      actionButtons={headerActionButtons}
      Sidebar={Filters}
      searchComponent={<PageHeaderSearch value={search} onChangeValue={onChangeSearch} />}
    >
      <InfinitScroller onLoadMore={loadMore}>
        <Paper className="overflow-hidden">
          <TableGrid
            collection={containers}
            loading={loading}
            fields={[
              {
                title: formatMessage({ id: 'models.container.qr-code' }),
                render: ({ qrCode }) => qrCode,
              },
              {
                title: formatMessage({ id: 'models.container.status' }),
                render: ContainerStatusChip,
              },
              {
                title: formatMessage({ id: 'models.container.holder' }),
                render: ContainerHolderReference,
              },
              {
                title: formatMessage({ id: 'models.container.capacity-type' }),
                render: ContainerCapacityTypeChip,
              },
              {
                title: formatMessage({ id: 'models.container.capacity' }),
                render: ({ capacity }) => <FormattedNumber value={capacity} />,
              },
            ]}
            rowClickable={can('container', 'preview')}
            onClickRow={({ id }) => navigate(id)}
          />
        </Paper>
      </InfinitScroller>
      <CreateContainersModal ref={createContainersModalRef} onSubmit={refetch} />
    </AppPage>
  )
}

export default pageWithRoleAuthorization(withFilters(IndexPage), ({ can }) =>
  can('container', 'list')
)
