import { FC } from 'react'
import { DefaultInfoPage } from '@web-panel/shared/'
import { useIntl } from 'react-intl'

export const ComingSoonPage: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <div className="flex justify-center flex-auto items-center">
      <div className="mx-24 md:mx-0">
        <DefaultInfoPage
          title={formatMessage({ id: 'coming-soon.title' })}
          message={formatMessage({ id: 'coming-soon.message' })}
        />
      </div>
    </div>
  )
}
