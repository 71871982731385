import { FC, useEffect } from 'react'
import usePlacesAutocomplete, { getGeocode, getLatLng, getDetails } from 'use-places-autocomplete'
import { Autocomplete, TextField } from '@mui/material'
import _includes from 'lodash/includes'
import { WorkingHoursData } from '../WorkingHoursInput/Inputs/types'

export type GoogleAutocompletePlace = {
  name: string
  lat: number
  lng: number
  street: string
  houseNumber: string
  postalCode: string
  googlePlaceId: string
  workingHours: WorkingHoursData[]
}

function getAddressComponent(place: google.maps.GeocoderResult, type: string) {
  const component = place.address_components.find(({ types }) => _includes(types, type))
  return component?.long_name
}

function getWorkingHours(place: google.maps.places.PlaceResult): WorkingHoursData[] {
  if (!place.opening_hours) return []

  return place.opening_hours.periods.reduce<WorkingHoursData[]>((days, { open, close }) => {
    if (!close) return days
    const openTime = `${open.time.slice(0, 2)}:${open.time.slice(2, 4)}:00`
    const closeTime = `${close.time.slice(0, 2)}:${close.time.slice(2, 4)}:00`

    days.push({
      dayOfWeek: open.day,
      openTime,
      closeTime,
    })
    return days
  }, [])
}

type GooglePlacesAutocompleteProps = {
  fullWidth?: boolean
  className?: string
  disabled?: boolean
  label?: string
  country: string
  city: string
  inputValue: string
  error?: boolean
  onSelect: (place: GoogleAutocompletePlace) => void
  onInputValueChange: (value: string) => void
}

export const GooglePlacesAutocomplete: FC<GooglePlacesAutocompleteProps> = ({
  className,
  disabled,
  fullWidth,
  label,
  country,
  city,
  inputValue,
  onSelect,
  onInputValueChange,
  error = false,
}) => {
  const {
    suggestions: { data },
    setValue,
  } = usePlacesAutocomplete({
    debounce: 300,
  })

  useEffect(() => {
    setValue(`${country}, ${city}, ${inputValue}`)
  }, [inputValue])

  const handleOnChange = async (place: google.maps.places.AutocompletePrediction) => {
    const geoResult = (await getGeocode({ address: place.description }))[0]
    const { lat, lng } = await getLatLng(geoResult)
    const details = await getDetails({ placeId: place.place_id, fields: ['opening_hours'] })

    onSelect({
      name: place.structured_formatting.main_text,
      lat,
      lng,
      googlePlaceId: place.place_id,
      street: getAddressComponent(geoResult, 'route') ?? '',
      houseNumber: getAddressComponent(geoResult, 'street_number') ?? '',
      postalCode: getAddressComponent(geoResult, 'postal_code') ?? '',
      workingHours: getWorkingHours(details as google.maps.places.PlaceResult),
    })
  }

  return (
    <Autocomplete
      disabled={disabled}
      fullWidth={fullWidth}
      blurOnSelect
      className={className}
      options={data}
      filterOptions={(x) => x}
      getOptionLabel={({ description }) => description}
      onInputChange={(event, val, reason) => {
        if (reason === 'reset' && !val) return
        onInputValueChange(val)
      }}
      inputValue={inputValue}
      renderInput={(params) => <TextField {...params} error={error} label={label} />}
      onChange={(event, val) => val && handleOnChange(val)}
      isOptionEqualToValue={(options, value) => options.place_id === value.place_id}
    />
  )
}

export default GooglePlacesAutocomplete
