import { VFC } from 'react'
import { Partner, useCountry } from '@web-panel/api'
import { HorizontalTable, HorizontalTableRow, HorizontalTableSection } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { RoleAuthorized } from '@local/components/RoleAuthorized'
import { PartnerSupplementsTable } from '../Tables/PartnerSupplements'

type PaymentSettingsTabProps = {
  partner: Partner
}
export const PaymentSettingsTab: VFC<PaymentSettingsTabProps> = ({ partner }) => {
  const { formatMessage } = useIntl()
  const { data: invoiceCountry } = useCountry({
    code: partner.invoiceAddress?.countryCode ?? '-1',
  })

  return (
    <>
      <HorizontalTable>
        <HorizontalTableSection
          title={formatMessage({ id: 'models.partner.payment-type' })}
          isFirstInTable
        />
        <HorizontalTableRow title={formatMessage({ id: 'models.partner.payment-type' })}>
          <FormattedMessage id={`payment-types.${partner.finance.paymentType}`} />
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.partner.invoice-recipient' })}>
          <FormattedMessage id={`order-invoice-recipient.${partner.invoiceRecipient}`} />
        </HorizontalTableRow>

        <HorizontalTableSection title={formatMessage({ id: 'models.partner.invoice-data' })} />
        <HorizontalTableRow title={formatMessage({ id: 'models.partner.name' })}>
          {partner.name}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.partner.invoice-data.country' })}>
          {invoiceCountry?.name}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.partner.invoice-data.city' })}>
          {partner.invoiceAddress?.city}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.partner.invoice-data.address' })}>
          {partner.invoiceAddress?.street} {partner.invoiceAddress?.houseNumber}
        </HorizontalTableRow>
        <HorizontalTableRow
          title={formatMessage({
            id: 'models.partner.invoice-data.postal-code',
          })}
        >
          {partner.invoiceAddress?.postalCode}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.partner.additional-address' })}>
          {partner.invoiceAddress.addressAddition}
        </HorizontalTableRow>
        <HorizontalTableRow title={formatMessage({ id: 'models.partner.registration-id' })}>
          {partner.registrationId}
        </HorizontalTableRow>
      </HorizontalTable>

      <RoleAuthorized resource="partnerSupplement" action="list">
        <PartnerSupplementsTable partnerId={partner.id} />
      </RoleAuthorized>
    </>
  )
}
