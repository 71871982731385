import { FC } from 'react'
import { PaymentOut } from '@web-panel/api'
import { FormattedCurrency, Paper, TableGrid, useFormatDate } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { PaymentOutStatusChip } from '@local/components/PaymentOutStatusChip'
import { UserLink } from '@local/components/UserLink'

type IndexTableProps = {
  paymentOuts: PaymentOut[]
  loading: boolean
}

const IndexTable: FC<IndexTableProps> = ({ paymentOuts, loading }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()

  return (
    <Paper className="overflow-hidden">
      <TableGrid
        loading={loading}
        collection={paymentOuts}
        fields={[
          {
            title: formatMessage({ id: 'models.payment-out.id' }),
            render: ({ externalPaymentOutId }) => externalPaymentOutId,
          },
          {
            title: formatMessage({ id: 'models.payment-out.consumer' }),
            render: ({ consumerId }) => <UserLink userId={consumerId} />,
          },
          {
            title: formatMessage({ id: 'models.payment-out.amount-to-payout' }),
            render: ({ amountToPayout, currency }) => (
              <FormattedCurrency value={amountToPayout} currency={currency.code} />
            ),
          },
          {
            title: formatMessage({ id: 'models.payment-out.payment-out-status' }),
            render: ({ paymentOutStatus }) => <PaymentOutStatusChip status={paymentOutStatus} />,
          },
          {
            title: formatMessage({ id: 'models.payment-out.payment-out-status' }),
            render: ({ paymentMethodType }) => (
              <FormattedMessage id={`payment-types.${paymentMethodType}`} />
            ),
          },
          {
            title: formatMessage({ id: 'models.payment-out.receipt' }),
            render: ({ receipt }) => (
              <a
                target="_blank"
                href={receipt}
                onClick={(e) => e.stopPropagation()}
                rel="noreferrer"
              >
                <FormattedMessage id="common.actions.download" />
              </a>
            ),
          },
          {
            title: formatMessage({ id: 'models.payment-out.create-date' }),
            render: ({ createDate }) => formatDate('date', createDate),
          },
        ]}
        onClickRow={(paymentOut) => navigate(paymentOut.id)}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
      />
    </Paper>
  )
}

export default IndexTable
