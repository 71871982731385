import { FC } from 'react'

import { AppPage, Button, CardedContent, useNotifications } from '@web-panel/shared'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik } from 'formik'
import modelIcons from '@local/model-icons'
import { useIntl, FormattedMessage } from 'react-intl'
import _includes from 'lodash/includes'
import { useUser, useUpdateUser } from '@web-panel/oc-api'
import { availableRoles } from './Filters'
import { UpdateAdminUserForm, FormFields, validationSchema } from './Forms/UpdateAdminUserForm'

const AdminUsersEditPageComponent: FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()
  const { formatMessage } = useIntl()
  const { data: user, loading: userLoading } = useUser({ id: id ?? '' })
  const { request: updateUser, loading: updateLoading } = useUpdateUser()

  if (userLoading) return null
  if (!user || !_includes(availableRoles, user.role)) return null

  const onSubmit = async (input: FormFields) => {
    try {
      await updateUser({ id: user.id, input })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
      navigate(-1)
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const initialValues: FormFields = {
    firstName: user.firstName ?? '',
    lastName: user.lastName ?? '',
    email: user.email ?? '',
  }

  return (
    <Formik<FormFields>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const headerActionButtons = [
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => formikProps.submitForm()}
            loading={updateLoading}
            disabled={!formikProps.dirty || !formikProps.isValid}
          >
            <FormattedMessage id="common.actions.update" />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            loading={userLoading}
            icon={modelIcons.adminUser}
            title={formatMessage(
              { id: 'routes.admin-users-edit' },
              { name: [user.firstName, user.lastName].join(' ') }
            )}
            actionButtons={headerActionButtons}
          >
            <CardedContent>
              <UpdateAdminUserForm {...formikProps} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export const AdminUsersEditPage = pageWithRoleAuthorization(
  AdminUsersEditPageComponent,
  ({ can }) => can('user', 'update')
)
