import { FC } from 'react'
import { TextField, LabeledSelect } from '@web-panel/shared'
import { UserRole } from '@web-panel/api'
import { FormikProps } from 'formik'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'

const acceptableRoles: UserRole[] = [
  UserRole.Administrator,
  UserRole.OperationsManager,
  UserRole.Driver,
]

export type FormFields = {
  firstName: string
  lastName: string
  email: string
  role: UserRole
}

export const defaultValues: FormFields = {
  firstName: '',
  lastName: '',
  email: '',
  role: UserRole.Administrator,
}

export const validationSchema = Yup.object().shape({
  role: Yup.string().oneOf(acceptableRoles).required(),
  firstName: Yup.string().trim().required(),
  lastName: Yup.string().trim().required(),
  email: Yup.string().trim().email().required(),
})

type AdminUserFormProps = FormikProps<FormFields>

const AdminUserForm: FC<AdminUserFormProps> = ({
  values,
  errors,
  handleSubmit,
  handleChange,
  setFieldValue,
}) => {
  const { formatMessage } = useIntl()

  return (
    <form className="flex w-full" onSubmit={handleSubmit}>
      <div className="flex flex-col flex-1 p-16 sm:p-24 max-w-2xl">
        <LabeledSelect
          label={formatMessage({ id: 'models.user.role' })}
          value={values.role}
          options={acceptableRoles}
          optionTitle={(userRole) => formatMessage({ id: `user-roles.${userRole}` })}
          onChange={(e) => setFieldValue('role', e.target.value)}
        />
        <TextField
          fullWidth
          className="mt-16"
          value={values.firstName}
          onChange={handleChange('firstName')}
          error={!!errors.firstName}
          label={formatMessage({ id: 'models.user.first-name' })}
        />
        <TextField
          fullWidth
          className="mt-16"
          value={values.lastName}
          onChange={handleChange('lastName')}
          error={!!errors.lastName}
          label={formatMessage({ id: 'models.user.last-name' })}
        />
        <TextField
          fullWidth
          className="mt-16"
          value={values.email}
          onChange={handleChange('email')}
          error={!!errors.email}
          label={formatMessage({ id: 'models.user.email' })}
        />
      </div>
    </form>
  )
}

export default AdminUserForm
