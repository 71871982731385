import { FC } from 'react'
import { RentalStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { Chip, ComponentColor } from '@web-panel/shared'

const statusColorMapping: Record<RentalStatus, ComponentColor> = {
  [RentalStatus.Requested]: 'info',
  [RentalStatus.Valid]: 'success',
  [RentalStatus.Rejected]: 'warning',
  [RentalStatus.Deleted]: 'error',
}

type RentalStatusChipProps = {
  status: RentalStatus
}

export const RentalStatusChip: FC<RentalStatusChipProps> = ({ status }) => {
  const { formatMessage } = useIntl()

  const color = statusColorMapping[status] ?? 'default'

  return (
    <Chip
      className="min-w-128"
      color={color}
      label={formatMessage({ id: `rental-statuses.${status}` })}
    />
  )
}
