import { FC } from 'react'
import { RentalConfigurationStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { Chip, ComponentColor } from '@web-panel/shared'

const statusColorMapping: Record<RentalConfigurationStatus, ComponentColor> = {
  [RentalConfigurationStatus.Active]: 'success',
  [RentalConfigurationStatus.Inactive]: 'error',
}

type RentalConfigurationStatusChipProps = {
  status: RentalConfigurationStatus
}

export const RentalConfigurationStatusChip: FC<RentalConfigurationStatusChipProps> = ({
  status,
}) => {
  const { formatMessage } = useIntl()

  const color = statusColorMapping[status] ?? 'default'

  return (
    <Chip
      className="min-w-128"
      color={color}
      label={formatMessage({ id: `rental-configuration-statuses.${status}` })}
    />
  )
}
