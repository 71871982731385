import { FC } from 'react'
import { ContractPackageSetting } from '@web-panel/api'
import { Form, useFormikContext } from 'formik'
import { DatePicker, LabeledSelect, TextField, useAppEnv } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { subMonths, startOfMonth } from 'date-fns'
import * as Yup from 'yup'

export type FormFields = {
  name: string
  description: string
  amount: number
  price: number
  templateId: string
  validationDate: Date
}

export const validationSchema = Yup.object({
  name: Yup.string().required(),
  description: Yup.string().required(),
  amount: Yup.number().min(0).required(),
  price: Yup.number().required(),
  templateId: Yup.string().required(),
  validationDate: Yup.date().required(),
})

type CreateOneTimeContractSettingFormProps = {
  templates: ContractPackageSetting[]
}
export const CreateOneTimeContractSettingForm: FC<CreateOneTimeContractSettingFormProps> = ({
  templates,
}) => {
  const { isAppEnv } = useAppEnv()
  const { values, errors, handleChange, setFieldValue, handleSubmit } =
    useFormikContext<FormFields>()
  const { formatMessage } = useIntl()

  const getTemplateTitle = (id: string) => {
    const temp = templates.find((template) => template.id === id)
    return temp?.contractSettingConfig.name ?? ''
  }

  const showTemplatesSelector = templates.length > 1

  const currentDate = new Date()
  const allowedPreviousMonth = isAppEnv('production')
    ? currentDate.getDate() <= 3
    : currentDate.getDate() <= 6

  const minDate = allowedPreviousMonth ? startOfMonth(subMonths(currentDate, 1)) : currentDate

  return (
    <Form className="p-24 max-w-2xl" onSubmit={handleSubmit}>
      {showTemplatesSelector && (
        <LabeledSelect
          fullWidth
          label={formatMessage({ id: 'models.one-time-contract-setting.configuration' })}
          value={values.templateId}
          options={templates.map(({ id }) => id)}
          optionTitle={getTemplateTitle}
          onChange={(e) => handleChange('contractSettingConfigId')(e.target.value)}
          error={!!errors.templateId}
        />
      )}

      <TextField
        fullWidth
        className={showTemplatesSelector ? 'mt-16' : undefined}
        value={values.name}
        InputLabelProps={{ shrink: true }}
        label={formatMessage({ id: 'models.one-time-contract-setting.name' })}
        placeholder={formatMessage({ id: 'common.labels.fee-name' })}
        onChange={handleChange('name')}
        error={!!errors.name}
      />
      <TextField
        fullWidth
        className="mt-16"
        InputLabelProps={{ shrink: true }}
        value={values.description}
        label={formatMessage({ id: 'models.one-time-contract-setting.description' })}
        placeholder={formatMessage({ id: 'common.labels.fee-description' })}
        onChange={handleChange('description')}
        error={!!errors.description}
      />

      <TextField
        fullWidth
        type="number"
        className="mt-16"
        value={values.amount}
        label={formatMessage({ id: 'models.one-time-contract-setting.amount' })}
        onChange={handleChange('amount')}
        error={!!errors.amount}
      />

      <TextField
        fullWidth
        type="number"
        className="mt-16"
        value={values.price}
        label={formatMessage({ id: 'models.one-time-contract-setting.price' })}
        onChange={handleChange('price')}
        error={!!errors.price}
      />

      <DatePicker
        className="mt-16"
        label={formatMessage({ id: 'models.one-time-contract-setting.validation-date' })}
        value={values.validationDate}
        onChange={(date) => setFieldValue('validationDate', date)}
        minDate={minDate}
        error={!!errors.validationDate}
      />
    </Form>
  )
}
