import { FC } from 'react'
import { AppPage, InfinitScroller } from '@web-panel/shared'
import { useTracking } from '@web-panel/oc-api'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { useParams } from 'react-router-dom'
import modelIcons from '@local/model-icons'
import IndexTable from './Tables/IndexTable'

const IndexPage: FC = () => {
  const params = useParams()
  const {
    data: tracking = [],
    loading,
    loadMore,
  } = useTracking({ labelId: params.id }, { fetchPolicy: 'no-cache' })

  return (
    <AppPage
      backButton
      loading={loading}
      icon={modelIcons.tracking}
      title={`Tracking number: ${params.trackingNumber}`}
    >
      <InfinitScroller onLoadMore={loadMore}>
        <IndexTable data={tracking} loading={loading} />
      </InfinitScroller>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(IndexPage, ({ can }) => can('tracking', 'list'))
