import { FC } from 'react'
import { Container } from '@web-panel/api'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useParams } from 'react-router-dom'
import { AppPage, CardedContent, TabSections } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import modelIcons from '@local/model-icons'
import { PhysicalLabelButton } from '@local/components/PhysicalLabelButton'
import { useContainer } from '@web-panel/oc-api'
import { Error404 } from '../404'
import { generateExternalRoute } from '../ExternalRouter/helpers'
import { ContainersGeneralTab } from './Tabs/General'
import { ContainersBatchesTab } from './Tabs/Batches'
import { ContainersActivityHistoryTab } from './Tabs/ActivityHistory'

type PreviewPageProps = {
  container: Container
}
const PreviewPage: FC<PreviewPageProps> = ({ container }) => {
  const { formatMessage } = useIntl()
  const { can } = useRoleAuthorization()

  const headerActionButtons = [
    <PhysicalLabelButton
      title={formatMessage({ id: 'common.actions.print-physical-label' })}
      printer={{
        title: formatMessage({ id: 'models.container' }),
        subtitle: container.qrCode,
        encodedData: generateExternalRoute('container', container.qrCode),
      }}
    />,
  ]

  return (
    <AppPage
      backButton
      icon={modelIcons.container}
      title={formatMessage({ id: 'routes.containers-preview' }, { name: container.qrCode })}
      actionButtons={headerActionButtons}
    >
      <CardedContent>
        <TabSections
          sections={[
            {
              key: 'general',
              title: formatMessage({ id: 'common.labels.general' }),
              Content: () => <ContainersGeneralTab container={container} />,
              enabled: true,
            },
            {
              key: 'batches',
              title: formatMessage({ id: 'models.batch.plural' }),
              Content: () => <ContainersBatchesTab container={container} />,
              enabled: can('container', 'listBatches'),
            },
            {
              key: 'history',
              title: formatMessage({ id: 'models.container-activity' }),
              Content: () => <ContainersActivityHistoryTab container={container} />,
              enabled: can('container', 'listActivity'),
            },
          ]}
        />
      </CardedContent>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(
  () => {
    const params = useParams()
    const { data: container, loading } = useContainer(
      { id: params.id! },
      { fetchPolicy: 'cache-and-network' }
    )
    if (!container && loading) return null
    if (!container) return <Error404 />

    return <PreviewPage container={container} />
  },
  ({ can }) => can('container', 'preview')
)
