import { FC } from 'react'
import { AppPage, InfinitScroller, useDebouncedSearch } from '@web-panel/shared'
import { useIntl } from 'react-intl'

import modelIcons from '@local/model-icons'
import { PageHeaderSearch } from '@local/components/PageHeaderSearch'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { usePartners } from '@web-panel/oc-api'
import IndexTable from './Tables/IndexTable'

const IndexPage: FC = () => {
  const { search, onChangeSearch, debouncedSearch } = useDebouncedSearch()
  const { formatMessage } = useIntl()
  const {
    data: partners = [],
    loading,
    loadMore,
  } = usePartners({ search: debouncedSearch }, { fetchPolicy: 'cache-and-network' })

  return (
    <AppPage
      loading={loading}
      icon={modelIcons.partner}
      title={formatMessage({ id: 'routes.partners' })}
      searchComponent={<PageHeaderSearch value={search} onChangeValue={onChangeSearch} />}
    >
      <InfinitScroller onLoadMore={loadMore}>
        <IndexTable data={partners} loading={loading} />
      </InfinitScroller>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(IndexPage, ({ can }) => can('partner', 'list'))
