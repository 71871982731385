import { FC, useState } from 'react'
import { Container } from '@web-panel/api'
import { Autocomplete, Box, TextField } from '@web-panel/shared'
import { useDebouncedValue } from 'rooks'
import { useContainers } from '@web-panel/oc-api'
import { AutocompleteInputProps } from './types'

type ContainerAutocompleteInputProps = AutocompleteInputProps<Container> & {
  getOptionDisabled?: (option: Container) => boolean
  filterOptions?: (options: Container[]) => Container[]
}
export const ContainerAutocompleteInput: FC<ContainerAutocompleteInputProps> = ({
  label,
  className,
  fullWidth,
  disabled,
  error,
  value,
  onChange,
  getOptionDisabled,
  filterOptions = (x) => x,
}) => {
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebouncedValue(search, 250, {
    initializeWithNull: false,
  })
  const { data: containers = [] } = useContainers({ filters: { search: debouncedSearch ?? '' } })

  return (
    <Autocomplete
      disablePortal
      className={className}
      fullWidth={fullWidth}
      disabled={disabled}
      options={containers}
      renderInput={(params) => <TextField error={error} {...params} label={label} />}
      getOptionLabel={({ qrCode }) => qrCode}
      getOptionDisabled={getOptionDisabled}
      renderOption={(props, option) => (
        <Box {...props} key={option.id} component="li">
          {option.qrCode}
        </Box>
      )}
      onChange={(_data, val) => onChange(val)}
      onInputChange={(event, val, reason) => {
        if (reason === 'reset' && !val) return
        setSearch(val)
      }}
      value={value}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      filterOptions={filterOptions}
    />
  )
}
