import { FC } from 'react'
import { ContractPartnerSetting, ContractSettingConfigTriggerUnit } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  ConfirmableIconButton,
  Icon,
  IconButton,
  Typography,
  useModalRef,
  useNotifications,
} from '@web-panel/shared'
import { useDeletePartnerContractSetting } from '@web-panel/oc-api'
import { useSettingDescription } from '../helpers/setting-helpers'
import UpdateContractSettingModal from '../Modals/UpdateContractSetting'

type TableSettingRowProps = {
  setting?: ContractPartnerSetting
  title?: string
  resourceName?: string
  onUpdate: () => Promise<void>
}
export const TableSettingRow: FC<TableSettingRowProps> = ({
  setting,
  title,
  resourceName,
  onUpdate,
}) => {
  const modalRef = useModalRef()
  const { pushNotification } = useNotifications()
  const { formatMessage } = useIntl()
  const getSettingDescription = useSettingDescription()
  const { request: deletePartnerContractSetting, loading: loadingDelete } =
    useDeletePartnerContractSetting()

  if (!setting) return <FormattedMessage id="common.labels.not-applicable" />

  const isDeletable = ![
    ContractSettingConfigTriggerUnit.Item,
    ContractSettingConfigTriggerUnit.Consumable,
  ].includes(setting.contractSettingConfig.triggerUnit)

  const handleDelete = async () => {
    try {
      await deletePartnerContractSetting({
        partnerId: setting.partnerId,
        outletId: setting.outletId,
        contractSettingId: setting.id,
      })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-deleted' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <div className="flex flex-row justify-between items-center">
      <Typography variant="body1">{getSettingDescription(setting, resourceName)}</Typography>
      <div className="flex flex-row gap-16">
        {isDeletable && (
          <ConfirmableIconButton
            color="error"
            confirmation={{
              title: formatMessage({ id: 'modals.delete-contract-partner-setting.title' }),
              acceptTitle: formatMessage({ id: 'common.yes' }),
              cancelTitle: formatMessage({ id: 'common.no' }),
            }}
            disabled={loadingDelete}
            onClick={handleDelete}
          >
            <Icon>delete</Icon>
          </ConfirmableIconButton>
        )}
        <IconButton onClick={() => modalRef.current?.open()}>
          <Icon>edit</Icon>
        </IconButton>
        <UpdateContractSettingModal
          setting={setting}
          ref={modalRef}
          heading={title}
          resourceName={resourceName}
          onUpdate={onUpdate}
        />
      </div>
    </div>
  )
}
