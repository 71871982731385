import { FC } from 'react'
import { Partner } from '@web-panel/api'
import {
  HorizontalTable,
  HorizontalTableRow,
  HorizontalTableSection,
  Button,
  ConfirmableButton,
  useModalRef,
  useNotifications,
} from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import PartnerAvatar from '@local/components/PartnerAvatar'
import { useDeletePartnerLogo } from '@web-panel/oc-api'
import UpdatePartnerLogoModal from '../Modals/UpdatePartnerLogo'

type GeneralTabProps = {
  data: Partner
}

export const GeneralTab: FC<GeneralTabProps> = ({ data }) => {
  const { formatMessage } = useIntl()
  const modalRef = useModalRef()
  const { request: deletePartnerLogo, loading: deletePartnerLogoLoading } = useDeletePartnerLogo()
  const { pushNotification } = useNotifications()

  const handleLogoDelete = async () => {
    try {
      await deletePartnerLogo({ id: data.id })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-deleted' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <div className="flex flex-col md:flex-row">
      <div className="mt-16 md:mt-0 md:w-1/3 mb-24 md:mb-0 flex flex-col items-center">
        <PartnerAvatar variant="preview" partner={data} onClick={() => modalRef.current?.open()} />
        <div className="flex flex-row mt-16">
          <Button variant="contained" onClick={() => modalRef.current?.open()}>
            <FormattedMessage id="common.actions.edit" />
          </Button>
          {data.logo && (
            <ConfirmableButton
              className="ml-16"
              variant="contained"
              color="error"
              onClick={handleLogoDelete}
              loading={deletePartnerLogoLoading}
              confirmation={{
                title: formatMessage({ id: 'confirmation-dialogs.delete-logo.title' }),
                message: formatMessage({ id: 'confirmation-dialogs.delete-logo.message' }),
                cancelTitle: formatMessage({ id: 'common.no' }),
                acceptTitle: formatMessage({ id: 'common.yes' }),
              }}
            >
              <FormattedMessage id="common.actions.delete" />
            </ConfirmableButton>
          )}
        </div>
        <UpdatePartnerLogoModal partnerId={data.id} ref={modalRef} />
      </div>
      <div className="flex-1">
        <HorizontalTable>
          <HorizontalTableSection
            isFirstInTable
            title={formatMessage({ id: 'pages.partner-preview.tabs.general.account-info' })}
          />
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.id' })}>
            {data.partnerNumber}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.authorized' })}>
            <FormattedMessage id={data.authorized ? 'common.yes' : 'common.no'} />
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.partner-status' })}>
            <FormattedMessage id={`partner-statuses.${data.partnerStatus}`} />
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.partner.trade-register-excerpt' })}
          >
            {data.tradeRegisterExcerpt && (
              <a href={data.tradeRegisterExcerpt} target="_blank" rel="noreferrer">
                <FormattedMessage id="common.actions.download" />
              </a>
            )}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.registration-id' })}>
            {data.registrationId}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.test' })}>
            <FormattedMessage id={data.test ? 'common.yes' : 'common.no'} />
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.kooky-operated' })}>
            <FormattedMessage id={data.kookyOperated ? 'common.yes' : 'common.no'} />
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.loyalty-enabled' })}>
            <FormattedMessage id={data.loyaltyEnabled ? 'common.yes' : 'common.no'} />
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.partner.delivery-signature-required' })}
          >
            <FormattedMessage id={data.deliverySignatureRequired ? 'common.yes' : 'common.no'} />
          </HorizontalTableRow>

          <HorizontalTableSection
            title={formatMessage({ id: 'pages.partner-preview.tabs.general.contact-details' })}
          />
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.person-name' })}>
            {[data.firstName, data.lastName].join(' ')}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.phone' })}>
            {data.phone}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.email' })}>
            {data.email}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.operations-phone' })}>
            {data.operationsPhone}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.operations-email' })}>
            {data.operationsEmail}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.partner.invoices-email' })}>
            {data.invoicesEmail ?? data.email}
          </HorizontalTableRow>
        </HorizontalTable>
      </div>
    </div>
  )
}
