import { gql } from '@apollo/client'
import { GroupLabel, groupLabelFields, Label, labelFields, SortDirection } from '@web-panel/api'

export type GroupLabelsDocumentInput = {
  limit: number
  offset: number
  search?: string | null
  status?: string
  sortOrder?: SortDirection
  sortBy?: 'UPDATE_STATUS_DATE'
  shipmentSequenceId?: string
}

export type GroupLabelsDocumentResponse = {
  groupLabelsCollection: {
    labels: GroupLabel[]
    hasNext: boolean
  }
}

export const GroupLabelsDocument = gql`
  query GroupLabels($limit: Int!, $offset: Int!, $status: String, $search: String, $sortOrder: String, $sortBy: String, $shipmentSequenceId: String) {
    groupLabelsCollection(limit: $limit, offset: $offset, status: $status, search: $search, sortOrder: $sortOrder, sortBy: $sortBy, shipmentSequenceId: $shipmentSequenceId) @rest(type: "GroupLabelsCollection", path: "/shipment-service/labels/group/label?{args}") {
      labels @type(name: "GroupLabel") {
        ${groupLabelFields}
      }
      hasNext
    }
  }
`
export type GroupLabelLabelsDocumentInput = {
  groupLabelId?: string
}

export type GroupLabelLabelsDocumentResponse = {
  labelsCollection: {
    labels: Label[]
    hasNext: boolean
  }
}

export const GroupLabelLabelsDocument = gql`
  query Labels($limit: Int, $offset: Int, $groupLabelId: String!) {
    labelsCollection(limit: $limit, offset: $offset, groupLabelId: $groupLabelId) @rest(type: "LabelsCollection", path: "/shipment-service/labels/group/label/{args.groupLabelId}/labels?limit={args.limit}&offset={args.offset}") {
      labels @type(name: "Label") {
        ${labelFields}
      }
      hasNext
    }
  }
`

export type GetGroupLabelDocumentInput = {
  id: string
}
export type GetGroupLabelDocumentResponse = {
  groupLabel: GroupLabel
}

export const GetGroupLabelDocument = gql`
  query GroupLabel($id: String!) {
    groupLabel(id: $id) @rest(type: "GroupLabel", path: "/shipment-service/labels/group/label/{args.id}") {
      ${groupLabelFields}
    }
  }
`

export type DeleteGroupLabelDocumentInput = {
  id: string
}
export type DeleteGroupLabelDocumentResponse = void

export const DeleteGroupLabelDocument = gql`
  mutation DeleteGroupLabel($id: String!) {
    deleteGroupLabel(id: $id)
      @rest(
        type: "GroupLabelDeleteResponse"
        path: "/shipment-service/partner/labels/group/{args.id}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`

export type ActivateGroupLabelDocumentInput = {
  input: { groupLabelCode?: string }
}

export type ActivateGroupLabelDocumentResponse = void
export const ActivateGroupLabelDocument = gql`
  mutation ActivateGroupLabel($groupLabelCode: String!, $input: String!) {
    activateGroupLabel(groupLabelCode: $groupLabelCode, input: $input)
      @rest(
        type: "GroupLabelActivateResponse"
        path: "/shipment-service/container/cycle/containerless/activate"
        method: "POST"
      ) {
      NoResponse
    }
  }
`

export type CreateGroupLabelDocumentInput = {
  input: { shipmentSequenceId?: string; numberOfCycles?: number }
}

export type CreateGroupLabelDocumentResponse = void
export const CreateGroupLabelDocument = gql`
  mutation CreateGroupLabel($shipmentSequenceId: String!, $numberOfCycles: Int!, $input: String!) {
    createGroupLabel(
      shipmentSequenceId: $shipmentSequenceId
      numberOfCycles: $numberOfCycles
      input: $input
    )
      @rest(
        type: "GroupLabelCreateResponse"
        path: "/shipment-service/labels/sequence"
        method: "POST"
      ) {
      NoResponse
    }
  }
`
