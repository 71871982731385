import { useMutation, useQuery } from '@apollo/client'
import { CollectionQueryHook, MutationHook, QueryHook } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  CreateBoxRentalDocument,
  CreateBoxRentalDocumentInput,
  CreateBoxRentalDocumentResponse,
  BoxRentalDocument,
  BoxRentalDocumentInput,
  BoxRentalDocumentResponse,
  BoxRentalsDocument,
  BoxRentalsDocumentInput,
  BoxRentalsDocumentResponse,
  UpdateBoxRentalDocument,
  UpdateBoxRentalDocumentInput,
  UpdateBoxRentalDocumentResponse,
} from '../docs'

export const useBoxRentals: CollectionQueryHook<
  BoxRentalsDocumentInput['filters'],
  BoxRentalsDocumentResponse['boxRentals']['inventories']
> = (filters, options) => {
  const pagination = { ...defaultCollectionVariables }
  const variables: BoxRentalsDocumentInput = {
    filters: filters ?? {},
    pagination,
  }

  const { data, loading, fetchMore, refetch } = useQuery<BoxRentalsDocumentResponse>(
    BoxRentalsDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.boxRentals.hasNext) return

    const offset = data.boxRentals.inventories.length
    await fetchMore({
      variables: { variables: { ...variables, pagination: { ...pagination, offset } } },
    })
  }

  return {
    data: data?.boxRentals.inventories,
    hasMore: data?.boxRentals.hasNext ?? false,
    loading,
    loadMore,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useBoxRental: QueryHook<
  BoxRentalDocumentInput,
  BoxRentalDocumentResponse['boxRental']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<BoxRentalDocumentResponse>(BoxRentalDocument, {
    variables,
    ...options,
  })

  return {
    data: data?.boxRental,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useCreateBoxRental: MutationHook<
  CreateBoxRentalDocumentInput['input'],
  CreateBoxRentalDocumentResponse['createBoxRental']
> = (options) => {
  const [execute, { loading }] = useMutation<CreateBoxRentalDocumentResponse>(
    CreateBoxRentalDocument,
    options
  )

  async function request(input: CreateBoxRentalDocumentInput['input']) {
    const { data } = await execute({ variables: { input } })
    if (!data) throw new Error('Could not create rental')

    return data.createBoxRental
  }

  return { loading, request }
}

export const useUpdateBoxRental: MutationHook<
  UpdateBoxRentalDocumentInput,
  UpdateBoxRentalDocumentResponse['updateBoxRental']
> = (options) => {
  const [execute, { loading }] = useMutation<UpdateBoxRentalDocumentResponse>(
    UpdateBoxRentalDocument,
    options
  )

  async function request(variables: UpdateBoxRentalDocumentInput) {
    const { data } = await execute({ variables })
    if (!data) throw new Error('Could not update rental')

    return data.updateBoxRental
  }

  return { loading, request }
}
