import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import _omitBy from 'lodash/omitBy'
import _isNil from 'lodash/isNil'

function getObject(searchParams: URLSearchParams): Record<string, string> {
  const object: Record<string, string> = {}

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of searchParams.entries()) {
    const [param, value] = pair
    object[param] = value
  }

  return object
}

type UseSearchParamsTupl = [
  Record<string, string>,
  (newSearchParams: Record<string, string | undefined>) => void
]

export function useSearchParams(): UseSearchParamsTupl {
  const location = useLocation()
  const searchParams = new URLSearchParams(window.location.search)
  const [params, setParams] = useState<Record<string, string>>(getObject(searchParams))

  const updateSearchParams = (newSearchParams: Record<string, string | undefined>) => {
    const currentParams = getObject(new URLSearchParams(window.location.search))
    const newParams = _omitBy({ ...currentParams, ...newSearchParams }, _isNil) as Record<
      string,
      string
    >
    setParams(newParams)

    const newURLSearchParams = new URLSearchParams(newParams)

    const newPath = `${location.pathname + location.hash}?${newURLSearchParams.toString()}`
    window.history.replaceState(location.state, '', newPath)
  }

  return [params, updateSearchParams]
}
