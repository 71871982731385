import { FC, useState, useRef } from 'react'
import {
  Button,
  Typography,
  Popover,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Icon,
} from '@mui/material'

type MenuItemData = {
  icon?: string
  title: string
  action: () => void
}

type UserMenuProps = {
  userName: string
  userRole?: string
  menu: MenuItemData[]
}

export const UserMenu: FC<UserMenuProps> = ({ userName, userRole, menu }) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const handleMenuClick = ({ action }: MenuItemData) => {
    setMenuOpen(false)
    action()
  }

  return (
    <>
      <Button
        ref={buttonRef}
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <div className="flex flex-col mx-4 items-end">
          <Typography component="span" className="font-semibold flex">
            {userName}
          </Typography>
          {userRole && (
            <Typography className="text-11 font-medium capitalize" color="textSecondary">
              {userRole}
            </Typography>
          )}
        </div>
      </Button>
      <Popover
        open={menuOpen}
        anchorEl={buttonRef.current}
        onClose={() => setMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        {menu.map((item, id) => (
          <MenuItem key={id} onClick={() => handleMenuClick(item)}>
            <ListItemIcon>
              <Icon>{item.icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={item.title} />
          </MenuItem>
        ))}
      </Popover>
    </>
  )
}
