import { VFC } from 'react'
import { TextField, LabeledSelect } from '@web-panel/shared'
import { ConsumerWallet } from '@web-panel/api'
import { currencies } from '@local/constants/currencies'
import { useFormikContext } from 'formik'
import * as yup from 'yup'
import { useIntl } from 'react-intl'

export type FormFields = {
  minimalAmount: number
  currencyCode: string
}

export const validationSchema = yup.object().shape({
  minimalAmount: yup.number().required(),
  currencyCode: yup.string().required(),
})

type UpdateWalletFormProps = {
  wallets: ConsumerWallet[]
}
export const UpdateWalletForm: VFC<UpdateWalletFormProps> = ({ wallets }) => {
  const { formatMessage } = useIntl()
  const { values, setValues, handleChange, handleSubmit } = useFormikContext<FormFields>()

  const handleChangeCurrency = (currencyCode: string) => {
    const wallet = wallets.find(({ currency }) => currency.code === currencyCode)

    setValues({
      currencyCode,
      minimalAmount: wallet?.minimalAmount ?? 0,
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      {currencies.length > 1 && (
        <LabeledSelect
          fullWidth
          label={formatMessage({ id: 'models.wallet.currency' })}
          value={values.currencyCode}
          options={currencies}
          onChange={(e) => handleChangeCurrency(e.target.value)}
        />
      )}
      <TextField
        fullWidth
        className="mt-16"
        label={formatMessage({ id: 'models.wallet.minimal-amount' })}
        value={values.minimalAmount}
        onChange={handleChange('minimalAmount')}
      />
    </form>
  )
}
