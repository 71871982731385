import { Props } from 'react-apexcharts'
import { Outlet, AnalyticAggregationType, AnalyticItemSeriesType } from '@web-panel/api'
import { compareAsc, subYears } from 'date-fns'

import { useNormalizedItemSeries } from '@web-panel/oc-api'

const requestStartDate = subYears(new Date(), 3).toISOString()

type SeriaData = {
  x: Date
  y: number
}[]

export const useSeries = (
  partnerId: string,
  outlets: Outlet[],
  startDate: Date | null,
  cupState: AnalyticItemSeriesType,
  aggregationType: AnalyticAggregationType
): Props['series'] => {
  const outletIds = outlets.map(({ id }) => id)
  const { data = [] } = useNormalizedItemSeries({
    type: cupState,
    input: { partnerId, outletIds, aggregationType, startDate: requestStartDate },
  })

  const series = data.map(({ holderId, metrics }) => {
    const outlet = outlets.find(({ id }) => id === holderId)
    const name = outlet?.location.name ?? holderId

    const seriaData: SeriaData = metrics.reduce<SeriaData>((acc, metric) => {
      if (startDate && compareAsc(metric.date, startDate) < 0) return acc

      return [...acc, { x: metric.date, y: metric.count }]
    }, [])

    return {
      name,
      data: seriaData,
    }
  })

  return series
}
