import { FC } from 'react'
import { FormattedCurrency, TableGrid, Typography } from '@web-panel/shared'
import { Order } from '@web-panel/api'
import { useIntl, FormattedNumber, FormattedMessage } from 'react-intl'

type OrderItemsTabProps = {
  data: Order
}

export const OrderItemsTab: FC<OrderItemsTabProps> = ({ data }) => {
  const { formatMessage } = useIntl()
  const { orderRefundables, orderConsumables, currencyCode } = data

  return (
    <>
      <Typography variant="h6">
        <FormattedMessage id="models.order.order-refundables" />
      </Typography>
      <TableGrid
        collection={orderRefundables}
        fields={[
          {
            title: formatMessage({ id: 'models.order.order-refundables.item-type' }),
            render: ({ model }) => formatMessage({ id: `product-item-types.${model.type}` }),
          },
          {
            title: formatMessage({ id: 'models.order.order-refundables.form' }),
            render: ({ model }) => formatMessage({ id: `product-item-model-forms.${model.form}` }),
          },
          {
            title: formatMessage({ id: 'models.order.order-refundables.design' }),
            render: ({ model }) => model.design,
          },
          {
            title: formatMessage({ id: 'models.order.order-refundables.item-capacity' }),
            render: ({ model }) => (model ? `${model.capacityValue} ${model.capacityUnit}` : '-'),
          },
          {
            title: formatMessage({ id: 'models.order.order-refundables.items-amount' }),
            render: ({ itemsAmount }) => <FormattedNumber value={itemsAmount} />,
          },
          {
            title: formatMessage({ id: 'models.order.order-refundables.item-cost' }),
            render: ({ itemsAmount, itemCost }) => (
              <FormattedCurrency value={itemsAmount * itemCost} currency={currencyCode} />
            ),
          },
          {
            title: formatMessage({
              id: 'models.order.order-refundables.item-service-fee',
            }),
            render: ({ itemsAmount, itemServiceFee }) => (
              <FormattedCurrency value={itemsAmount * itemServiceFee} currency={currencyCode} />
            ),
          },
        ]}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
      />
      <Typography variant="h6" className="mt-24">
        <FormattedMessage id="models.order.order-consumables" />
      </Typography>
      <TableGrid
        collection={orderConsumables}
        fields={[
          {
            title: formatMessage({ id: 'models.order.order-consumables.consumable-type' }),
            render: ({ consumableType }) =>
              formatMessage({ id: `consumable-product-item-types.${consumableType}` }),
          },
          {
            title: formatMessage({ id: 'models.order.order-consumables.consumables-amount' }),
            render: ({ consumablesAmount }) => <FormattedNumber value={consumablesAmount} />,
          },
          {
            title: formatMessage({ id: 'models.order.order-consumables.consumable-cost' }),
            render: ({ consumablesAmount, consumableCost }) => (
              <FormattedCurrency
                value={consumablesAmount * consumableCost}
                currency={currencyCode}
              />
            ),
          },
        ]}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
      />
    </>
  )
}
