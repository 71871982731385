import { FC, useState } from 'react'
import { Button, ModalBody, buildModal, ModalBodyProps } from '@web-panel/shared'
import { Outlet, Dispenser } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'
import { OutletAutocomplete } from '@local/components/Autocomplete/OutletAutocomplete'

import { useAssignDispenserToOutlet } from '@web-panel/oc-api'

type AssignDispenserToOutletModalProps = ModalBodyProps & {
  dispenser: Dispenser
  onSubmit?: () => void
}

const AssignDispenserToOutletModal: FC<AssignDispenserToOutletModalProps> = ({
  dispenser,
  onSubmit,
  onClose,
}) => {
  const { formatMessage } = useIntl()
  const { request: assignDispenserToOutlet } = useAssignDispenserToOutlet()
  const [selectedOutlet, setSelectedOutlet] = useState<Outlet | null>(null)

  const onAssign = async () => {
    if (!selectedOutlet) return

    await assignDispenserToOutlet({
      id: dispenser.id,
      outletId: selectedOutlet.id,
    })
    if (onSubmit) onSubmit()
    setSelectedOutlet(null)
    onClose()
  }

  return (
    <ModalBody
      title={formatMessage({ id: 'modals.assign-dispenser-to-outlet.title' })}
      actions={[
        <Button onClick={onClose} key="cancel-button">
          <FormattedMessage id="common.actions.cancel" />
        </Button>,
        <Button
          variant="contained"
          color="primary"
          onClick={onAssign}
          disabled={!selectedOutlet}
          key="submit-button"
        >
          <FormattedMessage id="common.actions.assign" />
        </Button>,
      ]}
    >
      <OutletAutocomplete
        fullWidth
        className="mt-8"
        label={formatMessage({ id: 'models.box.outlet' })}
        onChange={setSelectedOutlet}
        outletId={selectedOutlet?.id ?? null}
      />
    </ModalBody>
  )
}

export default buildModal(AssignDispenserToOutletModal, { fullWidth: true, maxWidth: 'md' })
