import { FC, useState } from 'react'

import {
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  FileLoadInput,
  useNotifications,
  buildModal,
  ModalBodyProps,
  Link,
} from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useCreateBatchProductItems } from '@web-panel/oc-api'
import { parseCsvFile, ParseResult } from './helpers'

type LoadFromFileModalProps = ModalBodyProps & {
  onSubmit?: () => void | Promise<void>
}

type SubmitError = {
  reason: string
  elements: string[]
}

const LoadFromFileModal: FC<LoadFromFileModalProps> = ({ onSubmit, onClose }) => {
  const { pushNotification } = useNotifications()
  const { formatMessage } = useIntl()
  const [parsedFile, setParsedFile] = useState<ParseResult | null>(null)
  const { request: createProductItems } = useCreateBatchProductItems()
  const [submitError, setSubmitError] = useState<SubmitError | null>(null)

  const handleClose = () => {
    setParsedFile(null)
    onClose()
  }

  const handleFilePick = (files: FileList) => {
    const file = files[0]

    const reader = new FileReader()
    reader.addEventListener('loadend', async (event) => {
      const data = (event.target?.result ?? '') as string
      const result = parseCsvFile(data)
      setParsedFile(result)
    })
    reader.readAsText(file)
  }

  const handleSubmit = async () => {
    setSubmitError(null)
    if (!parsedFile) return
    try {
      await createProductItems({ items: parsedFile.validItems })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-created' })
      )
      if (onSubmit) onSubmit()
      handleClose()
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { errorCode, elements } = err?.networkError?.result ?? {}

      if (errorCode === 5021) {
        setSubmitError({
          reason: 'duplicated',
          elements,
        })
      }

      if (errorCode === 5003)
        setSubmitError({
          reason: 'already_exist',
          elements,
        })

      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const fileLoadState = () => {
    if (!parsedFile) return 'empty'
    if (parsedFile.invalidLines.length > 0) return 'error'

    return 'valid'
  }

  return (
    <>
      <DialogTitle>
        <FormattedMessage id="pages.product-items-new-batch.modals.load-from-file.title" />
      </DialogTitle>
      <DialogContent>
        <div className="flex flex-col items-center">
          <FileLoadInput state={fileLoadState()} onLoad={handleFilePick} accept=".csv" />
          <Typography className="mt-16">
            <FormattedMessage id="common.labels.file-picker-hint" />
          </Typography>
          {parsedFile && (
            <Typography variant="subtitle1" className="mt-16">
              <FormattedMessage
                id="pages.product-items-new-batch.modals.load-from-file.amount-of-items"
                values={{ amount: parsedFile.totalCount }}
              />
            </Typography>
          )}
          {parsedFile && parsedFile.invalidLines.length > 0 && (
            <Typography color="error" className="mt-6">
              <FormattedMessage
                id="pages.product-items-new-batch.modals.load-from-file.invalid-lines"
                values={{ lines: parsedFile.invalidLines.map((id) => id + 1).join(', ') }}
              />
            </Typography>
          )}
          <div className="flex flex-col items-start">
            {submitError && (
              <>
                <Typography color="error" className="mt-6">
                  <FormattedMessage
                    id={`pages.product-items-new-batch.modals.load-from-file.error.${submitError.reason}`}
                    values={{ elements: submitError.elements.join(', ') }}
                  />
                </Typography>
                <ul>
                  {submitError.elements.map((code) => (
                    <li>
                      <Typography color="error">
                        <Link to={`/product-items?search=${code}`} target="_blank">
                          {code}
                        </Link>
                      </Typography>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage id="common.actions.cancel" />
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={!parsedFile || parsedFile.invalidLines.length > 0}
          onClick={() => handleSubmit()}
        >
          <FormattedMessage id="common.actions.add" />
        </Button>
      </DialogActions>
    </>
  )
}

export default buildModal(LoadFromFileModal, { fullWidth: true, maxWidth: 'md' })
