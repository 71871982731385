import { FC } from 'react'
import { Paper, TableGrid } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { Sequence } from '@web-panel/api'
import SequenceTypeChip from '@local/components/SequenceTypeChip'

type IndexTableProps = {
  data: Sequence[]
  loading: boolean
}

const IndexTable: FC<IndexTableProps> = ({ data, loading }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { can } = useRoleAuthorization()
  return (
    <Paper className="overflow-hidden">
      <TableGrid
        loading={loading}
        collection={data}
        fields={[
          {
            title: formatMessage({ id: 'models.shipment-sequences.name' }),
            render: (sequence) => sequence.name,
          },
          {
            title: formatMessage({ id: 'models.shipment-sequences.enabled' }),
            render: (sequence) =>
              sequence.enabled
                ? formatMessage({ id: 'common.yes' })
                : formatMessage({ id: 'common.no' }),
          },
          {
            title: formatMessage({ id: 'models.shipment-sequences.type' }),
            render: (sequence) => sequence.type && <SequenceTypeChip type={sequence.type} />,
          },
        ]}
        onClickRow={(sequence) =>
          navigate(`/sequence-nodes/${sequence.id}/${sequence.name}/${sequence.type}`)
        }
        rowClickable={can('sequenceNodes', 'list')}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
      />
    </Paper>
  )
}

export default IndexTable
