import { FC, useState, useRef } from 'react'
import { Button, SelectableList, Menu, Icon } from '@web-panel/shared'
import { Outlet } from '@web-panel/api'
import { FormattedMessage, useIntl } from 'react-intl'

type OutletsDropdownProps = {
  outlets: Outlet[]
  checked: Outlet[]
  onChange: (outlets: Outlet[]) => void
}

export const OutletsDropdown: FC<OutletsDropdownProps> = ({ outlets, checked, onChange }) => {
  const anchorEl = useRef(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const { formatMessage } = useIntl()

  const buttonLabel =
    checked.length === outlets.length || checked.length === 0
      ? formatMessage({ id: 'labels.all-outlets' })
      : formatMessage({ id: 'models.outlet.plural' }, { count: checked.length })

  return (
    <>
      <Button
        ref={anchorEl}
        variant="contained"
        color="secondary"
        onClick={() => setMenuOpen(true)}
      >
        {buttonLabel}
        <Icon>arrow_drop_down</Icon>
      </Button>
      <Menu
        anchorEl={anchorEl.current}
        open={menuOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setMenuOpen(false)}
      >
        <div className="flex flex-col justify-stretch px-16 py-8">
          <Button
            className="mb-16"
            variant="contained"
            disabled={checked.length === 0}
            onClick={() => onChange([])}
          >
            <FormattedMessage id="common.labels.reset" />
          </Button>
          <SelectableList
            multiple
            options={outlets}
            value={checked}
            optionTitle={(outlet) => `#${outlet.outletNumber} ${outlet.location.name}`}
            compareOption={(option, value) => option.id === value.id}
            onSelect={onChange}
          />
        </div>
      </Menu>
    </>
  )
}
