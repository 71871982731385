import {
  NavigationItemConfig,
  NavigationSectionConfig,
  isFeatureFlagDisabled,
} from '@web-panel/shared'
import { RoleResource, RoleAction } from '@local/components/RoleAuthorized/types'
import modelIcons from '@local/model-icons'
import _compact from 'lodash/compact'

type Authorization<Resource extends RoleResource> = {
  resource: Resource
  action: RoleAction<Resource>
}
export type ItemConfig = NavigationItemConfig & { authorization?: Authorization<RoleResource> }
export type SectionConfig = Omit<NavigationSectionConfig, 'routes'> & { routes: ItemConfig[] }
export type NavigationConfig = SectionConfig[]

export const navigationConfig: NavigationConfig = [
  {
    routes: [
      {
        icon: modelIcons.dashboard,
        translationKey: 'routes.dashboard',
        path: '/',
      },
    ],
  },
  {
    translationKey: 'navigation.sections.customers',
    routes: [
      {
        icon: modelIcons.partner,
        translationKey: 'routes.partners',
        path: '/partners',
        authorization: {
          resource: 'partner',
          action: 'list',
        },
      },
      {
        icon: modelIcons.outlet,
        translationKey: 'routes.outlets',
        path: '/outlets',
        authorization: {
          resource: 'outlet',
          action: 'list',
        },
      },
      {
        icon: modelIcons.order,
        translationKey: 'routes.orders',
        path: '/orders',
        authorization: {
          resource: 'order',
          action: 'list',
        },
      },
    ],
  },
  {
    translationKey: 'navigation.sections.consumers',
    routes: _compact([
      {
        icon: modelIcons.appUser,
        translationKey: 'routes.app-users',
        path: '/app-users',
        authorization: {
          resource: 'user',
          action: 'list',
        },
      },
      isFeatureFlagDisabled('neom') && {
        icon: modelIcons.payoutRequest,
        translationKey: 'routes.payout-requests',
        path: '/payout-requests',
        authorization: {
          resource: 'paymentOut',
          action: 'list',
        },
      },
      isFeatureFlagDisabled('neom') && {
        icon: modelIcons.donation,
        translationKey: 'routes.donations',
        path: '/donations',
        authorization: {
          resource: 'donation',
          action: 'list',
        },
      },
    ]),
  },
  {
    translationKey: 'navigation.sections.shipments',
    routes: [
      {
        icon: modelIcons.partner,
        translationKey: 'routes.addresses',
        path: '/addresses',
        authorization: {
          resource: 'addresses',
          action: 'list',
        },
      },
      {
        icon: modelIcons.sequences,
        translationKey: 'routes.sequences',
        path: '/sequences',
        authorization: {
          resource: 'sequences',
          action: 'list',
        },
      },
      {
        icon: modelIcons.groupLabel,
        translationKey: 'routes.group-labels',
        path: '/group-labels',
        authorization: {
          resource: 'groupLabels',
          action: 'list',
        },
      },
    ],
  },
  {
    translationKey: 'navigation.sections.inventory',
    routes: [
      {
        icon: modelIcons.productItem,
        translationKey: 'routes.product-items',
        path: '/product-items',
        authorization: {
          resource: 'productItem',
          action: 'list',
        },
      },
      {
        icon: modelIcons.productItemBatch,
        translationKey: 'routes.batches',
        path: '/product-item-batches',
        authorization: {
          resource: 'productItemBatch',
          action: 'list',
        },
      },
      {
        icon: modelIcons.container,
        translationKey: 'routes.containers',
        path: '/containers',
      },
      {
        icon: modelIcons.rental,
        translationKey: 'routes.rentals',
        path: '/rentals',
      },
    ],
  },
  {
    translationKey: 'navigation.sections.infrastructure',
    routes: [
      {
        icon: modelIcons.box,
        translationKey: 'routes.boxes',
        path: '/boxes',
        authorization: {
          resource: 'box',
          action: 'list',
        },
      },
      {
        icon: modelIcons.dispenser,
        translationKey: 'routes.dispensers',
        path: '/dispensers',
        authorization: {
          resource: 'dispenser',
          action: 'list',
        },
      },
    ],
  },
  {
    translationKey: 'navigation.sections.configuration',
    routes: [
      {
        icon: modelIcons.adminUser,
        translationKey: 'routes.admin-users',
        path: '/admin-users',
        authorization: {
          resource: 'user',
          action: 'list',
        },
      },
      {
        icon: modelIcons.settings,
        translationKey: 'routes.settings',
        path: '/settings',
      },
    ],
  },
]
