import { FC } from 'react'
import { Paper, TableGrid } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { SequenceNode } from '@web-panel/api'
import SequenceNodeAddressLink from '@local/components/AddressLink'

type IndexTableProps = {
  data: SequenceNode[]
  loading: boolean
}

const IndexTable: FC<IndexTableProps> = ({ data, loading }) => {
  const { formatMessage } = useIntl()

  return (
    <Paper className="overflow-hidden">
      <TableGrid
        loading={loading}
        collection={data}
        fields={[
          {
            title: formatMessage({
              id: 'models.shipment-sequence-nodes.company',
            }),
            render: (node) => node.partnerAddress.contactDetail.company,
          },
          {
            title: formatMessage({
              id: 'models.shipment-sequence-nodes.address',
            }),
            render: (node) =>
              node.partnerAddress && <SequenceNodeAddressLink address={node.partnerAddress} />,
          },
          {
            title: formatMessage({
              id: 'models.shipment-sequence-nodes.fullname',
            }),
            render: (node) => node.partnerAddress.contactDetail.fullName,
          },
          {
            title: formatMessage({
              id: 'models.shipment-sequence-nodes.phone-number',
            }),
            render: (node) => `+${node.partnerAddress.contactDetail.phone}`,
          },
          {
            title: formatMessage({
              id: 'models.shipment-sequence-nodes.partner-type',
            }),
            render: (node) => node.partnerAddress.partnerType,
          },
        ]}
        rowClickable={false}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
      />
    </Paper>
  )
}

export default IndexTable
