import { FC } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  DefaultErrorPage,
  AppPage,
  CardedContent,
  TabSections,
  Button,
  ConfirmableButton,
  useNotifications,
} from '@web-panel/shared'
import modelIcons from '@local/model-icons'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import { usePartnerEmployee, useDeletePartnerEmployee } from '@web-panel/oc-api'
import GeneralTab from './Tabs/General'
import OutletsTab from './Tabs/Outlets'

const PreviewPage: FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { pushNotification } = useNotifications()
  const { data: employee, loading } = usePartnerEmployee({
    partnerId: params.id ?? '',
    employeeId: params.employeeId ?? '',
  })
  const { request: deleteEmployee, loading: loadingDeletePartner } = useDeletePartnerEmployee()
  const { can } = useRoleAuthorization()

  if (loading) return null
  if (!employee)
    return (
      <DefaultErrorPage
        title={formatMessage({ id: 'errors.404.title' })}
        message={formatMessage({ id: 'errors.404.message' })}
      />
    )

  const onDeleteEmployee = async () => {
    navigate(-1)
    try {
      await deleteEmployee({ partnerId: params.id ?? '', employeeId: params.employeeId ?? '' })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-deleted' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const headerActionButtons = [
    can(
      'partnerEmployee',
      'delete',
      <ConfirmableButton
        fullWidth
        variant="contained"
        color="error"
        onClick={onDeleteEmployee}
        loading={loadingDeletePartner}
        confirmation={{
          title: formatMessage({ id: 'modals.delete-partner-employee.title' }),
          acceptTitle: formatMessage({ id: 'common.yes' }),
          cancelTitle: formatMessage({ id: 'common.no' }),
        }}
      >
        <FormattedMessage id="common.actions.delete" />
      </ConfirmableButton>
    ),
    can(
      'partnerEmployee',
      'update',
      <Button fullWidth variant="contained" color="secondary" onClick={() => navigate('edit')}>
        <FormattedMessage id="common.actions.edit" />
      </Button>
    ),
  ]

  return (
    <AppPage
      backButton
      hideTitleSm
      icon={modelIcons.partnerEmployee}
      title={formatMessage(
        { id: 'routes.partner-employee-preview' },
        { name: [employee.firstName, employee.lastName].join(' ') }
      )}
      actionButtons={headerActionButtons}
    >
      <CardedContent>
        <TabSections
          sections={[
            {
              key: 'general',
              title: formatMessage({ id: 'pages.partner-employee-preview.tabs.general' }),
              Content: () => <GeneralTab employee={employee} />,
            },
            {
              key: 'outlets',
              title: formatMessage({ id: 'pages.partner-employee-preview.tabs.outlets' }),
              badge: employee.outletIds.length > 0 ? employee.outletIds.length : undefined,
              Content: () => <OutletsTab employee={employee} partnerId={params.id ?? ''} />,
            },
          ]}
        />
      </CardedContent>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(PreviewPage, ({ can }) =>
  can('partnerEmployee', 'preview')
)
