import { FC, useState, useMemo } from 'react'
import { Widget } from '@web-panel/shared'
import { Outlet, AnalyticAggregationType } from '@web-panel/api'
import ReactApexChart from 'react-apexcharts'
import { useIntl } from 'react-intl'
import { subDays, startOfDay } from 'date-fns'
import { chartDefaults } from '../chart-defaults'
import { useSeries, useTotalCO2SavedSum } from './use-series'
import { DaysSelector, tabToDaysMap } from '../DaysSelector'
import { AggregationTypeSelector } from '../AggregationTypeSelector'

type CO2WidgetProps = {
  partnerId: string
  outlets: Outlet[]
}

export const CO2Widget: FC<CO2WidgetProps> = ({ partnerId, outlets }) => {
  const [daysToPresent, setDaysToPreset] = useState<string>('3')
  const { formatMessage } = useIntl()
  const [aggregationType, setAggregationType] = useState(AnalyticAggregationType.Cumulative)
  const startDate = useMemo(() => {
    const days = tabToDaysMap[daysToPresent]
    if (!days) return null

    return subDays(startOfDay(new Date()), days)
  }, [daysToPresent])

  const series = useSeries(partnerId, outlets, startDate, aggregationType)
  const totalCO2SavedSum = useTotalCO2SavedSum(partnerId, outlets)

  const widgetButtons = [
    <AggregationTypeSelector selected={aggregationType} onSelect={setAggregationType} />,
    <DaysSelector selected={daysToPresent} onSelect={setDaysToPreset} />,
  ]

  return (
    <Widget
      contentFullWidth
      title={formatMessage(
        { id: 'pages.partner-preview.tabs.dashboard.co2-series-widget.title' },
        { total: totalCO2SavedSum }
      )}
      buttons={widgetButtons}
    >
      <ReactApexChart
        stacked="true"
        type="area"
        options={{
          ...chartDefaults,
          xaxis: {
            ...chartDefaults?.xaxis,
          },
          yaxis: {
            ...chartDefaults?.yaxis,
            labels: {
              formatter: (val) => val.toFixed(2),
            },
          },
        }}
        series={series}
        height={200}
      />
    </Widget>
  )
}
