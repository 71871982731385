import { FC } from 'react'
import { PartnerEmployee } from '@web-panel/api'
import { HorizontalTable, HorizontalTableRow } from '@web-panel/shared'
import { useIntl } from 'react-intl'

type GeneralTabProps = {
  employee: PartnerEmployee
}
const GeneralTab: FC<GeneralTabProps> = ({ employee }) => {
  const { formatMessage } = useIntl()

  return (
    <HorizontalTable>
      <HorizontalTableRow title={formatMessage({ id: 'models.partner-employee.name' })}>
        {[employee.firstName, employee.lastName].join(' ')}
      </HorizontalTableRow>
      <HorizontalTableRow title={formatMessage({ id: 'models.partner-employee.email' })}>
        {employee.email}
      </HorizontalTableRow>
    </HorizontalTable>
  )
}

export default GeneralTab
