import { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { Address } from '@web-panel/api'
import { AppPage, Button, CardedContent, TabSections, useModalRef } from '@web-panel/shared'
import modelIcons from '@local/model-icons'
import { Error404 } from '@local/pages/404'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { useAddress } from '@web-panel/oc-api'
import { GeneralTab } from './Tabs/General'
import { LabelTab } from '../Labels/IndexPage'
import Filters, { useFilters, withFilters } from './Filters'
import { ScheduleTab } from './Tabs/Schedule'
import CreateScheduleModal from './Modals/CreateScheduleModal'

type PreviewPageProps = {
  address: Address
}

const PreviewPage: FC<PreviewPageProps> = ({ address }) => {
  const { formatMessage } = useIntl()
  const { filters } = useFilters()
  const createScheduleModalRef = useModalRef()

  const FilterComponent: FC = useCallback(() => {
    return <Filters />
  }, [])

  const actionButtons = [
    <Button
      fullWidth
      variant="contained"
      color="secondary"
      onClick={() => createScheduleModalRef.current?.open()}
    >
      <FormattedMessage
        id="common.actions.create-with-name"
        values={{
          name: formatMessage({ id: 'pages.address-preview.tabs.schedule' }),
        }}
      />
    </Button>,
  ]

  return (
    <AppPage
      backButton
      icon={modelIcons.address}
      title={`${address.contactDetail.company}`}
      actionButtons={actionButtons}
      Sidebar={FilterComponent}
    >
      <CardedContent>
        <TabSections
          sections={[
            {
              key: 'general',
              title: formatMessage({
                id: 'pages.address-preview.tabs.general',
              }),
              Content: () => <GeneralTab data={address} />,
            },
            {
              key: 'schedule',
              title: formatMessage({
                id: 'pages.address-preview.tabs.schedule',
              }),
              Content: () => <ScheduleTab data={address} />,
            },
            {
              key: 'label',
              title: formatMessage({
                id: 'pages.address-preview.tabs.labels',
              }),
              Content: () => (
                <LabelTab address={address} search={filters.search} status={filters.status} />
              ),
            },
          ]}
        />
      </CardedContent>
      <CreateScheduleModal ref={createScheduleModalRef} partnerShippingAddressId={address.id} />
    </AppPage>
  )
}

export const AddressesPreviewPage = pageWithRoleAuthorization(
  withFilters(() => {
    const params = useParams()
    const { data: address, loading: loadingAddress } = useAddress({
      id: params.id!,
    })

    if (loadingAddress) return null
    if (!address) return <Error404 />

    return <PreviewPage address={address} />
  }),
  ({ can }) => can('addresses', 'preview')
)
