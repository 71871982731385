import { VFC } from 'react'
import {
  AppPage,
  CardedContent,
  HorizontalTable,
  HorizontalTableRow,
  useFormatDate,
  FormattedCurrency,
  TableGrid,
  ConfirmableButton,
  useNotifications,
} from '@web-panel/shared'
import { LoyaltyCampaign, LoyaltyCampaignStatus } from '@web-panel/api'
import modelIcons from '@local/model-icons'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useNavigate, useParams } from 'react-router-dom'
import { Error404 } from '@local/pages/404'
import { FormattedMessage, useIntl } from 'react-intl'
import { LoyaltyCampaignStatusChip } from '@local/components/LoyaltyCampaignStatusChip'
import { OutletLink } from '@local/components/OutletLink'

import { useLoyaltyCampaign, useStopLoyaltyCampaign } from '@web-panel/oc-api'

type PartnerCampaignsPreviewProps = {
  partnerId: string
  campaign: LoyaltyCampaign
}
const PartnerCampaignsPreview: VFC<PartnerCampaignsPreviewProps> = ({ partnerId, campaign }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const { can } = useRoleAuthorization()
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()
  const { request: stopCampaign, loading: loadingStopCampaign } = useStopLoyaltyCampaign()

  const rewardDetails = campaign.rewardDetails[0]
  const { unitCurrencyCode: currencyCode } = rewardDetails

  const handleStopCampaign = async () => {
    try {
      await stopCampaign({ partnerId, campaignId: campaign.id })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
      navigate(-1)
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const headerActionButtons = [
    can(
      'loyaltyCampaign',
      'delete',
      <ConfirmableButton
        fullWidth
        variant="contained"
        color="error"
        disabled={campaign.status !== LoyaltyCampaignStatus.Active}
        confirmation={{
          title: formatMessage({ id: 'modals.stop-campaign.title' }, { name: campaign.name }),
          acceptTitle: formatMessage({ id: 'common.actions.stop-campaign' }),
          acceptColor: 'error',
          cancelTitle: formatMessage({ id: 'common.actions.keep-campaign' }),
          cancelColor: 'primary',
        }}
        loading={loadingStopCampaign}
        onClick={handleStopCampaign}
      >
        <FormattedMessage id="common.actions.stop-campaign" />
      </ConfirmableButton>
    ),
  ]

  return (
    <AppPage
      backButton
      title={campaign.name}
      icon={modelIcons.loaltyCampaign}
      actionButtons={headerActionButtons}
    >
      <CardedContent padded>
        <HorizontalTable>
          <HorizontalTableRow title={formatMessage({ id: 'models.loyalty-campaign.id' })}>
            {campaign.externalId}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.loyalty-campaign.name' })}>
            {campaign.name}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.loyalty-campaign-reward.type' })}>
            <FormattedMessage id={`reward-template-types.${rewardDetails.type}`} />
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.loyalty-campaign-reward.sub-type' })}
          >
            <FormattedMessage id={`reward-template-sub-types.${rewardDetails.subType}`} />
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.loyalty-campaign-reward.provider' })}
          >
            {rewardDetails.provider}
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.loyalty-campaign-reward.function-type' })}
          >
            <FormattedMessage
              id={`loyalty-campaign-function-types.${rewardDetails.functionType}`}
            />
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.loyalty-campaign.start-date' })}>
            {formatDate('human-date', campaign.startDate)}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.loyalty-campaign.end-date' })}>
            {campaign.endDate && formatDate('human-date', campaign.endDate)}
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.loyalty-campaign.valid-till-date' })}
          >
            {rewardDetails.validTillDate && formatDate('human-date', rewardDetails.validTillDate)}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.loyalty-campaign.status' })}>
            <LoyaltyCampaignStatusChip status={campaign.status} />
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.loyalty-campaign.current-cost' })}>
            <FormattedCurrency value={campaign.currentCost} currency={currencyCode} />
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.loyalty-campaign.commited-cost' })}
          >
            <FormattedCurrency value={campaign.committedCost} currency={currencyCode} />
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.loyalty-campaign.reference-value' })}
          >
            <FormattedCurrency value={rewardDetails.referenceValue} currency={currencyCode} />
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.loyalty-campaign.unit-cost' })}>
            <FormattedCurrency value={rewardDetails.unitCost} currency={currencyCode} />
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.loyalty-campaign.items-sold' })}>
            {campaign.itemsSold}
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({
              id: 'models.loyalty-campaign-reward.remaining-number-of-rewards',
            })}
          >
            {rewardDetails.remainingNumberOfRewards}
          </HorizontalTableRow>
          <HorizontalTableRow
            title={formatMessage({ id: 'models.loyalty-campaign.unlock-rule-amount' })}
          >
            {rewardDetails.unlockRuleAmount}
          </HorizontalTableRow>
        </HorizontalTable>
        <TableGrid
          collection={campaign.outletsEarnedRewards}
          fields={[
            {
              title: formatMessage({ id: 'models.loyalty-campaign-earned-reward.outlet' }),
              render: ({ outletId }) => <OutletLink outletId={outletId} />,
            },
            {
              title: formatMessage({
                id: 'models.loyalty-campaign-earned-reward.rewards-earned-count',
              }),
              render: ({ rewardsEarnedCount }) => rewardsEarnedCount,
            },
          ]}
          emptyLabel={formatMessage({ id: 'common.no-data' })}
        />
      </CardedContent>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(
  () => {
    const params = useParams()
    const { data: campaign, loading: loadingCampaign } = useLoyaltyCampaign({
      partnerId: params.id!,
      campaignId: params.campaignId!,
    })

    if (loadingCampaign) return null
    if (!campaign) return <Error404 />

    return <PartnerCampaignsPreview partnerId={params.id!} campaign={campaign} />
  },
  ({ can }) => can('loyaltyCampaign', 'preview')
)
