import { FC } from 'react'
import { AppPage, Button, InfinitScroller, useDebouncedSearch } from '@web-panel/shared'
import { useIntl, FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import modelIcons from '@local/model-icons'
import { PageHeaderSearch } from '@local/components/PageHeaderSearch'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useUsers } from '@web-panel/oc-api'
import IndexTable from './Tables/IndexTable'
import Filters, { useFilters, withFilters } from './Filters'

const IndexPage: FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { filters } = useFilters()
  const { search, onChangeSearch, debouncedSearch } = useDebouncedSearch()
  const { can } = useRoleAuthorization()
  const {
    data: adminUsers = [],
    loading,
    hasMore,
    loadMore,
  } = useUsers(
    {
      ...filters,
      search: debouncedSearch,
    },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )

  const onLoadMore = () => {
    if (!hasMore) return
    loadMore()
  }

  const headerActionButtons = [
    can(
      'user',
      'register',
      <Button fullWidth variant="contained" color="secondary" onClick={() => navigate('create')}>
        <FormattedMessage
          id="common.actions.create-with-name"
          values={{ name: formatMessage({ id: 'models.user' }) }}
        />
      </Button>
    ),
  ]

  return (
    <AppPage
      loading={loading}
      icon={modelIcons.adminUser}
      title={formatMessage({ id: 'routes.admin-users' })}
      actionButtons={headerActionButtons}
      Sidebar={Filters}
      searchComponent={<PageHeaderSearch value={search} onChangeValue={onChangeSearch} />}
    >
      <InfinitScroller onLoadMore={onLoadMore}>
        <IndexTable data={adminUsers} loading={loading} />
      </InfinitScroller>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(withFilters(IndexPage), ({ can }) => can('user', 'list'))
