import { FC } from 'react'
import { Box } from '@web-panel/api'
import { useModalRef } from '@web-panel/shared'
import PreviewTable from '../Tables/PreviewTable'
import OutletAssignmentModal from '../Modals/OutletAssignmentModal'

type GeneralTabProps = {
  box: Box
  onAssignOutlet: () => void
}

const GeneralTab: FC<GeneralTabProps> = ({ box, onAssignOutlet }) => {
  const outletAssignmentModalRef = useModalRef()

  return (
    <>
      <PreviewTable
        data={box}
        onClickAssignOutlet={() => outletAssignmentModalRef.current?.open()}
      />
      <OutletAssignmentModal box={box} ref={outletAssignmentModalRef} onSubmit={onAssignOutlet} />
    </>
  )
}

export default GeneralTab
