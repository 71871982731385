import { FC } from 'react'
import { Address } from '@web-panel/api'
import { Link } from '@web-panel/shared'

import { useAddress } from '@web-panel/oc-api'

type AddressLinkProps = {
  address: Address
}
const AddressLink: FC<AddressLinkProps> = ({ address }) => {
  return (
    <Link to={`/addresses/${address.id}`} onClick={(e) => e.stopPropagation()}>
      {`${address.address} ${address.cityName}`}
    </Link>
  )
}

type LinkWithDataLoadProps = {
  addressId: string
}
const LinkWithDataLoad: FC<LinkWithDataLoadProps> = ({ addressId }) => {
  const { data: address } = useAddress({ id: addressId })
  if (!address) return null

  return <AddressLink address={address} />
}

type GroupLabelAddressLinkProps = AddressLinkProps | LinkWithDataLoadProps

export const GroupLabelAddressLink: FC<GroupLabelAddressLinkProps> = (props) => {
  if ('address' in props) return <AddressLink address={props.address} />

  return <LinkWithDataLoad addressId={props.addressId} />
}

export default GroupLabelAddressLink
