import { FC } from 'react'
import { MapSequenceType, SequenceTypes } from '@web-panel/api'
import { Chip, ComponentColor } from '@web-panel/shared'

const typeColorMapping: Record<SequenceTypes, ComponentColor> = {
  [SequenceTypes.Cycle]: 'success',
  [SequenceTypes.Series]: 'secondary',
}

type SequenceTypeChipProps = {
  type: SequenceTypes
}

const SequenceTypeChip: FC<SequenceTypeChipProps> = ({ type }) => {
  const color = typeColorMapping[type] ?? 'default'
  return <Chip className="min-w-72" color={color} label={MapSequenceType(type)} />
}

export default SequenceTypeChip
