import { FC } from 'react'
import { ProductItemActivityHolderType } from '@web-panel/api'
import { FormattedMessage } from 'react-intl'

type HolderTypeProps = {
  holderType: ProductItemActivityHolderType
}

const HolderType: FC<HolderTypeProps> = ({ holderType }) => {
  return <FormattedMessage id={`product-item-activity-holder-types.${holderType}`} />
}

export default HolderType
