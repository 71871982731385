import { FC } from 'react'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { Outlet } from '@web-panel/api'
import { useNavigate, useParams } from 'react-router-dom'
import { AppPage, Button, CardedContent, useNotifications } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import modelIcons from '@local/model-icons'
import { Formik } from 'formik'
import {
  useOutlet,
  useOneTimeContractPackageTemplates,
  useCreateOneTimeContractSetting,
} from '@web-panel/oc-api'
import { Error404 } from '../404'
import {
  CreateOneTimeContractSettingForm,
  FormFields,
  validationSchema,
} from './Forms/CreateOneTimeContractSettingForm'

type CreateOneTimeSettingPageProps = {
  outlet: Outlet
}

const CreateOneTimeSettingPage: FC<CreateOneTimeSettingPageProps> = ({ outlet }) => {
  const { formatMessage } = useIntl()
  const { pushNotification } = useNotifications()
  const navigate = useNavigate()
  const { data: templates = [], loading } = useOneTimeContractPackageTemplates({
    countryCode: outlet.location.country.code,
  })
  const { request: createOneTimeContractSetting, loading: loadingCreate } =
    useCreateOneTimeContractSetting()

  if (loading) return null

  const initialTemplate = templates[0]

  const onSubmit = async (values: FormFields) => {
    const template = templates.find(({ id }) => id === values.templateId)
    if (!template) return

    const config = template.contractSettingConfig

    try {
      await createOneTimeContractSetting({
        partnerId: outlet.partnerId,
        outletId: outlet.id,
        input: {
          validationDate: values.validationDate.toISOString().split('T')[0],
          price: values.price,
          amount: values.amount,
          name: values.name,
          description: values.description,
          settingType: template.settingType,
          contractSettingConfigId: config.id,
          currencyCode: template.currency.code,
        },
      })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-created' })
      )
      navigate(-1)
    } catch (e) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const initialValues: FormFields = {
    name: '',
    description: '',
    amount: initialTemplate.amount,
    price: initialTemplate.price,
    templateId: initialTemplate.id,
    validationDate: new Date(),
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ isValid, handleSubmit }) => (
        <AppPage
          backButton
          icon={modelIcons.contractPackage}
          title={formatMessage(
            { id: 'routes.outlets.create-one-time-setting' },
            { outlet: outlet.location.name }
          )}
          actionButtons={[
            <Button
              disabled={!isValid}
              loading={loadingCreate}
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => handleSubmit()}
            >
              <FormattedMessage id="common.actions.create" />
            </Button>,
          ]}
        >
          <CardedContent>
            <CreateOneTimeContractSettingForm templates={templates} />
          </CardedContent>
        </AppPage>
      )}
    </Formik>
  )
}

export default pageWithRoleAuthorization(
  () => {
    const params = useParams()
    const { data: outlet, loading } = useOutlet({ id: params.id ?? '' })
    if (loading) return null
    if (!outlet) return <Error404 />

    return <CreateOneTimeSettingPage outlet={outlet} />
  },
  ({ can }) => can('oneTimeContractSetting', 'create')
)
