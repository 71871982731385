import { gql } from '@apollo/client'
import { Schedule, scheduleFields } from '@web-panel/api'

export type SchedulesDocumentInput = {
  partnerShippingAddressId: string
  endPlannedPickUpDateTime?: string
  startPlannedPickUpDateTime?: string
}

export type SchedulesDocumentResponse = {
  schedulesCollection: {
    pickupRequests: Schedule[]
    hasNext: boolean
  }
}

export const SchedulesDocument = gql`
  query Schedules($limit: Int!, $offset: Int!, $partnerShippingAddressId: String, $endPlannedPickUpDateTime: String, $startPlannedPickUpDateTime: String) {
    schedulesCollection(limit: $limit, offset: $offset, partnerShippingAddressId: $partnerShippingAddressId, endPlannedPickUpDateTime: $endPlannedPickUpDateTime, startPlannedPickUpDateTime: $startPlannedPickUpDateTime) @rest(type: "SchedulesCollection", path: "/shipment-service/shipments/pickup?{args}") {
      pickupRequests @type(name: "Schedule") {
        ${scheduleFields}
      }
      hasNext
    }
  }
`

export type GetScheduleDocumentInput = {
  id: string
}
export type GetScheduleDocumentResponse = {
  schedule: Schedule
}

export const GetScheduleDocument = gql`
  query Schedule($id: String!) {
    schedule(id: $id) @rest(type: "Schedule", path: "/shipment-service/partner/schedules/{args.id}") {
      ${scheduleFields}
    }
  }
`

export type DeleteScheduleDocumentInput = {
  pickupRequestId: string
  requestorName?: string
  reason?: string
}
export type DeleteScheduleDocumentResponse = void

export const DeleteScheduleDocument = gql`
  mutation DeleteSchedule($pickupRequestId: String!, $requestorName: String!, $reason: String!) {
    deleteSchedule(
      pickupRequestId: $pickupRequestId
      requestorName: $requestorName
      reason: $reason
    )
      @rest(
        type: "ScheduleDeleteResponse"
        path: "/shipment-service/shipments/pickup/{args.pickupRequestId}?requestorName={args.requestorName}&reason={args.reason}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`

export type CreateScheduleDocumentInput = {
  input: {
    partnerShippingAddressId?: string
    pickupDate?: string
    pickupTime?: string
    pickupClosingTime?: string
    pickUpPackagesNumber?: number
  }
}

export type CreateScheduleDocumentResponse = void
export const CreateScheduleDocument = gql`
  mutation CreateSchedule($shipmentSequenceId: String!, $numberOfCycles: Int!, $input: String!) {
    createGroupLabel(
      shipmentSequenceId: $shipmentSequenceId
      numberOfCycles: $numberOfCycles
      input: $input
    )
      @rest(
        type: "ScheduleCreateResponse"
        path: "/shipment-service/shipments/pickup"
        method: "POST"
      ) {
      NoResponse
    }
  }
`
