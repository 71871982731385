import { FC } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'
import { Partner, PartnerStatus } from '@web-panel/api'
import {
  AppPage,
  CardedContent,
  TabSections,
  ConfirmableButton,
  Button,
  useNotifications,
} from '@web-panel/shared'
import modelIcons from '@local/model-icons'
import { Error404 } from '@local/pages/404'
import { useRoleAuthorization, pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { usePartner, useDeletePartner } from '@web-panel/oc-api'
import { GeneralTab } from './Tabs/General'
import { DashboardTab } from './Tabs/Dashboard'
import OutletsTab from './Tabs/Outlets'
import OrdersTab from './Tabs/Orders'
import EmployeesTab from './Tabs/Employees'
import InvoicesTab from './Tabs/Invoices'
import { PartnersConsumableTab } from './Tabs/ConsumablesTab'
import { PartnerRefundablesTab } from './Tabs/RefundablesTab'
import { PartnerCampaignsTab } from './Tabs/CampaignsTab'
import { RentalsTab } from './Tabs/RentalsTab'
import { PaymentSettingsTab } from './Tabs/PaymentSettingsTab'

type PreviewPageProps = {
  partner: Partner
}

const PreviewPage: FC<PreviewPageProps> = ({ partner }) => {
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()
  const { formatMessage } = useIntl()
  const { request: deletePartner } = useDeletePartner()
  const { can } = useRoleAuthorization()

  const onDeletePartner = async () => {
    try {
      await deletePartner({ id: partner.id })
      navigate('/partners', { replace: true })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-deleted' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const headerActionButtons = [
    can(
      'partner',
      'delete',
      <ConfirmableButton
        fullWidth
        key="save"
        variant="contained"
        color="error"
        onClick={onDeletePartner}
        disabled={partner.partnerStatus === PartnerStatus.Deleted}
        confirmation={{
          title: formatMessage(
            { id: 'confirmation-dialogs.delete-partner' },
            { name: partner.name }
          ),
          acceptTitle: formatMessage({ id: 'common.yes' }),
          cancelTitle: formatMessage({ id: 'common.no' }),
        }}
      >
        <FormattedMessage
          id="common.actions.delete-with-name"
          values={{ name: formatMessage({ id: 'models.partner' }) }}
        />
      </ConfirmableButton>
    ),
    can(
      'partner',
      'update',
      <Button
        fullWidth
        key="edit"
        variant="contained"
        color="secondary"
        onClick={() => navigate('edit')}
      >
        <FormattedMessage
          id="common.actions.edit-with-name"
          values={{ name: formatMessage({ id: 'models.partner' }) }}
        />
      </Button>
    ),
  ]

  return (
    <AppPage
      backButton
      icon={modelIcons.partner}
      title={formatMessage({ id: 'routes.partner-preview' }, { name: partner.name })}
      actionButtons={headerActionButtons}
    >
      <CardedContent>
        <TabSections
          sections={[
            {
              key: 'dashboard',
              title: formatMessage({ id: 'pages.partner-preview.tabs.dashboard' }),
              Content: () => <DashboardTab partner={partner} />,
            },
            {
              key: 'general',
              title: formatMessage({ id: 'pages.partner-preview.tabs.general' }),
              Content: () => <GeneralTab data={partner} />,
            },
            {
              key: 'payment-settings',
              title: formatMessage({ id: 'pages.partner-preview.tabs.payment-settings' }),
              Content: () => <PaymentSettingsTab partner={partner} />,
            },
            {
              key: 'outlets',
              title: formatMessage({ id: 'pages.partner-preview.tabs.outlets' }),
              Content: () => <OutletsTab partner={partner} />,
              enabled: can('outlet', 'list'),
            },
            {
              key: 'orders',
              title: formatMessage({ id: 'pages.partner-preview.tabs.orders' }),
              Content: () => <OrdersTab partner={partner} />,
              enabled: can('order', 'list'),
            },
            {
              key: 'invoices',
              title: formatMessage({ id: 'pages.partner-preview.tabs.invoices' }),
              Content: () => <InvoicesTab partnerId={partner.id} />,
              enabled: can('invoice', 'list'),
            },
            {
              key: 'employees',
              title: formatMessage({ id: 'pages.partner-preview.tabs.employees' }),
              Content: () => <EmployeesTab partnerId={partner.id} />,
              enabled: can('partnerEmployee', 'list'),
            },
            {
              key: 'refundables',
              title: formatMessage({ id: 'pages.partner-preview.tabs.refundables' }),
              Content: () => <PartnerRefundablesTab partnerId={partner.id} />,
              enabled: can('partner', 'listRefundables'),
            },
            {
              key: 'consumables',
              title: formatMessage({ id: 'pages.partner-preview.tabs.consumables' }),
              Content: () => <PartnersConsumableTab partnerId={partner.id} />,
              enabled: can('partnerConsumable', 'list'),
            },
            {
              key: 'campaigns',
              title: formatMessage({ id: 'pages.partner-preview.tabs.loyalty-campaigns' }),
              Content: () => <PartnerCampaignsTab partnerId={partner.id} />,
              enabled: can('loyaltyCampaign', 'list'),
            },
            {
              key: 'rentals',
              title: formatMessage({ id: 'pages.partner-preview.tabs.rentals' }),
              Content: () => <RentalsTab partner={partner} />,
              enabled: can('rental', 'list'),
            },
          ]}
        />
      </CardedContent>
    </AppPage>
  )
}

export const PartnersPreviewPage = pageWithRoleAuthorization(
  () => {
    const params = useParams()
    const { data: partner, loading: loadingPartner } = usePartner({ id: params.id! })

    if (loadingPartner) return null
    if (!partner) return <Error404 />

    return <PreviewPage partner={partner} />
  },
  ({ can }) => can('partner', 'preview')
)
