import { FC } from 'react'
import { Link } from '@web-panel/shared'

import { FormattedMessage } from 'react-intl'

import { useOrder } from '@web-panel/oc-api'

type OrderLinkProps = {
  orderId: string
}

export const OrderLink: FC<OrderLinkProps> = ({ orderId }) => {
  const { data: order } = useOrder({ id: orderId })
  if (!order) return null

  return (
    <Link data-testid="OrderLink" to={`/orders/${order.id}`} onClick={(e) => e.stopPropagation()}>
      <FormattedMessage id="common.order-number" values={{ orderNumber: order.orderNumber }} />
    </Link>
  )
}
