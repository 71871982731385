import { FC } from 'react'

import { Link } from '@web-panel/shared'

import { useProductItem } from '@web-panel/oc-api'

type ProductItemLinkProps = {
  id: string
}

export const ProductItemLink: FC<ProductItemLinkProps> = ({ id }) => {
  const { data } = useProductItem({ id })

  if (!data) return null

  return (
    <Link stopPropogation to={`/product-items/${id}`}>
      {data.qrCode}
    </Link>
  )
}

export default ProductItemLink
