import { FC } from 'react'
import { Typography, Icon } from '@mui/material'
import { useIntl } from 'react-intl'
import { getNavigation } from '../../../registrations'

export type BackButtonProps = {
  backButtonLabel?: string
  onClick: () => void
}

const BackButton: FC<BackButtonProps> = ({ backButtonLabel, onClick }) => {
  const { formatMessage } = useIntl()

  const backLabelKey = getNavigation('defaultBackLabelKey')
  const backLabel = backLabelKey ? formatMessage({ id: backLabelKey }) : backButtonLabel

  return (
    <div className="flex flex-row mb-12">
      <Typography className="flex items-center" role="button" color="inherit" onClick={onClick}>
        <Icon className="text-20">arrow_back</Icon>
        {backLabel && <span className="mx-4 font-medium">{backLabel}</span>}
      </Typography>
    </div>
  )
}

export default BackButton
