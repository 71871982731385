import { FC } from 'react'
import { AppPage, Button, CardedContent, useNotifications } from '@web-panel/shared'

import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { Formik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import modelIcons from '@local/model-icons'
import { Error404 } from '@local/pages/404'
import {
  usePartnerProductItemModelConfiguration,
  useUpdatePartnerProductItemModelConfiguration,
  usePartner,
} from '@web-panel/oc-api'
import { EditRefundableForm, FormFields, validationSchema } from './Forms/EditRefundableForm'

const PartnerRefundableEditPageComponent: FC = () => {
  const { formatMessage } = useIntl()
  const { id, refundableId } = useParams()
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()
  const { data: partner, loading: loadingPartner } = usePartner({ id: id ?? '' })
  const { data: configuration, loading: loadingConfiguration } =
    usePartnerProductItemModelConfiguration({ partnerId: id ?? '', modelId: refundableId ?? '' })
  const { request: updateConfiguration, loading: loadingUpdateConfiguration } =
    useUpdatePartnerProductItemModelConfiguration()

  if (loadingConfiguration || loadingPartner) return null
  if (!configuration || !partner) return <Error404 />

  const initialValues: FormFields = {
    status: configuration.status,
    cost: configuration.cost ?? 0,
    serviceFee: configuration.serviceFee ?? 0,
    refundFeePercent: configuration.refundFeePercent ?? 0,
    payoutFeePercent: configuration.payoutFeePercent ?? 0,
  }

  const onSubmit = async (values: FormFields) => {
    try {
      await updateConfiguration({
        partnerId: partner.id,
        input: {
          itemModelId: configuration.itemModel.id,
          ...values,
        },
      })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-updated' })
      )
      navigate(-1)
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <Formik<FormFields>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formProps) => {
        const actionButtons = [
          <Button
            variant="contained"
            color="secondary"
            disabled={!formProps.dirty || !formProps.isValid}
            loading={loadingUpdateConfiguration}
            onClick={() => formProps.handleSubmit()}
          >
            <FormattedMessage id="common.actions.update" />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            icon={modelIcons.settings}
            title={formatMessage(
              { id: 'routes.partners.refundables.edit' },
              { name: configuration.itemModel.id, partnerName: partner.name }
            )}
            actionButtons={actionButtons}
          >
            <CardedContent>
              <EditRefundableForm {...formProps} currency={configuration.currency ?? {}} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export const PartnerRefundableEditPage = pageWithRoleAuthorization(
  PartnerRefundableEditPageComponent,
  ({ can }) => can('partner', 'updateRefundables')
)
