import { VFC } from 'react'
import { Navigate } from 'react-router-dom'
import { Dispenser } from '@web-panel/api'
import { DefaultErrorPage } from '@web-panel/shared'
import { useIntl } from 'react-intl'

import { useDispenserLastOrder, useDispenser } from '@web-panel/oc-api'

type DispenserLastOrderRouterProps = {
  dispenser: Dispenser
}
const DispenserLastOrderRouter: VFC<DispenserLastOrderRouterProps> = ({ dispenser }) => {
  const { data: lastOrder, loading } = useDispenserLastOrder({ coreId: dispenser.coreId })
  if (loading) return null

  if (!lastOrder) return <Navigate to={`/dispensers/${dispenser.id}`} replace />

  return <Navigate to={`/orders/${lastOrder.id}`} replace />
}

type DispenserRouterProps = {
  dispenserId: string
}

export const DispenserRouter: VFC<DispenserRouterProps> = ({ dispenserId }) => {
  const { formatMessage } = useIntl()
  const { data: dispenser, loading } = useDispenser({ id: dispenserId })

  if (loading) return null
  if (!dispenser)
    return (
      <DefaultErrorPage
        title={formatMessage({ id: 'errors.404.title' })}
        message={formatMessage({ id: 'errors.404.message' })}
      />
    )

  return <DispenserLastOrderRouter dispenser={dispenser} />
}
