import { FC } from 'react'
import { DefaultInfoPage } from '@web-panel/shared'
import { useIntl } from 'react-intl'

const AuthEmail: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <DefaultInfoPage
      title={formatMessage({ id: 'pages.auth-email.title' })}
      message={formatMessage({ id: 'pages.auth-email.message' })}
    />
  )
}

export default AuthEmail
