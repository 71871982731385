import { FC, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useContractPackageSettings } from '@web-panel/oc-api'
import { SubscriptionPricingSection } from './SubscriptionPricingSection'
import { ContractSettingsSectionFields, packageSettingToInput } from './helpers'
import { InventoryAndServiceSection } from './InventoryAndServiceSection'
import { InfrascructurePricing } from './InfrasctructurePricing'
import { groupPackageSettings } from '../../helpers/setting-helpers'
import { ContractFeaturesSection } from './ContractFeaturesSection'

type ContractSettingsSectionProps = {
  countryCode: string
  businessCategoryId: string
  packageCode: string
  overrideSettings: boolean
}

export const SettingsSection: FC<ContractSettingsSectionProps> = ({
  countryCode,
  businessCategoryId,
  packageCode,
  overrideSettings,
}) => {
  const { values, setValues } = useFormikContext<ContractSettingsSectionFields>()

  const { data: contractPackageSettings } = useContractPackageSettings({
    countryCode,
    businessCategoryId,
    packageCode,
  })

  const {
    subscriptionSetting,
    itemSettings,
    consumableSettings,
    deliverySettings,
    boxSettings,
    dispenserSettings,
    features,
  } = groupPackageSettings(contractPackageSettings ?? [])

  useEffect(() => {
    if (!contractPackageSettings || !overrideSettings) return

    const itemSetting = itemSettings[0]
    if (!itemSetting) return
    const { frequency } = itemSetting.contractSettingConfig

    const consumableSetting =
      itemSetting &&
      consumableSettings.find(
        ({ contractSettingConfig }) => contractSettingConfig.frequency === frequency
      )

    const deliverySetting =
      itemSetting &&
      deliverySettings.find(
        ({ contractSettingConfig }) => contractSettingConfig.frequency === frequency
      )

    setValues({
      ...values,
      invoiceFrequency: frequency ?? null,
      subscriptionSetting: subscriptionSetting ? packageSettingToInput(subscriptionSetting) : null,
      itemsSetting: itemSetting ? packageSettingToInput(itemSetting) : null,
      consumablesSetting: consumableSetting ? packageSettingToInput(consumableSetting) : null,
      deliverySetting: deliverySetting ? packageSettingToInput(deliverySetting) : null,
      boxSetting: boxSettings[0] ? packageSettingToInput(boxSettings[0]) : null,
      dispenserSetting: dispenserSettings[0] ? packageSettingToInput(dispenserSettings[0]) : null,
      featureSetting: features ? features.map((feature) => packageSettingToInput(feature)) : null,
    })
  }, [JSON.stringify(contractPackageSettings), businessCategoryId, packageCode, overrideSettings])

  if (!contractPackageSettings) return null

  return (
    <div>
      {subscriptionSetting && <SubscriptionPricingSection packageSetting={subscriptionSetting} />}
      {itemSettings.length > 0 && (
        <InventoryAndServiceSection
          packageCode={packageCode}
          packageItemSettings={itemSettings}
          packageConsumableSettings={consumableSettings}
          deliverySettings={deliverySettings}
        />
      )}
      <InfrascructurePricing
        packageCode={packageCode}
        packageBoxSettings={boxSettings}
        packageDispenserSettings={dispenserSettings}
      />
      {features.length > 0 && <ContractFeaturesSection packageSetting={features} />}
    </div>
  )
}
