import { FC } from 'react'
import { TableRow, TableCell } from '@mui/material'

type HorizontalTableRowProps = {
  title: string
}

export const HorizontalTableRow: FC<HorizontalTableRowProps> = ({ title, children }) => {
  return (
    <TableRow>
      <TableCell variant="head">{title}</TableCell>
      <TableCell>{children}</TableCell>
    </TableRow>
  )
}
