import { gql } from '@apollo/client'
import {
  Outlet,
  outletFields,
  OutletType,
  OutletStatus,
  WorkingHours,
  OutletLegalAddress,
  OutletCycleType,
  OutletSupplement,
  outletSupplementFields,
} from '@web-panel/api'

export type OutletsDocumentInput = {
  limit: number
  offset?: number
  search?: string
  partnerId?: string
}
export type OutletsDocumentResponse = {
  outletsCollection: {
    outlets: Outlet[]
    hasNext: boolean
  }
}
export const OutletsDocument = gql`
  query Outlets($limit: Int!, $offset: Int, $search: String, $partnerId: String) {
    outletsCollection(limit: $limit, offset: $offset, search: $search, partnerId: $partnerId) @rest(type: "OutletsCollection", path: "/partners-service/outlets?{args}") {
      outlets @type(name: "Outlet") {
        ${outletFields}
      }
      hasNext
    }
  }
`

export type GetOutletDocumentInput = {
  id: string
}
export type GetOutletDocumentResponse = {
  outlet: Outlet
}
export const GetOutletDocument = gql`
  query GetOutlet($id: String!) {
    outlet(id: $id) @rest(type: "Outlet", path: "/partners-service/outlets/{args.id}") {
      ${outletFields}
    }
  }
`

export type UpdateOutletDocumentInput = {
  id: string
  input: {
    type: OutletType
    firstName: string
    lastName: string
    phone: string
    email: string
    operationsPhone: string | null
    operationsEmail: string | null
    status: OutletStatus
    location: {
      name: string
      cityCode: string
      street: string
      houseNumber: string
      postalCode: string
      latitude: number
      longitude: number
      workingHours: WorkingHours[]
    }
    legalAddress: OutletLegalAddress | null
    featured: boolean
    batchDefaultSize: number
    hiddenOnMap: boolean
    cycleType: OutletCycleType
  }
}
export type UpdateOutletDocumentResponse = {
  updateOutlet: Outlet
}

export const UpdateOutletDocument = gql`
  mutation UpdateOutlet($id: String!, $input: any) {
    updateOutlet: publish(id: $id, input: $input) @rest(type: "Outlet", path: "/partners-service/outlets/{args.id}", method: "PUT") {
      ${outletFields}
    }
  }
`

export type UpdateOutletLogoDocumentInput = {
  id: string
  input: {
    logo: File
  }
  bodySerializer?: (data: { logo: File }, headers: Headers) => { body: FormData; headers: Headers }
}
export type UpdateOutletLogoDocumentResponse = {
  updateOutletLogo: Outlet
}
export const UpdateOutletLogoDocument = gql`
  mutation UpdateOutletLogo($id: String!, $input: any, $bodySerializer: any) {
    updateOutletLogo: publish(id: $id, input: $input)
      @rest(
        type: "Outlet"
        path: "/partners-service/outlets/{args.id}/logo"
        method: "POST"
        bodySerializer: $bodySerializer
      ) {
      ${outletFields}
    }
  }
`

export type DeleteOutletLogoDocumentInput = {
  id: string
}
export type DeleteOutletLogoDocumentResponse = {
  deleteOutletLogo: Outlet
}

export const DeleteOutletLogoDocument = gql`
  mutation DeleteOutletLogo($id: String!) {
    deleteOutletLogo(id: $id) @rest(type: "Outlet", path: "/partners-service/outlets/{args.id}/logo", method: "DELETE") {
      ${outletFields}
    }
  }
`

export type CreateOutletDocumentInput = {
  input: {
    partnerId: string
    type: OutletType
    firstName: string
    lastName: string
    phone: string
    email: string
    operationsPhone: string | null
    operationsEmail: string | null
    location: {
      name: string
      cityCode: string
      street: string
      houseNumber: string
      postalCode: string
      latitude: number
      longitude: number
      workingHours: WorkingHours[]
    }
    legalAddress: OutletLegalAddress | null
    batchDefaultSize: number
    cycleType: OutletCycleType
  }
}
export type CreateOutletDocumentResponse = {
  createOutlet: Outlet
}
export const CreateOutletDocument = gql`
  mutation CreateOutlet($input: any) {
    createOutlet: publish(input: $input) @rest(type: "Outlet", path: "/partners-service/outlets", method: "POST" ) {
      ${outletFields}
    }
  }
`

export type UserOutletsDocumentInput = {
  userId: string
}
export type UserOutletsDocumentResponse = {
  userOutletsCollection: {
    outlets: Outlet[]
    hasNext: boolean
  }
}
export const UserOutletsDocument = gql`
  query UserOutlets($userId: String!) {
    userOutletsCollection(userId: $userId) @rest(type: "UserOutletsCollection", path: "/partners-service/users/{args.userId}/outlets") {
      outlets @type(name: "Outlet") {
        ${outletFields}
      }
      hasNext
    }
  }
`

export type OutletSuplementsDocumentInput = {
  outletId: string
}
export type OutletSuplementsDocumentResponse = {
  outletSupplements: {
    supplements: OutletSupplement[]
  }
}
export const OutletSupplementsDocument = gql`
  query OutletSupplements($outletId: String!) {
    outletSupplements(outletId: $outletId)
      @rest(type: "OutletSupplementsCollection", path: "/partners-service/outlets/{args.outletId}/supplements") {
      supplements @type(name: "OutletSupplement") {
        ${outletSupplementFields}
      }
    }
  }
`

export type CreateOutletSuplementDocumentInput = {
  outletId: string
  input: {
    field: string
    value: string
  }
}
export type CreateOutletSuplementDocumentResponse = {
  createOutletSupplement: {
    supplements: OutletSupplement[]
  }
}

export const CreateOutletSupplementDocument = gql`
  mutation CreateOutletSupplement($outletId: String!, $input: any) {
    createOutletSupplement(outletId: $outletId, input: $input)
      @rest(
        type: "OutletSupplement"
        path: "/partners-service/outlets/{args.outletId}/supplements"
        method: "POST"
      ) {
        supplements @type(name: "OutletSupplement") {
          ${outletSupplementFields}
        }
    }
  }
`
export type UpdateOutletSupplementDocumentInput = {
  id: string
  outletId: string
  input: {
    field: string
    value: string
  }
}
export type UpdateOutletSupplementDocumentResponse = {
  updateOutletSupplement: {
    supplements: OutletSupplement[]
  }
}

export const UpdateOutletSupplementDocument = gql`
  mutation UpdateOutletSuplement($outletId: String!, $id: String!, $input: any) {
    updateOutletSupplement(outletId: $outletId, id: $id, input: $input)
      @rest(
        type: "OutletSupplement"
        path: "/partners-service/outlets/{args.outletId}/supplements/{args.id}"
        method: "PUT"
      ) {
        supplements @type(name: "OutletSupplement") {
          ${outletSupplementFields}
        }
    }
  }
`

export type DeleteOutletSupplementDocumentInput = {
  id: string
  outletId: string
}
export type DeleteOutletSupplementDocumentResponse = void
export const DeleteOutletSupplementDocument = gql`
  mutation DeleteOutletSuplement($outletId: String!, $id: String!) {
    deleteOutletSuplement(outletId: $outletId, id: $id)
      @rest(
        type: "OutletSupplement"
        path: "/partners-service/outlets/{args.outletId}/supplements/{args.id}"
        method: "DELETE"
      ) {
      NoBody
    }
  }
`
