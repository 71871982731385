import { FC } from 'react'
import { Partner, Outlet, getOutletLegalAddressWithDefault, useCountries } from '@web-panel/api'
import { FormSection, Typography, Button } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

type LegalAddressSectionProps = {
  partner: Partner
  outlet: Outlet
}

export const LegalAddressSection: FC<LegalAddressSectionProps> = ({ partner, outlet }) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { data: countries = [] } = useCountries()

  const legalAddress = getOutletLegalAddressWithDefault(outlet, partner)
  const legalAddressCountry = countries.find(({ code }) => code === legalAddress?.countryCode)

  const changeButton = (
    <Button
      key="change"
      variant="outlined"
      size="small"
      onClick={() => navigate(`/outlets/${outlet.id}/edit`)}
    >
      {formatMessage({ id: 'common.actions.change' })}
    </Button>
  )

  return (
    <FormSection
      title={formatMessage({ id: 'models.outlet.legal-address' })}
      buttons={[changeButton]}
    >
      <Typography variant="body1">
        {legalAddress &&
          [
            `${legalAddress.street} ${legalAddress.houseNumber}`,
            legalAddress.postalCode,
            legalAddress.city,
            legalAddressCountry?.name ?? '',
          ].join(', ')}
      </Typography>
    </FormSection>
  )
}
