import { FC } from 'react'
import { AppPage, CardedContent, Button, useNotifications } from '@web-panel/shared'

import modelIcons from '@local/model-icons'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik } from 'formik'
import { pageWithRoleAuthorization } from '@local/components/RoleAuthorized'
import { useCreatePartnerEmployee } from '@web-panel/oc-api'
import CreatePartnerEmployeeForm, {
  FormFields,
  validationSchema,
  defaultValues,
} from './Forms/CreatePartnerEmployeeForm'

const CreatePage: FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()
  const { request: updateEmployee, loading: loadingUpdateEmployee } = useCreatePartnerEmployee()
  const { formatMessage } = useIntl()

  const onSubmit = async (input: FormFields) => {
    try {
      await updateEmployee({
        partnerId: params.id ?? '',
        input,
      })
      navigate(-1)
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-created' })
      )
    } catch (e) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <Formik<FormFields>
      initialValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formProps) => {
        const headerActionButtons = [
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            loading={loadingUpdateEmployee}
            onClick={() => formProps.handleSubmit()}
            disabled={!formProps.dirty || !formProps.isValid}
          >
            <FormattedMessage id="common.actions.create" />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            hideTitleSm
            icon={modelIcons.partnerEmployee}
            title={formatMessage({ id: 'routes.partner-employee-create' })}
            actionButtons={headerActionButtons}
          >
            <CardedContent>
              <CreatePartnerEmployeeForm {...formProps} partnerId={params.id ?? ''} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export default pageWithRoleAuthorization(CreatePage, ({ can }) => can('partnerEmployee', 'create'))
