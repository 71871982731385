import { useQuery, useMutation } from '@apollo/client'
import _includes from 'lodash/includes'
import {
  Batch,
  ProductItem,
  ProductItemType,
  ProductItemModel,
  ConsumableProductItemType,
  ConsumableProductItem,
  ConsumablePartnerProductItem,
  ProductItemModelConfiguration,
  CollectionQueryHook,
  MutationHook,
  QueryHook,
} from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  ProductItemsDocument,
  ProductItemsDocumentInput,
  ProductItemsDocumentResponse,
  CreateProductItemDocument,
  CreateProductItemDocumentInput,
  CreateProductItemDocumentResponse,
  CreateBatchProductItemsDocument,
  CreateBatchProductItemsDocumentInput,
  CreateBatchProductItemsDocumentResponse,
  GetProductItemDocument,
  GetProductItemDocumentInput,
  GetProductItemDocumentResponse,
  GetProductItemBatchHistoryDocument,
  GetProductItemBatchHistoryDocumentResponse,
  UpdateProductItemDocument,
  UpdateProductItemDocumentInput,
  UpdateProductItemDocumentResponse,
  ConsumerProductItemsDocument,
  ConsumerProductItemsDocumentInput,
  ConsumerProductItemsDocumentResponse,
  ProductItemHistoryDocument,
  ProductItemHistoryDocumentInput,
  ProductItemHistoryDocumentResponse,
  ProductItemAggregationDocument,
  ProductItemAggregationDocumentInput,
  ProductItemAggregationDocumentResponse,
  ProductItemModelTypesDocumentInput,
  ProductItemModelTypesDocumentResponse,
  ProductItemModelTypesDocument,
  ProductItemConsumablesDocumentInput,
  ProductItemConsumablesDocumentResponse,
  ProductItemConsumablesDocument,
  ProductItemPartnerConsumablesDocumentInput,
  ProductItemPartnerConsumablesDocumentResponse,
  ProductItemPartnerConsumablesDocument,
  UpdateProductItemConsumableDocumentInput,
  UpdateProductItemConsumableDocumentResponse,
  UpdateProductItemConsumableDocument,
  CreateProductItemConsumableDocumentInput,
  CreateProductItemConsumableDocumentResponse,
  CreateProductItemConsumableDocument,
  DeleteProductItemConsumableDocumentInput,
  DeleteProductItemConsumableDocumentResponse,
  DeleteProductItemConsumableDocument,
  UpdateProductItemPartnerConsumableDocumentInput,
  UpdateProductItemPartnerConsumableDocumentResponse,
  UpdateProductItemPartnerConsumableDocument,
  CreateProductItemPartnerConsumableDocumentInput,
  CreateProductItemPartnerConsumableDocumentResponse,
  CreateProductItemPartnerConsumableDocument,
  ProductItemModelTypeConfigurationsDocumentInput,
  ProductItemModelTypeConfigurationsDocumentResponse,
  ProductItemModelTypeConfigurationsDocument,
  CountryProductItemModelConfigurationsDocument,
  CountryProductItemModelConfigurationsDocumentInput,
  CountryProductItemModelConfigurationsDocumentResponse,
  UpdateCountryProductItemModelConfigurationDocument,
  UpdateCountryProductItemModelConfigurationDocumentInput,
  UpdateCountryProductItemModelConfigurationDocumentResponse,
  CityProductItemModelConfigurationsDocument,
  CityProductItemModelConfigurationsDocumentInput,
  CityProductItemModelConfigurationsDocumentResponse,
  UpdateCityProductItemModelConfigurationDocument,
  UpdateCityProductItemModelConfigurationDocumentInput,
  UpdateCityProductItemModelConfigurationDocumentResponse,
  PartnerProductItemModelConfigurationsDocument,
  PartnerProductItemModelConfigurationsDocumentInput,
  PartnerProductItemModelConfigurationsDocumentResponse,
  UpdatePartnerProductItemModelConfigurationDocument,
  UpdatePartnerProductItemModelConfigurationDocumentInput,
  UpdatePartnerProductItemModelConfigurationDocumentResponse,
  OutletProductItemModelConfigurationsDocument,
  OutletProductItemModelConfigurationsDocumentInput,
  OutletProductItemModelConfigurationsDocumentResponse,
  UpdateOutletProductItemModelConfigurationDocument,
  UpdateOutletProductItemModelConfigurationDocumentInput,
  UpdateOutletProductItemModelConfigurationDocumentResponse,
  ProductBrandsDocumentInput,
  ProductBrandsDocumentResponse,
  ProductBrandsDocument,
} from '../docs'

type UseProductItemsInput = Omit<ProductItemsDocumentInput, 'limit' | 'offset'>

export const useProductItems: CollectionQueryHook<
  UseProductItemsInput,
  ProductItemsDocumentResponse['productItemsCollection']['items']
> = (input, options) => {
  const variables: ProductItemsDocumentInput = { ...defaultCollectionVariables, ...input }

  const { data, loading, fetchMore, refetch } = useQuery<ProductItemsDocumentResponse>(
    ProductItemsDocument,
    { ...options, variables }
  )

  const collection = data?.productItemsCollection

  const loadMore = async () => {
    if (loading || !data || !data.productItemsCollection.hasNext) return

    const offset = data.productItemsCollection.items.length

    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: collection?.items,
    loading,
    loadMore,
    hasMore: data?.productItemsCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useCreateProductItem: MutationHook<
  CreateProductItemDocumentInput,
  ProductItem | null
> = () => {
  const [execute, { loading }] =
    useMutation<CreateProductItemDocumentResponse>(CreateProductItemDocument)

  async function request(input: CreateProductItemDocumentInput): Promise<ProductItem | null> {
    const { data } = await execute({ variables: { input } })
    return data?.createProductItem ?? null
  }

  return { loading, request }
}

export const useCreateBatchProductItems: MutationHook<
  CreateBatchProductItemsDocumentInput,
  CreateBatchProductItemsDocumentResponse['createBatchProductItems']['items']
> = () => {
  const [execute, { loading }] = useMutation<CreateBatchProductItemsDocumentResponse>(
    CreateBatchProductItemsDocument
  )

  async function request(input: CreateBatchProductItemsDocumentInput) {
    const { data } = await execute({ variables: { input } })

    return data!.createBatchProductItems.items
  }

  return { loading, request }
}

export const useProductItem: QueryHook<
  GetProductItemDocumentInput,
  GetProductItemDocumentResponse['productItem']
> = (input, options) => {
  const { data, loading, refetch } = useQuery<GetProductItemDocumentResponse>(
    GetProductItemDocument,
    {
      ...options,
      variables: input,
    }
  )

  return {
    data: data?.productItem,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

type UseProductiItemBatchHistoryInput = {
  id: string
}

export const useProductItemBatchHistory: CollectionQueryHook<
  UseProductiItemBatchHistoryInput,
  Batch[]
> = (input, options) => {
  const { data, loading, fetchMore, refetch } =
    useQuery<GetProductItemBatchHistoryDocumentResponse>(GetProductItemBatchHistoryDocument, {
      ...options,
      variables: {
        ...defaultCollectionVariables,
        ...input,
      },
    })

  const collection = data?.productItemBatchHistory

  const loadMore = async () => {
    if (loading || !data || !data.productItemBatchHistory.hasNext) return

    const offset = data.productItemBatchHistory.batches.length

    await fetchMore({
      variables: {
        ...defaultCollectionVariables,
        ...input,
        offset,
      },
    })
  }

  return {
    data: collection?.batches,
    loading,
    loadMore,
    hasMore: data?.productItemBatchHistory.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useUpdateProductItem: MutationHook<
  UpdateProductItemDocumentInput,
  UpdateProductItemDocumentResponse['updateProductItem'] | null
> = (options) => {
  const [execute, { loading }] = useMutation<
    UpdateProductItemDocumentResponse,
    UpdateProductItemDocumentInput
  >(UpdateProductItemDocument, options)

  const request = async (variables: UpdateProductItemDocumentInput) => {
    const { data } = await execute({ variables })

    return data?.updateProductItem ?? null
  }

  return { request, loading }
}

type UseConsumerProductItemsInput = Omit<ConsumerProductItemsDocumentInput, 'offset' | 'limit'>
export const useConsumerProductItems: CollectionQueryHook<
  UseConsumerProductItemsInput,
  ConsumerProductItemsDocumentResponse['consumerProductItemsCollection']['consumerItems']
> = (input, options) => {
  const variables: ProductItemsDocumentInput = { ...defaultCollectionVariables, ...input }

  const { data, loading, fetchMore, refetch } = useQuery<ConsumerProductItemsDocumentResponse>(
    ConsumerProductItemsDocument,
    { ...options, variables }
  )

  const loadMore = async () => {
    if (loading || !data || !data.consumerProductItemsCollection.hasNext) return

    const offset = data.consumerProductItemsCollection.consumerItems.length

    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.consumerProductItemsCollection.consumerItems,
    loading,
    loadMore,
    hasMore: data?.consumerProductItemsCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

type UseProductItemHistoryInput = Omit<ProductItemHistoryDocumentInput, 'offset' | 'limit'>
export const useProductItemHistory: CollectionQueryHook<
  UseProductItemHistoryInput,
  ProductItemHistoryDocumentResponse['productItemHistory']['itemActivities']
> = (input, options) => {
  const variables: ProductItemsDocumentInput = { ...defaultCollectionVariables, ...input }

  const { data, loading, fetchMore, refetch } = useQuery<ProductItemHistoryDocumentResponse>(
    ProductItemHistoryDocument,
    { ...options, variables }
  )

  const loadMore = async () => {
    if (loading || !data || !data.productItemHistory.hasNext) return

    const offset = data.productItemHistory.itemActivities.length

    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.productItemHistory.itemActivities,
    loading,
    loadMore,
    hasMore: data?.productItemHistory.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useProductItemStatusAggregation: QueryHook<
  ProductItemAggregationDocumentInput,
  ProductItemAggregationDocumentResponse['productItemStatusAggregation']['itemCountByStatuses']
> = (variables) => {
  const { data, loading, refetch } = useQuery<ProductItemAggregationDocumentResponse>(
    ProductItemAggregationDocument,
    { variables }
  )

  return {
    data: data?.productItemStatusAggregation.itemCountByStatuses,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useProductItemModelTypes: QueryHook<
  ProductItemModelTypesDocumentInput,
  ProductItemModelTypesDocumentResponse['productItemModelTypes']['itemModelTypes']
> = (options) => {
  const { data, loading, refetch } = useQuery<ProductItemModelTypesDocumentResponse>(
    ProductItemModelTypesDocument,
    options
  )

  return {
    data: data?.productItemModelTypes.itemModelTypes,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useProductBrands: QueryHook<
  ProductBrandsDocumentInput,
  ProductBrandsDocumentResponse['productBrands']
> = (options) => {
  const { data, loading, refetch } = useQuery<ProductBrandsDocumentResponse>(
    ProductBrandsDocument,
    options
  )

  return {
    data: data?.productBrands,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useProductItemModelTypeConfigurations: QueryHook<
  ProductItemModelTypeConfigurationsDocumentInput,
  ProductItemModelTypeConfigurationsDocumentResponse['productItemModelTypeConfigurations']['itemModelConfigurations']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<ProductItemModelTypeConfigurationsDocumentResponse>(
    ProductItemModelTypeConfigurationsDocument,
    { variables, ...options }
  )

  return {
    data: data?.productItemModelTypeConfigurations.itemModelConfigurations,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useCupModelTypes = (filterCapacity?: number[]) => {
  const { data, loading, refetch } = useProductItemModelTypes()
  let finalModels: ProductItemModel[] = []

  const cupSizes = (data ?? []).find(({ type }) => type === ProductItemType.Cup)
  const models = cupSizes?.models ?? []

  if (filterCapacity) {
    finalModels = models.filter(({ capacityValue }) => _includes(filterCapacity, capacityValue))
  } else {
    finalModels = models
  }

  return {
    data: finalModels,
    loading,
    refetch,
  }
}

export const useProductItemConsumables: QueryHook<
  ProductItemConsumablesDocumentInput,
  ProductItemConsumablesDocumentResponse['productItemConsumables']['consumables']
> = (options) => {
  const { data, loading, refetch } = useQuery<ProductItemConsumablesDocumentResponse>(
    ProductItemConsumablesDocument,
    options
  )

  return {
    data: data?.productItemConsumables.consumables,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useProductItemConsumable: QueryHook<{ id: string }, ConsumableProductItem> = (
  { id },
  options
) => {
  const { data, loading, refetch } = useProductItemConsumables(options)

  const consumable = data?.find((item) => item.id === id)

  return {
    data: consumable,
    loading,
    refetch,
  }
}

export const useProductItemPartnerConsumables: QueryHook<
  ProductItemPartnerConsumablesDocumentInput,
  ProductItemPartnerConsumablesDocumentResponse['productItemPartnerConsumables']['consumables']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<ProductItemPartnerConsumablesDocumentResponse>(
    ProductItemPartnerConsumablesDocument,
    { variables, ...options }
  )

  return {
    data: data?.productItemPartnerConsumables.consumables,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

type ProductItemPartnerConsumableByTypeInput = {
  partnerId: string
  type: ConsumableProductItemType
}

export const useProductItemPartnerConsumableByType = ({
  partnerId,
  type,
}: ProductItemPartnerConsumableByTypeInput) => {
  const {
    data: consumables,
    loading,
    refetch,
  } = useProductItemPartnerConsumables({ partnerId }, { fetchPolicy: 'cache-and-network' })

  const consumable = (consumables ?? []).find(
    ({ consumableType, enabled }) => consumableType === type && enabled !== false
  )

  return {
    data: consumable,
    loading,
    refetch,
  }
}

export const useOriginalProductItemConsumable = (
  partnerConsumable: ConsumablePartnerProductItem
) => {
  const { data, loading } = useProductItemConsumables()
  const consumable = data?.find(
    (item) =>
      item.consumableType === partnerConsumable.consumableType &&
      item.currency.code === partnerConsumable.currency.code
  )

  return {
    data: consumable,
    loading,
  }
}

export const useUpdateProductItemConsumable: MutationHook<
  UpdateProductItemConsumableDocumentInput,
  UpdateProductItemConsumableDocumentResponse['updateProductItemConsumable']
> = (options) => {
  const [execute, { loading }] = useMutation<
    UpdateProductItemConsumableDocumentResponse,
    UpdateProductItemConsumableDocumentInput
  >(UpdateProductItemConsumableDocument, { ...options })

  const request = async (variables: UpdateProductItemConsumableDocumentInput) => {
    const { data } = await execute({ variables })
    if (!data) throw new Error('Could not update consumable')

    return data?.updateProductItemConsumable
  }

  return { request, loading }
}

export const useCreateProductItemConsumable: MutationHook<
  CreateProductItemConsumableDocumentInput,
  CreateProductItemConsumableDocumentResponse['createProductItemConsumable']
> = (options) => {
  const [execute, { loading }] = useMutation<
    CreateProductItemConsumableDocumentResponse,
    CreateProductItemConsumableDocumentInput
  >(CreateProductItemConsumableDocument, { ...options })

  const request = async (variables: CreateProductItemConsumableDocumentInput) => {
    const { data } = await execute({ variables })
    if (!data) throw new Error('Could not create consumable')

    return data?.createProductItemConsumable
  }

  return { request, loading }
}

export const useDeleteProductItemConsumable: MutationHook<
  DeleteProductItemConsumableDocumentInput,
  DeleteProductItemConsumableDocumentResponse
> = (options) => {
  const [execute, { loading }] = useMutation<
    DeleteProductItemConsumableDocumentResponse,
    DeleteProductItemConsumableDocumentInput
  >(DeleteProductItemConsumableDocument, { ...options })

  const request = async (variables: DeleteProductItemConsumableDocumentInput) => {
    const { data } = await execute({
      variables,
      update: (cache) => {
        const consuambleId = cache.identify({
          id: variables.id,
          __typename: 'ConsumableProductItem',
        })
        const partnerConsumableId = cache.identify({
          id: variables.id,
          __typename: 'PartnerConsumableProductItem',
        })
        cache.evict({ id: consuambleId })
        cache.evict({ id: partnerConsumableId })
        cache.gc()
      },
    })
    if (!data) throw new Error('Could not delete consumable')
  }

  return { request, loading }
}

export const useProductItemPartnerConsumable: QueryHook<
  { id: string; partnerId: string },
  ConsumablePartnerProductItem
> = ({ id, partnerId }, options) => {
  const { data, loading, refetch } = useProductItemPartnerConsumables({ partnerId }, options)

  const consumable = data?.find((item) => item.id === id)

  return {
    data: consumable,
    loading,
    refetch,
  }
}

export const useCreateProductItemPartnerConsumable: MutationHook<
  CreateProductItemPartnerConsumableDocumentInput,
  CreateProductItemPartnerConsumableDocumentResponse['createProductItemPartnerConsumable']
> = (options) => {
  const [execute, { loading }] = useMutation<
    CreateProductItemPartnerConsumableDocumentResponse,
    CreateProductItemPartnerConsumableDocumentInput
  >(CreateProductItemPartnerConsumableDocument, options)

  const request = async (variables: CreateProductItemPartnerConsumableDocumentInput) => {
    const { data } = await execute({ variables })
    if (!data) throw new Error('Could not create partner consumable')

    return data.createProductItemPartnerConsumable
  }

  return {
    request,
    loading,
  }
}

export const useUpdateProductItemPartnerConsumable: MutationHook<
  UpdateProductItemPartnerConsumableDocumentInput,
  UpdateProductItemPartnerConsumableDocumentResponse['updateProductItemPartnerConsumable']
> = (options) => {
  const [execute, { loading }] = useMutation<
    UpdateProductItemPartnerConsumableDocumentResponse,
    UpdateProductItemPartnerConsumableDocumentInput
  >(UpdateProductItemPartnerConsumableDocument, { ...options })

  const request = async (variables: UpdateProductItemPartnerConsumableDocumentInput) => {
    const { data } = await execute({ variables })
    if (!data) throw new Error('Could not update partner consumable')

    return data.updateProductItemPartnerConsumable
  }

  return {
    request,
    loading,
  }
}

export const useCountryProductItemModelConfigurations: QueryHook<
  CountryProductItemModelConfigurationsDocumentInput,
  CountryProductItemModelConfigurationsDocumentResponse['countryProductItemModelConfigurations']['itemModelConfigurations']
> = (variables, options) => {
  const { data, loading, refetch } =
    useQuery<CountryProductItemModelConfigurationsDocumentResponse>(
      CountryProductItemModelConfigurationsDocument,
      {
        variables,
        ...options,
      }
    )

  return {
    data: data?.countryProductItemModelConfigurations.itemModelConfigurations,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

type UseCountryProductItemModelConfigurationInput =
  CountryProductItemModelConfigurationsDocumentInput & {
    modelId: string
  }

export const useCountryProductItemModelConfiguration: QueryHook<
  UseCountryProductItemModelConfigurationInput,
  ProductItemModelConfiguration
> = ({ modelId, ...variables }, options) => {
  const {
    data = [],
    loading,
    refetch,
  } = useCountryProductItemModelConfigurations(variables, options)

  const configuration = data.find((item) => item.itemModel.id === modelId)

  return {
    data: configuration,
    loading,
    refetch,
  }
}

export const useUpdateCountryProductItemModelConfiguration: MutationHook<
  UpdateCountryProductItemModelConfigurationDocumentInput,
  UpdateCountryProductItemModelConfigurationDocumentResponse['updateCountryProductItemModelConfiguration']['itemModelConfigurations']
> = (options) => {
  const [execute, { loading }] = useMutation<
    UpdateCountryProductItemModelConfigurationDocumentResponse,
    UpdateCountryProductItemModelConfigurationDocumentInput
  >(UpdateCountryProductItemModelConfigurationDocument, { ...options })

  const request = async (variables: UpdateCountryProductItemModelConfigurationDocumentInput) => {
    const { data } = await execute({
      variables,
      update: (cache) => {
        cache.evict({ fieldName: 'productItemModelTypeConfigurations', broadcast: true })
      },
    })
    if (!data) throw new Error('Could not update product item for country')

    return data.updateCountryProductItemModelConfiguration.itemModelConfigurations
  }

  return {
    request,
    loading,
  }
}

export const useCityProductItemModelConfigurations: QueryHook<
  CityProductItemModelConfigurationsDocumentInput,
  CityProductItemModelConfigurationsDocumentResponse['cityProductItemModelConfigurations']['itemModelConfigurations']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<CityProductItemModelConfigurationsDocumentResponse>(
    CityProductItemModelConfigurationsDocument,
    {
      variables,
      ...options,
    }
  )

  return {
    data: data?.cityProductItemModelConfigurations.itemModelConfigurations,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

type UseCityProductItemModelConfigurationInput = CityProductItemModelConfigurationsDocumentInput & {
  modelId: string
}

export const useCityProductItemModelConfiguration: QueryHook<
  UseCityProductItemModelConfigurationInput,
  ProductItemModelConfiguration
> = ({ modelId, ...variables }, options) => {
  const { data = [], loading, refetch } = useCityProductItemModelConfigurations(variables, options)

  const configuration = data.find((item) => item.itemModel.id === modelId)

  return {
    data: configuration,
    loading,
    refetch,
  }
}

export const useUpdateCityProductItemModelConfiguration: MutationHook<
  UpdateCityProductItemModelConfigurationDocumentInput,
  UpdateCityProductItemModelConfigurationDocumentResponse['updateCityProductItemModelConfiguration']['itemModelConfigurations']
> = (options) => {
  const [execute, { loading }] = useMutation<
    UpdateCityProductItemModelConfigurationDocumentResponse,
    UpdateCityProductItemModelConfigurationDocumentInput
  >(UpdateCityProductItemModelConfigurationDocument, { ...options })

  const request = async (variables: UpdateCityProductItemModelConfigurationDocumentInput) => {
    const { data } = await execute({
      variables,
      update: (cache) => {
        cache.evict({ fieldName: 'productItemModelTypeConfigurations', broadcast: true })
      },
    })
    if (!data) throw new Error('Could not update product item for country')

    return data.updateCityProductItemModelConfiguration.itemModelConfigurations
  }

  return {
    request,
    loading,
  }
}

export const usePartnerProductItemModelConfigurations: QueryHook<
  PartnerProductItemModelConfigurationsDocumentInput,
  PartnerProductItemModelConfigurationsDocumentResponse['partnerProductItemModelConfigurations']['itemModelConfigurations']
> = (variables, options) => {
  const { data, loading, refetch } =
    useQuery<PartnerProductItemModelConfigurationsDocumentResponse>(
      PartnerProductItemModelConfigurationsDocument,
      { variables, ...options }
    )

  return {
    data: data?.partnerProductItemModelConfigurations.itemModelConfigurations,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

type UsePartnerProductItemModelConfigurationInput =
  PartnerProductItemModelConfigurationsDocumentInput & {
    modelId: string
  }

export const usePartnerProductItemModelConfiguration: QueryHook<
  UsePartnerProductItemModelConfigurationInput,
  ProductItemModelConfiguration
> = ({ modelId, ...variables }, options) => {
  const {
    data = [],
    loading,
    refetch,
  } = usePartnerProductItemModelConfigurations(variables, options)

  const configuration = data.find((item) => item.itemModel.id === modelId)

  return {
    data: configuration,
    loading,
    refetch,
  }
}

export const useUpdatePartnerProductItemModelConfiguration: MutationHook<
  UpdatePartnerProductItemModelConfigurationDocumentInput,
  UpdatePartnerProductItemModelConfigurationDocumentResponse['updatePartnerProductItemModelConfiguration']['itemModelConfigurations']
> = (options) => {
  const [execute, { loading }] = useMutation<
    UpdatePartnerProductItemModelConfigurationDocumentResponse,
    UpdatePartnerProductItemModelConfigurationDocumentInput
  >(UpdatePartnerProductItemModelConfigurationDocument, { ...options })

  const request = async (variables: UpdatePartnerProductItemModelConfigurationDocumentInput) => {
    const { data } = await execute({
      variables,
      update: (cache) => {
        cache.evict({ fieldName: 'productItemModelTypeConfigurations', broadcast: true })
      },
    })
    if (!data) throw new Error('Could not update product item for country')

    return data.updatePartnerProductItemModelConfiguration.itemModelConfigurations
  }

  return {
    request,
    loading,
  }
}

export const useOutletProductItemModelConfigurations: QueryHook<
  OutletProductItemModelConfigurationsDocumentInput,
  OutletProductItemModelConfigurationsDocumentResponse['outletProductItemModelConfigurations']['itemModelConfigurations']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<OutletProductItemModelConfigurationsDocumentResponse>(
    OutletProductItemModelConfigurationsDocument,
    { variables, ...options }
  )

  return {
    data: data?.outletProductItemModelConfigurations.itemModelConfigurations,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

type UseOutletProductItemModelConfigurationInput =
  OutletProductItemModelConfigurationsDocumentInput & {
    modelId: string
  }

export const useOutletProductItemModelConfiguration: QueryHook<
  UseOutletProductItemModelConfigurationInput,
  ProductItemModelConfiguration
> = ({ modelId, ...variables }, options) => {
  const {
    data = [],
    loading,
    refetch,
  } = useOutletProductItemModelConfigurations(variables, options)

  const configuration = data.find((item) => item.itemModel.id === modelId)

  return {
    data: configuration,
    loading,
    refetch,
  }
}

export const useUpdateOutletProductItemModelConfiguration: MutationHook<
  UpdateOutletProductItemModelConfigurationDocumentInput,
  UpdateOutletProductItemModelConfigurationDocumentResponse['updateOutletProductItemModelConfiguration']['itemModelConfigurations']
> = (options) => {
  const [execute, { loading }] = useMutation<
    UpdateOutletProductItemModelConfigurationDocumentResponse,
    UpdateOutletProductItemModelConfigurationDocumentInput
  >(UpdateOutletProductItemModelConfigurationDocument, { ...options })

  const request = async (variables: UpdateOutletProductItemModelConfigurationDocumentInput) => {
    const { data } = await execute({
      variables,
      update: (cache) => {
        cache.evict({ fieldName: 'productItemModelTypeConfigurations', broadcast: true })
      },
    })
    if (!data) throw new Error('Could not update product item for country')

    return data.updateOutletProductItemModelConfiguration.itemModelConfigurations
  }

  return {
    request,
    loading,
  }
}
