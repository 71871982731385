import { FC } from 'react'
import { FormSection, TextField } from '@web-panel/shared'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'

export type ContactInfoSectionFormFields = {
  firstName: string
  lastName: string
  phone: string
  email: string
  operationsPhone: string | null
  operationsEmail: string | null
}

export const contactInfoSectionValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  phone: Yup.string().required(),
  email: Yup.string().email().required(),
  operationsPhone: Yup.string().nullable(),
  operationsEmail: Yup.string().nullable(),
})

export const ContactInfoSection: FC = () => {
  const { formatMessage } = useIntl()
  const { values, handleChange, errors } = useFormikContext<ContactInfoSectionFormFields>()

  return (
    <FormSection title={formatMessage({ id: 'models.partner.contact-info' })}>
      <TextField
        fullWidth
        className="mb-16"
        value={values.firstName}
        onChange={handleChange('firstName')}
        label={formatMessage({ id: 'models.partner.first-name' })}
        error={!!errors.firstName}
      />
      <TextField
        fullWidth
        className="mb-16"
        value={values.lastName}
        onChange={handleChange('lastName')}
        label={formatMessage({ id: 'models.partner.last-name' })}
        error={!!errors.lastName}
      />
      <TextField
        fullWidth
        className="mb-16"
        value={values.phone}
        onChange={handleChange('phone')}
        label={formatMessage({ id: 'models.partner.phone' })}
        error={!!errors.phone}
      />
      <TextField
        fullWidth
        type="email"
        className="mb-16"
        value={values.email}
        onChange={handleChange('email')}
        label={formatMessage({ id: 'models.partner.email' })}
        error={!!errors.email}
      />
      <TextField
        fullWidth
        className="mb-16"
        value={values.operationsPhone}
        onChange={handleChange('operationsPhone')}
        label={formatMessage({ id: 'models.partner.operations-phone' })}
        error={!!errors.operationsPhone}
      />
      <TextField
        fullWidth
        className="mb-16"
        value={values.operationsEmail}
        onChange={handleChange('operationsEmail')}
        label={formatMessage({ id: 'models.partner.operations-email' })}
        error={!!errors.operationsEmail}
      />
    </FormSection>
  )
}
