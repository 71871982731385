import { FC } from 'react'
import { TextField, InputAdornment, Icon, Button } from '@web-panel/shared'
import { useIntl, FormattedMessage } from 'react-intl'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { useRequestAuthEmail } from '@web-panel/api'
import { useNavigate } from 'react-router-dom'

type FormFields = {
  email: string
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email(),
})

const SignInForm: FC = () => {
  const { request: requestAuthEmail } = useRequestAuthEmail()
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  const onSubmit = async (values: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
    await requestAuthEmail(values)
    navigate('/auth-email')
    setSubmitting(false)
  }

  return (
    <div className="w-full">
      <Formik<FormFields>
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleSubmit, isValid, isSubmitting }) => (
          <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit}>
            <TextField
              type="email"
              autoComplete="email"
              className="mb-16"
              label={formatMessage({ id: 'pages.sign-in.left-section.form.email-label' })}
              variant="outlined"
              value={values.email}
              onChange={handleChange('email')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon className="text-20" color="action">
                      mail
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="w-full mx-auto mt-16"
              aria-label="LOG IN"
              disabled={!isValid || isSubmitting}
              value="legacy"
            >
              <FormattedMessage id="pages.sign-in.left-section.form.submit-button" />
            </Button>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default SignInForm
