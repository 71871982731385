import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { ActivityTriggerType } from '@web-panel/api'
import _includes from 'lodash/includes'
import { UserLink } from '../UserLink'
import { PartnerLink } from '../PartnerLink'

type ActivityTriggerTypeChipProps = {
  id: string | null
  type: ActivityTriggerType
}

export const ActivityTriggerTypeChip: FC<ActivityTriggerTypeChipProps> = ({ id, type }) => {
  if (_includes([ActivityTriggerType.Administrator, ActivityTriggerType.Consumer], type) && id) {
    return <UserLink userId={id} />
  }

  if (type === ActivityTriggerType.Partner && id) return <PartnerLink partnerId={id} />

  return <FormattedMessage id={`activity-trigger-types.${type}`} />
}
