import { gql } from '@apollo/client'
import { Label, labelFields } from '@web-panel/api'

export type LabelsDocumentInput = {
  limit: number
  offset: number
  search?: string
  status?: string
  partnerAddressId?: string
}

export type LabelsDocumentResponse = {
  labelsCollection: {
    labels: Label[]
    hasNext: boolean
  }
}

export const LabelsDocument = gql`
  query Labels($limit: Int!, $offset: Int!, $status: String, $search: String, $partnerAddressId: String!) {
    labelsCollection(limit: $limit, offset: $offset, status: $status, search: $search, partnerAddressId :$partnerAddressId) @rest(type: "LabelsCollection", path: "/shipment-service/labels/partner/{args.partnerAddressId}?status={args.status}&limit={args.limit}&offset={args.offset}&search={args.search}") {
      labels @type(name: "Label") {
        ${labelFields}
      }
      hasNext
    }
  }
`

export type GetLabelDocumentInput = {
  id: string
}
export type GetLabelDocumentResponse = {
  label: Label
}

export const GetLabelDocument = gql`
  query Label($id: String!) {
    label(id: $id) @rest(type: "Label", path: "/shipment-service/partner/labels/{args.id}") {
      ${labelFields}
    }
  }
`

export type DeleteLabelDocumentInput = {
  id: string
}
export type DeleteLabelDocumentResponse = void

export const DeleteLabelDocument = gql`
  mutation DeleteLabel($id: String!) {
    deleteLabel(id: $id)
      @rest(
        type: "LabelDeleteResponse"
        path: "/shipment-service/partner/labels/{args.id}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`
