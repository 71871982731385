import { FC } from 'react'
import { BatchStatus } from '@web-panel/api'
import { useIntl } from 'react-intl'
import { Chip, ComponentColor } from '@web-panel/shared'

const statusColorMapping: Record<BatchStatus, ComponentColor> = {
  [BatchStatus.Ready]: 'info',
  [BatchStatus.Delivered]: 'success',
}

type BatchStatusChipProps = {
  status: BatchStatus
}

export const BatchStatusChip: FC<BatchStatusChipProps> = ({ status }) => {
  const { formatMessage } = useIntl()

  const color = statusColorMapping[status] ?? 'default'

  return (
    <Chip
      className="min-w-80"
      color={color}
      label={formatMessage({ id: `batch-statuses.${status}` })}
    />
  )
}
