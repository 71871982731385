import { FC } from 'react'
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { InputProps } from './types'
import { Button } from '../../Button'
import TimeInput from './TimeInput'
import { daysOfWeek } from './consts'

const HorizontalInput: FC<InputProps> = ({
  value,
  dayLabels,
  timeFrameLabels,
  actionLabels,
  onAdd,
  onDelete,
  onChange,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">{dayLabels.monday}</TableCell>
            <TableCell align="center">{dayLabels.tuesday}</TableCell>
            <TableCell align="center">{dayLabels.wednesday}</TableCell>
            <TableCell align="center">{dayLabels.thursday}</TableCell>
            <TableCell align="center">{dayLabels.friday}</TableCell>
            <TableCell align="center">{dayLabels.saturday}</TableCell>
            <TableCell align="center">{dayLabels.sunday}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell variant="head">{timeFrameLabels.opening}</TableCell>
            {daysOfWeek.map((id) => {
              const hours = value.find(({ dayOfWeek }) => dayOfWeek === id)
              return (
                <TableCell key={id} align="center">
                  {hours?.openTime && (
                    <TimeInput
                      type="openTime"
                      value={hours?.openTime}
                      onChange={(val) => onChange('openTime', id, val)}
                    />
                  )}
                </TableCell>
              )
            })}
          </TableRow>
          <TableRow>
            <TableCell variant="head">{timeFrameLabels.closing}</TableCell>
            {daysOfWeek.map((id) => {
              const hours = value.find(({ dayOfWeek }) => dayOfWeek === id)
              return (
                <TableCell key={id} align="center">
                  {hours?.closeTime && (
                    <TimeInput
                      type="closeTime"
                      value={hours?.closeTime}
                      onChange={(val) => onChange('closeTime', id, val)}
                    />
                  )}
                </TableCell>
              )
            })}
          </TableRow>
          <TableRow>
            <TableCell />
            {daysOfWeek.map((id) => {
              const isHoursExist = !!value.find(({ dayOfWeek }) => dayOfWeek === id)

              return (
                <TableCell key={id} align="center">
                  {isHoursExist ? (
                    <Button variant="text" color="error" onClick={() => onDelete(id)}>
                      {actionLabels.delete}
                    </Button>
                  ) : (
                    <Button variant="outlined" color="primary" onClick={() => onAdd(id)}>
                      {actionLabels.add}
                    </Button>
                  )}
                </TableCell>
              )
            })}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default HorizontalInput
