import { FC } from 'react'
import { AppPage, Button, CardedContent, useNotifications } from '@web-panel/shared'
import { Outlet } from '@web-panel/api'
import modelIcons from '@local/model-icons'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik } from 'formik'
import { useOutlet, useCreatePartnerContractPackage } from '@web-panel/oc-api'
import { Error404 } from '../404'
import {
  CreateContractPackageForm,
  FormFields,
  formInitialValues,
  validationSchema,
} from './Forms/CreateContractPackageForm'
import { formValuesToPartnerSettingsInput } from './Forms/CreateContractPackageForm/helpers'

type CreateContractPackagePageProps = {
  outlet: Outlet
}
const CreateContractPackagePage: FC<CreateContractPackagePageProps> = ({ outlet }) => {
  const { formatMessage } = useIntl()
  const { request: createPartnerPackage, loading: loadingCreatePackage } =
    useCreatePartnerContractPackage()
  const { pushNotification } = useNotifications()
  const navigate = useNavigate()

  const onSubmit = async (values: FormFields) => {
    try {
      await createPartnerPackage({
        partnerId: outlet.partnerId,
        outletId: outlet.id,
        input: {
          businessCategoryId: values.businessCategoryId!,
          contractPackageCode: values.contractPackageCode!,
          partnerContractSettings: formValuesToPartnerSettingsInput(values),
        },
      })
      navigate(-1)
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-created' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isValid }) => {
        const headerButtons = [
          <Button
            variant="contained"
            color="secondary"
            disabled={!isValid}
            loading={loadingCreatePackage}
            onClick={() => handleSubmit()}
          >
            <FormattedMessage id="common.actions.create" />
          </Button>,
        ]

        return (
          <AppPage
            backButton
            icon={modelIcons.contractPackage}
            title={formatMessage(
              { id: 'routes.outlets.create-contract-package' },
              { outlet: outlet.location.name }
            )}
            actionButtons={headerButtons}
          >
            <CardedContent>
              <CreateContractPackageForm outlet={outlet} />
            </CardedContent>
          </AppPage>
        )
      }}
    </Formik>
  )
}

export default function CreateContractPackagePageExport() {
  const params = useParams()
  const { data: outlet, loading } = useOutlet({ id: params.id! })
  if (loading && !outlet) return null
  if (!outlet) return <Error404 />

  return <CreateContractPackagePage outlet={outlet} />
}
