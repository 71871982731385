import { useMutation, useQuery } from '@apollo/client'
import { Partner, CollectionQueryHook, MutationHook, QueryHook } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  PartnersDocument,
  PartnersDocumentInput,
  PartnersDocumentResponse,
  GetPartnerDocument,
  GetPartnerDocumentInput,
  GetPartnerDocumentResponse,
  DeletePartnerDocument,
  DeletePartnerDocumentInput,
  DeletePartnerDocumentResponse,
  PartnerOutletsDocument,
  PartnerOutletsDocumentResponse,
  UpdatePartnerDocument,
  UpdatePartnerDocumentInput,
  UpdatePartnerDocumentResponse,
  UpdatePartnerLogoDocument,
  UpdatePartnerLogoDocumentInput,
  UpdatePartnerLogoDocumentResponse,
  DeletePartnerLogoDocument,
  DeletePartnerLogoDocumentInput,
  DeletePartnerLogoDocumentResponse,
  PartnerSuplementsDocumentInput,
  PartnerSuplementsDocumentResponse,
  CreatePartnerSuplementDocumentInput,
  CreatePartnerSuplementDocumentResponse,
  CreatePartnerSupplementDocument,
  PartnerSupplementsDocument,
  UpdatePartnerSupplementDocumentInput,
  UpdatePartnerSupplementDocumentResponse,
  UpdatePartnerSupplementDocument,
  DeletePartnerSupplementDocumentInput,
  DeletePartnerSupplementDocumentResponse,
  DeletePartnerSupplementDocument,
} from '../docs'

export const usePartners: CollectionQueryHook<PartnersDocumentInput, Partner[]> = (
  input,
  options
) => {
  const variables = {
    ...defaultCollectionVariables,
    ...input,
  }
  const { data, loading, refetch, fetchMore } = useQuery<PartnersDocumentResponse>(
    PartnersDocument,
    { ...options, variables }
  )

  const loadMore = async () => {
    if (loading || !data || !data.partnersCollection.hasNext) return

    const offset = data.partnersCollection.partners.length

    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.partnersCollection.partners,
    loading,
    loadMore,
    hasMore: data?.partnersCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const usePartner: QueryHook<GetPartnerDocumentInput, GetPartnerDocumentResponse['partner']> =
  (variables) => {
    const { data, loading, refetch } = useQuery<
      GetPartnerDocumentResponse,
      GetPartnerDocumentInput
    >(GetPartnerDocument, { variables })

    return {
      data: data?.partner,
      loading,
      refetch: async () => {
        await refetch()
      },
    }
  }

export const useDeletePartner: MutationHook<
  DeletePartnerDocumentInput,
  DeletePartnerDocumentResponse
> = () => {
  const [execute, { loading }] = useMutation<
    DeletePartnerDocumentResponse,
    DeletePartnerDocumentInput
  >(DeletePartnerDocument)

  async function request(variables: DeletePartnerDocumentInput): Promise<void> {
    await execute({ variables })
  }

  return { request, loading }
}

type UsePartnerOutletsInput = {
  id: string
}
export const usePartnerOutlets: CollectionQueryHook<
  UsePartnerOutletsInput,
  PartnerOutletsDocumentResponse['partnerOutlets']['outlets']
> = (input, options) => {
  const variables = {
    ...defaultCollectionVariables,
    ...input,
  }
  const { data, loading, fetchMore, refetch } = useQuery<PartnerOutletsDocumentResponse>(
    PartnerOutletsDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.partnerOutlets.hasNext) return

    const offset = data.partnerOutlets.outlets.length

    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.partnerOutlets.outlets,
    loading,
    loadMore,
    hasMore: data?.partnerOutlets.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useUpdatePartner: MutationHook<
  UpdatePartnerDocumentInput,
  UpdatePartnerDocumentResponse['updatePartner'] | null
> = () => {
  const [execute, { loading }] = useMutation<
    UpdatePartnerDocumentResponse,
    UpdatePartnerDocumentInput
  >(UpdatePartnerDocument)

  const request = async (variables: UpdatePartnerDocumentInput) => {
    const { data } = await execute({ variables })

    return data?.updatePartner ?? null
  }

  return {
    request,
    loading,
  }
}

//  Update Partner Logo
const updatePartnerLogoSerializer: UpdatePartnerLogoDocumentInput['bodySerializer'] = (
  { logo },
  headers
) => {
  const formData = new FormData()
  formData.append('logo', logo, logo.name)

  return {
    body: formData,
    headers,
  }
}

type UseUpdatePartnerLogoInput = Omit<UpdatePartnerLogoDocumentInput, 'bodySerializer'>
export const useUpdatePartnerLogo: MutationHook<
  UseUpdatePartnerLogoInput,
  UpdatePartnerDocumentResponse['updatePartner'] | null
> = () => {
  const [execute, { loading }] = useMutation<
    UpdatePartnerLogoDocumentResponse,
    UpdatePartnerLogoDocumentInput
  >(UpdatePartnerLogoDocument)

  async function request({ id, input }: UseUpdatePartnerLogoInput) {
    const { data } = await execute({
      variables: { id, input, bodySerializer: updatePartnerLogoSerializer },
    })

    return data?.updatePartnerLogo ?? null
  }

  return { loading, request }
}

// Delete Partner Logo

export const useDeletePartnerLogo: MutationHook<
  DeletePartnerLogoDocumentInput,
  DeletePartnerLogoDocumentResponse['deletePartnerLogo'] | null
> = () => {
  const [execute, { loading }] = useMutation<
    DeletePartnerLogoDocumentResponse,
    DeletePartnerLogoDocumentInput
  >(DeletePartnerLogoDocument)

  async function request(variables: DeletePartnerLogoDocumentInput) {
    const { data } = await execute({ variables })

    return data?.deletePartnerLogo ?? null
  }

  return { request, loading }
}

export const usePartnerSupplements: QueryHook<
  PartnerSuplementsDocumentInput,
  PartnerSuplementsDocumentResponse['partnerSupplements']['supplements']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<PartnerSuplementsDocumentResponse>(
    PartnerSupplementsDocument,
    { ...options, variables }
  )

  return {
    data: data?.partnerSupplements.supplements,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useCreatePartnerSupplement: MutationHook<
  CreatePartnerSuplementDocumentInput,
  CreatePartnerSuplementDocumentResponse['createPartnerSupplement']['supplements']
> = (options) => {
  const [execute, { loading }] = useMutation<
    CreatePartnerSuplementDocumentResponse,
    CreatePartnerSuplementDocumentInput
  >(CreatePartnerSupplementDocument, options)

  async function request(variables: CreatePartnerSuplementDocumentInput) {
    const { data } = await execute({ variables })

    if (!data) throw new Error('Could not create partner supplement')

    return data.createPartnerSupplement.supplements
  }

  return { request, loading }
}

export const useUpdatePartnerSupplement: MutationHook<
  UpdatePartnerSupplementDocumentInput,
  UpdatePartnerSupplementDocumentResponse['updatePartnerSupplement']['supplements']
> = (options) => {
  const [execute, { loading }] = useMutation<
    UpdatePartnerSupplementDocumentResponse,
    UpdatePartnerSupplementDocumentInput
  >(UpdatePartnerSupplementDocument, options)

  async function request(variables: UpdatePartnerSupplementDocumentInput) {
    const { data } = await execute({ variables })

    if (!data) throw new Error('Could not update partner supplement')

    return data.updatePartnerSupplement.supplements
  }

  return { request, loading }
}

export const useDeletePartnerSupplement: MutationHook<
  DeletePartnerSupplementDocumentInput,
  DeletePartnerSupplementDocumentResponse
> = (options) => {
  const [execute, { loading }] = useMutation<
    DeletePartnerSupplementDocumentResponse,
    DeletePartnerSupplementDocumentInput
  >(DeletePartnerSupplementDocument, options)

  async function request(variables: DeletePartnerSupplementDocumentInput) {
    await execute({
      variables,
      update: (cache) => {
        cache.evict({ id: cache.identify({ __typename: 'PartnerSupplement', id: variables.id }) })
      },
    })
  }

  return { request, loading }
}
