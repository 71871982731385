import { useMutation, useQuery } from '@apollo/client'
import { QueryHook, CollectionQueryHook, MutationHook } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  OrdersDocument,
  OrdersDocumentInput,
  OrdersDocumentResponse,
  GetOrderDocument,
  GetOrderDocumentInput,
  GetOrderDocumentResponse,
  AttachBatchToOrderDocument,
  AttachBatchToOrderDocumentInput,
  AttachBatchToOrderDocumentResponse,
  OrderBatchesDocument,
  OrderBatchesDocumentResponse,
  UpdateOrderStatusDocument,
  UpdateOrderStatusDocumentInput,
  UpdateOrderStatusDocumentResponse,
  RemoveBatchFromOrderDocumentInput,
  RemoveBatchFromOrderDocumentResponse,
  RemoveBatchFromOrderDocument,
  CreateOrderDocument,
  CreateOrderDocumentInput,
  CreateOrderDocumentResponse,
  UpdateOrderReturnTypeDocumentInput,
  UpdateOrderReturnTypeDocumentResponse,
  UpdateOrderReturnTypeDocument,
  CreateOrderSignatureDocumentInput,
  CreateOrderSignatureDocumentResponse,
  CreateOrderSignatureDocument,
  DeleteOrderSignatureDocumentInput,
  DeleteOrderSignatureDocumentResponse,
  DeleteOrderSignatureDocument,
  OrderAttachedContainersDocumentInput,
  OrderAttachedContainersDocumentResponse,
  OrderAttachedContainersDocument,
  AttachContainerToOrderDocumentResponse,
  AttachContainerToOrderDocumentInput,
  AttachContainerToOrderDocument,
  RemoveContainerFromOrderDocumentInput,
  RemoveContainerFromOrderDocument,
  RemoveContainerFromOrderDocumentResponse,
} from '../docs'

type UseOrdersInput = Omit<OrdersDocumentInput, 'limit' | 'offset'>
export const useOrders: CollectionQueryHook<
  UseOrdersInput,
  OrdersDocumentResponse['orderCollection']['orders']
> = (input, options) => {
  const variables = { ...input, ...defaultCollectionVariables }

  const { data, loading, fetchMore, refetch } = useQuery<OrdersDocumentResponse>(OrdersDocument, {
    ...options,
    variables,
  })

  const collection = data?.orderCollection

  const loadMore = async () => {
    if (loading || !data || !data.orderCollection.hasNext) return

    const offset = data.orderCollection.orders.length
    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: collection?.orders,
    loading,
    loadMore,
    hasMore: data?.orderCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useOrder: QueryHook<GetOrderDocumentInput, GetOrderDocumentResponse['order']> = (
  variables
) => {
  const { data, loading, refetch } = useQuery<GetOrderDocumentResponse, GetOrderDocumentInput>(
    GetOrderDocument,
    { variables }
  )

  return {
    data: data?.order,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

type UseAttachBatchesToOrderInput = {
  id: string
  batchIds: string[]
}

export const useAttachBatchesToOrder: MutationHook<
  UseAttachBatchesToOrderInput,
  AttachBatchToOrderDocumentResponse
> = () => {
  const [execute, { loading }] = useMutation<
    AttachBatchToOrderDocumentResponse,
    AttachBatchToOrderDocumentInput
  >(AttachBatchToOrderDocument)

  const request = async ({ id, batchIds }: UseAttachBatchesToOrderInput) => {
    const requestPromises = batchIds.map((batchId) =>
      execute({
        variables: {
          id,
          input: {
            batchId,
          },
        },
      })
    )

    await Promise.all(requestPromises)
  }

  return {
    request,
    loading,
  }
}

type UseOrderBatchesInput = {
  id: string
}
export const useOrderBatches: CollectionQueryHook<
  UseOrderBatchesInput,
  OrderBatchesDocumentResponse['orderBatches']['batches']
> = (input, options) => {
  const variables = { ...input, ...defaultCollectionVariables }

  const { data, loading, fetchMore, refetch } = useQuery<OrderBatchesDocumentResponse>(
    OrderBatchesDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.orderBatches.hasNext) return

    const offset = data.orderBatches.batches.length
    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.orderBatches.batches,
    loading,
    loadMore,
    hasMore: data?.orderBatches.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useUpdateOrderStatus: MutationHook<
  UpdateOrderStatusDocumentInput,
  UpdateOrderStatusDocumentResponse
> = () => {
  const [execute, { loading }] = useMutation<
    UpdateOrderStatusDocumentResponse,
    UpdateOrderStatusDocumentInput
  >(UpdateOrderStatusDocument)

  const request = async ({ id, input }: UpdateOrderStatusDocumentInput) => {
    await execute({
      variables: { id, input },
      refetchQueries: [
        {
          query: GetOrderDocument,
          variables: { id },
        },
      ],
    })
  }

  return {
    request,
    loading,
  }
}

export const useUpdateOrderReturnType: MutationHook<
  UpdateOrderReturnTypeDocumentInput,
  UpdateOrderReturnTypeDocumentResponse
> = () => {
  const [execute, { loading }] = useMutation<
    UpdateOrderReturnTypeDocumentResponse,
    UpdateOrderReturnTypeDocumentInput
  >(UpdateOrderReturnTypeDocument)

  const request = async ({ id, input }: UpdateOrderReturnTypeDocumentInput) => {
    await execute({
      variables: { id, input },
      refetchQueries: [
        {
          query: GetOrderDocument,
          variables: { id },
        },
      ],
    })
  }

  return {
    request,
    loading,
  }
}

export const useRemoveBatchFromOrder: MutationHook<
  RemoveBatchFromOrderDocumentInput,
  RemoveBatchFromOrderDocumentResponse
> = () => {
  const [execute, { loading }] = useMutation<
    RemoveBatchFromOrderDocumentResponse,
    RemoveBatchFromOrderDocumentInput
  >(RemoveBatchFromOrderDocument)

  const request = async (variables: RemoveBatchFromOrderDocumentInput) => {
    await execute({ variables })
  }

  return {
    request,
    loading,
  }
}

export const useCreateOrder: MutationHook<
  CreateOrderDocumentInput,
  CreateOrderDocumentResponse['createOrder'] | null
> = () => {
  const [execute, { loading }] = useMutation<CreateOrderDocumentResponse, CreateOrderDocumentInput>(
    CreateOrderDocument
  )

  const request = async ({ input }: CreateOrderDocumentInput) => {
    const { data } = await execute({
      variables: { input },
    })

    return data?.createOrder ?? null
  }

  return {
    request,
    loading,
  }
}

const createOrderSignatureBodySerializer: CreateOrderSignatureDocumentInput['bodySerializer'] = (
  { signature },
  headers
) => {
  const formData = new FormData()
  formData.append('signature', signature)

  return {
    body: formData,
    headers,
  }
}

type UseCreateOrderSignatureInput = Omit<CreateOrderSignatureDocumentInput, 'bodySerializer'>
export const useCreateOrderSignature: MutationHook<
  UseCreateOrderSignatureInput,
  CreateOrderSignatureDocumentResponse
> = () => {
  const [execute, { loading }] = useMutation<
    CreateOrderDocumentResponse,
    CreateOrderSignatureDocumentInput
  >(CreateOrderSignatureDocument)

  const request = async ({ orderId, input }: UseCreateOrderSignatureInput) => {
    await execute({
      variables: { orderId, input, bodySerializer: createOrderSignatureBodySerializer },
    })
  }

  return {
    request,
    loading,
  }
}

export const useDeleteOrderSignature: MutationHook<
  DeleteOrderSignatureDocumentInput,
  DeleteOrderSignatureDocumentResponse
> = () => {
  const [execute, { loading }] = useMutation<
    DeleteOrderSignatureDocumentResponse,
    DeleteOrderSignatureDocumentInput
  >(DeleteOrderSignatureDocument)

  const request = async ({ orderId }: DeleteOrderSignatureDocumentInput) => {
    await execute({
      variables: { orderId },
    })
  }

  return {
    request,
    loading,
  }
}

export const useOrderAttachedContainers: CollectionQueryHook<
  Omit<OrderAttachedContainersDocumentInput, 'pagination'>,
  OrderAttachedContainersDocumentResponse['orderAttachedContainers']['containers']
> = (input, options) => {
  const variables = { ...input, pagination: defaultCollectionVariables }

  const { data, loading, fetchMore, refetch } = useQuery<OrderAttachedContainersDocumentResponse>(
    OrderAttachedContainersDocument,
    { ...options, variables }
  )

  const loadMore = async () => {
    if (loading || !data || !data.orderAttachedContainers.hasNext) return

    const offset = data.orderAttachedContainers.containers.length
    await fetchMore({
      variables: {
        ...variables,
        pagination: {
          ...variables.pagination,
          offset,
        },
      },
    })
  }

  return {
    data: data?.orderAttachedContainers.containers,
    loading,
    loadMore,
    hasMore: data?.orderAttachedContainers.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useAttachContainerToOrder: MutationHook<
  AttachContainerToOrderDocumentInput,
  AttachContainerToOrderDocumentResponse
> = () => {
  const [execute, { loading }] = useMutation<AttachContainerToOrderDocumentResponse>(
    AttachContainerToOrderDocument
  )

  const request = async (variables: AttachContainerToOrderDocumentInput) => {
    await execute({ variables })
  }

  return {
    request,
    loading,
  }
}

export const useRemoveContainerFromOrder: MutationHook<
  RemoveContainerFromOrderDocumentInput,
  RemoveContainerFromOrderDocumentResponse
> = () => {
  const [execute, { loading }] = useMutation<RemoveContainerFromOrderDocumentResponse>(
    RemoveContainerFromOrderDocument
  )

  const request = async (variables: RemoveContainerFromOrderDocumentInput) => {
    await execute({ variables })
  }

  return {
    request,
    loading,
  }
}
