import { FC } from 'react'

import { InfinitScroller, TableGrid } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import ProductItemStatusChip from '@local/components/ProductItemStatusChip'

import { useBatchItems } from '@web-panel/oc-api'

type BatchItemsTableProps = {
  id: string
}
const BatchItemsTable: FC<BatchItemsTableProps> = ({ id }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { data: productItems = [], hasMore, loadMore, loading } = useBatchItems({ id })

  const onLoadMore = () => {
    if (!hasMore) return
    loadMore()
  }

  return (
    <InfinitScroller onLoadMore={onLoadMore}>
      <TableGrid
        loading={loading}
        collection={productItems}
        fields={[
          {
            title: formatMessage({ id: 'models.product-item.qr-code' }),
            render: ({ qrCode }) => qrCode,
          },
          {
            title: formatMessage({ id: 'models.product-item.rfid' }),
            render: ({ rfid }) => rfid,
          },
          {
            title: formatMessage({ id: 'models.product-item.brand' }),
            render: ({ brand }) => brand,
          },
          {
            title: formatMessage({ id: 'models.product-item.status' }),
            render: ({ status }) => <ProductItemStatusChip status={status} />,
          },
          {
            title: formatMessage({ id: 'models.product-item.current-holder-type' }),
            render: ({ currentHolderType }) => (
              <FormattedMessage id={`product-item-holder-types.${currentHolderType}`} />
            ),
          },
          {
            title: formatMessage({ id: 'models.product-item.capacity' }),
            render: ({ model }) => `${model.capacityValue} ${model.capacityUnit}`,
          },
        ]}
        onClickRow={(productItem) => navigate(`/product-items/${productItem.id}`)}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
      />
    </InfinitScroller>
  )
}

export default BatchItemsTable
