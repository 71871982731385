import { gql } from '@apollo/client'
import {
  ProductItem,
  productItemFields,
  ProductItemSize,
  ProductItemType,
  Batch,
  batchFields,
  ProductItemStatus,
  ProductItemActivity,
  ConsumerProductItem,
  consumerProductItemFields,
  productItemActivityFields,
  ProductItemStatusAggregation,
  productItemStatusAggregationFields,
  SortDirection,
  productItemModelTypeFields,
  ProductItemModelType,
  ConsumableProductItem,
  consumableProductItemFields,
  Currency,
  ConsumableProductItemType,
  ConsumablePartnerProductItem,
  consumablePartnerProductItemFields,
  ProductItemModelConfiguration,
  productItemModelConfigurationFields,
  ProductItemModelConfigurationStatus,
  ProductItemModelBrand,
  productItemModelBrandFields,
} from '@web-panel/api'

export type ProductItemsDocumentInput = {
  limit: number
  offset?: number
  size?: ProductItemSize
  status?: ProductItemStatus
  search?: string
  startHolderUpdateDateTime?: Date
  endHolderUpdateDateTime?: Date
  sortOrder?: SortDirection
  sortBy?: 'QR_CODE' | 'HOLDER_UPDATE_DATE' | 'STATUS'
}
export type ProductItemsDocumentResponse = {
  productItemsCollection: {
    items: ProductItem[]
    hasNext: boolean
  }
}
export const ProductItemsDocument = gql`
  query ProductItems($limit: Int!, $offset: Int, $search: String, $status: String, $size: String, $sortOrder: String, $sortBy: String, $startHolderUpdateDateTime: DateTime, $endHolderUpdateDateTime: DateTime) {
    productItemsCollection(limit: $limit, offset: $offset, search: $search, status: $status, size: $size, sortOrder: $sortOrder, sortBy: $sortBy, startHolderUpdateDateTime: $startHolderUpdateDateTime, endHolderUpdateDateTime: $endHolderUpdateDateTime) @rest(type: "ProductItemCollection", path: "/operations-service/items?{args}") {
      items @type(name: "ProductItem") {
        ${productItemFields}
      }
      hasNext
    }
  }
`

type ProductItemInput = {
  qrCode: string
  rfid: string
  brand: string
  size: ProductItemSize
  type: ProductItemType
}

export type CreateProductItemDocumentInput = ProductItemInput
export type CreateProductItemDocumentResponse = {
  createProductItem: ProductItem
}
export const CreateProductItemDocument = gql`
  mutation CreateProductItem($input: any) {
    createProductItem: publish(input: $input) @rest(type: "ProductItem", path: "/operations-service/items", method: "POST") {
      ${batchFields}
    }
  }
`

export type CreateBatchProductItemsDocumentInput = {
  items: ProductItemInput[]
}
export type CreateBatchProductItemsDocumentResponse = {
  createBatchProductItems: {
    items: ProductItem[]
    hasNext: boolean
  }
}
export const CreateBatchProductItemsDocument = gql`
  mutation CreateBatchProductItems($input: any) {
    createBatchProductItems: publish(input: $input) @rest(type: "ProductItemsBatch", path: "/operations-service/items/batch", method: "POST") {
      items @type(name: "ProductItem") {
        ${batchFields}
      }
      hasNext
    }
  }
`

export type GetProductItemDocumentInput = {
  id: string
}
export type GetProductItemDocumentResponse = {
  productItem: ProductItem
}
export const GetProductItemDocument = gql`
  query GetProductItem($id: String!) {
    productItem(id: $id) @rest(type: "ProductItem", path: "/operations-service/items/{args.id}") {
      ${productItemFields}
    }
  }
`

export type GetProductItemBatchHistoryDocumentInput = {
  id: string
  limit: number
  offset?: number
}
export type GetProductItemBatchHistoryDocumentResponse = {
  productItemBatchHistory: {
    batches: Batch[]
    hasNext: boolean
  }
}
export const GetProductItemBatchHistoryDocument = gql`
  query GetProductItem($id: String!, $limit: Int!, $offset: Int) {
    productItemBatchHistory(id: $id, limit: $limit, offset: $offset) @rest(type: "BatchesCollection", path: "/operations-service/items/{args.id}/batches?limit={args.limit}&offset={args.offset}") {
      batches @type(name: "Batch") {
        ${batchFields}
      }
      hasNext
    }
  }
`

export type UpdateProductItemDocumentInput = {
  id: string
  input: {
    status: ProductItemStatus
    holderId: string | null
    batchId: string | null
  }
}
export type UpdateProductItemDocumentResponse = {
  updateProductItem: ProductItem
}

export const UpdateProductItemDocument = gql`
  mutation UpdateProductItem($id: String!, $input: any) {
    updateProductItem: publish(id: $id, input: $input) @rest(type: "ProductItem", path: "/operations-service/items/{args.id}", method: "PUT") {
      ${productItemFields}
    }
  }
`

export type ConsumerProductItemsDocumentInput = {
  consumerId: string
  offset?: number
  limit?: number
}

export type ConsumerProductItemsDocumentResponse = {
  consumerProductItemsCollection: {
    consumerItems: ConsumerProductItem[]
    hasNext: boolean
  }
}

export const ConsumerProductItemsDocument = gql`
  query ConsumerProductItems($consumerId: String!, $offset: Int, $limit: Int) {
    consumerProductItemsCollection(consumerId: $consumerId, offset: $offset, limit: $limit) @rest(type: "ConsumerProductItems", path: "/operations-service/items/consumer/{args.consumerId}?limit={args.limit}&offset={args.offset}") {
      consumerItems @type(name: "ConsumerProductItem") {
        ${consumerProductItemFields}
      }
      hasNext
    }
  }
`

export type ProductItemHistoryDocumentInput = {
  itemId: string
  limit?: number
  offset?: number
}
export type ProductItemHistoryDocumentResponse = {
  productItemHistory: {
    itemActivities: ProductItemActivity[]
    hasNext: boolean
  }
}
export const ProductItemHistoryDocument = gql`
  query ProductItemHistory($itemId: String!, $limit: Int, $offset: Int) {
    productItemHistory(itemId: $itemId, limit: $limit, offset: $offset) @rest(type: "ProductItemActivityCollection", path: "/operations-service/items/{args.itemId}/history?limit={args.limit}&offset={args.offset}"){
      itemActivities @type(name: "ProductItemActivity") {
        ${productItemActivityFields}
      }
      hasNext
    }
  }
`

export type ProductItemAggregationDocumentInput = {
  type?: ProductItemStatus
  size?: ProductItemSize
  search?: string
  startHolderUpdateDateTime?: Date
  endHolderUpdateDateTime?: Date
}

export type ProductItemAggregationDocumentResponse = {
  productItemStatusAggregation: {
    itemCountByStatuses: ProductItemStatusAggregation[]
  }
}

export const ProductItemAggregationDocument = gql`
  query ProductItemStatusAggregation($type: String, $size: String, $search: String, $startHolderUpdateDateTime: Date, $endHolderUpdateDateTime: Date) {
    productItemStatusAggregation(type: $type, size: $size, search: $search, startHolderUpdateDateTime: $startHolderUpdateDateTime, endHolderUpdateDateTime: $endHolderUpdateDateTime) @rest(type: "ProductItemStatusAggregation", path: "/operations-service/items/filter/aggregation?{args}") {
      itemCountByStatuses {
        ${productItemStatusAggregationFields}
      }
    }
  }
`

export type ProductItemModelTypesDocumentInput = undefined
export type ProductItemModelTypesDocumentResponse = {
  productItemModelTypes: {
    itemModelTypes: ProductItemModelType[]
  }
}
export const ProductItemModelTypesDocument = gql`
  query ProductItemModelTypes {
    productItemModelTypes @rest(type: "ProductItemModelTypes", path: "/operations-service/items/models/types") {
      itemModelTypes @type(name: "ProductItemModelType") {
        ${productItemModelTypeFields}
      }
    }
  }
`

export type ProductBrandsDocumentInput = undefined
export type ProductBrandsDocumentResponse = {
  productBrands: ProductItemModelBrand
}
export const ProductBrandsDocument = gql`
  query ProductBrands {
    productBrands @rest(type: "ProductBrands", path: "/operations-service/items/models/brands") {
        ${productItemModelBrandFields}
    }
  }
`

export type ProductItemModelTypeConfigurationsDocumentInput = {
  partnerId: string
  outletId: string
}
export type ProductItemModelTypeConfigurationsDocumentResponse = {
  productItemModelTypeConfigurations: {
    itemModelConfigurations: ProductItemModelConfiguration[]
  }
}
export const ProductItemModelTypeConfigurationsDocument = gql`
  query ProductItemModelTypeConfigurations($partnerId: String!, $outletId: String!) {
    productItemModelTypeConfigurations(partnerId: $partnerId, outletId: $outletId) @rest(type: "ProductItemModelTypeConfigurations", path: "/operations-service/items/partners/{args.partnerId}/models?&outletId={args.outletId}") {
      itemModelConfigurations @type(name: "ProductItemModelTypeConfiguration") {
        ${productItemModelConfigurationFields}
      }
    }
  } 
`

export type ProductItemConsumablesDocumentInput = undefined
export type ProductItemConsumablesDocumentResponse = {
  productItemConsumables: {
    consumables: ConsumableProductItem[]
  }
}
export const ProductItemConsumablesDocument = gql`
  query ProductItemConsumables {
    productItemConsumables @rest(type: "ProductItemConsumableItems", path: "/partners-service/consumables") {
      consumables @type(name: "ProductItemConsumable"){
        ${consumableProductItemFields}
      }
    }
  }
`

export type UpdateProductItemConsumableDocumentInput = {
  id: string
  input: {
    consumableType: ConsumableProductItemType
    currency: Currency
    cost: number
  }
}
export type UpdateProductItemConsumableDocumentResponse = {
  updateProductItemConsumable: ConsumableProductItem
}

export const UpdateProductItemConsumableDocument = gql`
  mutation UpdateProductItemConsumable($id: String!, $input: any) {
    updateProductItemConsumable(id: $id, input: $input) @rest(type: "ProductItemConsumable", path: "/partners-service/consumables/{args.id}", method: "PUT") {
      ${consumableProductItemFields}
    }
  }
`

export type CreateProductItemConsumableDocumentInput = {
  input: {
    consumableType: ConsumableProductItemType
    currency: Currency
    cost: number
  }
}
export type CreateProductItemConsumableDocumentResponse = {
  createProductItemConsumable: ConsumableProductItem
}
export const CreateProductItemConsumableDocument = gql`
  mutation CreateProductItemConsumable($input: any) {
    createProductItemConsumable(input: $input) @rest(type: "ProductItemConsumable", path: "/partners-service/consumables", method: "POST") {
      ${consumableProductItemFields}
    }
  }
`

export type DeleteProductItemConsumableDocumentInput = {
  id: string
}
export type DeleteProductItemConsumableDocumentResponse = void

export const DeleteProductItemConsumableDocument = gql`
  mutation DeleteProductItemConsumable($id: String!) {
    deleteProductItemConsumable(id: $id)
      @rest(
        type: "ProductItemConsumableDeleteResponse"
        path: "/partners-service/consumables/{args.id}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`

export type ProductItemPartnerConsumablesDocumentInput = {
  partnerId: string
}
export type ProductItemPartnerConsumablesDocumentResponse = {
  productItemPartnerConsumables: {
    consumables: ConsumablePartnerProductItem[]
  }
}
export const ProductItemPartnerConsumablesDocument = gql`
  query ProductItemPartnerConsumables($partnerId: String!) {
    productItemPartnerConsumables(partnerId: $partnerId) @rest(type: "ProductItemPartnerConsumables", path: "/partners-service/consumables/partners/{args.partnerId}") {
      consumables @type(name: "ProductItemPartnerConsumable") {
        ${consumablePartnerProductItemFields}
      }
    }
  }
`

export type CreateProductItemPartnerConsumableDocumentInput = {
  partnerId: string
  input: {
    consumableType: ConsumableProductItemType
    currency: Currency
    cost: number
    enabled: boolean
  }
}
export type CreateProductItemPartnerConsumableDocumentResponse = {
  createProductItemPartnerConsumable: ConsumablePartnerProductItem
}
export const CreateProductItemPartnerConsumableDocument = gql`
  mutation CreateProductItemPartnerConsumable($partnerId: String!, $input: any) {
    createProductItemPartnerConsumable(partnerId: $partnerId, input: $input) @rest(type: "ProductItemPartnerConsumable", path: "/partners-service/consumables/partners/{args.partnerId}", method: "POST") {
      ${consumablePartnerProductItemFields}
    }
  }
`

export type UpdateProductItemPartnerConsumableDocumentInput = {
  id: string
  partnerId: string
  input: {
    consumableType: ConsumableProductItemType
    currency: Currency
    cost: number
    enabled: boolean
  }
}
export type UpdateProductItemPartnerConsumableDocumentResponse = {
  updateProductItemPartnerConsumable: ConsumablePartnerProductItem
}
export const UpdateProductItemPartnerConsumableDocument = gql`
  mutation UpdateProductItemPartnerConsumable($id: String! $partnerId: String!, $input: any) {
    updateProductItemPartnerConsumable(id: $id, partnerId: $partnerId, input: $input) @rest(type: "ProductItemPartnerConsumable", path: "/partners-service/consumables/{args.id}/partners/{args.partnerId}", method: "PUT") {
      ${consumablePartnerProductItemFields}
    }
  }
`

export type CountryProductItemModelConfigurationsDocumentInput = {
  countryCode: string
}
export type CountryProductItemModelConfigurationsDocumentResponse = {
  countryProductItemModelConfigurations: {
    countryCode: string
    itemModelConfigurations: ProductItemModelConfiguration[]
  }
}
export const CountryProductItemModelConfigurationsDocument = gql`
  query CountryProductItemModelConfigurations($countryCode: String!) {
    countryProductItemModelConfigurations(countryCode: $countryCode) @rest(type: "CountryProductItemModelConfigurations", path: "/operations-service/items/models/countries/{args.countryCode}") {
      countryCode
      itemModelConfigurations @type(name: "CountryProductItemConfiguration") {
        ${productItemModelConfigurationFields}
      }
    }
  }
`

export type UpdateCountryProductItemModelConfigurationDocumentInput = {
  countryCode: string
  input: {
    itemModelId: string
    status: ProductItemModelConfigurationStatus
    cost: number
    serviceFee: number
    refundFeePercent: number
    payoutFeePercent: number
  }
}
export type UpdateCountryProductItemModelConfigurationDocumentResponse = {
  updateCountryProductItemModelConfiguration: {
    countryCode: string
    itemModelConfigurations: ProductItemModelConfiguration[]
  }
}
export const UpdateCountryProductItemModelConfigurationDocument = gql`
  mutation UpdateCountryProductItemModelConfiguration($countryCode: String!, $input: any) {
    updateCountryProductItemModelConfiguration(countryCode: $countryCode, input: $input) @rest(type:  "CountryProductItemModelConfigurations", path: "/operations-service/items/models/countries/{args.countryCode}", method: "PUT") {
      countryCode
      itemModelConfigurations @type(name: "CountryProductItemConfiguration") {
        ${productItemModelConfigurationFields}
      }
    }
  }
`

export type CityProductItemModelConfigurationsDocumentInput = {
  cityCode: string
}
export type CityProductItemModelConfigurationsDocumentResponse = {
  cityProductItemModelConfigurations: {
    countryCode: string
    cityCode: string
    itemModelConfigurations: ProductItemModelConfiguration[]
  }
}
export const CityProductItemModelConfigurationsDocument = gql`
  query CityProductItemModelConfigurations($cityCode: String!) {
    cityProductItemModelConfigurations(cityCode: $cityCode) @rest(type: "CityProductItemModelConfigurations", path: "/operations-service/items/models/cities/{args.cityCode}") {
      countryCode
      cityCode
      itemModelConfigurations @type(name: "CityProductItemModelConfiguration") {
        ${productItemModelConfigurationFields}
      }
    }
  }
`

export type UpdateCityProductItemModelConfigurationDocumentInput = {
  cityCode: string
  input: {
    itemModelId: string
    status: ProductItemModelConfigurationStatus
    cost: number
    serviceFee: number
    refundFeePercent: number
    payoutFeePercent: number
  }
}
export type UpdateCityProductItemModelConfigurationDocumentResponse = {
  updateCityProductItemModelConfiguration: {
    countryCode: string
    cityCode: string
    itemModelConfigurations: ProductItemModelConfiguration[]
  }
}
export const UpdateCityProductItemModelConfigurationDocument = gql`
  mutation UpdateCityProductItemModelConfiguration($cityCode: String!, $input: any) {
    updateCityProductItemModelConfiguration(cityCode: $cityCode, input: $input) @rest(type:  "CityProductItemModelConfigurations", path: "/operations-service/items/models/cities/{args.cityCode}", method: "PUT") {
      countryCode
      cityCode
      itemModelConfigurations @type(name: "CityProductItemConfiguration") {
        ${productItemModelConfigurationFields}
      }
    }
  }
`

export type PartnerProductItemModelConfigurationsDocumentInput = {
  partnerId: string
}
export type PartnerProductItemModelConfigurationsDocumentResponse = {
  partnerProductItemModelConfigurations: {
    partnerId: string
    itemModelConfigurations: ProductItemModelConfiguration[]
  }
}
export const PartnerProductItemModelConfigurationsDocument = gql`
  query PartnerProductItemModelConfigurations($partnerId: String!) {
    partnerProductItemModelConfigurations(partnerId: $partnerId) @rest(type: "PartnerProductItemModelConfigurations", path: "/operations-service/items/models/partners/{args.partnerId}") {
      partnerId
      itemModelConfigurations @type(name: "PartnerProductItemModelConfiguration") {
        ${productItemModelConfigurationFields}
      }
    }
  }
`

export type UpdatePartnerProductItemModelConfigurationDocumentInput = {
  partnerId: string
  input: {
    itemModelId: string
    status: ProductItemModelConfigurationStatus
    cost: number
    serviceFee: number
    refundFeePercent: number
    payoutFeePercent: number
  }
}
export type UpdatePartnerProductItemModelConfigurationDocumentResponse = {
  updatePartnerProductItemModelConfiguration: {
    partnerId: string
    itemModelConfigurations: ProductItemModelConfiguration[]
  }
}
export const UpdatePartnerProductItemModelConfigurationDocument = gql`
  mutation UpdatePartnerProductItemModelConfiguration($partnerId: String!, $input: any) {
    updatePartnerProductItemModelConfiguration(partnerId: $partnerId, input: $input) @rest(type:  "PartnerProductItemModelConfigurations", path: "/operations-service/items/models/partners/{args.partnerId}", method: "PUT") {
      partnerId
      itemModelConfigurations @type(name: "PartnerProductItemModelConfiguration") {
        ${productItemModelConfigurationFields}
      }
    }
  }
`

export type OutletProductItemModelConfigurationsDocumentInput = {
  outletId: string
}
export type OutletProductItemModelConfigurationsDocumentResponse = {
  outletProductItemModelConfigurations: {
    outletId: string
    itemModelConfigurations: ProductItemModelConfiguration[]
  }
}
export const OutletProductItemModelConfigurationsDocument = gql`
  query OutletProductItemModelConfigurations($outletId: String!) {
    outletProductItemModelConfigurations(outletId: $outletId) @rest(type: "OutletProductItemModelConfigurations", path: "/operations-service/items/models/outlets/{args.outletId}") {
      outletId
      itemModelConfigurations @type(name: "OutletProductItemModelConfiguration") {
        ${productItemModelConfigurationFields}
      }
    }
  }
`

export type UpdateOutletProductItemModelConfigurationDocumentInput = {
  outletId: string
  input: {
    itemModelId: string
    status: ProductItemModelConfigurationStatus
    cost: number
    serviceFee: number
    refundFeePercent: number
    payoutFeePercent: number
  }
}
export type UpdateOutletProductItemModelConfigurationDocumentResponse = {
  updateOutletProductItemModelConfiguration: {
    outletId: string
    itemModelConfigurations: ProductItemModelConfiguration[]
  }
}
export const UpdateOutletProductItemModelConfigurationDocument = gql`
  mutation UpdateOutletProductItemModelConfiguration($outletId: String!, $input: any) {
    updateOutletProductItemModelConfiguration(outletId: $outletId, input: $input) @rest(type: "OutletProductItemModelConfigurations", path: "/operations-service/items/models/outlets/{args.outletId}", method: "PUT") {
      outletId
      itemModelConfigurations @type(name: "OutletProductItemModelConfiguration") {
        ${productItemModelConfigurationFields}
      }
    }
  }
`
