import { FC } from 'react'
import { Link } from '@web-panel/shared'

import { useIntl } from 'react-intl'

import { useBox } from '@web-panel/oc-api'

type UserLinkProps = {
  boxId: string
}

export const BoxLink: FC<UserLinkProps> = ({ boxId }) => {
  const { formatMessage } = useIntl()
  const { data: box } = useBox({ id: boxId })
  if (!box) return null

  return (
    <Link stopPropogation data-testid="BoxLink" to={`/boxes/${box.id}`}>
      {`${formatMessage({ id: 'models.box' })} ${box.coreId}`}
    </Link>
  )
}
