import { FC } from 'react'

import { InfinitScroller, TableGrid, useFormatDate } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'

import { useDispenserTasks } from '@web-panel/oc-api'

type DispenserTasksTabProps = {
  dispenserId: string
}
export const DispenserTasksTab: FC<DispenserTasksTabProps> = ({ dispenserId }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()

  const {
    data: tasks = [],
    loading,
    loadMore,
  } = useDispenserTasks(
    { dispenserId },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )

  return (
    <InfinitScroller onLoadMore={loadMore}>
      <TableGrid
        loading={loading}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        collection={tasks}
        fields={[
          {
            title: formatMessage({ id: 'models.box-task.provider' }),
            render: ({ provider }) => (
              <FormattedMessage id={`inventory-task-providers.${provider}`} />
            ),
          },
          {
            title: formatMessage({ id: 'models.box-task.type' }),
            render: ({ type }) => <FormattedMessage id={`inventory-task-types.${type}`} />,
          },
          {
            title: formatMessage({ id: 'models.box-task.status' }),
            render: ({ status }) => <FormattedMessage id={`inventory-task-statuses.${status}`} />,
          },
          {
            title: formatMessage({ id: 'models.box-task.task-url' }),
            render: ({ taskId, taskUrl }) =>
              taskUrl && (
                <a target="_blank" href={taskUrl} rel="noreferrer">
                  {taskId}
                </a>
              ),
          },
          {
            title: formatMessage({ id: 'models.box-task.task-date-from' }),
            render: ({ taskDateFrom }) => formatDate('datetime', taskDateFrom),
          },
          {
            title: formatMessage({ id: 'models.box-task.task-date-to' }),
            render: ({ taskDateTo }) => formatDate('datetime', taskDateTo),
          },
          {
            title: formatMessage({ id: 'models.box-task.create-date' }),
            render: ({ createDate }) => formatDate('datetime', createDate),
          },
        ]}
      />
    </InfinitScroller>
  )
}
