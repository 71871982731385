import { FC } from 'react'
import { Framer, AuthLeftSection, AuthRightSection, Typography } from '@web-panel/shared'
import { FormattedMessage } from 'react-intl'
import SignInForm from './Form'

const { motion } = Framer

const SignInPage: FC = () => {
  return (
    <>
      <AuthLeftSection>
        <SignInForm />
      </AuthLeftSection>
      <AuthRightSection>
        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
        >
          <Typography variant="h3" color="inherit" className="font-semibold leading-tight">
            <FormattedMessage id="pages.sign-in.right-section.text1" />
          </Typography>
        </motion.div>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.3 } }}>
          <Typography variant="subtitle1" color="inherit" className="mt-32">
            <FormattedMessage id="pages.sign-in.right-section.text2" />
          </Typography>
        </motion.div>
      </AuthRightSection>
    </>
  )
}

export default SignInPage
