import { FC } from 'react'
import { formatCurrency, TableGrid } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { RentalConfigurationStatusChip } from '@local/components/RentalConfigurationStatusChip'
import { useBoxRentalConfigurations } from '@web-panel/oc-api'

export const BoxRentalConfigurationsTab: FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { can } = useRoleAuthorization()
  const { data: inventories = [], loading } = useBoxRentalConfigurations({
    fetchPolicy: 'cache-and-network',
  })

  return (
    <TableGrid
      collection={inventories}
      loading={loading}
      fields={[
        {
          title: formatMessage({ id: 'models.rental-configuration.country' }),
          render: ({ countryCode }) => countryCode,
        },
        {
          title: formatMessage({ id: 'models.rental-configuration.price' }),
          render: ({ price, currencyCode }) => formatCurrency(price, currencyCode),
        },
        {
          title: formatMessage({ id: 'models.rental-configuration.status' }),
          render: ({ status }) => <RentalConfigurationStatusChip status={status} />,
        },
      ]}
      rowClickable={
        can('boxRentalConfiguration', 'preview') && can('boxRentalConfiguration', 'update')
      }
      emptyLabel={formatMessage({ id: 'common.no-data' })}
      onClickRow={(configuration) =>
        configuration.id
          ? navigate(`/settings/box-rental-configurations/${configuration.id}/edit`)
          : navigate(`/settings/box-rental-configurations/new`, { state: { configuration } })
      }
    />
  )
}
