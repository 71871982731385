import { useMutation, useQuery } from '@apollo/client'
import { CollectionQueryHook, Label, MutationHook, QueryHook } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  LabelsDocument,
  LabelsDocumentInput,
  LabelsDocumentResponse,
  DeleteLabelDocument,
  DeleteLabelDocumentInput,
  DeleteLabelDocumentResponse,
  GetLabelDocument,
  GetLabelDocumentInput,
  GetLabelDocumentResponse,
} from '../docs'

export const useLabels: CollectionQueryHook<
  Omit<LabelsDocumentInput, 'limit' | 'offset'>,
  Label[]
> = (input, options) => {
  const variables = {
    ...defaultCollectionVariables,
    ...input,
  }
  const { data, loading, refetch, fetchMore } = useQuery<LabelsDocumentResponse>(LabelsDocument, {
    ...options,
    variables,
  })

  const loadMore = async () => {
    if (loading || !data || !data.labelsCollection.hasNext) return

    const offset = data.labelsCollection.labels.length

    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.labelsCollection.labels,
    loading,
    loadMore,
    hasMore: data?.labelsCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useLabel: QueryHook<GetLabelDocumentInput, GetLabelDocumentResponse['label']> = (
  variables
) => {
  const { data, loading, refetch } = useQuery<GetLabelDocumentResponse, GetLabelDocumentInput>(
    GetLabelDocument,
    { variables }
  )

  return {
    data: data?.label,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useDeleteLabel: MutationHook<DeleteLabelDocumentInput, DeleteLabelDocumentResponse> =
  () => {
    const [execute, { loading }] = useMutation<
      DeleteLabelDocumentResponse,
      DeleteLabelDocumentInput
    >(DeleteLabelDocument)

    async function request(variables: DeleteLabelDocumentInput): Promise<void> {
      await execute({ variables })
    }

    return { request, loading }
  }
