import { useState, forwardRef, useImperativeHandle, MouseEventHandler } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'

export type ConfirmationDialogRef = {
  open: () => void
}

export type ConfirmationDialogProps = {
  onSubmit: () => void
  onCancel?: () => void
  title: string
  message?: string
  acceptTitle: string
  acceptColor?: 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'info'
  cancelTitle?: string
  cancelColor?: 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'info'
}

export const ConfirmationDialog = forwardRef<ConfirmationDialogRef, ConfirmationDialogProps>(
  (
    {
      title,
      message,
      acceptTitle,
      cancelTitle,
      onSubmit,
      onCancel,
      acceptColor = 'primary',
      cancelColor = 'error',
    },
    ref
  ) => {
    const [isOpenDialog, setIsOpenDialog] = useState(false)

    useImperativeHandle(ref, () => ({
      open: () => {
        setIsOpenDialog(true)
      },
    }))

    const handleOnSubmit: MouseEventHandler = (e) => {
      e.stopPropagation()
      onSubmit()
      setIsOpenDialog(false)
    }

    const handleOnCancel: MouseEventHandler = (e) => {
      e.stopPropagation()
      setIsOpenDialog(false)
      if (onCancel) onCancel()
    }

    return (
      <Dialog open={isOpenDialog} onClose={() => setIsOpenDialog(false)}>
        <DialogTitle>{title}</DialogTitle>
        {message && <DialogContent>{message}</DialogContent>}
        <DialogActions>
          <Button color={acceptColor} onClick={handleOnSubmit}>
            {acceptTitle}
          </Button>
          {cancelTitle && (
            <Button color={cancelColor} onClick={handleOnCancel}>
              {cancelTitle}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
)
