import { FC } from 'react'
import { Order, OrderReturnType, OrderStatus } from '@web-panel/api'
import { DropdownButton, useNotifications } from '@web-panel/shared'
import { useIntl, FormattedMessage } from 'react-intl'

import { useUpdateOrderReturnType } from '@web-panel/oc-api'

type ChangeOrderStatusButtonProps = {
  order: Order
}

const ChangeOrderStatusButton: FC<ChangeOrderStatusButtonProps> = ({ order }) => {
  const { pushNotification } = useNotifications()
  const { request: updateReturnType } = useUpdateOrderReturnType()
  const { formatMessage } = useIntl()

  if (order.status === OrderStatus.Delivered)
    return <FormattedMessage id={`order-return-types.${order.returnType}`} />

  const handleMenuClick = (returnType: OrderReturnType) => {
    return async () => {
      try {
        await updateReturnType({ id: order.id, input: { returnType } })
        pushNotification(
          'success',
          formatMessage({ id: 'common.notifications.successfully-updated' })
        )
      } catch (err) {
        pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
      }
    }
  }

  const menu = Object.values(OrderReturnType).map((returnType) => ({
    key: returnType,
    title: formatMessage({ id: `order-return-types.${returnType}` }),
    onClick: handleMenuClick(returnType),
    disabled: returnType === order.returnType,
  }))

  return (
    <DropdownButton
      title={formatMessage({ id: `order-return-types.${order.returnType}` })}
      menu={menu}
      color="secondary"
      variant="contained"
    />
  )
}

export default ChangeOrderStatusButton
