import { FC } from 'react'
import { BoxTask } from '@web-panel/api'
import { InfinitScroller, TableGrid, useFormatDate, useNotifications } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'

import { useBoxTasks, useDeleteIntegratorBoxOrder } from '@web-panel/oc-api'

type TasksTabProps = {
  boxId: string
}

const TasksTab: FC<TasksTabProps> = ({ boxId }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const { cannot } = useRoleAuthorization()
  const { pushNotification } = useNotifications()
  const {
    data: tasks = [],
    loading,
    loadMore,
    refetch,
  } = useBoxTasks({ boxId }, { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' })
  const { request: deleteTask } = useDeleteIntegratorBoxOrder()

  const isManualTask = (taskId: string) => {
    return taskId.includes('MANUAL_TASK')
  }

  const onDelete = async (task: BoxTask) => {
    try {
      await deleteTask({ orderId: task.taskId })
      await refetch()
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-deleted' })
      )
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <InfinitScroller onLoadMore={loadMore}>
      <TableGrid
        loading={loading}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        collection={tasks}
        fields={[
          {
            title: formatMessage({ id: 'models.box-task.provider' }),
            render: ({ provider }) => (
              <FormattedMessage id={`inventory-task-providers.${provider}`} />
            ),
          },
          {
            title: formatMessage({ id: 'models.box-task.type' }),
            render: ({ type }) => <FormattedMessage id={`inventory-task-types.${type}`} />,
          },
          {
            title: formatMessage({ id: 'models.box-task.status' }),
            render: ({ status }) => <FormattedMessage id={`inventory-task-statuses.${status}`} />,
          },
          {
            title: formatMessage({ id: 'models.box-task.task-url' }),
            render: ({ taskId, taskUrl }) =>
              taskUrl && (
                <a target="_blank" href={taskUrl} rel="noreferrer">
                  {taskId}
                </a>
              ),
          },
          {
            title: formatMessage({ id: 'models.box-task.task-date-from' }),
            render: ({ taskDateFrom }) => formatDate('datetime', taskDateFrom),
          },
          {
            title: formatMessage({ id: 'models.box-task.task-date-to' }),
            render: ({ taskDateTo }) => formatDate('datetime', taskDateTo),
          },
          {
            title: formatMessage({ id: 'models.box-task.create-date' }),
            render: ({ createDate }) => formatDate('datetime', createDate),
          },
        ]}
        rowActions={[
          {
            title: formatMessage({ id: 'common.actions.delete' }),
            icon: 'delete',
            onClick: onDelete,
            disabled: ({ taskId }) => cannot('box', 'deleteCriticalTask') || !isManualTask(taskId),
            mainAction: true,
          },
        ]}
      />
    </InfinitScroller>
  )
}

export default TasksTab
