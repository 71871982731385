import { useMutation, useQuery } from '@apollo/client'
import { CollectionQueryHook, MutationHook, QueryHook, Schedule } from '@web-panel/api'
import { defaultCollectionVariables } from '@web-panel/api/src/hooks/defaults'
import {
  SchedulesDocument,
  SchedulesDocumentInput,
  SchedulesDocumentResponse,
  DeleteScheduleDocument,
  DeleteScheduleDocumentInput,
  DeleteScheduleDocumentResponse,
  GetScheduleDocument,
  GetScheduleDocumentInput,
  GetScheduleDocumentResponse,
  CreateScheduleDocumentInput,
  CreateScheduleDocumentResponse,
  CreateScheduleDocument,
} from '../docs'

export const useSchedules: CollectionQueryHook<
  Omit<SchedulesDocumentInput, 'limit' | 'offset'>,
  Schedule[]
> = (input, options) => {
  const variables = {
    ...defaultCollectionVariables,
    ...input,
  }
  const { data, loading, refetch, fetchMore } = useQuery<SchedulesDocumentResponse>(
    SchedulesDocument,
    {
      ...options,
      variables,
    }
  )

  const loadMore = async () => {
    if (loading || !data || !data.schedulesCollection.hasNext) return

    const offset = data.schedulesCollection.pickupRequests.length

    await fetchMore({ variables: { ...variables, offset } })
  }

  return {
    data: data?.schedulesCollection.pickupRequests,
    loading,
    loadMore,
    hasMore: data?.schedulesCollection.hasNext ?? false,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useSchedule: QueryHook<
  GetScheduleDocumentInput,
  GetScheduleDocumentResponse['schedule']
> = (variables) => {
  const { data, loading, refetch } = useQuery<
    GetScheduleDocumentResponse,
    GetScheduleDocumentInput
  >(GetScheduleDocument, { variables })

  return {
    data: data?.schedule,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useDeleteSchedule: MutationHook<
  DeleteScheduleDocumentInput,
  DeleteScheduleDocumentResponse
> = () => {
  const [execute, { loading }] = useMutation<
    DeleteScheduleDocumentResponse,
    DeleteScheduleDocumentInput
  >(DeleteScheduleDocument)

  async function request(variables: DeleteScheduleDocumentInput): Promise<void> {
    await execute({ variables })
  }

  return { request, loading }
}

export const useCreateSchedule: MutationHook<
  CreateScheduleDocumentInput,
  CreateScheduleDocumentResponse
> = () => {
  const [execute, { loading }] = useMutation<
    CreateScheduleDocumentResponse,
    CreateScheduleDocumentInput
  >(CreateScheduleDocument)

  const request = async ({ input }: CreateScheduleDocumentInput) => {
    const { data } = await execute({ variables: { input } })
    if (!data) throw new Error('Could not create group label')
  }

  return { request, loading }
}
