import { FC } from 'react'
import { Order } from '@web-panel/api'
import { PreviewTable } from '../Tables/PreviewTable'

type GeneralTabProps = {
  data: Order
}
const GeneralTab: FC<GeneralTabProps> = ({ data }) => {
  return <PreviewTable order={data} />
}

export default GeneralTab
