import { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { UserStatus } from '@web-panel/api'
import {
  DefaultErrorPage,
  AppPage,
  CardedContent,
  HorizontalTable,
  HorizontalTableRow,
  ConfirmableButton,
  Button,
  useNotifications,
} from '@web-panel/shared'
import { UserStatusChip } from '@local/components/UserStatusChip'
import modelIcons from '@local/model-icons'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import _includes from 'lodash/includes'
import { useUser, useDeleteUser } from '@web-panel/oc-api'
import { availableRoles } from './Filters'
import { ChangeRoleButton } from './Components/ChangeRoleButton'

const PreviewPage: FC = () => {
  const { formatMessage } = useIntl()
  const { id } = useParams()
  const navigate = useNavigate()
  const { data: user, loading } = useUser({ id: id ?? '' })
  const { request: deleteUser, loading: loadingDeleteUser } = useDeleteUser()
  const { pushNotification } = useNotifications()
  const { can } = useRoleAuthorization()

  if (loading) return null
  if (!user || !_includes(availableRoles, user.role))
    return (
      <DefaultErrorPage
        title={formatMessage({ id: 'errors.404.title' })}
        message={formatMessage({ id: 'errors.404.message' })}
      />
    )

  const handleDelete = async () => {
    try {
      await deleteUser({ id: user.id })
      navigate(-1)
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-deleted' })
      )
    } catch (e) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  const headerActionButtons = [
    can(
      'user',
      'update',
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        onClick={() => navigate(`/admin-users/${user.id}/edit`)}
      >
        <FormattedMessage
          id="common.actions.edit-with-name"
          values={{ name: formatMessage({ id: `user-roles.${user.role}` }) }}
        />
      </Button>
    ),
    can(
      'user',
      'delete',
      <ConfirmableButton
        fullWidth
        variant="contained"
        color="error"
        disabled={user.status === UserStatus.Deleted}
        confirmation={{
          title: formatMessage({ id: 'confirmation-dialogs.admin-user-delete.title' }),
          acceptTitle: formatMessage({ id: 'common.yes' }),
          cancelTitle: formatMessage({ id: 'common.no' }),
        }}
        loading={loadingDeleteUser}
        onClick={handleDelete}
      >
        <FormattedMessage
          id="common.actions.delete-with-name"
          values={{ name: formatMessage({ id: `user-roles.${user.role}` }) }}
        />
      </ConfirmableButton>
    ),
  ]

  return (
    <AppPage
      backButton
      icon={modelIcons.appUser}
      title={formatMessage(
        { id: 'routes.admin-user-preview' },
        { name: [user.firstName, user.lastName].join(' ') }
      )}
      actionButtons={headerActionButtons}
    >
      <CardedContent padded>
        <HorizontalTable>
          <HorizontalTableRow title={formatMessage({ id: 'models.user.id' })}>
            {user.userNumber}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.user.email' })}>
            {user.email}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.user.name' })}>
            {[user.firstName, user.lastName].join(' ')}
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.user.role' })}>
            <ChangeRoleButton user={user} />
          </HorizontalTableRow>
          <HorizontalTableRow title={formatMessage({ id: 'models.user.status' })}>
            <UserStatusChip status={user.status} />
          </HorizontalTableRow>
        </HorizontalTable>
      </CardedContent>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(PreviewPage, ({ can }) => can('user', 'preview'))
