import { useMutation, useQuery } from '@apollo/client'
import { BoxRentalConfiguration, MutationHook, QueryHook } from '@web-panel/api'
import {
  BoxRentalConfigurationDocument,
  BoxRentalConfigurationDocumentInput,
  BoxRentalConfigurationDocumentResponse,
  BoxRentalConfigurationsDocument,
  BoxRentalConfigurationsDocumentInput,
  BoxRentalConfigurationsDocumentResponse,
  CreateBoxRentalConfigurationDocument,
  CreateBoxRentalConfigurationDocumentInput,
  CreateBoxRentalConfigurationDocumentResponse,
  PartnerBoxRentalConfigurationsDocument,
  PartnerBoxRentalConfigurationsDocumentInput,
  PartnerBoxRentalConfigurationsDocumentResponse,
  UpdateBoxRentalConfigurationDocument,
  UpdateBoxRentalConfigurationDocumentInput,
  UpdateBoxRentalConfigurationDocumentResponse,
} from '../docs'

export const useBoxRentalConfigurations: QueryHook<
  BoxRentalConfigurationsDocumentInput,
  BoxRentalConfigurationsDocumentResponse['boxRentalConfigurations']['configurations']
> = (options) => {
  const { data, loading, refetch } = useQuery<BoxRentalConfigurationsDocumentResponse>(
    BoxRentalConfigurationsDocument,
    { ...options }
  )

  return {
    data: data?.boxRentalConfigurations.configurations,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const usePartnerBoxRentalConfiguration: QueryHook<
  PartnerBoxRentalConfigurationsDocumentInput,
  BoxRentalConfiguration
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<PartnerBoxRentalConfigurationsDocumentResponse>(
    PartnerBoxRentalConfigurationsDocument,
    { variables, ...options }
  )

  const configurations = data?.partnerBoxRentalConfigurations.configurations ?? []

  return {
    data: configurations.length > 0 ? configurations[0] : undefined,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useRentalConfiguration: QueryHook<
  BoxRentalConfigurationDocumentInput,
  BoxRentalConfigurationDocumentResponse['boxRentalConfiguration']
> = (variables, options) => {
  const { data, loading, refetch } = useQuery<BoxRentalConfigurationDocumentResponse>(
    BoxRentalConfigurationDocument,
    { variables, ...options }
  )

  return {
    data: data?.boxRentalConfiguration,
    loading,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useCreateRentalConfiguration: MutationHook<
  CreateBoxRentalConfigurationDocumentInput['input'],
  CreateBoxRentalConfigurationDocumentResponse['createBoxRentalConfiguration']
> = (options) => {
  const [execute, { loading }] = useMutation<CreateBoxRentalConfigurationDocumentResponse>(
    CreateBoxRentalConfigurationDocument,
    options
  )

  async function request(input: CreateBoxRentalConfigurationDocumentInput['input']) {
    const { data } = await execute({ variables: { input } })
    if (!data) throw new Error('Could not create rental configuration')

    return data!.createBoxRentalConfiguration
  }

  return { loading, request }
}

export const useUpdateRentalConfiguration: MutationHook<
  UpdateBoxRentalConfigurationDocumentInput,
  UpdateBoxRentalConfigurationDocumentResponse['updateBoxRentalConfiguration']
> = (options) => {
  const [execute, { loading }] = useMutation<UpdateBoxRentalConfigurationDocumentResponse>(
    UpdateBoxRentalConfigurationDocument,
    options
  )

  async function request(variables: UpdateBoxRentalConfigurationDocumentInput) {
    const { data } = await execute({ variables })
    if (!data) throw new Error('Could not update rental configuration')

    return data!.updateBoxRentalConfiguration
  }

  return { loading, request }
}
