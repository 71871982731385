import { VFC } from 'react'
import { ConfirmableButton, useNotifications } from '@web-panel/shared'

import { FormattedMessage, useIntl } from 'react-intl'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useNavigate } from 'react-router-dom'

import { useCreateIntegratorBoxOrder } from '@web-panel/oc-api'

type OptimoRouteCriticalButtonProps = {
  boxId: string
}
export const OptimoRouteCriticalButton: VFC<OptimoRouteCriticalButtonProps> = ({ boxId }) => {
  const { formatMessage } = useIntl()
  const { request, loading } = useCreateIntegratorBoxOrder()
  const { pushNotification } = useNotifications()
  const { cannot } = useRoleAuthorization()
  const navigate = useNavigate()

  const onClick = async () => {
    try {
      await request({ boxId })
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-created' })
      )
      navigate('#tasks', { replace: true })
    } catch (err) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <ConfirmableButton
      variant="contained"
      color="secondary"
      confirmation={{
        title: formatMessage({
          id: 'confirmation-dialogs.create-optimo-route-critical-task.title',
        }),
        acceptTitle: formatMessage({ id: 'common.yes' }),
        cancelTitle: formatMessage({ id: 'common.no' }),
      }}
      loading={loading}
      onClick={onClick}
      disabled={cannot('box', 'createCriticalTask')}
    >
      <FormattedMessage id="common.actions.create-critical-task" />
    </ConfirmableButton>
  )
}
