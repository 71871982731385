import { FC } from 'react'
import { Paper, TableGrid } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { Address } from '@web-panel/api/src/data-types/address'

type IndexTableProps = {
  data: Address[]
  loading: boolean
}

const IndexTable: FC<IndexTableProps> = ({ data, loading }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { can } = useRoleAuthorization()
  return (
    <Paper className="overflow-hidden">
      <TableGrid
        loading={loading}
        collection={data}
        fields={[
          {
            title: formatMessage({ id: 'models.shipment-partner.name' }),
            render: (address) => address.contactDetail.company,
          },
          {
            title: formatMessage({ id: 'models.shipment-partner.address' }),
            render: (address) => `${address.address} ${address.cityName}`,
          },
          {
            title: formatMessage({ id: 'models.shipment-partner.fullName' }),
            render: (address) => address.contactDetail.fullName,
          },
          {
            title: formatMessage({ id: 'models.shipment-partner.phone' }),
            render: (address) => `+${address.contactDetail.phone}`,
          },
          {
            title: formatMessage({ id: 'models.shipment-partner.type' }),
            render: (address) => address.partnerType,
          },
        ]}
        onClickRow={(address) => navigate(address.id)}
        rowClickable={can('addresses', 'preview')}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
      />
    </Paper>
  )
}

export default IndexTable
